import {
  setColor,
  formatColor,
  convertHEXtoHSL,
} from '../helpers/set-color-variations';

const primaryHSLObj = convertHEXtoHSL('#405ED6');
const quaternaryHSLObj = convertHEXtoHSL('#55225D');

export default {
  primaryMain: formatColor(setColor('primary', primaryHSLObj).main),
  primaryMain12: formatColor(setColor('primary', primaryHSLObj).main, 0.12),
  primary30: formatColor(setColor('primary', primaryHSLObj).mid30),
  primaryLight: formatColor(setColor('primary', primaryHSLObj).light),

  quaternaryMainHex: '#55225D',
  quaternaryMain: formatColor(setColor('quaternary', quaternaryHSLObj).main),
  quaternary30: formatColor(setColor('quaternary', quaternaryHSLObj).mid30),
  quaternaryLight: formatColor(setColor('quaternary', quaternaryHSLObj).light),

  quinaryDark: '#6E970B',

  neutralMain: '#050D30',
  neutral90: '#3B3C42',
  neutral80: '#575961',
  neutral70: '#696C75',
  neutral50: '#D2D4D9',
  neutral60: '#999A9E',
  neutral40: '#E5E7EB',
  neutral30: '#F0F1F5',
  neutralLight: '#F0F1F5',
  neutralLightNew: '#F5F5F5',
  neutralLight90: 'rgba(245, 245, 245, 0.9)',
  neutralLight100: 'rgba(245, 245, 245, 1)',
  neutralSurface: '#FBFAF6',
  neutralSurface5: 'rgba(253, 252, 249, 0.05)',
  white: '#FFFFFF',
  secondaryMain: '#002666',
  tertiaryMain: '#FF5B59',
  quinaryMain: '#8cb036',
  quinaryLight: '#F2F8E3',
  irlenBlue: 'rgba(150, 173, 252, 1)',
  irlenGreen: '#8cb036',
  irlenYellow: 'rgba(237, 221, 110, 1)',
  irlenRed: 'rgba(224, 166, 170, 1)',
  primaryMain4: 'rgba(64, 94, 214, 0.04)',
  primaryMain90: 'rgba(64, 94, 214, 0.9)',
  neutralMain50: 'rgba(5, 13, 48, 0.5)',
  neutralMain10: 'rgba(5, 13, 48, 0.1)',
  white50: 'rgba(250, 250, 250, 0.5)',
  neutralMain80: 'rgba(5, 13, 48, 0.8)',
  quaternaryMain85: 'rgba(130, 59, 125, 0.85)',
  error: '#D83A2C',
  placeholder: '#696C75',
  secondaryLight: '#F2F4F7',
  tertiaryLight: '#FFF7F7',
  warningLight: '#FFF9E1',
  warningMain: '#F3B319',
  yellowOrange: '#FFF5DA',
  quaternaryLightBlue: '#E7ECFF',
  successBg: '#ECF8E3',
  failBg: '#FFEBEB',
};
