import { useState } from 'react';
import * as T from '../../../../components/Typography';
import { PlayButton } from '../../../../components/Button';
import EmptySpace from '../../../../components/EmptySpace';
import Option from '../../../../components/Option';
import * as S from './style';
import { showMovingBlock } from '../../../../helpers';
import StepFooterButtons from '../StepFooterButtons';

const DragAndDropCreateOrder = ({
  data: {
    textAudio,
    textAudioUrl,
    options: _options = [],
    correctAnswer = '',
  } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  cmsPreview,
}) => {
  const [answers, setAnswers] = useState(
    correctAnswer.split('-').map((e, i) => ({ text: '' }))
  );
  const [options, setOptions] = useState(
    _options.map((e, i) => ({
      text: e.option,
      id: i,
    }))
  );
  const [activePosition, setActivePosition] = useState(0);
  const onClickAnswer = (answer, i) => {
    setActivePosition(i);

    if (answer?.text) {
      showMovingBlock({
        OptionElId: `option-${answer.id}`,
        answerElId: `answer-${i}`,
        movingElId: 'moving-div',
        isBeingSelected: true,
        done: () => {
          setOptions((_ops) =>
            _ops.map((e) => {
              if (e.id === answer.id) {
                return { ...e };
              }
              return e;
            })
          );
        },
      });
      setAnswers((_answers) =>
        _answers.map((e, _i) => {
          if (_i === i) {
            return { id: null, text: null };
          }
          return e;
        })
      );
    }
  };

  const onClickOption = (option) => {
    setOptions((_ops) =>
      _ops.map((e) => {
        if (e.id === option.id) {
          return { ...e };
        }
        return e;
      })
    );
    showMovingBlock({
      OptionElId: `option-${option.id}`,
      answerElId: `answer-${activePosition}`,
      movingElId: 'moving-div',
      isBeingSelected: false,
      done: () => {
        setAnswers((_answers) => {
          const newAnswers = _answers.map((e, i) => {
            if (i === activePosition) {
              return { ...option };
            }
            return e;
          });

          return newAnswers;
        });
      },
    });
    setActivePosition((_activePosition) => {
      return _activePosition === answers.length - 1
        ? _activePosition
        : _activePosition + 1;
    });
  };

  const onFocusAnswer = (i) => {
    setActivePosition(i);
  };

  const answer = answers.map((e) => e.text).join('');

  if (!options?.length) {
    return (
      <S.Wrapper>
        {<T.P color="error">Exercise missing required fields </T.P>}
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper>
      {/* keep this to animation */}
      <div style={{ display: 'none' }}>
        <Option id="moving-div"></Option>
      </div>

      <S.TextAudioWrapper ml={3} mlM={1} mlT={2} mt={1} mb={7}>
        <PlayButton
          audioUrl={textAudioUrl}
          width="56px"
          height="56px"
          iconProps={{ width: '32px', height: '32px' }}
          fallbackText={textAudio}
        />
        <T.P ml={3} weight="regular" size="med">
          {textAudio}
        </T.P>
      </S.TextAudioWrapper>

      <S.PreFilledAnswersGridWrapper>
        {answers.map((answer, i) => {
          let bgColor = answer?.text
            ? 'neutralSurface'
            : 'rgba(255, 255, 255, 0.5)';
          bgColor = answer.preFilled ? 'white' : bgColor;

          const allowClear =
            (i === activePosition - 1 && !answers[activePosition]?.text) ||
            (i === activePosition &&
              answers[activePosition]?.text &&
              i === answers.length - 1);
          return (
            <EmptySpace
              isActive={i === activePosition && !cmsPreview}
              onClick={() =>
                (allowClear || i === activePosition) && onClickAnswer(answer, i)
              }
              onFocus={() =>
                (allowClear || i === activePosition) && onFocusAnswer(i)
              }
              id={`answer-${i}`}
              bgColor={bgColor}
              key={i}
              w="74px"
              h="74px"
              size="large"
              disabled={i !== activePosition && !allowClear}
            >
              {answer?.text}
            </EmptySpace>
          );
        })}
      </S.PreFilledAnswersGridWrapper>
      <S.OptionsGridWrapper>
        <S.OptionsGrid>
          {options.map((op) => (
            <Option
              onClick={() => onClickOption(op)}
              id={`option-${op.id}`}
              key={op.id}
              size="large"
            >
              {op.text}
            </Option>
          ))}
        </S.OptionsGrid>
      </S.OptionsGridWrapper>

      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleNext}
        preview={preview}
        disabled={disabled || answer !== correctAnswer?.replace(/-/g, '')}
        enableNext={answer === correctAnswer?.replace(/-/g, '')}
      />
    </S.Wrapper>
  );
};

export default DragAndDropCreateOrder;
