import theme from '../../../theme';

const Goals = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M19.7447 8.08984H5.31021C4.97301 8.10272 4.65392 8.24572 4.41991 8.48883C4.1859 8.73194 4.05518 9.05626 4.05518 9.3937C4.05518 9.73113 4.1859 10.0554 4.41991 10.2986C4.65392 10.5417 4.97301 10.6847 5.31021 10.6975H19.7447C20.0819 10.6847 20.401 10.5417 20.635 10.2986C20.869 10.0554 20.9997 9.73113 20.9997 9.3937C20.9997 9.05626 20.869 8.73194 20.635 8.48883C20.401 8.24572 20.0819 8.10272 19.7447 8.08984Z"
      fill={color || theme.colors.neutralMain}
    />
    <path
      d="M15.7356 13.3047H5.3029C4.58333 13.3047 4 13.888 4 14.6076V14.6095C4 15.3291 4.58333 15.9124 5.3029 15.9124H15.7356C16.4552 15.9124 17.0385 15.3291 17.0385 14.6095V14.6076C17.0385 13.888 16.4552 13.3047 15.7356 13.3047Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default Goals;
