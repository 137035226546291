import * as S from './style';

const LabelInput = ({ value, setValue, staticQuestion, error }) => {
  return (
    <S.QuestionLabelInput
      variant="standard"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      inputProps={{
        style: {
          paddingLeft: '12px',
        },
      }}
      disabled={staticQuestion}
      error={error}
      helperText={error}
    />
  );
};

export default LabelInput;
