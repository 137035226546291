import { Col, Row } from 'components/Grid';
import Image from 'components/Image';
import * as T from 'components/Typography';
import React from 'react';
import Copy from 'components/Copy';
import { BasicButton } from 'components/Button';
import { navRoutes } from '../../constants';
import { useLocation } from 'react-router-dom';

export const OrganizationAdded = () => {
  const { state } = useLocation();

  return (
    <Row jc={'center'} inner>
      <Col w={[4, 12, 12]} jc="center">
        <Image image="Congrats" width="300px" height={200} />
      </Col>
      <Col w={[4, 12, 12]} dir="column" jc="center" mt="5">
        <T.H1 weight="bold" color="neutralMain">
          Organisation Added successfully
        </T.H1>
        <T.P color="neutralMain" mt="3" maxWidth="500px" ta="center">
          The organisation has now received an email with their unique invite
          link to create their account. You can also copy the link below:
        </T.P>
      </Col>
      <Col w={[4, 9, 9]} mt="5">
        <Copy
          inviteToken={state?.inviteLink}
          tokenStyle={{ color: 'neutral70' }}
        />
      </Col>
      <Col w={[4, 12, 12]} mt="5" display="flex" jc="center">
        <BasicButton
          variant="primary"
          to={navRoutes.ADMIN.ORGANISATIONS}
          maxWidth="300px"
        >
          Return to organisations
        </BasicButton>
      </Col>
    </Row>
  );
};
