// MAKE SURE THIS FILE IS SYNCED WIth ./server/database/init/types.sql And constants in backend
// NOTE!!! IF YOU CHANGE ANY TYPES HERE MAKE SURE YOU UPDATE THE BACKEND as well
import { alphabetise, toTitleCase } from '../helpers';

export const keyMilestones = [1, 3, 6, 9, 12, 15];
export const contactWays = [
  { label: 'Phone', value: 'PHONE' },
  { label: 'Email', value: 'EMAIL' },
  {
    label: 'Keyworker',
    value: 'KEYWORKER',
  },
];

export const formTypes = {
  EXERCISE: 'EXERCISE',
  STEP: 'STEP',
};

export const mediaTypes = {
  IMAGE: 'IMAGE',
  AUDIO: 'AUDIO',
  VO_AUDIO: 'VO_AUDIO',
  ORG_LOGO: 'ORG_LOGO',
  MSG_AUDIO: 'MSG_AUDIO',
  // These types listed below are used
  // To handle the automatic selection of media items
  // Also when creating vo_required items to update the correct field
  TEXT_AUDIO: 'TEXT_AUDIO',
  QUESTION_AUDIO: 'QUESTION_AUDIO',
  ANSWER_IMAGE: 'ANSWER_IMAGE',
  ANSWER_AUDIO: 'ANSWER_AUDIO',
  OPTION_AUDIO: 'OPTION_AUDIO',
  MULTIPLE_WORDS_OPTION_AUDIO: 'MULTIPLE_WORDS_OPTION_AUDIO',
  OPTION_IMAGE: 'OPTION_IMAGE',
  TITLE_AUDIO: 'TITLE_AUDIO_KEY',
  BEFORE_EXERCISE_TEXT_AUDIO: 'BEFORE_EXERCISE_TEXT_AUDIO',
  BEFORE_EXERCISE_TIP_AUDIO: 'BEFORE_EXERCISE_TIP_AUDIO',
};

export const mediaInputLabels = {
  IMAGE_LABEL: 'Image Key',
  AUDIO_LABEL: 'Audio Key',
};

export const userRoles = {
  LEARNER: 'LEARNER',
  COACH: 'COACH',
  FACILITATOR: 'FACILITATOR',
  CONTENT_EDITOR: 'CONTENT_EDITOR',
  ADMIN: 'ADMIN',
  ORGANISATION_ADMIN: 'ORGANISATION_ADMIN',
};

export const userStatuses = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
};

export const organisationStatuses = {
  INVITED: 'INVITED',
  ACTIVATED: 'ACTIVATED',
  DEACTIVATED: 'DEACTIVATED',
  DELETED: 'DELETED',
};

export const facilitatorsStatuses = {
  ACTIVATED: 'ACTIVATED',
  DEACTIVATED: 'DEACTIVATED',
  DELETED: 'DELETED',
};

export const coaches_statuses = {
  ACTIVATED: 'ACTIVATED',
  DEACTIVATED: 'DEACTIVATED',
};

export const stageTypes = {
  BEFORE_CLAIMING: 'BEFORE_CLAIMING',
  CLAIMING: 'CLAIMING',
  AFTER_CLAIMING: 'AFTER_CLAIMING',
};

export const linkTypes = {
  LINK: 'LINK',
  PHONE: 'PHONE',
  WEBCHAT_LINK: 'WEBCHAT_LINK',
  EMAIL: 'EMAIL',
};

export const whereDoYouNeedToGoTypes = {
  LINK: 'LINK',
  PHONE: 'PHONE',
};

export const bestDayTime = {
  MORNING: 'MORNING',
  AFTERNOON: 'AFTERNOON',
  EVENING: 'EVENING',
  ANYTIME: 'ANYTIME',
};

export const availabilityHoursPerWeek = {
  ONE_TO_TWO_HOURS: '1 to 2 hours',
  THREE_TO_FOUR_HOURS: '3 to 4 hours',
  FOUR_PLUS_HOURS: '4+ hours',
};

export const organisationTypes = {
  INFORMAL_OR_CONSTITUTED_COMMUNITY_GROUP_LITTLE_OR_NO_ANNUAL_INCOME:
    'Informal or constituted community group [little or no annual income]',
  MICRO_CHARITY_VOLUNTARY_ORGANISATION__LESS_THAN_10000_ANNUAL_INCOME:
    'Micro Charity / Voluntary Organisation [Less than £10,000 annual income]',
  SMALL_CHARITY_VOLUNTARY_ORGANISATION_10000_TO_100000_ANNUAL_INCOME:
    'Small Charity / Voluntary Organisation [£10,000 to £100,000 annual income]',
  MEDIUM_SIZE_CHARITY_VOLUNTARY_ORGANISATION_100000_TO_1M_ANNUAL_INCOME:
    'Medium Size Charity / Voluntary Organisation [£100,000 to £1m annual income]',
  LARGE_CHARITY_VOLUNTARY_ORGANISATION_1M_TO_10M_ANNUAL_INCOME:
    'Large Charity / Voluntary Organisation [£1m to 10m annual income]',
  MAJOR_CHARITY_VOLUNTARY_ORGANISATION_10M_ANNUAL_INCOME:
    'Major Charity / Voluntary Organisation [£10m+ annual income]',
  LOCAL_GOVERNMENT: 'Local Government',
  NATIONAL_GOVERNMENT: 'National Government',
  NHS_CCG: 'NHS / CCG',
  COMMUNITY_INTEREST_COMPANY: 'Community Interest Company',
  SOCIAL_ENTERPRISE: 'Social Enterprise',
  SCHOOL: 'School',
  FAITH_ORGANISATION: 'Faith Organisation',
};

export const stages = {
  beforeClaiming: 'BEFORE_CLAIMING',
  claiming: 'CLAIMING',
  afterClaiming: 'AFTER_CLAIMING',
};

export const exerciseTypes = {
  LISTEN_AND_REPEAT: 'LISTEN_AND_REPEAT',
  LISTEN_AND_REPEAT_MULTIPLE: 'LISTEN_AND_REPEAT_MULTIPLE',
  DRAG_AND_DROP_1: 'DRAG_AND_DROP_1',
  DRAG_AND_DROP_2: 'DRAG_AND_DROP_2',
  DRAG_AND_DROP_ORDER_SOUNDS: 'DRAG_AND_DROP_ORDER_SOUNDS',
  DRAG_AND_DROP_CREATE_ORDER: 'DRAG_AND_DROP_CREATE_ORDER',
  SPOT_THE_WORD: 'SPOT_THE_WORD',
  WORD_BUILD: 'WORD_BUILD',
  VOWEL_BREAK: 'VOWEL_BREAK',
  LISTEN_AND_REPEAT_GRID: 'LISTEN_AND_REPEAT_GRID',
  LISTEN_AND_REPEAT_GRID_HIDDEN: 'LISTEN_AND_REPEAT_GRID_HIDDEN',
  REORDER: 'REORDER',
  WORD_JOIN_1: 'WORD_JOIN_1',
  WORD_JOIN_2: 'WORD_JOIN_2',
  WORD_JOIN_3: 'WORD_JOIN_3',
  SENTENCE_FILL: 'SENTENCE_FILL',
  TYPE_NEW_ENDINGS: 'TYPE_NEW_ENDINGS',
  COMBINE_WORDS: 'COMBINE_WORDS',
  COMBINE_WORDS_PRE: 'COMBINE_WORDS_PRE',
  TYPING: 'TYPING',
  TEXT_FINDER: 'TEXT_FINDER',
  COMPREHENSION_TEXT_FINDER: 'COMPREHENSION_TEXT_FINDER',
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  MULTIPLE_CHOICE_BUBBLE: 'MULTIPLE_CHOICE_BUBBLE',
  MULTIPLE_CHOICE_GRID: 'MULTIPLE_CHOICE_GRID',
  SELECT_RECIPES: 'SELECT_RECIPES',
  COMPREHENSION_LISTEN_AND_RESPOND: 'COMPREHENSION_LISTEN_AND_RESPOND',
  TRACE: 'TRACE',
  DRAW: 'DRAW',
  QUESTION_AND_ANSWER: 'QUESTION_AND_ANSWER',
  TYPING_HORIZONTAL: 'TYPING_HORIZONTAL',
  FILL_IN_THE_GRID: 'FILL_IN_THE_GRID',
  PYRAMID: 'PYRAMID',
  GRID_WITH_TYPING_ANSWER: 'GRID_WITH_TYPING_ANSWER',
  FACT_FAMILY: 'FACT_FAMILY',
  FORMULAE: 'FORMULAE',
  COLUMNS_TYPING: 'COLUMNS_TYPING',
  COMPREHENSION_SELECT: 'COMPREHENSION_SELECT',
};

export const formulaTypes = {
  SUM: 'SUM',
  SUBTRACTION: 'SUBTRACTION',
  DIVISION: 'DIVISION',
  MULTIPLICATION: 'MULTIPLICATION',
};

const dragAndDrop2VariationsObj = {
  DEFAULT: 'DEFAULT',
  WITH_EXPLAINER_AUDIO: 'WITH_EXPLAINER_AUDIO',
};
export const dragAndDrop2Variations = {
  variations: dragAndDrop2VariationsObj,
};

const combineWordsVariationsObj = {
  DEFAULT: 'DEFAULT',
  ONE_QUESTION_PER_ROW: 'ONE_QUESTION_PER_ROW',
};
export const combineWordsVariations = {
  variations: combineWordsVariationsObj,
};

const questionAndAnswerVariationsObj = {
  IMAGE_ONLY: 'IMAGE_ONLY',
  WITH_ENDING: 'WITH_ENDING',
  FRACTION: 'FRACTION',
  MULTIPLICATION: 'MULTIPLICATION',
  TWO_INPUTS_WITH_ENDINGS: 'TWO_INPUTS_WITH_ENDINGS',
  NO_IMAGE: 'NO_IMAGE',
};
export const questionAndAnswerVariations = {
  variations: questionAndAnswerVariationsObj,
  showQuestionVariations: [
    questionAndAnswerVariationsObj.IMAGE_ONLY,
    questionAndAnswerVariationsObj.NO_IMAGE,
  ],
  allowTwoAnswers: [
    questionAndAnswerVariationsObj.FRACTION,
    questionAndAnswerVariationsObj.TWO_INPUTS_WITH_ENDINGS,
  ],
  allowThreeAnswers: [questionAndAnswerVariationsObj.MULTIPLICATION],
};

const typingHorizontalVariationsObj = {
  ONE_ANSWER: 'ONE_ANSWER',
  TWO_ANSWERS: 'TWO_ANSWERS',
};
export const typingHorizontalVariations = {
  variations: typingHorizontalVariationsObj,
  correctOptionTypes: {
    HAS_PREFIX: 'HAS_PREFIX',
    HAS_SUFFIX: 'HAS_SUFFIX',
  },
};

const multipleChoiceVariationsObj = {
  DEFAULT: 'DEFAULT',
  TEXT_AND_IMAGE_AT_THE_TOP: 'TEXT_AND_IMAGE_AT_THE_TOP',
  OPTIONS_HAVE_IMAGES: 'OPTIONS_HAVE_IMAGES',
  TEXT_AND_PARAGRAPH_AT_THE_TOP: 'TEXT_AND_PARAGRAPH_AT_THE_TOP',
};
export const multipleChoiceVariations = {
  variations: multipleChoiceVariationsObj,
};

const typeNewEndingVariationsObj = {
  DEFAULT: 'DEFAULT',
  SEQUENCES: 'SEQUENCES',
};
export const typeNewEndingVariations = {
  variations: typeNewEndingVariationsObj,
};

const fillInTheGridVariationsObj = {
  GRID_NO_VALUES: 'GRID_NO_VALUES',
  ROWS: 'ROWS',
  GRID_WITH_VALUES: 'GRID_WITH_VALUES',
  THERMOMETER: 'THERMOMETER',
};
export const fillInTheGridVariations = {
  variations: fillInTheGridVariationsObj,
};

const factFamilyVariationsObj = {
  PYRAMID: 'PYRAMID',
  FLAT: 'FLAT',
};
export const factFamilyVariations = {
  variations: factFamilyVariationsObj,
};

export const sessionPreferencesTypes = {
  IN_PERSON: 'IN_PERSON',
  VIDEO_CALLS: 'VIDEO_CALLS',
  PHONE_CALLS: 'PHONE_CALLS',
};

export const voicePreferencesTypes = {
  FEMALE: 'FEMALE',
  MALE: 'MALE',
};

export const preferredContactWays = {
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  KEYWORKER: 'KEYWORKER',
};

export const sessionPreferencesDropDown = alphabetise(
  Object.values(sessionPreferencesTypes).map((e) => ({
    label: toTitleCase(e),
    value: e,
  }))
);

export const preferredContactWaysDropDown = [
  {
    value: preferredContactWays.PHONE,
    label: 'Phone',
  },
  {
    value: preferredContactWays.EMAIL,
    label: 'Email',
  },
  {
    value: preferredContactWays.KEYWORKER,
    label: 'Via keyworker',
  },
];

export const establishmentsOptions = [
  { label: 'HMP Whatton', value: 'HMP Whatton', group: 'Central' },
  { label: 'HMP Stocken', value: 'HMP Stocken', group: 'Central' },
  { label: 'HMP Bure', value: 'HMP Bure', group: 'Central' },
  { label: 'HMP Dovegate', value: 'HMP Dovegate', group: 'Central' },
  { label: 'HMP Wayland', value: 'HMP Wayland', group: 'Central' },
  { label: 'HMP Foston Hall', value: 'HMP Foston Hall', group: 'Central' },
  { label: 'HMP Gartree', value: 'HMP Gartree', group: 'Central' },
  { label: 'HMP Leicester', value: 'HMP Leicester', group: 'Central' },
  { label: 'HMP Lincoln', value: 'HMP Lincoln', group: 'Central' },
  { label: 'HMP Littlehey', value: 'HMP Littlehey', group: 'Central' },
  {
    label: 'HMP Lowdham Grange',
    value: 'HMP Lowdham Grange',
    group: 'Central',
  },
  {
    label: 'HMP North Sea Camp',
    value: 'HMP North Sea Camp',
    group: 'Central',
  },
  { label: 'HMP Norwich', value: 'HMP Norwich', group: 'Central' },
  {
    label: 'HMP Peterborough (F)',
    value: 'HMP Peterborough (F)',
    group: 'Central',
  },
  {
    label: 'HMP Peterborough (M)',
    value: 'HMP Peterborough (M)',
    group: 'Central',
  },
  { label: 'HMP Ranby', value: 'HMP Ranby', group: 'Central' },
  { label: 'HMP Rye Hill', value: 'HMP Rye Hill', group: 'Central' },
  { label: 'HMP Sudbury', value: 'HMP Sudbury', group: 'Central' },
  { label: 'HMP Whitemoor', value: 'HMP Whitemoor', group: 'Central' },
  { label: 'HMP Woodhill', value: 'HMP Woodhill', group: 'Central' },
  { label: 'HMP Bedford', value: 'HMP Bedford', group: 'Central' },
  { label: 'HMP Nottingham', value: 'HMP Nottingham', group: 'Central' },
  { label: 'HMP Onley', value: 'HMP Onley', group: 'Central' },
  {
    label: 'HMYOI Werrington',
    value: 'HMYOI Werrington',
    group: 'Central',
  },

  {
    label: 'HMP Hollesley Bay',
    value: 'HMP Hollesley Bay',
    group: 'Community',
  },
  { label: 'HMP The Mount', value: 'HMP The Mount', group: 'Community' },
  { label: 'HMP Chelmsford', value: 'HMP Chelmsford', group: 'Community' },
  {
    label: 'HMP Highpoint - North',
    value: 'HMP Highpoint - North',
    group: 'Community',
  },
  {
    label: 'HMP Highpoint - South',
    value: 'HMP Highpoint - South',
    group: 'Community',
  },
  {
    label: 'HMP Warren Hill',
    value: 'HMP Warren Hill',
    group: 'Community',
  },

  {
    label: 'HMP Hydebankwood (F)',
    value: 'HMP Hydebankwood (F)',
    group: 'North East',
  },
  {
    label: 'HMP Hydebankwood (M)',
    value: 'HMP Hydebankwood (M)',
    group: 'North East',
  },
  { label: 'HMP Maghaberry', value: 'HMP Maghaberry', group: 'North East' },
  { label: 'HMP Magilligan', value: 'HMP Magilligan', group: 'North East' },
  { label: 'HMP Leeds', value: 'HMP Leeds', group: 'North East' },
  { label: 'HMP Lindholme', value: 'HMP Lindholme', group: 'North East' },
  { label: 'HMP Hatfield', value: 'HMP Hatfield', group: 'North East' },
  { label: 'HMP New Hall', value: 'HMP New Hall', group: 'North East' },
  {
    label: 'HMP Askham Grange',
    value: 'HMP Askham Grange',
    group: 'North East',
  },
  { label: 'HMP Doncaster', value: 'HMP Doncaster', group: 'North East' },
  { label: 'HMP Deerbolt', value: 'HMP Deerbolt', group: 'North East' },
  {
    label: 'HMP Full Sutton',
    value: 'HMP Full Sutton',
    group: 'North East',
  },
  {
    label: 'HMP Holme House',
    value: 'HMP Holme House',
    group: 'North East',
  },
  { label: 'HMP Hull', value: 'HMP Hull', group: 'North East' },
  { label: 'HMP Humber', value: 'HMP Humber', group: 'North East' },
  {
    label: 'HMP Kirklevington Grange',
    value: 'HMP Kirklevington Grange',
    group: 'North East',
  },
  { label: 'HMP Low Newton', value: 'HMP Low Newton', group: 'North East' },
  {
    label: 'HMP Northumberland',
    value: 'HMP Northumberland',
    group: 'North East',
  },
  { label: 'HMP Wakefield', value: 'HMP Wakefield', group: 'North East' },
  { label: 'HMP Wealstun', value: 'HMP Wealstun', group: 'North East' },
  { label: 'HMP Durham', value: 'HMP Durham', group: 'North East' },
  { label: 'HMP Frankland', value: 'HMP Frankland', group: 'North East' },
  { label: 'HMP Moorland', value: 'HMP Moorland', group: 'North East' },
  { label: 'HMYOI Wetherby', value: 'HMYOI Wetherby', group: 'North East' },

  { label: 'HMP Berwyn', value: 'HMP Berwyn', group: 'North West' },
  {
    label: 'HMP Long Lartin',
    value: 'HMP Long Lartin',
    group: 'North West',
  },
  { label: 'HMP Hewell', value: 'HMP Hewell', group: 'North West' },
  { label: 'HMP Brinsford', value: 'HMP Brinsford', group: 'North West' },
  {
    label: 'HMP Featherstone',
    value: 'HMP Featherstone',
    group: 'North West',
  },
  { label: 'HMP Oakwood', value: 'HMP Oakwood', group: 'North West' },
  { label: 'HMP Birmingham', value: 'HMP Birmingham', group: 'North West' },
  { label: 'HMP Stafford', value: 'HMP Stafford', group: 'North West' },
  {
    label: 'HMP Stoke Heath',
    value: 'HMP Stoke Heath',
    group: 'North West',
  },
  {
    label: 'HMP Swinfen Hall',
    value: 'HMP Swinfen Hall',
    group: 'North West',
  },
  { label: 'HMP Drake Hall', value: 'HMP Drake Hall', group: 'North West' },
  { label: 'HMP Risley', value: 'HMP Risley', group: 'North West' },
  {
    label: 'HMP Lancaster Farms',
    value: 'HMP Lancaster Farms',
    group: 'North West',
  },
  {
    label: 'HMP Forest Bank',
    value: 'HMP Forest Bank',
    group: 'North West',
  },
  { label: 'HMP Garth', value: 'HMP Garth', group: 'North West' },
  { label: 'HMP Wymott', value: 'HMP Wymott', group: 'North West' },
  { label: 'HMP Kirkham', value: 'HMP Kirkham', group: 'North West' },
  { label: 'HMP Altcourse', value: 'HMP Altcourse', group: 'North West' },
  { label: 'HMP Hindley', value: 'HMP Hindley', group: 'North West' },
  {
    label: 'HMP Thorn Cross',
    value: 'HMP Thorn Cross',
    group: 'North West',
  },
  {
    label: 'HMP Buckley Hall',
    value: 'HMP Buckley Hall',
    group: 'North West',
  },
  { label: 'HMP Haverigg', value: 'HMP Haverigg', group: 'North West' },
  { label: 'HMP Liverpool', value: 'HMP Liverpool', group: 'North West' },
  { label: 'HMP Manchester', value: 'HMP Manchester', group: 'North West' },
  { label: 'HMP Preston', value: 'HMP Preston', group: 'North West' },
  { label: 'HMP Styal', value: 'HMP Styal', group: 'North West' },

  { label: 'HMP Isis', value: 'HMP Isis', group: 'South East' },
  { label: 'HMP Thameside', value: 'HMP Thameside', group: 'South East' },
  { label: 'HMP Send', value: 'HMP Send', group: 'South East' },
  {
    label: 'HMYOI Cookham Wood',
    value: 'HMYOI Cookham Wood',
    group: 'South East',
  },
  {
    label: 'HMP Isle of Wight - Albany',
    value: 'HMP Isle of Wight - Albany',
    group: 'South East',
  },
  {
    label: 'HMP Isle of Wight - Parkhurst',
    value: 'HMP Isle of Wight - Parkhurst',
    group: 'South East',
  },
  { label: 'HMP Ford', value: 'HMP Ford', group: 'South East' },
  { label: 'HMP Elmley', value: 'HMP Elmley', group: 'South East' },
  {
    label: 'HMP Standford Hill',
    value: 'HMP Standford Hill',
    group: 'South East',
  },
  { label: 'HMP Swaleside', value: 'HMP Swaleside', group: 'South East' },
  { label: 'HMP Rochester', value: 'HMP Rochester', group: 'South East' },
  { label: 'HMP Belmarsh', value: 'HMP Belmarsh', group: 'South East' },
  { label: 'HMP Brixton', value: 'HMP Brixton', group: 'South East' },
  {
    label: 'HMP Bronzefield',
    value: 'HMP Bronzefield',
    group: 'South East',
  },
  { label: 'HMP Coldingley', value: 'HMP Coldingley', group: 'South East' },
  { label: 'HMP Downview', value: 'HMP Downview', group: 'South East' },
  {
    label: 'HMP East Sutton Park',
    value: 'HMP East Sutton Park',
    group: 'South East',
  },
  { label: 'HMP High Down', value: 'HMP High Down', group: 'South East' },
  { label: 'HMP Lewes', value: 'HMP Lewes', group: 'South East' },
  { label: 'HMP Maidstone', value: 'HMP Maidstone', group: 'South East' },
  {
    label: 'HMP Pentonville',
    value: 'HMP Pentonville',
    group: 'South East',
  },
  { label: 'HMP Wandsworth', value: 'HMP Wandsworth', group: 'South East' },
  {
    label: 'HMP Wormwood Scrubs',
    value: 'HMP Wormwood Scrubs',
    group: 'South East',
  },
  { label: 'HMYOI Feltham', value: 'HMYOI Feltham', group: 'South East' },
  { label: 'HMP Feltham', value: 'HMP Feltham', group: 'South East' },
  { label: 'HMP Guernsey', value: 'HMP Guernsey', group: 'South East' },

  {
    label: 'HMP Ashfield',
    value: 'HMP Ashfield',
    group: 'South West & Wales',
  },
  {
    label: 'HMP Bristol',
    value: 'HMP Bristol',
    group: 'South West & Wales',
  },
  {
    label: 'HMP Erlestoke',
    value: 'HMP Erlestoke',
    group: 'South West & Wales',
  },
  {
    label: 'HMP Portland',
    value: 'HMP Portland',
    group: 'South West & Wales',
  },
  {
    label: 'HMP Channings Wood',
    value: 'HMP Channings Wood',
    group: 'South West & Wales',
  },
  {
    label: 'HMP Winchester',
    value: 'HMP Winchester',
    group: 'South West & Wales',
  },
  {
    label: 'HMP Dartmoor',
    value: 'HMP Dartmoor',
    group: 'South West & Wales',
  },
  {
    label: 'HMP Eastwood Park',
    value: 'HMP Eastwood Park',
    group: 'South West & Wales',
  },
  {
    label: 'HMP Guys Marsh',
    value: 'HMP Guys Marsh',
    group: 'South West & Wales',
  },
  {
    label: 'HMP Leyhill',
    value: 'HMP Leyhill',
    group: 'South West & Wales',
  },
  { label: 'HMP Exeter', value: 'HMP Exeter', group: 'South West & Wales' },
  {
    label: 'HMP The Verne',
    value: 'HMP The Verne',
    group: 'South West & Wales',
  },
  {
    label: 'HMP Huntercombe',
    value: 'HMP Huntercombe',
    group: 'South West & Wales',
  },
  {
    label: 'HMP Aylesbury',
    value: 'HMP Aylesbury',
    group: 'South West & Wales',
  },
  {
    label: 'HMP Bullingdon',
    value: 'HMP Bullingdon',
    group: 'South West & Wales',
  },
  {
    label: 'HMP Grendon',
    value: 'HMP Grendon',
    group: 'South West & Wales',
  },
  {
    label: 'HMP Springhill',
    value: 'HMP Springhill',
    group: 'South West & Wales',
  },
  {
    label: 'HMP Cardiff',
    value: 'HMP Cardiff',
    group: 'South West & Wales',
  },
  { label: 'HMP Parc', value: 'HMP Parc', group: 'South West & Wales' },
  {
    label: 'HMP Prescoed',
    value: 'HMP Prescoed',
    group: 'South West & Wales',
  },
  {
    label: 'HMP Swansea',
    value: 'HMP Swansea',
    group: 'South West & Wales',
  },
  { label: 'HMP Usk', value: 'HMP Usk', group: 'South West & Wales' },
];

export const probationAreaTypes = [
  { label: 'Crawley', value: 'Crawley', group: 'West Sussex' },
  { label: 'Littlehampton', value: 'Littlehampton', group: 'West Sussex' },
  { label: 'Worthing', value: 'Worthing', group: 'West Sussex' },

  { label: 'Brighton', value: 'Brighton', group: 'East Sussex' },
  { label: 'Hastings', value: 'Hastings', group: 'East Sussex' },
  { label: 'Eastbourne', value: 'Eastbourne', group: 'East Sussex' },

  { label: 'Guildford', value: 'Guildford', group: 'Surrey' },
  { label: 'Staines', value: 'Staines', group: 'Surrey' },
  { label: 'Redhill', value: 'Redhill', group: 'Surrey' },

  { label: 'Tunbridge Wells', value: 'Tunbridge Wells', group: 'Kent' },
  { label: 'Chatam', value: 'Chatam', group: 'Kent' },
  { label: 'Ashford', value: 'Ashford', group: 'Kent' },
  { label: 'Canterbury', value: 'Canterbury', group: 'Kent' },
  { label: 'Gravesend', value: 'Gravesend', group: 'Kent' },
  { label: 'Maidstone', value: 'Maidstone', group: 'Kent' },
  { label: 'Ramsgate', value: 'Ramsgate', group: 'Kent' },
  { label: 'Folkstone', value: 'Folkstone', group: 'Kent' },
  { label: 'Sittingbourne', value: 'Sittingbourne', group: 'Kent' },
];

export const referralTypeOptions = [
  { label: 'PDU', value: 'PDU' },
  { label: 'CRS', value: 'CRS' },
  { label: 'Pathways', value: 'Pathways' },
  { label: 'Providers', value: 'Providers' },
];

export const ethnicityTypes = {
  WHITE: 'White',
  ENGLISH_WELSH_SCOTTISH_NORTHERN_IRISH_BRITISH:
    'English / Welsh / Scottish / Northern Irish / British',
  IRISH: 'Irish',
  GYPSY_OR_IRISH_TRAVELER: 'Gypsy or Irish Traveller',
  ANY_OTHER_WHITE_BACKGROUND: 'Any other White background',
  MIXED_MULTIPLE_ETHNIC_GROUPS: 'Mixed / Multiple ethnic groups',
  WHITE_AND_BLACK_CARIBBEAN: 'White and Black Caribbean',
  WHITE_AND_BLACK_AFRICAN: 'White and Black African',
  WHITE_AND_ASIAN: 'White and Asian',
  ANY_OTHER_MIXED_MULTIPLE_ETHNIC_BACKGROUND:
    'Any other mixed / Multiple ethnic background',
  ASIAN_ASIAN_BRITISH: 'Asian / Asian British',
  INDIAN: 'Indian',
  PAKISTANI: 'Pakistani',
  BANGLADESHI: 'Bangladeshi',
  CHINESE: 'Chinese',
  ANY_OTHER_ASIAN_BACKGROUND: 'Any other Asian background',
  BLACK_AFRICAN_CARIBBEAN_BLACK_BRITISH:
    'Black / African / Caribbean / Black British',
  AFRICAN: 'African',
  CARIBBEAN: 'Caribbean',
  ANY_OTHER_BLACK_AFRICAN_CARIBBEAN_BACKGROUND:
    'Any other Black / African / Caribbean background',
  ARAB: 'Arab',
  ANY_OTHER_ETHNIC_GROUP: 'Any other ethnic group',
  OTHER_ETHNIC_GROUPS: 'Other ethnic groups',
  PREFER_NOT_TO_SAY: 'Prefer not to say',
};

export const ethnicityTypesCategorised = [
  {
    label: ethnicityTypes.ENGLISH_WELSH_SCOTTISH_NORTHERN_IRISH_BRITISH,
    value: ethnicityTypes.ENGLISH_WELSH_SCOTTISH_NORTHERN_IRISH_BRITISH,
    group: ethnicityTypes.WHITE,
  },
  {
    label: ethnicityTypes.IRISH,
    value: ethnicityTypes.IRISH,
    group: ethnicityTypes.WHITE,
  },
  {
    label: ethnicityTypes.GYPSY_OR_IRISH_TRAVELER,
    value: ethnicityTypes.GYPSY_OR_IRISH_TRAVELER,
    group: ethnicityTypes.WHITE,
  },
  {
    label: ethnicityTypes.ANY_OTHER_WHITE_BACKGROUND,
    value: ethnicityTypes.ANY_OTHER_WHITE_BACKGROUND,
    group: ethnicityTypes.WHITE,
  },
  {
    label: ethnicityTypes.WHITE_AND_BLACK_CARIBBEAN,
    value: ethnicityTypes.WHITE_AND_BLACK_CARIBBEAN,
    group: ethnicityTypes.MIXED_MULTIPLE_ETHNIC_GROUPS,
  },
  {
    label: ethnicityTypes.WHITE_AND_BLACK_AFRICAN,
    value: ethnicityTypes.WHITE_AND_BLACK_AFRICAN,
    group: ethnicityTypes.MIXED_MULTIPLE_ETHNIC_GROUPS,
  },
  {
    label: ethnicityTypes.WHITE_AND_ASIAN,
    value: ethnicityTypes.WHITE_AND_ASIAN,
    group: ethnicityTypes.MIXED_MULTIPLE_ETHNIC_GROUPS,
  },
  {
    label: ethnicityTypes.ANY_OTHER_MIXED_MULTIPLE_ETHNIC_BACKGROUND,
    value: ethnicityTypes.ANY_OTHER_MIXED_MULTIPLE_ETHNIC_BACKGROUND,
    group: ethnicityTypes.MIXED_MULTIPLE_ETHNIC_GROUPS,
  },
  {
    label: ethnicityTypes.INDIAN,
    value: ethnicityTypes.INDIAN,
    group: ethnicityTypes.ASIAN_ASIAN_BRITISH,
  },
  {
    label: ethnicityTypes.PAKISTANI,
    value: ethnicityTypes.PAKISTANI,
    group: ethnicityTypes.ASIAN_ASIAN_BRITISH,
  },
  {
    label: ethnicityTypes.BANGLADESHI,
    value: ethnicityTypes.BANGLADESHI,
    group: ethnicityTypes.ASIAN_ASIAN_BRITISH,
  },
  {
    label: ethnicityTypes.CHINESE,
    value: ethnicityTypes.CHINESE,
    group: ethnicityTypes.ASIAN_ASIAN_BRITISH,
  },
  {
    label: ethnicityTypes.ANY_OTHER_ASIAN_BACKGROUND,
    value: ethnicityTypes.ANY_OTHER_ASIAN_BACKGROUND,
    group: ethnicityTypes.ASIAN_ASIAN_BRITISH,
  },
  {
    label: ethnicityTypes.AFRICAN,
    value: ethnicityTypes.AFRICAN,
    group: ethnicityTypes.BLACK_AFRICAN_CARIBBEAN_BLACK_BRITISH,
  },
  {
    label: ethnicityTypes.CARIBBEAN,
    value: ethnicityTypes.CARIBBEAN,
    group: ethnicityTypes.BLACK_AFRICAN_CARIBBEAN_BLACK_BRITISH,
  },
  {
    label: ethnicityTypes.ANY_OTHER_BLACK_AFRICAN_CARIBBEAN_BACKGROUND,
    value: ethnicityTypes.ANY_OTHER_BLACK_AFRICAN_CARIBBEAN_BACKGROUND,
    group: ethnicityTypes.BLACK_AFRICAN_CARIBBEAN_BLACK_BRITISH,
  },
  {
    label: ethnicityTypes.ARAB,
    value: ethnicityTypes.ARAB,
    group: ethnicityTypes.OTHER_ETHNIC_GROUPS,
  },
  {
    label: ethnicityTypes.PREFER_NOT_TO_SAY,
    value: ethnicityTypes.PREFER_NOT_TO_SAY,
    group: ethnicityTypes.OTHER_ETHNIC_GROUPS,
  },
];

export const genderTypes = {
  FEMALE: 'Female',
  MALE: 'Male',
  TRANS: 'Trans',
  NON_BINARY: 'Non-binary',
  PREFER_NOT_TO_SAY: 'Prefer not to say',
  LET_ME_TYPE: 'Let me type',
};

export const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  },
];

export const comprehensionsKeys = [
  'M5_P164&166',
  'M5_P168',
  'M5_P170',
  'M5_P172',
  'M5_P174',
  'M5_P176',
  'M5_P178',
  'M5_P180',
  'M5_P182',
  'M5_P184',
  'M5_P188&190',
];

// used as queryStrings when getting coach data
// relates to approval statuses of coach
// ALL -> all statuses without rejected ones
// NEW -> requested and interview
export const coachStatusesQuery = {
  ALL: 'ALL',
  NEW: 'NEW',
  ONBOARDED: 'ONBOARDED',
  REJECTED: 'REJECTED',
};

export const coachApprovalStatuses = {
  APPROVED: 'APPROVED',
  INVITED_TO_INTERVIEW: 'INVITED_TO_INTERVIEW',
  REQUESTED: 'REQUESTED',
  REJECTED: 'REJECTED',
};

export const referralStatuses = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
};

export const ageGroups = [
  '18 to 24',
  '25 to 34',
  '35 to 44',
  '45 to 54',
  '55 to 64',
  '65+',
];

export const coachTypes = {
  PEER_COACH: 'PEER_COACH',
  COACH: 'COACH',
  PROFESSIONAL_COACH: 'PROFESSIONAL_COACH',
};

export const facilitatorDetailsPublic = {
  fullName: 'Chloe Bradbury',
  phoneNumber: '020 3763 8118',
};

export const feedbackScores = {
  low: 1,
  medium: 2,
  high: 3,
};

export const paymentPlans = [
  { value: 'monthly', label: 'Monthly' },
  { value: 'yearly', label: 'Yearly' },
];

export const chatMessagesTypes = {
  AUDIO: 'AUDIO',
  TEXT: 'TEXT',
};

export const chatMessagesStatuses = {
  SENT: 'SENT',
  SEEN: 'SEEN',
  DELETED: 'DELETED',
};

export const preferredSessionsTypes = {
  IN_PERSON: 'IN_PERSON',
  ONLINE: 'ONLINE',
  EITHER: 'EITHER',
};

export const courseCodeTypes = {
  TPD: 'TPD',
  CMID: 'CMID',
};

export const coursesNames = {
  TPD: 'Turning Pages',
  CMID: 'Count Me In',
};
