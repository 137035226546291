import styled from '@emotion/styled';

export const SelectButton = styled.button`
  color: ${({ theme }) => theme.colors['primaryMain']};
  font-size: 1rem;
  line-height: 24px;
  font-weight: bold;
  text-decoration: underline !important;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.primaryMain90};
    transition: 0.2s ease;
  }

  :focus {
    outline-style: solid;
    outline-width: 3px;
    outline-color: ${({ theme }) => theme.colors.primaryMain};
    outline-offset: 0.25em;
  }
`;

export const EditButton = styled.button`
  color: ${({ theme }) => theme.colors['primaryMain']};
  font-size: 1rem;
  display: flex;
  align-items: center;
  line-height: 24px;
  font-weight: bold;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.primaryMain90};
    transition: 0.2s ease;
  }

  :focus {
    outline-style: solid;
    outline-width: 3px;
    outline-color: ${({ theme }) => theme.colors.primaryMain};
    outline-offset: 0.25em;
  }
`;

export const MicButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  min-width: 56px;
  min-height: 56px;
  position: relative;
  border: none;
  border-radius: 16px;
  font-size: 1rem;
  font-weight: bold;
  padding: 0;

  box-shadow: ${({ theme }) => theme.shadows.elevation1};

  transition: background-color 300ms linear, color 300ms linear;

  background: ${({ theme }) => theme.colors.neutralMain};
`;

export const VoStatus = styled.div`
  width: fit-content;
  background: ${({ bgColor }) => bgColor};
  padding-left: ${({ theme }) => theme.spacings[3]};
  padding-right: ${({ theme }) => theme.spacings[3]};
  padding-top: ${({ theme }) => theme.spacings[1]};
  padding-bottom: ${({ theme }) => theme.spacings[1]};
  border-radius: 24px;
`;
