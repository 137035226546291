const getCountText = (
  totalCount,
  searchCount,
  shouldShowSearchCount = true,
  isLoading = false
) => {
  if (totalCount === 0) {
    return 0;
  }

  if (isLoading) {
    return totalCount;
  }

  if (shouldShowSearchCount) {
    return `${searchCount} of ${totalCount}`;
  }

  return totalCount;
};

export default getCountText;
