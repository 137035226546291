import { useMemo, useRef } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput } from '../../../../components/Inputs';

import * as S from './style';
import { Icon } from '../../../../components';
import { string, array, object, number } from 'yup';
import { MediaKeyInput } from '../../../components/MediaKeyInput';
import { mediaInputLabels } from 'constants';
import { mediaTypes } from 'constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';

const initialData = {
  options: [{ id: 0, option: '' }],
  correctAnswer: '',
  audioKey: '',
};

const DragAndDrop1 = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateFormFields, updateDataFields } = useStepForm(
    initialRef.current
  );
  const { form, data, validationErrs } = state;

  const options = useMemo(
    () =>
      data?.options?.map((e, i) => ({ ...e, id: i })) || initialData.options,
    [data?.options]
  );

  const add = () => {
    const maxId = Math.max(...options.map((e) => e.id), 0);
    updateDataFields({
      options: [...options, { id: maxId + 1, option: '' }],
    });
  };
  const remove = (id) => {
    updateDataFields({
      options: options.filter((e) => e.id !== id),
    });
  };
  const onOptionChange = (value, id) => {
    updateDataFields({
      options: options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  return (
    <div>
      <MediaKeyInput
        label={mediaInputLabels.AUDIO_LABEL}
        type={mediaTypes.AUDIO}
        value={form.audioKey}
        handleChange={(audioKey) => updateFormFields({ audioKey })}
        m={{ mt: 5 }}
        error={validationErrs.audioKey}
      />

      <BasicInput
        label="Correct Answer"
        value={data.correctAnswer}
        handleChange={(correctAnswer) => updateDataFields({ correctAnswer })}
        m={{ mt: 5 }}
        error={validationErrs.correctAnswer}
      />

      <T.P mb={2} mt={8} size="large" weight="bold">
        Options
      </T.P>

      {validationErrs?.options &&
        typeof validationErrs.options === 'string' && (
          <T.P mb={2} mt={2} color="error">
            {validationErrs.options}
          </T.P>
        )}
      {options.map((e, i) => {
        return (
          <>
            <BasicInput
              label={`Option ${i + 1}`}
              value={e.option}
              handleChange={(value) => {
                onOptionChange(value, e.id);
              }}
              m={{ mb: 2, mt: 5 }}
              error={
                validationErrs?.options && validationErrs?.options[i]?.option
              }
            />
            {options.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => remove(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}

      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={add}
        disabled={options.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Option
      </S.Button>
    </div>
  );
};

const validationSchema = {
  options: array()
    .of(
      object()
        .shape({
          id: number().notRequired(),
          option: string().required('required field'),
        })
        .required()
    )
    .required()
    .min(1),
  correctAnswer: string().required(),
};
export { validationSchema };

export default DragAndDrop1;
