import { string, array, object, number, boolean } from 'yup';

export const validationSchema = {
  textAudio: string().required('required field'),
  textAudioKey: string().required('required field'),
  rowSize: number()
    .typeError('required field')
    .min(1, 'Row size must be 1 or more')
    .required('required field'),
  columnSize: number()
    .typeError('required field')
    .min(1, 'columnSize must be between 1 and 3')
    .max(3, 'columnSize must be between 1 and 3')
    .required('required field'),
  headers: array().of(string()),
  options: array().of(
    object().shape({
      id: number().required('required field'),
      option: string().required('required field'),
      showValue: boolean().required('required field'),
    })
  ),
};
