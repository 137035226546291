import * as T from '../../../components/Typography';
import * as S from './style';
import { Button } from 'components';
import { courseCodeTypes } from 'constants';
import { Users } from 'api-calls';
import { useState } from 'react';
import { useSiteSettings } from 'context/site-settings';
import { useNavigate } from 'react-router-dom';
import { navRoutes } from 'constants';

const OrganisationWelcome = () => {
  const navigate = useNavigate();
  const { courseCode } = useSiteSettings();
  const [error, setError] = useState('');
  const { mutate: switchCourse, isLoading } = Users.useSwitchToCourse();

  const submit = (course) => {
    setError('');
    if (courseCode === course) {
      navigate(navRoutes.ORGANISATION_ADMIN.DASHBOARD, {
        state: { showOnboarding: true },
      });
      return;
    }
    switchCourse(
      { courseCode: course },
      {
        onSuccess: (data) => {
          if (data?.redirectUrl) {
            window.location.href = `${data.redirectUrl}&showOnboarding=true`;
          } else {
            setError('Something went wrong');
          }
        },
        onError: (error) => {
          setError(error.message);
        },
      }
    );
  };

  return (
    <S.Wrapper>
      <S.TitleWrapper>
        <T.H1 ta="center">Welcome!</T.H1>
        <T.P ta="center">
          Welcome organisations have been added to both courses, which course
          you want to view
        </T.P>
      </S.TitleWrapper>
      <S.ButtonWrapper>
        <Button.BigButton
          variant="custom"
          icon="openedBook"
          text="Turning Pages"
          handleClick={() => submit(courseCodeTypes.TPD)}
          loading={isLoading && courseCode !== courseCodeTypes.TPD}
          disabled={isLoading}
        />

        <Button.BigButton
          variant="custom"
          icon="cmidIcon"
          text="Count Me In"
          handleClick={() => submit(courseCodeTypes.CMID)}
          loading={isLoading && courseCode !== courseCodeTypes.CMID}
          disabled={isLoading}
        />
      </S.ButtonWrapper>
      <S.ErrorWrapper>
        <T.P color="error">{error}</T.P>
      </S.ErrorWrapper>
    </S.Wrapper>
  );
};
export default OrganisationWelcome;
