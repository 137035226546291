import theme from '../../../theme';

const MilestoneNew = ({ width, height, color, ...props }) => (
  <svg
    width={width || '97'}
    height={height || '97'}
    viewBox="0 0 97 97"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M57.786 27.3765L57.3076 27.5047L57.4312 27.9842L58.6025 32.5309L58.6024 32.5309L58.605 32.5402L61.1381 41.6447C62.2327 45.5788 59.9118 49.6661 55.9408 50.7709C55.9413 50.7708 55.9384 50.7716 55.9312 50.7735L55.8976 50.7825L55.7623 50.8188L55.2077 50.9675L52.8385 51.6024L42.0612 54.4902L41.5783 54.6196L41.7077 55.1026L42.9236 59.6406C43.2217 60.753 44.3686 61.4068 45.4806 61.1088L76.1942 52.8791C77.0432 52.6517 77.8091 52.186 78.4017 51.5376C80.1137 49.6644 79.9875 46.7618 78.1262 45.0429L71.7002 38.881L74.4863 30.2417L74.4877 30.2373C74.7521 29.3906 74.7706 28.5005 74.5445 27.6566C73.887 25.2028 71.3642 23.7382 68.9024 24.3979L57.786 27.3765Z"
      fill={theme.colors.neutral40}
      stroke={theme.colors.neutral40}
    />
    <path
      d="M54.6278 23.8363L54.6273 23.8348C53.8053 20.767 50.6409 18.9604 47.5688 19.7836L21.449 26.7824C19.2298 27.377 17.9128 29.6581 18.5075 31.8773L24.6415 54.7697C25.0118 56.1517 26.4349 56.9602 27.8108 56.5915C46.2446 51.6522 55.4789 49.1778 55.4986 49.1724L55.5001 49.172C58.5644 48.3198 60.3715 45.1558 59.5202 42.096L56.9896 33.0007C55.4215 26.9159 54.633 23.856 54.6278 23.8363Z"
      fill={color}
      stroke={theme.colors.secondaryMain}
      strokeWidth="2"
    />
    <path
      d="M35.9252 83.8602C37.723 83.3785 38.7899 81.5306 38.3082 79.7328L24.8133 29.3694C24.3316 27.5716 22.4837 26.5048 20.6859 26.9865C18.8882 27.4682 17.8213 29.3161 18.303 31.1139L31.7978 81.4772C32.2795 83.275 34.1274 84.3419 35.9252 83.8602Z"
      fill={color}
      stroke="#294A79"
      strokeWidth="2"
    />
  </svg>
);

export default MilestoneNew;
