import { Button, Typography as T, Video } from 'components';
import * as S from './style';
import { getMediaUrl } from 'helpers';
import { Col } from 'components/Grid';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { navRoutes as R, audio, courseCodeTypes } from '../../../constants';
import { useGeneralState } from '../../../context/general-state';
import { useMediaQuery } from 'react-responsive';
import theme from 'theme';
import { useState } from 'react';
import { useAuth } from 'context/auth';
import Redirect from 'components/Route/Redirect';
import { useSiteSettings } from 'context/site-settings';
import LearnerSwitchCourseImg from '../../../components/assets/learner-switch-course.png';

const SelfSignupWelcome = () => {
  const navigate = useNavigate();
  const handleStartExercises = () => {
    navigate(R.LEARNER.DASHBOARD);
  };
  const { state: generalState } = useGeneralState();
  const [searchParams] = useSearchParams();
  const signupWithExistingAccount =
    searchParams.get('signupWithExistingAccount') === 'true';
  const { courseName, courseCode } = useSiteSettings();
  const [step, setStep] = useState(signupWithExistingAccount ? 0 : 1);
  const { user } = useAuth();

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.mobile})`,
  });

  if (!user?.isSelfSignedUp) {
    return <Redirect to={R.GENERAL.NOT_FOUND} />;
  }

  const next = () => {
    setStep((PrevStep) => {
      const nextStep = PrevStep + 1;
      return nextStep > 2 ? 1 : nextStep;
    });
  };
  return (
    <S.Wrapper>
      {step === 0 && (
        <>
          <S.PlayButtonWrapper>
            <Button.PlayButton
              width="56px"
              height="56px"
              audioUrl={getMediaUrl(null, true, generalState?.preferredVoice)}
              iconProps={{ width: 27.2 }}
              backgroundColor="neutralSurface"
              fallbackText={`Welcome to ${courseName}. Now you can access both Count Me In and Turning Pages with your
              Learner I.D.`}
            />
            <S.MultiLineTitleWrapper ml="16px">
              <T.H2 size={isMobile ? 'regular' : 'large'}>
                Welcome to {courseName}!
              </T.H2>
              <T.P color="neutral90">
                Now you can access both Count Me In and Turning Pages with your
                Learner ID.
              </T.P>
            </S.MultiLineTitleWrapper>
          </S.PlayButtonWrapper>
          <S.PlayButtonWrapper mt="48px">
            <Button.PlayButton
              width="32px"
              height="32px"
              audioUrl={getMediaUrl(
                audio.welcomeToTurningPagesSelfSignupSwitchCourseInstructions,
                true,
                generalState?.preferredVoice
              )}
              iconProps={{ width: 27.2 }}
              backgroundColor="transparent"
              withoutButtonStyle
              fallbackText={`To switch course, click the hamburger menu on the top right corner and then select the button below`}
            />
            <T.P ml="8px" weight="700" maxWidth="668px">
              To switch course, click the hamburger menu on the top right corner
              and then select the button below.
            </T.P>
          </S.PlayButtonWrapper>
          <S.CoverWrapper>
            <S.TabImage src={LearnerSwitchCourseImg} />
          </S.CoverWrapper>
          <S.PlayButtonWrapper mt="auto">
            <Button.BasicButton
              variant="primary"
              iconProps={{ width: '23px', height: '23px' }}
              handleClick={next}
              showSound
              width="100%"
              audioUrl={getMediaUrl(
                audio.next,
                true,
                generalState?.preferredVoice
              )}
              fallbackText={'Next'}
            >
              <T.P weight="bold" color="white">
                Next
              </T.P>
            </Button.BasicButton>
          </S.PlayButtonWrapper>
        </>
      )}
      {step === 1 && (
        <>
          <S.PlayButtonWrapper>
            <Button.PlayButton
              width="56px"
              height="56px"
              audioUrl={getMediaUrl(
                audio.welcomeToTurningPagesSelfSignup,
                true,
                generalState?.preferredVoice
              )}
              iconProps={{ width: 27.2 }}
              backgroundColor="neutralSurface"
              fallbackText={`Welcome to ${courseName}`}
            />
            <T.H2 ml="4" size={isMobile ? 'regular' : 'large'}>
              Welcome to {courseName} 🎉
            </T.H2>
          </S.PlayButtonWrapper>

          <S.PlayButtonWrapper mt="4">
            <Button.PlayButton
              width="32px"
              height="32px"
              audioUrl={getMediaUrl(
                audio.welcomeToTurningPagesSelfSignupDescription,
                true,
                generalState?.preferredVoice
              )}
              iconProps={{ width: 27.2 }}
              backgroundColor="transparent"
              withoutButtonStyle
              fallbackText={`You will be contacted when you are matched with a coach, but you can
          get started right now! Watch the welcome video below to learn how to
          use this tool..`}
            />
            <T.P ml="2" maxWidth="668px">
              You will be contacted when you are matched with a coach, but you
              can get started right now! Watch the welcome video below to learn
              how to use this tool.
            </T.P>
          </S.PlayButtonWrapper>

          <Col w={[4, 12, 12]} mt="5">
            <Video
              src={
                courseCode === courseCodeTypes.TPD
                  ? 'https://www.youtube.com/embed/3ecB5090QCc?si=RvaTAb88BF8uWLhY'
                  : 'https://www.youtube.com/embed/l-l8I1SHjg0?si=wQD-10nXOIyV4AxC'
              }
            />
          </Col>

          <S.PlayButtonWrapper mt="6">
            <Button.BasicButton
              variant="primary"
              iconProps={{ width: '23px', height: '23px' }}
              handleClick={next}
              showSound
              width="100%"
              audioUrl={getMediaUrl(
                audio.next,
                true,
                generalState?.preferredVoice
              )}
              fallbackText={'Next'}
            >
              <T.P weight="bold" color="white">
                Next
              </T.P>
            </Button.BasicButton>
          </S.PlayButtonWrapper>
        </>
      )}
      {step === 2 && (
        <>
          <S.PlayButtonWrapper mt="4">
            <Button.PlayButton
              width="32px"
              height="32px"
              audioUrl={getMediaUrl(
                audio.thisIsYourLearnerIdDescription,
                true,
                generalState?.preferredVoice
              )}
              iconProps={{ width: 27.2 }}
              backgroundColor="transparent"
              withoutButtonStyle
              fallbackText={`This is your new Learner I D for when you want to log in. Please keep it safe.`}
            />
            <T.P ml="2" maxWidth="428px" weight="bold">
              This is your new Learner ID for when you want to log in. Please
              keep it safe.
            </T.P>
          </S.PlayButtonWrapper>

          <Col w={[4, 12, 12]} mt="5">
            <S.CircleDiv>{user.learnerUniqueId}</S.CircleDiv>
          </Col>

          <S.PlayButtonWrapper mt="6">
            <Button.BasicButton
              variant="primary"
              iconProps={{ width: '23px', height: '23px' }}
              handleClick={handleStartExercises}
              showSound
              width="100%"
              audioUrl={getMediaUrl(
                audio.startExercises,
                true,
                generalState?.preferredVoice
              )}
              fallbackText={'Start exercises'}
            >
              <T.P weight="bold" color="white">
                Start exercises
              </T.P>
            </Button.BasicButton>
          </S.PlayButtonWrapper>
        </>
      )}
    </S.Wrapper>
  );
};

export default SelfSignupWelcome;
