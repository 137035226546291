import { useState } from 'react';
import { format } from 'date-fns';
import { Button, LetterAvatar } from 'components';
import * as T from 'components/Typography';
import * as S from './style';

const TextMsg = ({
  id,
  sender,
  content,
  userType,
  sentAt,
  setMsgIdToReport,
  reported,
}) => {
  const isUserMsg = userType === sender.role;
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => {
    setIsExpanded((prev) => !prev);
  };

  const maxLength = 215;
  const showReadMore = content?.length > maxLength;
  return (
    <S.MsgWrapper key={id} isUserMsg={isUserMsg}>
      <LetterAvatar name={sender.name} />
      <S.TextMsg isUserMsg={isUserMsg}>
        <T.Pre>
          {showReadMore && !isExpanded
            ? content.slice(0, maxLength - 12)
            : content}
          {showReadMore && (
            <S.ReadMoreButton onClick={toggleReadMore}>
              {isExpanded ? ' Read less' : '...Read more'}
            </S.ReadMoreButton>
          )}
        </T.Pre>
        <T.P color="neutral60" ta="right" mt="4px">
          {sentAt ? format(new Date(sentAt), 'dd MMM yyyy h:mm a') : ''}
        </T.P>
      </S.TextMsg>
      {!isUserMsg && !reported && (
        <Button.ReportBtn id="reportBtn" onClick={() => setMsgIdToReport(id)} />
      )}
    </S.MsgWrapper>
  );
};

export default TextMsg;
