import theme from '../../../theme';

const ArrowDropDown = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={height || '25'}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 10.5L12 15.5L17 10.5H7Z"
      fill={color || theme.color.black}
      fill-opacity="0.54"
    />
  </svg>
);

export default ArrowDropDown;
