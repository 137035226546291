import { Col, Row } from '../../../../components/Grid';
import { Typography as T } from '../../../../components';
import { BasicInput, Dropdown } from '../../../../components/Inputs';
import { BasicButton } from '../../../../components/Button';

const StepForm = ({
  setFormData,
  form,
  validationErrs,
  httpError,
  loading,
  handleSubmit,
  handleDelete = () => {},
  formType = 'add',
  orderDropdownOptions = [],
}) => {
  const selectedOrder =
    orderDropdownOptions.find((option) => option.value === form.order) ||
    undefined;

  return (
    <div style={{ maxWidth: '636px' }}>
      <Row>
        <Col w={[4, 11, 12]} mt="5">
          <T.H1 weight="bold" color="neutralMain">
            {formType === 'add' ? 'Add resource' : 'Edit resource'}
          </T.H1>
        </Col>
        <Col w={[4, 11, 12]} mt="5">
          <BasicInput
            label="Title"
            value={form.title}
            name="title"
            handleChange={(title) => setFormData({ title })}
            error={validationErrs.title}
            placeholder="Title name..."
          />
        </Col>
        <Col w={[4, 11, 12]} mt="5">
          <BasicInput
            label="External link"
            placeholder="External link..."
            value={form.externalLink}
            name="externalLink"
            handleChange={(externalLink) => setFormData({ externalLink })}
            error={validationErrs.externalLink}
          />
        </Col>
        <Col w={[4, 11, 12]} mt="5">
          <Dropdown
            weight="regular"
            label="Order"
            placeholder="Select ..."
            options={orderDropdownOptions}
            selected={selectedOrder}
            handleChange={(order) => setFormData({ order })}
            error={validationErrs.order}
          />

          <T.P mt={4}>
            This lets you select which order your would like your custom
            resources to be completed
          </T.P>
        </Col>
        <Col w={[4, 11, 10]} mt="2">
          {httpError && (
            <T.P mb="2" color="error">
              {httpError}
            </T.P>
          )}

          {validationErrs.hasError && (
            <Row>
              <Col w={[4, 10, 12]}>
                <T.P color="error" mb={3}>
                  There is an error in your form. Please correct before
                  submitting.
                </T.P>
              </Col>
            </Row>
          )}
        </Col>
        <Col w={[4, 11, 6]}>
          <BasicButton
            mt={6}
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            handleClick={handleSubmit}
          >
            <T.P color="white" weight="semi">
              Save changes
            </T.P>
          </BasicButton>

          {formType === 'edit' && (
            <BasicButton
              variant="secondary"
              disabled={loading}
              loading={loading}
              bgColor="error"
              color="white"
              type="submit"
              handleClick={handleDelete}
              mt={6}
            >
              Delete
            </BasicButton>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default StepForm;
