const Face3 = ({ width, height, color, ...props }) => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.05994 16.0025C3.05994 23.4252 9.07723 29.4425 16.4999 29.4425C23.9226 29.4425 29.9399 23.4252 29.9399 16.0025C29.9399 8.57979 23.9226 2.5625 16.4999 2.5625C9.07723 2.5625 3.05994 8.57979 3.05994 16.0025ZM11.3799 20.4825H21.616C21.9694 20.4825 22.256 20.769 22.256 21.1225C22.256 21.476 21.9694 21.7625 21.616 21.7625H11.3799C11.0265 21.7625 10.7399 21.476 10.7399 21.1225C10.7399 20.769 11.0265 20.4825 11.3799 20.4825ZM13.2999 14.0825C14.0069 14.0825 14.5799 13.5094 14.5799 12.8025C14.5799 12.0956 14.0069 11.5225 13.2999 11.5225C12.593 11.5225 12.0199 12.0956 12.0199 12.8025C12.0199 13.5094 12.593 14.0825 13.2999 14.0825ZM20.9799 12.8025C20.9799 13.5094 20.4069 14.0825 19.6999 14.0825C18.993 14.0825 18.4199 13.5094 18.4199 12.8025C18.4199 12.0956 18.993 11.5225 19.6999 11.5225C20.4069 11.5225 20.9799 12.0956 20.9799 12.8025Z"
      fill="#3B3C42"
    />
  </svg>
);

export default Face3;
