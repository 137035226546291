import { typingHorizontalVariations } from '../../../../../constants';

export const generateInitialCorrectOptions = (
  variation,
  existingCorrectOptions
) => {
  let correctOptions = existingCorrectOptions;
  let defaultCorrectOption = {
    id: 0,
    option: '',
    type: '',
    suffix: '',
    prefix: '',
  };
  switch (variation) {
    case typingHorizontalVariations.variations.ONE_ANSWER:
      if (existingCorrectOptions.length === 0) {
        correctOptions = [{ ...defaultCorrectOption, id: 0 }];
      } else {
        correctOptions = [existingCorrectOptions[0]];
      }
      break;
    case typingHorizontalVariations.variations.TWO_ANSWERS:
      if (existingCorrectOptions.length === 0) {
        correctOptions = [
          { ...defaultCorrectOption, id: 0 },
          { ...defaultCorrectOption, id: 1 },
        ];
      } else {
        correctOptions = [
          existingCorrectOptions[0],
          existingCorrectOptions[1] || { ...defaultCorrectOption, id: 1 },
        ];
      }
      break;

    default:
      correctOptions = [];
      break;
  }

  return correctOptions;
};
