import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import * as S from './style';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import { Textarea } from '../../../components/Inputs';
import { BasicButton } from '../../../components/Button';
import { useFacilitatorUpdateCoachNote } from '../../../api-calls/facilitator.queries';
import validate from '../../../validation/schemas/add-coach-note';

const EditNote = () => {
  const navigate = useNavigate();

  const {
    state: { note, noteId, coachId },
  } = useLocation();
  const [updatedNote, setUpdatedNote] = useState(note);
  const [noteValidationError, setNoteValidationError] = useState('');
  const {
    mutateAsync: updateNote,
    error: updateNoteHttpError,
    isLoading,
  } = useFacilitatorUpdateCoachNote({ coachId });
  const submitAttempt = useRef(false);

  const validateNote = () => {
    try {
      validate({ note: updatedNote });
      setNoteValidationError(false);
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setNoteValidationError(error?.inner?.note);
      }
      return false;
    }
  };
  const handleNoteSubmission = async (e) => {
    e.preventDefault();
    submitAttempt.current = true;
    const isValid = validateNote();
    if (isValid) {
      await updateNote({ noteId, note: updatedNote });
      navigate(-1);
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedNote]);

  return (
    <S.Wrapper>
      <Row>
        <Col w={[4, 12, 12]} mb={4}>
          <T.H1>Edit note</T.H1>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 9]} mb={4} mt={4}>
          <Textarea
            value={updatedNote}
            handleChange={(note) => setUpdatedNote(note)}
            error={noteValidationError}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 4]} mb={4}>
          <BasicButton
            variant="primary"
            type="submit"
            onClick={handleNoteSubmission}
            loading={isLoading}
          >
            Submit
          </BasicButton>
        </Col>
      </Row>
      {updateNoteHttpError ? (
        <T.P color="error">{updateNoteHttpError?.message}</T.P>
      ) : null}
    </S.Wrapper>
  );
};
export default EditNote;
