import styled from '@emotion/styled';
import { setMargin } from 'helpers';

export const Divider = styled.div`
  ${setMargin}
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.neutral40};
  ${({ direction }) =>
    direction === 'vertical' &&
    `height: auto;
    width: 1px;
    min-width: 1px;
    max-width: 1px;`}

  ${({ direction }) => direction === 'horizontal' && 'margin-block: 24px;'}
  position: relative;

  &:after {
    content: 'OR';
    position: absolute;
    margin: 0 auto;
    left: 47%;
    right: auto;
    top: -10px;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.neutral60};
    padding: 0 8px;
    display: ${({ showText }) => (showText ? 'block' : 'none')};
  }
`;
