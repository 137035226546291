import globalStyle from './global-style';
import colors from './colors';
import borders from './borders';
import shadows from './shadows';

const constants = {
  columns: { mobile: 4, tablet: 12, desktop: 12 },
  gridGutter: { mobile: 12, tablet: 12, desktop: 12 },
  // side menu width
  // header height
  // ...
  layout: {
    header: {
      height: '112px',
      mobileHeight: '80px',
      progressHeight: '80px',
    },
    footer: {
      desktopTabletHeight: '200px',
      mobileHeight: '162px',
    },
    side: {
      top: {
        mobile: '32px',
        tablet: '4vw',
        desktop: '48px',
      },
      leftPadding: {
        mobile: '16px',
        tablet: '4vw',
        desktop: 'min(120px, 8vw)',
      },
    },
    content: {
      alignment: {
        center: 'center',
        start: 'start',
        end: 'end',
        stretch: 'stretch',
      },
    },
    calculateHeight(type) {
      switch (type) {
        case 'tabletWithProgressBar':
          return (
            Number(this.header.progressHeight.slice(0, -2)) +
            Number(this.footer.desktopTabletHeight.slice(0, -2)) +
            'px'
          );
        case 'mobileWithProgressBar':
          return (
            Number(this.header.progressHeight.slice(0, -2)) +
            Number(this.footer.mobileHeight.slice(0, -2)) +
            'px'
          );
        case 'tabletWithoutProgressBar':
          return this.footer.desktopTabletHeight;
        case 'mobileWithoutProgressBar':
          return this.footer.mobileHeight;
        default:
      }
    },
    calculateMargin(type) {
      switch (type) {
        case 'progressBar':
          return (
            Number(this.header.progressHeight.slice(0, -2)) +
            Number(spacings[7].slice(0, -2)) -
            130 +
            'px'
          );
        case 'noProgressBar':
          return Number(this.header.height.slice(0, -2)) - 54 + 'px';
        case 'progressBarMobile':
          return (
            Number(this.header.progressHeight.slice(0, -2)) +
            Number(spacings[5].slice(0, -2)) -
            130 +
            'px'
          );
        case 'noProgressBarMobile':
          return Number(this.header.height.slice(0, -2)) - 78 + 'px';
        default:
      }
    },
  },
};

export const spacings = {
  0: '0px',
  1: '4px',
  2: '8px',
  3: '12px',
  4: '16px',
  5: '24px',
  6: '32px',
  7: '48px',
  8: '64px',
  9: '80px',
  10: '96px',
  11: '120px',
};

export const screensWidth = {
  mobileXS: 374,
  mobile: 580,
  tablet: 949,
  desktop: 1442,
  all: 5000,
};

export const maxWidths = {
  desktop: '1560px',
};

export const media = {
  mobileXS: `@media (max-width: ${screensWidth.mobileXS}px)`,
  mobile: `@media (max-width: ${screensWidth.mobile}px)`,
  tablet: `@media (max-width: ${screensWidth.tablet}px)`,
  desktop: `@media (max-width: ${screensWidth.desktop}px)`,
  all: `@media (max-width: ${screensWidth.all}px)`,
};

export const breakpoints = {
  mobileXS: `${screensWidth.mobileXS}px`,
  mobile: `${screensWidth.mobile}px`,
  tablet: `${screensWidth.tablet}px`,
  desktop: `${screensWidth.desktop}px`,
};
const fontSizes = {
  0: 12,
  1: 14,
  2: 16,
  3: 18,
  4: 20,
  5: 24,
  6: 32,
  7: 48,
  8: 64,
  9: 80,
  10: 96,
  11: 120,
};
const theme = {
  name: 'default', // for storybook
  colors,
  spacings,
  screensWidth,
  media,
  breakpoints,
  constants,
  borders,
  shadows,
  maxWidths,
  fontSizes,
};

export { globalStyle };
export default theme;
