import React from 'react';

const RightChevron = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '8'}
    height={height || '14'}
    fill="none"
    viewBox="0 0 8 14"
  >
    <path
      fill={color || 'currentColor'}
      d="M7.54 6.29 1.88.64A1 1 0 1 0 .46 2.05l4.95 5L.46 12a1 1 0 0 0 0 1.41 1 1 0 0 0 .71.3.999.999 0 0 0 .71-.3l5.66-5.65a1 1 0 0 0 0-1.47Z"
    />
  </svg>
);

export default RightChevron;
