import { useEffect, useState } from 'react';

import * as T from '../../../../components/Typography';
import { PlayButton } from '../../../../components/Button';

import EmptySpace from '../../../../components/EmptySpace';
import Option from '../../../../components/Option';
import StepFooterButtons from '../StepFooterButtons';
import { getMediaUrl } from '../../../../helpers';
import { useGeneralState } from '../../../../context/general-state';

import * as S from './style';
import { showMovingBlock } from '../../../../helpers';
import { audio } from '../../../../constants';

const VowelBreak = ({
  data: { textAudioUrl, textAudio, correctAnswer = '', questionText = '' } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
}) => {
  const [answers, setAnswers] = useState(
    questionText?.split('').map((e, i) => ({ id: i, text: e }))
  );
  const [targets, setTargets] = useState({ from: null, to: null });
  const [showRotateMsg, setShowRotateMsg] = useState(false);

  const { state } = useGeneralState();

  const onClickAnswer = (id, e) => {
    if (targets.from === null) {
      return setTargets({ from: id, to: null });
    }
    if (targets.from !== null && targets.to !== null) {
      return setTargets({ from: id, to: null });
    }
    if (targets.from === id) {
      return setTargets({ from: null, to: null });
    }
    if (targets.from !== null && targets.to === null) {
      showMovingBlock({
        OptionElId: `answer-${id}`,
        answerElId: `answer-${targets.from}`,
        movingElId: 'moving-div-2',
        isBeingSelected: false,
      });
      showMovingBlock({
        OptionElId: `answer-${id}`,
        answerElId: `answer-${targets.from}`,
        movingElId: 'moving-div',
        isBeingSelected: true,
        done: () => {
          setAnswers((_answers) => {
            const from = _answers.find((a) => a.id === targets.from);
            const to = _answers.find((a) => a.id === id);

            return _answers.map((answer) => {
              if (answer.id === to.id) {
                return from;
              } else if (answer.id === from.id) {
                return to;
              }
              return answer;
            });
          });
        },
      });

      e.target.blur();

      return setTargets({ from: null, to: null });
    }
  };

  useEffect(() => {
    const ro = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setShowRotateMsg(
          entry.contentRect.height > entry.contentRect.width &&
            entry.contentRect.width < 790
        );
      }
    });
    ro.observe(document.body);
  }, []);

  const cleanAnswer = answers.map((e) => e.text).join('');

  return (
    <>
      <S.Wrapper display={showRotateMsg ? 'none' : 'flex'}>
        {/* keep this to animation */}
        <div style={{ display: 'none' }}>
          <Option id="moving-div"></Option>
          <Option id="moving-div-2"></Option>
        </div>
        {textAudioUrl ? (
          <PlayButton
            audioUrl={textAudioUrl}
            iconProps={{ width: '32px', height: '32px' }}
            padding="12px"
            weight="regular"
            mx="auto"
            mxM="0"
          >
            {textAudio}
          </PlayButton>
        ) : (
          <T.P size="med">{textAudio}</T.P>
        )}

        <S.Row>
          {answers.map((answer) => (
            <EmptySpace
              onClick={(e) => onClickAnswer(answer?.id, e)}
              id={`answer-${answer?.id}`}
              bgColor={'rgba(255, 255, 255, 0.5)'}
              key={answer?.id}
              isActive={targets.from === answer.id || targets.to === answer.id}
            >
              {answer?.text}
            </EmptySpace>
          ))}
        </S.Row>
        {(!questionText || !correctAnswer) && (
          <T.P color="error">Exercise missing required fields </T.P>
        )}

        <S.ButtonWrapper>
          <StepFooterButtons
            stuckAtReview={stuckAtReview}
            handleNext={handleNext}
            preview={preview}
            disabled={disabled || preview}
            enableNext={correctAnswer === cleanAnswer}
          />
        </S.ButtonWrapper>
      </S.Wrapper>
      <S.RotateWrapper display={showRotateMsg ? 'flex' : 'none'}>
        <PlayButton
          audioUrl={getMediaUrl(
            audio.pleaseRotateYourScreen,
            true,
            state?.preferredVoice
          )}
          iconProps={{ width: '32px', height: '32px' }}
          padding="12px"
          mt={5}
          mx="auto"
          mxM="0"
        >
          Please rotate your screen to landscape for this exercise
        </PlayButton>
      </S.RotateWrapper>
    </>
  );
};

export default VowelBreak;
