import LabelInput from './LabelInput';
import SelectQuestionType from './SelectQuestionType';
import Switch from '../../../../components/Switch';
import Icon from '../../../../components/Icon';
import * as S from './style';
import Options from './Options';
import { Popover } from 'antd';

const Question = ({ question, setQuestion, staticQuestion, error }) => {
  const setLabel = (value) => {
    setQuestion(question.id, { label: value });
  };

  const setType = (value) => {
    setQuestion(question.id, { type: value });
  };

  const setRequired = (value) => {
    setQuestion(question.id, { required: value });
  };

  const deleteQuestion = () => {
    setQuestion(question.id, { deleted: true });
  };

  const addNewOption = () => {
    setQuestion(question.id, {
      options: [
        ...question.options,
        {
          label: '',
          required: false,
        },
      ],
    });
  };

  const removeOption = (index) => {
    const newOptions = [...question.options];
    newOptions.splice(index, 1);
    setQuestion(question.id, {
      options: newOptions,
    });
  };
  const onOptionChange = (index, value) => {
    const newOptions = [...question.options];
    newOptions[index] = {
      ...newOptions[index],
      ...value,
    };
    setQuestion(question.id, {
      options: newOptions,
    });
  };
  return (
    <S.QuestionWrapper>
      <S.BasicQuestionWrapper>
        <LabelInput
          value={question?.label}
          setValue={setLabel}
          staticQuestion={staticQuestion}
          error={error?.label}
        />
        <S.ActionsWrapper>
          <SelectQuestionType
            value={question.type}
            setValue={setType}
            sx={{
              ml: {
                xs: 0,
                md: 4,
              },
            }}
            staticQuestion={staticQuestion}
          />
          {!staticQuestion ? (
            <>
              <Switch
                checked={question.required}
                onChange={setRequired}
                label="Required"
                sx={{
                  mr: 0,
                  ml: 0,
                }}
              />
              <Icon
                icon="trash2"
                width={28}
                height={28}
                pointer
                onClick={deleteQuestion}
              />
            </>
          ) : (
            <Popover
              content={
                'This question is not editable because it is essential for the platform functionality'
              }
              title="Fixed question"
              trigger="hover"
              placement="right"
            >
              <Icon
                icon="alert"
                width={28}
                height={28}
                color="primaryMain"
                pointer
              />
            </Popover>
          )}
        </S.ActionsWrapper>
      </S.BasicQuestionWrapper>
      {(question.type === 'SINGLE_CHOICE' ||
        question.type === 'CHECKBOXES' ||
        question.type === 'MULTIPLE_CHOICE') && (
        <Options
          options={question?.options}
          addNewOption={addNewOption}
          removeOption={removeOption}
          onOptionChange={onOptionChange}
          staticQuestion={staticQuestion}
          type={question.type}
          error={error}
        />
      )}
    </S.QuestionWrapper>
  );
};

export default Question;
