const StandingPerson = () => (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.1456 15.9987C9.19686 16.0001 9.24828 16.0008 9.29987 16.0008C9.35146 16.0008 9.40288 16.0001 9.45414 15.9987H12.5101C15.5973 15.9987 18.1 18.5014 18.1 21.5886V24.7784C18.1 26.1039 17.0255 27.1784 15.7 27.1784H14.9V35.9987C14.9 36.4405 14.5418 36.7987 14.1 36.7987H4.5C4.05817 36.7987 3.7 36.4405 3.7 35.9987V27.1784H2.9C1.57452 27.1784 0.5 26.1039 0.5 24.7784V21.5886C0.5 18.5014 3.00267 15.9987 6.08987 15.9987H9.1456ZM9.1456 15.9987C6.12413 15.917 3.69987 13.442 3.69987 10.4008C3.69987 7.30799 6.20708 4.80078 9.29987 4.80078C12.3927 4.80078 14.8999 7.30799 14.8999 10.4008C14.8999 13.442 12.4756 15.917 9.45414 15.9987H9.1456Z"
      fill="#3B3C42"
    />
  </svg>
);

export default StandingPerson;
