import Icon from '../../Icon';
import Markdown from '../../Markdown';
import * as S from './style';
import { antIcon } from '../Basic';
import { UseAudio } from 'Hooks';

/**
 * Primary UI component for user interaction
 */
const PlayButton = ({
  icon = 'speaker',
  loading,
  handleClick = () => {},
  disabled,
  to,
  customColor,
  iconProps,
  audioUrl,
  innerText,
  withoutButtonStyle,
  backgroundColor,
  children,
  weight,
  size,
  width,
  height,
  mlText = 4,
  radius = 16,
  color = 'neutralMain',
  showCrossIconOnError,
  external,
  combined,
  variant,
  btnColor,
  fallbackText,
  ...props
}) => {
  const { isLoading, isError, fallbackDisabled, handleAudioClick } = UseAudio({
    audioUrl,
    handleClick,
    fallbackText: typeof fallbackText === 'string' && fallbackText,
  });

  const audioDisabled = disabled || isLoading || fallbackDisabled;

  if (isError && showCrossIconOnError) {
    return (
      <S.Container text={children} {...props}>
        <S.PlayButton type="button" disabled as="button">
          <Icon icon="cross" width="16px" height="16px" color="red" />
        </S.PlayButton>
      </S.Container>
    );
  }

  if (combined) {
    return (
      <S.PlayButtonSoundCombinedButton
        type="button"
        disabled={audioDisabled}
        onClick={handleAudioClick}
        as="button"
        icon={icon}
        width={width}
        height={height}
        radius={radius}
        backgroundColor={backgroundColor}
        withoutButtonStyle={withoutButtonStyle}
        loading={loading}
        aria-disabled={audioDisabled ? 'true' : 'false'}
        {...props}
        aria-label="Play sound"
        innerText={innerText}
        variant={variant}
        btnColor={btnColor}
      >
        {icon && <Icon icon={icon} width="32px" height="32px" {...iconProps} />}
        {loading && <S.CenterLoading indicator={antIcon} />}
        {innerText && <S.InnerText color={color}>{innerText}</S.InnerText>}
      </S.PlayButtonSoundCombinedButton>
    );
  }

  if (variant === 'onlyIcon') {
    return (
      <S.PlayIconButton
        loading={loading}
        disabled={audioDisabled}
        onClick={handleAudioClick}
        {...props}
      >
        <Icon
          icon={icon}
          disabled={audioDisabled}
          aria-disabled={audioDisabled ? 'true' : 'false'}
          aria-label="Play sound"
          width="32px"
          height="32px"
          {...iconProps}
        />
      </S.PlayIconButton>
    );
  }

  return (
    <S.Container text={children} {...props}>
      <S.PlayButton
        type="button"
        disabled={audioDisabled}
        onClick={handleAudioClick}
        as="button"
        icon={icon}
        width={width}
        height={height}
        radius={radius}
        backgroundColor={backgroundColor}
        withoutButtonStyle={withoutButtonStyle}
        loading={loading}
        aria-disabled={audioDisabled ? 'true' : 'false'}
        {...props}
        aria-label="Play sound"
        innerText={innerText}
        btnColor={btnColor}
      >
        {icon && (
          <Icon
            icon={icon}
            disabled={audioDisabled}
            width="32px"
            height="32px"
            {...iconProps}
          />
        )}
        {loading && <S.CenterLoading indicator={antIcon} />}
        {innerText && <S.InnerText color={color}>{innerText}</S.InnerText>}
      </S.PlayButton>

      {children && to && (
        <S.PlayTextLink
          loading={loading}
          ml={mlText}
          color={color}
          to={to}
          external
        >
          <Markdown
            text={children}
            customStyles={{
              p: {
                ml: 3,
                color: loading ? 'neutral70' : color,
                weight: weight || '600',
                size: size || 'med',
              },
            }}
          />
        </S.PlayTextLink>
      )}

      {children && !to && (
        <S.PlayText loading={loading} ml={mlText} color={color}>
          <Markdown
            text={children}
            customStyles={{
              p: {
                ml: 0,
                color: loading ? 'neutral70' : color,
                weight: weight || '600',
                size: size || 'med',
              },
            }}
          />
        </S.PlayText>
      )}
    </S.Container>
  );
};

export default PlayButton;
