const CircleTick = ({ width, height, color, ...props }) => (
  <svg
    width={width || '34'}
    height={height || '34'}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.4 17.0002C33.4 7.98689 26.0933 0.680176 17.08 0.680176C8.06672 0.680176 0.76001 7.98689 0.76001 17.0002C0.76001 26.0135 8.06672 33.3202 17.08 33.3202C26.0933 33.3202 33.4 26.0135 33.4 17.0002ZM23.4352 10.487C23.0192 10.1583 22.4155 10.229 22.0868 10.645L15.1637 19.4066L11.9922 16.2958C11.6137 15.9246 11.0059 15.9304 10.6347 16.309C10.2634 16.6875 10.2693 17.2953 10.6478 17.6665L14.5821 21.5256C14.9896 21.9252 15.6537 21.8832 16.0076 21.4354L23.5932 11.8354C23.922 11.4194 23.8512 10.8157 23.4352 10.487Z"
      fill={color || '#55225D'}
    />
  </svg>
);

export default CircleTick;
