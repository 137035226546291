import { useState, useEffect, useCallback } from 'react';
import { Grid, Loading, Typography as T } from '../../../components';
import { useAuth } from '../../../context/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { navRoutes, userRoles } from '../../../constants';
import { JoinOurCommunity } from 'components/JoinOurCommunity';
import { Divider } from 'antd';
import { FurtherResources } from 'components/FurtherResources';
import { CompleteTrainingCongratsDialog } from 'components/CompleteTrainingCongratsDialog';
import useFacilitatorCompletedTrainingStep from 'pages/Facilitator/Training/Hooks/useFacilitatorCompletedTrainingStep';
import { useGetAllCustomTrainingResources } from 'api-calls/custom-training.queries';
import useFacilitatorTrainingData from './Hooks/useFacilitatorTrainingData';

const { Row, Col } = Grid;

const TrainingComplete = () => {
  const {
    user: { organisationId },
  } = useAuth();
  const { customTrainingResources, isLoading: customTrainingResourcesLoading } =
    useGetAllCustomTrainingResources({
      organisationId,
    });

  const navigate = useNavigate();

  const [showCongratsDialog, setShowCongratsDialog] = useState(false);
  const { state } = useLocation();

  const { completedTrainingStep, getCurrentStepLoading } =
    useFacilitatorCompletedTrainingStep();
  const { trainingData } = useFacilitatorTrainingData();

  useEffect(() => {
    if (!!state?.showCongrats) {
      setShowCongratsDialog(true);
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  const hideCongratsDialog = useCallback(() => {
    setShowCongratsDialog(false);
  }, []);

  if (completedTrainingStep < trainingData.length)
    return navigate(navRoutes.FACILITATOR.TRAINING);

  if (getCurrentStepLoading || customTrainingResourcesLoading) {
    return <Loading />;
  }

  return (
    <Row dir="column" jc="center">
      <Col w={[4, 10, 10]}>
        <T.H1 weight="bold" color="neutralMain">
          🎉 Training complete!
        </T.H1>
      </Col>
      <Col w={[4, 10, 10]} mt="2" mb="5">
        <T.P>
          You’ve now completed all the essential facilitator training. You’re
          ready to start onboarding some coaches and learners! Remember you can
          review any of the training as much as you like by clicking the
          Training section in your menu above.
        </T.P>
      </Col>

      <Col w={[4, 10, 10]}>
        <T.H2 weight="bold" color="neutralMain" mb="2">
          Here are the key points you need to know
        </T.H2>
        <T.P>
          You’ve now completed all the essential facilitator training. You’re
          ready to start onboarding some coaches and learners! Remember you can
          review any of the training as much as you like by clicking the
          Training section in your menu above.
        </T.P>
      </Col>
      <Col w={[4, 10, 10]}>
        <Divider />
      </Col>
      <Col w={[4, 10, 10]}>
        <JoinOurCommunity />
      </Col>
      <Col w={[4, 10, 10]}>
        <Divider />
      </Col>
      <Col w={[4, 10, 10]}>
        <FurtherResources customTrainingResources={customTrainingResources} />
      </Col>
      {showCongratsDialog ? (
        <CompleteTrainingCongratsDialog
          visible={showCongratsDialog}
          onCancel={hideCongratsDialog}
          role={userRoles.FACILITATOR}
        />
      ) : null}
    </Row>
  );
};

export default TrainingComplete;
