import { Row, Col } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import PageIconBanner from '../../../components/PageIconBanner';

import AddFacilitatorCard from './AddFacilitatorCard';
import FacilitatorStatus from './FacilitatorStatus';
import { useGetOrgProgress } from '../../../Hooks';
import Modal from '../../../components/Modal';
import { BasicButton } from '../../../components/Button';
import { navRoutes } from '../../../constants';
import Loading from '../../../components/Loading';

import * as S from './style';
import { BackButton } from 'components/BackButton';

const AddFacilitators = () => {
  const { loading, completedCustomisation } = useGetOrgProgress();

  if (loading) return <Loading />;

  return (
    <S.Wrapper>
      <BackButton mb={6} />
      <Row>
        <Col w={[4, 12, 12]} mb={5}>
          <PageIconBanner
            icon="userWithPlus"
            color="quaternaryMain"
            title="Add facilitator"
          >
            <T.P mb="1">
              Facilitators are the main person or persons who will manage the
              delivery of Turning Pages in your organisation.
            </T.P>

            <T.P mb="1">
              They will be able to invite coaches, sign up, add new learners and
              assign learners to coaches.
            </T.P>
            <T.P>
              They will get all the training they need right here on the tool,
              so don’t worry, we’ve got your covered!
            </T.P>
          </PageIconBanner>
        </Col>
      </Row>
      <Row mb={5}>
        <Col w={[4, 12, 12]}>
          <AddFacilitatorCard />
        </Col>
      </Row>
      <Row mb={5}>
        <Col w={[4, 12, 12]}>
          <S.CardWrapper clear>
            <FacilitatorStatus />
          </S.CardWrapper>
        </Col>
      </Row>
      <Modal
        visible={!completedCustomisation}
        maskColor="rgba(255, 255, 255, 0.5)"
      >
        <T.H2 mb={4} color="quaternaryLight">
          To proceed, please customize your organization page first.{' '}
        </T.H2>
        <T.P mb={4} color="quaternaryLight">
          Currently, it is essential to ensure that you have checked off the
          task of customizing your organization as complete before adding a
          facilitator.
        </T.P>
        <BasicButton
          to={navRoutes.ORGANISATION_ADMIN.CUSTOMISE}
          variant="secondary"
        >
          <T.P color="neutralSurface" weight="bold">
            Go to customisation
          </T.P>
        </BasicButton>
      </Modal>
    </S.Wrapper>
  );
};

export default AddFacilitators;
