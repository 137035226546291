import { useState, useEffect } from 'react';
import StepFooterButtons from '../../../StepFooterButtons';

const ButtonsSection = ({
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  tempArr,
  correctAnswer,
}) => {
  const [enableNext, setEnableNext] = useState(false);

  useEffect(() => {
    const checkForRightAnswer = () => {
      let currentAns = tempArr.map((e) => e.id);
      let result = false;
      if (JSON.stringify(currentAns) === JSON.stringify(correctAnswer)) {
        result = true;
      }
      setEnableNext(result);
      return result;
    };
    checkForRightAnswer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempArr]);

  return (
    <StepFooterButtons
      stuckAtReview={stuckAtReview}
      handleNext={handleNext}
      preview={preview}
      disabled={disabled}
      enableNext={enableNext}
    />
  );
};

export default ButtonsSection;
