import { courseCodeTypes, coursesNames } from '../../../constants';

export const getTrainingData = ({ courseName }) => [
  {
    index: 1,
    title: `How ${courseName} works`,
    desc: `Learn about the history and structure of ${courseName}`,
    user: 'FACILITATOR',
    clickable: true,
    routeKey: 'TRAINING_STEP',
    param: ':stepId',
  },
  {
    index: 2,
    title: 'How to use this tool',
    desc: `Learn how to navigate and utilise the ${courseName} tool effectively.`,
    user: 'FACILITATOR',
    clickable: true,
    routeKey: 'TRAINING_STEP',
    param: ':stepId',
  },
  {
    index: 3,
    title: 'Adding / Recruiting coaches',
    desc: `Learn how to recruit and add coaches to the ${courseName} program`,
    user: 'FACILITATOR',
    clickable: true,
    routeKey: 'TRAINING_STEP',
    param: ':stepId',
  },
  {
    index: 4,
    title: 'Managing coaches',
    desc: 'Learn to manage coaches and access performance data',
    user: 'FACILITATOR',
    clickable: true,
    routeKey: 'TRAINING_STEP',
    param: ':stepId',
  },
  {
    index: 5,
    title: 'Identifying Learners',
    desc: 'Explore strategies to identify potential learners',
    user: 'FACILITATOR',
    clickable: true,
    routeKey: 'TRAINING_STEP',
    param: ':stepId',
  },
  {
    index: 6,
    title: 'Onboarding Learners',
    desc: `Learn how to onboard learners and introduce the ${courseName} tool`,
    user: 'FACILITATOR',
    clickable: true,
    routeKey: 'TRAINING_STEP',
    param: ':stepId',
  },
  // {
  //   index: 7,
  //   title: 'Monitoring and reporting',
  //   desc: 'Learn how to generate reports from the Turning Pages tool',
  //   user: 'FACILITATOR',
  //   clickable: true,
  //   routeKey: 'TRAINING_STEP',
  //   param: ':stepId',
  // },
];

export const getTrainingContent = ({ courseName, courseCode }) => {
  const introductionSummary =
    courseCode === courseCodeTypes.TPD
      ? `Turning Pages was first developed back in 2015 and has successfully been used to help thousands of people improve their reading. It was designed specifically for use with adults and is phonics based. If you are not familiar with phonics then don’t worry, you can find lots of helpful tutorials and guidance within this tool.\n\n Turning Pages consists of five manuals, which increase in difficulty. We refer to it as a pick up and go resource as it provides a great deal of structure and lots of helpful information and tips to guide coaches in using it. To create this digital version of Turning Page we have transposed all of the activities from our manualised versions of the programme.\n\nAnyone that has a reading level below entry level 3 can benefit from participating in this programme.`
      : `The Count Me In program is designed to enhance numeracy skills, enabling learners to apply mathematical concepts in everyday life. It targets adults in the UK, nearly half of whom possess numeracy skills at a primary school level, and aligns its curriculum with functional skills mathematics qualifications at entry levels 1 to 3. With additional Level 1 content tailored to practical day-to-day use, the program draws on proven methodologies from the Turning Pages reading program to create an effective and learner-focused approach.

      Delivered on a one-to-one basis by a coach, Count Me In incorporates structured activities with clear instructions and answers to support guided learning. Learners participate in regular, short sessions of about 30 minutes, which are designed to maintain engagement and foster consistent progress. The program includes manuals with parking pages and progress checks, enabling learners to revisit challenging topics and monitor their development. By interweaving learning topics, it supports the reinforcement and integration of prior knowledge, helping learners build confidence and achieve measurable outcomes.

      The program's development was informed by numeracy experts and enriched by feedback from learners, mentors, and prison facilitators. Draft materials were tested across several establishments to ensure relevance and effectiveness. This rigorous development process ensures that Count Me In offers a robust, evidence-based solution for improving numeracy skills, with a strong focus on practical application and tangible progress.`;

  return [
    {
      index: 1,
      title: `How ${courseName} works`,
      introduction: introductionSummary,
      user: 'FACILITATOR',
      videoURLs: [
        {
          url:
            courseCode === courseCodeTypes.TPD
              ? 'https://www.youtube.com/embed/Arom6pKXKhw?si=hq5gEM1bPpmcDA6L'
              : 'https://www.youtube.com/embed/TpE39jFaBws?si=WrCgLhFEft1bAi_z',
          title: 'Welcome Video',
        },
      ],
    },
    {
      index: 2,
      title: 'How to use this tool',
      introduction:
        'In this short video tutorial we will begin by showing you how to use this tool.',
      user: 'FACILITATOR',
      videoURLs: [
        {
          url: 'https://www.youtube.com/embed/vp0oU2EVDo8?si=iFxs7HBb-PJ5TjJ3',
          title: 'Getting Started',
        },
        {
          url: 'https://www.youtube.com/embed/MQq293CgWvg?si=5qMixuysCDBDxmz0',
          title: 'Managing the Dashboard',
        },
        {
          url:
            courseCode === courseCodeTypes.TPD
              ? 'https://www.youtube.com/embed/iBbh8pQqvTc?si=yOopERPLThub8vYI'
              : 'https://www.youtube.com/embed/6LfgbS2zJdA?si=_Qw94gnGEB1DclEt',
          title:
            courseCode === courseCodeTypes.TPD
              ? 'Adding and Allocating Learners'
              : 'Adding a Learner',
        },
      ],
    },
    {
      index: 3,
      title: 'Adding / Recruiting coaches',
      introduction:
        'In this short video tutorial we will show you the processes available both for recruiting and adding coaches.',
      user: 'FACILITATOR',
      videoURLs: [
        {
          url: 'https://www.youtube.com/embed/dVFNzn3A0qg?si=caEH3BDMdGmfcOnO',
          title: 'Adding Coaches',
        },
      ],
    },
    {
      index: 4,
      title: 'Managing coaches',
      introduction:
        'Within the tool you will have access to a range of information that will support you in managing your reading coaches. As well as adding/updating their personal information, you will be able to see what training coaches have been completed, which coaches have been assigned which learners and various performance data e.g. how many sessions have taken place, what progress has been made.',
      user: 'FACILITATOR',
    },
    {
      index: 5,
      title: 'Identifying Learners',
      introduction:
        courseCode === courseCodeTypes.TPD
          ? 'Whilst many people will ask for help and support to improve their reading, many will try to keep this issue hidden, often through shame and embarrassment. In this short tutorial we will discuss how your organisation or service will be able to promote this support and encourage people to take part. As a basic set of tips we would recommend:'
          : '',
      user: 'FACILITATOR',
      items:
        courseCode === courseCodeTypes.TPD
          ? [
              {
                type: 'TickList',
                list: [
                  'Having questions regarding literacy included in assessment paperwork.',
                  'Having leaflets, flyers and posters available or promote via social media.',
                  'Ensuring all staff/volunteers are clear on the type of support available, who it is for and how this can be accessed.',
                  'Encourage staff/volunteers to broach with their clients and discuss their responses.',
                  'Host events which could promote reading.',
                ],
              },
            ]
          : [],
      videoURLs: [
        {
          url: 'https://www.youtube.com/embed/mlSZi5taq7k?si=bC57vkI1onk_rW0w',
          title: `Identifying Learners ${
            courseCode === courseCodeTypes.TPD ? 'TPD' : coursesNames.CMID
          }`,
        },
      ],
    },
    {
      index: 6,
      title: 'Onboarding Learners',
      introduction:
        'In this short video we will show you how to on-board a learner. This will include information on how to introduce the tool and to shows learners how it can be accessed and used.\n\nIn addition, the onboarding process provides an opportunity to discuss setting some personal goals with each learner and taking a baseline measure of their progress. This can help track their outcomes later down the line.',
      user: 'FACILITATOR',
      videoURLs: [
        {
          url: 'https://www.youtube.com/embed/YEpioTCqlJE?si=gzITkiB5IRSwlojb',
          title: 'Onboarding',
        },
      ],
    },
    // {
    //   index: 7,
    //   title: 'Monitoring and reporting',
    //   introduction:
    //     'In this short video we will show you how to pull reports from the tool, which can be used to monitor ongoing performance.',
    //   user: 'FACILITATOR',
    //   videoURLs: [{ url: 'https://www.youtube.com/embed/LfsXExFeBg0' }],
    // },
  ];
};

export const LINK_LIST = [
  {
    label: 'Link 1',
    url: 'http://www.example.com',
  },
  {
    label: 'Link 2',
    url: 'http://www.example.com',
  },
  {
    label: 'Link 3',
    url: 'http://www.example.com',
  },
];
