import theme from '../../../theme';

const Eraser = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={height || '22'}
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.1158 17.5433H14.3874L23.8516 7.65002C23.9255 7.50346 23.9994 7.39353 23.9994 7.24696C23.9994 7.06376 23.9625 6.91719 23.8146 6.84391L17.1601 0.651458C16.9383 0.431608 16.5686 0.46825 16.3838 0.651458L11.282 5.96451C11.282 6.00115 11.282 6.00115 11.282 6.00115L5.92146 11.5707C4.51662 13.1096 4.62753 15.4547 6.1063 16.9204L7.80689 18.496C7.84386 18.5326 7.95477 18.5326 7.99174 18.5693C8.06568 18.5693 8.13962 18.6425 8.21355 18.6425H21.1158C21.4486 18.6425 21.7073 18.386 21.7073 18.0563C21.7073 17.7631 21.4486 17.5433 21.1158 17.5433ZM8.43537 17.5433L6.88266 16.1143C5.84752 15.1249 5.81055 13.4394 6.80872 12.3768L11.7256 7.17368L17.5668 12.5967L12.8717 17.5433H8.43537Z"
      fill={theme.colors[color] || color || '#002666'}
    />
    <path
      d="M1.37673 17.1408C1.08097 16.9942 0.748249 17.1042 0.600372 17.3973C0.452495 17.6904 0.563402 18.0202 0.859157 18.1668L3.15125 19.3393C3.22519 19.376 3.3361 19.4126 3.41004 19.4126C3.63185 19.4126 3.8167 19.3027 3.92761 19.1195C4.07549 18.8263 3.96458 18.4966 3.66882 18.35L1.37673 17.1408Z"
      fill={theme.colors[color] || color || '#002666'}
    />
    <path
      d="M2.81391 14.3907C2.66604 14.1342 2.29634 14.0609 2.03756 14.2075C1.77877 14.3541 1.70483 14.7205 1.85271 14.977L3.40542 17.5053C3.51633 17.6885 3.70118 17.7618 3.88602 17.7618C3.99693 17.7618 4.07087 17.7251 4.18178 17.6885C4.44056 17.5419 4.5145 17.1755 4.36662 16.919L2.81391 14.3907Z"
      fill={theme.colors[color] || color || '#002666'}
    />
    <path
      d="M2.85132 19.8871L0.448319 20.4001C0.152565 20.4734 -0.0322819 20.7665 0.00468744 21.0597C0.078626 21.3162 0.300442 21.4994 0.559227 21.4994C0.596196 21.4994 0.633165 21.4994 0.670134 21.4994L3.07314 20.9864C3.36889 20.9131 3.55374 20.62 3.51677 20.3268C3.44283 19.9971 3.14708 19.8139 2.85132 19.8871Z"
      fill={theme.colors[color] || color || '#002666'}
    />
  </svg>
);

export default Eraser;
