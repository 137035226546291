import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from '../../../components/Grid';
import { BasicButton } from '../../../components/Button';
import { createExerciseStepUrl } from 'CMS/utils';
import UnsavedModal from 'components/UnsavedModal';
import { useStepForm } from 'CMS/Providers/StepFormProvider';

const NavigateExercisesSteps = ({ exerciseId, stepId, steps }) => {
  const navigate = useNavigate();
  const { state, updateFormMetaData, resetForm } = useStepForm();
  const { isDirty } = state;
  const [isUnsavedModalVisible, setIsUnsavedModalVisible] = useState(false);
  const [nextExerciseStepId, setNextExerciseStepId] = useState('');

  const currentExerciseStepIndex = steps?.findIndex(
    (step) => step.id === Number(stepId)
  );

  // Calculate previous and next exercise step
  const prevExerciseStep =
    currentExerciseStepIndex > 0 ? steps[currentExerciseStepIndex - 1] : null;
  const nextExerciseStep =
    currentExerciseStepIndex < steps?.length - 1
      ? steps[currentExerciseStepIndex + 1]
      : null;

  const handleNextExercise = (stepId) => {
    if (isDirty) {
      setIsUnsavedModalVisible(true);
      setNextExerciseStepId(stepId); // "prev" or "next"
    } else {
      resetForm();
      // No unsaved changes, navigate directly
      navigate(createExerciseStepUrl(exerciseId, stepId));
    }
  };

  if (stepId === 'new') return null;

  return (
    <>
      <Row jc="end" mt={6}>
        {prevExerciseStep && (
          <Col w={[4, 6, 6]}>
            <BasicButton
              onClick={() => handleNextExercise(prevExerciseStep.id)}
              variant="secondary"
            >
              Pervious step
            </BasicButton>
          </Col>
        )}
        {nextExerciseStep && (
          <Col w={[4, 6, 6]}>
            <BasicButton
              onClick={() => handleNextExercise(nextExerciseStep.id)}
              variant="secondary"
            >
              Next step
            </BasicButton>
          </Col>
        )}
      </Row>
      <UnsavedModal
        isModalVisible={isUnsavedModalVisible}
        setIsModalVisible={setIsUnsavedModalVisible}
        handleLeaveAnyway={() => {
          setIsUnsavedModalVisible(false);
          updateFormMetaData({ isDirty: false });
          navigate(createExerciseStepUrl(exerciseId, nextExerciseStepId));
        }}
      />
    </>
  );
};

export default NavigateExercisesSteps;
