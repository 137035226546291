import styled from '@emotion/styled';
import CanvasDraw from 'react-canvas-draw';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const AccordionContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacings[3]};
  border-radius: ${({ theme }) => theme.spacings[4]};
  border: ${({ theme }) => `1px solid  ${theme.colors.neutral40}`};
`;

export const AccordionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const AccordionItem = styled.div`
  overflow: hidden;
  height: auto;
  max-height: 9999px;

  ${(props) =>
    props.collapsed &&
    `
    max-height: 0;
  `}
`;

export const AccordionImage = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const StyledCanvasDraw = styled(CanvasDraw)`
  position: relative;

  &::before {
    content: '""';
    display: block;
    position: absolute;
    opacity: 0.4;
    width: 100%;
    height: 100%;
    background-image: url(${({ imageUrl }) => encodeURI(imageUrl)});
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
  }
`;
