import styled from '@emotion/styled';
import { setMargin } from '../../../helpers';

export const PlayButtonWrapper = styled.div`
  ${setMargin};
  display: flex;
  align-items: center;
`;

export const CardWrapper = styled.div`
  border-radius: 16px;
  width: 100%;
  padding: 32px;
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${({ theme, completed }) =>
    completed ? theme.colors.quinaryLight : theme.colors.neutralSurface};
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.secondaryLight};

  ${({ theme }) => theme.media.tablet} {
    padding: 16px;
  }
`;

export const GoalProgressWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const GoalProgressTube = styled.div`
  flex: 1;
  width: 100%;
  border-radius: 42.79px;
  position: relative;
  height: 20px;
  box-sizing: content-box;
  background: ${({ theme }) => theme.colors.neutral40};
`;

export const GoalProgressDone = styled.div`
  background: ${({ theme, completed }) =>
    completed ? theme.colors.quinaryMain : theme.colors.primaryMain};
  width: ${({ percentage }) => `${percentage}%`};
  height: 20px;
  border-radius: 42.79px;
  transition: 0.3s;
`;

export const EditButton = styled.button`
  border: none;
  background: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  margin-right: 12px;
`;
// todo: delete
export const IconButton = styled.button`
  border: none;
  background: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const CardButtons = styled.div`
  ${setMargin};
  width: 100%;
  display: flex;
`;

export const BigBtn = styled.div`
  flex: 65%;
  margin-left: 10px;
`;

export const SmallBtn = styled.div`
  flex: 35%;
`;

export const CardButtonsWrapper = styled.div`
  margin-top: 8px;
  flex: 1;
  display: flex;
  align-items: flex-end;
`;

// new

export const PageWrapper = styled.div`
  padding: 40px;
  width: 100%;
  ${({ theme }) => theme.media.tablet} {
    padding: 32px;
  }
`;

export const BackWrapper = styled.div`
  padding: 0 24px;
  ${({ theme }) => theme.media.tablet} {
    padding: 0 32px;
  }
`;

export const Divider = styled.div`
  background: ${({ theme }) => theme.colors.neutral40};
  height: 1px;
  width: 100%;
  margin: 24px 0;
`;

export const GoalCardWrapper = styled.button`
  width: 100%;
  padding: 16px;
  background: ${({ theme, selected }) =>
    selected ? theme.colors.secondaryLight : theme.colors.neutralSurface};
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.neutralMain : theme.colors.neutral50};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const EditButton2 = styled.button`
  background: none;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const EditInputWrapper = styled.div`
  width: 100%;
  position: relative;
  border-radius: 16px;
  padding: 8px;
  margin-top: 8px;
  background: white;
  border: 1px solid ${({ theme }) => theme.colors.primaryMain};
`;

export const EditInput = styled.input`
  width: 100%;
  min-height: 48px;
  border-radius: 16px;
  padding: 8px;
  font-family: BasicCommercial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border: none;
  &:active {
    border: none;
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.primaryMain};
  }
`;

export const SaveButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  outline: none;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primaryMain};
  z-index: 99;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  font-style: normal;
  font-family: BasicCommercial;
  font-weight: 400;
`;

export const ListItemWrapper = styled.div`
  padding: 8px;
  margin-top: 8px;
  width: 100%;
  display: flex;
  background: white;
  border-radius: 8px;
  justify-content: space-between;
  div {
    width: auto;
  }
`;

export const CheckBoxItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  margin-bottom: 32px;
`;

export const DeleteButton = styled.button`
  border: none;
  background: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  z-index: 99;
  position: absolute;
  top: 32px;
  right: 32px;
  ${({ theme }) => theme.media.tablet} {
    top: 16px;
    right: 16px;
  }
`;

export const AccordionWrapper = styled.div``;

export const AccordionItem = styled.div`
  overflow: hidden;
  height: auto;
  max-height: 9999px;

  ${(props) =>
    props.collapsed &&
    `
    max-height: 0;
  `}
`;

export const AccordionTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 12px;
  padding-bottom: 8px;
  margin-top: 16px;
`;

export const AccordionContent = styled.div``;

export const AccordionBtn = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  gap: 12px;
`;
