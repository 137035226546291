import {
  useFetch,
  usePatch,
  usePost,
  formatQueryError,
} from '../utils/queries';
import { FACILITATORS_BASE } from '../constants';

function useFacilitatorUpdateCoachNote({ coachId }, options = {}) {
  return usePatch({
    url: `${FACILITATORS_BASE}/coach-notes/update`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [['FACILITATOR_COACH_NOTES', { coachId }]],
    },
  });
}

function useGetDefaultFacilitator(_, options = {}) {
  const context = useFetch({
    key: [`GET_DEFAULT_FACILITATOR`],
    url: `${FACILITATORS_BASE}/default-facilitator`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    facilitator: context.data,
    error: formatQueryError(context),
  };
}

function useGetAllFacilitators({ page = 1, limit = 20 }, options = {}) {
  const context = useFetch({
    key: [`GET_ALL_FACILITATORS`, { page, limit }],
    url: `${FACILITATORS_BASE}/all-facilitators`,
    axiosParams: { page, limit },
    reactQueryConfig: options,
  });
  return {
    ...context,
    facilitators: context.data,
    error: formatQueryError(context),
  };
}

function useGetFacilitatorsCount({ search = '' }, options = {}) {
  const context = useFetch({
    key: [`GET_FACILITATORS_COUNT`, { search }],
    url: `${FACILITATORS_BASE}/count`,
    axiosParams: { search },
    reactQueryConfig: options,
  });
  return {
    ...context,
    facilitatorCount: context.data,
    error: formatQueryError(context),
  };
}

function useGetFacilitators(
  { searchValue, sortField, sortOrder, page, limit, statusType },
  options = {}
) {
  const context = useFetch({
    key: [
      `GET_FACILITATORS`,
      { searchValue, sortField, sortOrder, page, limit, statusType },
    ],
    url: `${FACILITATORS_BASE}/`,
    axiosParams: {
      search: searchValue,
      sortField,
      sortOrder,
      page,
      limit,
      statusType,
    },
    reactQueryConfig: options,
  });

  return {
    ...context,
    facilitators: context.data,
    error: formatQueryError(context),
  };
}

const useAddFacilitators = (_, options = {}) => {
  const context = usePost({
    url: `${FACILITATORS_BASE}`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [
        [`GET_FACILITATORS`],
        [`GET_ALL_FACILITATORS`],
        [`GET_FACILITATORS_COUNT`],
      ],
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useGetFacilitatorById = ({ facilitatorId }, options = {}) => {
  const context = useFetch({
    key: [`GET_FACILITATORS`, { facilitatorId }],
    url: `${FACILITATORS_BASE}/${facilitatorId}`,
    reactQueryConfig: options,
  });
  return {
    ...context,
    facilitator: context.data,
    error: formatQueryError(context),
  };
};

const useUpdateFacilitatorOrganisations = (_, options = {}) => {
  const context = usePatch({
    getUrl: ({ facilitatorId }) => `${FACILITATORS_BASE}/${facilitatorId}`,
    reactQueryConfig: {
      invalidateKeys: () => [
        [`GET_FACILITATORS`],
        [`GET_ALL_FACILITATORS`],
        [`GET_FACILITATORS_COUNT`],
      ],
      ...options,
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useMakeFacilitatorAsCoach = (_, options = {}) => {
  const context = usePatch({
    getUrl: ({ id }) => `${FACILITATORS_BASE}/${id}/roles`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [
        [`GET_FACILITATORS`],
        [`GET_ALL_FACILITATORS`],
        [`GET_FACILITATORS_COUNT`],
      ],
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useUpdateFacilitatorStatus = (_, options = {}) => {
  const context = usePatch({
    getUrl: ({ id }) => `${FACILITATORS_BASE}/${id}/status`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [
        [`GET_FACILITATORS`],
        [`GET_ALL_FACILITATORS`],
        [`GET_FACILITATORS_COUNT`],
      ],
    },
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useGetCompletedTrainingStepById = ({ id }, options = {}) => {
  const context = useFetch({
    key: [`GET_COMPLETED_TRAINING_STEP_BY_ID`, { id }],
    url: `${FACILITATORS_BASE}/${id}/training-step`,
    reactQueryConfig: options,
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useUpdateTrainingStep = ({ id }, options = {}) => {
  const context = usePatch({
    url: `${FACILITATORS_BASE}/${id}/training-step`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [[`GET_COMPLETED_TRAINING_STEP_BY_ID`, { id }]],
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useGetFacilitatorInvite = ({ inviteToken }, options = {}) => {
  const context = useFetch({
    key: [`GET_FACILITATOR_INVITE`, { inviteToken }],
    url: `${FACILITATORS_BASE}/signup-token/${inviteToken}`,
    reactQueryConfig: options,
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useSignup = (_, options = {}) => {
  const context = usePost({
    url: `${FACILITATORS_BASE}/signup/`,
    reactQueryConfig: options,
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

export {
  useFacilitatorUpdateCoachNote,
  useGetDefaultFacilitator,
  useGetFacilitators,
  useAddFacilitators,
  useGetAllFacilitators,
  useGetFacilitatorById,
  useUpdateFacilitatorOrganisations,
  useGetFacilitatorsCount,
  useMakeFacilitatorAsCoach,
  useUpdateFacilitatorStatus,
  useGetCompletedTrainingStepById,
  useUpdateTrainingStep,
  useGetFacilitatorInvite,
  useSignup,
};
