import { useEffect, useMemo, useRef, useState } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput, Dropdown } from '../../../../components/Inputs';
import { Checkbox } from 'antd';
import * as S from './style';
import { Icon } from '../../../../components';
import { dragAndDrop2Variations } from '../../../../constants';
import { string, number, array, object } from 'yup';

import { MediaKeyInput } from '../../../components/MediaKeyInput';
import { mediaInputLabels } from 'constants';
import { mediaTypes } from '../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import { convertSnakeCaseToSpaces } from 'helpers';

const initialData = {
  variation: dragAndDrop2Variations.variations.DEFAULT,
  options: [{ id: 0, option: '' }],
  correctAnswer: '',
  preFilledAnswer: [{ id: 0, text: '', hide: false }],
};

const DragAndDrop2 = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateFormFields, updateDataFields } = useStepForm(
    initialRef.current
  );
  const { form, data, validationErrs } = state;
  const [preFilledAnswer, setPreFilledAnswer] = useState(
    data.preFilledAnswer
      ? data.preFilledAnswer.map((e, i) => ({ text: e, id: i, hide: !e }))
      : [{ id: 0, text: '', hide: false }]
  );

  const variation = data.variation;

  const isWithExplainerAudio =
    variation === dragAndDrop2Variations.variations.WITH_EXPLAINER_AUDIO;

  const options = useMemo(
    () =>
      data.options
        ? data.options.map((e, i) => ({ ...e, id: i }))
        : [{ id: 0, option: '' }],
    [data.options]
  );

  useEffect(() => {
    updateDataFields({
      preFilledAnswer: preFilledAnswer?.map((e) => e.text || ''),
    });
  }, [preFilledAnswer, updateDataFields]);

  const variationOptions = Object.keys(dragAndDrop2Variations.variations).map(
    (e) => ({
      label: convertSnakeCaseToSpaces(e),
      value: e,
    })
  );

  const onVariationChange = (variation) => {
    updateDataFields({
      variation,
    });
  };

  const addOption = () => {
    const maxId = Math.max(...options.map((e) => e.id), 0);
    updateDataFields({
      options: [...options, { id: maxId + 1, option: '', hide: false }],
    });
  };
  const removeOption = (id) => {
    updateDataFields({
      options: options.filter((e) => e.id !== id),
    });
  };

  const addPreFilled = () => {
    const maxId = Math.max(...preFilledAnswer.map((e) => e.id), 0);
    setPreFilledAnswer([
      ...preFilledAnswer,
      { id: maxId + 1, text: '', hide: false },
    ]);
  };
  const removePreFilled = (id) => {
    setPreFilledAnswer(preFilledAnswer.filter((e) => e.id !== id));
  };
  const onOptionChange = (value, id) => {
    updateDataFields({
      options: options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };
  const onPreFillCheckBoxChange = (value, id) => {
    setPreFilledAnswer(
      preFilledAnswer.map((e) => {
        if (e.id !== id) return e;
        return { ...e, text: value ? '' : e.text, hide: value };
      })
    );
  };

  const onPreFillTextChange = (value, id) => {
    setPreFilledAnswer(
      preFilledAnswer.map((e) => {
        if (e.id !== id) return e;
        return { ...e, text: value };
      })
    );
  };

  return (
    <div>
      <Dropdown
        label="Choose a variation"
        options={variationOptions}
        selected={
          variation
            ? {
                value: variation,
                label: convertSnakeCaseToSpaces(variation),
              }
            : null
        }
        handleChange={onVariationChange}
        error={validationErrs?.variation}
      />

      {!!variation && (
        <>
          <BasicInput
            label="Correct Answer"
            value={data.correctAnswer}
            handleChange={(correctAnswer) =>
              updateDataFields({ correctAnswer })
            }
            m={{ mt: 5 }}
          />
          {isWithExplainerAudio && (
            <>
              <BasicInput
                value={data.textAudio}
                handleChange={(textAudio) => updateDataFields({ textAudio })}
                label="Explainer Text"
                error={validationErrs.textAudio}
                m={{ mt: 8 }}
              />
              <MediaKeyInput
                type={mediaTypes.TEXT_AUDIO}
                label="Explainer Audio"
                value={data.textAudioKey}
                handleChange={(textAudioKey) =>
                  updateDataFields({ textAudioKey })
                }
                m={{ mt: 5 }}
                error={validationErrs.textAudioKey}
              />
            </>
          )}

          {!isWithExplainerAudio && (
            <MediaKeyInput
              label={mediaInputLabels.AUDIO_LABEL}
              type={mediaTypes.AUDIO}
              value={form.audioKey}
              handleChange={(audioKey) => updateFormFields({ audioKey })}
              m={{ mt: 5 }}
              error={validationErrs.audioKey}
            />
          )}

          <T.P mb={2} mt={8} size="large" weight="bold">
            Options
          </T.P>

          {validationErrs?.options &&
            typeof validationErrs.options === 'string' && (
              <T.P mb={2} mt={2} color="error">
                {validationErrs.options}
              </T.P>
            )}

          {options.map((e, i) => {
            return (
              <>
                <BasicInput
                  label={`Option ${i + 1}`}
                  value={e.option}
                  handleChange={(value) => {
                    onOptionChange(value, e.id);
                  }}
                  m={{ mt: 5 }}
                  error={
                    validationErrs?.options &&
                    validationErrs?.options[i]?.option
                  }
                />

                {options.length === 1 && i === 0 ? null : (
                  <S.Button
                    type="link"
                    danger
                    ghost
                    mt={1}
                    onClick={() => removeOption(e.id)}
                    ml="auto"
                  >
                    <Icon icon="cross" />
                  </S.Button>
                )}
              </>
            );
          })}
          <S.Button
            type="link"
            ghost
            mt={5}
            mb={5}
            onClick={addOption}
            disabled={options.find((e) => !e.option)}
            ml="auto"
            mr="auto"
          >
            <Icon icon="plus" /> Add Option
          </S.Button>

          <T.P mb={2} mt={8} size="large" weight="bold">
            Pre Filled Options
          </T.P>

          {validationErrs?.preFilledAnswer &&
            typeof validationErrs.preFilledAnswer === 'string' && (
              <T.P mb={2} mt={2} color="error">
                {validationErrs.preFilledAnswer}ß
              </T.P>
            )}

          {preFilledAnswer.map((e, i) => {
            return (
              <>
                <BasicInput
                  label={`Pre-filled ${i + 1}`}
                  value={e.text}
                  handleChange={(value) => {
                    onPreFillTextChange(value, e.id);
                  }}
                  m={{ mb: 2, mt: 5 }}
                  disabled={e.hide}
                  error={
                    validationErrs?.preFilledAnswer &&
                    validationErrs?.preFilledAnswer[i]
                  }
                />
                <Checkbox
                  checked={e.hide}
                  onChange={(value) =>
                    onPreFillCheckBoxChange(value.target.checked, e.id)
                  }
                >
                  keep empty
                </Checkbox>
                {preFilledAnswer.length === 1 && i === 0 ? null : (
                  <S.Button
                    type="link"
                    danger
                    ghost
                    mt={1}
                    onClick={() => removePreFilled(e.id)}
                    ml="auto"
                  >
                    <Icon icon="cross" />
                  </S.Button>
                )}
              </>
            );
          })}

          <S.Button
            type="link"
            ghost
            mt={5}
            mb={5}
            onClick={addPreFilled}
            disabled={preFilledAnswer.find((e) => !e.text && !e.hide)}
            ml="auto"
            mr="auto"
          >
            <Icon icon="plus" /> Add Pre Fill Option
          </S.Button>
        </>
      )}
    </div>
  );
};

const validationSchema = {
  variation: string()
    .oneOf(Object.values(dragAndDrop2Variations.variations))
    .required('required field'),
  options: array()
    .of(
      object().shape({
        id: number().required(),
        option: string().required('required field'),
      })
    )
    .when('variation', {
      is: (variation) =>
        variation === dragAndDrop2Variations.variations.WITH_EXPLAINER_AUDIO,
      then: (schema) => schema.min(2).max(8),
      otherwise: (schema) => schema.required().min(1),
    }),
  preFilledAnswer: array()
    .of(string().nullable().notRequired())
    .when('variation', {
      is: (variation) =>
        variation === dragAndDrop2Variations.variations.WITH_EXPLAINER_AUDIO,
      then: (schema) =>
        schema.max(
          10,
          'The Pre Filled Options must be less than or equal to 10'
        ),
      otherwise: (schema) => schema.notRequired(),
    }),
  correctAnswer: string().required('required field'),
  audioKey: string().when('variation', {
    is: (variation) => variation === dragAndDrop2Variations.variations.DEFAULT,
    then: (schema) => schema.required('required field'),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  textAudio: string().when('variation', {
    is: (variation) =>
      variation === dragAndDrop2Variations.variations.WITH_EXPLAINER_AUDIO,
    then: (schema) => schema.required('required field'),
    otherwise: (schema) => schema.notRequired(),
  }),
  textAudioKey: string().notRequired(),
};
export { validationSchema };

export default DragAndDrop2;
