import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const StyledIconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme, noPadding }) => (noPadding ? '0px' : theme.spacings[3])};

  cursor: pointer;
  outline: none;
  border: none;
  background: none;
`;

export const StyledButton = styled(Button)`
  border-radius: 16px;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
