import { Button, Icon, Loading, Typography as T } from 'components';
import * as S from './style';
import { getMediaUrl } from 'helpers';
import { Col } from 'components/Grid';
import { useNavigate, useParams } from 'react-router-dom';
import { navRoutes as R, audio, courseCodeTypes } from '../../../constants';
import { useGeneralState } from '../../../context/general-state';
import { Row } from 'antd';
import { useEffect, useState } from 'react';
import { useGetOrganisationByIdOrUniqueSlug } from 'api-calls/organisations.queries';
import Redirect from 'components/Route/Redirect';
import YoutubeVid from 'components/YoutubeVid';
import { useAuth } from 'context/auth';
import { useSiteSettings } from 'context/site-settings';

const LandingPage = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { uniqueSlug } = useParams();
  const { logout } = useAuth();
  const { courseName, isTpdCourse, courseCode } = useSiteSettings();

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: organisation, isSuccess: getOrgSuccess } =
    useGetOrganisationByIdOrUniqueSlug({ uniqueSlug });
  const openOverlay = () => {
    setIsOpen(true);
  };

  const closeOverlay = (e) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  const { state: generalState } = useGeneralState();

  if (!getOrgSuccess) {
    return <Loading />;
  }

  const isOrgHaveSameCourse =
    courseCode === courseCodeTypes.TPD
      ? organisation?.isTpdCourse
      : organisation?.isCmidCourse;
  if (
    getOrgSuccess &&
    (!organisation.allowLearnerSelfSignup || !isOrgHaveSameCourse)
  ) {
    return <Redirect to={R.GENERAL.NOT_FOUND} />;
  }

  const handelGetStarted = () => {
    const isOrgHaveMultipleCourses =
      organisation?.isTpdCourse && organisation?.isCmidCourse;
    if (isOrgHaveMultipleCourses) {
      navigate(R.LEARNER.HAVE_ACCOUNT.replace(':uniqueSlug', uniqueSlug));
    } else {
      navigate(R.LEARNER.SIGNUP.replace(':uniqueSlug', uniqueSlug));
    }
  };

  return (
    <S.Wrapper>
      <S.DefaultHeader>
        <S.CustomHeight>
          <S.ContentAndVoiceWrapper>
            <Button.PlayButton
              width={'56px'}
              height={'56px'}
              radius={12}
              iconProps={{
                height: '32px',
                width: '32px',
              }}
              color="neutralMain"
              backgroundColor="white"
              audioUrl={getMediaUrl(null, true, generalState?.preferredVoice)}
              fallbackText={`Welcome to ${courseName}`}
            />
            <S.Title weight="bold" color="neutralMain" ml="3" mt="1">
              Welcome to {courseName}
            </S.Title>
          </S.ContentAndVoiceWrapper>
          <S.ContentAndVoiceWrapper mt={5}>
            <Button.PlayButton
              width={'56px'}
              height={'56px'}
              radius={12}
              color="neutralMain"
              iconProps={{
                height: '32px',
                width: '32px',
              }}
              audioUrl={getMediaUrl(
                audio.WeBelieveNobodyShouldBeLeftOutOfLearningToReadThisAppIsAFreshEasyWayForAdultsToReadAtTheirOwnPaceYouCanLearnByYourselfWithATrainedReadingCoachOrWithAFriendOrFamilyMember,
                true,
                generalState?.preferredVoice
              )}
              fallbackText="We believe nobody should be left out of learning to read. This app
              is a fresh, easy way for adults to read at their own pace. You can
              learn by yourself, with a trained reading coach or with a friend
              or family member."
            />
            {isTpdCourse ? (
              <S.Text>
                We believe nobody should be left out of learning to read. This
                app is a fresh, easy way for adults to read at their own pace.
                You can learn by yourself, with a trained reading coach or with
                a friend or family member.
              </S.Text>
            ) : (
              <S.Text>
                We believe nobody should be left out of learning basic numeracy
                skills. This app offers a fresh, easy way for adults to improve
                their math skills at their own pace. You can learn on your own,
                with the help of a trained math coach, or alongside a friend or
                family member.
              </S.Text>
            )}
          </S.ContentAndVoiceWrapper>
          <S.ClickBtn
            variant="primary"
            bgColor="secondaryMain"
            disabled={false}
            type="submit"
            mt="5"
            width="326px"
            showSound
            onClick={handelGetStarted}
            iconProps={{
              height: '32px',
              width: '32px',
            }}
            audioUrl={getMediaUrl(null, true, generalState?.preferredVoice)}
            fallbackText={`Try ${courseName} For Free`}
          >
            Try {courseName} For Free
          </S.ClickBtn>
        </S.CustomHeight>
        <S.BgWrapper onClick={openOverlay}>
          <S.PlayButtonWrapper>
            <S.OpenButton>
              <svg
                width="50"
                height="56"
                viewBox="0 0 50 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M47.3752 23.7293L7.64021 0.788039C6.89044 0.355451 6.04003 0.12779 5.17441 0.12793C4.3088 0.12807 3.45846 0.356005 2.70883 0.788836C1.95919 1.22167 1.33667 1.84415 0.903788 2.59375C0.470905 3.34335 0.242911 4.19367 0.24271 5.05929V50.9418C0.239468 51.8087 0.465303 52.6612 0.897365 53.4128C1.32943 54.1644 1.95238 54.7885 2.70317 55.222C3.45396 55.6555 4.30595 55.883 5.1729 55.8814C6.03985 55.8799 6.891 55.6493 7.64021 55.213L47.3752 32.2718C48.1248 31.8388 48.7473 31.2162 49.1801 30.4664C49.6129 29.7167 49.8407 28.8662 49.8407 28.0005C49.8407 27.1348 49.6129 26.2844 49.1801 25.5347C48.7473 24.7849 48.1248 24.1623 47.3752 23.7293ZM45.1877 28.4793L5.45271 51.4243C5.3692 51.4761 5.27287 51.5036 5.17458 51.5036C5.0763 51.5036 4.97997 51.4761 4.89646 51.4243C4.81015 51.3772 4.73848 51.3073 4.68931 51.2222C4.64013 51.1371 4.61536 51.0401 4.61771 50.9418V5.05929C4.61536 4.96102 4.64013 4.864 4.68931 4.77888C4.73848 4.69377 4.81015 4.62385 4.89646 4.57679C4.98132 4.52656 5.07785 4.49941 5.17646 4.49804C5.27394 4.49905 5.36935 4.52625 5.45271 4.57679L45.189 27.5193C45.2733 27.5682 45.3433 27.6384 45.392 27.7228C45.4406 27.8073 45.4663 27.9031 45.4663 28.0005C45.4663 28.098 45.4406 28.1938 45.392 28.2782C45.3433 28.3627 45.2733 28.4329 45.189 28.4818L45.1877 28.4793Z"
                  fill="white"
                />
              </svg>
            </S.OpenButton>
          </S.PlayButtonWrapper>
        </S.BgWrapper>
        {isOpen && (
          <S.Overlay onClick={closeOverlay}>
            <S.OverlayContent>
              <YoutubeVid
                videoId={
                  courseCode === courseCodeTypes.TPD
                    ? '8g0Tk3XFTVA'
                    : 'ndADO8BTUTI'
                }
              />
            </S.OverlayContent>
            <S.CloseButton
              handleClick={closeOverlay}
              aria-label="Close video"
              noPadding
            >
              <Icon icon="close" color="white" />
            </S.CloseButton>
          </S.Overlay>
        )}
      </S.DefaultHeader>

      <S.BgGray>
        <S.MotherImageWrapper>
          <S.BgMotherWrapper />
          <S.MiddleCircle />
        </S.MotherImageWrapper>

        <S.CustomHeight w={[12, 12, 6]} height="200px">
          <S.ContentAndVoiceWrapper>
            <Button.PlayButton
              width={'56px'}
              height={'56px'}
              radius={12}
              iconProps={{
                height: '32px',
                width: '32px',
              }}
              color="neutralMain"
              backgroundColor="white"
              audioUrl={getMediaUrl(
                audio.inALittleOver7WeeksIHadGoneFromBeingANonReaderToBeingAbleToReadShortStoryBooksTurningPagesLearner,
                true,
                generalState?.preferredVoice
              )}
              fallbackText={
                isTpdCourse
                  ? 'In a little over 7 weeks I had gone from being a non-reader to being able to read short story books.'
                  : 'In just over 7 weeks, I went from struggling with math to being able to solve basic math problems.'
              }
            />
            <S.QuoteWithAuthor>
              {isTpdCourse ? (
                <T.P size="large" mt="0" maxWidth="474px" weight="bold">
                  In a little over 7 weeks I had gone from being a non-reader to
                  being able to read short story books.
                </T.P>
              ) : (
                <T.P size="large" mt="0" maxWidth="474px" weight="bold">
                  In just over 7 weeks, I went from struggling with math to
                  being able to solve basic math problems.
                </T.P>
              )}
              <T.P size="med" weight="bold" mt="24px" display="block">
                {courseName} learner
              </T.P>
            </S.QuoteWithAuthor>
          </S.ContentAndVoiceWrapper>
        </S.CustomHeight>
      </S.BgGray>
      <Row style={{ height: '290px', paddingLeft: 0 }}>
        <Col
          w={[12, 12, 12]}
          mt="64px"
          style={{ display: 'flex', flexFlow: 'column' }}
        >
          <S.ContentAndVoiceWrapper>
            <S.Title weight="bold" color="neutralMain" ml="3" mt="1">
              Give it a try today for free.
            </S.Title>
          </S.ContentAndVoiceWrapper>
          <Button.BasicButton
            variant="primary"
            bgColor="secondaryMain"
            disabled={false}
            type="submit"
            mt="5"
            width="240px"
            showSound
            onClick={handelGetStarted}
            audioUrl={getMediaUrl(
              audio.getStarted,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="Get Started"
          >
            Get Started
          </Button.BasicButton>
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default LandingPage;
