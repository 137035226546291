import { useFetch, formatQueryError } from '../utils/queries';
import { MEDIA_BASE, mediaTypes, VO_REQUIRED } from '../constants';

function useGetMediaCms(params, options = {}) {
  const context = useFetch({
    key: [`GET_MEDIA_CMS`, params],
    url:
      params.type === mediaTypes.VO_AUDIO ? `${VO_REQUIRED}` : `${MEDIA_BASE}`,
    axiosParams: params,
    reactQueryConfig: options,
  });
  return { ...context, media: context.data, error: formatQueryError(context) };
}

function useGetMediaCmsById({ id, mediaKey, isNewSession }, options = {}) {
  const url = mediaKey
    ? `${MEDIA_BASE}/mediaKey?key=${mediaKey}`
    : `${MEDIA_BASE}/${id}`;
  const context = useFetch({
    key: isNewSession ? null : [`GET_MEDIA_CMS_BY_ID`, { id, mediaKey }],
    url: isNewSession ? null : url,
    reactQueryConfig: { ...options },
  });
  return { ...context, media: context.data, error: formatQueryError(context) };
}

function useGetMediaTags(_, options = {}) {
  const context = useFetch({
    key: [`GET_MEDIA_TAGS`],
    url: `${MEDIA_BASE}/tags`,
    reactQueryConfig: options,
  });
  return { ...context, tags: context.data, error: formatQueryError(context) };
}

export { useGetMediaCms, useGetMediaTags, useGetMediaCmsById };
