import { Typography as T, Button } from '../../../components';
import { Row, Col } from '../../../components/Grid';
import { audio } from '../../../constants';
import { BasicButton } from '../../../components/Button';
import { COACH, COMMON, LEARNER } from '../../../constants/nav-routes';
import turingPagesBooks from '../../../components/assets/turing-pages-books.png';
import * as S from './style';
import { getMediaUrl } from '../../../helpers';
import { useGeneralState } from '../../../context/general-state';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../context/auth';

const ProgressCheckThankYou = () => {
  const { state: generalState } = useGeneralState();
  const [searchParams] = useSearchParams();
  const selectMilestone = searchParams.get('selectMilestone');
  const { user } = useAuth();

  let link = LEARNER.DASHBOARD;

  if (generalState?.isPlayground) {
    link = COMMON.EXERCISES_PLAYGROUND;
  } else if (user.role === 'COACH') {
    link = COACH.LEARNER_DASHBOARD.replace(':id', user.selectedLearnerId);
  }
  if (selectMilestone === 'true') {
    link = LEARNER.SELECT_MILESTONE.replace(
      ':learnerId',
      user.selectedLearnerId
    );
  }

  return (
    <>
      <S.Wrapper>
        <Row jc="center">
          <Col w={[4, 10, 9]} jc="center">
            <S.PlayButtonWrapper mt="7">
              <Button.PlayButton
                width="56px"
                height="56px"
                audioUrl={getMediaUrl(
                  audio.thankYouLooksLikeYoureMakingSomeGreatProgress,
                  true,
                  generalState?.preferredVoice
                )}
                iconProps={{ width: 27.2 }}
                fallbackText="Thank you. Looks like you’re making some great progress. Now let’s keep going!"
              />

              <T.H2 ml="3" weight="regular">
                Thank you. Looks like you’re making some great progress. Now
                let’s keep going!
              </T.H2>
            </S.PlayButtonWrapper>
          </Col>
        </Row>
        <Row jc="center">
          <Col w={[3.5, 10, 9]} mt="5" jc="center">
            <S.Image src={turingPagesBooks} alt="progress check" />
          </Col>
        </Row>
      </S.Wrapper>
      <S.NextButtonWrapper mt="6" ai="end">
        <Col w={[4, 10, 9]} jc="center">
          <BasicButton
            variant="primary"
            icon="next"
            iconProps={{ width: '24px', height: '24px' }}
            to={link}
          />
        </Col>
      </S.NextButtonWrapper>
    </>
  );
};

export default ProgressCheckThankYou;
