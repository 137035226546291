import * as S from './style';
import RateButton from '../RateButton';

const RateButtons = ({ feeling, setFeeling }) => {
  return (
    <S.RateButtonsWrapper>
      <RateButton
        buttonId={1}
        setFeeling={setFeeling}
        selected={feeling === 'Very much'}
        variant="clickable"
      />
      <RateButton
        buttonId={2}
        setFeeling={setFeeling}
        selected={feeling === 'Yes, I am'}
        variant="clickable"
      />
      <RateButton
        buttonId={3}
        setFeeling={setFeeling}
        selected={feeling === 'Not really'}
        variant="clickable"
      />
      <RateButton
        buttonId={4}
        setFeeling={setFeeling}
        selected={feeling === 'No, I’m not'}
        variant="clickable"
      />
    </S.RateButtonsWrapper>
  );
};

export default RateButtons;
