import { useEffect, useReducer } from 'react';
import { useVoiceVisualizer, VoiceVisualizer } from 'react-voice-visualizer';
import { Button, Icon } from 'components';
import { Media } from '../../api-calls';
import { types as Ty } from '../../constants';
import * as S from './style';
import { useParams } from 'react-router-dom';
import { useAuth } from 'context/auth';
import moment from 'moment';

function reducer(state, newState) {
  return { ...state, ...newState };
}
const initState = {};
const AudioRecording = ({ handleSendMsg, isLoading }) => {
  const recorderControls = useVoiceVisualizer();
  const { receiverId } = useParams();
  const { user } = useAuth();
  const [uploadState, setUploadState] = useReducer(reducer, initState);

  const {
    startRecording,
    isRecordingInProgress,
    stopRecording,
    recordedBlob,
    clearCanvas,
    // error: recorderError,
  } = recorderControls;

  const handleSendRecording = () => {
    stopRecording();
    setUploadState({ loading: true });
  };

  const handleUpload = async (_recordedBlob) => {
    const { data: signedUrl, error: signedUrlErr } = await Media.getSignedUrl({
      fileType: _recordedBlob.type,
      fileSize: _recordedBlob.size,
      fileCategory: Ty.mediaTypes.MSG_AUDIO,
      key: `${user.id}-${receiverId}/${moment().format(
        'YYYY-MM-DD'
      )}/recorded-msg-${Date.now()}.webm`,
    });

    if (signedUrlErr) {
      setUploadState({
        uploadError: signedUrlErr.message,
        loading: false,
      });
      return;
    }

    const { error: s3Error } = await Media.uploadFileToS3({
      signedUrl: signedUrl.url,
      file: _recordedBlob,
    });

    if (s3Error) {
      setUploadState({
        uploadError: s3Error.message,
        loading: false,
      });
      return;
    }

    const { error, data } = await Media.createOrUpdateMedia({
      data: {
        type: Ty.mediaTypes.MSG_AUDIO,
        keys: [signedUrl.key],
      },
    });

    if (error) {
      setUploadState({
        uploadError: error.message,
        loading: false,
      });
      return;
    }
    clearCanvas();
    setUploadState({ loading: false });

    handleSendMsg(Ty.chatMessagesTypes.AUDIO, data.id);
  };

  useEffect(() => {
    if (uploadState.loading && recordedBlob) {
      handleUpload(recordedBlob);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordedBlob, uploadState.loading]);

  return (
    <>
      {isRecordingInProgress && (
        <S.RecordWrapper>
          <Button.IconButton
            style={{
              position: 'absolute',
              zIndex: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={clearCanvas}
          >
            <Icon icon="cross" color="error" />
          </Button.IconButton>
          <VoiceVisualizer
            controls={recorderControls}
            onlyRecording
            isDefaultUIShown={false}
            mainBarColor="#050D30"
            rounded={24}
            width="100%"
            height="40px"
            mainContainerClassName="voice-visualizer-main-container"
          />
        </S.RecordWrapper>
      )}
      <Button.IconButton
        isWithLoading
        style={{
          position: 'absolute',
          bottom: '8px',
          right: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={!isRecordingInProgress ? startRecording : handleSendRecording}
        loading={isLoading || uploadState.loading}
      >
        <Icon
          icon={!isRecordingInProgress ? 'mic' : 'send'}
          color="primaryMain"
        />
      </Button.IconButton>
    </>
  );
};

export default AudioRecording;
