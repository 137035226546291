import styled from '@emotion/styled';
import { Button } from 'components';

export const ButtonsWrapper = styled.div`
  width: 312px;
  margin: 0 auto;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DefaultModalWrapper = styled.div`
  position: relative;
  padding: 32px;
`;
export const CloseButton = styled(Button.IconButton)`
  position: absolute;
  z-index: 1001;
  top: 8px;
  right: 8px;
  font-size: 24px;
  color: white;
  cursor: pointer;
  background: none;
  border: none;
  pointer-events: all;
  svg {
    width: 32px;
    height: 32px;
  }
`;
