import React from 'react';

const RighSteptToLeftStep = ({ width, height, color, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '304'}
      height={height || '210'}
      fill="none"
      viewBox="0 0 304 210"
    >
      <path
        stroke={color || '#050D30'}
        stroke-dasharray="13 13"
        stroke-linecap="round"
        stroke-width="4"
        d="M2 208s20.513-78.691 162.179-91.221C286.615 105.949 302 79.187 302 2"
      />
    </svg>
  );
};

export default RighSteptToLeftStep;
