import styled from '@emotion/styled';
import { Preview as ExercisePreview } from 'CMS/Exercises/style';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.media.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;
export const Form = styled.form`
  max-width: 430px;
  flex: 1;
`;
export const Preview = styled(ExercisePreview)`
  max-width: ${({ increaseWidth }) => (increaseWidth ? '700px' : '500px')};
  border: 1px blue dashed;
`;
