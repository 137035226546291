import { LEARNING_SESSIONS_BASE } from '../constants';
import { usePost, formatQueryError } from '../utils/queries';

function useCreateLearningSession(_, options = {}) {
  const context = usePost({
    url: `${LEARNING_SESSIONS_BASE}`,
    reactQueryConfig: options,
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
}

export { useCreateLearningSession };
