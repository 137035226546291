import styled from '@emotion/styled';

export const CopyLinkWrapper = styled('button')`
  margin-top: ${({ theme }) => theme.spacings[3]};
  display: flex;
  cursor: pointer;
  border: none;
  background: none;
`;

export const CopyIconWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.neutralLight100};
  box-shadow: 0px 1px 3px -1px rgba(4, 13, 38, 0.1),
    0px 2px 5px -1px rgba(4, 13, 38, 0.11);
  border-radius: 8px;
`;

export const CopyTextWrapper = styled('div')`
  margin-left: ${({ theme }) => theme.spacings[2]};
`;
