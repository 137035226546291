import theme from '../../../theme';

const ActiveUser = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '41'}
    height={height || '40'}
    fill="none"
    viewBox="0 0 41 40"
  >
    <path
      fill={color || theme.colors.neutralMain}
      fill-rule="evenodd"
      d="M8.833 12a7.2 7.2 0 1 0 14.4 0 7.2 7.2 0 0 0-14.4 0Zm19.2 20.8c0-6.627-5.372-12-12-12-6.627 0-12 5.373-12 12V36a.8.8 0 0 0 .8.8h22.4a.8.8 0 0 0 .8-.8v-3.2Z"
      clip-rule="evenodd"
    />
    <path
      fill="#8CB036"
      stroke="#F0F1F5"
      d="M29.633 29.3a6.9 6.9 0 1 0 0-13.8 6.9 6.9 0 0 0 0 13.8Z"
    />
  </svg>
);
export default ActiveUser;
