import { Row, Col } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import {
  IconWithParagraph,
  NewLearnerHelloCard,
  Tip,
} from '../../../components';
import { BasicButton } from '../../../components/Button';
import { useParams } from 'react-router-dom';
import { COACH } from '../../../constants/nav-routes';
import * as S from './style';
import { BackButton } from 'components/BackButton';

const NewLearner = () => {
  const { userId } = useParams();

  return (
    <S.Wrapper>
      <BackButton mb="5" />
      <T.P size="med" weight="bold">
        Getting started
      </T.P>
      <T.P mt="2">
        Thank you so much for accepting a new learner! Now we need to get them
        onboarded. Here’s a reminder of what you need to do now.
      </T.P>
      <S.CardWrapper>
        <NewLearnerHelloCard learnerId={userId} />
      </S.CardWrapper>
      <Row mt="5">
        <Col w={[4, 12, 12]}>
          <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
            <T.P>
              Set up an <strong>introductory meeting</strong> to get to know
              your learner. In this meeting click ‘Start onboarding’ below so
              you can run through safeguarding and agree how and when you will
              meet.
            </T.P>
          </IconWithParagraph>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 12]}>
          <IconWithParagraph icon="smallTick" ai="flex-start" mt="3">
            <T.P>
              In this meeting you will also{' '}
              <strong>take them through a run-through</strong> on how the app
              and programme works.
            </T.P>
          </IconWithParagraph>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 12]}>
          <IconWithParagraph icon="smallTick" ai="flex-start" mt="3">
            <T.P>
              When you get to the learner <strong>progress profile</strong>,{' '}
              please make sure to explain it’s not a test, just exploring where
              they're currently at with their reading.{' '}
            </T.P>
          </IconWithParagraph>
        </Col>
      </Row>
      <Row mt="5">
        <Col w={[4, 12, 12]}>
          <S.TipWrapper>
            <Tip
              text={`Please note that you will need to be with them in person or on a call to complete these stages. If you feel you need to stop and split this over a few sessions, that is fine. The tool will pick up wherever you left off. In total it should take about 30-40 minutes.`}
            />
          </S.TipWrapper>
        </Col>
      </Row>
      <S.ButtonsWrapper>
        <Row>
          <Col w={[4, 12, 12]}>
            <BasicButton
              variant="primary"
              to={COACH.INTRODUCTORY_MEETING.replace(':userId', userId)}
            >
              <T.P color="white" weight="semi">
                Start onboarding
              </T.P>
            </BasicButton>
          </Col>
        </Row>
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export default NewLearner;
