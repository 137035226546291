const FormatAlignLeft = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 11.125C2.82292 11.125 2.67458 11.065 2.555 10.945C2.435 10.8254 2.375 10.6771 2.375 10.5C2.375 10.3229 2.435 10.1744 2.555 10.0544C2.67458 9.93479 2.82292 9.875 3 9.875H9.25C9.42708 9.875 9.57563 9.93479 9.69563 10.0544C9.81521 10.1744 9.875 10.3229 9.875 10.5C9.875 10.6771 9.81521 10.8254 9.69563 10.945C9.57563 11.065 9.42708 11.125 9.25 11.125H3ZM3 6.125C2.82292 6.125 2.67458 6.065 2.555 5.945C2.435 5.82542 2.375 5.67708 2.375 5.5C2.375 5.32292 2.435 5.17438 2.555 5.05438C2.67458 4.93479 2.82292 4.875 3 4.875H9.25C9.42708 4.875 9.57563 4.93479 9.69563 5.05438C9.81521 5.17438 9.875 5.32292 9.875 5.5C9.875 5.67708 9.81521 5.82542 9.69563 5.945C9.57563 6.065 9.42708 6.125 9.25 6.125H3ZM3 8.625C2.82292 8.625 2.67458 8.565 2.555 8.445C2.435 8.32542 2.375 8.17708 2.375 8C2.375 7.82292 2.435 7.67437 2.555 7.55437C2.67458 7.43479 2.82292 7.375 3 7.375H13C13.1771 7.375 13.3254 7.43479 13.445 7.55437C13.565 7.67437 13.625 7.82292 13.625 8C13.625 8.17708 13.565 8.32542 13.445 8.445C13.3254 8.565 13.1771 8.625 13 8.625H3ZM3 13.625C2.82292 13.625 2.67458 13.565 2.555 13.445C2.435 13.3254 2.375 13.1771 2.375 13C2.375 12.8229 2.435 12.6746 2.555 12.555C2.67458 12.435 2.82292 12.375 3 12.375H13C13.1771 12.375 13.3254 12.435 13.445 12.555C13.565 12.6746 13.625 12.8229 13.625 13C13.625 13.1771 13.565 13.3254 13.445 13.445C13.3254 13.565 13.1771 13.625 13 13.625H3ZM3 3.625C2.82292 3.625 2.67458 3.565 2.555 3.445C2.435 3.32542 2.375 3.17708 2.375 3C2.375 2.82292 2.435 2.67437 2.555 2.55437C2.67458 2.43479 2.82292 2.375 3 2.375H13C13.1771 2.375 13.3254 2.43479 13.445 2.55437C13.565 2.67437 13.625 2.82292 13.625 3C13.625 3.17708 13.565 3.32542 13.445 3.445C13.3254 3.565 13.1771 3.625 13 3.625H3Z"
      fill="currentColor"
    />
  </svg>
);

export default FormatAlignLeft;
