import theme from '../../../theme';

const SandGlass = ({ width, height, color, ...props }) => (
  <svg
    width={width || '40'}
    height={height || '40'}
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M32.4998 32.5002C32.4998 26.2602 28.0198 20.9802 22.0998 20.0202V19.3802C28.0198 18.4202 32.4998 13.1402 32.4998 6.9002C33.4598 6.9002 34.0998 6.1002 34.0998 5.3002C34.0998 4.5002 33.4598 3.7002 32.4998 3.7002H6.8998C6.0998 3.7002 5.2998 4.5002 5.2998 5.3002C5.2998 6.1002 6.0998 6.9002 6.8998 6.9002C6.8998 13.1402 11.3798 18.4202 17.2998 19.3802V19.8602C11.3798 20.9802 6.8998 26.2602 6.8998 32.5002C6.0998 32.5002 5.2998 33.3002 5.2998 34.1002C5.2998 34.9002 6.0998 35.7002 6.8998 35.7002H32.4998C33.4598 35.7002 34.0998 34.9002 34.0998 34.1002C34.0998 33.3002 33.4598 32.5002 32.4998 32.5002ZM12.4998 13.3002C11.0598 11.5402 10.0998 9.3002 10.0998 6.9002H29.2998C29.2998 9.3002 28.3398 11.5402 26.8998 13.3002H12.4998ZM13.2998 27.7002C14.7398 25.7802 17.1398 24.5002 19.6998 24.5002C22.2598 24.5002 24.6598 25.7802 26.0998 27.7002H13.2998Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default SandGlass;
