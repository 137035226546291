import { useMemo, useRef } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput } from '../../../../components/Inputs';
import * as S from './style';
import { Icon } from '../../../../components';
import { string, number, array, object } from 'yup';
import MDEditor from '../../../../components/MDEditor';
import { MediaKeyInput } from '../../../components/MediaKeyInput';
import { Checkbox } from 'antd';
import { mediaTypes } from '../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';

const initialData = {
  text: '',
  correctOptions: [{ id: 0, splitOption: '', option: '' }],
  textAudioKey: '',
  textAudio: '',
  exampleStep: false,
};

const TextFinderComprehension = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateDataFields } = useStepForm(initialRef.current);
  const { data, validationErrs } = state;

  const correctOptions = useMemo(
    () =>
      data?.correctOptions?.map((e, i) => ({
        ...e,
        id: i,
      })) || [{ id: 0, splitOption: '', option: '' }],
    [data?.correctOptions]
  );

  const removeCorrectOptions = (id) => {
    updateDataFields({
      correctOptions: correctOptions.filter((e) => e.id !== id),
    });
  };

  const onCorrectOptionsSplitChange = (value, id) => {
    updateDataFields({
      correctOptions: correctOptions.map((e) => {
        if (e.id !== id) return e;
        return { ...e, splitOption: value };
      }),
    });
  };
  const onCorrectOptionsOptionChange = (value, id) => {
    updateDataFields({
      correctOptions: correctOptions.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  const addOption = () => {
    const maxId = Math.max(...correctOptions.map((e) => e.id), 0);
    updateDataFields({
      correctOptions: [
        ...correctOptions,
        { id: maxId + 1, option: '', splitOption: '' },
      ],
    });
  };

  return (
    <div>
      <BasicInput
        label="Explainer Text"
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        m={{ mt: 8 }}
        error={validationErrs?.textAudio}
      />
      <MediaKeyInput
        label={`Explainer Text file Key`}
        type={mediaTypes.TEXT_AUDIO}
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs?.textAudioKey}
      />

      <T.P mt={8}>
        <Checkbox
          checked={data.exampleStep}
          onChange={(e) => updateDataFields({ exampleStep: e.target.checked })}
        >
          <T.P size="med" weight="bold">
            Example Step?
          </T.P>
        </Checkbox>
        check to allow learner to skip
      </T.P>

      <MDEditor
        value={data.text}
        onChange={(text) => updateDataFields({ text })}
        mode={'edit'}
        label="Text"
        m={{ mt: 8 }}
        helper='put "%" around the letter/word to make it selectable'
        error={validationErrs.text}
      />

      <T.P mt={8} size="large" weight="bold">
        Syllables table{' '}
      </T.P>

      {validationErrs?.correctOptions &&
        typeof validationErrs.correctOptions === 'string' && (
          <T.P mb={2} mt={2} color="error">
            {validationErrs.correctOptions}
          </T.P>
        )}

      {correctOptions.map((e, i) => {
        return (
          <>
            <BasicInput
              label={`Word ${i + 1}`}
              value={e.option}
              handleChange={(value) => {
                onCorrectOptionsOptionChange(value, e.id);
              }}
              m={{ mb: 2, mt: 5 }}
              helper='add the words you quoted with the "%"'
              error={
                validationErrs?.correctOptions &&
                validationErrs?.correctOptions[i]?.option
              }
            />

            <BasicInput
              label="Syllables Word"
              value={e.splitOption}
              handleChange={(value) => {
                onCorrectOptionsSplitChange(value, e.id);
              }}
              m={{ mb: 2, mt: 5 }}
              helper='e.g. "fit/ness"'
              error={
                validationErrs?.correctOptions &&
                validationErrs?.correctOptions[i]?.splitOption
              }
            />

            {correctOptions.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeCorrectOptions(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}

      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addOption}
        disabled={correctOptions.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add words
      </S.Button>
    </div>
  );
};

const validationSchema = {
  text: string().required('required field'),
  correctOptions: array()
    .of(
      object()
        .shape({
          id: number().required(),
          splitOption: string().required('required field'),
          option: string().required('required field'),
        })
        .required()
    )
    .required('You must provide correct words')
    .min(1, 'You must provide correct words'),
  textAudioKey: string().notRequired(),
  textAudio: string().notRequired(),
};
export { validationSchema };

export default TextFinderComprehension;
