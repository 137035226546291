import getMediaUrl from './get-media-url';

const replaceMediaKeysWithUrls = (step, voice) => {
  const options = step.data?.options
    ? step.data.options.map((o) =>
        Array.isArray(o)
          ? o
          : {
              ...o,
              audioUrl: getMediaUrl(o.audioKey, true, voice),
              imageUrl: getMediaUrl(o.imageKey, false),
            }
      )
    : undefined;

  const multipleWords = step.data?.multipleWords?.map((m) => ({
    ...m,
    audioUrl: getMediaUrl(m.audioKey, true, voice),
  }));
  const correctOptions = step.data?.correctOptions?.map((c) => ({
    ...c,
    ...(c.imageKey && { imageUrl: getMediaUrl(c.imageKey, false) }),
    ...(c.audioUrl && { audioUrl: getMediaUrl(c.audioKey, true, voice) }),
  }));

  return {
    ...step,
    data: {
      ...step.data,
      options,
      correctOptions,
      multipleWords,
      textAudioUrl: getMediaUrl(step.data?.textAudioKey, true, voice),
      questionAudioUrl: getMediaUrl(step.data?.questionAudioKey, true, voice),
    },
    audioUrl: getMediaUrl(step.audioKey, true, voice),
    imageUrl: getMediaUrl(step.imageKey, false),
  };
};

export default replaceMediaKeysWithUrls;
