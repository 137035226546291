import * as T from '../../Typography';
import { navRoutes as R } from '../../../constants';
import * as CommonStyled from '../style';
import * as S from './style';

import NewLearnerHelloImg from '../../assets/new_learner_hello.png';

export default function NewLearnerHelloCard({ learnerId }) {
  return (
    <CommonStyled.Wrapper>
      <div>
        <T.H2 mb={1}>Say hello and arrange the introductory meeting</T.H2>
        <T.P size="regular">
          To arrange the introductory meeting, please click the button below to
          send your new learner a message. In the message remember to:
        </T.P>
        <S.List mb={3}>
          <S.ListItem size="regular">Tell them a bit about yourself</S.ListItem>
          <S.ListItem size="regular">
            Suggest potential dates and times to meet online or in person
          </S.ListItem>
          <S.ListItem size="regular">
            Follow our{' '}
            <T.Link to="" external underline>
              Safeguarding Rules
            </T.Link>
          </S.ListItem>
        </S.List>
        <div>
          <S.SayHelloBtn
            to={R.COACH.CHAT_SAFEGUARD.replace(':receiverId', learnerId)}
            mb={5}
            variant="secondary"
            icon="message"
          >
            Say Hello
          </S.SayHelloBtn>
        </div>
        <T.Link
          size="regular"
          external
          underline
          to={`mailto:${R.EXTERNAL.CONTACT_US}`}
        >
          Need help setting up the meeting? Contact Shannon Trust
        </T.Link>
      </div>
      <CommonStyled.ImgWrapper>
        <img
          src={NewLearnerHelloImg}
          alt="Hello new learner"
          width={120}
          height={120}
        />
      </CommonStyled.ImgWrapper>
    </CommonStyled.Wrapper>
  );
}
