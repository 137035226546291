import React, { useEffect, useRef } from 'react';
import { Input } from 'antd';
import './ChatTextArea.css'; // Assuming you add the styles in this file
import theme from 'theme';
import { Icon, Button } from 'components';
import { types as Ty } from '../../constants';
import * as S from './style';
import AudioRecording from './AudioRecording';

const { TextArea } = Input;
const ChatTextArea = ({ handleSendMsg, isLoading, textMsg, setTextMsg }) => {
  const textAreaRef = useRef(null);
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (!e.shiftKey && textMsg.trim()) {
        e.preventDefault();
        handleSendMsg(Ty.chatMessagesTypes.TEXT);
      }
    }
  };
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style = { height: '56px' }; // Reset height to the fixed value
      textAreaRef.current.style.height = `${textAreaRef.current.resizableTextArea.textArea.scrollHeight}px`;
    }
  }, [textMsg]);

  return (
    <S.ChatTextAreaWrapper>
      <TextArea
        value={textMsg}
        ref={textAreaRef}
        onChange={(e) => setTextMsg(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Type your message..."
        autoSize={{ minRows: 1, maxRows: 5 }}
        className="chat-text-area ant-input"
        style={{
          borderRadius: '16px',
          resize: 'none',
          display: 'flex',
          alignItems: 'center',
          padding: '14px 16px',
          fontSize: '16px',
        }}
      />
      {textMsg ? (
        <Button.IconButton
          className="send-button"
          isWithLoading
          style={{
            background: theme.colors.primaryMain,
            color: theme.colors.primaryLight,
            borderRadius: '16px',
            width: '40px',
            position: 'absolute',
            bottom: '8px',
            right: '10px',
            height: '36px' /* Adjust height to fit nicely */,
            borderColor: '#1890ff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => handleSendMsg(Ty.chatMessagesTypes.TEXT)}
          loading={isLoading}
          disabled={!textMsg.trim()}
        >
          <Icon icon="send" color="primaryLight" />
        </Button.IconButton>
      ) : (
        <AudioRecording handleSendMsg={handleSendMsg} isLoading={isLoading} />
      )}
    </S.ChatTextAreaWrapper>
  );
};

export default ChatTextArea;
