import Icon from 'components/Icon';
import { Row, Col } from '../Grid';
import * as T from '../Typography';
import * as S from './style';

const PageIconBanner = ({ icon, color, title, children }) => {
  return (
    <Row inner>
      <Col w={[4, 12, 12]}>
        <S.Wrapper>
          <S.IconWrapper color={color}>
            <Icon icon={icon} width="60px" height="60px" color="white" />
          </S.IconWrapper>
          <div>
            <T.H1 mtM={2} mb={4}>
              {title}
            </T.H1>
            {children}
          </div>
        </S.Wrapper>
      </Col>
    </Row>
  );
};

export default PageIconBanner;
