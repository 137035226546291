import StepForm from './StepForm';
import { useParams } from 'react-router-dom';
import { useRef, useState, useReducer, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router';
import validate from '../../../validation/schemas/add-custom-step';
import {
  useUpdateCustomTrainingStepById,
  useGetCustomTrainingStepById,
  useDeleteCustomTrainingStepById,
  useGetAllCustomTrainingSteps,
} from '../../../api-calls/custom-training.queries';
import { useAuth } from '../../../context/auth';
import { navRoutes } from '../../../constants';
import DeleteConfirmModal from './DeleteConfirmModal';
import SuccessModal from './SuccessModal';
import { Col, Row } from 'components/Grid';
import { BackButton } from 'components/BackButton';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }
  return { ...state, ...value };
}

const initialState = {
  form: {
    title: '',
    introduction: '',
    videoLink: '',
    description: `<p style="text-align:left;"></p>`,
    order: 1,
  },
  httpError: '',
  validationErrs: {},
};

const TrainingCustomizationEditStep = () => {
  const { user } = useAuth();
  const [isEditMode, setIsEditMode] = useState(true);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const { stepId } = useParams();
  const navigate = useNavigate();
  const submitAttempt = useRef(false);

  const steps = useGetAllCustomTrainingSteps({
    organisationId: user.organisationId,
  });

  const {
    mutateAsync: deleteStep,
    error: deleteError,
    isLoading: deleteLoading,
  } = useDeleteCustomTrainingStepById({
    stepId,
  });

  const handleDelete = async () => {
    try {
      setIsEditMode(false);
      await deleteStep();
      setIsConfirmModalVisible(false);
      setIsSuccessModalVisible(true);
    } catch (error) {
      setState({
        httpError: error?.response?.data?.message,
        validationErrs: { ...validationErrs },
      });
    }
  };

  const { data: stepData, isLoading: stepDataLoading } =
    useGetCustomTrainingStepById({
      stepId,
    });

  useEffect(() => {
    if (!stepDataLoading && stepData) {
      setState((prevState) => ({
        form: {
          ...prevState.form,
          title: stepData?.title,
          introduction: stepData?.introduction,
          videoLink: stepData?.videoLink,
          description: stepData?.description,
          order: stepData?.order,
        },
      }));
    }
  }, [stepData, stepDataLoading]);

  const [state, setState] = useReducer(reducer, initialState);
  const { validationErrs, form } = state;

  const {
    mutateAsync,
    error: httpError,
    isLoading,
  } = useUpdateCustomTrainingStepById();

  const setFormData = (data) =>
    setState((prevState) => ({
      form: { ...prevState.form, ...data },
    }));

  const validateForm = useCallback(() => {
    try {
      validate({
        ...state.form,
      });
      setState({ validationErrs: { hasError: false }, httpError: false });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({
          validationErrs: { ...error.inner, hasError: true },
        });
      }
      return false;
    }
  }, [state.form]);

  const handleSubmit = async (e) => {
    setIsEditMode(true);
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();

    if (isValid) {
      try {
        await mutateAsync({
          ...state.form,
          organisationId: user.organisationId,
          stepId: stepId,
        });
        setIsSuccessModalVisible(true);
      } catch (error) {
        setState({
          httpError: error?.response?.data?.message,
          validationErrs: { ...validationErrs },
        });
      }
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
  }, [form, validateForm]);

  return (
    <Row jc="center">
      <Col w={[4, 10, 10]}>
        <BackButton link={navRoutes.FACILITATOR.TRAINING_CUSTOMIZATION} />
      </Col>
      <Col w={[4, 10, 10]}>
        <StepForm
          formType="edit"
          setFormData={setFormData}
          form={form}
          validationErrs={validationErrs}
          httpError={httpError?.message || deleteError?.message}
          loading={isLoading || deleteLoading}
          handleSubmit={handleSubmit}
          handleDelete={() => setIsConfirmModalVisible(true)}
          userRole={user.role}
          orderDropdownOptions={steps?.customTrainingSteps?.map((_, index) => ({
            value: index + 1,
            label: `${index + 1}`,
          }))}
        />
        <DeleteConfirmModal
          onCancel={() => setIsConfirmModalVisible(false)}
          onConfirm={handleDelete}
          isModalVisible={isConfirmModalVisible}
          setIsModalVisible={setIsConfirmModalVisible}
        />
        <SuccessModal
          message={
            isEditMode
              ? 'You have edited this step in your coaches’ training.'
              : 'You have deleted this step from your coaches’ training.'
          }
          isModalVisible={isSuccessModalVisible}
          setIsModalVisible={setIsSuccessModalVisible}
          onSuccess={() =>
            navigate(navRoutes.FACILITATOR.TRAINING_CUSTOMIZATION)
          }
        />
      </Col>
    </Row>
  );
};

export default TrainingCustomizationEditStep;
