const setColor = (type, primaryColor) => {
  const { h } = primaryColor;

  if (type === 'primary') {
    return {
      main: { h, s: 0.65, l: 0.55 },
      mid30: {
        h,
        s: 1,
        l: 0.95,
      },
      light: { h, s: 1, l: 0.98 },
    };
  }
  if (type === 'quaternary') {
    return {
      main: { h, s: 0.46, l: 0.25 },
      mid30: { h, s: 1, l: 0.97 },
      light: { h, s: 0.16, l: 0.94 },
    };
  }

  if (type === 'neutral') {
    return {
      main: { h, s: 0.81, l: 0.07 },
    };
  }

  // eslint-disable-next-line no-console
  console.error('no type provided');
};

const formatColor = (hslObj, a = 1) => {
  const { h, s, l } = hslObj;
  if ((!h && h !== 0) || (!s && s !== 0) || (!l && l !== 0)) {
    return hslObj;
  }

  return `hsla(${Math.round(h)}, ${Math.round(s * 100)}%, ${Math.round(
    l * 100
  )}%, ${a})`;
};

const rgbToHsl = (r, g, b) => {
  let h;
  let s;
  let l;
  r /= 255;
  g /= 255;
  b /= 255;
  const min = Math.min(r, g, b);
  const max = Math.max(r, g, b);
  l = (min + max) / 2;
  if (min === max) {
    h = s = 0; // achromatic
  } else {
    const delta = max - min;
    // eslint-disable-next-line no-nested-ternary
    s = l > 0.5 ? delta / (2 - max - min) : delta / (max + min);
    // eslint-disable-next-line no-nested-ternary
    h =
      max === r
        ? (g - b) / delta + (g < b ? 6 : 0)
        : max === g
        ? (b - r) / delta + 2
        : (r - g) / delta + 4;
    // eslint-disable-next-line no-mixed-operators
    h /= 6;
  }
  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);
  return { h, s, l };
};

const hexToRgb = (hex) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5), 16);
  return { r, g, b };
};

const convertHEXtoHSL = (hex) => {
  const { r, g, b } = hexToRgb(hex);
  const { h, s, l } = rgbToHsl(r, g, b);
  return { h, s, l };
};

const convertHEXtoHSLA = (hex, a = 1) => {
  const { r, g, b } = hexToRgb(hex);
  const { h, s, l } = rgbToHsl(r, g, b);
  return `hsla(${h}, ${s}%, ${l}%, ${a})`;
};

function hueToRgb(p, q, t) {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
}

function rgbaToHex(r = 0, g = 0, b = 0, a = 1) {
  const toHex = (x) => {
    const hex = x.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };
  return '#' + toHex(r) + toHex(g) + toHex(b) /*+ toHex(Math.round(a * 255))*/;
}

function hslaToHex(hslaString) {
  // Parse input string to extract h, s, l, a values
  const matches = hslaString.match(
    /hsla\((\d+),\s*(\d+)%,\s*(\d+)%,\s*([\d.]+)\)/i
  );
  if (!matches) return null; // Return null if input string is not a valid HSLA string

  let h = parseInt(matches[1]);
  let s = parseInt(matches[2]);
  let l = parseInt(matches[3]);
  let a = parseFloat(matches[4]);

  let r, g, b;
  h /= 360;
  s /= 100;
  l /= 100;
  if (Number(s) === 0) {
    r = g = b = l;
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hueToRgb(p, q, h + 1 / 3);
    g = hueToRgb(p, q, h);
    b = hueToRgb(p, q, h - 1 / 3);
  }
  r = Math.round(r * 255);
  g = Math.round(g * 255);
  b = Math.round(b * 255);
  return rgbaToHex(r, g, b, a); // Convert RGBA values
}

export {
  setColor,
  formatColor,
  rgbToHsl,
  hexToRgb,
  convertHEXtoHSL,
  convertHEXtoHSLA,
  hslaToHex,
};
