import { useFetch, usePost, formatQueryError } from '../utils/queries';
import { PROGRESS_CHECKS_BASE } from '../constants';

function useGetStepsByMilestoneId({ milestoneId }, options = {}) {
  const context = useFetch({
    key: [`GET_STEPS_BY_MILESTONE_ID`, { milestoneId }],
    url: `${PROGRESS_CHECKS_BASE}/${milestoneId}/steps`,
    reactQueryConfig: options,
  });
  return {
    ...context,
    steps: context.data,
    error: formatQueryError(context),
  };
}

function useCreateCompletedProgressCheck(_, options = {}) {
  return usePost({
    getUrl: ({ milestoneId }) =>
      `${PROGRESS_CHECKS_BASE}/${milestoneId}/completed`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: ({ milestoneId }) => [
        [`GET_STEPS_BY_MILESTONE_ID`, { milestoneId }],
      ],
    },
  });
}

export { useGetStepsByMilestoneId, useCreateCompletedProgressCheck };
