import StepForm from './StepForm';
import { navRoutes } from '../../../constants';
import SuccessModal from './SuccessModal';
import { useRef, useState, useReducer, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router';
import validate from '../../../validation/schemas/add-custom-step';
import {
  useAddCustomTrainingStep,
  useGetAllCustomTrainingSteps,
} from '../../../api-calls/custom-training.queries';
import { useAuth } from '../../../context/auth';
import { Col, Row } from 'components/Grid';
import { BackButton } from 'components/BackButton';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }
  return { ...state, ...value };
}

const initialState = {
  form: {
    title: '',
    introduction: '',
    videoLink: '',
    description: `<p style="text-align:left;"></p>`,
    order: 0,
  },
  httpError: '',
  validationErrs: {},
};

const TrainingCustomizationAddStep = () => {
  const { user } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [state, setState] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const submitAttempt = useRef(false);
  const { validationErrs, form } = state;

  const { customTrainingSteps = [] } = useGetAllCustomTrainingSteps({
    organisationId: user.organisationId,
  });

  const {
    mutateAsync,
    error: httpError,
    isLoading,
  } = useAddCustomTrainingStep({
    organisationId: user.organisationId,
  });

  const setFormData = (data) =>
    setState((prevState) => ({
      form: { ...prevState.form, ...data },
    }));

  const validateForm = useCallback(() => {
    try {
      validate({
        ...state.form,
      });
      setState({ validationErrs: { hasError: false }, httpError: false });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({
          validationErrs: { ...error.inner, hasError: true },
        });
      }
      return false;
    }
  }, [state.form]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();

    if (isValid) {
      try {
        await mutateAsync({
          ...state.form,
          organisationId: user.organisationId,
        });
        setIsModalVisible(true);
      } catch (error) {
        setState({
          httpError: error?.response?.data?.message,
          validationErrs: { ...validationErrs },
        });
      }
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
  }, [form, validateForm]);

  return (
    <Row jc="center">
      <Col w={[4, 10, 10]}>
        <BackButton link={navRoutes.FACILITATOR.TRAINING_CUSTOMIZATION} />
      </Col>
      <Col w={[4, 10, 10]}>
        <StepForm
          formType="add"
          setFormData={setFormData}
          form={state.form}
          validationErrs={validationErrs}
          httpError={httpError?.message}
          loading={isLoading}
          handleSubmit={handleSubmit}
          userRole={user.role}
          orderDropdownOptions={customTrainingSteps
            ?.map((_, index) => ({
              value: index + 1,
              label: `${index + 1}`,
            }))
            ?.concat({
              value: customTrainingSteps?.length + 1,
              label: `${customTrainingSteps?.length + 1}`,
            })}
        />
      </Col>
      <SuccessModal
        message="You have add a new custom step to your coaches’ training. "
        onSuccess={() => navigate(navRoutes.FACILITATOR.TRAINING_CUSTOMIZATION)}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </Row>
  );
};

export default TrainingCustomizationAddStep;
