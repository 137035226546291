import { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Layout from '../../../components/Layout';

import Loading from '../../../components/Loading';
import { navRoutes as NR } from '../../../constants';
import { useGetStepById } from '../../../api-calls/exercises.queries';
import { useAuth } from '../../../context/auth';
import { useGeneralState } from '../../../context/general-state';
import Formats from './Formats';
import { replaceMediaKeysWithUrls } from '../../../helpers';

import { useCreateCompletedStep } from '../../../api-calls/exercises.queries';
import { useExercise } from '../../../context/exercise';

const ExerciseStepContent = () => {
  const { stepId, exerciseId } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();
  const { state: generalState } = useGeneralState();
  const { exercise } = useExercise();

  const location = useLocation();
  const to = location.state?.to;
  const { mutate: createCompletedStep } = useCreateCompletedStep({
    id: stepId,
  });

  const { data: step = {}, isLoading } = useGetStepById({ exerciseId, stepId });

  const handleNext = async () => {
    if (!Number(stepId) || !auth.user.selectedLearnerId) {
      return navigate(NR.GENERAL.NOT_FOUND);
    }

    createCompletedStep(
      {
        id: stepId,
        learnerId: auth.user.selectedLearnerId,
        withCoach: true,
      },
      {
        onSuccess: () => {
          navigate(to || NR.COACH.LEARNERS_STUCK_AT);
        },
      }
    );
  };

  useEffect(() => {
    if (!Number(stepId) || !Number(exerciseId)) {
      navigate(NR.GENERAL.NOT_FOUND);
    }
  }, [stepId, exerciseId, navigate]);

  if (isLoading) return { Component: <Loading />, layout: 'general' };

  return Formats({
    exercise,
    handleNext,
    step: {
      ...replaceMediaKeysWithUrls(step, generalState?.preferredVoice),
    },
    stuckAtReview: true,
  });
};

const ExerciseStep = (props) => {
  const { Component, layout = 'general' } = ExerciseStepContent(props);

  return (
    <Layout
      layout={layout}
      headerProps={{
        type: 'progress',
      }}
      image="secondaryColor"
    >
      {Component}
    </Layout>
  );
};

export default ExerciseStep;
