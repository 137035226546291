import {
  useFetch,
  formatQueryError,
  usePost,
  usePatch,
  useDelete,
} from '../utils/queries';
import { VO_REQUIRED } from '../constants';

export function useGetVoRequiredByFileKey({ fileKey }, options = {}) {
  const context = useFetch({
    key: [`VO_REQUIRED`, { fileKey }],
    url: `${VO_REQUIRED}/${fileKey}`,
    reactQueryConfig: { ...options },
  });
  return { ...context, error: formatQueryError(context) };
}

export function useCreateVoRequired(_, options = {}) {
  const context = usePost({
    url: `${VO_REQUIRED}`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [[`VO_REQUIRED`], [`GET_STEP`]],
    },
  });

  return { ...context, error: formatQueryError(context) };
}

export function useUpdateVoRequiredById(_, options = {}) {
  const context = usePatch({
    getUrl: ({ id }) => `${VO_REQUIRED}/${id}`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [[`VO_REQUIRED`]],
    },
  });
  return { ...context, error: formatQueryError(context) };
}

export function useDeleteVoRequiredById(_, options = {}) {
  const context = useDelete({
    getUrl: ({ id }) => `${VO_REQUIRED}/${id}`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [[`VO_REQUIRED`], [`GET_STEP`]],
    },
  });

  return { ...context, error: formatQueryError(context) };
}
