import { useFetch, usePatch } from '../utils/queries';
import { BADGES_BASE } from '../constants';

function useGetBadges({ type }, options = {}) {
  const queryKey = ['BADGES', { type }];
  const context = useFetch({
    key: queryKey,
    url: BADGES_BASE,
    axiosParams: { type },
    reactQueryConfig: options,
  });
  return { ...context };
}

function UpdateBadges(_, options = {}) {
  return usePatch({
    url: BADGES_BASE,
    reactQueryConfig: { ...options, invalidateKeys: () => [['BADGES']] },
  });
}

export { useGetBadges, UpdateBadges };
