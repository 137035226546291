import styled from '@emotion/styled';

export const NoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-width: 300px;
  background-color: ${({ theme }) => theme.colors.neutralLight};
  border-radius: 16px;
  padding: 16px 24px;
  gap: 12px;
`;

export const DateWrapper = styled.div`
  display: flex;
`;

export const Value = styled.span`
  color: ${({ theme }) => theme.colors.neutralMain};
`;
