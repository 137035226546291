import { useGetCompletedTrainingStepById } from 'api-calls/facilitator.queries';
import { useAuth } from 'context/auth';

const useFacilitatorCompletedTrainingStep = () => {
  const { user } = useAuth();
  const id = user?.id;
  const {
    data,
    isLoading: getCurrentStepLoading,
    error: currentStepError,
    isSuccess,
    refetch: refetchCompletedTrainingStep,
  } = useGetCompletedTrainingStepById({ id });

  const completedTrainingStep =
    data?.tpdCompletedTrainingStep ?? data?.cmidCompletedTrainingStep ?? 0;

  return {
    completedTrainingStep,
    getCurrentStepLoading,
    currentStepError,
    isSuccess,
    refetchCompletedTrainingStep,
  };
};

export default useFacilitatorCompletedTrainingStep;
