import { useState, useEffect } from 'react';
import { ClearButton } from '../../../../../components/Button';
import { Alert } from '../../../../../components';
import { audio } from '../../../../../constants';
import * as S from '../style';

const alertInitState = {
  show: false,
  message: '',
  variant: '',
};

const GridInputWithValues = ({
  setEnableNext,
  correctAnswers,
  gridStart,
  gridEnd,
  gridReplacedWithText,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [selectedSquares, setSelectedSquares] = useState([]);
  const [selectedSquaresInOneTime, setSelectedSquaresInOneTime] = useState([]);
  const [showAlert, setShowAlert] = useState(alertInitState);

  useEffect(() => {
    const isSameLength = selectedSquares.length === correctAnswers.length;
    setEnableNext(
      isSameLength &&
        correctAnswers.every((ans) =>
          selectedSquares.includes(Number(ans.answer))
        )
    );
  }, [correctAnswers, selectedSquares, setEnableNext]);

  useEffect(() => {
    if (selectedSquaresInOneTime.length) {
      const isAllCorrect = selectedSquaresInOneTime.every((number) =>
        correctAnswers.find((ans) => Number(ans.answer) === number)
      );

      if (isAllCorrect) {
        setShowAlert({
          show: true,
          message: 'Nice, well done! 👍',
          variant: 'success',
        });
      } else {
        setShowAlert({
          show: true,
          message: 'Oops, try again! ❌',
          variant: 'fail',
        });
      }
    }

    if (selectedSquares.length === 0) {
      setShowAlert(alertInitState);
    }
  }, [correctAnswers, selectedSquaresInOneTime, selectedSquares]);

  const handleSquareFill = (number) => {
    setSelectedSquares((prev) => {
      if (prev.includes(number)) {
        return prev.filter((_number) => _number !== number);
      }
      setSelectedSquaresInOneTime((prev) => {
        return [...prev, number];
      });
      return [...prev, number];
    });
  };

  const handleMouseDown = (number) => {
    setIsDragging(true);
    setShowAlert(alertInitState);
    handleSquareFill(number);
  };

  const handleMouseEnter = (number) => {
    if (isDragging) {
      handleSquareFill(number);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setSelectedSquaresInOneTime([]);
  };

  if (!Number(gridEnd - gridStart)) {
    // [TODO]: Show error message
    return null;
  }

  const gridNumbers = Array.from(
    { length: gridEnd - gridStart + 1 },
    (_, i) => {
      const number = i + Number(gridStart);
      const replacedWithText = gridReplacedWithText.find(
        (item) => Number(item.number) === number
      )?.replacedWith;

      return replacedWithText || number;
    }
  );

  const calculateGridDimensions = (total) => {
    const sqrt = Math.sqrt(total);
    const rows = Math.ceil(sqrt);
    const cols = Math.ceil(total / rows);
    return { rows, cols };
  };

  const { rows, cols } = calculateGridDimensions(gridNumbers.length);

  return (
    <>
      {Array(rows)
        .fill()
        .map((_, rowIndex) => (
          <S.Row key={rowIndex}>
            {Array(cols)
              .fill()
              .map((_, colIndex) => {
                const number = gridNumbers[rowIndex * cols + colIndex];
                return number !== undefined ? (
                  <S.Square
                    key={`${rowIndex}-${colIndex}`}
                    selected={selectedSquares.includes(number)}
                    onMouseDown={() => handleMouseDown(number)}
                    onMouseEnter={() => handleMouseEnter(number)}
                    onMouseUp={(e) => handleMouseUp(e, number)}
                    content={number}
                  >
                    {number}
                  </S.Square>
                ) : null;
              })}
          </S.Row>
        ))}

      <ClearButton
        mt="4"
        hideIcon
        hideBorder
        handleClick={() => setSelectedSquares([])}
      />
      {showAlert.show && (
        <Alert
          mt="4"
          variant={showAlert.variant}
          message={showAlert.message}
          audio={audio.oopsTryAgain}
        />
      )}
    </>
  );
};

export default GridInputWithValues;
