import { useState } from 'react';
import * as T from '../../../../components/Typography';
import { PlayButton } from '../../../../components/Button';
import { Col, Row } from '../../../../components/Grid';
import StepFooterButtons from '../StepFooterButtons';
import * as S from './style';
import { shuffle } from './helpers';

const containsOption = (options, currentOption) =>
  options.some(
    (option) => JSON.stringify(option) === JSON.stringify(currentOption)
  );

const positions = {
  moreThan10: shuffle(Array.from(Array(20), (_, i) => i)),
  lessThan10: shuffle(Array.from(Array(10), (_, i) => i)),
};

const Step = ({
  data: { correctAnswer, textAudio, textAudioUrl, options = [] } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
}) => {
  const [shuffledOptions] = useState(() => shuffle(options));
  const [selectedOptions, setSelectedOptions] = useState([]);
  if (
    !correctAnswer ||
    !Array.isArray(shuffledOptions) ||
    !shuffledOptions.length
  ) {
    return (
      <S.Wrapper>
        <Row jc="center" mb={6}>
          <T.P color="error" m="0" mt="1">
            Error loading Spot The Word exercise
          </T.P>
        </Row>
      </S.Wrapper>
    );
  }

  const formattedCorrectOptions = shuffledOptions
    .map((option, i) => ({ [i]: option }))
    .filter((formattedOption) => {
      const key = Object.keys(formattedOption);
      return formattedOption[key].isCorrect;
    });

  const selectedOptionsSorted = selectedOptions.sort(
    (a, b) => Object.keys(a) - Object.keys(b)
  );

  const allSelectedOptionsCorrect =
    JSON.stringify(formattedCorrectOptions) ===
    JSON.stringify(selectedOptionsSorted);

  const handleToggleOptions = (currentOption) =>
    setSelectedOptions((prevOptions) =>
      containsOption(prevOptions, currentOption)
        ? prevOptions.filter(
            (option) => JSON.stringify(option) !== JSON.stringify(currentOption)
          )
        : [...prevOptions, currentOption]
    );
  return (
    <S.Wrapper>
      <Row jc="center" mb={6}>
        {textAudio && (
          <Col w={[4, 7, 7]} mt={6}>
            {textAudioUrl ? (
              <PlayButton
                audioUrl={textAudioUrl}
                iconProps={{ width: '32px', height: '32px' }}
                padding="12px"
                weight="regular"
                fallbackText={textAudio}
              >
                {textAudio}
              </PlayButton>
            ) : (
              <T.P size="med">{textAudio}</T.P>
            )}
          </Col>
        )}
      </Row>
      {Array.isArray(shuffledOptions) && shuffledOptions.length ? (
        <S.BubblesWrapper>
          {shuffledOptions.map((option, i) => {
            const selectedValue = {
              [i]: option,
            };
            const optionSelected = containsOption(
              selectedOptions,
              selectedValue
            );
            const { option: optionText } = option;
            return (
              <S.Bubble
                key={optionText + i}
                idx={
                  positions[
                    shuffledOptions.length > 10 ? 'moreThan10' : 'lessThan10'
                  ][i]
                }
                isSmall={shuffledOptions.length > 10}
                selected={optionSelected}
                onClick={() => handleToggleOptions(selectedValue)}
              >
                <T.P
                  weight="regular"
                  size="med"
                  color={optionSelected ? 'white' : 'neutralMain'}
                >
                  {optionText}
                </T.P>
              </S.Bubble>
            );
          })}
          <S.TitleNoteContainer>
            <S.StyledImage image="notepad" />
            <S.TitleWrapper>
              <T.P
                weight="bold"
                size={correctAnswer.length > 3 ? 'medLarge' : 'xxLarge'}
              >
                {correctAnswer}
              </T.P>
            </S.TitleWrapper>
          </S.TitleNoteContainer>
        </S.BubblesWrapper>
      ) : (
        <T.P weight="semi" ta="center">
          No data found
        </T.P>
      )}
      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleNext}
        preview={preview}
        disabled={disabled}
        enableNext={allSelectedOptionsCorrect}
      />
    </S.Wrapper>
  );
};

export default Step;
