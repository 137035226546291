import { Typography as T, Button } from '../../../components';

import * as S from './style';
import { getMediaUrl } from '../../../helpers';
import { audio } from '../../../constants';
import { useGeneralState } from '../../../context/general-state';
import CompletedCard from './CompletedCard';
import theme from 'theme';

const CompletedGoalsSection = ({ goals }) => {
  const { state: generalState } = useGeneralState();

  return (
    <>
      <S.PlayButtonWrapper>
        <Button.PlayButton
          width="56px"
          height="56px"
          backgroundColor={theme.colors.neutralSurface}
          audioUrl={getMediaUrl(
            audio.completedGoals,
            true,
            generalState?.preferredVoice
          )}
          iconProps={{ width: 27.2 }}
          fallbackText="Completed goals"
        />

        <T.H1 ml="3">Completed goals</T.H1>
      </S.PlayButtonWrapper>
      <S.CardsWrapper>
        {goals.map((g) => (
          <CompletedCard
            goalId={g.id}
            key={g.id}
            title={g.title}
            tasks={g.tasks}
          />
        ))}
      </S.CardsWrapper>
    </>
  );
};

export default CompletedGoalsSection;
