import { useRef } from 'react';
import { string, array, object, number } from 'yup';
import { Icon } from '../../../../components';

import { BasicInput } from '../../../../components/Inputs';
import { MediaKeyInput } from '../../../components/MediaKeyInput';
import * as T from '../../../../components/Typography';
import MDEditor from '../../../../components/MDEditor';
import * as S from './style';
import { mediaTypes, mediaInputLabels } from '../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';

const initialData = {
  audioKey: '',
  title: '',
  textAudio: '',
  textAudioKey: '',
  multipleWords: [{ id: 0, option: '', audioKey: '' }],
};

const ListenAndRepeatMultiple = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateDataFields } = useStepForm(initialRef.current);
  const { data, validationErrs } = state;

  const addWord = () => {
    const maxId = Math.max(...data.multipleWords.map((e) => e.id), 0);
    updateDataFields({
      multipleWords: [
        ...data.multipleWords,
        { id: maxId + 1, option: '', hide: false },
      ],
    });
  };
  const removeWord = (id) => {
    updateDataFields({
      multipleWords: data.multipleWords.filter((e) => e.id !== id),
    });
  };

  const onOptionChange = (value, id) => {
    updateDataFields({
      multipleWords: data.multipleWords.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };
  const onAudioKeyChange = (value, id) => {
    updateDataFields({
      multipleWords: data.multipleWords.map((e) => {
        if (e.id !== id) return e;
        return { ...e, audioKey: value };
      }),
    });
  };

  return (
    <div>
      <BasicInput
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        label="Explainer Text"
        error={validationErrs.textAudio}
        m={{ mt: 8 }}
      />

      <MediaKeyInput
        label="Explainer Audio"
        type={mediaTypes.TEXT_AUDIO}
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs.textAudioKey}
      />

      <MDEditor
        label={'Title'}
        value={data.title}
        onChange={(title) => updateDataFields({ title })}
        mode={'edit'}
        height={80}
        m={{ mt: 8 }}
        error={validationErrs?.title}
      />

      <T.P mt={8} size="large" weight="bold">
        Words
      </T.P>
      {validationErrs?.multipleWords &&
        typeof validationErrs.multipleWords === 'string' && (
          <T.P mb={2} mt={2} color="error">
            {validationErrs.multipleWords}
          </T.P>
        )}

      {data.multipleWords?.map((e, i) => {
        return (
          <>
            <MDEditor
              label={`Word ${i + 1}`}
              value={e.option}
              onChange={(value) => onOptionChange(value, e.id)}
              mode={'edit'}
              height={80}
              m={{ mt: 5 }}
              error={validationErrs?.multipleWords?.[i]?.option}
            />

            <MediaKeyInput
              index={i}
              type={mediaTypes.MULTIPLE_WORDS_OPTION_AUDIO}
              label={`Word ${i + 1} ${mediaInputLabels.AUDIO_LABEL}`}
              value={e.audioKey}
              handleChange={(value) => {
                onAudioKeyChange(value, e.id);
              }}
              m={{ mt: 5 }}
              error={validationErrs?.multipleWords?.[i]?.audioKey}
            />

            {data.multipleWords.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeWord(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}
      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addWord}
        disabled={data.options?.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Word
      </S.Button>

      <T.P color="error" mt={6}>
        {validationErrs.general}
      </T.P>
    </div>
  );
};

const validationSchema = {
  title: string().max(15).notRequired(),
  textAudio: string().notRequired(),
  textAudioKey: string().notRequired(),
  multipleWords: array()
    .of(
      object()
        .shape({
          id: number().notRequired(),
          option: string().required('required field'),
          audioKey: string().required('required field'),
        })
        .required()
    )
    .required('you must provide words')
    .min(1, 'you must provide words'),
  general: string().test(
    'titleOrText',
    'You should provide either title or explainer text',
    (val, { parent }) => {
      const { title, textAudio } = parent;
      return title || textAudio || false;
    }
  ),
};

export { validationSchema };
export default ListenAndRepeatMultiple;
