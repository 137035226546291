import Header from '.';
import { userRoles } from '../../../constants';

const adminBeforeCustomisation = {
  user: { role: userRoles.ADMIN },
};

const adminAfterCustomisation = {
  user: { role: userRoles.ADMIN },
};

const orgBeforeCustomisation = {
  user: { role: userRoles.ORGANISATION_ADMIN },
};

const orgAfterCustomisation = {
  user: { role: userRoles.ORGANISATION_ADMIN },
};

const facilitatorBeforeCustomisation = {
  user: { role: userRoles.FACILITATOR },
};

const facilitatorAfterCustomisation = {
  user: { role: userRoles.FACILITATOR },
};

const coachBeforeCustomisation = {
  user: { role: userRoles.COACH },
};

const coachAfterCustomisation = {
  user: { role: userRoles.COACH },
};

const learnerBeforeCustomisation = {
  user: { role: userRoles.LEARNER },
};

const learnerAfterCustomisation = {
  user: { role: userRoles.LEARNER },
};

const HeaderWrapper = ({ label, headerProps }) => {
  return (
    <div style={{ marginBottom: '20px' }}>
      <div style={{ marginLeft: '10px' }}>{label}</div>
      <Header {...headerProps} />
    </div>
  );
};

const HeaderComponentPage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <HeaderWrapper
        label="adminBeforeCustomisation ↓"
        headerProps={adminBeforeCustomisation}
      />
      <HeaderWrapper
        label="adminAfterCustomisation ↓"
        headerProps={adminAfterCustomisation}
      />
      <HeaderWrapper
        label="orgBeforeCustomisation ↓"
        headerProps={orgBeforeCustomisation}
      />
      <HeaderWrapper
        label="orgAfterCustomisation ↓"
        headerProps={orgAfterCustomisation}
      />
      <HeaderWrapper
        label="facilitatorBeforeCustomisation ↓"
        headerProps={facilitatorBeforeCustomisation}
      />
      <HeaderWrapper
        label="facilitatorAfterCustomisation ↓"
        headerProps={facilitatorAfterCustomisation}
      />
      <HeaderWrapper
        label="coachBeforeCustomisation ↓"
        headerProps={coachBeforeCustomisation}
      />
      <HeaderWrapper
        label="coachAfterCustomisation ↓"
        headerProps={coachAfterCustomisation}
      />
      <HeaderWrapper
        label="learnerBeforeCustomisation ↓"
        headerProps={learnerBeforeCustomisation}
      />
      <HeaderWrapper
        label="learnerAfterCustomisation ↓"
        headerProps={learnerAfterCustomisation}
      />
    </div>
  );
};

export default HeaderComponentPage;
