import { Loading } from '../../../components';
import { useNavigate } from 'react-router-dom';
import { navRoutes } from '../../../constants';
import { useEffect } from 'react';
import useFacilitatorCompletedTrainingStep from 'pages/Facilitator/Training/Hooks/useFacilitatorCompletedTrainingStep';
import useFacilitatorTrainingData from './Hooks/useFacilitatorTrainingData';

const Training = () => {
  const navigate = useNavigate();
  const { trainingData } = useFacilitatorTrainingData();

  const { completedTrainingStep, getCurrentStepLoading, isSuccess } =
    useFacilitatorCompletedTrainingStep();

  useEffect(() => {
    if (isSuccess) {
      if (completedTrainingStep === trainingData.length) {
        return navigate(navRoutes.FACILITATOR.TRAINING_COMPLETE);
      }
      return navigate(
        navRoutes.FACILITATOR.TRAINING_STEP.replace(
          ':stepId',
          completedTrainingStep + 1
        )
      );
    }
  }, [completedTrainingStep, isSuccess, navigate, trainingData.length]);

  if (getCurrentStepLoading) {
    return <Loading />;
  }

  return null;
};

export default Training;
