import { useNavigate } from 'react-router-dom';
import Modal from './../../../components/Modal';
import * as T from './../../../components/Typography';
import { Col } from './../../../components/Grid';
import { BasicButton } from './../../../components/Button';
import { navRoutes, coachStatusesQuery } from './../../../constants';

const redirectLink = navRoutes.COMMON.COACHES.replace(
  ':statusType',
  coachStatusesQuery.NEW
);

const SuccessModal = ({ isModalVisible, setIsModalVisible, rejectCoach }) => {
  const navigate = useNavigate();
  return (
    <Modal
      visible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      onCancel={() => navigate(redirectLink)}
    >
      <Col w={[4, 12, 12]} jc="center">
        {rejectCoach ? (
          <T.P size="med" weight="bold" color="white">
            Completed. They will be notified by email and you will be cc’d so
            you can confirm it has been sent out.
          </T.P>
        ) : (
          <>
            <T.P size="med" weight="bold" color="white">
              Success! They will be notified by email and you will be cc’d so
              you can confirm it has been sent out.
            </T.P>
            <T.P color="white" mt="5">
              Please remember to change the table with the date of the interview
              if applicable once it’s booked in.
            </T.P>
          </>
        )}
      </Col>
      <>
        <Col w={[4, 12, 12]} mt={5}>
          <BasicButton to={redirectLink} variant="secondary">
            Back to Coach Recruitment
          </BasicButton>
        </Col>
      </>
    </Modal>
  );
};

export default SuccessModal;
