import { useParams, useSearchParams } from 'react-router-dom';
import { useGeneralState } from 'context/general-state';
import { useExercise } from '../../../context/exercise';

import { navRoutes, userRoles } from '../../../constants';
import { useAuth } from '../../../context/auth';
import { Loading } from './../../../components';
import Content from './Content';
import { useEffect } from 'react';

const ExerciseExplainer = () => {
  const { user } = useAuth();
  const { exerciseId } = useParams();
  const { state: generalState, setState: setGeneralState } = useGeneralState();
  const { exercise, exerciseLoading } = useExercise();
  const [searchParams] = useSearchParams();
  const isPreparation = searchParams.get('isPreparation');
  useEffect(() => {
    if (isPreparation) {
      setGeneralState({ exerciseFlow: 'PREPARATION' });
    }
  }, [isPreparation, setGeneralState]);

  if (exerciseLoading) return <Loading />;

  return (
    <Content
      beforeExercise={exercise?.beforeExercise}
      showCoachNotes={user.role === userRoles.COACH || generalState.withCoach}
      toCoachNotes={{
        to: navRoutes.EXERCISES.COACH_NOTES.replace(':exerciseId', exerciseId),
        state: { canGoBack: true },
      }}
    />
  );
};

export { Content };
export default ExerciseExplainer;
