import * as S from './style';
import Icon from '../../Icon';
import * as T from '../../Typography';

const WithText = ({
  handleClick,
  color = 'neutralMain',
  ml,
  mlM = 1,
  mt,
  mb,
  mbM = '0px',
  to,
  icon,
  text,
  weight = 'bold',
  height,
  width,
  header,
  ...props
}) => {
  const StartCoachingWrapper = to
    ? S.StartCoachingWrapperLink
    : S.StartCoachingWrapperButton;

  return (
    <S.Container disabled={props?.disabled}>
      <StartCoachingWrapper
        onClick={handleClick}
        to={to}
        ml={ml}
        mlM={mlM}
        mt={mt}
        mb={mb}
        mbM={mbM}
        {...props}
      >
        {icon && (
          <S.IconWrapper>
            <Icon icon={icon} height={height} width={width} />
          </S.IconWrapper>
        )}
        <T.P
          weight={weight}
          ml={header ? 0 : 4}
          color={color}
          display={'inline'}
        >
          {text}
        </T.P>
      </StartCoachingWrapper>
    </S.Container>
  );
};

export default WithText;
