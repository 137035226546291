import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AudioIn } from '../../../../components/WordButton';
import { Row, Col } from '../../../../components/Grid';
import OptionButton from '../MultipleChoice/OptionButton';
import { useExercise } from '../../../../context/exercise';
import { useProgressCheck } from '../../../../context/progress-check';

const Find = ({ formattedWordsArray, setEnableNext, find }) => {
  const [selected, setSelected] = useState([]);

  const handleClick = (item) => {
    setSelected((old) => {
      const itemExists = old.find((opt) => opt.id === item.id);
      if (itemExists) {
        return old.filter((opt) => opt.id !== item.id);
      } else {
        return [...old, item];
      }
    });
  };

  useEffect(() => {
    const correctAnswers = formattedWordsArray.filter((opt) => opt.isCorrect);
    const allAnswersAreCorrect =
      correctAnswers.length === selected.length &&
      correctAnswers.every((opt) => selected.find((ans) => ans.id === opt.id));
    if (allAnswersAreCorrect) {
      setEnableNext(true);
    } else {
      setEnableNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.length]);

  return (
    <>
      {formattedWordsArray.map((el, i) => (
        <Col w={[2, 3, 3]} key={`col-${el?.option}-${i}`}>
          <OptionButton
            find
            mb={3}
            option={el?.option}
            handleClick={handleClick}
            selected={selected.find((opt) => opt.id === el.id) || {}}
            index={el?.id}
            isCorrect={el?.isCorrect}
            variant="small"
          />
        </Col>
      ))}
    </>
  );
};

const DisplayMultipleWords = ({
  wordsArray,
  setEnableNext,
  itemsPlayed,
  setItemsPlayed,
  grid,
  find,
}) => {
  const formattedWordsArray = wordsArray.map((el, idx) => ({ ...el, id: idx }));
  const { setSubProgress: setExerciseSubProgress } = useExercise();
  const { setSubProgress: setCheckSubProgress } = useProgressCheck();
  const [selected, setSelected] = useState([]);
  const location = useLocation();

  const handleClick = (el) => {
    if (!el.isPlayed) {
      el.isPlayed = true;
      setItemsPlayed((_itemsPlayed) => ({ ..._itemsPlayed, [el.id]: true }));
    }

    return;
  };

  const _itemsPlayed = Object.keys(itemsPlayed);
  useEffect(() => {
    if (find) return;
    if (_itemsPlayed.length === wordsArray.length) {
      setEnableNext(true);
    }
    if (typeof setExerciseSubProgress === 'function') {
      setExerciseSubProgress(_itemsPlayed.length / wordsArray.length);
    } else if (typeof setCheckSubProgress === 'function') {
      setCheckSubProgress(_itemsPlayed.length / wordsArray.length);
    }
  }, [
    _itemsPlayed.length,
    find,
    setEnableNext,
    setExerciseSubProgress,
    setCheckSubProgress,
    wordsArray.length,
  ]);
  useEffect(() => {
    setItemsPlayed({});
    if (typeof setExerciseSubProgress === 'function') {
      setExerciseSubProgress(0);
    } else if (typeof setCheckSubProgress === 'function') {
      setCheckSubProgress(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return (
    <Row>
      {grid &&
        formattedWordsArray.map((el, i) => (
          <Col w={[2, 3, 3]} key={`col-${el?.option}-${i}`}>
            <AudioIn
              audioUrl={el?.audioUrl}
              handleClick={() => handleClick(el)}
              innerText={el?.option}
              mb={3}
              key={`div-${el?.option}-${i}`}
              fallbackText={el?.option}
            />
          </Col>
        ))}

      {find && (
        <Find
          formattedWordsArray={formattedWordsArray}
          setSelected={setSelected}
          selected={selected}
          setEnableNext={setEnableNext}
        />
      )}
    </Row>
  );
};

export default DisplayMultipleWords;
