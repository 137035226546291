import { Grid, Icon } from '..';
import Modal from '../Modal';
import { BasicButton, PlayButton } from '../Button';
import { audio } from '../../constants';
import { getMediaUrl } from '../../helpers';
import { useGeneralState } from '../../context/general-state';

const { Col, Row } = Grid;
const TryAgainModal = ({ isModalVisible, setIsModalVisible }) => {
  const { state: generalState } = useGeneralState();

  return (
    <Modal
      bgColor="neutralSurface"
      visible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      aria-label="try-again-dialog"
      aria-modal="true"
      role="dialog"
      id="title"
      title="Title"
    >
      <Row>
        <Col w={[4, 12, 12]} jc="center">
          <Icon icon="tryAgain" color="neutralMain" width="80" height="80" />
        </Col>

        <Col w={[4, 12, 12]} jc="center" mt={3}>
          <PlayButton
            audioUrl={getMediaUrl(
              audio.tryAgain,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="Try again"
          >
            Try again
          </PlayButton>
        </Col>

        <Col w={[4, 12, 12]} mt={4}>
          <BasicButton
            handleClick={() => setIsModalVisible(false)}
            icon="next"
            variant="primary"
            iconProps={{ width: '20', height: '20' }}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default TryAgainModal;
