import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';

export const BadgesList = styled.div`
  ${setMargin};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px 24px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1 1 calc(50% - 24px);
`;
