import styled from '@emotion/styled';
import margins from 'helpers/set-margin';
import * as T from '../Typography';
import { BasicButton } from 'components/Button';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors['neutral30']};
  border-radius: 1rem;
  padding: ${({ theme }) => theme.spacings['4']};
`;

export const SLabel = styled(T.P)`
  color: ${({ theme }) => theme.colors['neutral80']};
`;

export const SValue = styled(T.P)`
  color: ${({ theme }) => theme.colors['neutral']};
  font-weight: 700;
`;

export const DateTimeWrapper = styled.div`
  ${margins}
  display: flex;
  justify-content: space-between;
`;

export const ActionButton = styled(BasicButton)`
  ${margins}
  width: 100%;
`;
