import * as T from 'components/Typography';

import { BasicButton } from 'components/Button';
import { Col, Row } from 'components/Grid';
import Modal from 'components/Modal';
import IconWithParagraph from 'components/IconWithParagraph';
import { Tip } from 'components';
import { Inputs } from 'components';

const AcceptedModal = ({
  acceptSuccessfullyModalVisible,
  rejectConfirmationVisible,
  setRejectConfirmationVisible,
  isLoading,
  confirmLearnerHttpError,
  onIUnderstand,
  onRejectYes,
  notes,
  setNotes,
}) => {
  return (
    <>
      {/* Accept successfully modal */}
      <Modal
        visible={acceptSuccessfullyModalVisible}
        bgColor="white"
        maxWidth="700px"
      >
        <Row mt="5">
          <Col w={[4, 12, 12]} jc="center">
            <T.H2 weight="bold" color="neutralMain">
              Thank you
            </T.H2>
          </Col>
          <Col w={[4, 12, 12]} jc="center">
            <T.P color="neutralMain" mt="22px">
              Your coaching is making a huge difference.
            </T.P>
          </Col>
          <Col w={[4, 12, 12]} mt={5}>
            <T.P weight="bold" color="neutralMain" mt="22px">
              Here are your next steps:
            </T.P>
          </Col>
          <Col w={[4, 12, 12]}>
            <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
              <T.P>
                Set up an introductory meeting to get to know your learner. Here
                are your manager’s details to help you set this up:
              </T.P>
            </IconWithParagraph>
          </Col>
        </Row>{' '}
        <Row mt="4">
          <Col w={[4, 12, 12]}>
            <Tip>
              {[
                'Remember not to share any personal contact details with your learner',
              ]}
            </Tip>
          </Col>
          <Col w={[4, 12, 12]} jc="center">
            <BasicButton
              variant="primary"
              disabled={false}
              onClick={onIUnderstand}
              type="click"
              mt="5"
              maxWidth="200px"
            >
              I understand
            </BasicButton>
          </Col>
        </Row>
      </Modal>
      {/* Reject Modal */}
      <Modal
        visible={rejectConfirmationVisible}
        setIsModalVisible={setRejectConfirmationVisible}
        bgColor="white"
        maxWidth="700px"
      >
        <Row>
          <Col w={[4, 12, 12]} jc="center">
            <T.P size="med" weight="bold">
              Are you sure you want to reject this learner?
            </T.P>
          </Col>
          <Col w={[4, 11, 12]}>
            <Inputs.Textarea
              rows={10}
              id="text"
              label="If you have any issue with this learner, please let us know here"
              placeholder="Notes..."
              type="text"
              value={notes}
              autoFocus
              m={{
                mt: 5,
              }}
              handleChange={(input) => setNotes(input)}
            />
          </Col>
          <Col w={[4, 6, 6]} mt={5}>
            <BasicButton
              variant="tertiary"
              handleClick={() => setRejectConfirmationVisible(false)}
              height="auto"
              size="small"
            >
              Cancel
            </BasicButton>
          </Col>
          <Col w={[4, 6, 6]} mt={5}>
            <BasicButton
              variant="secondary"
              height="auto"
              size="small"
              handleClick={onRejectYes}
              loading={isLoading}
              disabled={isLoading}
            >
              Reject
            </BasicButton>
          </Col>
        </Row>
        {confirmLearnerHttpError && (
          <Row>
            <Col w={[4, 11, 6]} mt={4}>
              <T.P color="error">{confirmLearnerHttpError?.message}</T.P>
            </Col>
          </Row>
        )}
      </Modal>
    </>
  );
};

export default AcceptedModal;
