import { useGetMediaTags } from 'api-calls/media.queries';
import {
  useCreateVoRequired,
  useDeleteVoRequiredById,
  useUpdateVoRequiredById,
} from 'api-calls/vo.queries';
import { Button } from 'components';
import { Col, Row } from 'components/Grid';
import { Textarea, Checkbox, Dropdown } from 'components/Inputs';
import { Modal } from 'components/Modal/style';
import * as T from 'components/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import { validateVo as _validateVo } from './validation';
import makeError from 'api-calls/format-error';
import { useUpdateStepMedia } from 'CMS/hooks/useUpdateStepMedia';
import { useUpdateExerciseMedia } from 'CMS/hooks/useUpdateExerciseMedia';
import { useExerciseForm } from 'CMS/Providers/ExerciseFormProvider';
import { formTypes } from 'constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';

const initialState = {
  id: null,
  form: {
    description: '',
    isMaleRequired: false,
    isFemaleRequired: false,
    tags: [],
  },
  httpError: '',
  validationErrs: {},
};

const AudioVoModal = ({
  isVoTable,
  formType,
  mediaType,
  open,
  onCancel,
  voRequired,
}) => {
  const [state, setState] = useState(initialState);
  const { tags } = useGetMediaTags();
  const { mutateAsync: createVoRequired, isLoading: isCreating } =
    useCreateVoRequired();
  const { mutateAsync: updateVoRequiredById, isLoading: isUpdating } =
    useUpdateVoRequiredById();
  const { mutateAsync: deleteVoRequiredById, isLoading: isDeleting } =
    useDeleteVoRequiredById();

  const { updateStepMediaType } = useUpdateStepMedia();
  const { updateExerciseMediaType } = useUpdateExerciseMedia();
  const {
    state: { form: exercise },
  } = useExerciseForm();
  const {
    state: { form: step },
  } = useStepForm();

  const updateFormFields = useCallback(
    (_data) =>
      setState((prevState) => ({
        ...prevState,
        form: { ...prevState.form, ..._data },
      })),
    []
  );

  const updateFormMetaFields = useCallback(
    (data) => setState((prevState) => ({ ...prevState, ...data })),
    []
  );

  const isEditSession = !!voRequired?.id;
  const isLoading = isCreating || isUpdating;

  const isSubmitButtonDisabled =
    !state.form.description ||
    !state.form.tags.length ||
    isLoading ||
    isDeleting;

  useEffect(() => {
    if (voRequired) {
      updateFormFields({
        description: voRequired.description,
        isMaleRequired: voRequired.isMaleRequired,
        isFemaleRequired: voRequired.isFemaleRequired,
        tags: voRequired.tags,
      });
    }
  }, [updateFormFields, voRequired, open]);

  const onCloseModal = () => {
    setState(initialState);
    onCancel();
  };

  const validateVo = useCallback(() => {
    try {
      _validateVo(state.form);
      updateFormMetaFields({ validationErrs: {} });
      return true;
    } catch (error) {
      updateFormMetaFields({ validationErrs: error.inner });
    }
  }, [state.form, updateFormMetaFields]);

  const onSuccess = (_data) => {
    if (!isVoTable) {
      const [_mediaType, index] = mediaType.split('-');
      formType === formTypes.EXERCISE
        ? updateExerciseMediaType({
            mediaType,
            mediaKey: _data.fileKey,
          })
        : updateStepMediaType({
            mediaType: _mediaType,
            mediaKey: _data.fileKey,
            index,
          });
    }

    onCloseModal();
  };
  const onError = (err) => {
    const error = makeError(err);
    updateFormMetaFields({
      httpError: error.message,
    });
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    updateFormMetaFields({ httpError: '' });

    const isValid = validateVo();

    if (!isValid) return;

    const payload = {
      mediaType,
      description: state.form.description,
      isMaleRequired: state.form.isMaleRequired,
      isFemaleRequired: state.form.isFemaleRequired,
      tags: state.form.tags,
    };

    if (isEditSession) {
      await updateVoRequiredById(
        { id: voRequired.id, ...payload },
        { onSuccess, onError }
      );
      return;
    }

    // if exerciseId or stepId exists means backend will update the fileKey in
    // the respective table
    // if not, the content editor have to manage that if he refresh or
    // leaves the page (we have no way of linking it if the exercise/step
    // is not created yet)
    // fileKey is unique string created in the backend and it will be used to
    //  upload the file later
    await createVoRequired(
      { exerciseId: exercise.id, stepId: step.id, ...payload },
      {
        onSuccess,
        onError,
      }
    );
  };

  const onDeleteVo = async () => {
    await deleteVoRequiredById(
      {
        id: voRequired.id,
        mediaType,
        exerciseId: exercise.id,
        stepId: step.id,
      },
      {
        onSuccess: () => {
          onSuccess({ fileKey: '' }); // empty fileKey
          onCloseModal();
        },
        onError: (err) => {
          const error = makeError(err);
          updateFormMetaFields({
            httpError: error.message,
          });
        },
      }
    );
  };

  return (
    <Modal open={open} bgColor="white" width="960px">
      <form
        onSubmit={onSubmit}
        name="update vo"
        style={{ width: '100%', maxWidth: 1200, margin: '0px auto' }}
      >
        <Row>
          <T.H1>Audio file record</T.H1>
        </Row>
        <Row>
          <Textarea
            label="Description"
            placeholder="please provide any words, descriptions, that will make it easy for you to find this file in your media library in the future..."
            value={state.form.description}
            handleChange={(description) => updateFormFields({ description })}
            m={{ my: 5 }}
            type="text"
            error={state.validationErrs.description}
          />
        </Row>
        <Row>
          <T.H3 color="neutralMain">Male and/or Female?</T.H3>
          <Checkbox
            mt={4}
            ml={4}
            font="regular"
            weight="bold"
            label="Male"
            checked={state.form.isMaleRequired}
            handleChange={(checked) =>
              updateFormFields({ isMaleRequired: checked })
            }
            plain
          />
          <Checkbox
            ml={4}
            font="regular"
            weight="bold"
            label="Female"
            checked={state.form.isFemaleRequired}
            handleChange={(checked) =>
              updateFormFields({ isFemaleRequired: checked })
            }
            plain
          />
          <T.P color="error">
            {state.validationErrs.isMaleRequired ||
              state.validationErrs.isFemaleRequired}
          </T.P>
        </Row>
        <Row>
          <Dropdown
            label="Select media tags"
            search
            addNew
            optionFilterProp="label"
            multi
            options={tags?.map((t) => ({ label: t.tag, value: t.id }))}
            selected={state.form.tags.map((t) => ({
              label: tags?.find((tag) => tag.id === t)?.tag,
              value: t,
            }))}
            handleChange={(v) =>
              updateFormFields({
                tags: v.map((newTag) => {
                  const index = tags?.findIndex((t) => t.tag === newTag);

                  if (index !== -1) {
                    return tags[index].id;
                  }
                  return newTag;
                }),
              })
            }
            error={state.validationErrs.tags}
          />
          <T.P color="neutral70" mt={2} ml={3}>
            *Tags help you find files by using relevant keywords
          </T.P>
        </Row>
        {state.httpError && (
          <Row mt="5">
            <Col w={[4, 12, 12]}>
              <T.P color="error">{state.httpError}</T.P>
            </Col>
          </Row>
        )}
        <Row mt={state.httpError ? '' : '5'} jc="flex-end">
          {isEditSession && (
            <Col w={[4, 4, 4]}>
              <Button.BasicButton
                bgColor={'white'}
                borderColor="tertiaryMain"
                customColor="tertiaryMain"
                type="button"
                loading={isDeleting}
                onClick={onDeleteVo}
              >
                Delete Request
              </Button.BasicButton>
            </Col>
          )}
          <Col w={[4, 4, 4]}>
            <Button.BasicButton
              variant="tertiary"
              type="button"
              onClick={onCancel}
            >
              Cancel
            </Button.BasicButton>
          </Col>
          <Col w={[4, 4, 4]}>
            <Button.BasicButton
              variant="primary"
              disabled={isSubmitButtonDisabled}
              loading={isLoading}
              type="submit"
            >
              {isEditSession ? 'Update' : 'Submit'}
            </Button.BasicButton>
          </Col>
        </Row>
      </form>
    </Modal>
  );
};

export default AudioVoModal;
