import { string, array, object, number } from 'yup';
import { typingHorizontalVariations } from '../../../../../constants';

export const validationSchema = {
  textAudio: string().required('required field'),
  textAudioKey: string().required('required field'),
  imageKey: string().required('required field'),
  correctOptions: array()
    .of(
      object()
        .shape({
          id: number().notRequired(),
          option: string().required('required field'),
          type: string().required('required field'),
          prefix: string().when('type', {
            is: typingHorizontalVariations.correctOptionTypes.HAS_PREFIX,
            then: string().required('required field'),
            otherwise: string().notRequired(),
          }),
          suffix: string().when('type', {
            is: typingHorizontalVariations.correctOptionTypes.HAS_SUFFIX,
            then: string().required('required field'),
            otherwise: string().notRequired(),
          }),
        })
        .required()
    )
    .required('you must provide answers')
    .when('variation', (variation, schema) => {
      // Handle array length validation
      let validatedSchema = schema;
      if (variation === typingHorizontalVariations.variations.ONE_ANSWER) {
        validatedSchema = schema.length(1, 'only one answer allowed');
      } else if (
        variation === typingHorizontalVariations.variations.TWO_ANSWERS
      ) {
        validatedSchema = schema.length(
          2,
          'you must provide exactly two answers'
        );
      }

      return validatedSchema;
    }),
};
