import styled from '@emotion/styled';
import { setMargin } from 'helpers';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentWrapper = styled.div`
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacings[6]};
  margin-right: ${({ theme }) => theme.spacings[6]};
`;

export const TextAudioWrapper = styled.div`
  width: 100%;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
  margin-bottom: ${({ theme, mb }) => theme.spacings[mb || 0]};
  display: flex;
  align-items: center;
  justify-content: ${({ jc }) => jc || 'center'};
`;

export const ImageWrapper = styled.div`
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  max-width: 420px;
  max-height: 420px;
`;

export const Img = styled.img`
  width: 100%;
  object-fit: cover;
`;

export const InputsWrapper = styled.div`
  ${setMargin}
  align-self: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 420px;
`;

export const TypeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${({ isFraction }) => (isFraction ? 'column' : 'row')};
  justify-content: ${({ jc }) => (jc ? 'center' : 'flex-start')};
  width: ${({ isFraction }) => (isFraction ? '64px' : '100%')};
  margin: 0 auto;
  gap: 12px;
`;

export const TypeInput = styled.input`
  outline: 0;
  border-width: ${({ isSquare }) => (isSquare ? '2px' : '0 0 2px')};
  border-radius: ${({ isSquare }) => (isSquare ? '12px' : '0')};
  border-color: ${({ theme, isCorrect }) =>
    isCorrect ? theme.colors.quinaryMain : theme.colors.primaryMain};
  width: ${({ isFullWidth, isSquare }) =>
    isSquare ? '64px' : isFullWidth ? '100%' : '142px'};
  height: ${({ isSquare }) => (isSquare ? '64px' : 'auto')};
  font-style: normal;
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 150%;
  text-align: center;
  background: transparent;
  color: ${({ theme }) => theme.colors.neutralMain};
  transition: 0.3s;
  &:focus {
    border-color: ${({ theme, isCorrect }) =>
      isCorrect ? theme.colors.quinaryMain : theme.colors.neutralMain};
  }
`;

export const Divide = styled.div`
  width: 100%;
  border: 2px solid #000;
`;
