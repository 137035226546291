import { useNavigate } from 'react-router-dom';

import * as T from '../Typography';

import * as S from './style';
import { coachStatusesQuery } from '../../constants';
import { COMMON } from '../../constants/nav-routes';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const tabsKeys = {
  [coachStatusesQuery.ALL]: '1',
  [coachStatusesQuery.NEW]: '2',
  [coachStatusesQuery.REJECTED]: '3',
};

const tabsKeyReverse = {
  1: coachStatusesQuery.ALL,
  2: coachStatusesQuery.NEW,
  3: coachStatusesQuery.REJECTED,
};

const CoachesTableTabs = ({ statusType }) => {
  const navigate = useNavigate();

  const onChange = (key) => {
    const _statusType = tabsKeyReverse[key];

    if (statusType === _statusType) return;
    navigate(COMMON.COACHES.replace(':statusType', _statusType));
  };
  const activeTab = tabsKeys[statusType];

  return (
    <S.TabsWrapper activeTab={activeTab}>
      <Tabs
        defaultActiveKey={tabsKeys[statusType]}
        onChange={onChange}
        size="large"
      >
        <TabPane
          tab={<T.P weight={activeTab === '1' ? 'bold' : 'regular'}>All</T.P>}
          key="1"
        ></TabPane>
        <TabPane
          tab={<T.P weight={activeTab === '2' ? 'bold' : 'regular'}>New</T.P>}
          key="2"
        ></TabPane>
        <TabPane
          tab={
            <T.P weight={activeTab === '3' ? 'bold' : 'regular'}>Rejected</T.P>
          }
          key="3"
        ></TabPane>
      </Tabs>
    </S.TabsWrapper>
  );
};

export default CoachesTableTabs;
