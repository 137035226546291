import { useParams, useNavigate } from 'react-router-dom';
import { BasicButton } from '../../../components/Button';
import { GroupCheckbox } from '../../../components/Inputs';
import { Col, Row } from '../../../components/Grid';
import { Loading } from '../../../components';
import * as T from '../../../components/Typography';
import * as S from './style';

import { navRoutes } from '../../../constants';

import {
  useGetLearnerSelfLearningByUserId,
  useMarkCompletedStepsAsReviewedByCoach,
} from '../../../api-calls/learners.queries';
import { useGeneralState } from '../../../context/general-state';

const ReviewSelfLearning = () => {
  const navigate = useNavigate();

  const { setState: setGeneralState } = useGeneralState();
  const { id } = useParams();
  const { data, isLoading: isFetching } = useGetLearnerSelfLearningByUserId({
    id,
  });
  const { learner, selfLearning } = data || {};
  const {
    mutateAsync: markCompletedStepsAsReviewedByCoach,
    error,
    isLoading,
  } = useMarkCompletedStepsAsReviewedByCoach({ learnerId: id });

  const handleCarryOnLearning = async () => {
    await markCompletedStepsAsReviewedByCoach({
      learnerId: id,
      type: 'MARK_AS_REVIEWED_BY_COACH',
    });

    navigate(navRoutes.COACH.LEARNER_DASHBOARD.replace(':id', id));
  };

  const selfLearningArr = selfLearning?.map((item) => ({
    id: item.exerciseId,
    checked: true,
    label: item.exerciseTitle,
    onClick: () => {
      setGeneralState({
        exerciseFlow: 'REVIEW_SELF_LEARNING',
      });

      navigate(
        navRoutes.EXERCISES.EXERCISE_EXPLAINER.replace(
          ':exerciseId',
          item.exerciseId
        )
      );
    },
  }));

  if (isFetching) {
    return <Loading />;
  }

  return (
    <S.Wrapper>
      <Row>
        <Col w={[4, 8, 8]} mtT="5">
          <T.P color="neutralMain" display="inline">
            {learner.firstName} has completed some{' '}
            <T.P weight="semi" display="inline">
              self learning
            </T.P>
          </T.P>
        </Col>
      </Row>

      <Row>
        <Col w={[4, 8, 8]}>
          <T.H2 mt={5}>Exercises completed</T.H2>
          <T.P mt={4}>
            You can click on an exercise to review it with your learner
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 8, 8]} mt={5}>
          <GroupCheckbox state={selfLearningArr} largeDivider />
        </Col>
      </Row>

      <S.ButtonsContainer jc="flex-end" mt="5">
        {error?.message && (
          <Col w={[4, 8, 8]} mb="4">
            <T.P color="error">{error?.message}</T.P>
          </Col>
        )}
        <S.ButtonWrapper w={[4, 8, 8]}>
          <BasicButton
            variant="primary"
            onClick={handleCarryOnLearning}
            loading={isLoading}
          >
            <T.P color="white" weight="semi">
              Carry on learning
            </T.P>
          </BasicButton>
        </S.ButtonWrapper>
        <S.ButtonWrapper w={[4, 8, 8]} mt="4">
          <BasicButton variant="secondary" to={navRoutes.COACH.DASHBOARD}>
            <T.P color="white" weight="semi">
              Return to dashboard
            </T.P>
          </BasicButton>
        </S.ButtonWrapper>
      </S.ButtonsContainer>
    </S.Wrapper>
  );
};

export default ReviewSelfLearning;
