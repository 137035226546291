import {
  useFetch,
  usePost,
  usePatch,
  useDelete,
  formatQueryError,
} from '../utils/queries';

const STEPS_BASE = '/steps';

const useGetSteps = ({ exerciseId }, options = {}) => {
  const context = useFetch({
    key: [`GET_STEPS`, { exerciseId }],
    url: `${STEPS_BASE}`,
    reactQueryConfig: options,
    axiosParams: { exerciseId },
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useCreateStep = (_, options = {}) => {
  const context = usePost({
    url: `${STEPS_BASE}`,
    reactQueryConfig: {
      invalidateKeys: () => [['GET_STEPS']],
      ...options,
    },
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useUpdateStep = ({ id }, options = {}) => {
  const context = usePatch({
    url: `${STEPS_BASE}/${id}`,
    reactQueryConfig: {
      invalidateKeys: () => [['GET_STEPS']],
      ...options,
    },
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useDeleteStep = ({ id }, options = {}) => {
  const context = useDelete({
    url: `${STEPS_BASE}/${id}`,
    reactQueryConfig: {
      invalidateKeys: () => [['GET_STEPS']],
      ...options,
    },
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useGetStep = ({ id }, options = {}) => {
  const context = useFetch({
    key: [`GET_STEP`, { id }],
    url: `${STEPS_BASE}/${id}`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

export { useGetSteps, useCreateStep, useUpdateStep, useDeleteStep, useGetStep };
