const Face2 = ({ width, height, color, ...props }) => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.05994 16.0025C3.05994 23.4252 9.07724 29.4425 16.4999 29.4425C23.9227 29.4425 29.9399 23.4252 29.9399 16.0025C29.9399 8.57979 23.9227 2.5625 16.4999 2.5625C9.07724 2.5625 3.05994 8.57979 3.05994 16.0025ZM14.5799 12.8025C14.5799 13.5094 14.0069 14.0825 13.2999 14.0825C12.593 14.0825 12.0199 13.5094 12.0199 12.8025C12.0199 12.0956 12.593 11.5225 13.2999 11.5225C14.0069 11.5225 14.5799 12.0956 14.5799 12.8025ZM19.6999 14.0825C20.4069 14.0825 20.9799 13.5094 20.9799 12.8025C20.9799 12.0956 20.4069 11.5225 19.6999 11.5225C18.993 11.5225 18.4199 12.0956 18.4199 12.8025C18.4199 13.5094 18.993 14.0825 19.6999 14.0825ZM21.9525 20.3985C19.03 22.2218 14.0443 22.2109 11.0515 20.4034C10.7482 20.2202 10.6494 19.8234 10.8308 19.5171C11.0123 19.2108 11.4052 19.111 11.7086 19.2942C14.295 20.8563 18.7679 20.8661 21.2796 19.2991C21.5803 19.1115 21.9747 19.2055 22.1605 19.5091C22.3463 19.8127 22.2532 20.2109 21.9525 20.3985Z"
      fill="#3B3C42"
    />
  </svg>
);

export default Face2;
