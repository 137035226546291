const SixDots = ({ width, height, color, ...props }) => (
  <svg
    width={width || '26'}
    height={height || '26'}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    {...props}
  >
    <path
      d="M9.43412 8.20809C10.4117 8.20809 11.2042 7.4156 11.2042 6.43803C11.2042 5.46045 10.4117 4.66797 9.43412 4.66797C8.45654 4.66797 7.66406 5.46045 7.66406 6.43803C7.66406 7.4156 8.45654 8.20809 9.43412 8.20809Z"
      fill={color}
    />
    <path
      d="M11.0343 6.4394C11.0292 7.10083 10.6126 7.71806 9.98349 7.9425C9.34586 8.17035 8.63342 7.97311 8.19813 7.4545C7.76624 6.9393 7.72033 6.17584 8.06891 5.60623C8.41067 5.04342 9.09251 4.73735 9.74034 4.86828C10.4868 5.01961 11.0292 5.67934 11.0343 6.4394C11.036 6.65874 11.3761 6.65874 11.3744 6.4394C11.3693 5.62663 10.8575 4.89889 10.0957 4.61493C9.34246 4.33607 8.45318 4.57752 7.94478 5.19985C7.42788 5.83238 7.34796 6.74036 7.77645 7.4443C8.20153 8.14314 9.033 8.49852 9.83216 8.33868C10.7248 8.15845 11.3693 7.34228 11.3761 6.4394C11.3761 6.22005 11.036 6.22005 11.0343 6.4394Z"
      fill={color}
    />
    <path
      d="M9.43412 14.2706C10.4117 14.2706 11.2042 13.4781 11.2042 12.5005C11.2042 11.523 10.4117 10.7305 9.43412 10.7305C8.45654 10.7305 7.66406 11.523 7.66406 12.5005C7.66406 13.4781 8.45654 14.2706 9.43412 14.2706Z"
      fill={color}
    />
    <path
      d="M11.0343 12.5019C11.0292 13.1633 10.6126 13.7806 9.98349 14.005C9.34586 14.2328 8.63342 14.0356 8.19813 13.517C7.76624 13.0018 7.72033 12.2383 8.06891 11.6687C8.41067 11.1059 9.09251 10.7999 9.74034 10.9308C10.4868 11.0821 11.0292 11.7418 11.0343 12.5019C11.036 12.7212 11.3761 12.7212 11.3744 12.5019C11.3693 11.6891 10.8575 10.9614 10.0957 10.6774C9.34246 10.3986 8.45318 10.64 7.94478 11.2623C7.42788 11.8949 7.34796 12.8029 7.77645 13.5068C8.20153 14.2056 9.033 14.561 9.83216 14.4012C10.7248 14.2209 11.3693 13.4048 11.3761 12.5019C11.3761 12.2826 11.036 12.2826 11.0343 12.5019Z"
      fill={color}
    />
    <path
      d="M9.43412 20.3331C10.4117 20.3331 11.2042 19.5406 11.2042 18.563C11.2042 17.5855 10.4117 16.793 9.43412 16.793C8.45654 16.793 7.66406 17.5855 7.66406 18.563C7.66406 19.5406 8.45654 20.3331 9.43412 20.3331Z"
      fill={color}
    />
    <path
      d="M11.0343 18.5644C11.0292 19.2258 10.6126 19.8431 9.98349 20.0675C9.34586 20.2953 8.63342 20.0981 8.19813 19.5795C7.76624 19.0643 7.72033 18.3008 8.06891 17.7312C8.41067 17.1684 9.09251 16.8624 9.74034 16.9933C10.4868 17.1446 11.0292 17.8043 11.0343 18.5644C11.036 18.7837 11.3761 18.7837 11.3744 18.5644C11.3693 17.7516 10.8575 17.0239 10.0957 16.7399C9.34246 16.4611 8.45318 16.7025 7.94478 17.3248C7.42788 17.9574 7.34796 18.8654 7.77645 19.5693C8.20153 20.2681 9.033 20.6235 9.83216 20.4637C10.7248 20.2834 11.3693 19.4673 11.3761 18.5644C11.3761 18.3451 11.036 18.3451 11.0343 18.5644Z"
      fill={color}
    />
    <path
      d="M15.9805 8.20809C16.9581 8.20809 17.7506 7.4156 17.7506 6.43803C17.7506 5.46045 16.9581 4.66797 15.9805 4.66797C15.0029 4.66797 14.2104 5.46045 14.2104 6.43803C14.2104 7.4156 15.0029 8.20809 15.9805 8.20809Z"
      fill={color}
    />
    <path
      d="M17.5807 6.4394C17.5756 7.10083 17.159 7.71806 16.5299 7.9425C15.8923 8.17035 15.1798 7.97311 14.7445 7.4545C14.3126 6.9393 14.2667 6.17584 14.6153 5.60623C14.9571 5.04342 15.6389 4.73735 16.2867 4.86828C17.0332 5.01961 17.5756 5.67934 17.5807 6.4394C17.5824 6.65874 17.9225 6.65874 17.9208 6.4394C17.9157 5.62663 17.4039 4.89889 16.6421 4.61493C15.8889 4.33607 14.9996 4.57752 14.4912 5.19985C13.9743 5.83238 13.8943 6.74036 14.3228 7.4443C14.7479 8.14314 15.5794 8.49852 16.3786 8.33868C17.2712 8.15845 17.9157 7.34228 17.9225 6.4394C17.9225 6.22005 17.5824 6.22005 17.5807 6.4394Z"
      fill={color}
    />
    <path
      d="M15.9805 14.2706C16.9581 14.2706 17.7506 13.4781 17.7506 12.5005C17.7506 11.523 16.9581 10.7305 15.9805 10.7305C15.0029 10.7305 14.2104 11.523 14.2104 12.5005C14.2104 13.4781 15.0029 14.2706 15.9805 14.2706Z"
      fill={color}
    />
    <path
      d="M17.5807 12.5019C17.5756 13.1633 17.159 13.7806 16.5299 14.005C15.8923 14.2328 15.1798 14.0356 14.7445 13.517C14.3126 13.0018 14.2667 12.2383 14.6153 11.6687C14.9571 11.1059 15.6389 10.7999 16.2867 10.9308C17.0332 11.0821 17.5756 11.7418 17.5807 12.5019C17.5824 12.7212 17.9225 12.7212 17.9208 12.5019C17.9157 11.6891 17.4039 10.9614 16.6421 10.6774C15.8889 10.3986 14.9996 10.64 14.4912 11.2623C13.9743 11.8949 13.8943 12.8029 14.3228 13.5068C14.7479 14.2056 15.5794 14.561 16.3786 14.4012C17.2712 14.2209 17.9157 13.4048 17.9225 12.5019C17.9225 12.2826 17.5824 12.2826 17.5807 12.5019Z"
      fill={color}
    />
    <path
      d="M15.9805 20.3331C16.9581 20.3331 17.7506 19.5406 17.7506 18.563C17.7506 17.5855 16.9581 16.793 15.9805 16.793C15.0029 16.793 14.2104 17.5855 14.2104 18.563C14.2104 19.5406 15.0029 20.3331 15.9805 20.3331Z"
      fill={color}
    />
    <path
      d="M17.5807 18.5644C17.5756 19.2258 17.159 19.8431 16.5299 20.0675C15.8923 20.2953 15.1798 20.0981 14.7445 19.5795C14.3126 19.0643 14.2667 18.3008 14.6153 17.7312C14.9571 17.1684 15.6389 16.8624 16.2867 16.9933C17.0332 17.1446 17.5756 17.8043 17.5807 18.5644C17.5824 18.7837 17.9225 18.7837 17.9208 18.5644C17.9157 17.7516 17.4039 17.0239 16.6421 16.7399C15.8889 16.4611 14.9996 16.7025 14.4912 17.3248C13.9743 17.9574 13.8943 18.8654 14.3228 19.5693C14.7479 20.2681 15.5794 20.6235 16.3786 20.4637C17.2712 20.2834 17.9157 19.4673 17.9225 18.5644C17.9225 18.3451 17.5824 18.3451 17.5807 18.5644Z"
      fill={color}
    />
  </svg>
);

export default SixDots;
