import { useState, useEffect } from 'react';
import * as T from '../../../../components/Typography';
import * as S from './style';
import { typeNewEndingVariations } from '../../../../constants';

const TypingInput = ({
  subtitle,
  index,
  setUserAnswers,
  userAnswers,
  answers,
  isSequences,
}) => {
  const text = userAnswers[index];
  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    setUserAnswers((old) => {
      const newAnswers = [...old];
      newAnswers[index] = value.toLowerCase();
      return newAnswers;
    });
  };
  return (
    <S.TypeInputWrapper>
      <label htmlFor={subtitle + index}>
        <T.P size={'medLarge'} weight="bold" mb="2">
          {isSequences ? '' : '+ '} {subtitle}
        </T.P>
      </label>
      <S.TypeInput
        id={subtitle + index}
        name={subtitle + index}
        type="text"
        aria-labelledby={subtitle + index}
        value={text}
        onChange={handleChange}
        isCorrect={text === answers[index].answer}
      />
    </S.TypeInputWrapper>
  );
};

const InputsWrapper = ({
  correctOptions,
  enableNext,
  setEnableNext,
  variation,
}) => {
  const [answers, setAnswers] = useState([]);
  const [userAnswers, setUserAnswers] = useState(
    new Array(correctOptions?.length).fill('')
  );

  const isSequences =
    variation === typeNewEndingVariations.variations.SEQUENCES;
  useEffect(() => {
    if (correctOptions?.length > 0) {
      const answers = correctOptions.map((item) => {
        if (isSequences) {
          return { subtitle: item.guidText, answer: item.option };
        }
        const ans = item.option.split('+');
        return { subtitle: ans[1], answer: ans.join('') };
      });
      setAnswers(answers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userAnswers.every((text) => !!text)) {
      if (
        userAnswers.every(
          (text, index) => text?.toLowerCase() === answers[index].answer
        )
      ) {
        setEnableNext(true);
      } else {
        if (enableNext) {
          setEnableNext(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAnswers]);
  return (
    <div
      style={{
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: 300,
        marginTop: 50,
      }}
    >
      {answers.map((item, index) => (
        <TypingInput
          subtitle={item.subtitle}
          key={item.subtitle + index}
          index={index}
          setUserAnswers={setUserAnswers}
          userAnswers={userAnswers}
          answers={answers}
          isSequences={isSequences}
        />
      ))}
    </div>
  );
};

export default InputsWrapper;
