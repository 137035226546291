import * as T from 'components/Typography';
import * as S from './style';
import Icon from 'components/Icon';
import { Popover } from 'antd';

const LearnerStatusColumn = (
  <S.StatusWrapper>
    <T.P weight="bold">Status</T.P>{' '}
    <Popover
      placement="top"
      content={
        <div>
          <p>
            <b>Referral:</b> Learner added to the system by a referral
          </p>
          <p>
            <b>Rejected:</b> Referral has been rejected
          </p>
          <p>
            <b>Awaiting Coach:</b> Learner has not been assigned to a coach yet
          </p>
          <p>
            <b>Still to onboard:</b> Accepted by coach but not onboarded yet
          </p>
          <p>
            <b>Assigned:</b> Accepted by coach and onboarded
          </p>
          <p>
            <b>Awaiting Coach Approval:</b> Assigned to coach but not accepted
            yet
          </p>
        </div>
      }
    >
      <Icon icon="info" height="24" width="24" color="primaryMain" />
    </Popover>
  </S.StatusWrapper>
);

export default LearnerStatusColumn;
