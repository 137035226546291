import React, { useState, useEffect } from 'react';
import { ClearButton } from '../../../../../components/Button';
import theme from 'theme';

const Thermometer = ({ setEnableNext, rows }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const SVG_WIDTH = 140;
  const ROW_HEIGHT = 42;
  const BULB_RADIUS = 30;
  const TUBE_WIDTH = 40;

  const MIN_REC_HEIGHT = 320;
  const MAX_REC_HEIGHT = 420;
  const recHeight = Math.min(
    Math.max(rows.length * ROW_HEIGHT, MIN_REC_HEIGHT),
    MAX_REC_HEIGHT
  );

  const height = recHeight + BULB_RADIUS * 2;
  const startY =
    height - BULB_RADIUS * 2 - ROW_HEIGHT * rows.length + ROW_HEIGHT * 0.7;
  const endY = height - BULB_RADIUS * 2 - ROW_HEIGHT * 0.3;
  const fillColor = theme.colors.primaryMain;
  const unFilledColor = theme.colors.white;

  useEffect(() => {
    setEnableNext(
      rows.every((row) => {
        const isSelected = selectedRows.includes(row.id);
        return row.isCorrect ? isSelected : !isSelected;
      })
    );
  }, [rows, selectedRows, setEnableNext]);

  const handleRowFill = (rowId) => {
    setSelectedRows((prev) => {
      if (prev.includes(rowId)) {
        return prev.filter((_rowId) => _rowId !== rowId);
      }
      return [...prev, rowId];
    });
  };

  const handleMouseDown = (rowId) => {
    setIsDragging(true);
    handleRowFill(rowId);
  };

  const handleMouseEnter = (rowId) => {
    if (isDragging) {
      handleRowFill(rowId);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'flex-end', gap: '20px' }}>
        <svg
          width={SVG_WIDTH + 180}
          height={height}
          style={
            isDragging
              ? {
                  userSelect: 'none',
                  WebkitUserSelect: 'none', // For Safari
                  msUserSelect: 'none', // For IE/Edge
                }
              : {}
          }
        >
          {/* Thermometer tube */}
          <rect
            x={(SVG_WIDTH - TUBE_WIDTH) / 2}
            y={BULB_RADIUS / 2}
            width={TUBE_WIDTH}
            height={recHeight}
            fill="transparent"
            stroke="#000"
          />

          {/* Interactive segments */}
          {rows.map((row, index) => (
            <React.Fragment key={row.id}>
              <line
                x1={(SVG_WIDTH - TUBE_WIDTH) / 2 + TUBE_WIDTH}
                x2={SVG_WIDTH}
                y1={startY + index * ROW_HEIGHT}
                y2={startY + index * ROW_HEIGHT}
                stroke="#000"
                strokeWidth="1"
              />
              <text
                x={SVG_WIDTH + 10}
                y={startY + index * ROW_HEIGHT}
                dominantBaseline="middle"
                style={{
                  fontSize: '16px',
                  fontWeight: 700,
                }}
              >
                {row.text}
              </text>
              {index === rows.length - 1 ? (
                <g
                  style={{ cursor: 'pointer', transition: 'fill 0.2s' }}
                  onMouseDown={() => handleMouseDown(row.id)}
                  onMouseEnter={() => handleMouseEnter(row.id)}
                  onMouseUp={handleMouseUp}
                  fill={
                    selectedRows.includes(row.id) ? fillColor : unFilledColor
                  }
                  stroke="#000"
                >
                  <rect
                    x={(SVG_WIDTH - TUBE_WIDTH) / 2}
                    y={
                      index === rows.length - 1
                        ? endY
                        : startY + index * ROW_HEIGHT
                    }
                    width={TUBE_WIDTH}
                    height={ROW_HEIGHT}
                  />
                  <path
                    d={`M ${SVG_WIDTH / 2 - TUBE_WIDTH / 2} ${
                      height - BULB_RADIUS * 2
                    }
                      C ${SVG_WIDTH / 2 - BULB_RADIUS * 2} ${height + 15},
                        ${SVG_WIDTH / 2 + BULB_RADIUS * 2} ${height + 15},
                        ${SVG_WIDTH / 2 + TUBE_WIDTH / 2} ${
                      height - BULB_RADIUS * 2
                    }
                    `}
                  />
                </g>
              ) : (
                <rect
                  x={(SVG_WIDTH - TUBE_WIDTH) / 2}
                  y={
                    index === rows.length - 1
                      ? endY
                      : startY + index * ROW_HEIGHT
                  }
                  width={TUBE_WIDTH}
                  height={ROW_HEIGHT}
                  fill={
                    selectedRows.includes(row.id) ? fillColor : unFilledColor
                  }
                  stroke="#000"
                  style={{ cursor: 'pointer', transition: 'fill 0.2s' }}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    handleMouseDown(row.id);
                  }}
                  onMouseEnter={() => handleMouseEnter(row.id)}
                  onMouseUp={handleMouseUp}
                />
              )}
            </React.Fragment>
          ))}
        </svg>
      </div>
      <ClearButton
        mt="4"
        hideIcon
        hideBorder
        handleClick={() => setSelectedRows([])}
      />
    </>
  );
};

export default Thermometer;
