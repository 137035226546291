import React, { useState } from 'react';
import GenericCommonTable from 'components/CommonTables/GenericCommonTable';
import {
  useGetReportedMsgsForAdmin,
  useToggleReportedMsgStatus,
} from 'api-calls/chat-messages.queries';
import { Button, Icon, Typography as T } from 'components';
import * as S from './style';
import moment from 'moment';
import {
  coachStatusesQuery,
  navRoutes,
  userRoles,
} from '../../../../constants';
import Switch from '../../../../components/Switch';
import ViewMsgModal from './ViewMsgModal';

const ReportedMsgsTable = () => {
  const [pageParam, setPageParam] = useState({ page: 1, pageSize: 5 });
  const [viewedMsg, setViewedMsg] = useState({});
  const [togglingStatusId, setTogglingStatusId] = useState('');
  const { data, isPending } = useGetReportedMsgsForAdmin({
    pageParam,
  });
  const { mutate: toggleStatus } = useToggleReportedMsgStatus({
    page: pageParam.page,
  });

  const handleToggleStatus = (reportedChatMsgId) => {
    setTogglingStatusId(reportedChatMsgId);
    toggleStatus(
      { reportedChatMsgId },
      {
        onSettled: () => {
          setTogglingStatusId('');
        },
      }
    );
  };

  const columns = [
    {
      title: '#',
      render: (text, record, index) => (
        <T.P color="neutralMain" style={{ whiteSpace: 'nowrap' }}>
          {index + 1}
          {/* {record.id} */}
        </T.P>
      ),
    },
    {
      title: 'Date',
      render: (text, record) => (
        <T.P color="neutralMain" style={{ whiteSpace: 'nowrap' }}>
          {moment(record.createdAt).format('MMMM Do, YYYY')}
        </T.P>
      ),
    },
    {
      title: 'Learner ID',
      render: (text, record) => {
        const learner =
          record.sender.role === userRoles.LEARNER
            ? record.sender
            : record.receiver;

        return (
          <S.NameColumnWrapper>
            <T.P color="neutralMain">{learner.learnerId}</T.P>
            <T.Link
              to={navRoutes.COMMON.LEARNER.replace(':userId', learner.id)}
              style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <Icon icon="eye" />
              <T.H1
                size="regular"
                color="inherit"
                style={{ whiteSpace: 'nowrap' }}
              >
                View Profile
              </T.H1>
            </T.Link>
          </S.NameColumnWrapper>
        );
      },
    },
    {
      title: 'Coach Name',
      render: (text, record) => {
        const coach =
          record.sender.role === userRoles.COACH
            ? record.sender
            : record.receiver;

        return (
          <S.NameColumnWrapper>
            <T.P color="neutralMain">{coach.name}</T.P>
            <T.Link
              to={navRoutes.COMMON.COACH_PROFILE.replace(
                ':statusType',
                coachStatusesQuery.ALL
              ).replace(':coachId', coach.id)}
              style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <Icon icon="eye" />
              <T.H1
                size="regular"
                color="inherit"
                style={{ whiteSpace: 'nowrap' }}
              >
                View Profile
              </T.H1>
            </T.Link>
          </S.NameColumnWrapper>
        );
      },
    },
    {
      title: 'Who raised the flag',
      render: (text, record) => (
        <T.P color="neutralMain">
          <span style={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}>
            {record.reportingUserRole.toLowerCase()}
          </span>
        </T.P>
      ),
    },
    {
      title: 'View/Listen',
      render: (text, record) => (
        <Button.BasicButton
          variant="transparent"
          customColor="primaryMain"
          icon="eye"
          iconProps={{ color: 'currentColor' }}
          noOutline
          height="40px"
          width="90px"
          onClick={() => setViewedMsg(record)}
        >
          <T.P color="inherit">View</T.P>
        </Button.BasicButton>
      ),
    },
    {
      title: 'Status',
      render: (text, record) => (
        <S.SwitchWrapper>
          <Switch
            checked={record.status === 'RESOLVED'}
            label={record.status[0] + record.status.slice(1).toLowerCase()}
            onChange={() => handleToggleStatus(record.id)}
            disabled={togglingStatusId === record.id}
          />
          {togglingStatusId === record.id && <S.SwitchLoading />}
        </S.SwitchWrapper>
      ),
    },
  ];

  const handleChangePage = (page) => {
    setPageParam((prev) => ({
      ...prev,
      page,
    }));
  };

  return (
    <>
      <S.Container>
        <T.H1 mb="12px">Issues</T.H1>
        <T.P color="neutral70" mb="14px">
          This table displays all messages flagged by learners and coaches
        </T.P>
        {data?.reportedMsgs.length ? (
          <GenericCommonTable
            columns={columns}
            dataSource={data.reportedMsgs}
            isResponsiveScroll
            pageSize={pageParam.pageSize}
            setPage={handleChangePage}
            loading={isPending}
            page={pageParam.page}
            totalRecords={data.meta.count}
          />
        ) : (
          <S.NoDataWrapper>
            <T.P>No Issues available</T.P>
          </S.NoDataWrapper>
        )}
      </S.Container>
      <ViewMsgModal viewedMsg={viewedMsg} setViewedMsg={setViewedMsg} />
    </>
  );
};

export default ReportedMsgsTable;
