import styled from '@emotion/styled';

export const CLearButton = styled.button`
  width: 94px;
  height: 32px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme, hideBorder }) =>
    hideBorder ? '0' : `${theme.spacings[1]} ${theme.spacings[3]}`};
  margin-left: ${({ theme }) => `${theme.spacings[1]}`};
  border-radius: ${({ theme }) => theme.spacings[2]};
  border: ${({ theme, hideBorder }) =>
    hideBorder ? 'none' : `1px solid  ${theme.colors.neutral50}`};
  cursor: pointer;

  :focus {
    box-shadow: ${({ hideBorder }) =>
      hideBorder ? 'none' : 'inset 0px 0px 0px 2px rgba(5, 23, 48, 1)'};
    outline-style: solid;
    outline-width: 2px;
    outline-color: ${({ theme, hideBorder }) =>
      hideBorder ? 'transparent' : theme.colors.primaryMain};
    outline-offset: 2px;
  }
`;
