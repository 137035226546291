import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';
import { BrowserTracing } from '@sentry/tracing';
import ErrorBoundary from './ErrorBoundary';
import { queryClient } from './api-calls/queryClient';

// Function to check if the user has accepted the necessary cookie categories
const initializeGoogleAnalytics = () => {
  if (window?.Cookiebot?.consent) {
    const consent = window.Cookiebot.consent;

    if (consent.statistics) {
      ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname + window.location.search,
      });
    }
  }
};

if (process.env.NODE_ENV === 'production') {
  // SENTRY
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: window.location.host.includes('turningpages.co.uk')
      ? 'production'
      : 'staging',

    tracesSampleRate: 0.5,
  });

  window.addEventListener('CookiebotOnAccept', function () {
    initializeGoogleAnalytics();
  });
}

const container = document.getElementById('root');

const root = createRoot(container);
root.render(
  <QueryClientProvider client={queryClient}>
    <ErrorBoundary>
      <App ReactGA={ReactGA} />
    </ErrorBoundary>
    {process.env.NODE_ENV === 'development' ? (
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
    ) : null}
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
