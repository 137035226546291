import * as React from 'react';
import Avatar from '@mui/material/Avatar';

const stringToColor = (string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};

const stringAvatar = (name = 'User') => {
  const [firstName, lastName = ''] = name.split(' ');
  const secondLetter = lastName[0] || '';
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: '32px',
      height: '32px',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '150%',
    },
    children: `${firstName[0]}${secondLetter}`,
  };
};

const LetterAvatar = ({ name, ...props }) => {
  return <Avatar {...stringAvatar(name)} {...props} />;
};

export default LetterAvatar;
