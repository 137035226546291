import { useState } from 'react';
import * as S from './style';
import { PlayButton } from '../../../../../../components/Button';

import StepFooterButtons from '../../../StepFooterButtons';

const Typing = ({
  data: { questionText, questionAudioUrl, correctOptions = [] } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
}) => {
  const [state, setState] = useState({ text: '', isCorrect: false });
  const handleOnChange = (e) => {
    const {
      target: { value },
    } = e;
    if (correctOptions?.find((opt) => opt.option === value?.toLowerCase())) {
      setState({ text: value, isCorrect: true });
    } else {
      setState({ text: value, isCorrect: false });
    }
  };
  return (
    <>
      <PlayButton
        audioUrl={questionAudioUrl}
        iconProps={{ width: '32px', height: '32px' }}
        weight="bold"
        fallbackText={questionText}
      >
        {questionText}
      </PlayButton>
      <S.TypeInput
        type="text"
        id="type-correct-answer"
        aria-label="type-correct-answer"
        aria-labelledby="type-correct-answer"
        value={state.text}
        onChange={handleOnChange}
        isCorrect={state.isCorrect}
      />
      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={() => {
          handleNext();
          setState({ text: '', isCorrect: false });
        }}
        preview={preview}
        disabled={disabled}
        enableNext={state.isCorrect}
      />
    </>
  );
};

export default Typing;
