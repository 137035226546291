import { useNavigate, useParams } from 'react-router-dom';
import * as T from '../../../components/Typography';
import { BasicButton, PlayButton } from '../../../components/Button';
import { Row, Col } from '../../../components/Grid';
import Image from '../../../components/Image';
import { getMediaUrl } from '../../../helpers';

import * as S from './style';
import { audio } from '../../../constants';
import { useGeneralState } from '../../../context/general-state';
import { navRoutes } from './../../../constants';
import { useExercise } from 'context/exercise';
const ExerciseCompleted = () => {
  const { milestoneId, exerciseId } = useParams();
  const { exercise } = useExercise();

  const {
    state: { preferredVoice, isPreparation },
  } = useGeneralState();
  const navigate = useNavigate();
  const onClick = () => {
    if (isPreparation) {
      navigate(
        navRoutes.COACH.PREPARE_MILESTONE.replace(
          ':milestoneId',
          exercise?.milestoneId
        )
      );
    } else {
      navigate(
        navRoutes.EXERCISES.EXERCISE_FEEDBACK.replace(
          ':exerciseId',
          exerciseId
        ),
        {
          state: {
            milestoneId,
          },
        }
      );
    }
  };
  return (
    <S.Wrapper>
      <Row jc="center">
        <Col w={[4, 8, 8]}>
          <PlayButton
            audioUrl={getMediaUrl(
              audio.congratsAnotherExerciseCompleted,
              true,
              preferredVoice
            )}
            iconProps={{ width: '32px', height: '32px' }}
            padding="12px"
            fallbackText="Congrats! Another exercise completed"
          >
            Congrats! Another exercise completed
          </PlayButton>
        </Col>
      </Row>
      <Row jc="center">
        <Col w={[4, 8, 8]} mt={5} style={{ position: 'relative' }}>
          <Image
            image="exerciseCompletedBackground"
            style={{ background: 'white', borderRadius: 18 }}
          />
          <S.StyledIcon icon="cup" width="80" height="80" />
        </Col>
      </Row>
      <Row jc="center" ai="flex-end" style={{ flex: 1 }}>
        <Col w={[4, 8, 8]}>
          <S.ButtonWrapper flex="1">
            <BasicButton
              handleClick={onClick}
              icon="next"
              variant="primary"
              iconProps={{ width: '20', height: '20' }}
            >
              <T.P color="white" weight="semi">
                Next
              </T.P>
            </BasicButton>
          </S.ButtonWrapper>
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default ExerciseCompleted;
