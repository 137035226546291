import styled from '@emotion/styled';
import { Button as AntdButton } from 'antd';

export const Button = styled(AntdButton)`
  padding: 7.5px 16px;
  background: ${({ theme }) => theme.colors.neutral30};
  border-radius: 16px;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  margin-top: 8px;
  border: none;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-self: center;
  max-width: 600px;
  width: 100%;
`;
