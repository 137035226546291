export const ORG_STEPS = [
  {
    index: 1,
    title: 'Customise my organisation',
    desc: 'Ensuring your Turning Pages tool is easily recognisable to your coaches',
    user: 'ORGANISATION_ADMIN',
    icon: 'catalog',
    clickable: true,
    routeKey: 'CUSTOMISE',
  },
  {
    index: 2,
    title: 'Set up my facilitator(s)',
    desc: 'Ensuring your Turning Pages tool is easily recognisable to your coaches',
    user: 'ORGANISATION_ADMIN',
    icon: 'userWithPlus',
    clickable: true,
    routeKey: 'ADD_FACILITATORS',
  },
  {
    index: 3,
    title: 'Set up my coaches',
    desc: 'Ensuring your Turning Pages tool is easily recognisable to your coaches',
    user: 'COMMON',
    icon: 'usersGroup',
    clickable: true,
    routeKey: 'ADD_COACHES',
  },
];
