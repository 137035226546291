import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LearnersEmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  max-width: 420px;
`;
