import * as T from '../../../../../components/Typography';
import { BasicInput } from '../../../../../components/Inputs';
import { questionAndAnswerVariations } from '../../../../../constants';

import * as S from '../style';

const CorrectAnswers = ({
  variation,
  errors,
  onOptionChange,
  correctOptions,
}) => {
  let AnswerComponent = null;
  switch (variation) {
    case questionAndAnswerVariations.variations.IMAGE_ONLY:
    case questionAndAnswerVariations.variations.NO_IMAGE:
      AnswerComponent = (
        <BasicInput
          value={correctOptions?.[0]?.option}
          handleChange={(value) => {
            onOptionChange({
              key: 'option',
              value,
              id: correctOptions?.[0]?.id,
            });
          }}
          error={errors?.correctOptions && errors?.correctOptions?.[0]?.option}
          placeholder="0"
        />
      );
      break;

    case questionAndAnswerVariations.variations.WITH_ENDING:
      AnswerComponent = (
        <S.AnswerInputWrapper isSuffix>
          <BasicInput
            value={correctOptions?.[0]?.option}
            handleChange={(value) => {
              onOptionChange({
                key: 'option',
                value,
                id: correctOptions?.[0]?.id,
              });
            }}
            error={
              errors?.correctOptions && errors?.correctOptions?.[0]?.option
            }
            helper="Provide the correct answer"
            placeholder="0"
          />
          <BasicInput
            value={correctOptions?.[0]?.ending}
            handleChange={(value) => {
              onOptionChange({
                key: 'ending',
                value,
                id: correctOptions?.[0]?.id,
              });
            }}
            error={errors?.correctOptions?.[0]?.ending}
            helper="Provide Ending"
            placeholder="%"
          />
        </S.AnswerInputWrapper>
      );
      break;

    case questionAndAnswerVariations.variations.FRACTION:
      AnswerComponent = (
        <S.AnswerInputWrapper isFraction isError={errors?.correctOptions}>
          <BasicInput
            placeholder=""
            value={correctOptions?.[0]?.option}
            handleChange={(value) => {
              onOptionChange({
                key: 'option',
                value,
                id: correctOptions?.[0]?.id,
              });
            }}
            error={errors?.correctOptions?.[0]?.option}
          />
          <S.Divide />
          <BasicInput
            placeholder=""
            value={correctOptions?.[1]?.option}
            handleChange={(value) => {
              onOptionChange({
                key: 'option',
                value,
                id: correctOptions?.[1]?.id,
              });
            }}
            error={errors?.correctOptions?.[1]?.option}
          />
        </S.AnswerInputWrapper>
      );
      break;

    case questionAndAnswerVariations.variations.MULTIPLICATION:
      AnswerComponent = (
        <S.AnswerInputWrapper isError={errors?.correctOptions}>
          <BasicInput
            placeholder=""
            value={correctOptions?.[0]?.option}
            handleChange={(value) => {
              onOptionChange({
                key: 'option',
                value,
                id: correctOptions?.[0]?.id,
              });
            }}
            error={errors?.correctOptions?.[0]?.option}
          />
          <T.P weight="bold" m="0" size="large" lh="64px !important">
            X
          </T.P>
          <BasicInput
            placeholder=""
            value={correctOptions?.[1]?.option}
            handleChange={(value) => {
              onOptionChange({
                key: 'option',
                value,
                id: correctOptions?.[1]?.id,
              });
            }}
            error={errors?.correctOptions?.[1]?.option}
          />
          <T.P weight="bold" m="0" size="large" lh="64px !important">
            =
          </T.P>
          <BasicInput
            placeholder=""
            value={correctOptions?.[2]?.option}
            handleChange={(value) => {
              onOptionChange({
                key: 'option',
                value,
                id: correctOptions?.[2]?.id,
              });
            }}
            error={errors?.correctOptions?.[2]?.option}
          />
        </S.AnswerInputWrapper>
      );
      break;

    case questionAndAnswerVariations.variations.TWO_INPUTS_WITH_ENDINGS:
      AnswerComponent = (
        <>
          <S.AnswerInputWrapper isSuffix>
            <BasicInput
              value={correctOptions?.[0]?.option}
              handleChange={(value) => {
                onOptionChange({
                  key: 'option',
                  value,
                  id: correctOptions?.[0]?.id,
                });
              }}
              error={
                errors?.correctOptions && errors?.correctOptions?.[0]?.option
              }
              helper="Provide the correct answer"
              placeholder="0"
            />
            <BasicInput
              value={correctOptions?.[0]?.ending}
              handleChange={(value) => {
                onOptionChange({
                  key: 'ending',
                  value,
                  id: correctOptions?.[0]?.id,
                });
              }}
              error={errors?.correctOptions?.[0]?.ending}
              helper="Provide Ending"
              placeholder="%"
            />
          </S.AnswerInputWrapper>
          <S.AnswerInputWrapper mt="3" isSuffix>
            <BasicInput
              value={correctOptions?.[1]?.option}
              handleChange={(value) => {
                onOptionChange({
                  key: 'option',
                  value,
                  id: correctOptions?.[1]?.id,
                });
              }}
              error={
                errors?.correctOptions && errors?.correctOptions?.[1]?.option
              }
              helper="Provide the correct answer"
              placeholder=""
            />
            <BasicInput
              value={correctOptions?.[1]?.ending}
              handleChange={(value) => {
                onOptionChange({
                  key: 'ending',
                  value,
                  id: correctOptions?.[1]?.id,
                });
              }}
              error={errors?.correctOptions?.[1]?.ending}
              helper="Provide Ending"
              placeholder="%"
            />
          </S.AnswerInputWrapper>
        </>
      );
      break;
    default:
      break;
  }

  return (
    <>
      <T.P weight="bold" mt="8" ml="2">
        Answers:
      </T.P>
      {AnswerComponent}
      {errors?.correctOptions && typeof errors.correctOptions === 'string' && (
        <T.P mb="2" color="error">
          {errors.correctOptions}
        </T.P>
      )}
    </>
  );
};

export default CorrectAnswers;
