import { hslaToHex } from './set-color-variations';

const isColorBright = (color = '') => {
  let r, g, b, brightness;
  const hexColor = color?.includes('hsla') ? hslaToHex(color) : color;
  let _color = hexColor.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);

  if (_color) {
    r = parseInt(_color[1], 16);
    g = parseInt(_color[2], 16);
    b = parseInt(_color[3], 16);
  } else {
    return;
  }

  brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 128;
};

export default isColorBright;
