import { string } from 'yup';
import { fields, createSchema, validate as _validate } from '..';
import { preferredSessionsTypes } from 'constants';

const {
  firstName,
  lastName,
  optionalEmail,
  requiredText,
  birthDate,
  requiredBoolean,
  optionalText,
  email,
  preferredSessionsType,
  postcode,
} = fields;

const organisationWorkingInPrisonsSchema = createSchema({
  firstName,
  lastName,
  involvedBefore: requiredText,
  hasDeviceInternetAccess: requiredText,
  preReleased: requiredBoolean,
  nomisId: optionalText,
  delius: optionalText,

  probationOfficerName: requiredText,
  probationOfficerEmail: email,
  email: optionalEmail,
  availability: optionalText,
  additionalInfo: requiredText,
  dateOfBirth: birthDate,
  requiredCoachGender: optionalText,
  additionalNotes: optionalText,
  referralType: requiredText,
});

const organisationNotWorkingInPrisonsSchema = createSchema({
  firstName,
  lastName,
  hasDeviceInternetAccess: requiredText,
  involvedBefore: requiredText,
  preferredSessionsType,
  postcode: string().when('preferredSessionsType', {
    is: preferredSessionsTypes.ONLINE,
    then: string().notRequired(),
    otherwise: postcode,
  }),
  email: optionalEmail,
  availability: optionalText,
  additionalInfo: requiredText,
  dateOfBirth: birthDate,
});

const validate = (data, isWorkingInPrison) => {
  return _validate(
    isWorkingInPrison
      ? organisationWorkingInPrisonsSchema
      : organisationNotWorkingInPrisonsSchema,
    data
  );
};

export default validate;
