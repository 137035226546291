const FormatAlignCenter = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 13.625C2.82292 13.625 2.67458 13.565 2.555 13.445C2.435 13.3254 2.375 13.1771 2.375 13C2.375 12.8229 2.435 12.6746 2.555 12.555C2.67458 12.435 2.82292 12.375 3 12.375H13C13.1771 12.375 13.3254 12.435 13.445 12.555C13.565 12.6746 13.625 12.8229 13.625 13C13.625 13.1771 13.565 13.3254 13.445 13.445C13.3254 13.565 13.1771 13.625 13 13.625H3ZM5.5 11.125C5.32292 11.125 5.17438 11.065 5.05438 10.945C4.93479 10.8254 4.875 10.6771 4.875 10.5C4.875 10.3229 4.93479 10.1744 5.05438 10.0544C5.17438 9.93479 5.32292 9.875 5.5 9.875H10.5C10.6771 9.875 10.8254 9.93479 10.945 10.0544C11.065 10.1744 11.125 10.3229 11.125 10.5C11.125 10.6771 11.065 10.8254 10.945 10.945C10.8254 11.065 10.6771 11.125 10.5 11.125H5.5ZM3 8.625C2.82292 8.625 2.67458 8.565 2.555 8.445C2.435 8.32542 2.375 8.17708 2.375 8C2.375 7.82292 2.435 7.67437 2.555 7.55437C2.67458 7.43479 2.82292 7.375 3 7.375H13C13.1771 7.375 13.3254 7.43479 13.445 7.55437C13.565 7.67437 13.625 7.82292 13.625 8C13.625 8.17708 13.565 8.32542 13.445 8.445C13.3254 8.565 13.1771 8.625 13 8.625H3ZM5.5 6.125C5.32292 6.125 5.17438 6.065 5.05438 5.945C4.93479 5.82542 4.875 5.67708 4.875 5.5C4.875 5.32292 4.93479 5.17438 5.05438 5.05438C5.17438 4.93479 5.32292 4.875 5.5 4.875H10.5C10.6771 4.875 10.8254 4.93479 10.945 5.05438C11.065 5.17438 11.125 5.32292 11.125 5.5C11.125 5.67708 11.065 5.82542 10.945 5.945C10.8254 6.065 10.6771 6.125 10.5 6.125H5.5ZM3 3.625C2.82292 3.625 2.67458 3.565 2.555 3.445C2.435 3.32542 2.375 3.17708 2.375 3C2.375 2.82292 2.435 2.67437 2.555 2.55437C2.67458 2.43479 2.82292 2.375 3 2.375H13C13.1771 2.375 13.3254 2.43479 13.445 2.55437C13.565 2.67437 13.625 2.82292 13.625 3C13.625 3.17708 13.565 3.32542 13.445 3.445C13.3254 3.565 13.1771 3.625 13 3.625H3Z"
      fill="currentColor"
    />
  </svg>
);

export default FormatAlignCenter;
