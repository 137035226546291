import React from 'react';
import * as S from './style';
import Modal from '../ModalNew';
const maskStyle = { backgroundColor: 'rgba(0,0,0,0.65)' };

export const OldModal = ({
  visible,
  setIsModalVisible,
  children,
  onCancel,
  ...props
}) => {
  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel();
    }
    if (typeof setIsModalVisible === 'function') {
      setIsModalVisible(false);
    }
  };

  return (
    <>
      <S.Modal
        visible={visible}
        onCancel={handleCancel}
        maskStyle={maskStyle}
        role="dialog"
        {...props}
      >
        {children}
      </S.Modal>
    </>
  );
};

export default Modal;
