import { useLocation } from 'react-router-dom';
import { BasicInput } from '../../components/Inputs';
import * as T from '../../components/Typography';
import { formTypes, navRoutes } from '../../constants';
import { useMemo } from 'react';
import RequireVo from './RequireVo';

const MediaKeyInput = ({
  label,
  type,
  index,
  form = formTypes.STEP, // use step form type by default since its used more
  ...props
}) => {
  const location = useLocation();

  const mediaRedirectionUrl = useMemo(() => {
    let url = `${navRoutes.CMS.MEDIA}?prevPath=${location.pathname}&form=${form}&type=${type}`;
    if (!!index || index === 0) {
      url += `&index=${index}`;
    }
    return url;
  }, [form, index, location.pathname, type]);

  return (
    <>
      <BasicInput
        label={
          <>
            {label} -{' '}
            <T.Link to={mediaRedirectionUrl}>Pick / Upload new</T.Link>
          </>
        }
        {...props}
      />
      <RequireVo
        type={type}
        formType={form}
        index={index}
        existingFileKey={props.value}
      />
    </>
  );
};

export { MediaKeyInput };
