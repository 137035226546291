import { useRef } from 'react';
import { string } from 'yup';

import { BasicInput } from '../../../../components/Inputs';
import { MediaKeyInput } from '../../../components/MediaKeyInput';
import { mediaTypes } from '../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';

const initialData = {
  questionText: '',
  questionAudioKey: '',
  textAudio: '',
  textAudioKey: '',
};

const ListenAndRepeatComp = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateFormFields, updateDataFields } = useStepForm(
    initialRef.current
  );
  const { form, data, validationErrs } = state;

  return (
    <div>
      <BasicInput
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        label="Top Question text"
        error={validationErrs.textAudio}
        m={{ mt: 8 }}
      />

      <MediaKeyInput
        label="Top Question text Audio"
        type={mediaTypes.TEXT_AUDIO}
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs.textAudioKey}
      />

      <BasicInput
        value={data.questionText}
        handleChange={(questionText) => updateDataFields({ questionText })}
        label="Question text"
        m={{ mt: 8 }}
        error={validationErrs.questionText}
      />

      <MediaKeyInput
        label="Question text Audio"
        type={mediaTypes.QUESTION_AUDIO}
        value={form.questionAudioKey}
        handleChange={(questionAudioKey) =>
          updateFormFields({ questionAudioKey })
        }
        m={{ mt: 5 }}
        error={validationErrs.questionAudioKey}
      />
    </div>
  );
};

const validationSchema = {
  questionText: string().optional(),
  questionAudioKey: string().required('Question text audio is required'),
  textAudio: string().optional(),
  textAudioKey: string().optional(),
};

export { validationSchema };
export default ListenAndRepeatComp;
