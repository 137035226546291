import styled from '@emotion/styled';

export const PyramidInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: ${({ theme }) => theme.spacings[5]};
  background-color: ${({ theme }) => theme.colors.neutralLight};
`;

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
  position: relative;

  /* for delete icon */
  & > button {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 64px;
  & input {
    height: 64px;
    padding: 8px;
    text-align: center;
  }
`;
