import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import * as S from './style';
import NoteCard from '../../../components/NoteCard';
import { navRoutes } from '../../../constants';
import AddNote from './AddNote';
import { useGetLearnerNotes } from 'api-calls/learners.queries';

const Notes = ({ learnerId }) => {
  const {
    notes = [],
    error: getLearnerNotesError,
    isLoading: getLearnerNotesLoading,
  } = useGetLearnerNotes({ learnerId });

  const viewAllNotesLink = navRoutes.COMMON.LEARNER_NOTES.replace(
    ':learnerId',
    learnerId
  );

  if (getLearnerNotesError) {
    return (
      <T.P mb={2} mt={2} color="error">
        Error loading notes
      </T.P>
    );
  }

  if (getLearnerNotesLoading) {
    return (
      <T.P mb={2} mt={2} color="primary">
        Loading notes...
      </T.P>
    );
  }

  return (
    <Col w={[4, 12, 12]} mt="7" key={`column-notes`}>
      <S.ColWrapper>
        <Row inner mb="3">
          <Col w={[2, 6, 6]}>
            <T.H2 size="med">Notes</T.H2>
          </Col>
          <Col w={[2, 6, 6]} display="flex" jc="flex-end">
            <T.Link to={viewAllNotesLink} color="neutralMain">
              View all
            </T.Link>
          </Col>
        </Row>

        {notes.length ? (
          notes
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            .slice(0, 3)
            .map(({ note, id, updatedAt }) => (
              <Row mb={4}>
                <NoteCard
                  createdAt={updatedAt}
                  editPath={navRoutes.COMMON.EDIT_LEARNER_NOTE}
                  editState={{
                    note,
                    noteId: id,
                    learnerId,
                  }}
                  note={note}
                />
              </Row>
            ))
        ) : (
          <Row inner>
            <Col w={[4, 12, 6]}>
              <T.P>There is no notes yet</T.P>
            </Col>
          </Row>
        )}

        <AddNote learnerId={learnerId} />
      </S.ColWrapper>
    </Col>
  );
};

export default Notes;
