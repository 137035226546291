import React from 'react';

const LeftProgressToRightStep = ({ width, height, color, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '304'}
      height={height || '329'}
      fill="none"
      viewBox="0 0 304 329"
    >
      <path
        stroke={color || '#050D30'}
        stroke-dasharray="13 13"
        stroke-linecap="round"
        stroke-width="4"
        d="M2.01 2S.406 40.5 37.388 69.103c54.387 42.065 126.973 21.001 171.963 42.065C309.721 154.234 295.893 321.083 302 326.5"
      />
    </svg>
  );
};

export default LeftProgressToRightStep;
