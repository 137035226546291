import { useRef, useReducer, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import ReactGA from 'react-ga4';
import validate from '../../../validation/schemas/coach-signup';
import { Col, Row } from '../../../components/Grid';
import { Typography as T, Inputs as I, ReCaptcha } from '../../../components';
import Checkbox from '../../../components/Inputs/Checkbox/';
import { BasicButton } from '../../../components/Button';
import { BasicInput } from '../../../components/Inputs';
import Loading from '../../../components/Loading';
import Tip from '../../../components/Tip';

import {
  useGetFacilitatorInvite,
  useSignup as useFacilitatorSignup,
} from '../../../api-calls/facilitator.queries';
import {
  useSignup as useCoachSignup,
  useGetCoachBySignupToken,
} from '../../../api-calls/coaches.queries';
import { cleanEmail } from '../../../helpers';
import { useAuth } from '../../../context/auth';
import { navRoutes } from '../../../constants';
import { useOrganisationDetails } from '../../../context/organisation-details';
import { useSiteSettings } from 'context/site-settings';
const { EXTERNAL } = navRoutes;

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }
  return { ...state, ...value };
}

const tipString = `
Password must contain:
  * Minimum of 8 characters
  * One capital letter
  * One lowercase letter
  * One number
  * One special character
`;

const SignupCoachOrFacilitator = () => {
  const { pathname } = useLocation();
  const isFacilitator = pathname.includes('/facilitator/');
  const { mutateAsync: coachSignup, isLoading: coachSignupLoading } =
    useCoachSignup();
  const {
    mutateAsync: facilitatorSignup,
    isLoading: facilitatorSignupLoading,
  } = useFacilitatorSignup();
  const dashboardLink = isFacilitator
    ? navRoutes.FACILITATOR.DASHBOARD
    : navRoutes.COACH.DASHBOARD;

  const linkState = isFacilitator ? { showOnboarding: true } : undefined;

  const { inviteToken } = useParams();
  const {
    isLoading: getInviteLoading,
    isSuccess: isGetFacilitatorSuccess,
    data: facilitatorInvite,
  } = useGetFacilitatorInvite({ inviteToken }, { enabled: isFacilitator });

  const {
    isLoading: getCoachLoading,
    isSuccess,
    data,
  } = useGetCoachBySignupToken(
    { token: inviteToken },
    { enabled: !isFacilitator }
  );
  const { courseName } = useSiteSettings();

  const initialState = {
    form: {
      token: inviteToken,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      giveConsent: false,
      shareData: false,
      organisationName: '',
      userId: null,
    },
    httpError: '',
    validationErrs: {},
  };

  const [state, setState] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const { setOrganisationIdFromInvite } = useOrganisationDetails();
  const submitAttempt = useRef(false);

  const {
    form: {
      firstName,
      lastName,
      email,
      password,
      giveConsent,
      shareData,
      organisationName,
    },
    validationErrs,
  } = state;

  const setFormData = (data) => {
    setState((prevState) => ({
      httpError: '',
      form: { ...prevState.form, ...data },
    }));
  };

  const validateForm = () => {
    try {
      validate(state.form);
      setState({ validationErrs: { hasError: false } });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({
          validationErrs: { ...error.inner, hasError: true },
        });
      }
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      handleSignup();
    }
  };

  const handleSignup = async () => {
    try {
      let resultData = {};
      if (isFacilitator) {
        resultData = await facilitatorSignup({
          ...state.form,
          email: cleanEmail(email),
        });
      } else {
        resultData = await coachSignup({
          ...state.form,
          email: cleanEmail(email),
        });
      }

      if (resultData) {
        setUser(resultData);
        if (ReactGA.isInitialized) {
          ReactGA.event({
            category: 'sign_up',
            action: 'sign_up',
          });
          ReactGA.set({
            ...resultData,
          });
        }
        navigate(dashboardLink, {
          state: linkState,
        });
      }
    } catch (error) {
      const err = error?.response?.data || error;
      if (err) {
        setState({
          httpError: err.message,
          validationErrs:
            err?.data?.field === 'email'
              ? {
                  ...validationErrs,
                  email: err.message,
                }
              : { ...validationErrs },
        });
      }
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, lastName, email, password, giveConsent, shareData]);

  useEffect(() => {
    if (isSuccess) {
      setState((prevState) => ({
        form: {
          ...prevState.form,
          ...data,
        },
      }));
      setOrganisationIdFromInvite(data.organisationId);
    }
  }, [isSuccess, data, setOrganisationIdFromInvite]);

  useEffect(() => {
    if (isGetFacilitatorSuccess) {
      setState((prevState) => ({
        form: {
          ...prevState.form,
          ...facilitatorInvite,
        },
      }));
      setOrganisationIdFromInvite(facilitatorInvite.organisationId);
    }
  }, [isGetFacilitatorSuccess, facilitatorInvite, setOrganisationIdFromInvite]);

  if (getCoachLoading || getInviteLoading) return <Loading />;

  return (
    <>
      <Row>
        <Col w={[4, 12, 12]} mb="40px">
          <T.H1 weight="bold" color="neutralMain" mb="4">
            Create your {isFacilitator ? 'facilitator' : 'coach'} account
          </T.H1>
          <T.P>
            Welcome to {courseName}! You have been invited to create a{' '}
            {isFacilitator ? 'facilitator' : 'coach'} account by{' '}
            {organisationName}. Get started by filling in the details below.
          </T.P>
        </Col>
        <Col w={[4, 11, 6]}>
          <I.BasicInput
            label="First name"
            placeholder="Name..."
            type="text"
            value={firstName}
            autoFocus
            handleChange={(firstName) => setFormData({ firstName })}
            error={validationErrs.firstName}
          />
        </Col>
        <Col w={[4, 11, 6]}>
          <I.BasicInput
            label="Last name"
            placeholder="Name..."
            type="text"
            value={lastName}
            handleChange={(lastName) => setFormData({ lastName })}
            error={validationErrs.lastName}
          />
        </Col>
        <Col w={[4, 11, 6]} mt="5">
          <BasicInput
            label="Email address "
            placeholder="Email..."
            value={email}
            name="email"
            handleChange={(email) => setFormData({ email })}
            error={validationErrs.email}
          />
        </Col>
        <Col w={[4, 11, 6]} mt="5">
          <BasicInput
            label="Create a password"
            placeholder="Password..."
            value={password}
            name="password"
            type="password"
            handleChange={(password) => setFormData({ password })}
            error={validationErrs.password}
          />
        </Col>

        <Col w={[4, 11, 6]} mt="3">
          <Tip coachNotesStyle={true} ml={0} text={tipString} p="12px 16px" />
          <Checkbox
            mt="4"
            plain
            font="regular"
            checked={giveConsent}
            handleChange={(giveConsent) => setFormData({ giveConsent })}
            label={
              <T.P color="neutralMain">
                By creating an account I give consent to {courseName} using and
                keeping the information I provide as described in our{' '}
                <T.Link
                  to={EXTERNAL.PRIVACY_POLICY}
                  color="neutralMain"
                  external
                  underline
                >
                  Privacy Policy
                </T.Link>
              </T.P>
            }
            error={validationErrs.giveConsent}
          />
          <Checkbox
            plain
            font="regular"
            handleChange={(shareData) => setFormData({ shareData })}
            checked={shareData}
            label={
              <T.P color="neutralMain">
                Do you give consent for your details to be shared with your
                programme manager?
              </T.P>
            }
            mb={4}
            error={validationErrs.shareData}
          />
          <BasicButton
            variant="primary"
            disabled={
              facilitatorSignupLoading ||
              coachSignupLoading ||
              getCoachLoading ||
              getInviteLoading ||
              !giveConsent ||
              !shareData
            }
            loading={facilitatorSignupLoading || coachSignupLoading}
            type="submit"
            handleClick={handleSubmit}
          >
            <T.P color="white" weight="semi">
              Create account
            </T.P>
          </BasicButton>
          <T.P color="error" mt="3">
            {state.httpError}
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 11, 6]}>
          <ReCaptcha />
        </Col>
      </Row>
    </>
  );
};

export default SignupCoachOrFacilitator;
