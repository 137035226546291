import { useMemo, useRef } from 'react';
import { Typography as T } from '../../../../../components';
import { BasicInput, Checkbox } from '../../../../../components/Inputs';
import { MediaKeyInput } from '../../../../components/MediaKeyInput';
import { mediaTypes } from '../../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import * as S from '../style';

export { validationSchema } from './validation';

const initialData = {
  options: [],
  rowSize: null,
  textAudio: '',
  textAudioKey: '',
};

const Pyramid = () => {
  const initialRef = useRef(initialData);
  const { state, updateDataFields, updateFormMetaData } = useStepForm(
    initialRef.current
  );
  const { data = initialData, validationErrs } = state;

  const handleRowSizeChange = (rowSize) => {
    if (rowSize < 3 || rowSize > 4) {
      updateFormMetaData({
        validationErrs: {
          ...validationErrs,
          rowSize: 'Row size must be between 3 and 4',
        },
      });
      updateDataFields({ rowSize });

      return;
    }
    const newOptions = [];
    let currentIndex = 0;

    Array.from({ length: rowSize }).forEach((_, rowIndex) => {
      Array.from({ length: rowIndex + 1 }).forEach(() => {
        newOptions.push({
          id: currentIndex,
          option: data.options[currentIndex]?.option || '',
          showValue: data.options[currentIndex]?.showValue || true,
        });
        currentIndex += 1;
      });
    });

    updateDataFields({ options: newOptions, rowSize });
    updateFormMetaData({
      validationErrs: {
        ...validationErrs,
        rowSize: '',
      },
    });
  };

  const onOptionChange = ({ key, value, id }) => {
    updateDataFields({
      options: data.options.map((e) => {
        if (e.id !== id) return e;
        return {
          ...e,
          [key]: value,
        };
      }),
    });
  };

  const showSquares = data.rowSize >= 3 && data.rowSize <= 4;
  const rows = useMemo(() => {
    if (!showSquares) {
      return [];
    }
    const options = data.options || [];
    const result = [];
    let index = 0;
    let rowLength = 1;

    while (index < options.length) {
      result.push(options.slice(index, index + rowLength));
      index += rowLength;
      rowLength += 1;
    }

    return result;
  }, [data.options, showSquares]);

  return (
    <div>
      <BasicInput
        label="Explainer Text"
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        m={{ mt: 8 }}
        error={validationErrs?.textAudio}
      />
      <MediaKeyInput
        type={mediaTypes.TEXT_AUDIO}
        label="Explainer Text file Key"
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs?.textAudioKey}
      />
      <BasicInput
        label="Row Size"
        value={data.rowSize}
        handleChange={handleRowSizeChange}
        m={{ mt: 8 }}
        type="number"
        error={validationErrs.rowSize}
      />

      <T.P mt="8">
        Fill in all the pyramid with the correct values. uncheck the box if you
        want to hide the value and allow the user to fill it.
      </T.P>
      {showSquares && (
        <S.PyramidInputsWrapper>
          {rows.map((rowOptions, rowIndex) => (
            <S.PyramidRowWrapper key={rowIndex} rowSize={data.rowSize}>
              {rowOptions.map((option) => {
                const id = option.id;
                const error = validationErrs?.options?.[id]?.option;

                return (
                  <S.BoxWrapper>
                    <BasicInput
                      key={id}
                      placeholder=""
                      value={option.option}
                      handleChange={(value) => {
                        onOptionChange({
                          key: 'option',
                          value,
                          id,
                        });
                      }}
                      bgColor={option.showValue ? 'neutralSurface' : ''}
                      borderColor={
                        error ? 'error' : option.showValue ? '' : 'primaryMain'
                      }
                      error={error}
                      hideErrorMsg
                      textAlign="center"
                    />
                    <Checkbox
                      w="33px"
                      plain
                      noPadding
                      checked={option.showValue}
                      handleChange={(checked) => {
                        onOptionChange({
                          key: 'showValue',
                          value: checked,
                          id,
                        });
                      }}
                      error={validationErrs.confirmedPermissionType}
                      hideErrorMsg
                    />
                  </S.BoxWrapper>
                );
              })}
            </S.PyramidRowWrapper>
          ))}
        </S.PyramidInputsWrapper>
      )}
    </div>
  );
};

export default Pyramid;
