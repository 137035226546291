import { useState } from 'react';
import * as S from './style';

import { Typography as T, Icon, Grid } from '../../../components';

import { Checkbox } from 'components/Inputs';

import { useUpdateLearnerGoalTask } from '../../../api-calls/learnersGoals.queries';
const { Col } = Grid;

const Task = ({ item, completedGoal, goalId, refetch, handleTaskCheck }) => {
  const [editItemId, setEditItemId] = useState(0);
  const [editItem, setEditItem] = useState('');

  const { mutateAsync: updateTask } = useUpdateLearnerGoalTask();
  const handleItemEdit = async ({ id, completed }) => {
    setEditItemId(0);
    if (editItem) {
      await updateTask({
        taskId: id,
        personalGoalId: goalId,
        title: editItem,
        completed: completed,
      });
      // setEditMode(false);
      await refetch();
    }
  };

  const handleCheckboxClick = ({ value, id, task }) => {
    updateTask({
      ...task,
      taskId: id,
      personalGoalId: goalId,
      completed: value,
    });
    return handleTaskCheck({
      goalId,
      taskId: id,
      completed: value,
      title: task.title,
    });
  };

  const handleItemEditChange = async ({ value, id }) => {
    setEditItem(value);
  };
  return (
    <Col w={[4, 12, 12]} key={item.id + item.title}>
      {item.id === editItemId ? (
        <S.EditInputWrapper>
          <S.EditInput
            value={editItem}
            onChange={(e) =>
              handleItemEditChange({
                value: e.target.value,
                id: item.id,
              })
            }
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleItemEdit({
                  id: item.id,
                  completed: item.completed,
                });
              }
            }}
          />
          <S.SaveButton
            onClick={() =>
              handleItemEdit({
                id: item.id,
                completed: item.completed,
              })
            }
          >
            Save
          </S.SaveButton>
        </S.EditInputWrapper>
      ) : (
        <S.ListItemWrapper>
          <S.CheckBoxItem>
            <Checkbox
              borderColor={'neutral40'}
              checked={item.completed}
              disabled={completedGoal}
              handleChange={(value) =>
                handleCheckboxClick({
                  value,
                  id: item.id,
                  completed: item.completed,
                  task: item,
                })
              }
              plain
              noPadding
            />
            <T.P>{item.title}</T.P>
          </S.CheckBoxItem>
          {!completedGoal && (
            <S.EditButton2
              onClick={() => {
                setEditItemId(item.id);
                setEditItem(item.title);
              }}
            >
              <Icon icon="edit2" color={'primaryMain'} />
            </S.EditButton2>
          )}
        </S.ListItemWrapper>
      )}
    </Col>
  );
};

export default Task;
