const getGreetings = () => {
  const now = new Date();
  const isMorning = now.getHours() > 5 && now.getHours() <= 12;
  const isAfternoon = now.getHours() > 12 && now.getHours() <= 18;
  const isEvening = now.getHours() > 18 && now.getHours() <= 22;
  const isNight = now.getHours() > 22 || now.getHours() <= 5;

  if (isMorning) {
    return 'Good morning';
  } else if (isAfternoon) {
    return 'Good afternoon';
  } else if (isEvening) {
    return 'Good evening';
  } else if (isNight) {
    return 'Good night';
  } else {
    return 'Welcome';
  }
};

export default getGreetings;
