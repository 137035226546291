import { useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';

function RouteChangeTracker({ isProduction, ReactGA }) {
  let location = useLocation();
  useLayoutEffect(() => {
    if (isProduction && ReactGA?.isInitialized) {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
      });
    }
  }, [ReactGA, isProduction, location.pathname, location.search]);

  return <div></div>;
}

export default RouteChangeTracker;
