import styled from '@emotion/styled';
import { setMargin } from '../../../helpers';

export const Wrapper = styled.div`
  margin: 0 auto;
`;

export const PlayButtonWrapper = styled.div`
  ${setMargin};
  display: flex;
  align-items: center;
  width: 100%;
`;
