import { useParams } from 'react-router-dom';
import { useGetLearnerUniqueId } from '../../../api-calls/learners.queries';

import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';

import { LEARNER, COACH } from '../../../constants/nav-routes';
import { useAuth } from '../../../context/auth';
import * as S from './style';
import { useEffect } from 'react';

const LearnerId = () => {
  const { user, setUser } = useAuth();
  const { userId } = useParams();

  const { data: learner, isSuccess } = useGetLearnerUniqueId(
    { learnerUserId: userId },
    { enabled: !!userId }
  );

  useEffect(() => {
    if (isSuccess) {
      setUser({ ...user, selectedLearnerId: learner.id });
    }
  }, [isSuccess, learner?.id, setUser, user]);

  return (
    <>
      <S.Wrapper>
        <Row>
          <Col w={[4, 10, 9]}>
            <T.H2 color="neutralMain">
              This is your new Learner ID for when you want to log in. Please
              keep it safe.
            </T.H2>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 10, 9]} mt="6" jc="center">
            <S.Circle>
              <T.H1 color="white">{learner?.learnerUniqueId}</T.H1>
            </S.Circle>
          </Col>
        </Row>{' '}
      </S.Wrapper>
      <S.NextButtonWrapper mt="10">
        <Col w={[4, 10, 9]}>
          <BasicButton
            variant="primary"
            icon="next"
            iconProps={{ width: '24px', height: '24px' }}
            to={LEARNER.ONBOARDING_1}
          />
        </Col>
        <Col w={[4, 10, 9]} mt="3">
          <BasicButton variant="secondary" to={COACH.LEARNERS}>
            <T.P color="white" weight="semi">
              Exit onboarding
            </T.P>
          </BasicButton>
        </Col>
      </S.NextButtonWrapper>
    </>
  );
};

export default LearnerId;
