import { useState, useEffect } from 'react';
import * as T from '../../../components/Typography';
import * as S from './style';
import LoaderIcon from './LoaderIcon';

const SplashScreen = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          window.location.href = 'https://turningpages.co.uk';
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <S.PageWrapper>
      <LoaderIcon />

      <T.H1 color="neutralMain" mb={3} mt={7} ta="center" display="inline">
        We have moved to TurningPages{' '}
        <T.H1 display="inline" size="larger">
          🎉
        </T.H1>
      </T.H1>
      <T.P color="neutralMain" mb={8} ta="center" size="med">
        All accounts have been migrated to the new app. Please use the same
        credentials to login.
      </T.P>

      <T.P color="neutralMain" mt={2} mb={9}>
        Redirecting to{' '}
        <T.Link
          to={'https://turningpages.co.uk'}
          color="primary"
          external
          target="_self"
        >
          TurningPages
        </T.Link>{' '}
        in a few seconds...
      </T.P>

      <div
        style={{
          width: '100%',
        }}
      >
        <S.BorderLinearProgress variant="determinate" value={progress} />
      </div>
    </S.PageWrapper>
  );
};
export default SplashScreen;
