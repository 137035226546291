import { useReducer, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import { BasicSelect } from '../../../components/Inputs';
import { useUpdateLearnerPreferredVoice } from '../../../api-calls/learners.queries';
import { useAuth } from '../../../context/auth';
import { useGeneralState } from '../../../context/general-state';
import { navRoutes as R } from '../../../constants';
import * as S from './style';

const initialState = {
  preferredVoice: '',
  validationErr: '',
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const Onboarding5 = () => {
  const [state, setState] = useReducer(reducer, initialState);
  const { preferredVoice, validationErr } = state;
  const { user } = useAuth();
  const navigate = useNavigate();
  const { state: generalState, setState: setGeneralState } = useGeneralState();

  const {
    mutateAsync: updateLearnerPreferredVoice,
    error: httpError,
    isLoading,
    isSuccess,
  } = useUpdateLearnerPreferredVoice();

  useEffect(() => {
    if (generalState?.selectedLearnerInfo?.preferredVoice) {
      setState({
        preferredVoice: generalState?.selectedLearnerInfo?.preferredVoice,
      });
    }
  }, [generalState?.selectedLearnerInfo?.preferredVoice]);
  const validate = (preferredVoice) => {
    if (preferredVoice === 'FEMALE' || preferredVoice === 'MALE') return true;
    return false;
  };

  useEffect(() => {
    const isValid = validate();
    if (isValid) setState({ validationErr: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferredVoice]);

  const handlePreferVoice = async () => {
    if (isSuccess || generalState?.selectedLearnerInfo?.preferredVoice) {
      navigate(R.LEARNER.PROGRESS_FIRST_PAGE);
    } else {
      setState({ validationErr: 'Please select an option' });
    }
  };

  const onChange = async (preferredVoice) => {
    const isValid = validate(preferredVoice);
    if (isValid) {
      await updateLearnerPreferredVoice({
        learnerUserId: user.selectedLearnerId,
        preferredVoice,
      });
      setGeneralState({
        selectedLearnerInfo: {
          ...generalState.selectedLearnerInfo,
          preferredVoice,
        },
      });
    }
  };

  return (
    <>
      <S.Wrapper>
        <Row>
          <Col w={[4, 9, 9]}>
            <T.H2 color="neutralMain">
              Which voice would you prefer when you hear audio?
            </T.H2>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 9, 9]} mt="3">
            <BasicSelect
              options={[
                { label: 'Female', value: 'FEMALE' },
                { label: 'Male', value: 'MALE' },
              ]}
              selected={preferredVoice}
              handleChange={(preferredVoice) => {
                setState({ preferredVoice });
                onChange(preferredVoice);
              }}
            />
          </Col>
        </Row>
      </S.Wrapper>
      <S.NextButtonWrapper>
        <Row mt="6" ai="end" style={{ flex: 1 }}>
          <Col w={[4, 9, 9]}>
            {validationErr && (
              <T.P color="error" mb="2">
                {validationErr}
              </T.P>
            )}
            {httpError?.message && (
              <T.P mb="2" color="error">
                {httpError?.message}
              </T.P>
            )}
            <BasicButton
              variant="primary"
              icon="next"
              loading={isLoading}
              iconProps={{ width: '24px', height: '24px' }}
              handleClick={handlePreferVoice}
            />
          </Col>
          <Col w={[4, 9, 9]} mt="3">
            <BasicButton variant="secondary" to={R.COACH.LEARNERS}>
              <T.P color="white" weight="semi">
                Exit onboarding
              </T.P>
            </BasicButton>
          </Col>
        </Row>
      </S.NextButtonWrapper>
    </>
  );
};

export default Onboarding5;
