import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 0 120px;
  ${({ theme }) => theme.media.tablet} {
    padding: 0 40px;
  }
  ${({ theme }) => theme.media.mobile} {
    padding: 0 20px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: ${({ insideSignup }) => (insideSignup ? '48px' : '145px')};
  gap: 16px;
  ${({ theme }) => theme.media.mobile} {
    margin-top: 35px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  margin-top: 48px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: auto;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
`;
