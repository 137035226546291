import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Loading } from 'components';
import * as S from './style';
import { Users } from 'api-calls';
import { useAuth } from 'context/auth';
import { navRoutes as R, userRoles } from 'constants';

const AutoLogin = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const targetUrl = searchParams.get('targetUrl');
  const targetRole = searchParams.get('targetRole');
  const showOnboarding = searchParams.get('showOnboarding');

  const { mutate: autoLogin } = Users.useAutoLogin(token);
  const { setUser, logout } = useAuth();

  useEffect(() => {
    const handleAutoLogin = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await logout();
      autoLogin(
        { token },
        {
          onSuccess: (data) => {
            setUser(data);

            if (data.role !== userRoles.ADMIN) {
              ReactGA.event({
                category: 'login',
                action: 'login',
              });
              ReactGA.set({
                ...data,
              });
            }
            let redirectPath = '';
            let routeState = {};
            const isMultiOneRole =
              !data.role && data?.organisationsRoles?.length === 1;

            if (data?.role === userRoles.LEARNER) {
              redirectPath = R.LEARNER.DASHBOARD;
              if (data?.isSelfSignedUp || data?.onboarded) {
                redirectPath = R.LEARNER.DASHBOARD;
              } else {
                redirectPath = R.LEARNER.WELCOME;
              }
            } else if (isMultiOneRole || data?.organisationsRoles?.length > 1) {
              redirectPath = R.COMMON.SELECT_ORGANISATION;
            } else if (data.role === userRoles.CONTENT_EDITOR) {
              redirectPath = R.CMS.BASE;
            } else if (data.role === userRoles.ADMIN) {
              redirectPath = R.ADMIN.DASHBOARD;
            } else if (data.role === userRoles.ORGANISATION_ADMIN) {
              routeState = {
                showOnboarding: showOnboarding,
              };
              redirectPath = R.ORGANISATION_ADMIN.DASHBOARD;
            } else {
              redirectPath = R.COMMON.SPLASH_SCREEN;
              routeState = {
                role: data.role,
              };
            }

            if (targetUrl && redirectPath !== R.COMMON.SELECT_ORGANISATION) {
              return navigate(targetUrl);
            } else if (targetUrl) {
              routeState.targetUrl = targetUrl;
              routeState.targetRole = targetRole;
            }

            navigate(redirectPath, { state: routeState });
          },
        }
      );
    };

    if (token) {
      handleAutoLogin();
    }
  }, [
    autoLogin,
    logout,
    navigate,
    setUser,
    showOnboarding,
    targetRole,
    targetUrl,
    token,
  ]);

  return (
    <S.Wrapper>
      <Loading />
    </S.Wrapper>
  );
};

export default AutoLogin;
