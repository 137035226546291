import styled from '@emotion/styled';
import * as T from '../../Typography';
import setMargin from './../../../helpers/set-margin';

export const Container = styled.div`
  display: flex;
  align-items: center;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const commonStyle = ({ theme, disabled }) => {
  return `
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 16px;
  display: flex;
  align-items: center;
  pointer-events: ${disabled ? 'none' : 'auto'};

  :focus {
    box-shadow: 0px 0px 0px 2px rgba(5, 23, 48, 1) !important;
    outline-style: solid;
    outline-width: 3px;
    outline-color: ${theme.colors.primaryMain};
    outline-offset: 4px;
  }
  `;
};

export const StartCoachingWrapperLink = styled(T.Link)`
  ${setMargin};
  ${commonStyle};
`;

export const StartCoachingWrapperButton = styled.button`
  ${setMargin};
  ${commonStyle};
`;

export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.colors.neutralMain};
  background: ${({ theme }) => theme.colors.neutralLight};
  border: none;
  border-radius: 16px;
  display: inline-block;
  padding: ${({ theme }) => `${theme.spacings[3]}`};

  /* clicking style */
  :active {
    opacity: 0.9;
    transform: translateY(1px) scale(0.99);
  }
`;
