import styled from '@emotion/styled';
import { rowButtonStyleVariants } from '.';

export const RowButtonPrimaryStyles = ({ selected, theme, showBorderTop }) => `
  background: ${selected ? theme.colors.primaryMain : 'transparent'};
  border-top: ${showBorderTop ? '1px solid #000' : 'none'};
  border: 1px solid #000;
  justify-content: center;
`;

export const RowButtonSecondaryStyles = ({ selected, theme }) => `
  background: ${selected ? '#E2F8DD' : 'transparent'};
  border-left: none;
  border-right: none;
  border-top: 1px solid ${theme.colors.neutral40};
  border-bottom: 1px solid ${theme.colors.neutral40};
  justify-content: flex-start;
  align-items: center;
`;

export const RowButton = styled.button`
  display: flex;
  padding: 0;
  margin: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  overflow: hidden;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  width: 100%;
  min-height: 48px;
  padding-top: 4px;

  ${({ styleVariant, ...props }) =>
    styleVariant === rowButtonStyleVariants.PRIMARY
      ? RowButtonPrimaryStyles(props)
      : RowButtonSecondaryStyles(props)}
`;
