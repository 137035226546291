import { fields, createSchema, validate as _validate } from '..';

const {
  email,
  firstName,
  lastName,
  postcode,
  optionalPhoneNumber,
  requiredRadio,
} = fields;

const learnerSignUpStep1Schema = createSchema({
  email,
  firstName,
  lastName,
  phoneNumber: optionalPhoneNumber,
});

export const validate = (data) => {
  return _validate(learnerSignUpStep1Schema, data);
};

const validateEmailSchema = createSchema({
  email,
});

export const validateEmail = (data) => {
  return _validate(validateEmailSchema, data);
};

export const validate2 = (data) => {
  if (
    data.preferredSessionsType === 'EITHER' ||
    data.preferredSessionsType === 'IN_PERSON'
  ) {
    const learnerSignUpStep2Schema = createSchema({
      postcode,
      preferredSessionsType: requiredRadio,
    });

    return _validate(learnerSignUpStep2Schema, data);
  } else {
    const learnerSignUpStep2Schema = createSchema({
      preferredSessionsType: requiredRadio,
    });

    return _validate(learnerSignUpStep2Schema, data);
  }
};

export default { validate, validate2 };
