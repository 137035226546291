import { Table as AntdTable, ConfigProvider, Empty } from 'antd';
import * as T from 'components/Typography';
import * as S from './style';
import { WithText } from 'components/Button';

const Table = ({
  dataSource,
  pagination,
  onChange,
  loading,
  columns,
  emptyState,
  ...restProps
}) => (
  <ConfigProvider
    renderEmpty={() =>
      emptyState?.buttonText ? (
        <S.EmptyWrapper>
          <T.P>{emptyState?.title}</T.P>
          <WithText
            mr={5}
            icon="userWithArrow"
            text={emptyState?.buttonText}
            weight="bold"
            to={emptyState?.buttonLink}
            width="32px"
            height="32px"
            mt={4}
            disabled={emptyState?.disabled}
          />
        </S.EmptyWrapper>
      ) : (
        <Empty />
      )
    }
  >
    <AntdTable
      columns={columns}
      dataSource={dataSource}
      pagination={pagination}
      onChange={onChange}
      loading={loading}
      {...restProps}
    />
  </ConfigProvider>
);

export default Table;
