import theme from '../../../theme';

function Message({ width, height, color, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '18'}
      height={height || '18'}
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill={color || theme.colors.neutralMain}
        d="M17.148 16.576a.48.48 0 01-.572.572l-3.893-.865a8.16 8.16 0 113.6-3.6l.865 3.893z"
      ></path>
    </svg>
  );
}

export default Message;
