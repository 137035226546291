import theme from '../../../theme';

const Circle = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.75 12C3.75 7.16728 7.66728 3.25 12.5 3.25C17.3327 3.25 21.25 7.16728 21.25 12C21.25 16.8327 17.3327 20.75 12.5 20.75C7.66728 20.75 3.75 16.8327 3.75 12Z"
      fill={'transparent'}
      stroke={color || theme.colors.neutralMain}
      stroke-width="2"
    />
  </svg>
);
export default Circle;
