import Stack from '@mui/material/Stack';
import Table from '../Table';
import * as T from '../Typography';
import * as S from './style';
import CopyInviteLink from '../CopyInviteLink';

const InvitesTable = ({ dataSource, hideErrorColumn, hideInviteLink }) => {
  const columns = [
    {
      title: <T.P weight="bold">Status</T.P>,
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (status, record) => (
        <Stack spacing="16px">
          <S.Tag
            bgColor={status === 'Error' ? 'tertiaryMain' : 'primaryMain'}
            key={status}
          >
            {status}
          </S.Tag>
          {record.isMultiOrganisationsFacilitator && (
            <S.Tag bgColor={'warningLight'} fontColor="neutral90" key={status}>
              Multi-organisations facilitator
            </S.Tag>
          )}
        </Stack>
      ),
    },
    {
      title: <T.P weight="bold">Email</T.P>,
      dataIndex: 'email',
      key: 'email',
      render: (email) => <T.P>{email}</T.P>,
      width: '10%',
    },
    {
      title: <T.P weight="bold">Invite link</T.P>,
      dataIndex: 'inviteLink',
      key: 'inviteLink',
      width: '10%',

      render: (inviteLink, record) => {
        if (record.isMultiOrganisationsFacilitator) {
          return <T.P>-</T.P>;
        } else {
          return inviteLink === '-' ? (
            <T.P>{inviteLink}</T.P>
          ) : (
            <T.Link color="neutralMain" underline>
              <CopyInviteLink textToCopy={inviteLink} />
            </T.Link>
          );
        }
      },
    },
    {
      title: <T.P weight="bold">Reason for error</T.P>,
      dataIndex: 'error',
      key: 'error',
      render: (error) => <T.P>{error}</T.P>,
      width: '40%',
    },
  ];

  return (
    <Table
      columns={columns
        .filter((column) => !hideErrorColumn || column.key !== 'error')
        .filter((column) => !hideInviteLink || column.key !== 'inviteLink')}
      dataSource={dataSource}
      pagination={{
        showSizeChanger: false,
        pageSize: 5,
      }}
    />
  );
};

export default InvitesTable;
