import styled from '@emotion/styled';
import { Button as AntdButton } from 'antd';
import { setMargin } from './../../../../helpers';
export const Button = styled(AntdButton)`
  ${setMargin}
  max-width: 150px;
  display: block;
  display: flex;
  align-items: center;
`;

export const AnswerInputWrapper = styled.div`
  ${setMargin}
  display: flex;
  flex-direction: ${({ isFraction }) => (isFraction ? 'column' : 'row')};
  justify-content: ${({ isFraction }) =>
    isFraction ? 'center' : 'flex-start'};
  align-items: ${({ isFraction }) => (isFraction ? 'center' : 'flex-start')};
  gap: 24px;

  ${({ isFraction, isError, isPrefix, isSuffix, isGrid }) => {
    if (isFraction) {
      return `
        & > * {
          max-width: ${isError ? '110px' : '64px'};
          gap: 0;
        }
      `;
    }
    if (isPrefix) {
      return `
        & > *:first-child {
          max-width: 110px;
        }
      `;
    }

    if (isGrid) {
      return `
        flex-wrap: wrap;
        & > * {
          max-width: 90px;
        }
      `;
    }

    if (isSuffix) {
      return `
      & > *:last-child {
        max-width: 140px;
      }
    `;
    }
  }}
`;

export const Divide = styled.div`
  ${setMargin}
  width: 100%;
  border: 2px solid #000;
`;

export const CorrectOptionWrapper = styled.div`
  ${setMargin}
`;

export const InputWithDeleteWrapper = styled.div`
  display: flex;
  position: relative;
  min-width: 100px;

  & > button {
    position: absolute;
    top: 0;
    right: -6px;
  }
`;

export const IsCorrectWrapperWithDelete = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const PyramidInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: ${({ theme }) => theme.spacings[5]};
`;

export const PyramidRowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  flex-wrap: wrap;
  & > * {
    max-width: ${({ rowSize }) => (rowSize > 3 ? '64px' : '80px')};
  }
  & input {
    width: ${({ rowSize }) => (rowSize > 3 ? '64px' : '80px')};
    height: ${({ rowSize }) => (rowSize > 3 ? '64px' : '80px')};
  }
`;

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
