import Modal from '../../../components/Modal';
import * as T from '../../../components/Typography';
import { Row, Col } from '../../../components/Grid';
import { BasicButton } from '../../../components/Button';
import { useSiteSettings } from 'context/site-settings';
import { courseCodeTypes } from 'constants';
import { useUpdateCoachApprovalStatus } from 'api-calls/coaches.queries';

const ApproveCoachModal = ({
  isModalVisible,
  setIsModalVisible,
  selectedCoachId,
}) => {
  const { courseName, courseCode } = useSiteSettings();
  const {
    mutateAsync: updateCoachApprovalStatus,
    isLoading: updateCoachApprovalStatusLoading,
  } = useUpdateCoachApprovalStatus();

  const handleApproveCoach = async ({ isTpdCourse, isCmidCourse }) => {
    await updateCoachApprovalStatus({
      note: '',
      coachId: selectedCoachId,
      status: 'APPROVED',
      isTpdCourse,
      isCmidCourse,
    });
    setIsModalVisible(false);
  };

  return (
    <Modal visible={isModalVisible} setIsModalVisible={setIsModalVisible}>
      <Row>
        <Col w={[4, 12, 12]} jc="left">
          <T.P size="med" weight="bold" color="white">
            Approve to be coaches
          </T.P>
        </Col>
        <Col w={[4, 12, 12]} jc="left" mt={4}>
          <T.P color="white">
            Would you approve to be coaches on both courses or just for this
            course?
          </T.P>
        </Col>
        <>
          <Col w={[4, 12, 12]} mt={4}>
            <BasicButton
              onClick={() =>
                handleApproveCoach({
                  isTpdCourse: true,
                  isCmidCourse: true,
                })
              }
              variant="secondary"
              disabled={updateCoachApprovalStatusLoading}
            >
              Approve on both courses
            </BasicButton>
          </Col>
          <Col w={[4, 12, 12]} mt={4}>
            <BasicButton
              onClick={() =>
                handleApproveCoach({
                  isTpdCourse: courseCode === courseCodeTypes.TPD,
                  isCmidCourse: courseCode === courseCodeTypes.CMID,
                })
              }
              variant="tertiary"
              disabled={updateCoachApprovalStatusLoading}
            >
              Just for {courseName}
            </BasicButton>
          </Col>
        </>
      </Row>
    </Modal>
  );
};

export default ApproveCoachModal;
