import { useState, useEffect } from 'react';

const useSteps = (steps = [], currentStepId) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const currentStep = steps[currentStepIndex];
  const lastStepIndex = steps.length - 1;
  const lastStep = steps[lastStepIndex];

  const isLastStep = currentStepIndex === lastStepIndex;
  const nextStep = isLastStep ? null : steps[currentStepIndex + 1];

  useEffect(() => {
    if (currentStepId) {
      const index = steps.findIndex(
        (step) => step.id === Number(currentStepId)
      );
      setCurrentStepIndex(index);
    }
  }, [currentStepId, steps]);

  return {
    currentStepIndex,
    currentStep,
    lastStepIndex,
    isLastStep,
    lastStep,
    stepsArray: steps,
    nextStep,
  };
};

export default useSteps;
