import styled from '@emotion/styled';

export const RowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const RowWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  & input {
    width: ${({ inputsPerRow }) =>
      inputsPerRow > 4 ? '54px' : inputsPerRow > 3 ? '64px' : '80px'};
    height: ${({ inputsPerRow }) =>
      inputsPerRow > 4 ? '54px' : inputsPerRow > 3 ? '64px' : '80px'};
    padding-right: ${({ theme }) => theme.spacings[3]};
  }
`;

export const RowInputsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  flex-wrap: wrap;
  & > * {
    max-width: ${({ inputsPerRow }) =>
      inputsPerRow > 4 ? '54px' : inputsPerRow > 3 ? '64px' : '80px'};
  }
`;
