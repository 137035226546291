import { useState, useRef, useEffect } from 'react';
import { audio } from './../../constants';
import Modal from '../Modal';
import { BasicButton, PlayButton } from '../Button';
import { getMediaUrl } from '../../helpers';
import Icon from '../Icon';
import { useGeneralState } from '../../context/general-state';
import * as S from './style';
import * as T from '../Typography';

const successMessages = [
  'Nice!',
  'Great!',
  'Good work',
  'Cool, keep it going',
  `Great, let's continue`,
  'Correct!',
];
const successAudio = [
  audio.nice,
  audio.great,
  audio.goodWork,
  audio.coolKeepItGoing,
  audio.greatLetSContinue,
  audio.correct,
];

const successIcons = ['like', 'check'];

const selectSuccessMessage = (preferredVoice) => {
  const randomIndex = Math.floor(Math.random() * 4);
  const randomIconIndex = Math.floor(Math.random() * 2);
  return {
    text: successMessages[randomIndex],
    audioUrl: getMediaUrl(successAudio[randomIndex], true, preferredVoice),
    icon: successIcons[randomIconIndex],
  };
};

const ExerciseSuccessModal = ({
  currentStepIndex,
  currentStep,
  stepsArray,
}) => {
  const successCounter = useRef(0);
  // track the largest step's index completed, so that we don't show the modal if the user goes back to a previous step and completes it again
  const largestStepIndexCompleted = useRef(0);

  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const { state: generalState } = useGeneralState();

  const increment = () => {
    successCounter.current = successCounter.current + 1;
  };

  useEffect(() => {
    const checkToShowModal = () => {
      if (currentStepIndex <= largestStepIndexCompleted.current) {
        return;
      }
      if (
        Number(currentStep?.id) &&
        stepsArray?.lastStep?.id === Number(currentStep?.id)
      ) {
        return;
      }

      if (successCounter.current >= 5) {
        successCounter.current = 0;
        setSuccessModalVisible(
          selectSuccessMessage(generalState?.preferredVoice)
        );
      } else if (successCounter.current < 3) {
        increment();
      } else {
        const res = Math.random();
        if (res < 1 / 3) {
          increment();
        } else {
          successCounter.current = 0;
          setSuccessModalVisible(
            selectSuccessMessage(generalState?.preferredVoice)
          );
          return true;
        }
      }
    };

    if (generalState?.isLearning) {
      checkToShowModal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep?.id]);

  useEffect(() => {
    if (currentStepIndex > largestStepIndexCompleted.current) {
      largestStepIndexCompleted.current = currentStepIndex;
    }
  }, [currentStepIndex]);

  return (
    <Modal bgColor="white" visible={successModalVisible}>
      <S.Wraper>
        <S.IconWrapper>
          <Icon
            icon={successModalVisible && successModalVisible.icon}
            color="quinaryMain"
            width="80"
            height="80"
          />
        </S.IconWrapper>
        <PlayButton
          color="neutralMain"
          audioUrl={successModalVisible && successModalVisible.audioUrl}
          mb="4"
          mt="4"
          backgroundColor={'neutralSurface'}
          fallbackText={successModalVisible && successModalVisible.text}
        >
          {successModalVisible && successModalVisible.text}
        </PlayButton>

        <S.PlayButtonWrapper mt="4">
          <PlayButton
            width="32px"
            height="32px"
            audioUrl={getMediaUrl(
              audio.youAreMakingProgressKeepGoing,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            backgroundColor="transparent"
            withoutButtonStyle
            fallbackText="You are making progress, Keep going!"
          />
          <T.P color="neutral80" ml="2">
            You are making progress, Keep going!
          </T.P>
        </S.PlayButtonWrapper>

        <BasicButton
          handleClick={() => {
            setSuccessModalVisible(false);
          }}
          icon="next"
          variant="primary"
          iconProps={{ width: '20', height: '11.5' }}
          focusOutlineColor="neutralMain"
          mt="4"
        >
          Next
        </BasicButton>
      </S.Wraper>
    </Modal>
  );
};

export default ExerciseSuccessModal;
