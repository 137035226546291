const DividerLine = ({ width, height, color, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="130"
      height="2"
      viewBox="0 0 130 2"
      fill={'red'}
    >
      <path
        d="M1 1.49609H129"
        stroke="url(#paint0_linear_9337_159305)"
        stroke-linecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9337_159305"
          x1="129"
          y1="1.496"
          x2="1"
          y2="1.49609"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0.44" />
          <stop offset="0.489583" stop-color="#EBEBEB" />
          <stop offset="1" stop-color="white" stop-opacity="0.44" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default DividerLine;
