import { useEffect, useRef, useCallback, useState } from 'react';
import { ChatMessages } from 'api-calls';
import { Typography as T, Icon } from 'components';
import { CircularProgress, debounce } from '@mui/material';
import { useVirtualizer } from '@tanstack/react-virtual';
import * as S from './style';
import TextMsg from './TextMsg';
import { types as Ty } from '../../constants';
import AudioMsg from './AudioMsg';
import { useAuth } from 'context/auth';
import * as Sentry from '@sentry/react';
import { queryClient } from 'api-calls/queryClient';
import ReportModal from './ReportModal';
import CoachFirstMsg from './CoachFirstMsg';

const ViewMsgs = ({ receiverId, userType, PreMsg }) => {
  const {
    data,
    error,
    isLoading,
    hasPreviousPage,
    isFetchingPreviousPage,
    fetchPreviousPage,
    isFetchingNextPage,
    fetchNextPage,
  } = ChatMessages.useGetMyChatMsgs({ receiverId }, { enabled: !!receiverId });
  const { mutate: setAsSeen } = ChatMessages.useSetMsgSeen();
  const { user } = useAuth();
  const [msgIdToReport, setMsgIdToReport] = useState('');
  const parentRef = useRef(null);
  const initialScrollDone = useRef(false);
  const previousFirstItem = useRef({});
  const allMsgs = data ? data.pages.flatMap((page) => page.myMsgs) : [];
  const count = allMsgs.length;
  const overscan = 8;
  const showPreMsg = allMsgs.length < 3 && !!PreMsg;
  const showPreMsgCoach =
    userType === Ty.userRoles.COACH &&
    count === 1 &&
    allMsgs[0].sender.id === user.id;

  const virtualizer = useVirtualizer({
    count: showPreMsg || showPreMsgCoach ? count + 1 : count,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 50,
    overscan,
  });

  const scrollToBottom = useCallback(() => {
    if (virtualizer && count > 0) {
      virtualizer.scrollToIndex(count - 1, { align: 'end' });
    }
  }, [virtualizer, count]);

  useEffect(() => {
    if (!initialScrollDone.current && virtualizer && count > 0) {
      scrollToBottom();
      requestAnimationFrame(() => {
        initialScrollDone.current = true;
      });
    }
  }, [scrollToBottom, virtualizer, count]);

  const handleLoadMore = () => {
    const previousFirstItemIndex = virtualizer.range.startIndex;
    previousFirstItem.current = allMsgs[previousFirstItemIndex];

    fetchPreviousPage();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(
    debounce(() => {
      if (initialScrollDone.current) {
        const currentScrolledElementIndex = virtualizer.range.startIndex;
        if (currentScrolledElementIndex === 0 && !isFetchingPreviousPage) {
          handleLoadMore();
        }
      }
    }, 100),
    [virtualizer, hasPreviousPage, isFetchingPreviousPage]
  );
  useEffect(() => {
    const scrollElement = parentRef.current;
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentRef.current, handleScroll]);

  useEffect(() => {
    if (previousFirstItem.current?.id) {
      const currentFirstItemIndex = virtualizer.range.startIndex;
      const currentFirstItem = allMsgs[currentFirstItemIndex];

      if (currentFirstItem.id !== previousFirstItem.current.id) {
        const newIndexOfPreviousFirstItem = allMsgs.findIndex(
          (msg) => msg.id === previousFirstItem.current.id
        );

        if (newIndexOfPreviousFirstItem !== -1 && !isFetchingPreviousPage) {
          virtualizer.scrollToIndex(newIndexOfPreviousFirstItem, {
            align: 'start',
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousFirstItem.current?.id, count]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isFetchingNextPage) {
        fetchNextPage();
      }
    }, 3000);

    return () => clearInterval(intervalId);
  }, [fetchNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (allMsgs.length > 0) {
      const lastPage = [...data.pages[data.pages.length - 1].myMsgs];
      const lastUnseenMsg = lastPage
        .reverse()
        .find((msg) => !msg.seenAt && msg.receiver.id === user.id);

      if (lastUnseenMsg && lastUnseenMsg.receiver.id === user.id) {
        setAsSeen(
          { msgId: lastUnseenMsg.id, seenAt: new Date() },
          {
            onSuccess: () => {
              queryClient.setQueryData(['GET_USER_HAS_NEW_MSGS'], {
                hasNewMsgs: false,
              });
            },
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(['GET_MY_CHAT_MSGS', { receiverId }]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!allMsgs && isLoading) {
    return (
      <S.MsgsWrapper>
        <S.ErrorWrapper>
          <CircularProgress color="inherit" />
        </S.ErrorWrapper>
      </S.MsgsWrapper>
    );
  }

  if (error) {
    Sentry.captureException(error, {
      extra: {
        receiverId,
        userId: user.id,
        type: 'getMyChatMsgs',
      },
    });

    return (
      <S.MsgsWrapper>
        <S.ErrorWrapper>
          <T.P color="error" m="0" mt="2" mx={2}>
            Something went wrong please try again
          </T.P>
        </S.ErrorWrapper>
      </S.MsgsWrapper>
    );
  }

  const items = virtualizer.getVirtualItems();

  if (!isLoading && userType === Ty.userRoles.COACH && count === 0) {
    return (
      <S.MsgsWrapper>
        <CoachFirstMsg learnerUserId={receiverId} showTip />
      </S.MsgsWrapper>
    );
  }

  return (
    <S.MsgsWrapper ref={parentRef}>
      {isFetchingPreviousPage && (
        <div style={{ textAlign: 'center', padding: '10px' }}>
          <T.P>Loading more messages...</T.P>
        </div>
      )}
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: showPreMsgCoach ? '-32px' : 0,
            left: 0,
            width: '100%',
            transform: `translateY(${items[0]?.start ?? 0}px)`,
          }}
        >
          {items.map((virtualRow) => {
            if (showPreMsgCoach && virtualRow.index === 0) {
              return (
                <div
                  key={virtualRow.key}
                  data-index={virtualRow.index}
                  ref={virtualizer.measureElement}
                >
                  <CoachFirstMsg learnerUserId={receiverId} />
                </div>
              );
            }
            if (showPreMsg && virtualRow.index === 0) {
              return (
                <div
                  key={virtualRow.key}
                  data-index={virtualRow.index}
                  ref={virtualizer.measureElement}
                >
                  {PreMsg}
                </div>
              );
            }
            const msg =
              allMsgs[
                showPreMsgCoach || showPreMsg
                  ? virtualRow.index - 1
                  : virtualRow.index
              ];
            return (
              <div
                key={virtualRow.key}
                data-index={virtualRow.index}
                ref={virtualizer.measureElement}
              >
                {msg.msgType === Ty.chatMessagesTypes.AUDIO ? (
                  <AudioMsg
                    id={msg.id}
                    sender={msg.sender}
                    userType={userType}
                    audioUrl={msg.audioUrl}
                    // receiver={msg.receiver}
                    setMsgIdToReport={setMsgIdToReport}
                    reported={msg.reported}
                  />
                ) : (
                  <TextMsg
                    id={msg.id}
                    sender={msg.sender}
                    content={msg.content}
                    userType={userType}
                    sentAt={msg.sentAt}
                    // receiver={msg.receiver}
                    setMsgIdToReport={setMsgIdToReport}
                    reported={msg.reported}
                  />
                )}
              </div>
            );
          })}
        </div>
        {showPreMsgCoach && (
          <S.YouWillBeNotifiedWrapper>
            <Icon icon="bell" color="warningMain" width="24px" height="24px" />
            <T.P color="neutral70">
              You will be notified by email and on the platform when they reply.
            </T.P>
          </S.YouWillBeNotifiedWrapper>
        )}
      </div>
      <ReportModal
        msgIdToReport={msgIdToReport}
        setMsgIdToReport={setMsgIdToReport}
        receiverId={receiverId}
      />
    </S.MsgsWrapper>
  );
};

export default ViewMsgs;
