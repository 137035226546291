import React from 'react';
import * as S from './style';

const Divider = ({
  direction = 'horizontal',
  my = '5',
  showText,
  ...props
}) => {
  return (
    <S.Divider
      direction={direction}
      my={direction === 'vertical' ? 0 : my}
      showText={showText}
      {...props}
    />
  );
};

export default Divider;
