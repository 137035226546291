import { useContext } from 'react';
import * as S from './style';
import Context from './context';

const RateQuestionContent = ({ answers }) => {
  const { mode } = useContext(Context);
  const modalView = mode === 'onModal';

  return (
    <S.QuestionContentWrapper modalView={modalView}>
      {answers?.length ? (
        answers.map((answer, i) => (
          <S.Answer color="neutral90" key={i}>
            {i + 1}. {answer}{' '}
          </S.Answer>
        ))
      ) : (
        <S.NoData ta="center">No data</S.NoData>
      )}
    </S.QuestionContentWrapper>
  );
};

export default RateQuestionContent;
