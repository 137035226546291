import { useReducer, useState, useCallback } from 'react';
import { Row, Col } from 'components/Grid';
import { useNavigate } from 'react-router-dom';
import { BasicInput } from '../../../components/Inputs';
import { BasicButton } from '../../../components/Button';
import * as T from '../../../components/Typography';
import { useAuth } from 'context/auth';
import { Loading, TextWithIcon } from 'components';
import SuccessModal from './SuccessModal';
import DeleteConfirmModal from './DeleteConfirmModal';
import validate from '../../../validation/schemas/update-account-details.js';
import { useApiCallQueries } from './hooks';
import { navRoutes, userRoles } from '../../../constants';
import * as S from './style';
import { BackButton } from 'components/BackButton';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }
  return { ...state, ...value };
}

const initialState = {
  form: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    organisationId: '',
    organisationName: '',
  },
  httpError: '',
  validationErrs: {},
};

const AccountDetails = () => {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [isDeleteConfirmModalVisible, setIsDeleteConfirmModalVisible] =
    useState(false);

  const [state, setState] = useReducer(reducer, initialState);

  const {
    logout,
    user: { id: userId, role },
  } = useAuth();

  const isOrganisationAdmin = role === 'ORGANISATION_ADMIN';

  const {
    deleteUserPersonalInfo,
    updateUserPersonalInfo,
    isLoadingUserPersonalInfo,
    isUpdatingUserPersonalInfoLoading,
    isDeletingUserPersonalInfoLoading,
  } = useApiCallQueries({
    setState,
    state,
  });

  const handleLogout = async () => {
    try {
      await logout();
    } catch (e) {
      if (e) {
        setState({
          httpError:
            e.message ||
            'Sorry there was an error logging you out. Please try again later.',
        });
      }
    }
  };

  const setFormData = (data) => {
    setState((prevState) => ({ form: { ...prevState.form, ...data } }));
  };

  const handleDelete = async () => {
    try {
      await deleteUserPersonalInfo({ id: userId });
      setSuccessMessage('Your account has been deleted');
      setIsDeleteConfirmModalVisible(false);
      await handleLogout();
    } catch (e) {
      if (e) {
        setState({
          httpError:
            e?.response?.data?.message ||
            'Sorry there was an error while deleting this user. Please try again later.',
        });
      }
    }
  };

  const validateForm = useCallback(() => {
    try {
      validate(
        {
          ...state.form,
        },
        isOrganisationAdmin
      );
      setState({ validationErrs: { hasError: false }, httpError: false });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({
          validationErrs: { ...error.inner, hasError: true },
        });
      }
      return false;
    }
  }, [isOrganisationAdmin, state.form]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();

    if (isValid) {
      updateUserPersonalInfo(
        {
          id: userId,
          ...state.form,
        },
        {
          onSuccess: () => {
            setSuccessMessage('Your account has been updated');
            setIsSuccessModalVisible(true);
          },
          onError: (e) => {
            setState({
              httpError:
                e?.response?.data?.message ||
                'Sorry there was an error updating Hello this user. Please try again later.',
            });
          },
        }
      );
    }
  };

  const {
    validationErrs,
    httpError,
    form: { firstName, lastName, email, organisationName },
  } = state;

  if (isDeletingUserPersonalInfoLoading || isLoadingUserPersonalInfo)
    return <Loading />;

  return (
    <S.AccountDetailsContainer>
      <BackButton mb={6} />
      <Row mb="6">
        <Col w={[4, 12, 8]}>
          <T.H1 color="neutralMain" weight="bold">
            Account settings
          </T.H1>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 6]} mb="5">
          <BasicInput
            value={firstName}
            label="First name"
            name="firstName"
            handleChange={(firstName) => setFormData({ firstName })}
            error={validationErrs?.firstName}
          />
        </Col>
        <Col w={[4, 6, 6]} mb="5">
          <BasicInput
            value={lastName}
            label="Last name"
            name="lastName"
            handleChange={(lastName) => setFormData({ lastName })}
            error={validationErrs?.lastName}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 6]} mb="5">
          <BasicInput
            value={email}
            label="Email address"
            name="email"
            handleChange={(email) => setFormData({ email })}
            error={validationErrs?.email}
          />
        </Col>

        {isOrganisationAdmin && (
          <Col w={[4, 6, 6]} mb="5">
            <BasicInput
              value={organisationName}
              label="Account name"
              name="organisationName"
              handleChange={(organisationName) =>
                setFormData({ organisationName })
              }
              error={validationErrs?.organisationName}
            />
          </Col>
        )}
      </Row>

      <Row>
        <Col w={[4, 11, 10]} mt="2">
          {httpError && (
            <T.P mb="2" color="error">
              {httpError ? httpError : 'Something went wrong'}
            </T.P>
          )}

          {validationErrs?.hasError && (
            <Row>
              <Col w={[4, 10, 12]}>
                <T.P color="error" mb={3}>
                  There is an error in your form. Please correct before
                  submitting.
                </T.P>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Row mt="2" mb="6">
        <Col w={[4, 6, 6]}>
          <BasicButton
            variant="primary"
            onClick={handleSubmit}
            loading={isUpdatingUserPersonalInfoLoading}
          >
            <T.P color="white" weight="semi">
              Save changes
            </T.P>
          </BasicButton>
        </Col>
      </Row>
      {role !== userRoles.ADMIN && (
        <Row>
          <Col w={[4, 6, 6]}>
            <TextWithIcon
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              icon=""
              isButton={true}
              weight="bold"
              text="Delete account"
              handleClick={() => {
                setIsDeleteConfirmModalVisible(true);
              }}
            />
          </Col>
        </Row>
      )}

      <SuccessModal
        successMessage={successMessage}
        isSuccessModalVisible={isSuccessModalVisible}
        setIsSuccessModalVisible={setIsSuccessModalVisible}
        onSuccessClick={() => {
          setIsSuccessModalVisible(false);
          navigate(navRoutes.GENERAL.HOME);
        }}
      />

      <DeleteConfirmModal
        isDeleteConfirmModalVisible={isDeleteConfirmModalVisible}
        setIsDeleteConfirmModalVisible={setIsDeleteConfirmModalVisible}
        onConfirm={() => {
          setIsDeleteConfirmModalVisible(false);
          handleDelete();
        }}
        onCancel={() => {
          setIsDeleteConfirmModalVisible(false);
        }}
      />
    </S.AccountDetailsContainer>
  );
};

export default AccountDetails;
