import React from 'react';

import * as S from './style';
import IconButtonWithLoader from './WithLoader';

const IconButton = ({ handleClick, children, isWithLoading, ...props }) => {
  if (isWithLoading) {
    return (
      <IconButtonWithLoader onClick={handleClick} {...props}>
        {children}
      </IconButtonWithLoader>
    );
  }
  return (
    <S.StyledIconButton type="button" onClick={handleClick} {...props}>
      {children}
    </S.StyledIconButton>
  );
};

export default IconButton;
