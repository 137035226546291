const UserWithPlus = ({ width, height, color, ...props }) => (
  <svg
    width={width || '61'}
    height={height || '60'}
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M47.8542 18.8652V26.3027M47.8542 26.3027V33.7402M47.8542 26.3027H55.2917M47.8542 26.3027H40.4167M34.8386 16.0762C34.8386 17.175 34.6221 18.263 34.2016 19.2782C33.7812 20.2933 33.1648 21.2157 32.3879 21.9927C31.6109 22.7696 30.6885 23.386 29.6734 23.8064C28.6582 24.2269 27.5702 24.4434 26.4714 24.4434C25.3726 24.4434 24.2845 24.2269 23.2694 23.8064C22.2542 23.386 21.3318 22.7696 20.5549 21.9927C19.7779 21.2157 19.1616 20.2933 18.7411 19.2782C18.3206 18.263 18.1042 17.175 18.1042 16.0762C18.1042 13.8571 18.9857 11.7288 20.5549 10.1597C22.124 8.59052 24.2523 7.70898 26.4714 7.70898C28.6905 7.70898 30.8187 8.59052 32.3879 10.1597C33.957 11.7288 34.8386 13.8571 34.8386 16.0762ZM10.6667 47.9583V47.6855C10.6667 43.4939 12.3318 39.4739 15.2958 36.5099C18.2597 33.546 22.2797 31.8809 26.4714 31.8809C30.663 31.8809 34.683 33.546 37.647 36.5099C40.6109 39.4739 42.2761 43.4939 42.2761 47.6855V47.9558C37.5049 50.8294 32.0386 52.3434 26.4689 52.334C20.69 52.334 15.2829 50.7349 10.6667 47.9558V47.9583Z"
      stroke={color || '#F1EDF2'}
      strokeWidth="3.71875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UserWithPlus;
