import * as S from './style';
import * as T from '../Typography';
import { useState } from 'react';
import { BasicButton } from 'components/Button';
import { Col } from 'components/Grid';

const OnboardingModal = ({
  isModalOpen,
  onClose,
  handleClick,
  data = [],
  ...props
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const {
    image,
    title,
    description,
    isLastTab = false,
    isFirstTab = false,
    width,
  } = data[currentStep] || {};

  const modalOnClose = (event, reason) => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
    onClose();
  };

  return (
    <S.Modal open={isModalOpen} onClose={modalOnClose} role="dialog">
      <S.Wrapper {...props} width={width}>
        <S.Header isFirstTab={isFirstTab}>
          {isFirstTab ? null : (
            <>
              {data?.map((_, index) => (
                <S.LineProgress
                  mr="1"
                  key={index}
                  activeColor={index <= currentStep}
                />
              ))}
            </>
          )}
        </S.Header>

        {image && (
          <S.CoverWrapper isFirstTab={isFirstTab}>
            <S.TabImage src={image} />
          </S.CoverWrapper>
        )}

        <S.Content isFirstTab={isFirstTab}>
          <Col jc={isFirstTab ? 'center' : ''} mt="5" w={[4, 12, 12]}>
            <T.H1 ta={isFirstTab ? 'center' : ''} color="neutralMain">
              {title}
            </T.H1>
            <T.P ta={isFirstTab ? 'center' : ''} mt="3" color="neutral80">
              {description}
            </T.P>
          </Col>
        </S.Content>

        <S.Footer isFirstTab={isFirstTab}>
          {currentStep > 0 && (
            <BasicButton
              className="back"
              handleClick={() => setCurrentStep((prev) => prev - 1)}
              variant="tertiary"
              icon="back"
              maxWidth="200px"
            >
              <T.P weight="semi">Back</T.P>
            </BasicButton>
          )}
          <BasicButton
            className="next"
            handleClick={() => {
              if (currentStep === data.length - 1) {
                onClose();
                handleClick();
              } else {
                setCurrentStep((prev) => prev + 1);
              }
            }}
            variant="primary"
            icon="next"
            maxWidth="200px"
          >
            <T.P color="white" weight="semi">
              {isLastTab ? 'Go dashboard' : 'Next'}
            </T.P>
          </BasicButton>
        </S.Footer>
      </S.Wrapper>
    </S.Modal>
  );
};

export default OnboardingModal;
