import { useRef } from 'react';
import { Icon, Typography as T } from '../../../../../components';
import { BasicInput, Checkbox } from '../../../../../components/Inputs';
import MDEditor from '../../../../../components/MDEditor';
import { MediaKeyInput } from '../../../../components/MediaKeyInput';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import * as S from '../style';
import { mediaTypes } from 'constants';

export { validationSchema } from './validation';

const initialData = {
  textAudio: '',
  textAudioKey: '',
  questionTitle: '',
  rowsTitle: '',
  rows: [{ id: 1, text: '', isCorrect: false }],
};

const ComprehensionSelect = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateDataFields } = useStepForm(initialRef.current);
  const { data, validationErrs } = state;

  const addRow = () => {
    const maxId = Math.max(...data.rows?.map((e) => e.id), 0);
    updateDataFields({
      rows: [...data.rows, { id: maxId + 1, text: '', isCorrect: false }],
    });
  };

  const onRowChange = ({ key, value, id }) => {
    updateDataFields({
      rows: data.rows?.map((e) => {
        if (e.id !== id) return e;
        return {
          ...e,
          [key]: value,
        };
      }),
    });
  };

  const removeRow = (id) => {
    updateDataFields({
      rows: data.rows.filter((e) => e.id !== id),
    });
  };

  return (
    <div>
      <MDEditor
        label="Explainer Text"
        value={data.textAudio}
        onChange={(textAudio) => updateDataFields({ textAudio })}
        mode={'edit'}
        height={80}
        m={{ mt: 8 }}
        error={validationErrs?.textAudio}
      />
      <MediaKeyInput
        type={mediaTypes.TEXT_AUDIO}
        label={`Explainer Text file Key`}
        value={data.textAudioKey}
        handleChange={(textAudioKey) => {
          updateDataFields({ textAudioKey });
        }}
        m={{ mt: 5 }}
        error={validationErrs.textAudioKey}
      />

      <MDEditor
        label="Question Title"
        value={data.questionTitle}
        onChange={(questionTitle) => updateDataFields({ questionTitle })}
        mode={'edit'}
        height={80}
        m={{ mt: 8 }}
        error={validationErrs?.questionTitle}
      />

      <MDEditor
        label="Rows Title"
        value={data.rowsTitle}
        onChange={(rowsTitle) => updateDataFields({ rowsTitle })}
        mode={'edit'}
        height={80}
        m={{ mt: 8 }}
        error={validationErrs?.rowsTitle}
      />

      <T.P weight="bold" mt="8" ml="2">
        {'Rows:'}
      </T.P>
      {data.rows?.map((row, i) => {
        return (
          <>
            <BasicInput
              m={{ mt: i === 0 ? '2' : '5' }}
              value={row.text}
              handleChange={(value) => {
                onRowChange({
                  key: 'text',
                  value,
                  id: row.id,
                });
              }}
              error={validationErrs?.rows?.[i]?.text}
            />

            <S.IsCorrectWrapperWithDelete>
              <Checkbox
                m={{ ml: '2' }}
                plain
                noPadding
                label={
                  <T.P size="small" m="0" ml="1">
                    correct option?
                  </T.P>
                }
                checked={row.isCorrect}
                handleChange={(checked) => {
                  onRowChange({
                    key: 'isCorrect',
                    value: checked,
                    id: row.id,
                  });
                }}
                disabled={!row.text}
              />

              {i === 0 ? null : (
                <S.Button
                  type="link"
                  danger
                  ghost
                  mt={1}
                  onClick={() => removeRow(row.id)}
                  ml="auto"
                >
                  <Icon icon="cross" />
                </S.Button>
              )}
            </S.IsCorrectWrapperWithDelete>
          </>
        );
      })}
      {typeof validationErrs?.rows === 'string' && (
        <T.P mb="2" color="error">
          {validationErrs.rows}
        </T.P>
      )}
      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addRow}
        disabled={data.rows?.find((e) => !e.text)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add {'Row'}
      </S.Button>
    </div>
  );
};

export default ComprehensionSelect;
