import theme from '../../../theme';

const Square = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '25'}
    height={height || '25'}
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <path
      d="M7.69984 3.83984H17.2998C19.1555 3.83984 20.6598 5.34417 20.6598 7.19984V16.7998C20.6598 18.6555 19.1555 20.1598 17.2998 20.1598H7.69984C5.84417 20.1598 4.33984 18.6555 4.33984 16.7998V7.19984C4.33984 5.34417 5.84417 3.83984 7.69984 3.83984Z"
      fill={'transparent'}
    />
    <path
      d="M7.69984 4.58984H17.2998V3.08984H7.69984V4.58984ZM17.2998 4.58984C18.7413 4.58984 19.9098 5.75838 19.9098 7.19984H21.4098C21.4098 4.92995 19.5697 3.08984 17.2998 3.08984V4.58984ZM19.9098 7.19984V16.7998H21.4098V7.19984H19.9098ZM19.9098 16.7998C19.9098 18.2413 18.7413 19.4098 17.2998 19.4098V20.9098C19.5697 20.9098 21.4098 19.0697 21.4098 16.7998H19.9098ZM17.2998 19.4098H7.69984V20.9098H17.2998V19.4098ZM7.69984 19.4098C6.25838 19.4098 5.08984 18.2413 5.08984 16.7998H3.58984C3.58984 19.0697 5.42995 20.9098 7.69984 20.9098V19.4098ZM5.08984 16.7998V7.19984H3.58984V16.7998H5.08984ZM5.08984 7.19984C5.08984 5.75838 6.25838 4.58984 7.69984 4.58984V3.08984C5.42995 3.08984 3.58984 4.92995 3.58984 7.19984H5.08984Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default Square;
