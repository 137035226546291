import styled from '@emotion/styled';

export const AnswersInputsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const AnswerInputWrapper = styled.div`
  width: ${({ w }) => w ?? '100%'};
`;
