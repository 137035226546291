import styled from '@emotion/styled';
import { setMargin } from '../../helpers';

export const AlertWrapper = styled.div`
  ${setMargin}
  display: flex;
  width: 100%;
`;

export const AlertContent = styled.div`
  ${setMargin}
  width: ${({ width }) => width || 'auto'};
  padding: ${({ theme, p }) =>
    p ? theme.spacings[p] || p : theme.spacings[3]};
  background-color: ${({ theme, variant }) =>
    variant === 'primary'
      ? theme.colors.tertiaryLight
      : variant === 'success'
      ? theme.colors.successBg
      : variant === 'fail'
      ? theme.colors.failBg
      : theme.colors.neutralLight};
  border-radius: ${({ br }) => br || '12px'};
  display: flex;
  align-items: center;
  div {
    display: inline;
  }
`;

export const PlaySoundButton = styled.button`
  border-top-left-radius: ${({ theme }) => theme.spacings[4]};
  border-bottom-left-radius: ${({ theme }) => theme.spacings[4]};
  background: ${({ theme }) => theme.colors.neutralMain50};
  border: 0;
  padding: ${({ theme }) => `${theme.spacings[5]} ${theme.spacings[3]}`};
  cursor: pointer;

  :focus {
    box-shadow: inset 0px 0px 0px 2px rgba(5, 23, 48, 1);
    outline-style: solid;
    outline-width: 2px;
    outline-color: ${({ theme }) => theme.colors.primaryMain};
    outline-offset: 2px;
    -moz-outline-radius-topleft: ${({ theme }) => theme.spacings[4]};
    -moz-outline-radius-bottomleft: ${({ theme }) => theme.spacings[4]};
  }
`;
