import { useNavigate } from 'react-router-dom';
import { navRoutes } from '../../../../constants';
import { Icon, Loading } from 'components';
import * as T from '../../../../components/Typography';
import * as S from './style';

const StepsList = ({ data = [], loading }) => {
  const navigate = useNavigate();

  const handleEditIconClick = (stepId) => {
    navigate(
      navRoutes.FACILITATOR.TRAINING_CUSTOMIZATION_EDIT_STEP.replace(
        ':stepId',
        stepId
      )
    );
  };

  if (loading) return <Loading />;

  if (data.length === 0) return <T.P>No custom steps currently added</T.P>;

  return data.map((step, index) => {
    return (
      <S.AlignmentWrapper key={step?.order}>
        <S.IconStepContainer border="neutralMain" bg="neutralLight">
          <S.Wrapper border="neutralMain">
            <S.NumberWrapper bg={'neutralMain'} icon={'neutralMain'}>
              <Icon icon="tick" color="white" width="24px" height="24px" />
            </S.NumberWrapper>
            <S.TextWrapper>
              <T.P weight="bold">{step?.title}</T.P>
              <T.P>{step?.introduction}</T.P>
            </S.TextWrapper>
          </S.Wrapper>

          <S.IconWrapper onClick={() => handleEditIconClick(step.id)}>
            <Icon icon="edit" color="primaryMain" />
          </S.IconWrapper>
        </S.IconStepContainer>

        {data.length - 1 !== index && <S.Line active />}
      </S.AlignmentWrapper>
    );
  });
};

export default StepsList;
