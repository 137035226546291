import { useNavigate } from 'react-router';
import * as T from '../../../components/Typography';
import { Row, Col } from '../../../components/Grid';
import InfoCard, { InfoCardWithButton } from '../../../components/InfoCard';
import { useGetCoachStuckAtLearners } from '../../../api-calls/coaches.queries';
import { useSelectLearner } from '../../../api-calls/learners.queries';

import { COACH } from '../../../constants/nav-routes';
import { useAuth } from '../../../context/auth';

import * as S from './style';
import { BackButton } from 'components/BackButton';
const LearnersStuckAt = () => {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();

  const { data: learnersStuckAt } = useGetCoachStuckAtLearners();
  const { mutateAsync: selectLearner } = useSelectLearner();

  return (
    <S.Wrapper>
      <BackButton link={COACH.DASHBOARD} mb="8" />
      <S.Content>
        <T.H1 weight="bold" mb="6">
          Learners who are stuck
        </T.H1>

        {learnersStuckAt.length !== 0 ? (
          learnersStuckAt.map((learner, idx) => {
            return (
              <InfoCard
                key={idx}
                mb={idx === learnersStuckAt.length - 1 ? '0' : '5'}
                firstColumnLabel="Learner ID"
                firstColumnValue={learner.learnerUniqueId}
                secondColumnLabel="Name"
                secondColumnValue={learner.firstName}
                buttonText={'View'}
                onClick={async () => {
                  setUser({ ...user, selectedLearnerId: learner.id });
                  await selectLearner({
                    learnerId: learner.learnerId,
                  });
                  navigate(
                    COACH.REVIEW_STUCK_AT.replace(
                      ':exerciseId',
                      learner.exerciseId
                    ).replace(':stepId', learner.stepId),
                    { type: learner.stepType }
                  );
                }}
              />
            );
          })
        ) : (
          <Row>
            <Col w={[4, 6, 6]}>
              <InfoCardWithButton />
            </Col>
          </Row>
        )}
      </S.Content>
    </S.Wrapper>
  );
};
export default LearnersStuckAt;
