import { Route, Routes } from 'react-router-dom';
import { Route as CustomRoute } from '../../components';

import { ExerciseProvider } from '../../context/exercise';
import ExerciseStep from './ExerciseStep';
import CoachNotes from './CoachNotes';
import SelectWithCoachOrNot from './SelectWithCoachOrNot';
import ExerciseExplainer from './ExerciseExplainer';
import ExerciseCompleted from './ExerciseCompleted';
import ExerciseFeedback from './ExerciseFeedback';
import ReviewCompleted from './ReviewCompleted';

import WonNewBadge from './WonNewBadge';
import { userRoles } from '../../constants';
import { EXERCISES } from '../../constants/nav-routes';
const { BASE } = EXERCISES;

function ExercisesRouter() {
  return (
    <>
      <ExerciseProvider>
        <Routes>
          <Route
            path={EXERCISES.EXERCISE_COMPLETED.replace(BASE, '')}
            element={
              <CustomRoute
                Component={ExerciseCompleted}
                layout="general"
                image="secondaryColor"
              />
            }
          />
          <Route
            path={EXERCISES.EXERCISE_FEEDBACK.replace(BASE, '')}
            element={
              <CustomRoute
                Component={ExerciseFeedback}
                layout="general"
                image="secondaryColor"
              />
            }
          />
          <Route
            path={EXERCISES.COACH_NOTES.replace(BASE, '')}
            element={
              <CustomRoute
                Component={CoachNotes}
                layout="general"
                image="secondaryColor"
              />
            }
          />
          <Route
            path={EXERCISES.SELECT_WITH_COACH_OR_NOT.replace(BASE, '')}
            element={
              <CustomRoute
                Component={SelectWithCoachOrNot}
                layout="general"
                image="secondaryColor"
              />
            }
          />
          <Route
            path={EXERCISES.EXERCISE_EXPLAINER.replace(BASE, '')}
            element={
              <CustomRoute
                Component={ExerciseExplainer}
                layout="general"
                image="secondaryColor"
                fullWidth
              />
            }
          />
          <Route
            path={EXERCISES.SINGLE_STEP.replace(BASE, '')}
            element={<CustomRoute Component={ExerciseStep} />}
          />
          <Route
            path={EXERCISES.SINGLE_STEP_PREVIEW.replace(BASE, '')}
            element={<CustomRoute Component={ExerciseStep} preview />}
          />
          <Route
            path={EXERCISES.WON_NEW_BADGE.replace(BASE, '')}
            element={
              <CustomRoute
                Component={WonNewBadge}
                layout="general"
                image="secondaryColor"
                isPrivate
                allowedRoles={[userRoles.COACH, userRoles.LEARNER]}
              />
            }
          />
          <Route
            path={EXERCISES.REVIEW_COMPLETED_SL.replace(BASE, '')}
            element={
              <CustomRoute
                Component={ReviewCompleted}
                layout="general"
                headerProps={{
                  type: 'progress',
                  progressPercent: 100,
                }}
                image="secondaryColor"
                isPrivate
                allowedRoles={[userRoles.COACH]}
              />
            }
          />
        </Routes>
      </ExerciseProvider>
    </>
  );
}

export default ExercisesRouter;
