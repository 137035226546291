import Icon from '../Icon';
import * as S from './style';
import Markdown from '../../components/Markdown';
import { UseAudio } from 'Hooks';

const AudioOut = ({
  handleClick = () => {},
  disabled,
  audioUrl,
  text,
  fontSize = 'medLarge',
  children,
  needsRowFormat,
  fallbackText,
  ...props
}) => {
  const { isLoading, fallbackDisabled, handleAudioClick } = UseAudio({
    audioUrl,
    handleClick,
    fallbackText: typeof fallbackText === 'string' && fallbackText,
  });

  const audioDisabled = disabled || isLoading || fallbackDisabled;

  return (
    <S.Container needsRowFormat={needsRowFormat} {...props}>
      <S.PlayButton
        disabled={audioDisabled}
        onClick={handleAudioClick}
        aria-label="Play sound"
        needsRowFormat={needsRowFormat}
      >
        <Icon
          icon={'speaker'}
          disabled={audioDisabled}
          width={32}
          height={32}
        />
      </S.PlayButton>
      {text && (
        <S.PlayText needsRowFormat={needsRowFormat}>
          <Markdown
            text={text}
            customStyles={{
              p: { size: fontSize, color: 'neutralMain', ta: 'left' },
            }}
          />
        </S.PlayText>
      )}
      {children ? children : null}
    </S.Container>
  );
};

export default AudioOut;
