import { useNavigate } from 'react-router-dom';
import { useGeneralState } from '../../../context/general-state';
import { Typography as T, Button, Icon } from '../../../components';
import { getMediaUrl } from '../../../helpers';
import { navRoutes as R, audio } from '../../../constants';
import { useAuth } from 'context/auth';
import * as S from './style';
import { BackButton } from 'components/BackButton';
import { LEARNER } from 'constants/nav-routes';

const Menu = () => {
  const navigate = useNavigate();
  const { state: generalState } = useGeneralState();
  const { logout, user } = useAuth();

  const handleLearnerLogout = () => {
    logout({
      onLogoutSuccess: () => {
        navigate(LEARNER.LOGIN);
      },
    });
  };
  const isInBothCourses = user?.isTpdCourse && user?.isCmidCourse;
  return (
    <S.Wrapper>
      <BackButton link={LEARNER.DASHBOARD} />
      <S.Content>
        <S.PlayButtonWrapper>
          <Button.BasicButton
            icon="exercises"
            variant="primary"
            handleClick={() => navigate(R.LEARNER.DASHBOARD)}
            showSound
            audioUrl={getMediaUrl(
              audio.myExercises,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="My exercises"
            width="100%"
          >
            My exercises
          </Button.BasicButton>
        </S.PlayButtonWrapper>

        <S.PlayButtonWrapper>
          <Button.BasicButton
            icon="goals"
            variant="secondary"
            handleClick={() => navigate(R.LEARNER.GOALS)}
            showSound
            audioUrl={getMediaUrl(
              audio.myGoals,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="My goals"
            width="100%"
          >
            <T.P color="white" weight="bold">
              My goals
            </T.P>
          </Button.BasicButton>
        </S.PlayButtonWrapper>

        <S.PlayButtonWrapper>
          <Button.BasicButton
            icon="message"
            variant="tertiary"
            iconProps={{ width: '23px', height: '23px' }}
            handleClick={() =>
              navigate(
                R.LEARNER.CHAT_WITH_COACH.replace(':receiverId', user.coachId)
              )
            }
            showSound
            audioUrl={getMediaUrl(
              audio.messages,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="Messages"
            disabled={!user.coachId}
            width="100%"
          >
            Messages
          </Button.BasicButton>
        </S.PlayButtonWrapper>
        <S.PlayButtonWrapper>
          <Button.BasicButton
            icon="calendar"
            iconProps={{ width: '26.88px', height: '25.6px' }}
            variant="tertiary"
            handleClick={() => navigate(R.LEARNER.MY_COACHING_SESSIONS)}
            showSound
            audioUrl={getMediaUrl(
              audio.myCoachingSessions,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="My Coaching Sessions"
            width="100%"
          >
            My Coaching Sessions
          </Button.BasicButton>
        </S.PlayButtonWrapper>
        <S.PlayButtonWrapper>
          <Button.BasicButton
            icon="profile"
            variant="tertiary"
            iconProps={{ width: '23px', height: '23px' }}
            handleClick={() => navigate(R.LEARNER.PROFILE)}
            showSound
            audioUrl={getMediaUrl(
              audio.myProfile,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="My profile"
            width="100%"
          >
            My profile
          </Button.BasicButton>
        </S.PlayButtonWrapper>
        {isInBothCourses && (
          <S.PlayButtonWrapper>
            <Button.BasicButton
              icon="book"
              variant="tertiary"
              iconProps={{ width: '23px', height: '23px' }}
              handleClick={() =>
                navigate(`${R.COMMON.SWITCH_COURSE}?showAudio=true`)
              }
              showSound
              audioUrl={getMediaUrl(
                audio.switchCourse,
                true,
                generalState?.preferredVoice
              )}
              fallbackText="Switch Course"
              width="100%"
            >
              Switch Course
            </Button.BasicButton>
          </S.PlayButtonWrapper>
        )}
        <S.PlayButtonWrapper>
          <Button.BasicButton
            icon="profile"
            variant="tertiary"
            iconProps={{ width: '23px', height: '23px' }}
            handleClick={() => navigate(R.LEARNER.WELCOME)}
            showSound
            audioUrl={getMediaUrl(
              audio.howToUse,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="How to use"
            width="100%"
          >
            How to use
          </Button.BasicButton>
        </S.PlayButtonWrapper>
        <S.PlayButtonWrapper>
          <Button.PlayButton
            width="56px"
            height="56px"
            audioUrl={getMediaUrl(
              audio.logout,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            mr={3}
            backgroundColor="neutralSurface"
            fallbackText="Logout"
          />

          <S.LogoutButton onClick={handleLearnerLogout}>
            <Icon icon="logout" width="32px" height="32px" />
            Logout
          </S.LogoutButton>
        </S.PlayButtonWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

export default Menu;
