import { useState } from 'react';
import { useGeneralState } from 'context/general-state';

import Icon from '../../../components/Icon';
import * as T from '../../../components/Typography';
import { BasicButton } from '../../../components/Button';
import Modal from '../../../components/Modal';
import { ORGANISATION_ADMIN, COMMON } from '../../../constants/nav-routes';
import { useUpdateOrgAdminToFacilitator } from '../../../api-calls/organisations.queries';
import { useAuth } from '../../../context/auth';

import * as S from './style';

const FacilitatorStatus = () => {
  const { user, resetStateAfterSwitchAccount } = useAuth();
  const { state: generalState, setState } = useGeneralState();

  const { isFacilitator, organisationId } = user;

  const [visible, setVisible] = useState(false);
  const {
    mutateAsync: setFacilitator,
    error,
    isLoading,
  } = useUpdateOrgAdminToFacilitator({ id: organisationId });

  const handleClick = async () => {
    await setFacilitator();
    await resetStateAfterSwitchAccount({});
    setState({ ...generalState, switchAccount: true });
    setVisible(true);
  };

  return (
    <>
      {isFacilitator ? (
        <S.MessageWrapper>
          <Icon icon="circleTick" color="quaternaryMain" />
          <T.H2>You are currently set as facilitator</T.H2>
        </S.MessageWrapper>
      ) : (
        <>
          <S.Section>
            <T.H2 mb="4">Set myself up as a facilitator</T.H2>
            <T.P mb="4">
              If you are planning to facilitate the programme yourself, please
              click the button below
            </T.P>
            {error && (
              <T.P color="error" mb="4">
                {error?.message || 'Something went wrong'}
              </T.P>
            )}
            <BasicButton
              onClick={handleClick}
              maxWidth="284px"
              loading={isLoading}
            >
              <T.P color="white" weight="semi">
                Make me a facilitator
              </T.P>
            </BasicButton>
          </S.Section>
        </>
      )}
      <Modal visible={visible}>
        <T.H2 mb={4} color="quaternaryLight">
          Success
        </T.H2>
        <T.P mb={4} color="quaternaryLight">
          You're facilitator account is now set up. To access, please go to your
          menu and select Switch account
        </T.P>
        <BasicButton mb="2" to={COMMON.SELECT_ORGANISATION} variant="secondary">
          <T.P color="neutralSurface" weight="bold">
            Switch account
          </T.P>
        </BasicButton>
        <BasicButton to={ORGANISATION_ADMIN.DASHBOARD} variant="tertiary">
          <T.P weight="bold">Return to dashboard</T.P>
        </BasicButton>
      </Modal>
    </>
  );
};

export default FacilitatorStatus;
