import { useEffect, useMemo, useState } from 'react';

import * as T from '../../../../components/Typography';
import TryAgainModal from '../../../../components/ModalNew/TryAgainModal';
import { PlayButton } from '../../../../components/Button';
import { useLocation } from 'react-router-dom';
import StepFooterButtons from '../StepFooterButtons';

import * as S from './style';

const getInitialAnswers = (options) => {
  const _answers = {};
  options?.forEach((option) => {
    _answers[option.id] = option.showValue ? option.option : '';
  });

  return _answers;
};

const GridWithTypingAnswer = ({
  data: {
    options,
    rowSize,
    squaresPerRow,
    question,
    questionAudioUrl,
    correctAnswer,
  } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
}) => {
  const [showTryAgain, setShowTryAgain] = useState(false);
  const [answers, setAnswers] = useState(() => getInitialAnswers(options));

  const location = useLocation();
  useEffect(() => {
    if (options?.length) {
      setAnswers(getInitialAnswers(options));
    }

    // To avoid unnecessary re-renders we only want this to work when the pathname changes,
    // if react-query still refetching step is handled in the parent (isStepLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleClick = () => {
    const allCorrect = options?.every(
      (option) => answers[option.id] === option.option
    );

    if (allCorrect && answers.answer === correctAnswer) {
      handleNext();
    } else {
      setShowTryAgain(true);
    }
  };

  const showSquares =
    rowSize >= 2 && rowSize <= 4 && squaresPerRow >= 2 && squaresPerRow <= 4;
  const rows = useMemo(() => {
    if (!showSquares) {
      return [];
    }
    const _options = options || [];
    const result = [];
    let index = 0;
    let rowLength = Number(squaresPerRow);

    while (index < _options.length) {
      result.push(_options.slice(index, index + rowLength));
      index += rowLength;
    }
    return result;
  }, [options, showSquares, squaresPerRow]);

  const isAllInputsFilled =
    Object.values(answers).every((value) => !!value) && !!answers.answer;

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        {showSquares && (
          <S.PreFilledAnswersGridWrapper>
            {rows.map((rowOptions, rowIndex) => (
              <S.PyramidRowWrapper key={rowIndex}>
                {rowOptions.map((option) => {
                  const id = option.id;
                  const value = answers[id];
                  const isCorrect = value === option.option;
                  return (
                    <S.TypeInput
                      key={id}
                      id={`answer-${id}`}
                      name={`answer-${id}`}
                      type="text"
                      aria-labelledby={`answer-${id}`}
                      rowSize={rowSize}
                      showValue={option.showValue}
                      value={value}
                      disabled={option.showValue}
                      onChange={(e) => {
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          [option.id]: e.target.value,
                        }));
                      }}
                      isCorrect={isCorrect}
                    />
                  );
                })}
              </S.PyramidRowWrapper>
            ))}
          </S.PreFilledAnswersGridWrapper>
        )}

        <S.TextAudioWrapper mt="7" mb={2}>
          <PlayButton
            audioUrl={questionAudioUrl}
            width="56px"
            height="56px"
            iconProps={{ width: '32px', height: '32px' }}
            fallbackText={question}
          />
          <T.P ml={4} weight="bold" size="med">
            {question}
          </T.P>
        </S.TextAudioWrapper>
        <S.AnswerInput
          id="answer"
          name="answer"
          type="text"
          aria-labelledby="answer"
          value={answers.answer}
          onChange={(e) =>
            setAnswers((prevAnswers) => ({
              ...prevAnswers,
              answer: e.target.value,
            }))
          }
          isCorrect={answers.answer === correctAnswer}
        />
      </S.ContentWrapper>

      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleClick}
        preview={preview}
        disabled={disabled}
        enableNext={isAllInputsFilled}
      />
      <TryAgainModal
        aria-modal="true"
        isModalVisible={showTryAgain}
        setIsModalVisible={setShowTryAgain}
      />
    </S.Wrapper>
  );
};

export default GridWithTypingAnswer;
