import { Button, Grid, Icon, Modal, Typography as T } from 'components';
import { useGeneralState } from 'context/general-state';
import { getMediaUrl } from 'helpers';
import { audio } from '../../constants';
import * as S from './style';

const Success = ({
  visible,
  setIsModalVisible,
  maxWidth,
  cancelTextAudioKey,
  cancelText = 'close',
  onCancel,
  title,
  titleAudioKey,
}) => {
  const { state: generalState } = useGeneralState();
  return (
    <Modal
      visible={visible}
      setIsModalVisible={setIsModalVisible}
      maxWidth={maxWidth}
      bgColor="white"
    >
      <Grid.Row id="dte">
        <Grid.Col w={[4, 12, 12]} pr="32px" dir="column" gap="16px">
          <Icon
            icon="circleTick"
            color="quinaryMain"
            width="72px"
            height="72px"
          />
          <S.TextWrapper>
            {titleAudioKey && (
              <Button.PlayButton
                mr="16px"
                audioUrl={getMediaUrl(
                  audio[titleAudioKey],
                  true,
                  generalState?.preferredVoice
                )}
                fallbackText={title}
              />
            )}
            <T.H2>{title}</T.H2>
          </S.TextWrapper>
          <S.ButtonsWrapper>
            <Button.BasicButton
              mt="8px"
              showSound={cancelTextAudioKey ? true : false}
              soundBgColor="#050D3080"
              soundColor="#FFFF"
              style={{
                backgroundColor: '#F0F1F5',
                color: '#050D30',
                height: '64px',
                minWidth: '100%',
              }}
              onClick={onCancel}
              audioUrl={getMediaUrl(
                audio[cancelTextAudioKey],
                true,
                generalState?.preferredVoice
              )}
              fallbackText={cancelText}
            >
              {cancelText}
            </Button.BasicButton>
          </S.ButtonsWrapper>
        </Grid.Col>
      </Grid.Row>
    </Modal>
  );
};

export default Success;
