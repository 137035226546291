const { default: styled } = require('@emotion/styled');
const { setMargin } = require('helpers');

export const LinkButton = styled.button`
  ${setMargin};
  all: unset;
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.tertiaryMain};
  text-decoration: underline;
  cursor: pointer;
`;
