import { useMemo, useRef } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput } from '../../../../components/Inputs';
import * as S from './style';
import { Icon } from '../../../../components';
import { MediaKeyInput } from '../../../components/MediaKeyInput';

import { string, number, boolean, array, object } from 'yup';
import { mediaTypes } from '../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';

const initialData = {
  correctOptions: [{ id: 0, option: '' }],
  questionText: '',
  audioUrl: '',
  textAudio: '',
  textAudioKey: '',
  questionAudioKey: '',
};

const Typing = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateFormFields, updateDataFields } = useStepForm(
    initialRef.current
  );
  const { form, data, validationErrs } = state;

  const correctOptions = useMemo(
    () =>
      data?.correctOptions?.map((e, i) => ({
        ...e,
        id: i,
      })) || [{ id: 0, option: '' }],
    [data?.correctOptions]
  );

  const addOption = () => {
    const maxId = Math.max(...correctOptions.map((e) => e.id), 0);
    updateDataFields({
      correctOptions: [...correctOptions, { id: maxId + 1, option: '' }],
    });
  };
  const removeOption = (id) => {
    updateDataFields({
      correctOptions: correctOptions.filter((e) => e.id !== id),
    });
  };

  const onOptionChange = (value, id) => {
    updateDataFields({
      correctOptions: correctOptions.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  return (
    <div>
      <BasicInput
        label="Explainer Text"
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        m={{ mt: 8 }}
        error={validationErrs?.textAudio}
      />
      <MediaKeyInput
        label={`Explainer audio file Key`}
        type={mediaTypes.TEXT_AUDIO}
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs?.textAudioKey}
      />

      <BasicInput
        label="Question text"
        value={data.questionText}
        handleChange={(questionText) => updateDataFields({ questionText })}
        m={{ mt: 8 }}
        error={validationErrs?.questionText}
      />
      <MediaKeyInput
        type={mediaTypes.QUESTION_AUDIO}
        label={`Question audio file Key`}
        value={form.questionAudioKey}
        handleChange={(questionAudioKey) =>
          updateFormFields({ questionAudioKey })
        }
        m={{ mt: 5 }}
        error={validationErrs?.questionAudioKey}
      />

      <T.P mt={8} size="large" weight="bold">
        Correct Answers
      </T.P>
      {validationErrs?.correctOptions &&
        typeof validationErrs.correctOptions === 'string' && (
          <T.P mb={2} mt={2} color="error">
            {validationErrs.correctOptions}
          </T.P>
        )}

      {correctOptions.map((e, i) => {
        return (
          <div style={{ marginBottom: '25px' }}>
            <BasicInput
              label={`Answer ${i + 1}`}
              value={e.option}
              handleChange={(value) => {
                onOptionChange(value, e.id);
              }}
              m={{ mt: 5 }}
              helper='correct options to match with e.g "supply dogs" / "supplydogs" ...'
              error={
                validationErrs?.correctOptions &&
                validationErrs?.correctOptions[i]?.option
              }
            />

            {correctOptions.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeOption(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </div>
        );
      })}
      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addOption}
        disabled={correctOptions.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Option
      </S.Button>
    </div>
  );
};

const validationSchema = {
  correctOptions: array()
    .of(
      object()
        .shape({
          id: number().required(),
          option: string().required('required field'),
          isCorrect: boolean(),
        })
        .required()
    )
    .required('you must provide answers')
    .min(1, 'you must provide answers'),
  questionText: string().required('required field'),
  questionAudioKey: string().required('required field'),
  textAudio: string().notRequired(),
  textAudioKey: string().notRequired(),
};
export { validationSchema };

export default Typing;
