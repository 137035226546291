import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as S from './style';
import Icon from 'components/Icon';

export const BackButton = ({ link, ...props }) => {
  const navigate = useNavigate();

  const onClick = () => {
    if (link) {
      navigate(link);
      return;
    }

    navigate(-1);
  };
  return (
    <S.BackButtonContainer {...props}>
      <S.BackButtonStyle type="button" onClick={onClick}>
        <Icon icon="leftChevron" />
        <S.TextWrapper>
          <S.BackTextWrapper>Back</S.BackTextWrapper>
        </S.TextWrapper>
      </S.BackButtonStyle>
    </S.BackButtonContainer>
  );
};
