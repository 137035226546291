import { Spin } from 'antd';
import styled from '@emotion/styled';
import * as T from 'components/Typography';

export const Container = styled.div`
  margin-top: 30px;
`;

export const NoDataWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

export const NameColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 8px;
`;

export const StyledLink = styled(T.Link)`
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
`;

export const HeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const SwitchWrapper = styled.div`
  position: relative;
`;

export const SwitchLoading = styled(Spin)`
  position: absolute;
  top: 0;
  left: 0;
  span {
    line-height: 24px;
    margin-left: 10px;
    color: ${({ theme, color }) =>
      theme.colors[color] || color || theme.colors.white};
  }
`;
