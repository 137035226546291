import { useRef } from 'react';

import { BasicInput } from '../../../../components/Inputs';
import { string } from 'yup';
import { MediaKeyInput } from '../../../components/MediaKeyInput';
import { mediaTypes } from '../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';

const initialData = {
  correctAnswer: '',
  questionText: '',
};

const VowelBreak = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateDataFields } = useStepForm(initialRef.current);
  const { data, validationErrs } = state;

  return (
    <div>
      <BasicInput
        label="Explainer Text"
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        m={{ mt: 8 }}
        error={validationErrs?.textAudio}
      />
      <MediaKeyInput
        label={`Explainer audio file Key`}
        type={mediaTypes.TEXT_AUDIO}
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs?.textAudioKey}
      />

      <BasicInput
        label="Initial value"
        value={data.questionText}
        handleChange={(questionText) => updateDataFields({ questionText })}
        m={{ mt: 8 }}
        helper="put slashes in the wrong places(i.e. int/o)"
        error={validationErrs?.questionText}
      />
      <BasicInput
        label="Correct Answer"
        value={data.correctAnswer}
        handleChange={(correctAnswer) => updateDataFields({ correctAnswer })}
        m={{ mt: 5 }}
        helper="put slashes in the correct places(i.e. in/to)"
        error={validationErrs?.correctAnswer}
      />
    </div>
  );
};

const validationSchema = {
  correctAnswer: string()
    .required('required field')
    .test(
      'slashes',
      'correct answer should has the same slash numbers',
      (val, a) => {
        return (
          val.split('').filter((e) => e === '/').length ===
          a.parent.questionText.split('').filter((e) => e === '/').length
        );
      }
    ),
  questionText: string()
    .required('required field')
    .test('oneSlashAtLeast', 'should split syllables with "/"', (val, a) => {
      return val.includes('/');
    }),
  textAudio: string().notRequired(),
  textAudioKey: string().notRequired(),
};
export { validationSchema };

export default VowelBreak;
