import { fields, createSchema, validate as _validate } from '..';

const {
  requiredText,
  optionalText,
  interestsAtLeastOne,
  requiredArrayOfStrings,
  optionalArrayOfString,
} = fields;

const schema = createSchema({
  confidentInReadingAbility: requiredText,
  canManageEverydayLife: requiredText,
  communicateWellWithOthers: requiredText,
  feelingCloseToOtherPeople: requiredText,
  ableToReadLetters: requiredText,
  dealWithConflict: requiredText,
  haveGotWithReadingGoals: requiredText,
  optimisticAboutFuture: requiredText,
  education: requiredText,
  employment: requiredText,

  firstLanguage: optionalText,
  ethnicity: optionalText,
  genders: optionalArrayOfString,
  interests: interestsAtLeastOne,
  personalGoals: requiredArrayOfStrings,
  hasDeviceInternetAccess: optionalText,
  usesDeviceType: optionalArrayOfString,
  confidentUsingTechnology: optionalText,
});

const validate = (data) => _validate(schema, data);

export default validate;
