import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  gap: 22px;
  padding: 0 40px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IdWrapper = styled.div`
  min-width: 92px;
`;

export const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  padding: 0 56px;
  padding-bottom: 34px;
  @media (max-width: 768px) {
    padding: 0;
  }
`;
