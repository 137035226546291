const UsersGroup = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '61'}
    height={height || '61'}
    viewBox="0 0 61 61"
    fill="none"
    {...props}
  >
    <path
      d="M37.9375 47.693C40.0522 48.307 42.2434 48.6175 44.4453 48.6152C47.9866 48.6204 51.4818 47.8129 54.662 46.2551C54.756 44.033 54.1231 41.8408 52.8591 40.0109C51.5951 38.1809 49.7691 36.8128 47.6576 36.114C45.5462 35.4153 43.2645 35.4239 41.1584 36.1386C39.0523 36.8532 37.2366 38.235 35.9864 40.0745M37.9375 47.693V47.6855C37.9375 44.9262 37.2285 42.3305 35.9864 40.0745M37.9375 47.693V47.9558C33.1663 50.8294 27.7001 52.3434 22.1304 52.334C16.3514 52.334 10.9444 50.7349 6.32816 47.9558L6.32569 47.6855C6.32378 44.1762 7.48993 40.766 9.64029 37.9927C11.7906 35.2193 14.8029 33.2405 18.2021 32.3683C21.6013 31.496 25.1942 31.7799 28.4142 33.1752C31.6343 34.5705 34.2983 36.9978 35.9864 40.0745M30.5 16.0762C30.5 18.2953 29.6185 20.4235 28.0493 21.9927C26.4802 23.5618 24.352 24.4434 22.1329 24.4434C19.9137 24.4434 17.7855 23.5618 16.2164 21.9927C14.6472 20.4235 13.7657 18.2953 13.7657 16.0762C13.7657 13.8571 14.6472 11.7288 16.2164 10.1597C17.7855 8.59052 19.9137 7.70898 22.1329 7.70898C24.352 7.70898 26.4802 8.59052 28.0493 10.1597C29.6185 11.7288 30.5 13.8571 30.5 16.0762ZM50.9532 21.6543C50.9532 23.3803 50.2675 25.0356 49.0471 26.256C47.8266 27.4765 46.1713 28.1621 44.4453 28.1621C42.7194 28.1621 41.0641 27.4765 39.8436 26.256C38.6232 25.0356 37.9375 23.3803 37.9375 21.6543C37.9375 19.9283 38.6232 18.273 39.8436 17.0526C41.0641 15.8321 42.7194 15.1465 44.4453 15.1465C46.1713 15.1465 47.8266 15.8321 49.0471 17.0526C50.2675 18.273 50.9532 19.9283 50.9532 21.6543Z"
      stroke={color}
      strokeWidth="3.71875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UsersGroup;
