import { Col, Row } from 'components/Grid';
import { BasicButton } from '../../../components/Button';
import { Typography as T } from 'components';

import Modal from '../../../components/Modal';

const SuccessModal = ({
  isSuccessModalOpen,
  setIsSuccessModalVisible,
  successMessage,
}) => {
  return (
    <Modal
      visible={isSuccessModalOpen}
      setIsModalVisible={setIsSuccessModalVisible}
    >
      <Row>
        <Col w={[4, 12, 12]}>
          <T.P size="med" weight="bold" color="white">
            Success
          </T.P>
          <T.P size="regular" color="white" mt={4} mb={4}>
            {successMessage}
          </T.P>

          <BasicButton
            variant="secondary"
            onClick={() =>
              setIsSuccessModalVisible({
                modal: '',
                visible: false,
              })
            }
            mt={4}
          >
            Return to facilitators
          </BasicButton>
        </Col>
      </Row>
    </Modal>
  );
};
export default SuccessModal;
