import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  flex: 1;
  margin-top: ${({ theme }) => theme.spacings[2]};
  display: ${({ display }) => display};
`;

export const RotateWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: ${({ theme }) => theme.spacings[5]};
  flex-direction: column;
  flex: 1;
  margin-top: ${({ theme }) => theme.spacings[2]};
  display: ${({ display }) => display};
`;

export const Row = styled.div`
  width: 100%;
  height: 96px;
  display: flex;
  gap: 32px;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacings[5]};
  padding: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[6]}`};
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
  background: ${({ theme }) => theme.colors.neutralLight};
`;

export const ButtonWrapper = styled.div`
  max-width: 200px;
  margin: 0;
  display: flex;
`;

export const ImageWrapper = styled.div`
  margin: 24px auto;
  overflow: hidden;
  width: 100%;
  max-height: 300px;
`;

export const Img = styled.img`
  width: 100%;
  object-fit: cover;
`;

export const TypeInputWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: 'center';
  margin: 0;
  gap: 12px;
`;

export const AnswersWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;

export const TypeInput = styled.input`
  outline: 0;
  border-width: 0 0 2px;
  border-radius: 0;
  border-color: ${({ theme, isCorrect }) =>
    isCorrect ? theme.colors.quinaryMain : theme.colors.primaryMain};
  width: 98px;
  height: 42px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 150%;
  text-align: center;
  background: transparent;
  color: ${({ theme }) => theme.colors.neutralMain};
  transition: 0.3s;
  &:focus {
    border-color: ${({ theme, isCorrect }) =>
      isCorrect ? theme.colors.quinaryMain : theme.colors.neutralMain};
  }
`;
