import { useNavigate } from 'react-router';
import * as T from '../../../components/Typography';
import { Row, Col } from '../../../components/Grid';
import InfoCard, { InfoCardWithButton } from '../../../components/InfoCard';
import { useSelectLearner } from '../../../api-calls/learners.queries';
import { COACH, COMMON } from '../../../constants/nav-routes';
import StartCoaching from '../../../components/StartCoaching';
import Loading from '../../../components/Loading';
import { useGetLearners } from '../../../api-calls/learners.queries';
import * as S from './style';
import { useAuth } from '../../../context/auth';
import NewLearners from '../Dashboard/NewLearners';
import { BackButton } from 'components/BackButton';

const Learners = () => {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const {
    learners = [],
    isLoading: getLearnersLoading,
    error: getLearnersError,
  } = useGetLearners();
  const { mutateAsync: selectLearner } = useSelectLearner();
  const currentLearners = learners?.filter((e) => e.onboarded && e.confirmed);
  const toOnboardLearners = learners?.filter(
    (e) => !e.onboarded && e.confirmed
  );
  const newLearners = learners?.filter((e) => !e.confirmed);
  if (getLearnersLoading) {
    return <Loading />;
  }
  return (
    <S.Wrapper>
      <BackButton />
      {newLearners.length ? (
        <Row>
          <Col w={[4, 12, 12]}>
            <NewLearners newLearners={newLearners} />
          </Col>
        </Row>
      ) : null}

      {toOnboardLearners.length !== 0 ? (
        <Row>
          <Col w={[4, 12, 12]} mb="4">
            <T.H2 weight="bold">Learners to be onboarded</T.H2>
          </Col>
          {toOnboardLearners?.map((learner) => {
            return (
              <Col w={[4, 12, 12]} mt="2" key={learner.id}>
                <InfoCard
                  firstColumnLabel="Learner ID"
                  firstColumnValue={learner.learnerUniqueId}
                  secondColumnLabel="Name"
                  secondColumnValue={learner.firstName}
                  to={COACH.NEW_LEARNER.replace(
                    ':firstName',
                    learner.firstName
                  ).replace(':userId', learner.id)}
                  actionType={'onboard'}
                />
              </Col>
            );
          })}
        </Row>
      ) : null}

      <Row>
        <Col w={[4, 12, 12]} mb="4">
          <Row gapM="16px" ai="center">
            <Col w={[4, 6, 6]}>
              <T.H2 weight="bold">Current Learners</T.H2>
            </Col>

            <Col w={[4, 6, 6]} display="flex" jc="flex-end" jcM="stretch">
              <StartCoaching
                hideForgetLink
                btnProps={{
                  height: 'auto',
                  maxWidthM: '100%',
                }}
              />
            </Col>
          </Row>
        </Col>
        {currentLearners.length !== 0 ? (
          currentLearners?.map((learner) => {
            return (
              <Col w={[4, 12, 12]} mt="2" key={learner.id}>
                <InfoCard
                  firstColumnLabel="Learner ID"
                  firstColumnValue={learner.learnerUniqueId}
                  secondColumnLabel="Name"
                  secondColumnValue={learner.firstName}
                  onClick={async () => {
                    setUser({ ...user, selectedLearnerId: learner.id });
                    selectLearner({
                      learnerId: learner.id,
                    });
                    navigate(COMMON.LEARNER.replace(':userId', learner.id));
                  }}
                  hasNewMessages={learner.hasNewMessages}
                  messagingLink={COACH.CHAT_SAFEGUARD.replace(
                    ':receiverId',
                    learner.id
                  )}
                />
              </Col>
            );
          })
        ) : (
          <Col w={[4, 12, 12]} mt="2">
            <InfoCardWithButton />
          </Col>
        )}
      </Row>
      {getLearnersError ? (
        <T.P color="error">{getLearnersError?.message}</T.P>
      ) : null}
    </S.Wrapper>
  );
};
export default Learners;
