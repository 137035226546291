import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-bottom: 50px;
`;

export const MilestoneSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Circle = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: ${({ theme, overlayColor }) =>
    !overlayColor || overlayColor === 'default'
      ? 'white'
      : theme.colors[overlayColor]};
  border: ${({ theme }) => `4px solid ${theme.colors.neutralMain}`};
  position: absolute;
  top: 190px;
  z-index: 5;
`;
export const Line = styled.svg`
  border: ${({ theme }) => `3px solid ${theme.colors.neutralMain}`};
  height: 56px;
  width: 0;
`;
export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: center;
  width: 100%;
  max-width: 600px;
  position: relative;
  ${({ theme }) => theme.media.mobile} {
    align-items: center;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  align-items: center;
  justify-content: center;
  span {
    color: ${({ theme }) => theme.colors['neutralMain']};
    font-weight: bold;
    font-size: 1rem;
  }
`;

export const CurvedLineWrapper = styled.div`
  transform: translateY(-10px);
  ${({ theme }) => theme.media.mobile} {
    transform: translateY(0px);
  }
`;

export const MilestoneTitle = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  padding-left: 120px;
  margin-bottom: 90px;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0px;
    margin-bottom: 0px;
  }
`;

export const StartPathWrapper = styled.div`
  position: relative;
  top: 150px;
  ${({ theme }) => theme.media.mobile} {
    top: unset;
  }
`;

export const CurrentBtnWrapper = styled.div`
  position: fixed;
  bottom: 32px;
  right: calc((100vw - 973px) / 2 + 16px);
  z-index: 50;
  @media (max-width: 1200px) {
    right: 32px;
    bottom: 80px;
  }
`;

export const CurrentButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.neutralSurface};
  padding: 8px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.neutralMain};
  box-shadow: 0px 2px 5px -1px #040d261c, 0px 1px 3px -1px #040d261a;
`;
