import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import handleError from '../api-calls/format-error';

import { queryClient } from './../api-calls/queryClient';

const onMutationSuccess = (reactQueryConfig) => {
  return (data, inputs) => {
    if (reactQueryConfig.invalidateAll) {
      queryClient.invalidateQueries(); // invalidates all queries
    } else if (
      reactQueryConfig.invalidateKeys &&
      typeof reactQueryConfig.invalidateKeys === 'function'
    ) {
      const keys = reactQueryConfig.invalidateKeys(inputs, data);
      if (keys) {
        keys.forEach((key) => queryClient.invalidateQueries(key));
      }
    }

    if (reactQueryConfig.onSuccess) {
      reactQueryConfig.onSuccess(data, inputs);
    }
  };
};

const useFetch = ({ key, url, axiosParams = {}, reactQueryConfig = {} }) => {
  const fetchData = async () => {
    const response = await axios.get(url, { params: axiosParams });
    return response.data;
  };

  return useQuery({
    queryKey: key,
    queryFn: fetchData,
    retry: (failureCount, error) => {
      if (error.response?.status >= 400 && error.response?.status < 500) {
        return false;
      }
      return failureCount < 3;
    },
    ...reactQueryConfig,
  });
};

const useFetchMutation = ({
  url,
  getUrl,
  axiosParams = {},
  reactQueryConfig = {},
}) => {
  const fetchData = async (data) => {
    const _url = typeof getUrl === 'function' ? getUrl(data) : url;
    // Prevent axios calls in Storybook
    if (process.env.STORYBOOK) {
      // In Storybook, return mock data
      return { data: {} };
    }
    const response = await axios.get(_url, { params: axiosParams });
    return response.data;
  };

  const context = useMutation({
    mutationFn: fetchData,
    ...reactQueryConfig,
  });

  return {
    ...context,
    isLoading: context.isPending,
  };
};

const usePost = ({ url, getUrl, axiosOptions = {}, reactQueryConfig = {} }) => {
  const postData = async (data) => {
    const _url = typeof getUrl === 'function' ? getUrl(data) : url;
    const response = await axios.post(_url, data, axiosOptions);
    return response.data;
  };

  const context = useMutation({
    mutationFn: postData,
    ...reactQueryConfig,
    onSuccess: onMutationSuccess(reactQueryConfig),
  });
  return {
    ...context,
    isLoading: context.isPending,
  };
};

const usePatch = ({
  url,
  getUrl,
  axiosOptions = {},
  reactQueryConfig = {},
}) => {
  const patchData = async (data) => {
    const _url = typeof getUrl === 'function' ? getUrl(data) : url;

    const response = await axios.patch(_url, data, axiosOptions);
    return response.data;
  };

  const context = useMutation({
    mutationFn: patchData,
    ...reactQueryConfig,
    onSuccess: onMutationSuccess(reactQueryConfig),
  });
  return {
    ...context,
    isLoading: context.isPending,
  };
};

const useDelete = ({
  url,
  getUrl,
  axiosOptions = {},
  reactQueryConfig = {},
}) => {
  const deleteData = async (data) => {
    const _url = typeof getUrl === 'function' ? getUrl(data) : url;
    const response = await axios.delete(_url, { data, ...axiosOptions });
    return response.data;
  };

  const context = useMutation({
    mutationFn: deleteData,
    ...reactQueryConfig,
    onSuccess: onMutationSuccess(reactQueryConfig),
  });
  return {
    ...context,
    isLoading: context.isPending,
  };
};

function formatQueryError(context) {
  return context.isError ? handleError(context.error) : null;
}

export {
  useFetch,
  usePost,
  usePatch,
  useDelete,
  useFetchMutation,
  formatQueryError,
  queryClient,
};
