import React from 'react';

import Icon from 'components/Icon';
import * as T from 'components/Typography';
import * as S from './style';
import { useCopyToClipboard } from 'Hooks';

/**
 * Copy button used in tables to copy links invites
 * @param {string} textToCopy
 * @returns JSX.Element
 */
const CopyInviteLink = ({ textToCopy }) => {
  const { copied, copyToClipboard } = useCopyToClipboard();

  return (
    <S.CopyLinkWrapper onClick={() => copyToClipboard(textToCopy)}>
      <S.CopyIconWrapper>
        <Icon
          icon={copied ? 'tick' : 'copy2'}
          color={copied ? 'green' : 'black'}
          width={copied ? '16' : '25'}
          height={copied ? '16' : '25'}
          ml="1"
        />
      </S.CopyIconWrapper>
      <S.CopyTextWrapper>
        <T.P weight="bold">Copy invite link</T.P>
        <T.P weight="regular">{textToCopy}</T.P>
      </S.CopyTextWrapper>
    </S.CopyLinkWrapper>
  );
};

export default CopyInviteLink;
