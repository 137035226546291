import Tag from 'components/Tag';
import WithText from '../Button/WithText';
import * as S from './style';

const AssignColumn = ({ handleClick, alreadyAssigned, previouslyAssigned }) => (
  <S.NameColumnWrapper previouslyAssigned={previouslyAssigned}>
    <WithText
      text={alreadyAssigned ? 'Assigned' : 'Assign'}
      icon="userWithArrow"
      width={'30px'}
      height={'30px'}
      handleClick={handleClick}
      disabled={alreadyAssigned}
    />
    {previouslyAssigned && <Tag color="quinaryDark">previously assigned</Tag>}
  </S.NameColumnWrapper>
);

export default AssignColumn;
