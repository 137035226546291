import { useParams } from 'react-router-dom';
import { audio, userRoles } from '../../../constants';
import * as S from './style';
import { BackButton } from 'components/BackButton';
import { Chat } from 'components';
import { PlayButton } from 'components/Button';
import { getMediaUrl } from 'helpers';
import { useGeneralState } from '../../../context/general-state';

const ChatWithCoach = () => {
  const { receiverId } = useParams();
  const { state: generalState } = useGeneralState();

  return (
    <S.Wrapper>
      <BackButton />
      <S.ChatWrapper>
        <Chat
          receiverId={receiverId}
          userType={userRoles.LEARNER}
          PreMsg={
            <S.PreWrapper>
              <S.HiWrapper>
                <S.HiSvgWrapper>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                  >
                    <path
                      d="M47.9654 46.281C48.1895 47.2897 47.2897 48.1895 46.281 47.9654L34.8313 45.421C31.5039 47.1068 27.8146 47.999 23.9995 47.999C10.7449 47.999 0 37.2541 0 23.9995C0 10.7449 10.7449 0 23.9995 0C37.2541 0 47.999 10.7449 47.999 23.9995C47.999 27.8146 47.1068 31.5039 45.421 34.8313L47.9654 46.281Z"
                      fill="#050D30"
                    />
                  </svg>
                </S.HiSvgWrapper>
                <S.HiSvgWrapper style={{ color: 'white' }}>Hi</S.HiSvgWrapper>
              </S.HiWrapper>
              <PlayButton
                audioUrl={getMediaUrl(
                  audio.messageYourCoach,
                  true,
                  generalState?.preferredVoice
                )}
                iconProps={{ width: '32px', height: '32px' }}
                padding="12px"
                size="large"
                // backgroundColor={theme.colors.neutralSurface}
                fallbackText="Message your coach"
              >
                Message your coach
              </PlayButton>
            </S.PreWrapper>
          }
        />
      </S.ChatWrapper>
    </S.Wrapper>
  );
};

export default ChatWithCoach;
