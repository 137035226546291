import { useState, useMemo } from 'react';
import * as T from 'components/Typography';
import * as S from './style';
import { useAuth } from 'context/auth';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  useGetFacilitators,
  useGetFacilitatorsCount,
} from 'api-calls/facilitator.queries';
import getCountText from 'utils/getCountText';

import { navRoutes, userRoles } from 'constants/index';
import { Button, Inputs } from 'components';
import { Row, Col } from 'components/Grid';
import SelectSortOptionComponent from 'components/SelectSortOptionComponent';
import TabsCommonTable from 'components/CommonTables/TabsCommonTable';
import getFacilitatorsTableColumns from './columns';
import useCsvDownload from 'Hooks/useCsvDownload';

const facilitatorTableEmptyState = {
  title: 'You currently do NOT have any facilitators',
  subTitle: 'Currently, there are no facilitators registered.',
  buttonText: 'Setup a new facilitator',
  buttonLink: navRoutes.ORGANISATION_ADMIN.ADD_FACILITATORS,
  disabled: false,
};
const pageSize = 20;

const FacilitatorsTable = ({
  onActivateAndDeactivate,
  organisationId,
  makeFacilitatorAsCoach,
}) => {
  const [page, setPage] = useState(1);
  const [statusType, setStatusType] = useState('ACTIVE');
  const navigate = useNavigate();

  const [sortInfo, setSortInfo] = useState({
    key: 'created_at',
    order: 'ASC',
  });

  const [searchValue, setSearchValue] = useState('');

  const [debounceSearchValue, setDebounceSearchValue] = useState('');
  const [data, handleClick] = useCsvDownload('/csv/facilitators');
  const {
    user: { role },
  } = useAuth();

  const { facilitatorCount } = useGetFacilitatorsCount({
    search: debounceSearchValue,
  });

  const {
    activated: activeCount = 0,
    deactivated: deactivatedCount = 0,
    deleted: deletedCount = 0,
    invited: invitedCount = 0,
    total: totalCount = 0,
  } = facilitatorCount?.count || {
    activated: 0,
    deactivated: 0,
    deleted: 0,
    total: 0,
    invited: 0,
  };

  const {
    activated: activeCountSearch = 0,
    deactivated: deactivatedCountSearch = 0,
    deleted: deletedCountSearch = 0,
    invited: invitedCountSearch = 0,
  } = facilitatorCount?.searchCount || {
    activated: 0,
    deactivated: 0,
    deleted: 0,
    invited: 0,
  };

  const { facilitators, isLoading, isError } = useGetFacilitators({
    page,
    limit: pageSize,
    statusType,
    searchValue: debounceSearchValue,
    sortField: sortInfo.key,
    sortOrder: sortInfo.order,
  });

  const searchValueFunctionDebounced = useMemo(
    () => debounce((value) => setDebounceSearchValue(value), 500),
    [setDebounceSearchValue]
  );
  if (isError?.message) {
    return (
      <T.P mb={2} mt={2} color="error">
        {isError?.message}
      </T.P>
    );
  }

  const isAdmin = role === userRoles.ADMIN;

  const commonTableProps = {
    loading: isLoading,
    emptyState: !totalCount && facilitatorTableEmptyState,
    setPage,
    data: facilitators,
    totalRecords: totalCount || 0,
  };

  return (
    <S.TableWrapper>
      <Row ai="center" mb={5}>
        <Col w={[4, 12, 4.5]} mbT={4}>
          <T.H2 color="neutralMain" size="medLarge">
            My Facilitators
          </T.H2>
        </Col>
        <Col w={[4, 4, 2.5]} mbM={2}>
          <SelectSortOptionComponent
            sortOptions={[
              {
                value: 'oldest-to-newest',
                label: 'Oldest to Newest',
              },
              {
                value: 'newest-to-oldest',
                label: 'Newest to Oldest',
              },
            ]}
            onChange={(value) => {
              if (value === 'oldest-to-newest') {
                setSortInfo({
                  key: 'created_at',
                  order: 'ASC',
                });
              } else if (value === 'newest-to-oldest') {
                setSortInfo({
                  key: 'created_at',
                  order: 'DESC',
                });
              }
            }}
          />
        </Col>

        <Col w={[4, 4, 2.5]} mbM={2}>
          <Inputs.BasicInput
            w="100%"
            value={searchValue}
            searchIcon
            handleChange={(value) => {
              setSearchValue(value);
              setPage(1);
              searchValueFunctionDebounced(value);
            }}
            placeholder="Search..."
            m={{ ml: '0' }}
          />
        </Col>

        <Col w={[4, 4, 2.5]} mbM={2}>
          <Button.BasicButton
            width="100%"
            disabled={data.loading}
            handleClick={handleClick}
            loading={data.loading}
            variant="tertiary"
            icon={'export'}
          >
            Export data
          </Button.BasicButton>
        </Col>
      </Row>

      <TabsCommonTable
        page={page}
        tabs={[
          {
            key: '1',
            title: 'Active',
            count: activeCount
              ? getCountText(
                  Number(activeCount),
                  Number(activeCountSearch),
                  !!debounceSearchValue,
                  isLoading
                )
              : 0,
            columns: getFacilitatorsTableColumns({
              organisationId,
              makeFacilitatorAsCoach,
              showActionsField: true,
              showAdminFields: isAdmin === true,
              onActivateAndDeactivate,
              UpdateFacilitatorsOrganisationsOnClick: (id) => {
                navigate(
                  navRoutes.ADMIN.UPDATE_FACILITATORS_ORGANISATIONS.replace(
                    ':id',
                    id
                  )
                );
              },
            }),
            ...commonTableProps,
            totalRecords: Number(activeCountSearch) || Number(activeCount),
          },
          {
            key: '2',

            title: 'Deactivated',
            count: deactivatedCount
              ? getCountText(
                  Number(deactivatedCount),
                  Number(deactivatedCountSearch),
                  !!debounceSearchValue,
                  isLoading
                )
              : 0,
            columns: getFacilitatorsTableColumns({
              makeFacilitatorAsCoach,
              organisationId,
              showActionsField: false,
              showAdminFields: isAdmin === true,
              onActivateAndDeactivate,
            }),
            ...commonTableProps,
            totalRecords:
              Number(deactivatedCountSearch) || Number(deactivatedCount),
          },
          {
            key: '3',
            title: 'Invited',
            count: invitedCount
              ? getCountText(
                  Number(invitedCount),
                  Number(invitedCountSearch),
                  !!debounceSearchValue,
                  isLoading
                )
              : 0,
            columns: getFacilitatorsTableColumns({
              organisationId,
              hideNameColumn: true,
              showActionsField: false,
              showAdminFields: isAdmin === true,
              onActivateAndDeactivate,
            }),
            ...commonTableProps,
            totalRecords: Number(deletedCountSearch) || Number(deletedCount),
          },
          {
            key: '4',
            title: 'Deleted',
            count: deletedCount
              ? getCountText(
                  Number(deletedCount),
                  Number(deletedCountSearch),
                  !!debounceSearchValue,
                  isLoading
                )
              : 0,
            columns: getFacilitatorsTableColumns({
              organisationId,
              showActionsField: false,
              showAdminFields: isAdmin === true,
              onActivateAndDeactivate,
            }),
            ...commonTableProps,
            totalRecords: Number(deletedCountSearch) || Number(deletedCount),
          },
        ]}
        tabsProps={{
          onChange: (key) => {
            setPage(1);
            if (key === '1') setStatusType('ACTIVE');
            if (key === '2') setStatusType('DEACTIVATED');
            if (key === '3') setStatusType('INVITED');
            if (key === '4') setStatusType('DELETED');
          },
        }}
      />
    </S.TableWrapper>
  );
};

export default FacilitatorsTable;
