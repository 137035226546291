import RateQuestionContent from './RateQuestionContent';
import Question from './Question';
import TextQuestionContent from './TextQuestionContent';

const Questions = ({ role, feedbacks }) => {
  const feelingCount =
    feedbacks?.filter((feedback) => feedback?.feeling)?.length || 0;
  const challenges =
    feedbacks
      ?.filter((feedback) => feedback?.challenges)
      .map((feedback) => feedback?.challenges) || [];
  const suggestions =
    feedbacks
      ?.filter((feedback) => feedback?.suggestions)
      .map((feedback) => feedback?.suggestions) || [];

  return (
    <>
      <Question
        questionLabel={`Are you enjoying being a ${role}?`}
        answersCount={feelingCount}
        role={role}
      >
        <RateQuestionContent feedbacks={feedbacks} totalCount={feelingCount} />
      </Question>

      <Question
        questionLabel="What challenges do you face?"
        answersCount={challenges?.length}
        role={role}
      >
        <TextQuestionContent answers={challenges} />
      </Question>
      <Question
        questionLabel="What could make things better for you?"
        answersCount={suggestions?.length}
        role={role}
      >
        <TextQuestionContent answers={suggestions} />
      </Question>
    </>
  );
};

export default Questions;
