import { useRef } from 'react';
import { Icon, Typography as T } from '../../../../../components';
import {
  BasicInput,
  Checkbox,
  Dropdown,
} from '../../../../../components/Inputs';
import MDEditor from '../../../../../components/MDEditor';
import { MediaKeyInput } from '../../../../components/MediaKeyInput';
import { fillInTheGridVariations, mediaTypes } from '../../../../../constants';
import { convertSnakeCaseToSpaces } from 'helpers';
import CorrectAnswers from './CorrectAnswers';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import { generateInitialCorrectOptions, generateInitialRows } from './utils';
import * as S from '../style';

export { validationSchema } from './validation';

const initialData = {
  correctOptions: [],
  variation: '',
  textAudio: '',
  textAudioKey: '',

  // grid no values
  columnSize: 8,
  rowSize: 8,

  // grid with values
  gridStart: 1,
  gridEnd: 64,
  gridReplacedWithText: [],

  // rows & thermometer
  rows: [],
};

const FillInTheGrid = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateDataFields } = useStepForm(initialRef.current);
  const { data, validationErrs } = state;

  const onVariationChange = (variation) => {
    updateDataFields({
      variation,
      correctOptions: generateInitialCorrectOptions(
        variation,
        data.correctOptions
      ),
      rows: generateInitialRows(variation, data.rows),
    });
  };

  const variationOptions = Object.keys(fillInTheGridVariations.variations).map(
    (e) => ({
      label: convertSnakeCaseToSpaces(e),
      value: e,
    })
  );

  const addReplacedWithText = () => {
    const maxId = Math.max(...data.gridReplacedWithText.map((e) => e.id), 0);
    updateDataFields({
      gridReplacedWithText: [
        ...data.gridReplacedWithText,
        { id: maxId + 1, number: null, replacedWith: '' },
      ],
    });
  };
  const onReplacedWithTextChange = ({ key, value, id }) => {
    updateDataFields({
      gridReplacedWithText: data.gridReplacedWithText.map((e) => {
        if (e.id !== id) return e;
        return {
          ...e,
          [key]: value,
        };
      }),
    });
  };
  const removeReplacedWithText = (id) => {
    updateDataFields({
      gridReplacedWithText: data.gridReplacedWithText.filter(
        (e) => e.id !== id
      ),
    });
  };

  const addRow = () => {
    const maxId = Math.max(...data.rows.map((e) => e.id), 0);
    updateDataFields({
      rows: [...data.rows, { id: maxId + 1, text: '', isCorrect: false }],
    });
  };
  const onRowChange = ({ key, value, id }) => {
    updateDataFields({
      rows: data.rows.map((e) => {
        if (e.id !== id) return e;
        return {
          ...e,
          [key]: value,
        };
      }),
    });
  };
  const removeRow = (id) => {
    updateDataFields({
      rows: data.rows.filter((e) => e.id !== id),
    });
  };

  const isGridNoValues =
    data.variation === fillInTheGridVariations.variations.GRID_NO_VALUES;
  const isGridWithValues =
    data.variation === fillInTheGridVariations.variations.GRID_WITH_VALUES;
  const isRows = data.variation === fillInTheGridVariations.variations.ROWS;
  const isThermometer =
    data.variation === fillInTheGridVariations.variations.THERMOMETER;

  return (
    <div>
      <Dropdown
        label="Choose a variation"
        options={variationOptions}
        selected={
          data.variation
            ? {
                value: data.variation,
                label: convertSnakeCaseToSpaces(data.variation),
              }
            : null
        }
        handleChange={onVariationChange}
        error={validationErrs.variation}
      />

      {!!data.variation && (
        <>
          <MDEditor
            label="Explainer Text"
            value={data.textAudio}
            onChange={(textAudio) => updateDataFields({ textAudio })}
            mode={'edit'}
            height={80}
            m={{ mt: 8 }}
            error={validationErrs?.textAudio}
          />
          <MediaKeyInput
            type={mediaTypes.TEXT_AUDIO}
            label={`Explainer Text file Key`}
            value={data.textAudioKey}
            handleChange={(textAudioKey) => {
              updateDataFields({ textAudioKey });
            }}
            m={{ mt: 5 }}
            error={validationErrs.textAudioKey}
          />

          {isGridNoValues && (
            <>
              <BasicInput
                label="Column Size"
                value={data.columnSize}
                handleChange={(columnSize) => updateDataFields({ columnSize })}
                m={{ mt: 5 }}
                type="number"
                error={validationErrs.columnSize}
              />
              <BasicInput
                label="Row Size"
                value={data.rowSize}
                handleChange={(rowSize) => updateDataFields({ rowSize })}
                m={{ mt: 5 }}
                type="number"
                error={validationErrs.rowSize}
              />
            </>
          )}
          {isGridWithValues && (
            <>
              <BasicInput
                label="Grid Start"
                value={data.gridStart}
                handleChange={(gridStart) => updateDataFields({ gridStart })}
                m={{ mt: 5 }}
                type="number"
                error={validationErrs.gridStart}
              />
              <BasicInput
                label="Grid End"
                value={data.gridEnd}
                handleChange={(gridEnd) => updateDataFields({ gridEnd })}
                m={{ mt: 5 }}
                type="number"
                error={validationErrs.gridEnd}
              />

              <T.P mt="8">Replace Numbers in the grid</T.P>
              {data.gridReplacedWithText.map((item, index) => (
                <S.InputWithDeleteWrapper>
                  <S.AnswerInputWrapper key={index}>
                    <BasicInput
                      m={{ mt: 5 }}
                      label="Replaced number"
                      value={item.number}
                      type="number"
                      handleChange={(value) => {
                        onReplacedWithTextChange({
                          key: 'number',
                          value,
                          id: item.id,
                        });
                      }}
                      error={
                        validationErrs?.gridReplacedWithText?.[index]?.number
                      }
                    />
                    <BasicInput
                      m={{ mt: 5 }}
                      label="Replaced with"
                      value={item.replacedWith}
                      handleChange={(value) => {
                        onReplacedWithTextChange({
                          key: 'replacedWith',
                          value,
                          id: item.id,
                        });
                      }}
                      error={
                        validationErrs?.gridReplacedWithText?.[index]
                          ?.replacedWith
                      }
                    />
                  </S.AnswerInputWrapper>
                  <S.Button
                    type="link"
                    danger
                    ghost
                    onClick={() => removeReplacedWithText(item.id)}
                  >
                    <Icon icon="cross" />
                  </S.Button>
                </S.InputWithDeleteWrapper>
              ))}
              {typeof validationErrs?.gridReplacedWithText === 'string' && (
                <T.P mb="2" color="error">
                  {validationErrs.gridReplacedWithText}
                </T.P>
              )}
              <S.Button
                type="link"
                ghost
                mt={5}
                mb={5}
                onClick={addReplacedWithText}
                disabled={
                  data.gridReplacedWithText.find(
                    (e) => !e.number || !e.replacedWith
                  ) || validationErrs?.gridReplacedWithText
                }
                ml="auto"
                mr="auto"
              >
                <Icon icon="plus" /> Add Replaced with Text
              </S.Button>
            </>
          )}

          {(isRows || isThermometer) && (
            <>
              <T.P weight="bold" mt="8" ml="2">
                {isThermometer ? 'Thermometer Values' : 'Rows:'}
              </T.P>
              {data.rows.map((row, i) => {
                return (
                  <>
                    <BasicInput
                      m={{ mt: i === 0 ? '2' : '5' }}
                      value={row.text}
                      handleChange={(value) => {
                        onRowChange({
                          key: 'text',
                          value,
                          id: row.id,
                        });
                      }}
                      error={validationErrs?.rows?.[i]?.text}
                    />

                    <S.IsCorrectWrapperWithDelete>
                      <Checkbox
                        m={{ ml: '2' }}
                        plain
                        noPadding
                        label={
                          <T.P size="small" m="0" ml="1">
                            correct option?
                          </T.P>
                        }
                        checked={row.isCorrect}
                        handleChange={(checked) => {
                          onRowChange({
                            key: 'isCorrect',
                            value: checked,
                            id: row.id,
                          });
                        }}
                        disabled={!row.text}
                      />

                      {i === 0 ? null : (
                        <S.Button
                          type="link"
                          danger
                          ghost
                          mt={1}
                          onClick={() => removeRow(row.id)}
                          ml="auto"
                        >
                          <Icon icon="cross" />
                        </S.Button>
                      )}
                    </S.IsCorrectWrapperWithDelete>
                  </>
                );
              })}
              {typeof validationErrs?.rows === 'string' && (
                <T.P mb="2" color="error">
                  {validationErrs.rows}
                </T.P>
              )}
              <S.Button
                type="link"
                ghost
                mt={5}
                mb={5}
                onClick={addRow}
                disabled={data.rows.find((e) => !e.text)}
                ml="auto"
                mr="auto"
              >
                <Icon icon="plus" /> Add {isThermometer ? 'Value' : 'Row'}
              </S.Button>
            </>
          )}

          {!isRows && !isThermometer && <CorrectAnswers />}
        </>
      )}
    </div>
  );
};

export default FillInTheGrid;
