import Modal from './../../../components/Modal';
import * as T from './../../../components/Typography';
import { Col } from './../../../components/Grid';
import { BasicButton } from './../../../components/Button';

const SuccessModal = ({
  isModalVisible,
  setIsModalVisible,
  onSuccess,
  message = 'You have add a new custom step to your coaches’ training.',
}) => {
  return (
    <Modal visible={isModalVisible} setIsModalVisible={setIsModalVisible}>
      <Col w={[4, 12, 12]} jc="left">
        <>
          <T.P size="med" weight="bold" color="white">
            Success!
          </T.P>
          <T.P color="white" mt="5">
            {message}
          </T.P>
        </>
      </Col>
      <>
        <Col w={[4, 12, 12]} mt={5}>
          <BasicButton onClick={onSuccess} variant="secondary">
            Return to training
          </BasicButton>
        </Col>
      </>
    </Modal>
  );
};

export default SuccessModal;
