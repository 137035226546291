import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.media.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;
export const Form = styled.form`
  max-width: 430px;
  flex: 1;
  margin-bottom: ${({ theme }) => `${theme.spacings[6]}`};
`;
export const Preview = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto 0;
  height: 75vh;
  box-sizing: border-box;
  flex-direction: column;
  position: fixed;
  top: 200px;
  right: 120px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.quaternaryLightBlue};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.secondaryMain};
    border-radius: 10px;
  }

  ${({ theme }) => theme.media.tablet} {
    height: fit-content;
    position: static;
    overflow: hidden;
    margin-top: ${({ theme }) => theme.spacings[7]};
  }
`;

export const PreviewItem = styled.div`
  width: 100%;
  border: 1px blue dashed;
  overflow: hidden;
`;
