import { fields, createSchema, validate as _validate } from '..';
import { array, object } from 'yup';

const { numberField, requiredText, requiredBoolean } = fields;

const schema = createSchema({
  organisationId: numberField,
  organisationName: requiredText,
  recruitmentFormTitle: requiredText,
  recruitmentFormDescription: requiredText,
  questions: array()
    .of(
      object().shape({
        id: numberField,
        label: requiredText,
        order: numberField,
        type: requiredText,
        required: requiredBoolean,
        options: array().when('type', {
          is: (type) => ['SINGLE_CHOICE', 'MULTIPLE_CHOICE'].includes(type),
          then: array()
            .of(
              object().shape({
                label: requiredText,
                required: requiredBoolean,
              })
            )
            .required(),
          otherwise: array().notRequired().nullable(),
        }),
      })
    )
    .required(),
});

const validate = (data) => _validate(schema, data);

export default validate;
