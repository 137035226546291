import { useNavigate } from 'react-router-dom';
import { useGetCoachesCount } from '../../../api-calls/coaches.queries';
import { useAuth } from 'context/auth';
import { userRoles } from '../../../constants';
import { Row } from 'components/Grid';
import { BasicButton } from '../../../components/Button';
import BasicTable from './BasicTable';
import { StatsChart, StatsChartLayout } from '../../../components';

import * as T from '../../../components/Typography';
import { navRoutes } from '../../../constants';
import * as S from './style';

import { useGetStats } from 'api-calls/stats.queries';

const AllCoaches = () => {
  const {
    user: { role },
  } = useAuth();

  const isAdmin = role === userRoles.ADMIN;

  const navigate = useNavigate();

  const { stats } = useGetStats();

  const { totalCompletedMilestones, completedTrainingCoachesCount } = stats || {
    totalCompletedMilestones: 0,
    completedTrainingCoachesCount: 0,
  };

  const { coachesCount } = useGetCoachesCount({
    searchValue: '',
  });

  const { approvedStatusCount: approvedCount, total } = coachesCount || {};

  return (
    <S.Wrapper>
      <Row mb={7} mbT={5} inner jc="space-between" ai="center">
        <S.TableButtonWrapper>
          <S.TableTitleDescriptionWrapper>
            <T.H1 mb="8px">Coaches</T.H1>
            <T.P>
              Take Charge of Coaching: Manage, Accept, and Collaborate with
              Coaches.
            </T.P>
          </S.TableTitleDescriptionWrapper>

          {!isAdmin && (
            <BasicButton
              width="230px"
              handleClick={() => navigate(navRoutes.COMMON.ADD_COACHES)}
            >
              Setup a new coach
            </BasicButton>
          )}
        </S.TableButtonWrapper>
      </Row>

      <S.StatsWrapper>
        <T.H2 mb="16px">Statistics</T.H2>

        <StatsChartLayout>
          <StatsChart
            label="Total Coaches"
            statChartType="totalCount"
            value={total}
            backgroundMode="light"
          />
          <StatsChart
            statChartType="totalActiveUsers"
            value={approvedCount || 0}
            backgroundMode="dark"
          />

          <StatsChart
            statChartType="milestone"
            value={Number(totalCompletedMilestones)}
            backgroundMode="light"
          />

          <StatsChart
            statChartType="usersCompletedTrainingCount"
            value={completedTrainingCoachesCount}
            backgroundMode="dark"
          />
        </StatsChartLayout>
      </S.StatsWrapper>

      <BasicTable />
    </S.Wrapper>
  );
};

export default AllCoaches;
