import { useRef } from 'react';
import { string } from 'yup';

import { BasicInput } from '../../../../components/Inputs';
import { MediaKeyInput } from '../../../components/MediaKeyInput';
import * as T from '../../../../components/Typography';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import { mediaTypes } from '../../../../constants';

const initialData = {
  textAudio: '',
  textAudioKey: '',
};

const Draw = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateDataFields } = useStepForm(initialRef.current);
  const { data, validationErrs } = state;

  return (
    <div>
      <BasicInput
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        label="Title"
        error={validationErrs.textAudio}
        m={{ mt: 8 }}
        placeholder="Ex. 3"
      />

      <MediaKeyInput
        type={mediaTypes.TEXT_AUDIO}
        label="Title Audio"
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs.textAudioKey}
      />

      <T.P color="error" mt={6}>
        {validationErrs.general}
      </T.P>
    </div>
  );
};

const validationSchema = {
  textAudio: string().required(),
  textAudioKey: string().notRequired(),
};

export { validationSchema };
export default Draw;
