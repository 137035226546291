import { Button, Grid, Modal, Typography as T } from 'components';
import { audio } from '../../constants';
import { useGeneralState } from 'context/general-state';
import { getMediaUrl } from 'helpers';
import * as S from './style';

const WithAudio = ({
  visible,
  setIsModalVisible,
  title,
  titleAudioKey,
  description,
  descriptionAudioKey,
  onConfirm,
  onConfirmLoading,
  onConfirmDisabled,
  confirmText,
  confirmTextAudioKey,
  cancelText,
  cancelTextAudioKey,
  onCancel,
  maxWidth,
  httpError,
}) => {
  const { state: generalState } = useGeneralState();
  return (
    <Modal
      visible={visible}
      setIsModalVisible={setIsModalVisible}
      maxWidth={maxWidth}
    >
      <Grid.Row>
        <Grid.Col w={[4, 12, 12]} pr="32px">
          <div
            style={{
              fontSize: '20px',
              fontWeight: 700,
              color: '#F1EDF2',
              display: 'flex',
              alignItems: 'center',
              marginBottom: '16px',
            }}
          >
            <Button.PlayButton
              mr="16px"
              audioUrl={getMediaUrl(
                audio[titleAudioKey],
                true,
                generalState?.preferredVoice
              )}
              fallbackText={title}
            />
            {title}
          </div>
          {description && (
            <div
              style={{
                fontSize: '16px',
                marginBottom: '16px',
                color: '#F1EDF2',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Button.PlayButton
                mr="16px"
                variant="onlyIcon"
                audioUrl={getMediaUrl(
                  audio[descriptionAudioKey],
                  true,
                  generalState?.preferredVoice
                )}
                fallbackText={description}
              />
              {description}
            </div>
          )}
          <S.ButtonsWrapper>
            <Button.BasicButton
              id="aaa"
              variant="default"
              showSound
              soundBgColor="#050D30"
              bgColor="neutralMain"
              style={{
                height: '64px',
                minWidth: '100%',
              }}
              onClick={onConfirm}
              loading={onConfirmLoading}
              disabled={onConfirmDisabled}
              audioUrl={getMediaUrl(
                audio[confirmTextAudioKey],
                true,
                generalState?.preferredVoice
              )}
              fallbackText={confirmText}
            >
              {confirmText}
            </Button.BasicButton>
            {cancelText && (
              <Button.BasicButton
                mt="8px"
                showSound
                soundBgColor="#050D3080"
                soundColor="#FFFF"
                variant="default"
                bgColor="neutral30"
                style={{
                  height: '64px',
                  minWidth: '100%',
                }}
                onClick={onCancel}
                audioUrl={getMediaUrl(
                  audio[cancelTextAudioKey],
                  true,
                  generalState?.preferredVoice
                )}
                fallbackText={cancelText}
              >
                {cancelText}
              </Button.BasicButton>
            )}
            {httpError && (
              <T.P size="small" color="#ff8181" mt={4}>
                {httpError}
              </T.P>
            )}
          </S.ButtonsWrapper>
        </Grid.Col>
      </Grid.Row>
    </Modal>
  );
};

export default WithAudio;
