import { useRef } from 'react';

import * as T from '../../../../../components/Typography';
import { BasicInput, Dropdown } from '../../../../../components/Inputs';
import { MediaKeyInput } from '../../../../components/MediaKeyInput';
import { generateInitialCorrectOptions } from './utils';
import { convertSnakeCaseToSpaces } from '../../../../../helpers';
import {
  mediaTypes,
  typingHorizontalVariations,
} from '../../../../../constants';
import CorrectAnswer from './CorrectAnswer';
import { useStepForm } from 'CMS/Providers/StepFormProvider';

export { validationSchema } from './validation';

const initialData = {
  correctOptions: [
    {
      id: 0,
      option: '',
      type: '',
      suffix: '',
      prefix: '',
    },
  ],
  variation: '',
  textAudio: '',
  textAudioKey: '',
};

const TypingHorizontal = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateFormFields, updateDataFields } = useStepForm(
    initialRef.current
  );
  const { form, data, validationErrs } = state;

  const onVariationChange = (variation) => {
    updateDataFields({
      variation,
      correctOptions: generateInitialCorrectOptions(
        variation,
        data.correctOptions
      ),
    });
  };

  const variationOptions = Object.keys(
    typingHorizontalVariations.variations
  ).map((e) => ({
    label: convertSnakeCaseToSpaces(e),
    value: e,
  }));

  const onOptionChange = ({ key, value, id }) => {
    updateDataFields({
      correctOptions: data.correctOptions.map((e) => {
        if (e.id !== id) return e;
        return {
          ...e,
          [key]: value,
        };
      }),
    });
  };

  return (
    <div>
      <Dropdown
        label="Choose a variation"
        options={variationOptions}
        selected={
          data.variation
            ? {
                value: data.variation,
                label: convertSnakeCaseToSpaces(data.variation),
              }
            : null
        }
        handleChange={onVariationChange}
        error={validationErrs?.variation}
      />

      {!!data.variation && (
        <>
          <BasicInput
            label="Explainer Text"
            value={data.textAudio}
            handleChange={(textAudio) => updateDataFields({ textAudio })}
            m={{ mt: 8 }}
            error={validationErrs?.textAudio}
          />
          <MediaKeyInput
            label={`Explainer audio file Key`}
            type={mediaTypes.TEXT_AUDIO}
            value={data.textAudioKey}
            handleChange={(textAudioKey) => {
              updateDataFields({ textAudioKey });
            }}
            m={{ mt: 5 }}
            error={validationErrs?.textAudioKey}
          />

          <MediaKeyInput
            type={mediaTypes.IMAGE}
            label="Image Key"
            value={form.imageKey}
            handleChange={(imageKey) => {
              updateFormFields({ imageKey });
            }}
            m={{ mb: 5, mt: 8 }}
            error={validationErrs?.imageKey}
          />
          <>
            <T.P weight="bold" mt="8" ml="2">
              Answers:
            </T.P>

            {data.correctOptions.map((correctOption, index) => (
              <CorrectAnswer
                errors={validationErrs}
                onOptionChange={onOptionChange}
                correctOption={correctOption}
                index={index}
              />
            ))}
            {validationErrs?.correctOptions &&
              typeof validationErrs.correctOptions === 'string' && (
                <T.P mb="2" color="error">
                  {validationErrs.correctOptions}
                </T.P>
              )}
          </>
        </>
      )}
    </div>
  );
};

export default TypingHorizontal;
