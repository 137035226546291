import { useParams } from 'react-router-dom';
import { Button, Typography as T } from 'components';
import { audio, navRoutes as R } from '../../../constants';
import { useSiteSettings } from 'context/site-settings';
import { useGeneralState } from 'context/general-state';
import { getMediaUrl } from 'helpers';
import * as S from './style';

const DoYouHaveAccount = ({
  insideSignup,
  handleYes = () => {},
  handleNo = () => {},
  selectedOption,
  insideLearnerReferral,
}) => {
  const { anotherCourseName, isTpdCourse } = useSiteSettings();
  const { state: generalState } = useGeneralState();
  const { uniqueSlug } = useParams();

  return (
    <>
      <S.TitleWrapper insideSignup={insideSignup}>
        {!insideLearnerReferral && (
          <Button.PlayButton
            width={'56px'}
            height={'56px'}
            radius={12}
            color="neutralMain"
            iconProps={{
              height: '32px',
              width: '32px',
            }}
            audioUrl={getMediaUrl(
              isTpdCourse
                ? audio.doHaveACountMeInAccountAlready
                : audio.doHaveATurningPagesAccountAlready,
              true,
              generalState?.preferredVoice
            )}
            fallbackText={`Do you have a ${anotherCourseName} account already?`}
          />
        )}

        <T.H1>
          {insideLearnerReferral
            ? `Is this learner already enrolled in ${anotherCourseName} course?`
            : `Do you have a ${anotherCourseName} account already?`}
        </T.H1>
      </S.TitleWrapper>
      <S.ButtonsWrapper>
        <S.BtnWrapper>
          {!insideLearnerReferral && (
            <Button.PlayButton
              width={'56px'}
              height={'56px'}
              radius={12}
              color="neutralMain"
              iconProps={{
                height: '32px',
                width: '32px',
              }}
              audioUrl={getMediaUrl(
                audio.yes,
                true,
                generalState?.preferredVoice
              )}
              fallbackText="Yes"
            />
          )}
          <Button.BigButton
            variant="custom"
            icon="smallTick"
            text="Yes"
            {...(insideSignup
              ? {
                  selected: selectedOption === 'yes',
                  onClick: handleYes,
                }
              : {
                  to: R.LEARNER.SIGNUP_EXISTING_ACCOUNT.replace(
                    ':uniqueSlug',
                    uniqueSlug
                  ),
                })}
          />
        </S.BtnWrapper>
        <S.BtnWrapper>
          {!insideLearnerReferral && (
            <Button.PlayButton
              width={'56px'}
              height={'56px'}
              radius={12}
              color="neutralMain"
              iconProps={{
                height: '32px',
                width: '32px',
              }}
              audioUrl={getMediaUrl(
                audio.no,
                true,
                generalState?.preferredVoice
              )}
              fallbackText="No"
            />
          )}
          <Button.BigButton
            variant="custom"
            icon="cross"
            text="No"
            {...(insideSignup
              ? {
                  selected: selectedOption === 'no',
                  onClick: handleNo,
                }
              : {
                  to: R.LEARNER.SIGNUP.replace(':uniqueSlug', uniqueSlug),
                })}
          />
        </S.BtnWrapper>
      </S.ButtonsWrapper>
    </>
  );
};
export default DoYouHaveAccount;
