import set from 'lodash.set';

const handleValidationError = (error) => {
  const newErrors = {};
  if (error.inner)
    error.inner.forEach(({ path, message: errorMessage }) => {
      set(newErrors, path, errorMessage);
    });

  error.inner = newErrors;
  return error;
};

const validate = (schema, data, { abortEarly = false, ...options } = {}) => {
  try {
    const validData = schema.validateSync(data, {
      abortEarly,
      ...options,
    });
    return { data: validData };
  } catch (error) {
    const errorData = handleValidationError(error);
    throw errorData;
  }
};

export default validate;
