import { useState, useEffect } from 'react';
import { ClearButton } from '../../../../../components/Button';
import * as S from '../style';

const GridInput = ({ setEnableNext, correctAnswers, rowSize, columnSize }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [selectedSquares, setSelectedSquares] = useState([]);
  const correctFillSquares = correctAnswers[0]?.answer;

  useEffect(() => {
    setEnableNext(selectedSquares.length === Number(correctFillSquares));
  }, [selectedSquares, correctFillSquares, setEnableNext]);

  const handleSquareFill = (rowIndex, colIndex) => {
    const squareKey = `${rowIndex}-${colIndex}`;

    setSelectedSquares((prev) => {
      if (prev.includes(squareKey)) {
        return prev.filter((key) => key !== squareKey);
      }
      return [...prev, squareKey];
    });
  };

  const handleMouseDown = (rowIndex, colIndex) => {
    setIsDragging(true);
    handleSquareFill(rowIndex, colIndex);
  };

  const handleMouseEnter = (rowIndex, colIndex) => {
    if (isDragging) {
      handleSquareFill(rowIndex, colIndex);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <>
      {Array(Number(rowSize))
        .fill()
        .map((_, rowIndex) => (
          <S.Row key={rowIndex}>
            {Array(Number(columnSize))
              .fill()
              .map((_, colIndex) => (
                <S.Square
                  key={`${rowIndex}-${colIndex}`}
                  selected={selectedSquares.includes(`${rowIndex}-${colIndex}`)}
                  onMouseDown={() => handleMouseDown(rowIndex, colIndex)}
                  onMouseEnter={() => handleMouseEnter(rowIndex, colIndex)}
                  onMouseUp={handleMouseUp}
                />
              ))}
          </S.Row>
        ))}

      <ClearButton
        mt="4"
        hideIcon
        hideBorder
        handleClick={() => setSelectedSquares([])}
      />
    </>
  );
};

export default GridInput;
