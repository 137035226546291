import React from 'react';
import * as S from './style';
import Graphics from '../../../components/Graphics';
import Header from '../Header';
import Footer from '../Footer';
import { useAuth } from './../../../context/auth';

import { useMediaQuery } from 'react-responsive';

import theme from '../../../theme';
const Side = ({
  children,
  image,
  sideColor = 'neutralLight',
  sideChildren,
  headerProps = {},
  staticBg,
  ...props
}) => {
  const {
    user: { overlayColor },
  } = useAuth();

  const isTablet = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.tablet})`,
  });

  return (
    <S.Wrapper {...props} bgColor={overlayColor}>
      <Header {...headerProps} />
      <S.InnerWrapper {...props} bgColor={overlayColor}>
        <S.Content>
          <S.ContentBody>{children}</S.ContentBody>
        </S.Content>
        {!isTablet && (
          <Graphics
            image={image}
            bgColor={sideColor}
            minHeight="auto"
            staticBg={staticBg}
          >
            {sideChildren}
          </Graphics>
        )}
      </S.InnerWrapper>
      <Footer {...props} />
    </S.Wrapper>
  );
};

export default Side;
