import React from 'react';
import * as S from './style';
import * as T from 'components/Typography';
import { BasicButton } from 'components/Button';
import moment from 'moment';
import useCsvDownload from 'Hooks/useCsvDownload';
import { useParams } from 'react-router-dom';
import Milestones from './Milestones';

const ProgressReports = ({ latestProgressReport, stats }) => {
  const { userId } = useParams();
  const [data, handleClick] = useCsvDownload(
    `/csv/learners/${userId}/progress-reports`
  );

  return (
    <>
      <S.ProgressTitleWrapper>
        <div>
          <T.H2 size="med" mb="2">
            Progress reports
          </T.H2>
          <T.P>
            Last report:{' '}
            {moment(latestProgressReport?.createdAt).format('DD/MM/YYYY')}
          </T.P>
        </div>
        <BasicButton
          loading={data.loading}
          handleClick={handleClick}
          variant="secondary"
          maxWidth="277px"
          disabled={!latestProgressReport?.createdAt}
          icon={'export'}
        >
          Export progress reports
        </BasicButton>
      </S.ProgressTitleWrapper>
      <S.CardWrapper>
        <Milestones stats={stats} />
      </S.CardWrapper>
    </>
  );
};

export default ProgressReports;
