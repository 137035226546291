import styled from '@emotion/styled';
import { setMargin } from '../../helpers';

export const PlayButtonWrapper = styled.button`
  ${setMargin}
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  padding-left: 1rem;
`;

export const Wrapper = styled.div`
  width: 300px;
  position: relative;
  /* left: ${({ left }) => left || 'auto'};
  right: ${({ right }) => right || 'auto'}; */
  ${({ theme }) => theme.media.mobile} {
    /* left: 0;
    right: 0; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const CardWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryLight};
  border-radius: 16px;
  padding: 24px 16px 16px;
  max-width: 300px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const CompletedWrapper = styled.div`
  ${setMargin}
  display: flex;
  align-items: center;
  z-index: 10;
  padding-bottom: ${({ theme }) => theme.spacings[4]};
`;

export const BellIconWrapper = styled.div`
  position: absolute;
  top: -20px;
  right: 0px;
  left: 0px;
  margin-inline: auto;
  background-color: ${({ theme }) => theme.colors.primaryMain};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EndPathWrapper = styled.div`
  position: relative;
  /* left: ${({ left }) => `-${left}` || 'auto'}; */
  /* right: ${({ right }) => `-${right}` || 'auto'}; */
`;
