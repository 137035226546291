import { useState, useEffect } from 'react';

import * as S from './style';
import * as T from '../Typography';
import { BasicButton } from 'components/Button';
import { Col } from 'components/Grid';
import imageSrc from '../assets/Woman_graduating_during_pandemic2.png';
import { Button } from 'components';
import Icon from '../Icon';
import RateButtons from './RateButtons';
import { BasicInput } from 'components/Inputs';
import { Feedback } from 'api-calls';
import moment from 'moment';
import { useAuth } from 'context/auth';
import Success from './Success';

const isMoreThanOneMonth = (date) => {
  const lastPromptDate = date && moment(date);
  if (!lastPromptDate) return true;

  const today = moment();
  const diff = today.diff(lastPromptDate, 'days');
  if (diff >= 30) {
    return true;
  }

  return false;
};

const FeedbackModal = () => {
  const [feeling, setFeeling] = useState(null);
  const [challenges, setChallenges] = useState(null);
  const [suggestions, setSuggestions] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const { user } = useAuth();
  const feedbackKey = `FEEDBACK_PROMPT_DATE__${user?.id}`;
  const [showFeedbackFromFrontEnd, setShowFeedbackFromFrontEnd] =
    useState(false);

  const { mutateAsync: createFeedback, isLoading: isFeedbackLoading } =
    Feedback.useCreateFeedback();

  const onCloseFirstModal = () => {
    setIsModalOpen(false);
    localStorage.setItem(feedbackKey, JSON.stringify(new Date()));
  };

  const handleClick = async () => {
    const data = {
      feeling,
      challenges,
      suggestions,
    };

    await createFeedback(data);
    onCloseFirstModal();
    setIsSuccessModalOpen(true);
  };

  useEffect(() => {
    const feedbackMoreThanMonth = isMoreThanOneMonth(user?.latestFeedbackDate);
    const signedUpMoreThanMonth = isMoreThanOneMonth(user?.createdAt);
    const shouldShowFeedback =
      showFeedbackFromFrontEnd &&
      feedbackMoreThanMonth &&
      signedUpMoreThanMonth;
    if (shouldShowFeedback) {
      setIsModalOpen(true);
    }
  }, [user?.latestFeedbackDate, showFeedbackFromFrontEnd, user?.createdAt]);

  useEffect(() => {
    const lastPromptDate = localStorage.getItem(feedbackKey);
    const showFeedbackFromFrontEnd = isMoreThanOneMonth(lastPromptDate);
    setShowFeedbackFromFrontEnd(showFeedbackFromFrontEnd);
  }, [feedbackKey]);

  return (
    <>
      <S.Modal open={isModalOpen} onClose={onCloseFirstModal} role="dialog">
        <S.Wrapper>
          <S.Header>
            <Button.IconButton handleClick={onCloseFirstModal}>
              <Icon icon="close" text="Cancel" />
            </Button.IconButton>
          </S.Header>

          <S.CoverWrapper>
            <S.TabImage src={imageSrc} />
            <S.Content>
              <Col w={[4, 12, 12]}>
                <T.H1 color="neutralMain" weight="bold">
                  Thank you for your ongoing work!
                </T.H1>
                <T.P mt="1" color="neutral80">
                  Expressing heartfelt gratitude to our coach for their ongoing
                  dedication and impact. Thank you!
                </T.P>
              </Col>
            </S.Content>
          </S.CoverWrapper>
          <S.DividingLine />

          <S.QuestionsSection>
            <T.H2 color="neutralMain" mb={5}>
              We would be happy to hear from you
            </T.H2>
            <T.P color="neutralMain" display="inline" weight="bold">
              Are you enjoying being a {user.role?.toLowerCase()}?{' '}
              <T.P color="neutral70" display="inline">
                (Optional)
              </T.P>
            </T.P>
            <RateButtons feeling={feeling} setFeeling={setFeeling} />
            <S.DividingLine />
            <BasicInput
              label="What challenges do you face?"
              placeholder="e.g. Are they only available on weekends or certain weekdays/times?"
              value={challenges}
              handleChange={(v) => setChallenges(v)}
              optional
            />
            <S.DividingLine />
            <BasicInput
              label="What could make things better for you?"
              placeholder="e.g. Are they only available on weekends or certain weekdays/times?"
              value={suggestions}
              handleChange={(v) => setSuggestions(v)}
              optional
            />
          </S.QuestionsSection>

          <S.Footer>
            <BasicButton
              className="next"
              handleClick={handleClick}
              variant="primary"
              icon="next"
              maxWidth="180px"
              loading={isFeedbackLoading}
              disabled={!feeling && !challenges && !suggestions}
            >
              <T.P color="white" weight="semi">
                Submit
              </T.P>
            </BasicButton>
            <S.CancelButton
              className="next"
              handleClick={onCloseFirstModal}
              variant="tertiary"
              maxWidth="180px"
              mt={4}
            >
              <T.P color="neutralMain" weight="semi">
                Cancel
              </T.P>
            </S.CancelButton>
          </S.Footer>
        </S.Wrapper>
      </S.Modal>
      <Success
        isModalOpen={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
      />
    </>
  );
};

export default FeedbackModal;
