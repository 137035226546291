import {
  Grid,
  Typography as T,
  ProgressCard,
  Loading,
} from '../../../components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/auth';
import { navRoutes } from '../../../constants';
import { BasicButton } from 'components/Button';
import * as S from './style';
import { JoinOurCommunity } from 'components/JoinOurCommunity';
import { Divider } from 'components/Divider';
import { FurtherResources } from 'components/FurtherResources';
import { useGetAllCustomTrainingResources } from 'api-calls/custom-training.queries';
import PlayWithTheTool from 'components/PlayWithTheTool';
import useFacilitatorTrainingData from './Hooks/useFacilitatorTrainingData';
import useFacilitatorCompletedTrainingStep from './Hooks/useFacilitatorCompletedTrainingStep';

const { Row, Col } = Grid;

const TrainingReview = () => {
  const {
    user: { id, organisationId },
  } = useAuth();
  const navigate = useNavigate();

  const { customTrainingResources } = useGetAllCustomTrainingResources({
    organisationId,
  });
  const { trainingData } = useFacilitatorTrainingData();
  const { completedTrainingStep, isSuccess, getCurrentStepLoading } =
    useFacilitatorCompletedTrainingStep();

  useEffect(() => {
    if (isSuccess && completedTrainingStep < trainingData.length)
      return navigate(
        navRoutes.FACILITATOR.TRAINING_STEP.replace(
          ':stepId',
          completedTrainingStep + 1
        )
      );
  }, [completedTrainingStep, isSuccess, navigate, trainingData.length]);

  if (getCurrentStepLoading) {
    return <Loading />;
  }

  return (
    <>
      <Row dir="column" jc="center">
        <Col w={[4, 10, 10]}>
          <T.H1 weight="bold" color="neutralMain" mb="2">
            Facilitator training
          </T.H1>
          <T.P mb="5">
            Here is everything you need to know in order to deliver Turning
            Pages in your organisation
          </T.P>
          <ProgressCard
            loading={getCurrentStepLoading}
            id={id}
            activeStep={completedTrainingStep}
            data={trainingData}
            completionMessage="🎉 Training complete"
          />
        </Col>
        <Col w={[4, 10, 10]}>
          <Divider />
        </Col>
        <Col w={[4, 10, 10]}>
          <S.Card bg="secondaryLight">
            <Row inner>
              <Col w={[4, 12, 12]}>
                <T.H2 weight="bold" color="neutralMain" mb="4">
                  Coach training
                </T.H2>
                <T.P>
                  Here you can review and manage the training and resources your
                  coaches use to deliver Tuning Pages fot your organization.
                </T.P>
              </Col>
            </Row>
            <Row mt="4" inner>
              <Col w={[4, 12, 12]}>
                <BasicButton
                  variant="primary"
                  to={navRoutes.FACILITATOR.TRAINING_CUSTOMIZATION}
                  aria-label="Manage Coach Training"
                  type="submit"
                  id="manage-coach-training"
                >
                  Manage Coach Training
                </BasicButton>
              </Col>
            </Row>
          </S.Card>
        </Col>
        <Col w={[4, 10, 10]}>
          <Divider />
        </Col>
        <Col w={[4, 10, 10]}>
          <JoinOurCommunity />
        </Col>
        <Col w={[4, 10, 10]}>
          <Divider />
        </Col>
        <Col w={[4, 10, 10]}>
          <PlayWithTheTool />
        </Col>
        <Col w={[4, 10, 10]}>
          <Divider />
        </Col>
        <Col w={[4, 10, 10]}>
          <FurtherResources customTrainingResources={customTrainingResources} />
        </Col>
      </Row>
    </>
  );
};

export default TrainingReview;
