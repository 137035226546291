import styled from '@emotion/styled';

export const RateButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;

  ${({ theme }) => theme.media.mobile} {
    flex-wrap: wrap;
  }
`;
export const RadioLabel = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  ${({ variant }) => variant === 'clickable' && 'cursor: pointer;'}
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutral80};
  width: 100%;
  flex: 1;
  padding: 16px;

  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.primaryMain : 'transparent'};
  background: ${({ theme, selected }) =>
    selected ? theme.colors.neutralSurface : 'transparent'};

  ${({ variant }) =>
    variant === 'clickable' &&
    `&:hover,
      &:focus {
        border: 1px solid ${({ theme }) => theme.colors.primaryMain};
        background: ${({ theme }) => theme.colors.neutralSurface};
      }`}

  ${({ theme }) => theme.media.mobile} {
    min-width: 40%;
  }
`;

export const RadioInput = styled.input`
  display: none;
`;
