import React from 'react';
import * as S from './style';
import * as T from '../Typography';

const YoutubeVid = ({
  videoUrl,
  videoId,
  onVideoEnd,
  autoPlay = 1,
  ...props
}) => {
  // Get videoId from the URL supporting different youtube url versions
  const videoIdRegex =
    /(?:youtu\.be\/|youtube\.com\/(?:embed\/|watch\?v=|v\/|.+&v=|live\/))([^&?/]+)/;

  // Extract the video ID from the YouTube URL
  const _videoId = videoId || videoUrl.match(videoIdRegex)?.[1];

  const opts = {
    width: '100%',
    height: '100%',
    playerVars: {
      autoplay: autoPlay,
    },
  };

  const handleVideoEnd = (event) => {
    if (onVideoEnd) {
      onVideoEnd();
    }
  };

  if (!_videoId) {
    return (
      <S.VideoContainer>
        <T.P>Invalid YouTube URL</T.P>
      </S.VideoContainer>
    );
  }

  return (
    <S.VideoContainer>
      <S.StyledYouTube
        videoId={_videoId}
        opts={opts}
        onEnd={handleVideoEnd}
        {...props}
      />
    </S.VideoContainer>
  );
};

export default YoutubeVid;
