import * as T from '../../Typography';
import * as S from './style';
import NewLearnerHelloImg from '../../assets/new_learner_hello.png';
import { Icon } from 'components';
import { useGetLearnerUniqueId } from 'api-calls/learners.queries';

const CoachFirstMsg = ({ learnerUserId, showTip }) => {
  const { data: learner, isSuccess } = useGetLearnerUniqueId(
    { learnerUserId },
    { enabled: !!learnerUserId }
  );

  return (
    <S.Wrapper>
      <S.ImgWrapper>
        <img
          src={NewLearnerHelloImg}
          alt="Hello new learner"
          width={96}
          height={96}
        />
      </S.ImgWrapper>
      <T.H1 mt="4">Say Hello!</T.H1>
      <T.P mt="2">
        This is the beginning of your chat with{' '}
        <strong>
          {isSuccess
            ? `${learner?.firstName} (${learner?.learnerUniqueId})`
            : '...'}
        </strong>
      </T.P>
      {showTip && (
        <S.TipWrapper>
          <Icon icon="alert" color="currentColor" width="24" height="24" />
          <T.P color="inherit">
            Say a bit about yourself, suggest potential dates and times to meet
            and ask how they might like to meet (online / in person).
            <br />
            For the first message we recommend sending a voice recording.
          </T.P>
        </S.TipWrapper>
      )}
    </S.Wrapper>
  );
};

export default CoachFirstMsg;
