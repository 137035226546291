import { useRef } from 'react';
import { Typography as T } from '../../../../../components';
import { BasicInput, Dropdown } from '../../../../../components/Inputs';
import { MediaKeyInput } from '../../../../components/MediaKeyInput';
import { formulaTypes, mediaTypes } from '../../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import * as S from './style';
import { convertSnakeCaseToSpaces, getOperatorSymbol } from 'helpers';

export { validationSchema } from './validation';

const initialData = {
  textAudio: '',
  textAudioKey: '',
  inputsPerRow: 1,
  formulaType: formulaTypes.SUM,
  rows: [
    [{ id: 0, value: '', rowIndex: 0 }],
    [{ id: 0, value: '', rowIndex: 0 }],
  ],
  answers: [{ id: 0, value: '' }],
};

const Formulae = () => {
  const initialRef = useRef(initialData);
  const { state, updateDataFields, updateFormMetaData } = useStepForm(
    initialRef.current
  );
  const { data = initialData, validationErrs } = state;

  const handleInputsPerRow = ({ inputsPerRow = 1 }) => {
    if (inputsPerRow < 1 || inputsPerRow > 5) {
      updateFormMetaData({
        validationErrs: {
          ...validationErrs,
          inputsPerRow: 'Inputs per row must more than 1 and under 5',
        },
      });
      updateDataFields({ inputsPerRow });

      return;
    }

    const rowSize = 2;
    const newRows = [];

    Array.from({ length: rowSize }).forEach((_, rowIndex) => {
      const newRowInputs = [];
      Array.from({ length: inputsPerRow }).forEach((_, currentIndex) => {
        newRowInputs.push({
          id: currentIndex,
          value: data.rows?.[rowIndex]?.[currentIndex]?.value ?? '',
          rowIndex,
        });
        currentIndex += 1;
      });
      newRows.push(newRowInputs);
    });

    const newAnswers = Array.from(
      { length: inputsPerRow },
      (_, currentIndex) => ({
        id: currentIndex,
        value: '',
      })
    );

    updateDataFields({
      rows: newRows,
      inputsPerRow,
      answers: newAnswers,
    });
    updateFormMetaData({
      validationErrs: {
        ...validationErrs,
        inputsPerRow: '',
      },
    });
  };

  const onRowInputChange = ({ value, id, rowIndex }) => {
    const newInputsRows = data.rows?.[rowIndex]?.map((e) => {
      if (e.id !== id) return e;
      return {
        ...e,
        value,
      };
    });
    const newRows = [...data.rows];
    newRows[rowIndex] = newInputsRows;
    updateDataFields({ rows: newRows });
  };

  const onAnswerChange = ({ value, id }) => {
    const newAnswers = data.answers?.map((e) => {
      if (e.id !== id) return e;
      return {
        ...e,
        value,
      };
    });
    updateDataFields({ answers: newAnswers });
  };

  const formulaTypesOptions = Object.keys(formulaTypes).map((e) => ({
    label: convertSnakeCaseToSpaces(e),
    value: e,
  }));

  return (
    <div>
      <BasicInput
        label="Explainer Text"
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        m={{ mt: 8 }}
        error={validationErrs?.textAudio}
      />
      <MediaKeyInput
        type={mediaTypes.TEXT_AUDIO}
        label="Explainer Text file Key"
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs?.textAudioKey}
      />

      <BasicInput
        label="Inputs Per Row"
        value={data.inputsPerRow}
        handleChange={(inputsPerRow) => {
          handleInputsPerRow({
            inputsPerRow: Number(inputsPerRow),
          });
        }}
        m={{ mt: 8 }}
        type="number"
        error={validationErrs.inputsPerRow}
      />

      <Dropdown
        label="Choose a formula type"
        options={formulaTypesOptions}
        selected={
          data.formulaType
            ? {
                value: data.formulaType,
                label: convertSnakeCaseToSpaces(data.formulaType),
              }
            : initialData.formulaType
        }
        handleChange={(formulaType) => updateDataFields({ formulaType })}
        error={validationErrs?.formulaType}
        m={{ mt: 5 }}
      />

      <T.P mt={8} mb={4} size="large" weight="bold">
        Formula
      </T.P>
      <S.RowsWrapper jc="space-between">
        {data.rows?.map((rowInputs, rowIndex) => (
          <S.RowWrapper key={rowIndex} inputsPerRow={data.inputsPerRow}>
            {rowIndex === data.rows.length - 1 && data.formulaType && (
              <BasicInput
                value={getOperatorSymbol(data.formulaType)}
                w="80px"
                bgColor="neutralSurface"
                readOnly
              />
            )}
            <S.RowInputsWrapper inputsPerRow={data.inputsPerRow}>
              {rowInputs?.map((input) => {
                const id = input.id;
                const error =
                  validationErrs?.rows?.[rowIndex]?.value || undefined;

                return (
                  <BasicInput
                    key={id}
                    placeholder=""
                    value={input.value}
                    handleChange={(value) => {
                      onRowInputChange({
                        value,
                        id,
                        rowIndex,
                      });
                    }}
                    error={error}
                    hideErrorMsg
                  />
                );
              })}
            </S.RowInputsWrapper>
          </S.RowWrapper>
        ))}
      </S.RowsWrapper>

      <T.P mt={8} mb={4} size="large" weight="bold">
        Answer
      </T.P>
      <S.RowWrapper inputsPerRow={data.inputsPerRow}>
        <S.RowInputsWrapper inputsPerRow={data.inputsPerRow}>
          {data.answers?.map((answer, answerIndex) => {
            const id = answer?.id;
            const error =
              validationErrs?.answers?.[answerIndex]?.value || undefined;

            return (
              <BasicInput
                key={id}
                placeholder=""
                value={answer?.value}
                handleChange={(value) => {
                  onAnswerChange({
                    value,
                    id,
                  });
                }}
                error={error}
                hideErrorMsg
              />
            );
          })}
        </S.RowInputsWrapper>
      </S.RowWrapper>
    </div>
  );
};

export default Formulae;
