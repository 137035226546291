import * as T from '../../../components/Typography';
import * as S from './style';
import { BackButton } from 'components/BackButton';
import { Button } from 'components';
import { courseCodeTypes } from 'constants';
import { Users } from 'api-calls';
import { useState } from 'react';
import { useSiteSettings } from 'context/site-settings';
import { useSearchParams } from 'react-router-dom';
import { getMediaUrl } from 'helpers';
import { audio } from 'constants';
import { useGeneralState } from 'context/general-state';

const SwitchCourse = () => {
  const { courseCode } = useSiteSettings();
  const [error, setError] = useState('');
  const { mutate: switchCourse, isLoading } = Users.useSwitchToCourse();
  const [searchParams] = useSearchParams();
  const showAudio = searchParams.get('showAudio') === 'true';
  const { state: generalState } = useGeneralState();

  const submit = (course) => {
    setError('');
    switchCourse(
      { courseCode: course },
      {
        onSuccess: (data) => {
          if (data?.redirectUrl) {
            window.location.href = data.redirectUrl;
          } else {
            setError('Something went wrong');
          }
        },
        onError: (error) => {
          setError(error.message);
        },
      }
    );
  };

  return (
    <S.Wrapper>
      <BackButton />

      <S.TitleWrapper>
        {showAudio && (
          <Button.PlayButton
            mr="16px"
            width="32px"
            height="32px"
            radius={12}
            backgroundColor="transparent"
            withoutButtonStyle
            iconProps={{ width: 27.2 }}
            audioUrl={getMediaUrl(
              audio.whichCourseDoYouWantToView,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="Which course do you want to view?"
          />
        )}
        <T.H1>
          {showAudio ? 'Which course do you want to view?' : 'Switch course'}
        </T.H1>
      </S.TitleWrapper>
      <S.ButtonWrapper>
        <S.BtnWrapperSameLine>
          {showAudio && (
            <Button.PlayButton
              width={'56px'}
              height={'56px'}
              radius={12}
              color="neutralMain"
              iconProps={{
                height: '32px',
                width: '32px',
              }}
              audioUrl={getMediaUrl(
                audio.turningPages,
                true,
                generalState?.preferredVoice
              )}
              fallbackText="Turning Pages"
            />
          )}
          <Button.BigButton
            variant="custom"
            icon="openedBook"
            text="Turning Pages"
            handleClick={() => submit(courseCodeTypes.TPD)}
            loading={isLoading && courseCode !== courseCodeTypes.TPD}
            disabled={isLoading || courseCode === courseCodeTypes.TPD}
            selected={courseCode === courseCodeTypes.TPD}
          />
        </S.BtnWrapperSameLine>

        <S.BtnWrapperSameLine>
          {showAudio && (
            <Button.PlayButton
              width={'56px'}
              height={'56px'}
              radius={12}
              color="neutralMain"
              iconProps={{
                height: '32px',
                width: '32px',
              }}
              audioUrl={getMediaUrl(
                audio.countMeIn,
                true,
                generalState?.preferredVoice
              )}
              fallbackText="Count Me In"
            />
          )}
          <Button.BigButton
            variant="custom"
            icon="cmidIcon"
            text="Count Me In"
            handleClick={() => submit(courseCodeTypes.CMID)}
            loading={isLoading && courseCode !== courseCodeTypes.CMID}
            disabled={isLoading || courseCode === courseCodeTypes.CMID}
            selected={courseCode === courseCodeTypes.CMID}
          />
        </S.BtnWrapperSameLine>
      </S.ButtonWrapper>
      <S.ErrorWrapper>
        <T.P color="error">{error}</T.P>
      </S.ErrorWrapper>
    </S.Wrapper>
  );
};
export default SwitchCourse;
