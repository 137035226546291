import { factFamilyVariations } from '../../../../../constants';

export const OPERATORS = {
  ADD: '+',
  SUBTRACT: '-',
  MULTIPLY: 'x',
  MULTIPLY2: '*',
  DIVIDE: '/',
};
const defaultOption = { option: '', showValue: true };
export const defaultNumbers = [
  { ...defaultOption, id: 0 },
  { ...defaultOption, id: 1 },
  { ...defaultOption, id: 2 },
];

export const generateInitialOptions = (variation, existingOptions) => {
  let options = existingOptions;

  switch (variation) {
    case factFamilyVariations.variations.PYRAMID:
      options = [
        {
          id: 0,
          operator: '',
          numbers: defaultNumbers,
        },
        {
          id: 1,
          operator: OPERATORS.ADD,
          numbers: defaultNumbers,
        },
      ];
      break;
    case factFamilyVariations.variations.FLAT:
      options = [
        {
          id: 0,
          operator: OPERATORS.MULTIPLY,
          numbers: defaultNumbers,
        },
      ];
      break;

    default:
      options = [];
      break;
  }

  return options;
};

export const generateAddOptionInitial = (variation, existingOptions = []) => {
  const maxId = Math.max(...existingOptions.map((e) => e.id), 0);

  let option = {};
  switch (variation) {
    case factFamilyVariations.variations.PYRAMID:
      option = {
        id: maxId + 1,
        numbers: defaultNumbers,
        operator: maxId >= 2 ? OPERATORS.SUBTRACT : OPERATORS.ADD,
      };
      break;
    case factFamilyVariations.variations.FLAT:
      option = {
        id: maxId + 1,
        numbers: defaultNumbers,
        operator: maxId >= 1 ? OPERATORS.DIVIDE : OPERATORS.MULTIPLY,
      };
      break;

    default:
      throw new Error('Invalid variation');
  }

  return option;
};
