import { BackButton } from 'components/BackButton';
import * as S from './style';
import DoYouHaveAccount from './DoYouHaveAccount';

const HaveAccount = () => {
  return (
    <S.Wrapper>
      <BackButton />
      <DoYouHaveAccount />
    </S.Wrapper>
  );
};
export default HaveAccount;
