import * as Yup from 'yup';
import validate from '../../../validation/validate';

const validationSchema = Yup.object({
  description: Yup.string().required('Description is required'),

  tags: Yup.array()
    .of(Yup.string().required('Tags must be strings'))
    .min(1, 'At least one media tag is required'),

  isMaleRequired: Yup.boolean().test(
    'isMaleRequired-check',
    'At least one of Male or Female options must be selected',
    function (value) {
      return value || this.parent.isFemaleRequired;
    }
  ),

  isFemaleRequired: Yup.boolean().test(
    'isFemaleRequired-check',
    'At least one of Male or Female options must be selected',
    function (value) {
      return value || this.parent.isMaleRequired;
    }
  ),
});

export const validateVo = (data) => validate(validationSchema, data);
