import styled from '@emotion/styled';
import { setMargin } from 'helpers';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  z-index: 99;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const QuickAccessWrapper = styled.div`
  width: 100%;
  margin-block: 32px;
  padding: ${({ theme }) => theme.spacings[5]};
  background-color: ${({ theme }) => theme.colors.neutralSurface};
  border-radius: 12px;
`;

export const HeaderWrapper = styled.div`
  ${setMargin}
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: stretch;
  }
`;
