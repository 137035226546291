import { useFetch } from '../utils/queries';
import { SITE_SETTINGS } from '../constants';

function useGetSiteSettings(_, options = {}) {
  const queryKey = ['SITE_SETTINGS'];
  const context = useFetch({
    key: queryKey,
    url: SITE_SETTINGS,
    reactQueryConfig: options,
  });
  return { ...context };
}

export { useGetSiteSettings };
