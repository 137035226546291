import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding-bottom: 32px;
`;

export const ImgWrapper = styled.div`
  @media (max-width: 580px) {
    display: none;
  }
`;

export const TipWrapper = styled.div`
  display: flex;
  padding: 12px;
  margin-top: 16px;
  align-items: flex-start;
  gap: 12px;
  border-radius: 16px;
  color: ${({ theme }) => theme.colors.primaryMain};
  background: ${({ theme }) => theme.colors.neutralSurface};
`;
