import React, { createContext, useContext } from 'react';
import { useGetExercises } from '../../api-calls/exercises.queries';

const ExercisesContext = createContext();

export const useExercises = () => {
  return useContext(ExercisesContext);
};

export const ExercisesProvider = ({ children }) => {
  const { data: exercises, isLoading } = useGetExercises({ cms: true });

  const value = {
    exercises,
    isLoading,
  };

  return (
    <ExercisesContext.Provider value={value}>
      {children}
    </ExercisesContext.Provider>
  );
};
