import { useEffect, useState } from 'react';
import * as T from '../../../../components/Typography';
import { PlayButton } from '../../../../components/Button';
import { Row } from '../../../../components/Grid';
import StepFooterButtons from '../StepFooterButtons';
import { getMediaUrl } from '../../../../helpers';
import { useGeneralState } from '../../../../context/general-state';
import { audio } from '../../../../constants';
import AnswerInput from './AnswerInput';
import * as S from './style';

const TypingHorizontal = ({
  data: { variation, textAudioUrl, textAudio, correctOptions } = {},
  imageUrl,
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  isLoading,
}) => {
  const [showRotateMsg, setShowRotateMsg] = useState(false);
  const [enableNext, setEnableNext] = useState(false);
  const [userAnswers, setUserAnswers] = useState(
    new Array(correctOptions?.length).fill('')
  );
  const { state } = useGeneralState();

  useEffect(() => {
    const ro = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setShowRotateMsg(
          entry.contentRect.height > entry.contentRect.width &&
            entry.contentRect.width < 790
        );
      }
    });
    ro.observe(document.body);
  }, []);

  const correctAnswers = correctOptions?.map((co) => {
    return { ...co, answer: co.option };
  });

  const isCorrectAnswersProvided =
    correctAnswers?.length > 0 && correctAnswers.every((ans) => !!ans?.answer);

  useEffect(() => {
    if (isCorrectAnswersProvided) {
      const allAnswersCorrect = userAnswers.every(
        (text, index) => text?.toLowerCase() === correctAnswers[index]?.answer
      );
      setEnableNext(allAnswersCorrect);
    }
  }, [userAnswers, isCorrectAnswersProvided, correctAnswers, setEnableNext]);

  if (!variation) {
    return (
      <S.Wrapper>
        <Row jc="center" mb={6}>
          <T.P color="error" m="0" mt="1">
            Please choose a variation
          </T.P>
        </Row>
      </S.Wrapper>
    );
  }

  return (
    <>
      <S.Wrapper display={showRotateMsg ? 'none' : 'flex'}>
        {textAudioUrl ? (
          <PlayButton
            audioUrl={textAudioUrl}
            iconProps={{ width: '32px', height: '32px' }}
            padding="12px"
            weight="regular"
            mx="auto"
            mxM="0"
          >
            {textAudio}
          </PlayButton>
        ) : (
          <T.P size="med">{textAudio}</T.P>
        )}

        <S.ImageWrapper>
          <S.Img src={imageUrl} alt={textAudio} />
        </S.ImageWrapper>

        <S.Row>
          <S.AnswersWrapper>
            {correctAnswers.map((correctAnswer, index) => (
              <AnswerInput
                index={index}
                setUserAnswers={setUserAnswers}
                userAnswers={userAnswers}
                correctAnswer={correctAnswer}
              />
            ))}
          </S.AnswersWrapper>
          <S.ButtonWrapper>
            <StepFooterButtons
              stuckAtReview={stuckAtReview}
              handleNext={handleNext}
              preview={preview}
              disabled={disabled || preview}
              enableNext={enableNext}
              isLoading={isLoading}
            />
          </S.ButtonWrapper>
        </S.Row>
      </S.Wrapper>
      <S.RotateWrapper display={showRotateMsg ? 'flex' : 'none'}>
        <PlayButton
          audioUrl={getMediaUrl(
            audio.pleaseRotateYourScreen,
            true,
            state?.preferredVoice
          )}
          iconProps={{ width: '32px', height: '32px' }}
          padding="12px"
          mt={5}
          mx="auto"
          mxM="0"
        >
          Please rotate your screen to landscape for this exercise
        </PlayButton>
      </S.RotateWrapper>
    </>
  );
};

export default TypingHorizontal;
