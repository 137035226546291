import { BasicButton } from '../../../components/Button';

import { navRoutes } from '../../../constants';
import Table from './Table';
import { useExercises } from 'CMS/Providers/ExercisesProvider';

const ViewExercises = () => {
  const { exercises, isLoading } = useExercises();

  return (
    <div>
      <Table data={exercises} loading={isLoading} />

      <div style={{ maxWidth: '300px' }}>
        <BasicButton
          to={navRoutes.CMS.CREATE_EXERCISES}
          mt={5}
          loading={isLoading}
        >
          Create new exercise
        </BasicButton>
      </div>
    </div>
  );
};

export default ViewExercises;
