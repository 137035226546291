import styled from '@emotion/styled';
import { Row, Col } from '../../../components/Grid';

export const Form = styled.form`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacings[6]};
  display: flex;
  flex-direction: column;
  flex: 1;
  ${({ theme }) => theme.media.tablet} {
    padding-top: ${({ theme }) => theme.spacings[5]};
  }
  ${({ theme }) => theme.media.mobile} {
    padding-top: ${({ theme }) => theme.spacings[4]};
  }
  max-width: 500px;
  margin: 0 auto;
`;

export const ButtonsWrapper = styled(Row)`
  flex: 0;
  align-items: flex-end;
  ${({ theme }) => theme.media.tablet} {
    flex: 1;
  }
`;

export const ButtonContainer = styled(Col)`
  align-items: flex-end;
`;

export const LinkButton = styled.button`
  all: unset;
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.placeholder : theme.colors.primaryMain};
  text-decoration: underline;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
