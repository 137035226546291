export const getFirstUncompletedExercise = (milestones) => {
  let firstUncompletedEx;
  let order = 1;
  let firstSavedExercise;
  milestones.forEach((m) => {
    m.exercises.forEach((e) => {
      if (
        !e.exerciseComplete &&
        !e.savedExercise &&
        !firstUncompletedEx &&
        !m.completed
      ) {
        firstUncompletedEx = e;
      }
      if (e.savedExercise && !firstSavedExercise) {
        firstSavedExercise = e;
      }
      e.order = order;
    });
  });
  if (!firstUncompletedEx) {
    firstUncompletedEx = firstSavedExercise;
  }
  if (!firstUncompletedEx) {
    firstUncompletedEx = milestones[0]?.exercises[0];
  }

  return firstUncompletedEx;
};

const getLastCompletedMilestone = (milestones) => {
  let lastCompletedMilestone;

  milestones?.forEach((m) => {
    if (m?.completed) {
      lastCompletedMilestone = m;
    }
  });

  return lastCompletedMilestone;
};

export const getActiveExWithFormattedMilestones = ({
  milestones = [],
  lastCompletedExId,
  milestoneIdToMoveTo,
}) => {
  if (milestoneIdToMoveTo) {
    const activeExercise = milestones.find(
      (m) => Number(m.milestoneId) === Number(milestoneIdToMoveTo)
    )?.exercises[0];
    return { milestones, activeExercise };
  }

  const lastCompletedMilestone = getLastCompletedMilestone(milestones);
  let activeExercise =
    lastCompletedMilestone?.exercises[0] || milestones[0]?.exercises[0];

  let _milestones = [...milestones];
  let firstUncompletedEx = getFirstUncompletedExercise(milestones);

  activeExercise = firstUncompletedEx;

  return { milestones: _milestones, activeExercise, lastCompletedMilestone };
};

export const getProgress = ({
  milestones,
  activeExercise,
  setMilestoneIndex,
  setProgress,
}) => {
  milestones?.forEach((m) => {
    m?.exercises?.forEach((e, i) => {
      if (e.id === activeExercise?.id) {
        setProgress((prev) => ({
          ...prev,
          total: m.exercises?.length,
          complete: i,
        }));
        setMilestoneIndex(m.milestoneId);
      }
    });
  });
};
