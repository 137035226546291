import { formulaTypes } from 'constants';
import { string, array, object, number } from 'yup';

export const validationSchema = {
  textAudio: string().required('Explainer Text is required'),
  textAudioKey: string().required('Explainer Text file Key is required'),
  inputsPerRow: number()
    .positive('Inputs per row must be a positive number')
    .integer('Inputs per row must be an integer')
    .min(1, 'Inputs per row must be more than 1 and less than 5')
    .max(5, 'Inputs per row must be more than 2 and less than 5')
    .required('Inputs per row is required'),
  formulaType: string()
    .oneOf(
      Object.values(formulaTypes),
      `formula type must be one of ${Object.values(formulaTypes).join(', ')}`
    )
    .required('formula type is required'),
  rows: array()
    .of(
      array()
        .of(
          object().shape({
            id: number()
              .required('id is required')
              .integer('id must be an integer'),
            value: string()
              .required('value is required')
              .min(1, 'value cannot be an empty string'), // Ensures value is not an empty string
            rowIndex: number()
              .required('rowIndex is required')
              .integer('rowIndex must be an integer'),
          })
        )
        .min(1, 'Each row must contain at least one item')
    )
    .required('rows is required')
    .min(2, 'rows must have at least 2 rows'),
  answers: array()
    .of(
      object().shape({
        id: number().required('id is required').integer(),
        value: string()
          .required('value is required')
          .min(1, 'answer value cannot be an empty string'),
      })
    )
    .required('answers is required')
    .min(1, 'answers must have at least one answer'),
};
