import { Spin } from 'antd';
import styled from '@emotion/styled';
import setMargin from './../../../helpers/set-margin';

export const decideBackgroundBig = (variant, theme, selected) => {
  switch (variant) {
    case 'withCoach':
      return theme.colors.neutralMain;
    case 'byYourSelf':
      return theme.colors.neutralLightNew;
    case 'custom':
      if (selected) {
        return theme.colors.neutralMain;
      }
      return theme.colors.neutralLightNew;
    default:
      return 'transparent';
  }
};

export const decideColorBig = (variant, theme, selected) => {
  switch (variant) {
    case 'byYourSelf':
      return theme.colors.neutralMain;
    case 'custom':
      if (selected) {
        return theme.colors.neutralSurface;
      }
      return theme.colors.neutralMain;
    case 'withCoach':
      return theme.colors.neutralSurface;
    default:
      return theme.colors.white;
  }
};

export const decidePaddingTopAndBottom = (variant, theme) => {
  switch (variant) {
    case 'byYourSelf':
      return '36px';
    case 'withCoach':
      return '30px';
    case 'custom':
      return '24px';
    default:
      return theme.spacings[2];
  }
};

export const TextWrapper = styled.div`
  text-align: center;
  padding-top: ${({ variant, theme }) =>
    variant === 'custom' ? '8px' : theme.spacings[3]};
  padding-left: ${({ theme }) => theme.spacings[5]};
  padding-right: ${({ theme }) => theme.spacings[5]};
`;

export const decideBackgroundHoverBasic = (
  variant,
  theme,
  opacity = '',
  disabled,
  card
) => {
  if (disabled || card) {
    return null;
  }
  switch (variant) {
    case 'withCoach':
      return `${theme.colors.neutralMain80}${opacity}`;
    case 'byYourSelf':
      return `${theme.colors.neutralLight}${opacity}`;
    case 'custom':
      return `${theme.colors.neutralLightNew}${opacity}`;
    default:
      return 'transparent';
  }
};

export const BigButton = styled.button`
  ${setMargin};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: ${({ variant, selected, theme }) =>
    decideColorBig(variant, theme, selected)};
  position: relative;
  background: ${({ variant, selected, theme }) =>
    decideBackgroundBig(variant, theme, selected)};
  border: none;
  border-radius: 16px;
  font-size: 1rem;
  font-weight: bold;
  padding: ${({ variant, theme }) =>
    `${decidePaddingTopAndBottom(variant, theme)} ${theme.spacings[5]}`};
  transition: background-color 300ms linear, color 300ms linear;
  box-shadow: ${({ theme }) => theme.shadows.elevation1};

  :hover {
    background: ${({ variant, theme, disabled, card }) =>
      decideBackgroundHoverBasic(variant, theme, disabled, card)};
    color: ${({ variant, theme }) =>
      ['byYourSelf', 'custom'].includes(variant)
        ? theme.colors.primaryMain
        : theme.colors.neutralSurface};
  }

  :active {
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.9;
    background-color: ${({ theme }) => theme.colors.neutralMain};
  }

  :focus {
    box-shadow: ${({ card }) =>
      !card && 'inset 0px 0px 0px 2px rgba(5, 23, 48, 1)'};
    outline-style: ${({ card }) => !card && 'solid'};
    outline-width: 3px;
    outline-color: ${({ theme, card }) => !card && theme.colors.primaryMain};
    outline-offset: 2px;
  }

  /* for disabled style */
  opacity: ${({ disabled, selected, loading }) =>
    (disabled && !selected) || loading ? 0.5 : 1};
  cursor: ${({ disabled, loading, card }) =>
    disabled || loading ? 'not-allowed' : card ? 'default' : 'pointer'};
`;

export const BigLoading = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 48%;
  transform: translate(-50%, -50%);
  span {
    font-size: 3.25rem !important;
    line-height: 24px;
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.quaternaryMain};
  }
`;

export const IconWrapper = styled.div`
  padding: ${({ variant }) => (variant === 'custom' ? '16px' : '0')};
`;
