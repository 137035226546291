import { useState } from 'react';

import { Alert, Grid } from '../../../components';
import * as S from './style';
import DoYouHaveAccount from '../HaveAccount/DoYouHaveAccount';
import TryNewEmail from './TryNewEmail';
import SignupExistingForm from '../SignupExistingAccount/SignupExistingForm';
import { audio } from 'constants';

const { Row, Col } = Grid;

const LinkedToOtherCourse = ({ setFormState, setStep, submitAttempt }) => {
  const [state, setState] = useState('');

  let selectedOption = '';
  if (state === 'haveAccount') {
    selectedOption = 'yes';
  } else if (state === 'tryNewEmail') {
    selectedOption = 'no';
  }

  return (
    <>
      <Row mt="16px">
        <Col w={[4, 12, 12]} ai="center">
          <Alert
            variant="primary"
            audio={audio.alertEmailIsLinkedToALearner}
            message="We can see this email is already linked to a learner. Do you already have a Count Me In or Turning Pages account?"
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 12]} ai="center">
          <DoYouHaveAccount
            insideSignup
            handleYes={() => setState('haveAccount')}
            handleNo={() => setState('tryNewEmail')}
            selectedOption={selectedOption}
          />
        </Col>
      </Row>
      {state !== '' && (
        <Row mt="48px">
          <Col w={[4, 12, 12]} ai="center">
            <S.BR />
          </Col>
        </Row>
      )}
      {state === 'haveAccount' && (
        <Row mt="48px">
          <Col w={[4, 12, 12]} ai="center">
            <SignupExistingForm insideSignup />
          </Col>
        </Row>
      )}
      {state === 'tryNewEmail' && (
        <TryNewEmail setFormState={setFormState} setStep={setStep} />
      )}
    </>
  );
};

export default LinkedToOtherCourse;
