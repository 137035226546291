import React from 'react';
import { Badges as BadgesApis } from '../../../../api-calls';
import BadgesList from 'components/BadgesList';
import * as T from 'components/Typography';

const Badges = () => {
  const { data: badges } = BadgesApis.useGetBadges({
    type: 'all',
  });

  return (
    <>
      <T.H2 size="med" display="block" mt={6} mb={2}>
        Badges
      </T.H2>
      <BadgesList badges={badges} />
    </>
  );
};

export default Badges;
