// TODO : style the empty state as design

import { Table, ConfigProvider } from 'antd';
import { WithText } from 'components/Button';
import Icon from 'components/Icon';
import * as T from 'components/Typography';
import * as S from './style';

const EmptySearchState = () => {
  return (
    <S.EmptyWrapper>
      <Icon icon="search" mb="16px" />
      <S.EmptyText>
        <T.P ta="center" weight="bold" mb="16px">
          No matching records found
        </T.P>
        <T.P ta="center" mb="16px">
          Check the other taps, you may find results there!
        </T.P>
      </S.EmptyText>
    </S.EmptyWrapper>
  );
};

const GenericCommonTable = ({
  dataSource = [],
  pageSize = 20,
  setPage,
  page = 1,
  loading,
  columns,
  emptyState = {},
  totalRecords,
  paginationDisabled,
  isResponsiveScroll = false,
}) => (
  <S.TableScroll isResponsiveScroll={isResponsiveScroll}>
    <ConfigProvider
      renderEmpty={() =>
        emptyState ? (
          <S.EmptyWrapper>
            <S.EmptyText>
              <T.P ta="center">{emptyState?.title}</T.P>
              <T.P ta="center">{emptyState?.subTitle}</T.P>
            </S.EmptyText>

            {emptyState?.buttonLink && (
              <WithText
                mr={5}
                icon="userWithArrow"
                text={emptyState?.buttonText}
                weight="bold"
                to={emptyState?.buttonLink}
                width="32px"
                height="32px"
                mt={4}
                disabled={emptyState?.disabled}
              />
            )}
          </S.EmptyWrapper>
        ) : (
          <EmptySearchState />
        )
      }
    >
      <Table
        sortDirections={['ascend', 'descend', 'ascend']}
        showSorterTooltip
        dataSource={dataSource}
        pagination={
          paginationDisabled
            ? false
            : {
                showSizeChanger: false,
                pageSize,
                total: totalRecords || 0,
                current: page,
              }
        }
        onChange={(props) => {
          setPage(props.current);
        }}
        columns={columns}
        loading={loading}
      />
    </ConfigProvider>
  </S.TableScroll>
);

export default GenericCommonTable;
