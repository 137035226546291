import { useEffect, useState } from 'react';

import { Grid, Typography as T, Button, Icon } from '../../../components';
import { getMediaUrl } from '../../../helpers';
import { useGeneralState } from '../../../context/general-state';

import { defaultGoals } from './helpers';
import { audio } from '../../../constants';

import * as S from './style';
import { Checkbox } from 'components/Inputs';

const { Col, Row } = Grid;

const AddGoalList = ({
  selectedGoal,
  handleCancel,
  handleCreateGoal,
  setOpenAddModal,
}) => {
  const [goalList, setGoalList] = useState([]);
  const [title, setTitle] = useState(selectedGoal);
  const [addMode, setAddMode] = useState(false);
  const [newItem, setNewItem] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editItemId, setEditItemId] = useState(0);

  useEffect(() => {
    setTitle(selectedGoal);
  }, [selectedGoal]);

  const { state: generalState } = useGeneralState();
  const goalHasSound = defaultGoals[title];

  const handleSave = () => {
    if (!title) {
      setTitle(selectedGoal);
    }
    setEditMode(false);
  };

  const handleEditChange = (e) => {
    const { value } = e.target;
    setTitle(value);
  };
  const handleAdd = () => {
    if (!newItem) {
      return;
    }
    setGoalList((old) => [...old, { id: old.length + 1, title: newItem }]);
    setNewItem('');
    setAddMode(false);
  };

  const handleAddChange = (e) => {
    const { value } = e.target;
    setNewItem(value);
  };

  const handleItemSave = (e) => {
    setEditItemId(0);
  };

  const handleItemEditChange = ({ value, id }) => {
    setGoalList((old) =>
      old.map((item) => (item.id === id ? { ...item, title: value } : item))
    );
  };
  return (
    <div style={{ marginTop: 24 }}>
      <>
        <S.CardWrapper>
          <Button.PlayButton
            width="56px"
            height="56px"
            audioUrl={getMediaUrl(
              goalHasSound?.audio || null, // TODO: update the sound when it's ready
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            mr={3}
            fallbackText={title}
          />

          <Row mt={4}>
            <Col w={[4, 12, 12]} display="flex" jc={'space-between'}>
              {editMode ? (
                <S.EditInputWrapper>
                  <S.EditInput
                    value={title}
                    onChange={handleEditChange}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        handleSave();
                      }
                    }}
                  />
                  <S.SaveButton onClick={handleSave}>Save</S.SaveButton>
                </S.EditInputWrapper>
              ) : (
                <>
                  <T.H2>{title}</T.H2>
                  <S.EditButton2
                    onClick={() => {
                      setEditMode(true);
                    }}
                  >
                    <Icon icon="edit2" color={'primaryMain'} />
                    <T.P mt="1" color="primaryMain" weight="bold">
                      Edit title
                    </T.P>
                  </S.EditButton2>
                </>
              )}
            </Col>
          </Row>
          <S.Divider />
          <Row mb="4">
            <Col w={[4, 12, 12]}>
              {addMode ? (
                <S.EditInputWrapper>
                  <S.EditInput
                    value={newItem}
                    onChange={handleAddChange}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        handleAdd();
                      }
                    }}
                  />
                  <S.SaveButton onClick={handleAdd}>Add</S.SaveButton>
                </S.EditInputWrapper>
              ) : (
                <Button.BasicButton
                  handleClick={() => {
                    setAddMode(true);
                  }}
                  icon="plus"
                  variant="tertiary"
                  loading={false}
                >
                  <T.P weight="semi">Add goal list</T.P>
                </Button.BasicButton>
              )}
            </Col>
          </Row>
          {goalList?.length > 0 && (
            <Row>
              {goalList.map((item) => {
                return (
                  <Col w={[4, 12, 12]} key={item.id}>
                    {item.id === editItemId ? (
                      <S.EditInputWrapper>
                        <S.EditInput
                          value={item.title}
                          onChange={(e) =>
                            handleItemEditChange({
                              value: e.target.value,
                              id: item.id,
                            })
                          }
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              handleItemSave();
                            }
                          }}
                        />
                        <S.SaveButton onClick={handleItemSave}>
                          Save
                        </S.SaveButton>
                      </S.EditInputWrapper>
                    ) : (
                      <S.ListItemWrapper>
                        <S.CheckBoxItem>
                          <Checkbox
                            borderColor={'neutral40'}
                            disabled={true}
                            plain
                            noPadding
                          />
                          <T.P>{item.title}</T.P>
                        </S.CheckBoxItem>
                        <S.EditButton2
                          onClick={() => {
                            setEditItemId(item.id);
                          }}
                        >
                          <Icon icon="edit2" color={'primaryMain'} />
                        </S.EditButton2>
                      </S.ListItemWrapper>
                    )}
                  </Col>
                );
              })}
            </Row>
          )}

          <Row inner={true} mt={5}>
            <Col w={[4, 12, 12]}>
              <Button.BasicButton
                variant="primary"
                handleClick={() => {
                  handleCreateGoal({ title, list: goalList }).then(() => {
                    setOpenAddModal(true);
                  });
                }}
                disabled={false}
                showSound
                width={'100%'}
                audioUrl={getMediaUrl(
                  audio.addANewGoal,
                  true,
                  generalState?.preferredVoice
                )}
                fallbackText="Add the goal"
              >
                <T.P color="white" weight="bold">
                  Add the goal
                </T.P>
              </Button.BasicButton>
            </Col>
            <Col w={[4, 12, 12]} mt={2}>
              <Button.BasicButton
                variant="tertiary"
                handleClick={handleCancel}
                disabled={false}
                showSound
                width={'100%'}
                audioUrl={getMediaUrl(
                  audio.cancel,
                  true,
                  generalState?.preferredVoice
                )}
                fallbackText="Cancel"
              >
                <T.P weight="bold">Cancel</T.P>
              </Button.BasicButton>
            </Col>
          </Row>
        </S.CardWrapper>
        <S.Divider />
      </>
    </div>
  );
};

export default AddGoalList;
