import React from 'react';
import * as S from './style';
import Header from '../Header';
import Footer from '../Footer';
import { useAuth } from './../../../context/auth';

const Side = ({
  children,
  image,
  contentProps = {},
  contentBodyProps = {},
  headerProps = {},
  ...props
}) => {
  const {
    user: { overlayColor },
  } = useAuth();

  return (
    <S.Wrapper {...props} bgColor={overlayColor}>
      <S.Content {...contentProps}>
        <Header {...headerProps} />
        <S.ContentBody {...contentBodyProps} headerType={headerProps.type}>
          {children}
        </S.ContentBody>
      </S.Content>
      <Footer {...props} />
    </S.Wrapper>
  );
};

export default Side;
