import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';
import { Row } from '../../../components/Grid';

export const Wrapper = styled.div`
  max-width: 600px;
  margin-top: ${({ theme }) => theme.spacings[2]};
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ImageButtonWrapper = styled(RouterLink)`
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  background-image: url(${({ image }) => image});
  width: 100%;
  min-height: 155px;
  max-height: 200px;
  height: 41vw;
  border-radius: 16px;
  background-size: cover;
  margin: ${({ theme }) => theme.spacings[1]} 0;
  display: flex;
  justify-content: center;
  align-items: end;
  padding: ${({ theme }) => theme.spacings[4]}
    ${({ theme }) => theme.spacings[5]};
  &:hover {
    transform: scale(1.01);
  }
`;

export const TipWrapper = styled.div`
  max-width: 300px;
`;

export const TextWrapper = styled.button`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-left: 1rem;
`;

export const ProgressCheckWrapper = styled(RouterLink)`
  background-image: ${({ image, theme }) => `linear-gradient(
    180deg,
    ${theme.colors.primaryMain12} 9.24%,
    ${theme.colors.primaryMain} 80.48%
  ),url(${image})`};

  width: 100%;
  height: 189.48px;
  border-radius: 16px;
  background-size: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  margin-bottom: ${({ theme }) => theme.spacings[3]};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const SelectWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacings[5]};
  width: 100%;
`;

export const BackWrapper = styled(Row)`
  flex: 1;
  max-width: 600px;
`;
