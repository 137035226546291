import theme from '../../../theme';

const Edit2 = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '45'}
    height={height || '44'}
    viewBox="0 0 45 44"
    fill="none"
    {...props}
  >
    <path
      d="M28.375 18.6343C28.375 18.2201 28.7108 17.8843 29.125 17.8843C29.5392 17.8843 29.875 18.2201 29.875 18.6343V26.9534C29.875 28.7767 28.3615 30.25 26.5 30.25H17.75C15.8885 30.25 14.375 28.7767 14.375 26.9534V18.0466C14.375 16.2233 15.8885 14.75 17.75 14.75H25.8942C26.3084 14.75 26.6442 15.0858 26.6442 15.5C26.6442 15.9142 26.3084 16.25 25.8942 16.25H17.75C16.7116 16.25 15.875 17.0575 15.875 18.0466V26.9534C15.875 27.9425 16.7116 28.75 17.75 28.75H26.5C27.5384 28.75 28.375 27.9425 28.375 26.9534V18.6343Z"
      fill={theme.colors[color] || color || '#405ED6'}
    />
    <path
      d="M29.5947 13.9697C29.8876 13.6768 30.3624 13.6768 30.6553 13.9697C30.9482 14.2626 30.9482 14.7374 30.6553 15.0303L23.6553 22.0303C23.3624 22.3232 22.8876 22.3232 22.5947 22.0303C22.3018 21.7374 22.3018 21.2626 22.5947 20.9697L29.5947 13.9697Z"
      fill={theme.colors[color] || color || '#405ED6'}
    />
  </svg>
);

export default Edit2;
