import styled from '@emotion/styled';
import exerciseSuccessBg from '../assets/exerciseSuccessBg.png';

export const Wraper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PlayButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${exerciseSuccessBg});
  background-size: cover;
  background-position: center;
  padding: 32px 0;
  width: 100%;
`;
