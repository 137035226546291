import { COACHES_BASE, CUSTOM_TRAINING_BASE } from '../constants';
import {
  useFetch,
  usePost,
  usePatch,
  useDelete,
  formatQueryError,
} from '../utils/queries';

const useDeleteCustomTrainingResourceById = ({ id }, options = {}) => {
  const context = useDelete({
    url: `${CUSTOM_TRAINING_BASE}/custom-training-resources/${id}`,
    reactQueryConfig: {
      ...options,
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useDeleteCustomTrainingStepById = ({ stepId }, options = {}) => {
  const context = useDelete({
    url: `${CUSTOM_TRAINING_BASE}/custom-training-steps/${stepId}`,
    reactQueryConfig: {
      ...options,
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useGetCustomTrainingStepById = ({ stepId }, options = {}) => {
  const queryKey = ['GET_CUSTOM_TRAINING_STEP', { stepId }];
  const context = useFetch({
    key: queryKey,
    url: `${CUSTOM_TRAINING_BASE}/custom-training-steps/${stepId}`,
    reactQueryConfig: options,
  });
  return {
    ...context,
    customTrainingSteps: context.data,
    error: formatQueryError(context),
  };
};

const useGetCustomTrainingResource = ({ id }, options = {}) => {
  const queryKey = ['GET_CUSTOM_TRAINING_RESOURCE', { id }];
  const context = useFetch({
    key: queryKey,
    url: `${CUSTOM_TRAINING_BASE}/custom-training-resources/${id}`,
    reactQueryConfig: options,
  });
  return {
    ...context,
    customTrainingResources: context.data,
    error: formatQueryError(context),
  };
};

const useUpdateCustomTrainingResourceById = (_, options = {}) => {
  const context = usePatch({
    getUrl: ({ id }) =>
      `${CUSTOM_TRAINING_BASE}/custom-training-resources/${id}`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: ({ id, organisationId }) => [
        ['GET_ALL_CUSTOM_TRAINING_RESOURCES', { organisationId }],
        ['GET_CUSTOM_TRAINING_RESOURCE', { id }],
      ],
    },
  });
  return {
    ...context,
    customTrainingResources: context.data,
    error: formatQueryError(context),
  };
};

const useUpdateCustomTrainingStepById = (_, options = {}) => {
  const context = usePatch({
    getUrl: ({ stepId }) =>
      `${CUSTOM_TRAINING_BASE}/custom-training-steps/${stepId}`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: ({ stepId, organisationId }) => [
        ['GET_ALL_CUSTOM_TRAINING_STEPS', { organisationId }],
        ['GET_CUSTOM_TRAINING_STEP', { stepId }],
      ],
    },
  });
  return {
    ...context,
    customTrainingSteps: context.data,
    error: formatQueryError(context),
  };
};

const useGetDefaultCompletedStep = ({ userId }, options = {}) => {
  const queryKey = ['GET_DEFAULT_COMPLETED_STEP', { userId }];
  const context = useFetch({
    key: queryKey,
    url: `${COACHES_BASE}/${userId}/training-step`,
    reactQueryConfig: options,
  });
  return {
    ...context,
    defaultCompletedStep: context.data,
    error: formatQueryError(context),
  };
};

const useUpdateTrainingStepUsesMutation = (_, options = {}) => {
  const context = usePatch({
    getUrl: ({ customStepId, userId }) => {
      return customStepId
        ? `${CUSTOM_TRAINING_BASE}/completed-step/${customStepId}`
        : `${COACHES_BASE}/${userId}/training-step`;
    },
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [
        ['GET_DEFAULT_COMPLETED_STEP'],
        ['GET_ALL_CUSTOM_TRAINING_STEPS'],
      ],
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useGetAllCustomTrainingResources = ({ organisationId }, options = {}) => {
  const context = useFetch({
    key: ['GET_ALL_CUSTOM_TRAINING_RESOURCES', { organisationId }],
    url: `${CUSTOM_TRAINING_BASE}/custom-training-resources`,
    axiosParams: { organisationId },
    reactQueryConfig: options,
  });
  return {
    ...context,
    customTrainingResources: context.data,
    error: formatQueryError(context),
  };
};

const useGetAllCustomTrainingSteps = ({ organisationId }, options = {}) => {
  const context = useFetch({
    key: ['GET_ALL_CUSTOM_TRAINING_STEPS', { organisationId }],
    url: `${CUSTOM_TRAINING_BASE}/custom-training-steps`,
    axiosParams: { organisationId },
    reactQueryConfig: options,
  });

  return {
    ...context,
    customTrainingSteps: context.data,
    error: formatQueryError(context),
  };
};

const useAddCustomTrainingResource = ({ organisationId }, options = {}) => {
  const context = usePost({
    url: `${CUSTOM_TRAINING_BASE}/custom-training-resources`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [
        ['GET_ALL_CUSTOM_TRAINING_RESOURCES', { organisationId }],
      ],
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useAddCustomTrainingStep = ({ organisationId }, options = {}) => {
  const context = usePost({
    url: `${CUSTOM_TRAINING_BASE}/custom-training-steps`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [
        ['GET_ALL_CUSTOM_TRAINING_STEPS', { organisationId }],
      ],
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

export {
  useAddCustomTrainingStep,
  useGetAllCustomTrainingSteps,
  useGetCustomTrainingStepById,
  useUpdateCustomTrainingStepById,
  useDeleteCustomTrainingStepById,
  useGetAllCustomTrainingResources,
  useGetCustomTrainingResource,
  useAddCustomTrainingResource,
  useUpdateCustomTrainingResourceById,
  useDeleteCustomTrainingResourceById,
  useGetDefaultCompletedStep,
  useUpdateTrainingStepUsesMutation,
};
