import { Row, Col } from '../../../../../../components/Grid';
import StepFooterButtons from '../../../StepFooterButtons';

const SelectRecipes = ({
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  enableNext,
}) => {
  return (
    <Row jc="center">
      <Col w={[4, 6, 6]}>
        <StepFooterButtons
          stuckAtReview={stuckAtReview}
          handleNext={handleNext}
          preview={preview}
          disabled={disabled || preview || !enableNext}
          enableNext={enableNext}
        />
      </Col>
    </Row>
  );
};

export default SelectRecipes;
