import { useState, useEffect, useRef } from 'react';
import { useSpeechSynthesis } from 'context/speech-synthesis';

import * as Sentry from '@sentry/react';
import { useGeneralState } from 'context/general-state';

const reportAudioIssue = ({ audioUrl, fallbackText, errorMessage, state }) => {
  Sentry.captureMessage(`Issue with audio file | ${errorMessage}`, {
    extra: {
      state,
      message: errorMessage,
      audioUrl,
      fallbackText,
    },
    level: 'warning',
  });
};

function useAudioPlayer(audioUrl, onEnd) {
  const [canPlay, setCanPlay] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [audioCheckDone, setAudioCheckDone] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const audioRef = useRef();

  useEffect(() => {
    if (!audioUrl) {
      setCanPlay(false);
      setAudioCheckDone(true);
      return;
    }

    audioRef.current = new Audio(audioUrl);
    const audio = audioRef.current;

    audio.addEventListener('canplaythrough', () => {
      setCanPlay(true);
      setAudioCheckDone(true);
    });

    audio.addEventListener('error', (e) => {
      setCanPlay(false);
      setAudioCheckDone(true);
      setErrorMessage(e.target?.error?.message || '');
    });

    audio.addEventListener('ended', () => {
      setPlaying(false);
      if (typeof onEnd === 'function') {
        onEnd();
      }
    });

    audio.load();

    return () => {
      audio.pause();
      audio.removeEventListener('canplaythrough', () => {});
      audio.removeEventListener('ended', () => {});
      audio.removeEventListener('error', () => {});
    };
  }, [audioUrl, onEnd]);

  return {
    canPlay,
    playing,
    setPlaying,
    audioRef,
    audioCheckDone,
    errorMessage,
  };
}

export default function UseAudio({
  audioUrl,
  handleClick,
  onEnd,
  fallbackText,
}) {
  const { playingAudioRef } = useGeneralState();
  const {
    canPlay,
    setPlaying,
    audioRef,
    audioCheckDone,
    errorMessage,
    playing,
  } = useAudioPlayer(audioUrl, onEnd);

  const { speak } = useSpeechSynthesis();

  const handleAudioClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (handleClick && typeof handleClick === 'function') {
      handleClick();
    }

    // If there is an audio playing, pause it and play the new one
    if (playingAudioRef.current) {
      playingAudioRef.current.pause();
      playingAudioRef.current.currentTime = 0;
    }

    if (!audioCheckDone) {
      return;
    }

    if (canPlay) {
      // Set the new audio as the playing audio
      playingAudioRef.current = audioRef.current;
      playingAudioRef.current.play();
    } else {
      reportAudioIssue({
        audioUrl,
        fallbackText,
        errorMessage,
        state: {
          canPlay,
          playing,
          audioCheckDone,
          isLoading: !audioCheckDone,
          isError: !!errorMessage,
          fallbackDisabled: !canPlay && !fallbackText,
        },
      });

      if (fallbackText) {
        speak(fallbackText, onEnd);
      } else {
        onEnd && onEnd();
      }
    }

    if (canPlay) {
      setPlaying((p) => !p);
    }
  };

  return {
    handleAudioClick,
    isError: !!errorMessage,
    isLoading: !audioCheckDone,
    fallbackDisabled: !canPlay && !fallbackText,
  };
}
