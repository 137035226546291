import * as T from '../../../../components/Typography';
import { BasicInput } from '../../../../components/Inputs';
import { Checkbox } from 'antd';
import * as S from './style';
import { Icon } from '../../../../components';
import { string, number, boolean, array, object } from 'yup';
import { MediaKeyInput } from '../../../components/MediaKeyInput';
import MDEditor from '../../../../components/MDEditor';
import { mediaTypes } from '../../../../constants';
import { useMemo, useRef } from 'react';
import { useStepForm } from 'CMS/Providers/StepFormProvider';

const initialData = {
  options: [{ id: 0, option: '', isCorrect: false }],
  questionText: '',
  questionAudioKey: '',
  textAudio: '',
  textAudioKey: '',
};

const MultipleChoiceComp = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateFormFields, updateDataFields } = useStepForm(
    initialRef.current
  );
  const { form, data, validationErrs } = state;

  const options = useMemo(
    () =>
      data?.options?.map((e, i) => ({
        ...e,
        id: i,
      })) || [{ id: 0, option: '', isCorrect: false }],
    [data?.options]
  );

  const addOption = () => {
    const maxId = Math.max(...options.map((e) => e.id), 0);
    updateDataFields({
      options: [...options, { id: maxId + 1, option: '', hide: false }],
    });
  };
  const removeOption = (id) => {
    updateDataFields({
      options: options.filter((e) => e.id !== id),
    });
  };

  const onOptionChange = (value, id) => {
    updateDataFields({
      options: options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  const onIsCorrectChange = (value, id) => {
    updateDataFields({
      options: options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, isCorrect: value };
      }),
    });
  };

  return (
    <div>
      <BasicInput
        label="Top Question text"
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        m={{ mt: 5 }}
        error={validationErrs?.textAudio}
      />
      <MediaKeyInput
        label={`Top Question text Audio`}
        type={mediaTypes.TEXT_AUDIO}
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs?.textAudioKey}
      />
      <BasicInput
        label="Question"
        value={data.questionText}
        handleChange={(questionText) => updateDataFields({ questionText })}
        m={{ mt: 8 }}
        error={validationErrs?.questionText}
      />
      <MediaKeyInput
        label={`Question file Key`}
        type={mediaTypes.QUESTION_AUDIO}
        value={form.questionAudioKey}
        handleChange={(questionAudioKey) =>
          updateFormFields({ questionAudioKey })
        }
        m={{ mt: 5 }}
        error={validationErrs?.questionAudioKey}
      />
      <T.P mt={8} size="large" weight="bold">
        Options
      </T.P>
      {validationErrs?.options &&
        typeof validationErrs.options === 'string' && (
          <T.P mb={2} mt={2} color="error">
            {validationErrs.options}
          </T.P>
        )}
      {options.map((e, i) => {
        return (
          <>
            <MDEditor
              label={`Option ${i + 1}`}
              value={e.option}
              onChange={(value) => onOptionChange(value, e.id)}
              mode={'edit'}
              height={80}
              m={{ mt: 8 }}
              error={
                validationErrs?.options && validationErrs?.options[i]?.option
              }
            />
            <Checkbox
              checked={e.isCorrect}
              onChange={(value) =>
                onIsCorrectChange(value.target.checked, e.id)
              }
              disabled={!e.option}
            >
              correct option?
            </Checkbox>
            {options.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeOption(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}
      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addOption}
        disabled={state.form.options.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Option
      </S.Button>
    </div>
  );
};

const validationSchema = {
  options: array()
    .of(
      object()
        .shape({
          id: number().required(),
          option: string().required('required field'),
          isCorrect: boolean(),
        })
        .required()
    )
    .required('you must provide options')
    .min(1, 'you must provide options')
    .test('oneCorrect', 'should select one correct answer', (val) => {
      return val?.filter((e) => e.isCorrect)?.length === 1;
    }),

  questionText: string().required('required field'),
  questionAudioKey: string().required('required field'),
};
export { validationSchema };

export default MultipleChoiceComp;
