import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`;

export const IconWrapper = styled.div`
  padding: 27px;
  background-color: ${({ theme, color }) => theme.colors[color] || color};
  border-radius: 23px;
  margin-right: 20px;
`;
