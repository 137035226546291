import { fields, createSchema, validate as _validate } from '..';
import { array, object, mixed, boolean } from 'yup';
import * as errMsgs from '../err-msgs';

const {
  firstName,
  lastName,
  email,
  phoneNumber,
  requiredText,
  shouldBeTrue,
  positiveNumber,
  city,
} = fields;

const schema = createSchema({
  firstName,
  lastName,
  email,
  phoneNumber,
  city,
  availabilityHoursPerWeek: requiredText,
  bestDayTime: requiredText,

  questions: array().of(
    object().shape({
      id: positiveNumber,
      options: array().of(
        object().shape({
          isAnswered: boolean().test(
            'required',
            'You must tick this box',
            function (value) {
              const { isAnswered, required } = this.parent;
              if (!required) return true;
              return isAnswered;
            }
          ),
        })
      ),

      // if required, then must be answered
      answer: mixed()
        .test('answer', errMsgs.DEFAULT_REQUIRED, function (value) {
          const { required } = this.parent;
          if (!required) return true;
          return !!value;
        })
        .test('answer', errMsgs.DEFAULT_REQUIRED, function (value) {
          const { type } = this.parent;
          if (type === 'MULTIPLE_CHOICE' || type === 'CHECKBOXES') {
            return Array.isArray(value) && value.length > 0;
          }
          if (type === 'SINGLE_CHOICE') {
            return !!value;
          }
          return true;
        }),
    })
  ),
  //
  trueAndCompleteInfo: shouldBeTrue,
  giveConsent: shouldBeTrue,
});

const validate = (data) => _validate(schema, data);

export default validate;
