import { useFetch, usePatch, usePost, useDelete } from '../utils/queries';
import { LEARNERS_GOALS_BASE } from '../constants';

function useGetLearnerGoals(_, options = {}) {
  const context = useFetch({
    key: [`GET_LEARNER_GOALS`],
    url: `${LEARNERS_GOALS_BASE}`,
    reactQueryConfig: options,
  });
  return { ...context };
}

function useUpdateLearnerGoalTask(_, options = {}) {
  return usePatch({
    getUrl: ({ personalGoalId, taskId }) =>
      `${LEARNERS_GOALS_BASE}/${personalGoalId}/tasks/${taskId}`,
    reactQueryConfig: {
      invalidateKeys: () => [[`GET_LEARNER_GOALS`]],
      ...options,
    },
  });
}

function useCreateLearnerGoalTask(_, options = {}) {
  return usePost({
    getUrl: ({ personalGoalId }) =>
      `${LEARNERS_GOALS_BASE}/${personalGoalId}/tasks`,
    reactQueryConfig: {
      invalidateKeys: () => [[`GET_LEARNER_GOALS`]],
      ...options,
    },
  });
}

function useUpdateLearnerGoal({ personalGoalId }, options = {}) {
  return usePatch({
    url: `${LEARNERS_GOALS_BASE}/${personalGoalId}`,
    reactQueryConfig: {
      invalidateKeys: () => [[`GET_LEARNER_GOALS`]],
      ...options,
    },
  });
}

function useDeleteLearnerGoal({ personalGoalId }, options = {}) {
  return useDelete({
    url: `${LEARNERS_GOALS_BASE}/${personalGoalId}`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: ({ personalGoalId }) => [
        [`GET_LEARNER_GOALS`, { personalGoalId }],
      ],
    },
  });
}

function useCreateLearnerGoal(_, options = {}) {
  return usePost({
    url: `${LEARNERS_GOALS_BASE}`,
    reactQueryConfig: {
      invalidateKeys: () => [[`GET_LEARNER_GOALS`]],
      ...options,
    },
  });
}

export {
  useGetLearnerGoals,
  useUpdateLearnerGoalTask,
  useCreateLearnerGoalTask,
  useUpdateLearnerGoal,
  useDeleteLearnerGoal,
  useCreateLearnerGoal,
};
