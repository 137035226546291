import { PlayButton } from 'components/Button';
import { Row } from 'components/Grid';
import * as T from 'components/Typography';
import { getMediaUrl } from 'helpers';
import { useGeneralState } from 'context/general-state';
import { getValidationErrorMessageAudio } from './utils';

const ValidationErrorWithPlayButton = ({ message }) => {
  const { state: generalState } = useGeneralState();

  return (
    <Row ai="end" mb={1}>
      <PlayButton
        width={'28px'}
        height={'28px'}
        iconProps={{
          height: '28px',
          width: '28px',
          color: 'error',
        }}
        audioUrl={getMediaUrl(
          getValidationErrorMessageAudio(message),
          true,
          generalState?.preferredVoice
        )}
        fallbackText={message}
        withoutButtonStyle
        backgroundColor="none"
      />
      <T.P color="error">{message}</T.P>
    </Row>
  );
};

export default ValidationErrorWithPlayButton;
