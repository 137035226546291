import styled from '@emotion/styled';
import * as T from 'components/Typography';
import { Row } from 'components/Grid';

export const TableTitleWrapper = styled.div`
  min-height: 72px;
  display: flex;
  align-items: center;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[6]};
`;

export const SignupDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  button {
    font-weight: bold;
    font-size: 1rem;
    margin-left: -0.5rem;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  max-width: 100%;
  .ant-table table {
    font-size: 1rem;
    font-weight: 400;
  }

  .ant-tabs-ink-bar {
    background: ${({ theme }) => theme.colors.primaryMain};
    height: 3px !important;
  }
  .ant-tabs-tab {
    margin: 0 !important;
    min-width: 133px;
    justify-content: center;
    border-bottom: 3px solid #e8e8e8;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    left: ${({ activeTab }) => {
      if (activeTab === '1') return '0px';
      if (activeTab === '2') return '133px';
      if (activeTab === '3') return '266px';
    }};
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-table {
    border: 1px solid ${({ theme }) => theme.colors.neutral50};
    overflow: auto;
  }
`;

export const EditDiv = styled.div`
  background: #f5f5f5;
  box-shadow: 0px 1px 3px -1px rgba(4, 13, 38, 0.1),
    0px 2px 5px -1px rgba(4, 13, 38, 0.11);
  border-radius: 8px;
  padding: 7px;
  margin-right: ${({ theme }) => theme.spacings[2]};
`;

export const Link = styled(T.Link)`
  display: flex;
  align-items: center;
`;

export const ActivationButton = styled.button`
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  color: #051730;
  font-weight: bold;
`;

export const EmptyWrapper = styled.div`
  display: flex;
  overflow: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings[3]};
`;

export const TableOptionWrapper = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 16px; */
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
`;

export const TableTitleOptionWrapper = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchInputWrapper = styled.div``;

export const TableTitleDescriptionWrapper = styled.div``;

export const TableButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: stretch;
  }
`;
