import { audio } from 'constants';
import { ValidationErrorMessages } from './constants';

export const getValidationErrorMessageAudio = (message) => {
  switch (message) {
    case ValidationErrorMessages.DEFAULT_REQUIRED.toLowerCase() ===
      message.toLowerCase():
      return audio.thisFieldIsRequired;

    case ValidationErrorMessages.MUST_SELECT_OPTION.toLowerCase() ===
      message.toLowerCase():
      return audio.mustSelectOneOfTheseOptions;

    case ValidationErrorMessages.EMAIL_ALREADY_EXISTS.toLowerCase() ===
      message.toLowerCase():
      return audio.aUserAccountWithThatEmailAlreadyExists;

    case ValidationErrorMessages.YOUR_ORGANISATION_HAS_BEEN_DEACTIVATED_FOR_OTHERS.toLowerCase() ===
      message.toLowerCase():
      return audio.yourOrganisationHasBeenDeactivated;

    case ValidationErrorMessages.YOUR_ORGANISATION_DOES_NOT_ALLOW_SELF_SIGNUP.toLowerCase() ===
      message.toLowerCase():
      return audio.yourOrganisationDoesNotAllowSelfSignup;

    case ValidationErrorMessages.LEARNER_UNIQUE_ID_DOES_NOT_EXIST.toLowerCase() ===
      message.toLowerCase():
      return audio.weCantFindThatLearnerId;

    case ValidationErrorMessages.LEARNER_ALREADY_REGISTERED_TO_THIS_COURSE.toLowerCase() ===
      message.toLowerCase():
      return audio.learnerAlreadyRegisteredToThisCourse;

    case ValidationErrorMessages.LEARNER_LINKED_TO_OTHER_COURSE.toLowerCase() ===
      message.toLowerCase():
      return audio.learnerLinkedToOtherCourse;

    case ValidationErrorMessages.LEARNER_LOGIN_NO_COACH_ASSIGNED.toLowerCase() ===
      message.toLowerCase():
      return audio.sorryYouCannotLogInUntilYouHaveBeenAssignedACoach;

    case ValidationErrorMessages.INVALID_POSTCODE.toLowerCase() ===
      message.toLowerCase():
      return audio.invalidPostcode;

    default:
      return audio.internalServerError;
  }
};
