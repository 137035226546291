import { mediaTypes, navRoutes } from './../constants';
import { convertSnakeCaseToSpaces } from 'helpers';

export const createExerciseUrl = (id) =>
  navRoutes.CMS.VIEW_EXERCISE.replace(':id', id);

export const createExerciseStepUrl = (exerciseId, stepId) =>
  navRoutes.CMS.SINGLE_STEP.replace(':id', exerciseId).replace(
    ':stepId',
    stepId
  );

export const isEmpty = (obj) => Object.keys(obj).length === 0;

export const getMediaType = (mediaType) => {
  if (
    mediaType === mediaTypes.IMAGE ||
    mediaType === mediaTypes.ANSWER_IMAGE ||
    mediaType === mediaTypes.OPTION_IMAGE
  )
    return mediaTypes.IMAGE;

  return mediaTypes.AUDIO;
};

export const getVariationOptions = (variations) =>
  Object.keys(variations).map((e) => ({
    label: convertSnakeCaseToSpaces(e),
    value: e,
  }));
