import {
  useFetch,
  formatQueryError,
  usePost,
  usePatch,
} from '../utils/queries';
import { EXERCISES_BASE } from '../constants';

function useGetExercises({ cms, forPublic }, options = {}) {
  const context = useFetch({
    key: [`GET_EXERCISES`, { cms, forPublic }],
    url: EXERCISES_BASE,
    axiosParams: { cms, forPublic },
    reactQueryConfig: options,
  });
  return {
    ...context,
    milestones: context.data,
    error: formatQueryError(context),
  };
}

function useGetLetterSoundsSteps(_, options = {}) {
  const context = useFetch({
    key: [`GET_LETTER_SOUNDS_STEPS`],
    url: `${EXERCISES_BASE}/letter-sounds`,
    reactQueryConfig: options,
  });
  return { ...context, sounds: context.data, error: formatQueryError(context) };
}

function useGetMilestoneAllExercisesCompleted({ milestoneId }, options = {}) {
  const context = useFetch({
    key: [`GET_MILESTONE_ALL_EXERCISES_COMPLETED`, { milestoneId }],
    url: `${EXERCISES_BASE}/milestone-last-ex/${milestoneId}`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [
        [EXERCISES_BASE],
        [`GET_EXERCISE_BY_ID`, { id: milestoneId }],
      ],
    },
  });
  return {
    ...context,
    lastExercise: context.data,
    error: formatQueryError(context),
  };
}

function useUpdateExerciseFeedback({ exerciseId }, options = {}) {
  return usePatch({
    url: `${EXERCISES_BASE}/${exerciseId}/feedback`,
    reactQueryConfig: options,
  });
}

const useCreateCompletedStep = ({ id }, options = {}) => {
  return usePost({
    url: `${EXERCISES_BASE}/steps/${id}/complete`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [[EXERCISES_BASE], [`GET_EXERCISE_BY_ID`, { id }]],
    },
  });
};

const useGetExerciseById = ({ id, learnerId }, options = {}) => {
  const context = useFetch({
    key: [`GET_EXERCISE_BY_ID`, { id, learnerId }],
    url: `${EXERCISES_BASE}/${id}`,
    axiosParams: { learnerId },
    reactQueryConfig: options,
  });

  const completed = !context?.data?.steps?.find((e) => !e.completed);

  const exercise = {
    ...context.data,
    steps: context?.data?.steps,
    completed,
  };

  return { ...context, exercise, error: formatQueryError(context) };
};

const useGetStepById = ({ exerciseId, stepId }, options = {}) => {
  const context = useFetch({
    key: [`GET_STEP_BY_ID`, { exerciseId, stepId }],
    url: `${EXERCISES_BASE}/${exerciseId}/steps/${stepId}`,
    reactQueryConfig: options,
  });
  return { ...context, error: formatQueryError(context) };
};

const useCreateExercise = (_, options = {}) => {
  const context = usePost({
    url: `${EXERCISES_BASE}/`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [[EXERCISES_BASE]],
    },
  });
  return { ...context, error: formatQueryError(context) };
};

const useUpdateExercise = (_, options = {}) => {
  const context = usePatch({
    getUrl: ({ id }) => `${EXERCISES_BASE}/${id}`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: ({ id }) => [[`GET_EXERCISE_BY_ID`, { id }]],
    },
  });
  return { ...context, error: formatQueryError(context) };
};

export {
  useGetExercises,
  useGetLetterSoundsSteps,
  useCreateCompletedStep,
  useGetMilestoneAllExercisesCompleted,
  useUpdateExerciseFeedback,
  useGetExerciseById,
  useGetStepById,
  useCreateExercise,
  useUpdateExercise,
};
