import {
  useFetch,
  formatQueryError,
  usePatch,
  usePost,
  useFetchMutation,
} from '../utils/queries';
import { LEARNERS_BASE, EXERCISES_BASE } from '../constants';

function useGetLearnerStats({ learnerId }, options = {}) {
  const context = useFetch({
    key: [`GET_LEARNER_STATS`, { learnerId }],
    url: `${LEARNERS_BASE}/${learnerId}/stats`,
    reactQueryConfig: options,
  });
  return { ...context, stats: context.data, error: formatQueryError(context) };
}

function useGetLearnerInfo({ id }, options = {}) {
  const context = useFetch({
    key: [`GET_LEARNER_INFO`, { id }],
    url: `${LEARNERS_BASE}/${id}/user-info`,
    reactQueryConfig: options,
  });
  return {
    ...context,
    userInfo: context.data,
    error: formatQueryError(context),
  };
}

const useGetLearnersCount = ({ search }, options = {}) => {
  const context = useFetch({
    key: [`GET_LEARNERS_COUNT`, { search }],
    url: `${LEARNERS_BASE}/count`,
    axiosParams: { search },
    reactQueryConfig: options,
  });
  return {
    ...context,
    learnersCount: context.data,
    error: formatQueryError(context),
  };
};

const useGetLearnersQuery = ({ ...params }, options = {}) => {
  const context = useFetch({
    key: ['GET_LEARNERS', params],
    url: `${LEARNERS_BASE}/`,
    axiosParams: params,
    reactQueryConfig: options,
  });
  return {
    ...context,
    learners: context.data,
    error: formatQueryError(context),
  };
};

function useGetLearners(
  { page, limit, search = '', providedCourseCode } = {},
  options = {}
) {
  const context = useFetch({
    key: [`GET_LEARNERS`, { page, limit, search, providedCourseCode }],
    url: `${LEARNERS_BASE}/`,
    axiosParams: { page, limit, search, providedCourseCode },
    reactQueryConfig: options,
  });

  return {
    ...context,
    learners: context.data,
    error: formatQueryError(context),
  };
}

function useGetLearnerNotes({ learnerId }, options = {}) {
  const context = useFetch({
    key: [`LEARNER_NOTES`, { learnerId }],
    url: `${LEARNERS_BASE}/${learnerId}/notes`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    notes: context.data,
    error: formatQueryError(context),
  };
}

function useAddLearnerNote(_, options = {}) {
  return usePost({
    getUrl: ({ learnerId }) => `${LEARNERS_BASE}/${learnerId}/notes`,
    reactQueryConfig: {
      invalidateKeys: ({ learnerId }) => [
        [`LEARNER_NOTES`, { learnerId }],
        [`GET_LEARNERS`],
      ],
      ...options,
    },
  });
}

function useUpdateLearnerNote(_, options = {}) {
  return usePatch({
    getUrl: ({ learnerId, noteId }) =>
      `${LEARNERS_BASE}/${learnerId}/notes/${noteId}`,
    reactQueryConfig: {
      invalidateKeys: ({ learnerId }) => [
        [`LEARNER_NOTES`, { learnerId }],
        [`GET_LEARNERS`],
      ],
      ...options,
    },
  });
}

function useUpdateLearnerContactInfo(_, options = {}) {
  return usePatch({
    getUrl: ({ userId }) => `${LEARNERS_BASE}/${userId}/intro-meeting-info`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: ({ id }) => [
        [`GET_LEARNERS`],
        [`GET_LEARNER_INFO`, { id }],
      ],
    },
  });
}

function useGetLearnersByCoachId({ coachId }, options = {}) {
  const context = useFetch({
    key: [`GET_LEARNERS_BY_COACH_ID`, { coachId }],
    url: `${LEARNERS_BASE}/coach-id/${coachId}`,
    reactQueryConfig: options,
  });
  return {
    ...context,
    learners: context.data,
    error: formatQueryError(context),
  };
}

const useCoachConfirmsLearner = (_, options = {}) => {
  return usePost({
    getUrl: ({ learnerId }) => `${LEARNERS_BASE}/${learnerId}/confirm`,
    reactQueryConfig: {
      ...options,
    },
  });
};

const useCreateStuckAt = (_, options = {}) => {
  return usePost({
    getUrl: ({ learnerId }) => `${LEARNERS_BASE}/${learnerId}/stuck-at`,
    reactQueryConfig: {
      invalidateKeys: () => [
        [`${EXERCISES_BASE}`],
        [`GET_LEARNER_STATS`],
        ['GET_EXERCISES'],
      ],
      ...options,
    },
  });
};

const useUpdateActiveStatus = (_, options) => {
  return usePost({
    getUrl: ({ learnerId }) => `${LEARNERS_BASE}/${learnerId}/active-status`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [
        [`${EXERCISES_BASE}`],
        [`GET_LEARNER_STATS`],
        ['GET_LEARNERS'],
      ],
    },
  });
};

const useUpdateLearnerMilestoneUpdate = ({ learnerId }, options = {}) => {
  return usePatch({
    url: `${LEARNERS_BASE}/${learnerId}/milestones-updates`,

    reactQueryConfig: {
      invalidateKeys: () => [
        [`${EXERCISES_BASE}`, { learnerId }],
        ['GET_LEARNER_STATS', { learnerId }],
      ],
      ...options,
    },
  });
};

const useGetLearnerCurrentMilestone = ({ learnerId }, options = {}) => {
  const context = useFetch({
    key: [`GET_LEARNER_CURRENT_MILESTONE`, { learnerId }],
    url: `${LEARNERS_BASE}/${learnerId}/milestone`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    currentMilestone: context.data,
    error: formatQueryError(context),
  };
};

const useGetLearnerProfile = ({ learnerUserId }, options = {}) => {
  const context = useFetch({
    key: [`GET_LEARNER_PROFILE`, { learnerUserId }],
    url: `${LEARNERS_BASE}/${learnerUserId}/profile`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    learnerProfile: context.data,
    error: formatQueryError(context),
  };
};

const useGetLearnerStuckAt = ({ learnerUserId }, options = {}) => {
  const context = useFetch({
    key: [`GET_LEARNER_STUCK_AT`, { learnerUserId }],
    url: `${LEARNERS_BASE}/${learnerUserId}/stuck-at`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    stuckAt: context.data,
    error: formatQueryError(context),
  };
};

const useLogin = (_, options = {}) => {
  const context = usePost({
    url: `${LEARNERS_BASE}/login`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};
const useLearnerSignup = (_, options = {}) => {
  const context = usePost({
    url: `${LEARNERS_BASE}/signup`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};
const useLearnerValidateEmailForSignup = (_, options = {}) => {
  const context = usePost({
    url: `${LEARNERS_BASE}/signup/validate-email`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};
const useCreateLearner = (_, options = {}) => {
  const context = usePost({
    url: `${LEARNERS_BASE}`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [
        [LEARNERS_BASE],
        [`GET_LEARNERS`],
        [`GET_LEARNERS_COUNT`],
      ],
    },
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useGetLearnerUniqueId = ({ learnerUserId }, options = {}) => {
  const context = useFetch({
    key: [`GET_LEARNER_UNIQUE_ID`, { learnerUserId }],
    url: `${LEARNERS_BASE}/${learnerUserId}`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useGetLearnerSelfLearningByUserId = ({ id }, options = {}) => {
  const context = useFetch({
    key: [`GET_LEARNER_SELF_LEARNING_BY_USER_ID`, { id }],
    url: `${LEARNERS_BASE}/${id}/self-learning`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useGetLearnerByUniqueId = ({ learnerUniqueId }, options = {}) => {
  const context = useFetch({
    key: [`GET_LEARNER_BY_UNIQUE_ID`, { learnerUniqueId }],
    url: `${LEARNERS_BASE}/unique-id/${learnerUniqueId}`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useGetLearnerByUniqueIdMutation = (_, options = {}) => {
  const context = useFetchMutation({
    getUrl: ({ learnerUniqueId }) =>
      `${LEARNERS_BASE}/unique-id/${learnerUniqueId}`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useMarkCompletedStepsAsReviewedByCoach = (
  { learnerId },
  options = {}
) => {
  const context = usePatch({
    url: `${LEARNERS_BASE}/${learnerId}/completed-steps`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [['GET_LEARNER_BY_UNIQUE_ID']],
    },
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useAddLearnerPersonalGoals = (_, options = {}) => {
  const context = usePatch({
    getUrl: ({ learnerId }) => `${LEARNERS_BASE}/${learnerId}/personal-goals`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useCreateProgressReport = (_, options = {}) => {
  const context = usePost({
    url: `${LEARNERS_BASE}/fill-progress-report`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [
        ['GET_LEARNER_PROFILE'],
        ['GET_LEARNERS'],
        ['GET_LEARNERS_BY_COACH_ID'],
      ],
    },
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useAddLearnerInterests = ({ learnerId }, options = {}) => {
  const context = usePatch({
    url: `${LEARNERS_BASE}/${learnerId}/interests`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useSelectLearner = (_, options) => {
  const context = usePost({
    getUrl: ({ learnerId }) => `${LEARNERS_BASE}/${learnerId}/select-learner`,
    reactQueryConfig: {
      ...options,
      invalidateAll: true,
    },
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useGetLearnerReferralByLearner = ({ learnerUserId }, options = {}) => {
  const context = useFetch({
    key: [`GET_LEARNER_REFERRAL_BY_LEARNER`, { learnerUserId }],
    url: `${LEARNERS_BASE}/${learnerUserId}/referral`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useUpdateLearnerReferral = ({ learnerUserId }, options = {}) => {
  const context = usePatch({
    url: `${LEARNERS_BASE}/${learnerUserId}/referral`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [
        [`GET_LEARNER_REFERRAL_BY_LEARNER`, { learnerUserId }],
      ],
    },
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useUpdateLearnerCoach = (_, options = {}) => {
  const context = usePatch({
    getUrl: ({ learnerUserId, coachId }) =>
      `${LEARNERS_BASE}/${learnerUserId}/assign/${coachId}`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: ({ learnerUserId }) => [
        [`GET_LEARNER_UNIQUE_ID`, { learnerUserId }],
        ['GET_LEARNERS_BY_COACH_ID'],
        ['GET_LEARNERS'],
      ],
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useUpdateLearnerPreferredVoice = (options = {}) => {
  const context = usePatch({
    getUrl: ({ learnerUserId }) =>
      `${LEARNERS_BASE}/${learnerUserId}/preferred-voice`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useUpdateLearnerWatchedOnboardingVid = (
  { learnerUserId },
  options = {}
) => {
  const context = usePatch({
    url: `${LEARNERS_BASE}/${learnerUserId}/watched-onboarding-vid`,
    reactQueryConfig: {
      ...options,
    },
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useSendRequestToUpdateLevel = (_, options = {}) => {
  const context = usePost({
    url: `${LEARNERS_BASE}/update-level-request`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useDontWantThisCoach = (_, options = {}) => {
  const context = usePost({
    url: `${LEARNERS_BASE}/dont-want-this-coach`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

export {
  useGetLearnerStats,
  useGetLearners,
  useGetLearnerInfo,
  useUpdateLearnerContactInfo,
  useCoachConfirmsLearner,
  useGetLearnersByCoachId,
  useCreateStuckAt,
  useUpdateActiveStatus,
  useUpdateLearnerMilestoneUpdate,
  useGetLearnerCurrentMilestone,
  useGetLearnerProfile,
  useGetLearnerStuckAt,
  useGetLearnersQuery,
  useGetLearnersCount,
  useGetLearnerNotes,
  useAddLearnerNote,
  useUpdateLearnerNote,
  useLogin,
  useLearnerSignup,
  useLearnerValidateEmailForSignup,
  useCreateLearner,
  useGetLearnerUniqueId,
  useGetLearnerSelfLearningByUserId,
  useGetLearnerByUniqueId,
  useGetLearnerByUniqueIdMutation,
  useMarkCompletedStepsAsReviewedByCoach,
  useAddLearnerPersonalGoals,
  useCreateProgressReport,
  useAddLearnerInterests,
  useSelectLearner,
  useGetLearnerReferralByLearner,
  useUpdateLearnerReferral,
  useUpdateLearnerCoach,
  useUpdateLearnerPreferredVoice,
  useUpdateLearnerWatchedOnboardingVid,
  useSendRequestToUpdateLevel,
  useDontWantThisCoach,
};
