import theme from '../../../theme';

const ArrowLine = ({ width, height, color, ...props }) => (
  <svg
    width={width || '26'}
    height={height || '67'}
    viewBox="0 0 25 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="2.05957"
      y1="-2.05957"
      x2="54.5787"
      y2="-2.05957"
      transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 11 63)"
      stroke={theme.colors.neutralMain}
      strokeWidth="4.11915"
      strokeLinecap="round"
      strokeDasharray="12.36 12.36"
    />
    <path
      d="M20.7344 14.2687L15 8.53896L12.5 6.5L10 8.53896L4.26564 14.2687C3.28816 15.2454 1.70524 15.2435 0.730109 14.2645C-0.245027 13.2854 -0.243125 11.7 0.734359 10.7233L10.7344 0.731279C11.1995 0.266548 11.8297 -1.15138e-06 12.5 -1.09278e-06C13.1703 -1.03419e-06 13.8005 0.266548 14.2656 0.731284L24.2656 10.7233C25.2431 11.7 25.245 13.2854 24.2699 14.2645C23.2948 15.2435 21.7118 15.2454 20.7344 14.2687Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default ArrowLine;
