import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';

const sizes = {
  default: '52px',
  large: '66px',
  extraLarge: '96px',
};

const fontSizes = {
  default: '24px',
  large: '28px',
  extraLarge: '28px',
};

const paddings = {
  default: '12px',
  large: '12px 16px',
};

export const Wrapper = styled.button`
  ${setMargin};
  min-width: ${({ size = 'default' }) => sizes[size]};
  min-height: ${({ size = 'default' }) => sizes[size]};
  background: ${({ theme, bgColor }) => theme.colors[bgColor] || bgColor};
  border-radius: ${({ theme }) => theme.borders.emptySpaceBR};
  width: fit-content;
  padding: ${({ size = 'default' }) => paddings[size]};
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: ${({ size = 'default', fontSize }) => fontSize || fontSizes[size]};
  background: ${({ theme }) => theme.colors.neutralLight};

  color: ${({ theme, color = 'neutralMain' }) => theme.colors[color] || color};

  cursor: pointer;
  line-height: 0;
  border: none;

  :focus {
    outline: 2px solid black;
    outline-offset: -6px;
    border: 2px solid ${({ theme }) => theme.colors.primaryMain};
  }

  :hover {
    color: ${({ theme }) => theme.colors.primaryMain};
  }
  box-shadow: ${({ theme }) => theme.shadows.elevation1};
`;
