import { fields, createSchema, validate as _validate } from '..';

const { requiredText } = fields;

const schema = createSchema({
  confidentInReadingAbility: requiredText,
  canManageEverydayLife: requiredText,
  communicateWellWithOthers: requiredText,
  feelingCloseToOtherPeople: requiredText,
  ableToReadLetters: requiredText,
  haveGotWithReadingGoals: requiredText,
  optimisticAboutFuture: requiredText,
  happyWithProgress: requiredText,
  education: requiredText,
  employment: requiredText,
});

const validate = (data) => _validate(schema, data);

export default validate;
