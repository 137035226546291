import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  gap: 22px;
  padding: 0 40px;
  @media (max-width: 768px) {
    padding: 0 40px;
    padding-bottom: 16px;
  }
`;

export const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  padding: 0 56px;
  padding-bottom: 34px;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const PreWrapper = styled.div`
  padding-bottom: 16px;
`;

export const HiWrapper = styled.div`
  width: 96px;
  height: 96px;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.quaternaryLightBlue};
  position: relative;
  margin-bottom: 16px;
`;
export const HiSvgWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
