import styled from '@emotion/styled';

export const TestimonialWrapper = styled('div')`
  padding: ${({ theme }) => theme.spacings[5]};
  padding-bottom: ${({ theme }) => theme.spacings[4]};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors['neutral40']};
  border-radius: ${({ theme }) => theme.borders.emptySpaceBR};
  ${({ theme }) => theme.media.mobile} {
    padding: ${({ theme }) => theme.spacings[4]};
    padding-bottom: ${({ theme }) => theme.spacings[4]};
  }
`;

export const CancelButton = styled('div')`
  all: unset;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;
