import { useRef, useReducer, useEffect } from 'react';
import { useNavigate } from 'react-router';
import validate from '../../validation/schemas/coach-interest';
import { GENERAL } from '../../constants/nav-routes';
import Form from './Form';
import {
  useAddCoaches,
  useGetRecruitmentFormAnswers,
} from '../../api-calls/coaches.queries';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import { cleanEmail } from '../../helpers';
import { useGetOrganisationRecruitmentForm } from '../../api-calls/organisations.queries';
import Loading from '../../components/Loading';
import { BackButton } from 'components/BackButton';
import * as S from './style';
import InterestFormIsClosed from './InterestFormIsClosed';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }
  return { ...state, ...value };
}

const FormComponent = ({
  adminView,
  setFormData,
  data,
  state,
  validationErrs,
  httpError,
  isLoading,
  handleSubmit,
}) => {
  const formIsClosed = false;
  // const formIsClosed = !adminView && state?.form?.organisationId === 1;

  return (
    <S.Wrapper>
      {adminView && <BackButton mb="6" />}
      <Form
        setFormData={setFormData}
        form={state.form}
        title={data?.recruitmentFormTitle}
        description={data?.recruitmentFormDescription}
        validationErrs={validationErrs}
        httpError={httpError?.response?.data?.message}
        loading={isLoading}
        handleSubmit={formIsClosed ? () => {} : handleSubmit}
        adminView={adminView}
      />
      {formIsClosed && <InterestFormIsClosed />}
    </S.Wrapper>
  );
};

const InterestFormWithCoachId = ({
  coachId,
  gettingFormSuccess,
  state,
  setState,
  isGettingForm,
  isLoading,
  handleSubmit,
  data,
  validationErrs,
  httpError,
  adminView,
  setFormData,
}) => {
  const {
    data: coachData,
    isLoading: isGettingCoach,
    isSuccess: isCoachSuccess,
  } = useGetRecruitmentFormAnswers({ coachId }, { enabled: !!coachId });

  useEffect(() => {
    if (
      gettingFormSuccess &&
      isCoachSuccess &&
      !state.hasSetAnswer &&
      state?.form?.questions
    ) {
      setState({
        ...state,
        hasSetAnswer: true,
        form: {
          ...state.form,
          firstName: coachData.firstName,
          lastName: coachData.lastName,
          email: coachData.email,
          phoneNumber: coachData?.phoneNumber?.trim(),
          city: coachData.city,
          availabilityHoursPerWeek: coachData.availabilityHoursPerWeek,
          bestDayTime: coachData.bestDayTime,
          questions: state?.form?.questions.map((q) => {
            const formAnswer = coachData.answers.find(
              (a) => a.questionId === q.id
            );

            if (formAnswer) {
              const answer = formAnswer?.answer || formAnswer?.answerArray;

              return {
                ...q,
                answer: answer,
                options: q?.options?.map((o) => ({
                  ...o,
                  isAnswered: answer?.includes(o.value),
                })),
              };
            }
            return q;
          }),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gettingFormSuccess,
    isGettingForm,
    isCoachSuccess,
    isGettingCoach,
    state?.form?.questions,
  ]);

  if (
    isGettingCoach ||
    (coachId && !state?.hasSetAnswer) // if coachId is present, we need to get the coach's answers
  ) {
    return <Loading />;
  }

  return (
    <FormComponent
      adminView={adminView}
      data={data}
      handleSubmit={handleSubmit}
      httpError={httpError}
      isLoading={isLoading}
      setFormData={setFormData}
      state={state}
      validationErrs={validationErrs}
    />
  );
};

const InterestForm = ({ adminView: _adminView }) => {
  const [searchParams] = useSearchParams();
  const preview = searchParams.get('preview');
  const adminView = _adminView || preview === 'true';

  const initialState = {
    form: {
      // static questions
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      city: '',
      availabilityHoursPerWeek: '',
      bestDayTime: '',
      // dynamic questions
      questions: null,
      // consent questions
      trueAndCompleteInfo: _adminView ? true : '',
      giveConsent: _adminView ? true : '',
    },
    httpError: '',
    validationErrs: {},
    hasSetAnswer: false,
  };
  const { user } = useAuth();
  const [state, setState] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const { coachId, uniqueSlug } = useParams();
  const submitAttempt = useRef(false);
  const { mutateAsync, error: httpError, isLoading } = useAddCoaches();

  // decide how to get the id for the form
  // if user is org user then use user.organisationId
  // if admin user then get the default form from the coachId
  const { data: coachData } = useGetRecruitmentFormAnswers(
    { coachId },
    { enabled: !!coachId }
  );

  const organisationId = user.organisationId || coachData?.organisationId;
  // if org user get the form from their id
  const {
    data,
    isLoading: isGettingForm,
    isSuccess: gettingFormSuccess,
  } = useGetOrganisationRecruitmentForm(
    { id: user.organisationId || coachData?.organisationId, uniqueSlug },
    { enabled: !!organisationId || !!uniqueSlug }
  );

  useEffect(() => {
    if (gettingFormSuccess) {
      setState({
        ...state,
        form: {
          ...state.form,
          ...data,
          questions: data?.questions
            ?.sort((a, b) => a.order - b.order)
            .map((q, i) => ({ ...q, order: i + 1 }))
            .map((q) => {
              if (q?.options?.length) {
                return {
                  ...q,
                  options: q.options.map((o) => ({
                    ...o,
                    value: o.label,
                  })),
                };
              }
              return q;
            }),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gettingFormSuccess]);

  const { validationErrs, form } = state;

  const setFormData = (data) =>
    setState((prevState) => ({
      form: { ...prevState.form, ...data },
    }));

  const validateForm = () => {
    try {
      validate({
        ...state.form,
        questions: state?.form?.questions?.map((q) => ({
          ...q,
          options: q?.options?.map((o) => ({
            ...o,
            isAnswered: q?.answer?.includes(o.value),
          })),
        })),
      });
      setState({ validationErrs: { hasError: false }, httpError: false });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({
          validationErrs: { ...error.inner, hasError: true },
        });
      }
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();

    if (isValid) {
      try {
        await mutateAsync({
          ...state.form,
          email: cleanEmail(state.form.email),
          uniqueSlug,
        });
        navigate(GENERAL.INTEREST_THANK_YOU);
      } catch (error) {
        setState({
          httpError: error?.response?.data?.message,
          validationErrs:
            error?.response?.data?.data?.field === 'email'
              ? {
                  ...validationErrs,
                  email: error?.response?.data?.message,
                }
              : { ...validationErrs },
        });
      }
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  if (isGettingForm) {
    return <Loading />;
  }

  if (coachId) {
    return (
      <InterestFormWithCoachId
        coachId={coachId}
        gettingFormSuccess={gettingFormSuccess}
        state={state}
        setState={setState}
        isGettingForm={isGettingForm}
        adminView={adminView}
        data={data}
        handleSubmit={handleSubmit}
        httpError={httpError}
        isLoading={isLoading}
        setFormData={setFormData}
        validationErrs={validationErrs}
      />
    );
  }

  return (
    <FormComponent
      adminView={adminView}
      data={data}
      handleSubmit={handleSubmit}
      httpError={httpError}
      isLoading={isLoading}
      setFormData={setFormData}
      state={state}
      validationErrs={validationErrs}
    />
  );
};

export default InterestForm;
