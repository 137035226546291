const Copy3 = ({ width, height, color, ...props }) => (
  <svg
    width={width || '16'}
    height={height || '18'}
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.125 13.375V16.1875C11.125 16.705 10.705 17.125 10.1875 17.125H2.0625C1.81386 17.125 1.5754 17.0262 1.39959 16.8504C1.22377 16.6746 1.125 16.4361 1.125 16.1875V5.5625C1.125 5.045 1.545 4.625 2.0625 4.625H3.625C4.04381 4.62472 4.46192 4.65928 4.875 4.72834M11.125 13.375H13.9375C14.455 13.375 14.875 12.955 14.875 12.4375V8.375C14.875 4.65834 12.1725 1.57417 8.625 0.978335C8.21192 0.90928 7.79381 0.874717 7.375 0.875002H5.8125C5.295 0.875002 4.875 1.295 4.875 1.8125V4.72834M11.125 13.375H5.8125C5.56386 13.375 5.3254 13.2762 5.14959 13.1004C4.97377 12.9246 4.875 12.6861 4.875 12.4375V4.72834M14.875 10.25V8.6875C14.875 7.94158 14.5787 7.22621 14.0512 6.69876C13.5238 6.17132 12.8084 5.875 12.0625 5.875H10.8125C10.5639 5.875 10.3254 5.77623 10.1496 5.60041C9.97377 5.4246 9.875 5.18614 9.875 4.9375V3.6875C9.875 3.31816 9.80225 2.95243 9.66091 2.6112C9.51957 2.26998 9.3124 1.95993 9.05124 1.69876C8.79007 1.4376 8.48003 1.23043 8.1388 1.08909C7.79757 0.947749 7.43184 0.875002 7.0625 0.875002H6.125"
      stroke={color || 'white'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Copy3;
