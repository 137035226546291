import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'components';
import * as S from './style';
import Logo from './Logo';
import Icon from '../../Icon';
import { navRoutes } from 'constants';
import { useAuth } from 'context/auth';

export const LearnerMenuButton = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isMenu = location.pathname?.includes('menu');

  return isMenu ? (
    <Button.IconButton
      handleClick={() => navigate(-1)}
      aria-label={`Go back to your previous page`}
    >
      <Icon icon="cross" color="white" width="24" height="24" />
    </Button.IconButton>
  ) : (
    <Link
      to={navRoutes.LEARNER.MENU}
      aria-label={`Visit your ${user.role.toLowerCase()} menu page`}
    >
      <Icon
        icon="hamburger"
        color="white"
        style={{ cursor: 'pointer' }}
        width="36"
        height="32"
      />
    </Link>
  );
};

const LearnerHeader = ({ bgColor, ...props }) => {
  return (
    <S.Header bgColor={bgColor} {...props}>
      <S.HeaderContent>
        <Logo />
        <LearnerMenuButton />
      </S.HeaderContent>
    </S.Header>
  );
};

export default LearnerHeader;
