import { InvitesTable } from 'components/CommonTables';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import * as S from './style';
const FacilitatorsInvitesTable = ({ invites }) => {
  const isInviteThrowError = invites?.failedInvites?.length > 0;
  const isInviteSuccess = invites?.successInvites?.length > 0;

  const isInviteSuccessAndError = isInviteThrowError && isInviteSuccess;
  const isAllInviteSuccess = !isInviteThrowError && isInviteSuccess;
  const isAllInviteError = isInviteThrowError && !isInviteSuccess;

  const successInvitesDataSource =
    invites?.successInvites?.map((p) => {
      return {
        email: p.email,
        status: 'Invite sent',
        inviteLink: p.inviteLink,
        error: '-',
        isMultiOrganisationsFacilitator: p.isMultiOrganisationsFacilitator,
      };
    }) || [];

  const failedInvitesDataSource =
    invites?.failedInvites?.map((p) => {
      return {
        email: p.email,
        status: 'Error',
        error: p.error,
        inviteLink: '-',
        isMultiOrganisationsFacilitator: p.isMultiOrganisationsFacilitator,
      };
    }) || [];

  const invitesDataSource = [
    ...successInvitesDataSource,
    ...failedInvitesDataSource,
  ];

  return (
    <S.FacilitatorsInvitesTableWrapper>
      <Row ai="center" mb={5}>
        <Col w={[4, 6, 6]}>
          <S.TableTitleWrapper>
            <T.H2 color="neutralMain" size="medLarge" mb={4}>
              {isInviteSuccessAndError && 'Invites partially sent'}
              {isAllInviteSuccess && 'Invites sent'}
              {isAllInviteError && 'Invite error'}
            </T.H2>
            <T.P mb={6}>
              {isInviteSuccessAndError &&
                'Unfortunately there were some errors with sending out your invites. Below you can see which invites have been sent successfully and review those that encountered an error.'}
              {isAllInviteSuccess &&
                `All of your invitations were successful sent out and have now been
              received via email. You can also copy each invite link below if
              you with to send them a personal message.`}

              {isAllInviteError &&
                `Unfortunately we were not able to send out your invites. Below we have provided more details for you to review.`}
            </T.P>
          </S.TableTitleWrapper>
        </Col>
        <Col w={[4, 12, 12]}>
          <S.TableWrapper>
            <InvitesTable
              hideInviteLink={isAllInviteError}
              hideErrorColumn={isAllInviteSuccess}
              dataSource={invitesDataSource}
              dashboard
            />
          </S.TableWrapper>
        </Col>
      </Row>
    </S.FacilitatorsInvitesTableWrapper>
  );
};

export default FacilitatorsInvitesTable;
