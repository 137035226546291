import { Tabs } from 'antd';
import React, { useState } from 'react';
import * as T from 'components/Typography';
import * as S from '../style';
import GenericCommonTable from 'components/CommonTables/GenericCommonTable';
import { BasicButton } from 'components/Button';
import { navRoutes } from 'constants';
import { CopyLink } from 'components';
import { COACH } from 'constants/nav-routes';
import Tag from 'components/Tag';
import { useGetLearnerCoachSessionByCoachId } from 'api-calls/learner-coach-sessions.queries';
import moment from 'moment';
import { BackButton } from 'components/BackButton';

function AllSessions() {
  const [page, setPage] = useState(1);
  const [previousTabPage, setPreviousTabPage] = useState(1);
  const [status, setStatus] = useState('upcoming');
  const { data: sessions } = useGetLearnerCoachSessionByCoachId({
    status,
  });
  const columns = [
    {
      title: 'Session Date',
      dataIndex: 'start',
      key: 'start',
      render: (start) => (
        <T.P style={{ whiteSpace: 'nowrap' }}>
          {moment(start).format('DD MMM YYYY')}
        </T.P>
      ),
    },
    {
      title: 'Time',
      dataIndex: 'start',
      key: 'start',
      render: (start) => (
        <T.P style={{ whiteSpace: 'nowrap' }}>
          {moment(start).format('hh:mm A')}
        </T.P>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return (
          <div>
            {status === 'PENDING' ? (
              <Tag color="quinaryDark">
                <T.P color="white" weight="bold">
                  Pending
                </T.P>
              </Tag>
            ) : status === 'CANCELLED' ? (
              <Tag color="quaternaryMainHex">
                <T.P color="white" weight="bold">
                  Cancelled
                </T.P>
              </Tag>
            ) : status === 'BOOKED' ? (
              <Tag color="quinaryDark">
                <T.P color="white" weight="bold">
                  Booked
                </T.P>
              </Tag>
            ) : null}
          </div>
        );
      },
    },
    {
      title: 'Location',
      dataIndex: 'link',
      key: 'link',
      render: (_, row) => {
        const isOnline = row.type === 'ONLINE';

        let locationTxt = isOnline
          ? row.link
          : `${row.addressLine1}, ${row.town}, ${row.postcode}`;
        return (
          <CopyLink
            text={locationTxt}
            title={isOnline ? 'Copy Link' : 'Copy Address'}
          />
        );
      },
    },
    {
      title: 'Learner',
      dataIndex: 'learnerFullName',
      key: 'learnerFullName',
      render: (text) => <T.P style={{ whiteSpace: 'nowrap' }}>{text}</T.P>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <BasicButton
          variant="tertiary"
          height="40px"
          width="130px"
          icon="edit3"
          to={COACH.EDIT_COACHING_SESSION.replace(
            ':sessionIdP',
            record.id
          ).replace(':learnerIdP', record.learnerId)}
        >
          View/Edit
        </BasicButton>
      ),
    },
  ];
  const onChange = (key) => {
    let status = key === '1' ? 'upcoming' : 'previous';
    setStatus(status);
  };
  const items = [
    {
      key: '1',
      label: 'Upcoming',
      children: (
        <S.TableWrapper>
          <GenericCommonTable
            columns={columns}
            dataSource={sessions}
            isResponsiveScroll={true}
            pageSize={5}
            setPage={setPage}
            page={page}
            emptyState={{
              title: 'No coaching sessions found',
              subTitle: 'Click on arrange session to create a new session',
              buttonText: 'Arrange Session',
              buttonLink: navRoutes.COACH.CREATE_COACHING_SESSION,
            }}
          />
        </S.TableWrapper>
      ),
    },
    {
      key: '2',
      label: 'Previous',
      children: (
        <S.TableWrapper>
          <GenericCommonTable
            columns={columns}
            dataSource={sessions}
            isResponsiveScroll={true}
            pageSize={3}
            setPage={setPreviousTabPage}
            page={previousTabPage}
            emptyState={{
              title: 'No coaching sessions found',
              subTitle: 'Click on arrange session to create a new session',
              buttonText: 'Arrange Session',
              buttonLink: navRoutes.COACH.CREATE_COACHING_SESSION,
            }}
          />
        </S.TableWrapper>
      ),
    },
  ];
  return (
    <S.TabsWrapper>
      <BackButton link={navRoutes.COACH.DASHBOARD} my="12px" mx="8px" />
      <S.HR mb="16px" mt="32px" />
      <S.TableTitle>
        <div
          style={{
            flex: 2,
          }}
        >
          <T.H1 style={{ textWrap: 'nowrap' }}>Coaching Sessions</T.H1>
        </div>

        <BasicButton
          icon="calendar"
          iconProps={{ width: '32px' }}
          variant="tertiary"
          width="193px"
          to={navRoutes.COACH.CREATE_COACHING_SESSION}
          style={{
            whiteSpace: 'nowrap',
            backgroundColor: '#405ED6',
            color: 'white',
          }}
        >
          Arrange Session
        </BasicButton>
      </S.TableTitle>
      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
        indicator={{
          size: () => origin - 40,
          align: 'center',
        }}
      />
    </S.TabsWrapper>
  );
}

export default AllSessions;
