import { useMemo, useRef } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput } from '../../../../components/Inputs';
import * as S from './style';
import { Icon } from '../../../../components';
import { string, number, array, object } from 'yup';
import { MediaKeyInput } from '../../../components/MediaKeyInput';
import { mediaTypes, mediaInputLabels } from '../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';

const initialData = {
  options: [{ id: 0, option: '' }],
  textAudio: '',
  textAudioKey: '',
};

const ListenAndRepeatGridHidden = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateDataFields } = useStepForm(initialRef.current);
  const { data, validationErrs } = state;

  const options = useMemo(
    () =>
      data?.options?.map((e, i) => ({ ...e, id: i })) || [
        { id: 0, option: '' },
      ],
    [data?.options]
  );

  const addOption = () => {
    const maxId = Math.max(...options.map((e) => e.id), 0);
    updateDataFields({
      options: [...options, { id: maxId + 1, option: '', hide: false }],
    });
  };

  const removeOption = (id) => {
    updateDataFields({
      options: options.filter((e) => e.id !== id),
    });
  };

  const onOptionChange = (value, id) => {
    updateDataFields({
      options: options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  const onAudioKeyChange = (value, id) => {
    updateDataFields({
      options: options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, audioKey: value };
      }),
    });
  };

  return (
    <div>
      <BasicInput
        label={`Audio Text`}
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        m={{ mt: 8 }}
        error={validationErrs?.textAudio}
      />

      <MediaKeyInput
        label={`Audio Text Key`}
        type={mediaTypes.TEXT_AUDIO}
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs?.textAudioKey}
      />

      <T.P mt={8} size="large" weight="bold">
        Words
      </T.P>

      {validationErrs?.options &&
        typeof validationErrs.options === 'string' && (
          <T.P mb={2} mt={2} color="error">
            {validationErrs.options}
          </T.P>
        )}

      {options.map((e, i) => {
        return (
          <>
            <BasicInput
              label={`Word ${i + 1} - After reveal`}
              value={e.option}
              handleChange={(value) => {
                onOptionChange(value, e.id);
              }}
              m={{ mt: 5 }}
              helper="put % around the hidden letter, it will be bold after e.g. Farm => F%a%rm"
              error={
                validationErrs?.options && validationErrs?.options[i]?.option
              }
            />

            <MediaKeyInput
              index={i}
              type={mediaTypes.OPTION_AUDIO}
              label={`Word ${i + 1} ${mediaInputLabels.AUDIO_LABEL}`}
              value={e.audioKey}
              handleChange={(value) => onAudioKeyChange(value, e.id)}
              m={{ mt: 5 }}
              error={validationErrs?.options?.[i]?.audioKey}
            />

            {options.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeOption(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}
      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addOption}
        disabled={options.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Option
      </S.Button>
    </div>
  );
};

const validationSchema = {
  options: array()
    .of(
      object()
        .shape({
          id: number().required(),
          option: string().required('required field'),
          audioKey: string().required('required field'),
        })
        .required()
    )
    .required('you must provide words')
    .min(1, 'you must provide words'),
  textAudio: string().notRequired(),
  textAudioKey: string().notRequired(),
};
export { validationSchema };

export default ListenAndRepeatGridHidden;
