export default [
  {
    id: 1,
    label: 'What is your first name?',
    type: 'SHORT_ANSWER',
    key: 'firstName',
    required: true,
  },
  {
    id: 2,
    label: 'What is your last name?',
    type: 'SHORT_ANSWER',
    key: 'lastName',
    required: true,
  },
  {
    id: 3,
    label: 'What is your email?',
    type: 'SHORT_ANSWER',
    key: 'email',
    required: true,
  },
  {
    id: 4,
    label: 'What is your phone number?',
    type: 'SHORT_ANSWER',
    key: 'phoneNumber',
    required: true,
  },
  {
    id: 5,
    label: 'City',
    type: 'SHORT_ANSWER',
    key: 'city',
    required: true,
  },
  {
    id: 6,
    label: 'How many hours per week are you available?',
    type: 'SINGLE_CHOICE',
    key: 'availabilityHoursPerWeek',
    options: [
      {
        label: '1 to 2 hours',
        value: '1 to 2 hours',
        required: false,
      },
      {
        label: '3 to 4 hours',
        value: '3 to 4 hours',
        required: false,
      },
      {
        label: '4+ hours',
        value: '4+ hours',
        required: false,
      },
    ],
    required: true,
  },
  {
    id: 7,
    label: 'What time of day is best for you?',
    type: 'SINGLE_CHOICE',
    key: 'bestDayTime',
    options: [
      {
        label: 'Morning',
        value: 'MORNING',
        required: false,
      },
      {
        label: 'Afternoon',
        value: 'AFTERNOON',
        required: false,
      },
      {
        label: 'Evening',
        value: 'EVENING',
        required: false,
      },
      {
        label: 'Anytime',
        value: 'ANYTIME',
        required: false,
      },
    ],
    required: true,
  },
];
