import styled from '@emotion/styled';
import { setMargin } from '../../../helpers';

export const MilestoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.neutralLight};
  margin-left: 12px !important ;
  max-width: 350px;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacings[3]};
  padding: ${({ theme }) => theme.spacings[4]}
    ${({ theme }) => theme.spacings[5]};
`;

export const Wrapper = styled.div`
  ${setMargin}
  display: flex;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`;

export const OuterWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings[1]};
  width: 100%;
  display: flex;
`;

export const PageWrapper = styled.div`
  width: 100%;
`;
