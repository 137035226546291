import React from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from '../../Icon';
import * as S from './style';
import { antIcon } from '../Basic';

const defaultIcon = (variant) => {
  switch (variant) {
    case 'byYourSelf':
      return 'byYourSelf';
    case 'withCoach':
      return 'withCoach';
    default:
      return '';
  }
};

/**
 * Primary UI component for user interaction
 */
const BigButton = ({
  variant = 'byYourSelf',
  icon = defaultIcon(variant),
  text,
  selected,
  loading,
  handleClick,
  disabled,
  to,
  customColor,
  external,
  iconProps,
  card,
  children,
  ...props
}) => {
  const navigate = useNavigate();
  const onClick = (e) => {
    if (external) return;
    if (to) navigate(to);
    if (handleClick instanceof Function) handleClick(e);
  };

  if (external) {
    props.href = to;
    props.target = '_blank';
  }

  const btnTxtVariations = {
    byYourSelf: 'By yourself',
    withCoach: 'With coach',
    custom: text,
  };

  return (
    <S.BigButton
      type="button"
      variant={variant}
      selected={selected}
      disabled={disabled}
      card={card}
      onClick={onClick}
      as={external ? 'a' : 'button'}
      icon={icon}
      loading={loading}
      aria-disabled={disabled ? 'true' : 'false'}
      {...props}
    >
      {icon && (
        <S.IconWrapper variant={variant}>
          <Icon icon={icon} {...iconProps} />
        </S.IconWrapper>
      )}
      <S.TextWrapper variant={variant}>
        {btnTxtVariations[variant] || 'With coach'}
      </S.TextWrapper>
      {loading && <S.BigLoading variant={variant} indicator={antIcon} />}
    </S.BigButton>
  );
};

export default BigButton;
