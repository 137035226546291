import { useAuth } from 'context/auth';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import theme from 'theme';
import { courseCodeTypes, navRoutes, userRoles } from '../../../constants';
import Icon from '../../Icon';
import * as S from './style';
import { useSiteSettings } from 'context/site-settings';
import { useOrganisationDetails } from 'context/organisation-details';

const Logo = ({ hideCustomLogo, hideMainLogo }) => {
  const { user } = useAuth();
  const { organisationDetails: { logoUrl } = {} } = useOrganisationDetails();
  const { courseCode } = useSiteSettings();
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.mobile})`,
  });
  let link = !!user.id
    ? navRoutes[user?.role]?.DASHBOARD || navRoutes.COMMON.SELECT_ORGANISATION
    : navRoutes.GENERAL.LOGIN;
  if (user.role === userRoles.CONTENT_EDITOR) {
    link = navRoutes.CMS.BASE;
  }

  return (
    <S.LogoWrapper>
      {logoUrl && !hideCustomLogo && <S.CustomLogo src={logoUrl} />}
      {!hideMainLogo && (
        <Link to={link}>
          <Icon
            icon={courseCode === courseCodeTypes.TPD ? 'logo' : 'cmidLogo'}
            color="white"
            height={hideCustomLogo && isMobile ? 24 : 32}
            width={hideCustomLogo && isMobile ? 151 : 214}
          />
        </Link>
      )}
    </S.LogoWrapper>
  );
};

export default Logo;
