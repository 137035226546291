import theme from '../../../theme';

const Awaiting = ({ width, height, color, ...props }) => (
  <svg
    width={width || '40'}
    height={height || '40'}
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66719 11.9998C8.66719 15.9763 11.8907 19.1998 15.8672 19.1998C19.8436 19.1998 23.0672 15.9763 23.0672 11.9998C23.0672 8.02335 19.8436 4.7998 15.8672 4.7998C11.8907 4.7998 8.66719 8.02335 8.66719 11.9998ZM27.8672 32.7998C27.8672 26.1724 22.4946 20.7998 15.8672 20.7998C9.23977 20.7998 3.86719 26.1724 3.86719 32.7998V35.9998C3.86719 36.4416 4.22536 36.7998 4.66719 36.7998H27.0672C27.509 36.7998 27.8672 36.4416 27.8672 35.9998V32.7998Z"
      fill={color || theme.colors.neutralMain}
    />
    <path
      d="M31.067 12.7998C27.5405 12.7998 24.667 15.6733 24.667 19.1998C24.667 22.7263 27.5405 25.5998 31.067 25.5998C34.5935 25.5998 37.467 22.7263 37.467 19.1998C37.467 15.6733 34.5935 12.7998 31.067 12.7998ZM31.067 24.5549C28.121 24.5549 25.7119 22.1458 25.7119 19.1998C25.7119 16.2538 28.121 13.8447 31.067 13.8447C34.013 13.8447 36.4221 16.2538 36.4221 19.1998C36.4221 22.1458 34.0275 24.5549 31.067 24.5549Z"
      fill={color || theme.colors.neutralMain}
      stroke="#050D30"
      strokeWidth="0.5"
    />
    <path
      d="M30.5359 19.9452V16.4509C30.5359 16.2004 30.2984 16 30.0014 16C29.7044 16 29.4668 16.2004 29.4668 16.4509V20.1331C29.4668 20.1957 29.4816 20.2458 29.5113 20.2959C29.5113 20.2959 29.5113 20.2959 29.5113 20.3084C29.541 20.3585 29.5707 20.4086 29.6301 20.4587L31.7684 22.2622C31.8724 22.3499 32.006 22.4 32.1397 22.4C32.2733 22.4 32.4069 22.3499 32.5109 22.2622C32.7188 22.0869 32.7188 21.7988 32.5109 21.6235L30.5359 19.9452Z"
      fill={color || theme.colors.neutralMain}
      stroke="#050D30"
      strokeWidth="0.5"
    />
  </svg>
);
export default Awaiting;
