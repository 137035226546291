import Question from './Question';
import Icon from 'components/Icon';
import * as S from './style';
import { recruitmentStaticQuestions as data } from 'constants';

const StaticQuestions = () => {
  return data.map((question) => (
    <S.StaticQuestionsWrapper>
      <Icon
        icon="sixDots"
        width={26}
        height={26}
        color="neutral50"
        mt={4}
        mr={4}
      />
      <Question question={question} staticQuestion />
    </S.StaticQuestionsWrapper>
  ));
};

export default StaticQuestions;
