import { FEEDBACK_BASE } from '../constants';
import { usePost, formatQueryError, useFetch } from '../utils/queries';

function useCreateFeedback(_, options = {}) {
  const context = usePost({
    url: `${FEEDBACK_BASE}`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [[FEEDBACK_BASE]],
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
}

function useGetFeedbacks({ targetRole }, options = {}) {
  const context = useFetch({
    key: [`GET_FEEDBACKS`, { targetRole }],
    url: `${FEEDBACK_BASE}`,
    axiosParams: { targetRole },
    reactQueryConfig: options,
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
}

export { useCreateFeedback, useGetFeedbacks };
