import { Route, Routes } from 'react-router-dom';
import { Route as CustomRoute } from './../../components';
import {
  TrainingDetailPage,
  TrainingComplete,
  TrainingReview,
  TrainingCustomization,
  TrainingCustomizationAddStep,
  TrainingCustomizationEditStep,
  TrainingCustomizationAddResources,
  TrainingCustomizationEditResources,
  Training,
} from 'pages/Facilitator/Training';

import Signup from '../CommonPages/Signup';

import Dashboard from './Dashboard';

import { navRoutes, userRoles } from './../../constants';

const { BASE } = navRoutes.FACILITATOR;
function FacilitatorRouter() {
  return (
    <Routes>
      <Route
        path={navRoutes.FACILITATOR.SIGNUP.replace(BASE, '')}
        element={
          <CustomRoute
            Component={Signup}
            layout="side"
            sideColor="neutralLight"
            image="secondaryColor"
            publicOnly
          />
        }
      />

      <Route
        path={navRoutes.FACILITATOR.DASHBOARD.replace(BASE, '')}
        element={
          <CustomRoute
            Component={Dashboard}
            layout="full"
            isPrivate
            allowedRoles={[userRoles.FACILITATOR]}
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.TRAINING.replace(BASE, '')}
        element={<Training />}
      />
      <Route
        path={navRoutes.FACILITATOR.TRAINING_STEP.replace(BASE, '')}
        element={
          <CustomRoute
            Component={TrainingDetailPage}
            isPrivate
            layout="full"
            overlayColor
            allowedRoles={[userRoles.FACILITATOR, userRoles.ORGANISATION_ADMIN]}
            facilitatorView
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.TRAINING_COMPLETE.replace(BASE, '')}
        element={
          <CustomRoute
            Component={TrainingComplete}
            isPrivate
            layout="center"
            overlayColor
            allowedRoles={[userRoles.FACILITATOR, userRoles.ORGANISATION_ADMIN]}
            facilitatorView
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.TRAINING_REVIEW.replace(BASE, '')}
        element={
          <CustomRoute
            Component={TrainingReview}
            isPrivate
            layout="center"
            overlayColor
            allowedRoles={[userRoles.FACILITATOR, userRoles.ORGANISATION_ADMIN]}
            facilitatorView
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.TRAINING_CUSTOMIZATION.replace(BASE, '')}
        element={
          <CustomRoute
            Component={TrainingCustomization}
            isPrivate
            layout="full"
            overlayColor
            allowedRoles={[userRoles.FACILITATOR, userRoles.ORGANISATION_ADMIN]}
            facilitatorView
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.TRAINING_CUSTOMIZATION_ADD_STEP.replace(
          BASE,
          ''
        )}
        element={
          <CustomRoute
            Component={TrainingCustomizationAddStep}
            isPrivate
            layout="center"
            overlayColor
            allowedRoles={[userRoles.FACILITATOR, userRoles.ORGANISATION_ADMIN]}
            facilitatorView
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.TRAINING_CUSTOMIZATION_EDIT_STEP.replace(
          BASE,
          ''
        )}
        element={
          <CustomRoute
            Component={TrainingCustomizationEditStep}
            isPrivate
            layout="center"
            overlayColor
            allowedRoles={[userRoles.FACILITATOR, userRoles.ORGANISATION_ADMIN]}
            facilitatorView
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.TRAINING_ADD_RESOURCES.replace(BASE, '')}
        element={
          <CustomRoute
            Component={TrainingCustomizationAddResources}
            isPrivate
            layout="center"
            overlayColor
            allowedRoles={[userRoles.FACILITATOR, userRoles.ORGANISATION_ADMIN]}
            facilitatorView
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.TRAINING_EDIT_RESOURCES.replace(BASE, '')}
        element={
          <CustomRoute
            Component={TrainingCustomizationEditResources}
            isPrivate
            layout="center"
            overlayColor
            allowedRoles={[userRoles.FACILITATOR, userRoles.ORGANISATION_ADMIN]}
            facilitatorView
          />
        }
      />
    </Routes>
  );
}

export default FacilitatorRouter;
