import React from 'react';

const UserWithPlusFill = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '33'}
    height={height || '32'}
    fill="none"
    viewBox="0 0 33 32"
  >
    <path
      fill={color || '#fff'}
      fill-rule="evenodd"
      d="M5.62 9.6a5.76 5.76 0 1 0 11.52 0 5.76 5.76 0 0 0-11.52 0Zm15.36 16.64a9.6 9.6 0 0 0-19.2 0v2.56c0 .353.286.64.64.64h17.92a.64.64 0 0 0 .64-.64v-2.56Z"
      clip-rule="evenodd"
    />
    <path
      fill={color || '#fff'}
      d="M20.596 15.614c-.538 0-.896-.402-.896-.896 0-.493.358-.896.896-.896h2.688v-2.688c0-.538.403-.896.896-.896.494 0 .896.358.896.896v2.688h2.688c.538 0 .896.403.896.896 0 .494-.358.896-.896.896h-2.688v2.688c0 .539-.402.896-.896.896-.493 0-.896-.357-.896-.896v-2.688h-2.688Z"
    />
  </svg>
);

export default UserWithPlusFill;
