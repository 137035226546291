import { useEffect, useState } from 'react';
import { ConfirmationSuccessModal } from '../../components';
import { useGetUserHasNewMsgs } from 'api-calls/chat-messages.queries';
import { useNavigate } from 'react-router-dom';
import { LEARNER } from '../../constants/nav-routes';
import { useAuth } from 'context/auth';

const NewMsgsModal = () => {
  const [hasMessage, setHasMessage] = useState(false);
  const { data, isLoading } = useGetUserHasNewMsgs();
  const { user } = useAuth();
  const navigate = useNavigate();
  const handleCloseConfirm = () => {
    setHasMessage(false);
    navigate(LEARNER.CHAT_WITH_COACH.replace(':receiverId', user?.coachId));
  };

  useEffect(() => {
    if (!isLoading && data) {
      setHasMessage(data.hasNewMsgs);
    }
  }, [data, isLoading]);

  return (
    <ConfirmationSuccessModal
      variant="withAudio"
      visible={hasMessage}
      setIsModalVisible={(status) => setHasMessage(status)}
      title="New message"
      titleAudioKey="newMessage"
      description="a new message from your coach"
      descriptionAudioKey="aNewMessageFromYourCoach"
      onConfirm={handleCloseConfirm}
      confirmText="View message"
      confirmTextAudioKey="viewMessage"
      cancelText="Cancel"
      cancelTextAudioKey="cancel"
      onCancel={() => setHasMessage(false)}
    />
  );
};

export default NewMsgsModal;
