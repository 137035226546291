import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

export const QuestionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacings[3]} 0;
`;

export const BasicQuestionWrapper = styled.div`
  width: 100%;
  display: flex;
  ${({ theme }) => theme.media.tablet} {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 16px;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: 24px;
`;

export const QuestionLabelInput = styled(TextField)`
  width: ${({ charLength }) => (charLength ? `${charLength}ch` : '100%')};
  max-width: 500px;
  display: flex;
  flex: ${({ charLength }) => (charLength ? 'none' : '1')};
  border: none;
  color: ${({ theme }) => theme.colors.neutralMain};
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  ${({ theme }) => theme.media.mobile} {
    width: auto;
    flex: 1;
  }
`;

export const QuestionTypeDropdown = styled(TextField)`
  width: 100%;
  max-width: ${({ staticQuestion }) => (staticQuestion ? '366px' : '218px')};
  background: ${({ theme, staticQuestion }) =>
    staticQuestion ? theme.colors.neutral30 : 'none'};

  .MuiSelect-select {
    padding: 12px 12px 12px 24px;
  }

  .MuiButtonBase-root
    MuiMenuItem-root
    MuiMenuItem-gutters
    MuiMenuItem-root
    MuiMenuItem-gutters
    css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  }
`;

export const QuestionTypeDropdownItem = styled(MenuItem)`
  padding: 12px 12px 12px 24px;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -42px;
`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) =>
    `${theme.spacings[2]} 0 ${theme.spacings[2]} ${theme.spacings[2]}`};
  width: 100%;
`;

export const AddOptionButton = styled.button`
  display: flex;
  align-items: center;
  padding: ${({ theme }) =>
    `${theme.spacings[2]} 0 ${theme.spacings[2]} ${theme.spacings[2]}`};
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.primaryMain};
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
`;

export const InputCheckWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`;
