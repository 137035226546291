import React from 'react';
import * as T from '../../Typography';
import * as CS from '../style';
import * as S from './style';
import Icon from './../../Icon';

const BasicInputWrapper = ({
  InputComponent,
  disabled,
  wrapperStyle,
  label,
  helper,
  optional,
  labelColor,
  w,
  m,
  color,
  suffix,
  error,
}) => {
  return (
    <CS.Field w={w} disabled={disabled} {...m} style={wrapperStyle}>
      {label && (
        <CS.Label htmlFor={label} mb={helper ? '1' : '2'}>
          <T.P weight="bold" color={labelColor || color} m="0">
            {label}
          </T.P>
          {optional && <CS.OptionalTag ml="1">(optional)</CS.OptionalTag>}
        </CS.Label>
      )}
      {helper && (
        <T.P isSmall color={'neutral80'} mb="2" ml="2">
          {helper}
        </T.P>
      )}
      <S.BasicInputRelativeDiv>
        <S.InputWrapper>{InputComponent}</S.InputWrapper>

        {suffix && <S.AlertWrapper>{suffix}</S.AlertWrapper>}
        {error && (
          <S.AlertWrapper>
            <Icon width="28px" height="28px" icon="alert" color="error" />
          </S.AlertWrapper>
        )}
      </S.BasicInputRelativeDiv>
      {error && (
        <T.P color="error" m="0" mt="2" mx={2}>
          {error}
        </T.P>
      )}
    </CS.Field>
  );
};

export default BasicInputWrapper;
