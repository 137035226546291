import Header from '../Header';
import VoiceToggle from '../../../CMS/components/VoiceToggle';
import * as S from './style';
import { BackButton } from 'components/BackButton';
import { Col, Row } from 'components/Grid';

const CMSLayout = ({ children, goBackUrl, withBackButton = true }) => {
  return (
    <div>
      <Header />
      <S.Wrapper>
        <Row jc="space-between" ai="baseline" my={6}>
          <Col w={[2, 4, 4]} jc="start">
            {withBackButton && <BackButton link={goBackUrl} />}
          </Col>
          <Col w={[2, 4, 4]} jc="end">
            <VoiceToggle />
          </Col>
        </Row>
        {children}
      </S.Wrapper>
    </div>
  );
};

export default CMSLayout;
