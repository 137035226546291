import React, { useRef, useState } from 'react';
import CanvasDraw from 'react-canvas-draw';
import theme from 'theme';
import { Col, Row } from '../../../../components/Grid';
import { ClearButton, PlayButton } from '../../../../components/Button';
import StepFooterButtons from '../StepFooterButtons';
import * as T from '../../../../components/Typography';
import * as S from './style';

const Draw = ({
  data: { textAudio, textAudioUrl } = {},
  imageUrl,
  handleNext,
  disabled,
  stuckAtReview,
  preview,
}) => {
  const canvasRef = useRef(null);
  const [enableNext, setEnableNext] = useState(false);

  return (
    <S.Wrapper>
      <Row jc="center" mb={6}>
        {textAudio && (
          <Col w={[4, 8, 8]} mt={6} jc="center">
            {textAudioUrl ? (
              <PlayButton
                audioUrl={textAudioUrl}
                iconProps={{ width: '32px', height: '32px' }}
                padding="12px"
                weight="bold"
                fallbackText={textAudio}
                size="extraLarge"
              >
                {textAudio}
              </PlayButton>
            ) : (
              <T.P size="extraLarge" weight="bold">
                {textAudio}
              </T.P>
            )}
          </Col>
        )}
      </Row>
      <Row jc="center" mb={6}>
        <Col w={[4, 7, 7]} mt={6}>
          <CanvasDraw
            brushColor={`${theme.colors.neutralMain}`}
            imgSrc={imageUrl}
            gridColor={theme.colors.neutral80}
            hideGrid={false}
            hideInterface={true}
            canvasWidth={800}
            canvasHeight={400}
            gridSizeX={15}
            gridSizeY={15}
            gridLineWidth={0.5}
            hideGridX={false}
            hideGridY={false}
            ref={canvasRef}
            onChange={() => {
              setEnableNext(true);
            }}
          />
        </Col>
        <Col w={[4, 7, 7]} mt={6}>
          <ClearButton
            handleClick={() => {
              canvasRef.current.clear();
              setEnableNext(false);
            }}
          />
        </Col>
      </Row>
      <Row jc="center" mb={6}>
        <Col w={[4, 7, 7]} mt={6}>
          <StepFooterButtons
            stuckAtReview={stuckAtReview}
            handleNext={handleNext}
            preview={preview}
            disabled={disabled}
            enableNext={enableNext}
          />
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default Draw;
