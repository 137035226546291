import * as S from './style.js';
import * as CS from './../style';
import * as T from '../../Typography';

const MuiDropDownWrapper = ({
  label,
  color,
  error,
  helper,
  w,
  disabled,
  multi,
  m,
  search,
  optional,
  children,
  answerProps,
}) => {
  const open = false;
  const focus = false;
  const decideColor = () => {
    if (error) return 'error';
    return color;
  };
  return (
    <S.Field
      w={w}
      disabled={disabled}
      open={open}
      focus={focus}
      multi={multi}
      color={decideColor()}
      error={error}
      search={search}
      {...m}
    >
      {' '}
      {label && (
        <CS.Label htmlFor={label} mb={helper ? '1' : '2'}>
          <T.P weight="bold" mb={helper ? '0' : '4'}>
            {label}
          </T.P>
          {optional && <CS.OptionalTag ml="1">(optional)</CS.OptionalTag>}
        </CS.Label>
      )}
      {helper && (
        <T.P color="neutralMain" mb="4" ml={2}>
          {helper}
        </T.P>
      )}
      <S.Answer {...answerProps}>{children}</S.Answer>
      {error && (
        <T.P color="error" m="0" mt="1">
          {error}
        </T.P>
      )}
    </S.Field>
  );
};

export default MuiDropDownWrapper;
