import { useMemo, useRef } from 'react';
import { Typography as T } from '../../../../../components';
import { BasicInput, Checkbox } from '../../../../../components/Inputs';
import { MediaKeyInput } from '../../../../components/MediaKeyInput';
import MDEditor from '../../../../../components/MDEditor';
import { mediaTypes } from '../../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import * as S from '../style';

export { validationSchema } from './validation';

const initialData = {
  textAudio: '',
  textAudioKey: '',
  rowSize: 1,
  columnSize: 1,
  headers: [''],
  options: [{ id: 0, option: '', showValue: false }],
};

const ColumnsTyping = () => {
  const initialRef = useRef(initialData);
  const { state, updateDataFields, updateFormMetaData } = useStepForm(
    initialRef.current
  );
  const { data = initialData, validationErrs } = state;

  const handleRowSizeChange = ({ rowSize, columnSize }) => {
    const isRowSize = rowSize !== undefined;
    let value = isRowSize ? rowSize : columnSize;
    let updatedKey = '';
    let validationErrsKey = '';
    if (isRowSize) {
      updatedKey = 'rowSize';
      validationErrsKey = 'rowSize';
    } else {
      updatedKey = 'columnSize';
      validationErrsKey = 'columnSize';
    }

    const newOptions = [];
    let currentIndex = 0;

    Array.from({ length: isRowSize ? value : data.rowSize }).forEach(() => {
      Array.from({ length: !isRowSize ? value : data.columnSize }).forEach(
        () => {
          newOptions.push({
            id: currentIndex,
            option: data.options[currentIndex]?.option || '',
            showValue: data.options[currentIndex]?.showValue || false,
          });
          currentIndex += 1;
        }
      );
    });

    const updatedFields = { options: newOptions, [updatedKey]: value };
    if (!isRowSize) {
      updatedFields.headers = Array.from(
        { length: value },
        (_, i) => data.headers[i] || ''
      );
    }
    updateDataFields(updatedFields);
    updateFormMetaData({
      validationErrs: {
        ...validationErrs,
        [validationErrsKey]: '',
      },
    });
  };

  const onOptionChange = ({ key, value, id }) => {
    updateDataFields({
      options: data.options.map((e) => {
        if (e.id !== id) return e;
        return {
          ...e,
          [key]: value,
        };
      }),
    });
  };

  const onHeaderChange = ({ value, index }) => {
    updateDataFields({
      headers: data.headers.map((e, i) => {
        if (i !== index) return e;
        return value;
      }),
    });
  };

  const showSquares =
    data.rowSize >= 1 && data.columnSize >= 1 && data.columnSize <= 3;

  const rows = useMemo(() => {
    if (!showSquares) {
      return [];
    }
    const options = data.options || [];
    const result = [];
    let index = 0;
    let rowLength = Number(data.columnSize);

    while (index < options.length) {
      result.push(options.slice(index, index + rowLength));
      index += rowLength;
    }

    return result;
  }, [data.options, data.columnSize, showSquares]);

  return (
    <div>
      <MDEditor
        label="Explainer Text"
        value={data.textAudio}
        onChange={(textAudio) => updateDataFields({ textAudio })}
        mode={'edit'}
        height={80}
        m={{ mt: 8 }}
        error={validationErrs?.textAudio}
      />
      <MediaKeyInput
        label="Explainer Audio"
        type={mediaTypes.TEXT_AUDIO}
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs.textAudioKey}
        requireVo
      />

      <S.AnswerInputWrapper>
        <BasicInput
          label="Row Size"
          value={data.rowSize}
          handleChange={(rowSize) => handleRowSizeChange({ rowSize })}
          m={{ mt: 8 }}
          type="number"
          error={validationErrs.rowSize}
        />
        <BasicInput
          label="Column Size"
          value={data.columnSize}
          handleChange={(columnSize) => handleRowSizeChange({ columnSize })}
          m={{ mt: 8 }}
          type="number"
          error={validationErrs.columnSize}
        />
      </S.AnswerInputWrapper>
      {showSquares && (
        <>
          <T.P mt="6" mb="3">
            Fill in the column headers.
          </T.P>
          <S.AnswerInputWrapper>
            {data.headers.map((_, index) => (
              <S.BoxWrapper key={index}>
                <BasicInput
                  placeholder=""
                  value={data.headers[index]}
                  handleChange={(value) => {
                    onHeaderChange({ index, value });
                  }}
                  bgColor="neutralSurface"
                  error={validationErrs?.headers?.[index]}
                  hideErrorMsg
                  textAlign="center"
                />
              </S.BoxWrapper>
            ))}
          </S.AnswerInputWrapper>
          <T.P mt="6">
            Fill in all the inputs with the correct values. uncheck the box if
            you want to hide the value and allow the user to fill it.
          </T.P>
          <div>
            {rows.map((rowOptions, rowIndex) => (
              <S.AnswerInputWrapper>
                {rowOptions.map((option) => {
                  const id = option.id;
                  const error = validationErrs?.options?.[id]?.option;

                  return (
                    <S.BoxWrapper>
                      <BasicInput
                        key={id}
                        placeholder=""
                        value={option.option}
                        type="number"
                        handleChange={(value) => {
                          onOptionChange({
                            key: 'option',
                            value,
                            id,
                          });
                        }}
                        bgColor={option.showValue ? 'neutralSurface' : ''}
                        borderColor={
                          error
                            ? 'error'
                            : option.showValue
                            ? ''
                            : 'primaryMain'
                        }
                        error={error}
                        hideErrorMsg
                        textAlign="center"
                      />
                      <Checkbox
                        w="33px"
                        plain
                        noPadding
                        checked={option.showValue}
                        handleChange={(checked) => {
                          onOptionChange({
                            key: 'showValue',
                            value: checked,
                            id,
                          });
                        }}
                        error={validationErrs.confirmedPermissionType}
                        hideErrorMsg
                      />
                    </S.BoxWrapper>
                  );
                })}
              </S.AnswerInputWrapper>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ColumnsTyping;
