import styled from '@emotion/styled';
import { setMargin } from '../../../helpers';
import PlayBtnBg from '../../../components/assets/PlayBtnBg.jpg';
import SkyBg from '../../../components/assets/skyBg.svg';
import SkyBgSm from '../../../components/assets/skyBgSm.svg';
import cornerCircleBg from 'components/assets/cornerCircleBg.png';

import Mother from '../../../components/assets/Mother.jpeg';
import { Button, Typography as T } from 'components';
export const Wrapper = styled.div`
  width: 100%;
  padding-top: 0;

  @media (max-width: 768px) {
    padding-left: 0;
    & > * {
      padding-left: 0;
    }
  }
`;

export const BgGray = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12vw;
  flex-wrap: wrap;
  padding: 48px;
  padding-bottom: 140px;
  background-color: #fbf9fb;
  background-repeat: no-repeat;
  background-size: auto;
  @media (max-width: 768px) {
    & > *:nth-child(1) {
      order: 2;
    }
  }
`;

export const MotherImageWrapper = styled.div`
  position: relative;
  width: 384px;
  height: 384px;
  ${({ theme }) => theme.media.mobile} {
    width: 316px;
    height: 316px;
  }
`;

export const CustomHeight = styled.div``;

export const BgWrapper = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${PlayBtnBg});

  width: Min(40vw, 400px);
  height: Min(40vw, 400px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 10px solid ${({ theme }) => theme.colors.secondaryMain};
  cursor: pointer;
  background-position: center;
  @media (max-width: 768px) {
    width: 347px;
    height: 327px;
    margin-top: 0px;
  }
`;
export const PlayButtonWrapper = styled.div`
  ${setMargin};
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled(T.P)`
  color: var(--Current-Neutral-Main, #050d30);
  font-family: Aller;
  font-size: 32px !important;
  line-height: 32px !important;
  font-style: normal;
  font-weight: 700;
  padding: 14px 45px 12px 43px;
  background-repeat: no-repeat;
  background-size: auto;
  background-image: url(${SkyBg});
  min-width: fit-content;
  background-size: cover;
  @media (max-width: 768px) {
    font-size: 19px !important;
    line-height: 19px !important;
    padding: 19px 10px 12px 30px;
    background-image: url(${SkyBgSm});
  }
`;

export const OpenButton = styled.button`
  width: 116px;
  height: 116px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  background-color: ${({ theme }) => theme.colors.secondaryMain};
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const OverlayContent = styled.div`
  width: 800px;
  ${({ theme }) => theme.media.tablet} {
    width: 100%;
  }
`;

export const CloseButton = styled(Button.IconButton)`
  position: absolute;
  z-index: 1001;
  top: calc(20px + ${({ theme }) => theme.constants.layout.header.height});
  right: 20px;
  font-size: 24px;
  color: white;
  cursor: pointer;
  background: none;
  border: none;
  pointer-events: all;
  svg {
    width: 72px;
    height: 72px;
  }
  ${({ theme }) => theme.media.tablet} {
    top: 10px;
    right: 10px;
    padding: 10px;
    svg {
      width: 40px;
      height: 40px;
    }
  }
`;

export const ContentAndVoiceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${setMargin};
`;

export const QuoteWithAuthor = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
`;

export const ClickBtn = styled(Button.BasicButton)`
  width: ${(props) => props.width || '50%'};
  font-size: 16px !important;
  padding-right: 43px;
  @media (max-width: 768px) {
    margin-left: auto !important;
  }
`;

export const BgMotherWrapper = styled.div`
  position: absolute;
  width: 316px;
  height: 316px;
  border-radius: 50%;
  right: -17%;
  bottom: -77px;
  border: 61px solid #e6e7ed;
  ${({ theme }) => theme.media.mobile} {
    width: 230px;
    height: 230px;
  }
`;

export const MiddleCircle = styled.div`
  position: absolute;
  background-repeat: no-repeat;
  background-size: auto;
  background-image: url(${Mother});
  width: 384px;
  height: 384px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  border: 10px solid #e6e7ed;
  ${({ theme }) => theme.media.mobile} {
    width: 280px;
    height: 280px;
  }
`;
export const Text = styled.p`
  font-size: 20px !important;
  font-weight: 400;
  line-height: 160%;
  width: 474px;
  margin-left: 16px;
  @media (max-width: 768px) {
    width: 279px;
    font-size: 16px !important;
  }
`;

export const DefaultHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5vw;
  padding: 3vw;

  background-image: url(${cornerCircleBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 -81px;
`;
