import { useState, useRef } from 'react';
import { userRoles } from './../constants';
import { createContext, useReducer, useContext, useEffect } from 'react';
import { useGetSelectedLearner } from './../api-calls/users.queries';
import { useAuth } from './auth';

const initialState = {
  headerSteps: {
    show: false,
    requiredSteps: null,
    completedSteps: null,
  },
  learnerUniqueId: '',
  switchAccount: false,
  preferredVoice: 'MALE',
  withCoach: false,
  exerciseFlow: 'LEARNING', // LEARNING, REVIEW_SELF_LEARNING, PLAYGROUND, PREPARATION
  lastCompletedExerciseId: null, // used when exerciseFlow === 'PLAYGROUND'
};

const getStateFromLocalStorage = () => {
  const state = JSON.parse(localStorage.getItem('generalState'));
  if (state && state.exerciseFlow) {
    return state;
  } else {
    return initialState;
  }
};

const storeStateIntoStorage = (user) => {
  localStorage.setItem('generalState', JSON.stringify(user));
};

const _getPreferredVoice = (
  localStorageVoice,
  user,
  state,
  selectedLearnerInfo
) => {
  const { preferredVoice } = state;

  if (localStorageVoice === 'MALE' || localStorageVoice === 'FEMALE') {
    return localStorageVoice;
  }

  if (
    user.role !== userRoles.CONTENT_EDITOR &&
    selectedLearnerInfo?.preferredVoice
  ) {
    return selectedLearnerInfo.preferredVoice;
  }

  return user?.preferredVoice || preferredVoice;
};

const GeneralStateContext = createContext({
  state: initialState,
  setState: () => {},
});

function reducer(state, newState) {
  storeStateIntoStorage({ ...state, ...newState });
  return { ...state, ...newState };
}

const GeneralStateProvider = (props) => {
  const playingAudioRef = useRef(); // used in UseAudio.js, used to control the current playing audio
  const [state, setState] = useReducer(reducer, getStateFromLocalStorage());
  const [localStorageVoice, setLocalStorageVoice] = useState(
    localStorage.getItem('voice')
  );

  const { user } = useAuth();

  const { data: selectedLearnerInfo } = useGetSelectedLearner(null, {
    enabled: !!(
      user?.id &&
      user?.role === userRoles.COACH &&
      user.selectedLearnerId
    ),
  });

  useEffect(() => {
    if (!localStorageVoice) {
      localStorage.setItem('voice', 'INIT');
      setLocalStorageVoice('INIT');
    }
  }, [localStorageVoice]);

  const value = {
    state: {
      ...state,
      switchAccount: state?.switchAccount || false,
      isPlayground: state.exerciseFlow === 'PLAYGROUND',
      isPreparation: state.exerciseFlow === 'PREPARATION',
      isLearning: state.exerciseFlow === 'LEARNING',
      preferredVoice: _getPreferredVoice(
        localStorageVoice,
        user,
        state,
        selectedLearnerInfo
      ),
      selectedLearnerInfo,
    },
    setState: setState,
    playingAudioRef,
  };

  return <GeneralStateContext.Provider value={value} {...props} />;
};

const useGeneralState = () => {
  const value = useContext(GeneralStateContext);
  return value;
};

export { GeneralStateProvider, useGeneralState };
export default GeneralStateContext;
