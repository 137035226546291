import styled from '@emotion/styled';
import * as T from '../../Typography';
import { setMargin } from 'helpers';
import { BasicButton } from '../../Button';

export const List = styled(T.Ul)`
  ${setMargin}
  list-style-type: initial;
  padding-left: 1rem;
  margin-bottom: 2rem;
`;

export const ListItem = styled(T.Li)`
  ${setMargin}
  list-style: initial;
  list-style-type: initial;
  line-height: 24px !important;
`;

export const SayHelloBtn = styled(BasicButton)`
  width: fit-content;
  padding: 1rem 3rem;
`;
