const ToggleActive = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 39 24"
  >
    <rect width="39" height="24" rx="12" fill={color || '#002666'} />
    <rect x="16.5" y="1.5" width="21" height="21" rx="10.5" fill="white" />
  </svg>
);

export default ToggleActive;
