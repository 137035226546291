import { Col, Row } from '../../components/Grid';

import * as T from '../../components/Typography';
import * as S from './style';

const ThankYou = () => {
  return (
    <S.Wrapper>
      <Row>
        <Col w={[4, 12, 12]}>
          <T.H2>Thank you for submitting the interest form!</T.H2>
          <T.P mt={3}>
            You will be contacted soon with next steps. This usually takes 2-3
            working days.
          </T.P>
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default ThankYou;
