import { string, array, object, number, boolean } from 'yup';

export const validationSchema = {
  rowSize: number()
    .typeError('required field')
    .min(2, 'Row size must be between 2 and 4')
    .max(4, 'Row size must be between 2 and 4')
    .required('required field'),
  squaresPerRow: number()
    .typeError('required field')
    .min(2, 'Squares per row must be between 2 and 4')
    .max(4, 'Squares per row must be between 2 and 4')
    .required('required field'),
  options: array().of(
    object().shape({
      id: number().required('required field'),
      option: string().required('required field'),
      showValue: boolean().required('required field'),
    })
  ),
  question: string().required('required field'),
  questionAudioKey: string().required('required field'),
  correctAnswer: string().required('required field'),
};
