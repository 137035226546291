const BoldText = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.125 11.75C5.78125 11.75 5.48708 11.6277 5.2425 11.3831C4.9975 11.1381 4.875 10.8438 4.875 10.5V4.25C4.875 3.90625 4.9975 3.61208 5.2425 3.3675C5.48708 3.1225 5.78125 3 6.125 3H8.3125C9.02083 3 9.64062 3.20312 10.1719 3.60937C10.7031 4.01562 10.9688 4.57292 10.9688 5.28125C10.9688 5.78125 10.8542 6.17958 10.625 6.47625C10.3958 6.77333 10.125 7 9.8125 7.15625V7.28125C10.2708 7.4375 10.6302 7.68229 10.8906 8.01562C11.151 8.34896 11.2812 8.78125 11.2812 9.3125C11.2812 10.0938 10.9973 10.6954 10.4294 11.1175C9.86188 11.5392 9.1875 11.75 8.40625 11.75H6.125ZM6.53125 6.5625H8.1875C8.53125 6.5625 8.81521 6.46354 9.03938 6.26563C9.26312 6.06771 9.375 5.81771 9.375 5.51562C9.375 5.21354 9.26312 4.96354 9.03938 4.76562C8.81521 4.56771 8.53125 4.46875 8.1875 4.46875H6.53125V6.5625ZM6.53125 10.25H8.3125C8.71875 10.25 9.03917 10.1458 9.27375 9.9375C9.50792 9.72917 9.625 9.45312 9.625 9.10938C9.625 8.76562 9.50792 8.48958 9.27375 8.28125C9.03917 8.07292 8.71875 7.96875 8.3125 7.96875H6.53125V10.25Z"
      fill="currentColor"
    />
  </svg>
);
export default BoldText;
