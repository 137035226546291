import { useState } from 'react';
import * as T from 'components/Typography';
import * as S from './style';
import { Tabs } from 'antd';
import { Button } from 'components';
import Questions from './Questions';
import { useGetFeedbacks } from 'api-calls/feedback.queries';
import useCsvDownload from '../../../../Hooks/useCsvDownload';

const { TabPane } = Tabs;

const FeedbackSection = () => {
  const [activeTab, setActiveTab] = useState('1');

  const { data: feedbacks } = useGetFeedbacks({
    targetRole: activeTab === '1' ? 'COACH' : 'FACILITATOR',
  });
  const [data, handleClick] = useCsvDownload('/csv/feedbacks');

  return (
    <>
      <S.FeedbackHeader>
        <T.H2 color="neutralMain" mt={4} mb={4}>
          Users’ Feedback
        </T.H2>
      </S.FeedbackHeader>
      <S.TableWrapper activeTab={activeTab}>
        <S.ButtonWrapper>
          <Button.BasicButton
            width="280px"
            handleClick={handleClick}
            loading={data.loading}
            mb={3}
            variant="tertiary"
          >
            Export feedback data
          </Button.BasicButton>
        </S.ButtonWrapper>
        <Tabs defaultActiveKey="1" onChange={setActiveTab} size="large">
          <TabPane
            activeTab={activeTab === '1'}
            tab={
              <span>
                <T.P weight={activeTab === '1' ? 'bold' : 'regular'}>Coach</T.P>
              </span>
            }
            tabKey="1"
            key="1"
          >
            <Questions feedbacks={feedbacks} role="coach" />
          </TabPane>
          <TabPane
            activeTab={activeTab === '2'}
            tab={
              <span>
                <T.P weight={activeTab === '2' ? 'bold' : 'regular'}>
                  Facilitator
                </T.P>
              </span>
            }
            tabKey="2"
            key="2"
          >
            <Questions feedbacks={feedbacks} role="facilitator" />
          </TabPane>
        </Tabs>
      </S.TableWrapper>
    </>
  );
};

export default FeedbackSection;
