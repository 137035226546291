import styled from '@emotion/styled';

import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacings[8]} 0;
  width: 100%;
  max-width: 820px;
  margin: 0 auto;
`;

export const LoaderIconWrapper = styled.div`
  @keyframes rotateLoader {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg); /* 6/6th of a full circle */
    }
  }

  width: 72px;
  height: 72px;
  position: relative;
  animation: rotateLoader 1s steps(9) infinite;
`;

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#F5F7FF',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#F89E1D',
  },
}));
