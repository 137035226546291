import styled from '@emotion/styled';
import setMargin from '../../../../helpers/set-margin';

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  flex: 1;
  margin-top: ${({ theme }) => theme.spacings[2]};
  display: ${({ display }) => display};
`;

export const Row = styled.div`
  ${setMargin};
  width: 100%;
  min-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ direction }) => direction || 'row'};
  padding: ${({ theme }) => `${theme.spacings[6]} ${theme.spacings[1]}`};
  background: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] || bgColor : theme.colors.neutralLight};
`;

export const ButtonWrapper = styled.div`
  width: 300px;
  margin: 0 auto;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
  flex: ${({ flex }) => flex || 0};

  display: flex;
  align-items: end;
  flex: 1;
`;

export const MarkdownWrapper = styled.div`
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const MDElementWrapper = styled.div`
  display: flex;
  min-width: 300px;
  margin-bottom: ${({ theme }) => theme.spacings[5]};
`;

export const MDElement = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
`;

export const TypeInput = styled.input`
  outline: 0;
  border-width: 0 0 2px;
  border-color: ${({ theme, isCorrect }) =>
    isCorrect ? theme.colors.quinaryMain : theme.colors.primaryMain};
  width: 142px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 150%;
  text-align: center;
  background: transparent;
  margin-left: ${({ theme }) => theme.spacings[2]};
  color: ${({ theme }) => theme.colors.neutralMain};
  transition: 0.3s;
  &:focus {
    border-color: ${({ theme, isCorrect }) =>
      isCorrect ? theme.colors.quinaryMain : theme.colors.neutralMain};
  }
`;

export const PlayBtnWrapper = styled.div`
  padding-left: ${({ theme }) => theme.spacings[4]};
  display: flex;
  justify-content: center;
`;

export const TextAudioWrapper = styled.div`
  ${setMargin};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ jc }) => jc || 'center'};
`;
