import theme from '../../../theme';

const Edit4 = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.9654 6.73014L15.2714 4.03614L13.3234 5.98514L16.0184 8.68014L17.9654 6.73014ZM5.09038 17.9971C5.06038 18.0001 5.03038 18.0011 5.00038 18.0011C4.73638 18.0011 4.48138 17.8971 4.29238 17.7081C4.08338 17.4981 3.97738 17.2061 4.00338 16.9111L4.38238 12.7411C4.42438 12.2841 4.62638 11.8521 4.95238 11.5271L13.9484 2.53114C14.6504 1.82614 15.9234 1.86114 16.6644 2.60014L19.4024 5.33814L19.4034 5.33914C20.1684 6.10514 20.1994 7.32314 19.4714 8.05314L10.4744 17.0501C10.1494 17.3751 9.71838 17.5771 9.26038 17.6191L5.09038 17.9971ZM4.99988 20.0014H18.9999C19.5499 20.0014 19.9999 20.4514 19.9999 21.0014C19.9999 21.5514 19.5499 22.0014 18.9999 22.0014H4.99988C4.44988 22.0014 3.99988 21.5514 3.99988 21.0014C3.99988 20.4514 4.44988 20.0014 4.99988 20.0014Z"
      fill={theme.colors[color] || color || '#6E970B'}
    />
  </svg>
);

export default Edit4;
