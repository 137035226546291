import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';
import MuiModal from '@mui/material/Modal';

export const Modal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  ${setMargin};
  background: ${({ theme }) => theme.colors.white};
  width: 908px;
  padding: ${({ theme }) => theme.spacings[6]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  position: relative;

  ${({ theme }) => theme.media.tablet} {
    width: 95%;
    padding: ${({ theme }) => theme.spacings[4]};
  }

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    height: 100%;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const CoverWrapper = styled.div`
  width: 300px;
  height: 200px;
  margin: 0 auto;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;

export const TabImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Content = styled.div`
  width: 70%;
  margin: 0 auto;

  ${({ theme }) => theme.media.mobile} {
    width: 90%;
  }
`;

export const Message = styled.div`
  margin-top: ${({ theme }) => theme.spacings[5]};
  padding: ${({ theme }) => theme.spacings[5]};
  padding-bottom: ${({ theme }) => theme.spacings[4]};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutral40};

  ${({ theme }) => theme.media.mobile} {
    margin-top: ${({ theme }) => theme.spacings[0]};
    padding: ${({ theme }) => theme.spacings[2]};
  }
`;

export const DividingLine = styled.div`
  width: 200px;
  background-color: red;
  height: 2px;
  margin: 0 auto;
  background: linear-gradient(to left, #0000, #ebebeb, #0000);
`;

export const Footer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacings[7]};
  justify-content: flex-end;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    margin-top: ${({ theme }) => theme.spacings[0]};

    .next {
      max-width: 100% !important;
    }
  }
`;
