const AlarmClock = ({ width, height, color, ...props }) => (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4998 35.1998C17.969 35.1998 15.5826 34.5813 13.4837 33.4872C13.444 33.5438 13.3989 33.5977 13.3483 33.6483L10.1483 36.8483C9.6797 37.317 8.9199 37.317 8.45127 36.8483C7.98264 36.3797 7.98264 35.6199 8.45127 35.1513L11.4151 32.1874C7.70314 29.416 5.2998 24.9885 5.2998 19.9998C5.2998 11.6051 12.1051 4.7998 20.4998 4.7998C28.8945 4.7998 35.6998 11.6051 35.6998 19.9998C35.6998 24.9885 33.2965 29.416 29.5845 32.1874L32.5483 35.1513C33.017 35.6199 33.017 36.3797 32.5483 36.8483C32.0797 37.317 31.3199 37.317 30.8513 36.8483L27.6513 33.6483C27.6007 33.5978 27.5556 33.5438 27.5159 33.4872C25.417 34.5813 23.0306 35.1998 20.4998 35.1998ZM7.48744 6.34076C6.21843 7.33221 5.90074 9.09002 6.67754 10.448C7.85947 8.74089 9.34951 7.26333 11.0672 6.09574C9.96976 5.45766 8.54897 5.5114 7.48744 6.34076ZM33.487 6.39979C32.4451 5.58572 31.057 5.51894 29.9683 6.12019C31.673 7.28537 33.1521 8.75642 34.3264 10.4543C35.0654 9.10384 34.7397 7.37846 33.487 6.39979ZM15.1488 15.904C15.4692 15.5997 15.9756 15.6127 16.2798 15.9331L20.5717 20.4515L27.932 13.0362C28.2433 12.7226 28.7498 12.7208 29.0634 13.032C29.377 13.3433 29.3788 13.8498 29.0676 14.1634L21.1269 22.1634C20.8091 22.4835 20.2897 22.4778 19.9791 22.1508L15.1198 17.035C14.8155 16.7146 14.8285 16.2083 15.1488 15.904Z"
      fill={color || 'neutralMain'}
    />
  </svg>
);

export default AlarmClock;
