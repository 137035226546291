import PlayButton from '../Play';
import * as T from '../../Typography';
import Icon from '../../Icon';
import * as S from './style';
import { Row } from '../../Grid';

const ClearButton = ({ handleClick, hideIcon, hideBorder, ...props }) => {
  return (
    <Row {...props}>
      <PlayButton
        width="32px"
        height="32px"
        audioUrl=""
        iconProps={{ width: 27.2 }}
        backgroundColor="transparent"
        withoutButtonStyle
        fallbackText="Clear"
      />
      <S.CLearButton onClick={handleClick} hideBorder={hideBorder}>
        <T.P weight="bold" textDecoration="underline">
          Clear
        </T.P>
        {!hideIcon && <Icon icon="eraser" aria-label="Clear" />}
      </S.CLearButton>
    </Row>
  );
};

export default ClearButton;
