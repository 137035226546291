import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentWrapper = styled.div`
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacings[6]};
  margin-right: ${({ theme }) => theme.spacings[6]};
`;

export const TextAudioWrapper = styled.div`
  width: 100%;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
  margin-bottom: ${({ theme, mb }) => theme.spacings[mb || 0]};
  display: flex;
  align-items: center;
  justify-content: ${({ jc }) => jc || 'center'};
`;

export const PreFilledAnswersGridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: ${({ theme }) => theme.spacings[6]} 0px;
  background: ${({ theme }) => theme.colors.neutralLight};
  max-width: ${(props) => (props.isWithExplainerAudio ? '350px' : 'auto')};
`;

export const PyramidRowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  flex-wrap: wrap;
`;

export const TypeInput = styled.input`
  border-style: solid;
  border-width: ${({ showValue }) => (showValue ? '0' : '2px')};
  border-radius: 12px;
  border-color: ${({ theme, isCorrect }) =>
    isCorrect ? theme.colors.quinaryMain : theme.colors.primaryMain};
  width: ${({ rowSize }) => (rowSize > 3 ? '64px' : '80px')};
  height: ${({ rowSize }) => (rowSize > 3 ? '64px' : '80px')};
  font-style: normal;
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 150%;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutralMain};
  background: ${({ theme, showValue }) =>
    showValue ? theme.colors.white : 'transparent'};
  transition: 0.3s;
  &:focus,
  &:focus-visible {
    border-color: ${({ theme, isCorrect }) =>
      isCorrect ? theme.colors.quinaryMain : theme.colors.neutralMain};
    outline: none;
  }
`;
