import styled from '@emotion/styled';
import { setMargin } from 'helpers';

export const Card = styled.div`
  ${setMargin};
  width: 100%;
  background: ${({ theme }) => theme.colors.yellowOrange};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => `${theme.spacings[5]}`};
  padding: ${({ theme }) => `${theme.spacings[5]}`};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.neutral40};
  border-radius: ${({ theme }) => theme.borders.radiusMedium};
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;
