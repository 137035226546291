import { useEffect, useState } from 'react';
import * as T from '../../../../components/Typography';
import EmptySpace from '../../../../components/EmptySpace';
import Option from '../../../../components/Option';
import * as S from './style';
import { showMovingBlock } from '../../../../helpers';
import TryAgainModal from '../../../../components/Modal/TryAgainModal';
import StepFooterButtons from '../StepFooterButtons';
import { useExercise } from '../../../../context/exercise';

const WordJoin1 = ({
  data: { options: _options = [], correctOptions: _correctOptions = [] } = {},
  handleNext,
  stuckAtReview,
  preview,
  cmsPreview,
}) => {
  const { setSubProgress } = useExercise();

  const [disableCheck, setDisableCheck] = useState(false);
  const [correctOptions, setCorrectOptions] = useState(_correctOptions);
  const [activePosition, setActivePosition] = useState({ row: 0, column: 0 });

  const [showTryAgain, setShowTryAgain] = useState(false);

  const options = _options.map((op, i) => ({ option: op.option, id: i }));

  const answerRow = [null, null];

  const defaultEmptyAnswersArray = [
    answerRow,
    answerRow,
    answerRow,
    answerRow,
    answerRow,
  ];
  const [answers, setAnswers] = useState(defaultEmptyAnswersArray);

  const onClickAnswer = (row, column, answer) => {
    if (answer?.option) {
      setActivePosition({ row, column });
      showMovingBlock({
        OptionElId: `option-${answer.id}`,
        answerElId: `answer-${row}-${column}`,
        movingElId: 'moving-div',
        isBeingSelected: true,
      });
      setAnswers((_answers) =>
        _answers.map((_row, ir) =>
          _row.map((_column, ic) => {
            if (ir === row && ic === column) {
              return null;
            } else {
              return _column;
            }
          })
        )
      );
    } else {
      setActivePosition({ row, column });
    }
  };

  const onFocusAnswer = (row, column) => {
    setActivePosition({ row, column });
  };

  const onClickOption = (option) => {
    setDisableCheck(true);
    showMovingBlock({
      OptionElId: `option-${option.id}`,
      answerElId: `answer-${activePosition.row}-${activePosition.column}`,
      movingElId: 'moving-div',
      isBeingSelected: false,
      done: () => {
        setAnswers((_answers) =>
          _answers.map((row, ir) =>
            row.map((column, ic) => {
              if (ir === activePosition.row && ic === activePosition.column) {
                return option;
              } else {
                return column;
              }
            })
          )
        );
        setDisableCheck(false);
      },
    });

    setActivePosition((_activePosition) => {
      const availablePositions = [
        ...answers[_activePosition.row],
        ...answers[_activePosition.row],
      ].map((answer, i) => !answer?.option && i > _activePosition.column);

      const nextSpace = availablePositions.indexOf(true);

      return {
        row: _activePosition.row,
        column:
          nextSpace > 0
            ? nextSpace % answers[_activePosition.row].length
            : _activePosition.column,
      };
    });
  };

  const concatRowAnswer = (row) => {
    return answers[row].map((e) => e?.option || ' ').join('-');
  };

  const validateRow = (row) => {
    const currentAnswer = concatRowAnswer(row);
    if (currentAnswer.includes(' ')) {
      return false;
    }

    return correctOptions.find((e) => e?.option === currentAnswer);
  };

  const onCheck = () => {
    if (stuckAtReview) return handleNext();
    const correctOption = validateRow(activePosition.row);
    if (correctOption) {
      setCorrectOptions((_correctOptions) =>
        _correctOptions.filter((e) => e.option !== correctOption.option)
      );

      if (activePosition.row + 1 === answers.length) {
        handleNext();
      } else {
        setActivePosition((_activePosition) => ({
          row: _activePosition.row + 1,
          column: 0,
        }));
      }
    } else {
      setShowTryAgain(true);
    }
  };

  const currentAnswer = concatRowAnswer(activePosition.row);

  const subProgress =
    (_correctOptions.length - correctOptions.length) / _correctOptions.length;

  useEffect(() => {
    setSubProgress(subProgress);
  }, [setSubProgress, subProgress]);

  return (
    <S.Wrapper>
      {/* keep this to animation */}
      <div style={{ display: 'none' }}>
        <Option id="moving-div"></Option>
      </div>
      <TryAgainModal
        isModalVisible={showTryAgain}
        setIsModalVisible={setShowTryAgain}
      />

      <S.Grid>
        {answers.map((row, ir) => (
          <S.Row disabled={ir !== activePosition.row} key={ir}>
            {row.map((answer, ic) => (
              <EmptySpace
                isActive={
                  ir === activePosition.row &&
                  ic === activePosition.column &&
                  !cmsPreview
                }
                onClick={() => onClickAnswer(ir, ic, answer)}
                onFocus={() => onFocusAnswer(ir, ic, answer)}
                id={`answer-${ir}-${ic}`}
                bgColor={
                  answer?.id ? 'rgba(255, 255, 255, 0.5)' : 'neutralSurface'
                }
                key={`${ir}-${ic}`}
                disabled={ir !== activePosition.row}
                w="123px"
                h="58px"
                size="large"
                fontSize="20px"
              >
                {answer?.option}
              </EmptySpace>
            ))}
          </S.Row>
        ))}
      </S.Grid>

      <S.OptionsGridWrapper>
        <S.OptionsGrid jc="center">
          {options.map((op) => (
            <Option
              m={1}
              onClick={() => onClickOption(op)}
              id={`option-${op.id}`}
              key={op.id}
              style={{ minWidth: '74px' }}
              fontSize="20px"
            >
              {op.option}
            </Option>
          ))}
        </S.OptionsGrid>
      </S.OptionsGridWrapper>

      {(!_options || !_correctOptions) && (
        <T.P color="error">Exercise missing required fields </T.P>
      )}
      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={onCheck}
        preview={preview}
        disabled={currentAnswer.includes(' ') || disableCheck}
        enableNext={currentAnswer.length >= 2}
        title="Check"
      />
    </S.Wrapper>
  );
};

export default WordJoin1;
