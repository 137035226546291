import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

// Page style
export const PageWrapper = styled.div`
  width: 100%;
  display: block;
`;

// Cover style
export const CoverWrapper = styled.div`
  position: relative;
  height: 184px;
  background: ${({ theme }) => theme.colors.neutralMain};
  margin-bottom: 20px;
`;

export const CoverBackButtonWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 120px;
  width: 85px;
  z-index: 10;
  ${({ theme }) => theme.media.mobile} {
    left: 40px;
  }
`;

export const ChatLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #051730;
  width: 140px;
  height: 40px;
  top: 285px;
  left: 267px;
  padding: 8px;
  gap: 8px;
  border-radius: 16px;
  opacity: 0px;
  background: #f5f5f5;
  box-shadow: 0px 1.37px 3.43px -0.69px #040d261c;
  box-shadow: 0px 0.69px 2.06px -0.69px #040d261a;
`;

export const CoverBackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  :after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.neutralMain};
    opacity: 0.5;
    top: 0;
    left: 0;
    z-index: 5;
  }
`;

// Content style
export const Content = styled.div`
  width: 100%;
  max-width: 800px;
  margin-inline: auto;
  ${({ theme }) => theme.media.tablet} {
    padding: 0 32px;
  }
`;

// Details style
export const DetailsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    gap: 16px;
  }
`;
export const AvatarNameWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    gap: 8px;
  }
`;
export const AvatarWrapper = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.secondaryMain};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 4px solid ${({ theme }) => theme.colors.white};
  position: relative;
  margin-top: -40px;
  z-index: 15;
  padding: ${({ theme }) => theme.spacing(1)};
  ${({ theme }) => theme.media.mobile} {
    margin-top: 0;
  }
`;
export const NameWrapper = styled.div``;
export const NameStuckLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Stucklabel = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryMain};
  padding: 2px 12px 1px 12px;
  border-radius: 24px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  margin-left: ${({ theme }) => theme.spacing(1)};
`;

export const StatsWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 4px;
  ${({ theme }) => theme.media.tablet} {
    flex-direction: column;
  }
`;
export const StatsColWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  flex-basis: 25%;
  ${({ theme }) => theme.media.tablet} {
    flex-basis: 100%;
    flex-direction: row;
  }
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const HandOverCard = styled.div`
  padding: 16px 32px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.neutralSurface};
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: stretch;
    padding: 16px;
  }
`;
export const TableTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 480px) {
    flex-direction: column;
    padding: 5px;
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-direction: ${({ awaitCoach }) => (awaitCoach ? 'row' : 'column')};
  gap: 8px;
`;
export const Tag = styled.div`
  background-color: ${({ bgColor, theme }) => theme.colors[bgColor] ?? bgColor};
  color: ${({ fontColor, theme }) =>
    theme.colors[fontColor] || theme.colors['white']};
  border-radius: 24px;
  font-size: 1rem;
  font-weight: 700;
  padding: 4px 12px;
  width: fit-content;
`;
