import Modal from '../Modal';
import * as T from '../Typography';
import { Row, Col } from '../Grid';
import { BasicButton } from '../Button';

const UnsavedModal = ({
  isModalVisible,
  setIsModalVisible,
  handleLeaveAnyway,
}) => {
  return (
    <Modal visible={isModalVisible} setIsModalVisible={setIsModalVisible}>
      <Row>
        <Col w={[4, 12, 12]} jc="left">
          <T.P size="med" weight="bold" color="white">
            Unsaved changes!
          </T.P>
        </Col>
        <Col w={[4, 12, 12]} jc="left" mt={4}>
          <T.P color="white">
            Are you sure you want to leave without saving changes?
          </T.P>
        </Col>
        <>
          <Col w={[4, 12, 12]} mt={4}>
            <BasicButton onClick={handleLeaveAnyway} variant="secondary">
              Leave anyway
            </BasicButton>
          </Col>
          <Col w={[4, 12, 12]} mt={4}>
            <BasicButton
              onClick={() => setIsModalVisible(false)}
              variant="tertiary"
            >
              Cancel
            </BasicButton>
          </Col>
        </>
      </Row>
    </Modal>
  );
};

export default UnsavedModal;
