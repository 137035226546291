import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from '../../../components/Layout';

import Loading from '../../../components/Loading';
import { useExercise } from '../../../context/exercise';
import { useGeneralState } from '../../../context/general-state';
import { navRoutes as NR } from '../../../constants';
import { useGetStepById } from '../../../api-calls/exercises.queries';

import { useCreateCompletedStep } from '../../../api-calls/exercises.queries';
import { useAuth } from 'context/auth';
import StuckAtReview from './StuckAtReview';

import Formats from './Formats';
import { replaceMediaKeysWithUrls } from 'helpers';

const initStep = {
  audioKey: '',
  correctOption: null,
  imageKey: '',
  options: null,
  subtitle: '',
  title: '',
};

const ExerciseStepContent = ({ preview }) => {
  const navigate = useNavigate();
  const { state: generalState } = useGeneralState();
  const auth = useAuth();

  const { stepId, exerciseId } = useParams();
  const { exercise, nextStep } = useExercise();
  const { mutateAsync: createCompletedStep, isLoading } =
    useCreateCompletedStep({
      id: stepId,
    });

  const { data: step, isLoading: isStepLoading } = useGetStepById(
    { exerciseId, stepId },
    { placeholderData: initStep, enabled: stepId !== 'start' }
  );

  const handleNext = async () => {
    if (generalState?.isLearning) {
      await createCompletedStep({
        id: stepId,
        learnerId: auth.user.selectedLearnerId,
        withCoach: generalState.withCoach,
      });
    }

    if (nextStep) {
      navigate(
        NR.EXERCISES.SINGLE_STEP.replace(':exerciseId', exerciseId).replace(
          ':stepId',
          nextStep.id
        )
      );
    } else {
      if (generalState.exerciseFlow === 'REVIEW_SELF_LEARNING') {
        navigate(
          NR.EXERCISES.REVIEW_COMPLETED_SL.replace(':exerciseId', exerciseId)
        );
      } else {
        navigate(
          NR.EXERCISES.EXERCISE_COMPLETED.replace(
            ':exerciseId',
            exerciseId
          ).replace(':milestoneId', exercise.milestoneId)
        );
      }
    }
  };

  useEffect(() => {
    if ((!Number(stepId) && stepId !== 'start') || !Number(exerciseId)) {
      navigate(NR.GENERAL.NOT_FOUND);
    }
  }, [stepId, exerciseId, navigate]);

  if (isStepLoading) return { Component: <Loading />, layout: 'general' };
  return Formats({
    exercise,
    handleNext,
    preview,
    step: replaceMediaKeysWithUrls(step, generalState?.preferredVoice),
    isLoading,
  });
};

const ExerciseStep = (props) => {
  const { Component, layout } = ExerciseStepContent(props);

  return (
    <Layout
      layout={layout}
      headerProps={{
        type: 'progress',
      }}
      image="secondaryColor"
    >
      {Component}
    </Layout>
  );
};

export default ExerciseStep;
export { StuckAtReview };
