import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';

export const StyledField = styled(TextField)`
  :hover {
    border-color: ${({ theme }) => theme.colors.primaryMain} !important;
  }

  .MuiInputBase-root {
    border-radius: 16px;
    border: ${({ theme }) => `1px solid ${theme.colors.neutral50} !important`};
    height: 64px;
    vertical-align: middle;
    font-family: 'BasicCommercial', sans-serif;

    :hover {
      border-color: ${({ theme }) => theme.colors.primaryMain} !important;
    }

    :focus-within {
      outline: none;
      border: ${({ theme }) => `solid 1px ${theme.colors.primaryMain}`};
      box-shadow: ${({ theme }) =>
        `0 0 0 2px white,
        0 0 0 5px ${theme.colors.primaryMain}`};
    }

    :focus {
      outline: none;
      border: ${({ theme }) => `solid 1px ${theme.colors.primaryMain}`};
      box-shadow: ${({ theme }) =>
        `0 0 0 2px white,
        0 0 0 5px ${theme.colors.primaryMain}`};
    }

    ::placeholder {
      overflow: visible;
    }
  }

  .MuiOutlinedInput-root {
    border: none;

    :hover {
      border: none;
    }
  }

  .MuiSelect-select {
    padding-top: 24px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-width: 0;
  }
`;
