import theme from '../../../theme';

const ThreePersons = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '41'}
    height={height || '40'}
    fill="none"
    viewBox="0 0 41 40"
  >
    <path
      fill={color || theme.colors.neutralMain}
      fill-rule="evenodd"
      d="M16.833 8.8a4 4 0 0 0 4 4h-.8a4.8 4.8 0 0 0-4.8 4.8V20a2.4 2.4 0 0 0 2.4 2.4h6.4a2.4 2.4 0 0 0 2.4-2.4v-2.4a4.8 4.8 0 0 0-4.8-4.8h-.8a4 4 0 1 0-4-4ZM28.034 23.2a4 4 0 0 0 4 4h-.8a4.8 4.8 0 0 0-4.8 4.8v2.4a2.4 2.4 0 0 0 2.4 2.4h6.4a2.4 2.4 0 0 0 2.4-2.4V32a4.8 4.8 0 0 0-4.8-4.8h-.8a4 4 0 1 0-4-4ZM5.634 23.2a4 4 0 0 0 4 4h-.8a4.8 4.8 0 0 0-4.8 4.8v2.4a2.4 2.4 0 0 0 2.4 2.4h6.4a2.4 2.4 0 0 0 2.4-2.4V32a4.8 4.8 0 0 0-4.8-4.8h-.8a4 4 0 1 0-4-4Z"
      clip-rule="evenodd"
    />
  </svg>
);
export default ThreePersons;
