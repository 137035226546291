import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 0 120px;
  ${({ theme }) => theme.media.tablet} {
    padding: 0 40px;
  }
  ${({ theme }) => theme.media.mobile} {
    padding: 0 20px;
  }
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  margin-top: ${({ insideSignup }) => (insideSignup ? '0px' : '90px')};
  ${({ theme }) => theme.media.mobile} {
    margin-top: 35px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
`;
export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0;
`;

// export const ErrorWrapper = styled.div`
//   display: flex;
//   align-self: center;
//   width: 100%;
// `;

export const ButtonContainer = styled.div``;
