import { useState } from 'react';
import { Carousel } from 'antd';
import * as T from '../Typography';
import * as S from './style';
import Icon from '../Icon';

const randomizeArray = (arr) => {
  const newArr = [...arr];
  for (let i = newArr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
  }
  return newArr;
};

const Quote = ({ data }) => {
  const [_data] = useState(randomizeArray(data)); // randomize array and set in state so it doesn't change on re-render

  return (
    <S.CarouselWrapper>
      <Carousel dotPosition="bottom" autoplay>
        {_data.map((item) => (
          <div>
            <S.QuoteWrapper key={item.id}>
              <T.P color="neutral80" ta="center" mb={2}>
                {item.description}
              </T.P>
              <Icon icon="dividerLine" />
              <T.P color="neutral60" ta="center" mt={2} mb={2}>
                {item.author}
              </T.P>
            </S.QuoteWrapper>
          </div>
        ))}
      </Carousel>
    </S.CarouselWrapper>
  );
};
export default Quote;
