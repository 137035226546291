import { useSiteSettings } from 'context/site-settings';
import { useMemo } from 'react';
import { getTrainingData } from '../training-content';

const useCoachTrainingData = () => {
  const { courseName, courseCode } = useSiteSettings();

  const trainingData = useMemo(
    () => getTrainingData({ courseName, courseCode }),
    [courseName, courseCode]
  );

  return { trainingData };
};

export default useCoachTrainingData;
