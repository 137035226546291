const Export = ({ width, height, color, ...props }) => (
  <svg
    width={width || '32'}
    height={height || '32'}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color || 'currentColor'}
      d="M25.328 18.56a.96.96 0 1 1 1.92 0v3.84c0 2.64-1.943 4.8-4.373 4.8H9.22c-2.43 0-4.373-2.16-4.373-4.8v-3.84a.96.96 0 1 1 1.92 0v3.84c0 1.602 1.116 2.88 2.453 2.88h13.654c1.337 0 2.453-1.278 2.453-2.88v-3.84Z"
    />
    <path
      fill={color || 'currentColor'}
      d="M19.21 15.321a.96.96 0 0 1 1.356 1.36l-3.84 3.83a.96.96 0 0 1-1.356 0l-3.84-3.83a.96.96 0 1 1 1.356-1.36l2.202 2.197V5.76a.96.96 0 1 1 1.92 0v11.757l2.202-2.197Z"
    />
  </svg>
);
export default Export;
