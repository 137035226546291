import { BackButton } from 'components/BackButton';
import React from 'react';
import * as S from './style';
import { navRoutes as R, audio } from '../../../constants';
import { Button, Loading, Typography as T } from 'components';
import { getMediaUrl } from 'helpers';
import { useGeneralState } from 'context/general-state';
import { useNavigate } from 'react-router-dom';
import { useGetLearnerSessions } from 'api-calls/learner-coach-sessions.queries';
import { useAuth } from 'context/auth';
import moment from 'moment';

const MyCoachingSessions = () => {
  const { state: generalState } = useGeneralState();
  const navigate = useNavigate();
  const { user } = useAuth();

  const { data: sessions, isLoading } = useGetLearnerSessions({
    id: user.id,
    status: 'upcoming',
  });

  return (
    <S.Container>
      <div style={{ marginLeft: '30px', marginBottom: '40px' }}>
        <BackButton link={R.LEARNER.DASHBOARD} />
      </div>
      <S.ScrolledWrapper>
        <S.Wrapper>
          <S.PlayButtonWrapper>
            <Button.PlayButton
              width="56px"
              height="56px"
              audioUrl={getMediaUrl(
                audio.myUpcomingCoachingSessions,
                true,
                generalState?.preferredVoice
              )}
              iconProps={{ width: 27.2 }}
              mr={3}
              fallbackText="My upcoming coaching sessions"
            />
            <T.H1 ml="1">My upcoming coaching sessions</T.H1>
          </S.PlayButtonWrapper>
          {isLoading ? (
            <Loading />
          ) : sessions?.length ? (
            sessions.map((session) => {
              const sessionDate = moment(session.start).format('YYYY-MM-DD');
              const sessionTime = `${moment(session.start).format(
                'HH:mm'
              )} - ${moment(session.end).format('HH:mm')}`;
              let sessionLocation = session.link;
              if (session.type === 'IN-PERSON') {
                sessionLocation = `${session.addressLine1}, ${session.town}, ${session.postcode}`;
              }
              return (
                <S.PlayCardWrapper key={session.id}>
                  <S.RowWrapper>
                    <div style={{ display: 'flex' }}>
                      <Button.PlayButton
                        width="32px"
                        height="32px"
                        audioUrl={getMediaUrl(
                          audio.sessionDate,
                          true,
                          generalState?.preferredVoice
                        )}
                        iconProps={{ width: 27.2 }}
                        backgroundColor="transparent"
                        withoutButtonStyle
                        fallbackText={`Session date`}
                      />
                      <S.SessionTextWrapper>
                        <T.P color="neutral80">Session Date</T.P>
                        <T.P color="neutralMain" weight="bold">
                          {sessionDate}
                        </T.P>
                      </S.SessionTextWrapper>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <Button.PlayButton
                        width="32px"
                        height="32px"
                        audioUrl={getMediaUrl(
                          audio.sessionTime,
                          true,
                          generalState?.preferredVoice
                        )}
                        iconProps={{ width: 27.2 }}
                        backgroundColor="transparent"
                        withoutButtonStyle
                        fallbackText={`Session Time`}
                      />
                      <S.SessionTextWrapper>
                        <T.P color="neutral80">Session Time</T.P>
                        <T.P color="neutralMain" weight="bold">
                          {sessionTime}
                        </T.P>
                      </S.SessionTextWrapper>
                    </div>
                  </S.RowWrapper>
                  <S.RowWrapper mt="9px" mb="12px">
                    <div
                      style={{
                        display: 'flex',
                        minWidth: '225px',
                        maxWidth: '300px',
                      }}
                    >
                      <Button.PlayButton
                        width="32px"
                        height="32px"
                        audioUrl={getMediaUrl(
                          audio.sessionLocation,
                          true,
                          generalState?.preferredVoice
                        )}
                        iconProps={{ width: 27.2 }}
                        backgroundColor="transparent"
                        withoutButtonStyle
                        fallbackText={`Meeting Location`}
                      />
                      <S.SessionTextWrapper>
                        <T.P color="neutral80">Meeting Location</T.P>
                        {session.type === 'IN-PERSON' ? (
                          <T.P color="neutralMain" weight="bold">
                            {sessionLocation}
                          </T.P>
                        ) : (
                          <T.Link
                            color="neutralMain"
                            external
                            href={
                              sessionLocation.startsWith('http')
                                ? sessionLocation
                                : `https://${sessionLocation}`
                            }
                          >
                            {sessionLocation}
                          </T.Link>
                        )}
                      </S.SessionTextWrapper>
                    </div>
                    <S.AddToCalendarWrapper>
                      <Button.PlayButton
                        width="32px"
                        height="32px"
                        audioUrl={getMediaUrl(
                          audio.addToCalendar,
                          true,
                          generalState?.preferredVoice
                        )}
                        iconProps={{ width: 27.2 }}
                        backgroundColor="transparent"
                        withoutButtonStyle
                        fallbackText={`Add to calendar`}
                      />
                      <S.CustomAddToCalendarButton
                        name={`Session with ${session.coach.firstName} ${session.coach.lastName}`}
                        description={`Meeting Location: ${
                          session.type === 'IN-PERSON'
                            ? sessionLocation
                            : sessionLocation.startsWith('http')
                            ? sessionLocation
                            : `https://${sessionLocation}`
                        }`}
                        location={
                          session.type === 'IN-PERSON'
                            ? sessionLocation
                            : sessionLocation.startsWith('http')
                            ? sessionLocation
                            : `https://${sessionLocation}`
                        }
                        startDate={sessionDate}
                        startTime={sessionTime.split(' - ')[0]}
                        endTime={sessionTime.split(' - ')[1]}
                        timeZone="currentBrowser"
                        options={['Google', 'Outlook.com']}
                        // 'Apple', 'Google', 'iCal', 'Microsoft365', 'MicrosoftTeams', 'Outlook.com', 'Yahoo'
                        buttonStyle="text"
                      />
                    </S.AddToCalendarWrapper>
                  </S.RowWrapper>
                  <S.PlayButtonWrapper>
                    <Button.BasicButton
                      icon="message"
                      variant="tertiary"
                      handleClick={() =>
                        navigate(
                          R.LEARNER.CHAT_WITH_COACH.replace(
                            ':receiverId',
                            session.coachId
                          )
                        )
                      }
                      showSound
                      audioUrl={getMediaUrl(
                        audio.contactTheCoach,
                        true,
                        generalState?.preferredVoice
                      )}
                      fallbackText="Contact the coach"
                      style={{
                        width: '100%',
                      }}
                      PlayButtonProps={{
                        height: '100%',
                      }}
                    >
                      Contact the coach
                    </Button.BasicButton>
                  </S.PlayButtonWrapper>
                </S.PlayCardWrapper>
              );
            })
          ) : (
            <S.NoDataWrapper>
              <Button.PlayButton
                variant="onlyIcon"
                audioUrl={getMediaUrl(
                  audio.noSessionsAvailable,
                  true,
                  generalState?.preferredVoice
                )}
                iconProps={{ width: 27.2 }}
                mr={2}
                fallbackText="No sessions available"
              />
              <T.P>No sessions available</T.P>
            </S.NoDataWrapper>
          )}
        </S.Wrapper>
      </S.ScrolledWrapper>
    </S.Container>
  );
};
export default MyCoachingSessions;
