import styled from '@emotion/styled';
import { BasicButton } from 'components/Button';

export const Card = styled.div`
  width: 100%;
  background: ${({ clear, theme, bg }) =>
    clear ? 'transparent' : theme.colors[bg]};
  gap: ${({ theme }) => `${theme.spacings[5]}`};
  padding: ${({ theme }) => `${theme.spacings[5]}`};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.neutral40};
  border-radius: ${({ theme }) => theme.borders.radiusMedium};
`;

export const Button = styled(BasicButton)`
  max-width: 300px;
`;
