import { string, number, array, object } from 'yup';

import { BasicInput } from '../../../../components/Inputs';
import { MediaKeyInput } from '../../../components/MediaKeyInput';
import { Icon } from '../../../../components';
import * as T from '../../../../components/Typography';
import * as S from './style';
import { useRef } from 'react';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import { mediaTypes } from '../../../../constants';

const initialData = {
  audioKey: '',
  title: '',
  subtitle: '',
  sentence: '',
  titleImage: '',
  correctOptions: [],
};

const Trace = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateFormFields, updateDataFields } = useStepForm(
    initialRef.current
  );
  const { form, data, validationErrs } = state;

  const addOption = () => {
    const maxId = Math.max(...data.correctOptions?.map((e) => e.id), 0);

    updateDataFields({
      correctOptions: [...data.correctOptions, { id: maxId + 1, imageKey: '' }],
    });
  };
  const removeOption = (id) => {
    updateDataFields({
      correctOptions: data.correctOptions.filter((e) => e.id !== id),
    });
  };

  const onOptionChange = (imageKey, id) => {
    updateDataFields({
      correctOptions: data.correctOptions.map((e) => {
        if (e.id !== id) return e;
        return { ...e, imageKey };
      }),
    });
  };

  return (
    <div>
      <BasicInput
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        label="Explainer Text"
        error={validationErrs.textAudio}
        m={{ mt: 8 }}
      />

      <MediaKeyInput
        type={mediaTypes.TEXT_AUDIO}
        label="Explainer Audio"
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs.textAudioKey}
      />

      <MediaKeyInput
        type={mediaTypes.IMAGE}
        label="Background Image Key"
        value={form.imageKey}
        handleChange={(imageKey) => updateFormFields({ imageKey })}
        m={{ mb: 5, mt: 8 }}
        error={validationErrs?.imageKey}
      />
      {data.correctOptions?.map((c, i) => {
        return (
          <S.CorrectOptionWrapper mt={i === 0 ? '8' : '3'} mb="5">
            <MediaKeyInput
              type={mediaTypes.ANSWER_IMAGE}
              index={i}
              label="Answer Image key"
              value={data.correctOptions[i]?.imageKey}
              handleChange={(imageKey) => {
                onOptionChange(imageKey, c.id);
              }}
              error={validationErrs?.correctOptions?.[i]?.imageKey}
            />
            {data.correctOptions.length > 0 && (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeOption(c.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </S.CorrectOptionWrapper>
        );
      })}
      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addOption}
        disabled={data.correctOptions?.find((e) => !e.imageKey)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Answer
      </S.Button>

      <T.P color="error" mt={6}>
        {validationErrs.general}
      </T.P>
    </div>
  );
};

const validationSchema = {
  textAudio: string().required(),
  textAudioKey: string().notRequired(),
  imageKey: string().required(),
  correctOptions: array()
    .of(
      object()
        .shape({
          id: number().required(),
          imageKey: string().required('required field'),
        })
        .required()
    )
    .required('you must provide answers')
    .min(0, 'you must provide answers')
    .max(4, 'maximum of 5 answers'),
};

export { validationSchema };
export default Trace;
