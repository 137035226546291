import * as T from '../../../../components/Typography';
import { PlayButton } from '../../../../components/Button';
import { Markdown } from '../../../../components';

import * as S from './style';
import StepFooterButtons from '../StepFooterButtons';

const CombineWordsPre = ({
  data: { textAudioUrl, textAudio, options = [] } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  isLoading,
}) => {
  return (
    <S.Wrapper display={'flex'}>
      {textAudio && (
        <S.PlayBtnWrapper>
          {textAudioUrl ? (
            <PlayButton
              audioUrl={textAudioUrl}
              iconProps={{ width: '32px', height: '32px' }}
              padding="12px"
              weight="regular"
              mt={1}
              mx="auto"
              mxM="0"
              fallbackText={typeof textAudio === 'string' && textAudio}
            >
              {textAudio}
            </PlayButton>
          ) : (
            <T.P weight="regular" size="med">
              {textAudio}
            </T.P>
          )}
        </S.PlayBtnWrapper>
      )}

      {options?.length > 0 &&
        options.map(({ option }, index) => (
          <S.Row
            bgColor={index % 2 ? 'white' : 'neutralLight'}
            key={index}
            mt={index === 0 ? 5 : 0}
          >
            <S.MarkdownWrapper>
              <Markdown
                text={option.replaceAll('+ ', '+&nbsp;')}
                customStyles={{
                  p: {
                    size: 'large',
                    weight: 'regular',
                    mt: 1,
                    lh: '150%',
                    ta: 'left',
                  },
                }}
              />
            </S.MarkdownWrapper>
          </S.Row>
        ))}
      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleNext}
        preview={preview}
        disabled={disabled}
        enableNext
        isLoading={isLoading}
      />
    </S.Wrapper>
  );
};

export default CombineWordsPre;
