const Strikethrough = () => (
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.375 6.25C1.19792 6.25 1.04958 6.19 0.93 6.07C0.81 5.95042 0.75 5.80208 0.75 5.625C0.75 5.44792 0.81 5.29937 0.93 5.17937C1.04958 5.05979 1.19792 5 1.375 5H12.625C12.8021 5 12.9504 5.05979 13.07 5.17937C13.19 5.29937 13.25 5.44792 13.25 5.625C13.25 5.80208 13.19 5.95042 13.07 6.07C12.9504 6.19 12.8021 6.25 12.625 6.25H1.375ZM6.0625 3.75V1.875H3.5625C3.30208 1.875 3.08083 1.78375 2.89875 1.60125C2.71625 1.41917 2.625 1.19792 2.625 0.9375C2.625 0.677083 2.71625 0.455833 2.89875 0.27375C3.08083 0.0912501 3.30208 0 3.5625 0H10.4375C10.6979 0 10.9192 0.0912501 11.1012 0.27375C11.2838 0.455833 11.375 0.677083 11.375 0.9375C11.375 1.19792 11.2838 1.41917 11.1012 1.60125C10.9192 1.78375 10.6979 1.875 10.4375 1.875H7.9375V3.75H6.0625ZM7 10C6.73958 10 6.51833 9.90875 6.33625 9.72625C6.15375 9.54417 6.0625 9.32292 6.0625 9.0625V7.5H7.9375V9.0625C7.9375 9.32292 7.84625 9.54417 7.66375 9.72625C7.48167 9.90875 7.26042 10 7 10Z"
      fill="currentColor"
    />
  </svg>
);
export default Strikethrough;
