import { BasicButton } from '../../../components/Button';

import Table from './Table';
import { createExerciseStepUrl } from 'CMS/utils';
import { useGetSteps } from 'api-calls/steps.queries';
import { useParams } from 'react-router-dom';

const ViewStepsByExerciseId = () => {
  const { id: exerciseId } = useParams();
  const { data: steps, loading } = useGetSteps({ exerciseId });

  return (
    <div>
      <Table data={steps} loading={loading} />

      <div>
        <BasicButton
          to={createExerciseStepUrl(exerciseId, 'new')}
          mt={5}
          loading={loading}
          maxWidth="352px"
          external
        >
          Add Step
        </BasicButton>
      </div>
    </div>
  );
};

export default ViewStepsByExerciseId;
