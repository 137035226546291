import { useMemo, useRef } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput, Dropdown } from '../../../../components/Inputs';
import * as S from './style';
import { Icon } from '../../../../components';
import { string, array, object } from 'yup';
import MDEditor from '../../../../components/MDEditor';
import theme from '../../../../theme';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import { combineWordsVariations, mediaTypes } from '../../../../constants';
import { getVariationOptions } from 'CMS/utils';
import { convertSnakeCaseToSpaces } from 'helpers';
import { MediaKeyInput } from '../../../components/MediaKeyInput';

const getDefaultOptions = (
  variation = combineWordsVariations.variations.DEFAULT
) => {
  if (variation === combineWordsVariations.variations.ONE_QUESTION_PER_ROW) {
    return [[{ pre: '', answer: '' }]];
  }
  return [
    [
      { pre: '', answer: '' },
      { pre: '', answer: '' },
    ],
  ];
};

const initialData = {
  variation: combineWordsVariations.variations.DEFAULT,
  options: getDefaultOptions(),
};

const CombineWords = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateDataFields, updateFormMetaData } = useStepForm(
    initialRef.current
  );
  const { data, validationErrs } = state;

  const variation = data.variation ?? initialData.variation;

  const options = useMemo(
    () => data?.options ?? getDefaultOptions(variation),
    [data?.options, variation]
  );

  const addSection = () => {
    updateDataFields({
      options: [...options, getDefaultOptions(variation)[0]],
    });
  };

  const removeCorrectOptions = (i) => {
    updateDataFields({
      options: options.filter((e, _i) => i !== _i),
    });
  };

  const onPreChange = (value, i, arrI) => {
    updateDataFields({
      options: options.map((e, _i) => {
        if (_i !== i) return e;
        return e.map((sec, secI) => {
          if (secI !== arrI) return sec;
          return { ...sec, pre: value };
        });
      }),
    });
  };

  const onAnswerChange = (value, i, arrI) => {
    updateDataFields({
      options: options.map((e, _i) => {
        if (_i !== i) return e;
        return e.map((sec, secI) => {
          if (secI !== arrI) return sec;
          return { ...sec, answer: value };
        });
      }),
    });
  };

  const onVariationChange = (variation) => {
    updateDataFields({
      variation,
      options: getDefaultOptions(variation),
    });
    updateFormMetaData({
      validationErrs: {},
    });
  };

  return (
    <div>
      <Dropdown
        label="Choose a variation"
        options={getVariationOptions(combineWordsVariations.variations)}
        selected={
          variation
            ? {
                value: variation,
                label: convertSnakeCaseToSpaces(variation),
              }
            : null
        }
        handleChange={onVariationChange}
        error={validationErrs?.variation}
      />

      {!!variation && (
        <>
          {variation ===
            combineWordsVariations.variations.ONE_QUESTION_PER_ROW && (
            <>
              <BasicInput
                value={data.textAudio}
                handleChange={(textAudio) => updateDataFields({ textAudio })}
                label="Explainer Text"
                error={validationErrs.textAudio}
                m={{ mt: 8 }}
              />

              <MediaKeyInput
                label="Explainer Audio"
                type={mediaTypes.TEXT_AUDIO}
                value={data.textAudioKey}
                handleChange={(textAudioKey) =>
                  updateDataFields({ textAudioKey })
                }
                m={{ mt: 5 }}
                error={validationErrs.textAudioKey}
              />
            </>
          )}

          <T.P mb={2} mt={8} size="large" weight="bold">
            Sections
          </T.P>

          {validationErrs?.options &&
            typeof validationErrs.options === 'string' && (
              <T.P mb={2} mt={2} color="error">
                {validationErrs.options}
              </T.P>
            )}
          {options.map((section, i) => {
            return (
              <div
                style={{
                  background: i % 2 ? 'white' : theme.colors.neutralLight,
                  border: `1px solid ${theme.colors.neutral50}`,
                  marginTop: '40px',
                }}
              >
                <T.P mb={2} mt={5} weight="bold" size="med">
                  Section {i + 1}
                </T.P>
                <>
                  <MDEditor
                    label="Prefix text first part"
                    value={section[0].pre}
                    onChange={(value) => {
                      onPreChange(value, i, 0);
                    }}
                    height={80}
                    m={{ mb: 2, mt: 5 }}
                    error={
                      !section[0]?.pre &&
                      validationErrs?.options &&
                      validationErrs?.options[i]?.pre
                    }
                    helper="e.g 'un + **pack** =' or '1 + 2 ='"
                  />
                  <BasicInput
                    label="Answer for the first part"
                    value={section[0].answer}
                    handleChange={(value) => {
                      onAnswerChange(value, i, 0);
                    }}
                    m={{ mb: 2, mt: 5 }}
                    error={
                      !section[0]?.answer &&
                      validationErrs?.options &&
                      validationErrs?.options[i]?.answer
                    }
                    helper="e.g 'unpack' or '3'"
                  />
                  {variation !==
                    combineWordsVariations.variations.ONE_QUESTION_PER_ROW && (
                    <>
                      <MDEditor
                        label="Prefix text second part"
                        value={section[1].pre}
                        onChange={(value) => {
                          onPreChange(value, i, 1);
                        }}
                        height={80}
                        m={{ mb: 2, mt: 7 }}
                        error={
                          !section[1]?.pre &&
                          validationErrs?.options &&
                          validationErrs?.options[i]?.pre
                        }
                        helper="e.g '+ **ed** ='"
                      />
                      <BasicInput
                        label="Answer for the second part"
                        value={section[1].answer}
                        handleChange={(value) => {
                          onAnswerChange(value, i, 1);
                        }}
                        m={{ mb: 2, mt: 5 }}
                        error={
                          !section[1]?.answer &&
                          validationErrs?.options &&
                          validationErrs?.options[i]?.answer
                        }
                        helper="e.g 'unpacked'"
                      />
                    </>
                  )}
                </>

                {options.length === 1 && i === 0 ? null : (
                  <S.Button
                    type="link"
                    danger
                    ghost
                    mt={1}
                    onClick={() => removeCorrectOptions(i)}
                    ml="auto"
                  >
                    <Icon icon="cross" />
                  </S.Button>
                )}
              </div>
            );
          })}

          <S.Button
            type="link"
            ghost
            mt={5}
            mb={5}
            onClick={addSection}
            ml="auto"
            mr="auto"
          >
            <Icon icon="plus" /> Add section
          </S.Button>
        </>
      )}
    </div>
  );
};

const validationSchema = {
  variation: string()
    .oneOf(Object.values(combineWordsVariations.variations))
    .required(),
  options: array()
    .of(
      array()
        .of(
          object()
            .shape({
              pre: string().required('required field'),
              answer: string().required('required field'),
            })
            .required('required field')
        )
        .test(
          'section-options-length',
          'Section options must have the correct number of arrays based on the variation 2 prefixes and 2 answers for the default 2 questions per row and 1 prefix and 1 answer for the one question per row variation',
          function (value) {
            // Access parent variation
            const variation = this.from[0].value.variation;

            const expectedLength =
              variation === combineWordsVariations.variations.DEFAULT ? 2 : 1;

            if (!value) return false; // Ensure `options` exists
            return value.length === expectedLength;
          }
        )
    )
    .required('You must provide sections')
    .min(1, 'You must provide sections')
    .when('variation', {
      is: (variation) =>
        variation === combineWordsVariations.variations.ONE_QUESTION_PER_ROW,
      then: (schema) =>
        schema.max(
          12,
          'The Sections must be less than or equal to 12 sections'
        ),
    }),
};
export { validationSchema };

export default CombineWords;
