import { Checkbox } from '../../components/Inputs';
import { mediaTypes } from '../../constants';
import { useState } from 'react';
import AudioVoModal from './AudioVoModal';
import { getMediaType } from '../utils';
import { useGetVoRequiredByFileKey } from 'api-calls/vo.queries';
import { Icon } from 'components';
import styled from '@emotion/styled';

const EditButton = styled.button`
  color: ${({ theme }) => theme.colors['primaryMain']};
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-top: ${({ theme }) => theme.spacings[2]};
  line-height: 24px;
  font-weight: bold;
  text-decoration: underline !important;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.primaryMain90};
    transition: 0.2s ease;
  }
`;

const RequireVo = ({ type, formType, index, existingFileKey }) => {
  const isAudio = getMediaType(type) === mediaTypes.AUDIO;
  const [isVoModalOpen, setIsVoModalOpen] = useState(false);
  const [requireVoChecked, setRequiresVoChecked] = useState(false);
  const { data: voRequired, isLoading: isFetchingVo } =
    useGetVoRequiredByFileKey(
      { fileKey: existingFileKey },
      {
        enabled:
          isAudio && !!existingFileKey && existingFileKey.startsWith('vo-'),
      }
    );

  if (!isAudio || isFetchingVo) return null;

  const showRequiresVo = isAudio && !existingFileKey;

  return (
    <>
      {showRequiresVo && (
        <Checkbox
          mt={4}
          ml={4}
          font="regular"
          label="Requires VO"
          plain
          checked={requireVoChecked}
          handleChange={(checked) => {
            setRequiresVoChecked(checked);
            setIsVoModalOpen(checked);
          }}
        />
      )}
      {!!voRequired && (
        <EditButton type="button" onClick={() => setIsVoModalOpen(true)}>
          <Icon icon="eye" mr={2} />
          View/Edit
        </EditButton>
      )}
      <AudioVoModal
        formType={formType}
        mediaType={index !== undefined ? `${type}-${index}` : type}
        open={isVoModalOpen}
        onCancel={() => {
          setIsVoModalOpen(false);
          setRequiresVoChecked(false);
        }}
        voRequired={voRequired}
      />
    </>
  );
};

export default RequireVo;
