import { fields, createSchema, validate as _validate } from '..';

const { firstName, lastName, email, requiredText, city, phoneNumber } = fields;

const schema = createSchema({
  firstName,
  lastName,
  email,
  availabilityHoursPerWeek: requiredText,
  bestDayTime: requiredText,
  city,
  phoneNumber,
});

const validate = (data) => _validate(schema, data);

export default validate;
