import { useMemo, useRef } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput, Dropdown } from '../../../../components/Inputs';
import * as S from './style';
import { Icon } from '../../../../components';
import { string, array, object, number } from 'yup';
import { MediaKeyInput } from '../../../components/MediaKeyInput';
import { mediaTypes, typeNewEndingVariations } from '../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import { convertSnakeCaseToSpaces } from 'helpers';

const initialData = {
  correctOptions: [{ id: 0, option: '' }],
  title: '',
  audioUrl: '',
  variation: typeNewEndingVariations.variations.DEFAULT,
};

const TypeNewEndings = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateDataFields } = useStepForm(initialRef.current);
  const { data, validationErrs } = state;

  const correctOptions = useMemo(
    () =>
      data?.correctOptions?.map((e, i) => ({
        ...e,
        id: i,
      })) || [{ id: 0, option: '' }],
    [data?.correctOptions]
  );

  const addOption = () => {
    const maxId = Math.max(...correctOptions.map((e) => e.id), 0);
    updateDataFields({
      correctOptions: [
        ...correctOptions,
        { id: maxId + 1, option: '', hide: false },
      ],
    });
  };
  const removeOption = (id) => {
    updateDataFields({
      correctOptions: correctOptions.filter((e) => e.id !== id),
    });
  };

  const onOptionChange = ({ key, value, id }) => {
    updateDataFields({
      correctOptions: correctOptions.map((e) => {
        if (e.id !== id) return e;
        return { ...e, [key]: value };
      }),
    });
  };

  const variationOptions = Object.keys(typeNewEndingVariations.variations).map(
    (e) => ({
      label: convertSnakeCaseToSpaces(e),
      value: e,
    })
  );

  const onVariationChange = (variation) => {
    updateDataFields({
      variation,
    });
  };

  const isSequences =
    data.variation === typeNewEndingVariations.variations.SEQUENCES;

  return (
    <div>
      <Dropdown
        label="Choose a variation"
        options={variationOptions}
        selected={
          data.variation
            ? {
                value: data.variation,
                label: convertSnakeCaseToSpaces(data.variation),
              }
            : null
        }
        handleChange={onVariationChange}
        error={validationErrs?.variation}
      />

      <BasicInput
        label="Explainer Text"
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        m={{ mt: 8 }}
        error={validationErrs?.textAudio}
      />
      <MediaKeyInput
        label={`Explainer Text file Key`}
        type={mediaTypes.TEXT_AUDIO}
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs?.textAudioKey}
      />

      <BasicInput
        label="Title"
        value={data.title}
        handleChange={(title) => updateDataFields({ title })}
        m={{ mt: 8 }}
        helper="e.g. 'Play'"
        error={validationErrs.title}
      />

      <T.P mt={8} size="large" weight="bold">
        Endings{' '}
      </T.P>

      {validationErrs?.correctOptions &&
        typeof validationErrs.correctOptions === 'string' && (
          <T.P mb={2} mt={2} color="error">
            {validationErrs.correctOptions}
          </T.P>
        )}

      {correctOptions.map((e, i) => {
        return (
          <>
            {isSequences ? (
              <S.AnswerInputWrapper>
                <BasicInput
                  label="Guide text"
                  value={e.guidText}
                  handleChange={(value) => {
                    onOptionChange({
                      key: 'guidText',
                      value,
                      id: e.id,
                    });
                  }}
                  m={{ mt: 5 }}
                  error={validationErrs?.correctOptions?.[i]?.guidText}
                  placeholder="10%"
                />
                <BasicInput
                  label="Correct Answer"
                  value={e.option}
                  handleChange={(value) => {
                    onOptionChange({
                      key: 'option',
                      value,
                      id: e.id,
                    });
                  }}
                  m={{ mt: 5 }}
                  error={validationErrs?.correctOptions?.[i]?.option}
                  placeholder="1000"
                />
              </S.AnswerInputWrapper>
            ) : (
              <BasicInput
                label="Text"
                value={e.option}
                handleChange={(value) => {
                  onOptionChange({
                    key: 'option',
                    value,
                    id: e.id,
                  });
                }}
                m={{ mt: 5 }}
                error={
                  validationErrs?.correctOptions &&
                  validationErrs?.correctOptions[i]?.option
                }
                helper={
                  'Add "+" between the word and the ending (e.g. Play+ed)'
                }
                placeholder="Play+ed"
              />
            )}
            {correctOptions.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeOption(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}
      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addOption}
        disabled={correctOptions.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add ending
      </S.Button>
    </div>
  );
};

const validationSchema = {
  variation: string()
    .oneOf(Object.values(typeNewEndingVariations.variations))
    .required(),
  correctOptions: array()
    .of(
      object()
        .shape({
          id: number().notRequired(),
          option: string().required('required field'),
          guidText: string().test(
            'guidText-required',
            'Guid Text is required',
            function (value) {
              // Access parent variation through this.parent
              const parentObject =
                (this.from && this.from[1] && this.from[1].value) || {};
              const variation = parentObject.variation;

              switch (variation) {
                case typeNewEndingVariations.variations.SEQUENCES:
                  return Boolean(value);

                default:
                  return true;
              }
            }
          ),
        })
        .required()
    )
    .required('you must provide endings')
    .min(1, 'you must provide endings'),
  title: string().required('required field'),
  textAudioKey: string().notRequired(),
  textAudio: string().notRequired(),
};
export { validationSchema };

export default TypeNewEndings;
