import * as S from './style';

const LoaderIcon = () => {
  return (
    <S.LoaderIconWrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="72"
        height="73"
        viewBox="0 0 72 73"
        fill="none"
      >
        <path
          opacity="0.65"
          d="M14.1971 62.6452C18.4376 62.6452 21.8752 59.2076 21.8752 54.9671C21.8752 50.7266 18.4376 47.2891 14.1971 47.2891C9.95663 47.2891 6.51904 50.7266 6.51904 54.9671C6.51904 59.2076 9.95663 62.6452 14.1971 62.6452Z"
          fill="#F3B319"
        />
        <path
          opacity="0.35"
          d="M63.5981 55.8514C67.2785 55.8514 70.262 52.8678 70.262 49.1874C70.262 45.507 67.2785 42.5234 63.5981 42.5234C59.9176 42.5234 56.9341 45.507 56.9341 49.1874C56.9341 52.8678 59.9176 55.8514 63.5981 55.8514Z"
          fill="#F3B319"
        />
        <path
          d="M57.5128 22.2288C60.5532 22.2288 63.0179 19.7641 63.0179 16.7238C63.0179 13.6834 60.5532 11.2188 57.5128 11.2188C54.4725 11.2188 52.0078 13.6834 52.0078 16.7238C52.0078 19.7641 54.4725 22.2288 57.5128 22.2288Z"
          fill="#FFF9E1"
        />
        <path
          opacity="0.75"
          d="M8.14829 43.9108C12.6484 43.9108 16.2965 40.3517 16.2965 35.9613C16.2965 31.5709 12.6484 28.0117 8.14829 28.0117C3.64818 28.0117 0.00012207 31.5709 0.00012207 35.9613C0.00012207 40.3517 3.64818 43.9108 8.14829 43.9108Z"
          fill="#F3B319"
        />
        <path
          opacity="0.55"
          d="M31.5555 72.4987C35.6465 72.4987 38.963 69.2671 38.963 65.2806C38.963 61.2942 35.6465 58.0625 31.5555 58.0625C27.4645 58.0625 24.1481 61.2942 24.1481 65.2806C24.1481 69.2671 27.4645 72.4987 31.5555 72.4987Z"
          fill="#F3B319"
        />
        <path
          opacity="0.45"
          d="M50.5923 69.3483C54.4788 69.3483 57.6293 66.2806 57.6293 62.4964C57.6293 58.7122 54.4788 55.6445 50.5923 55.6445C46.7059 55.6445 43.5553 58.7122 43.5553 62.4964C43.5553 66.2806 46.7059 69.3483 50.5923 69.3483Z"
          fill="#F3B319"
        />
        <path
          opacity="0.85"
          d="M16.9632 25.4465C21.6678 25.4465 25.4816 21.7237 25.4816 17.1315C25.4816 12.5392 21.6678 8.81641 16.9632 8.81641C12.2585 8.81641 8.4447 12.5392 8.4447 17.1315C8.4447 21.7237 12.2585 25.4465 16.9632 25.4465Z"
          fill="#F3B319"
        />
        <path
          opacity="0.2"
          d="M65.7035 38.0505C69.1809 38.0505 71.9998 35.2838 71.9998 31.8709C71.9998 28.4581 69.1809 25.6914 65.7035 25.6914C62.2262 25.6914 59.4072 28.4581 59.4072 31.8709C59.4072 35.2838 62.2262 38.0505 65.7035 38.0505Z"
          fill="#F3B319"
        />
        <path
          d="M38.1012 18.4677C43.0617 18.4677 47.083 14.4464 47.083 9.4858C47.083 4.52524 43.0617 0.503906 38.1012 0.503906C33.1406 0.503906 29.1193 4.52524 29.1193 9.4858C29.1193 14.4464 33.1406 18.4677 38.1012 18.4677Z"
          fill="#F3B319"
        />
      </svg>
    </S.LoaderIconWrapper>
  );
};

export default LoaderIcon;
