import styled from '@emotion/styled';

export const TraningCard = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  padding: 16px;
  border: 2px solid ${({ theme }) => theme.colors.primaryMain};
  border-radius: 16px;
  cursor: pointer;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const StepNo = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9.2px;
  background-color: ${({ theme }) => theme.colors.primaryMain};
`;
