import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import * as T from '../../../../components/Typography';
import { PlayButton } from '../../../../components/Button';
import Markdown from '../../../../components/Markdown';
import { createH1 } from '../../../../utils/markdown';
import DisplayMultipleWords from './DisplayMultipleWords';
import StepFooterButtons from '../StepFooterButtons';

import * as S from './style';

const Step = ({
  data: { title, subtitle, textAudio, textAudioUrl, options } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
}) => {
  const [enableNext, setEnableNext] = useState(false);
  const [itemsPlayed, setItemsPlayed] = useState({});

  const location = useLocation();
  useEffect(() => {
    setItemsPlayed({});
    setEnableNext(false);
  }, [location]);

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        {textAudio ? (
          <S.TextAudioWrapper mt={1} mb={5}>
            {textAudioUrl ? (
              <>
                <PlayButton
                  audioUrl={textAudioUrl}
                  width="56px"
                  height="56px"
                  iconProps={{ width: '32px', height: '32px' }}
                  fallbackText={textAudio}
                />
                <T.P ml={4} weight="regular" size="med">
                  {textAudio}
                </T.P>
              </>
            ) : (
              <T.P weight="regular" size="med">
                {textAudio}
              </T.P>
            )}
          </S.TextAudioWrapper>
        ) : null}
        {title ? (
          <Markdown
            text={createH1(title)}
            customStyles={{
              h1: {
                size: 'extraLarge',
                ta: 'center',
                weight: 'regular',
                mb: 2,
              },
            }}
          />
        ) : null}
        {subtitle ? (
          <Markdown
            text={subtitle}
            customStyles={{
              p: { size: 'med', ta: 'center', mb: 4 },
            }}
          />
        ) : null}
        {Array.isArray(options) &&
        options?.length &&
        options.some((value) => value.audioUrl) ? (
          <DisplayMultipleWords
            grid
            wordsArray={options}
            setEnableNext={setEnableNext}
            setItemsPlayed={setItemsPlayed}
            itemsPlayed={itemsPlayed}
          />
        ) : null}
        {options?.length && !options.some((value) => value.audioUrl) ? (
          <DisplayMultipleWords
            find
            wordsArray={options}
            setEnableNext={setEnableNext}
            setItemsPlayed={setItemsPlayed}
            itemsPlayed={itemsPlayed}
          />
        ) : null}
      </S.ContentWrapper>
      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={() => {
          handleNext();
          setEnableNext(false);
        }}
        preview={preview}
        disabled={disabled}
        enableNext={enableNext}
      />
    </S.Wrapper>
  );
};

export default Step;
