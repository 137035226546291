import TrainingChecklist from './TrainingChecklist';
import Safeguarding from './Safeguarding';
import Advice from './Advice';
import Qualities from './Qualities';
import ArrangeCoachingSession from './ArrangeCoachingSession';
import AllSessions from './AllSessions';
export default {
  TrainingChecklist,
  Safeguarding,
  Advice,
  Qualities,
  ArrangeCoachingSession,
  AllSessions,
};
