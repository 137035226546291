import { useRef } from 'react';
import { string } from 'yup';

import MDEditor from '../../../../components/MDEditor';
import { MediaKeyInput } from '../../../components/MediaKeyInput';
import { BasicInput } from '../../../../components/Inputs';
import { mediaTypes } from '../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';

const initialData = {
  text: '',
  textAudioKey: '',
  textAudio: '',
};

const TextFinder = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateDataFields } = useStepForm(initialRef.current);
  const { data, validationErrs } = state;

  return (
    <div>
      <BasicInput
        label="Explainer Text"
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        m={{ mt: 8 }}
      />
      <MediaKeyInput
        label={`Explainer Text file Key`}
        type={mediaTypes.TEXT_AUDIO}
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
      />

      <MDEditor
        value={data.text}
        onChange={(text) => updateDataFields({ text })}
        mode={'edit'}
        label="Text"
        helper='put "%" around the letter/word to make it selectable'
        error={validationErrs.text}
        m={{ mt: 8 }}
      />
    </div>
  );
};

const validationSchema = {
  text: string().required('required field'),
  textAudioKey: string().notRequired(),
  textAudio: string().notRequired(),
};

export { validationSchema };
export default TextFinder;
