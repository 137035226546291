import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import * as S from './style.js';
import * as CS from './../style';
import * as T from '../../Typography';
import Icon from '../../Icon';
import { forwardRef } from 'react';

const CustomPaper = (props) => {
  return (
    <Paper
      style={{
        marginTop: 5,
      }}
      {...props}
    />
  );
};

const NewDropDown = forwardRef(
  (
    {
      handleChange,
      label,
      color,
      placeholder = 'Select...',
      error,
      helper,
      w,
      disabled,
      options = [],
      selected,
      multi,
      m,
      search,
      optional,
      autocompleteProps,
    },
    ref
  ) => {
    const open = false;
    const focus = false;
    const decideColor = () => {
      if (error) return 'error';
      return color;
    };

    const mappedOptions = options.map((option) =>
      typeof option === 'string' ? { label: option, value: option } : option
    );

    return (
      <S.Field
        w={w}
        disabled={disabled}
        open={open}
        focus={focus}
        multi={multi}
        color={decideColor()}
        error={error}
        search={search}
        {...m}
      >
        {' '}
        {label && (
          <CS.Label htmlFor={label} mb={helper ? '1' : '2'}>
            <T.P weight="bold" mb={helper ? '0' : '4'}>
              {label}
            </T.P>
            {optional && <CS.OptionalTag ml="1">(optional)</CS.OptionalTag>}
          </CS.Label>
        )}
        {helper && (
          <T.P color="neutralMain" mb="4" ml={2}>
            {helper}
          </T.P>
        )}
        <S.Answer>
          <Autocomplete
            ref={ref}
            getOptionLabel={(option) => option?.label || ''}
            renderOption={(props, option) => (
              <MenuItem
                {...props}
                disabled={option?.disabled}
                sx={{
                  '&.Mui-disabled': {
                    opacity: 1,
                  },
                }}
              >
                <T.P
                  color="neutralMain"
                  weight={option?.bold ? 'bold' : 'normal'}
                  size="small"
                >
                  {option?.label || option}
                </T.P>
              </MenuItem>
            )}
            options={mappedOptions}
            value={selected || []}
            disabled={disabled}
            onChange={(op, val) => {
              handleChange(multi ? val.map((e) => e?.value) : val?.value);
            }}
            isOptionEqualToValue={(option, selectedOption) =>
              option?.value === selectedOption?.value
            }
            search={search}
            fullWidth
            disablePortal
            PaperComponent={CustomPaper}
            id={`combo-box-demo${label}`}
            groupBy={(option) => option.group}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <S.StyledField
                {...params}
                multiline={true}
                rows={1.22}
                fullWidth
                hiddenLabel
                placeholder={
                  (multi && selected?.length === 0) || !selected
                    ? placeholder
                    : ''
                }
                label={`hidden-label ${label}`}
                focused
                InputLabelProps={{
                  style: {
                    clip: 'rect(0 0 0 0)',
                    clipPath: 'inset(50%)',
                    height: 1,
                    overflow: 'hidden',
                    position: 'absolute',
                    whiteSpace: 'nowrap',
                    width: 1,
                  },
                }}
              />
            )}
            multiple={multi}
            popupIcon={
              search ? (
                <S.StyledIcon
                  icon="search"
                  color="black"
                  width="20"
                  height="22"
                />
              ) : (
                <Icon
                  icon="chevronDown"
                  color="neutralMain"
                  width="20"
                  height="22"
                />
              )
            }
            {...autocompleteProps}
          />{' '}
        </S.Answer>
        {error && (
          <T.P color="error" m="0" mt="1">
            {error}
          </T.P>
        )}
      </S.Field>
    );
  }
);

export default NewDropDown;
