import React from 'react';

const Copy4 = ({ width, height, color, ...props }) => (
  <svg
    width={width || '16'}
    height={height || '16'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
  >
    <path
      fill="#050D30"
      fill-rule="evenodd"
      d="M5.5 8.833V8c0-1.378 1.122-2.5 2.5-2.5h.833V2.723a.556.556 0 0 0-.556-.556H2.723a.556.556 0 0 0-.556.556v5.554c0 .307.249.556.556.556H5.5Zm0 1.667H2.723A2.225 2.225 0 0 1 .5 8.277V2.723C.5 1.497 1.497.5 2.723.5h5.554c1.226 0 2.223.997 2.223 2.223V5.5H13c1.378 0 2.5 1.122 2.5 2.5v5c0 1.378-1.122 2.5-2.5 2.5H8A2.503 2.503 0 0 1 5.5 13v-2.5ZM7.167 8c0-.46.374-.833.833-.833h5c.46 0 .833.374.833.833v5c0 .46-.373.833-.833.833H8A.835.835 0 0 1 7.167 13V8Z"
      clip-rule="evenodd"
    />
  </svg>
);

export default Copy4;
