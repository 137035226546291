import theme from '../../../theme';

const Send = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75606 14.5003L8.6627 16.6542C8.68384 16.5879 8.71931 16.5265 8.7673 16.4745L20.4695 3.87207C20.4575 3.87669 20.4455 3.88183 20.4336 3.88751L3.71616 11.8332C2.98661 12.18 2.67571 13.0534 3.02084 13.7845C3.17172 14.1041 3.4328 14.3583 3.75606 14.5003ZM9.6 16.9877V17.0657L16.3675 20.0365C17.108 20.3616 17.9708 20.0228 18.2943 19.2809C18.3387 19.1792 18.3713 19.0728 18.3917 18.9637L21.0688 4.63751L9.6 16.9877Z"
      fill={color || theme.colors.primaryLight}
    />
    <path
      d="M11.6572 19.0171L11.232 19.584C10.9601 19.9466 10.5333 20.16 10.08 20.16C9.28472 20.16 8.64001 19.5153 8.64001 18.72V17.6926L11.6572 19.0171Z"
      fill={color || theme.colors.primaryLight}
    />
  </svg>
);

export default Send;
