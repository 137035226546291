import styled from '@emotion/styled';
import MuiModal from '@mui/material/Modal';

export const Modal = styled(({ maxWidth, ...props }) => (
  <MuiModal {...props} />
))`
  max-width: ${({ maxWidth = '375px' }) => maxWidth};
  height: auto;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  @media (max-width: 949px) {
    padding-inline: ${({ theme }) => theme.spacings[4]};
  }
  @media (max-width: 580px) {
    max-width: 100%;
    padding-inline: ${({ theme }) => theme.spacings[4]};
  }
`;

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme, bgColor }) =>
    theme.colors[bgColor] || theme.colors.primaryMain};
  border: none;
  box-shadow: none;
  padding: ${({ padding }) => padding || '32px'};
  @media (max-width: 580px) {
    max-width: 100%;
    padding: ${({ padding }) => padding || '16px'};
  }
`;
