import * as T from '../../../components/Typography';
import { IconWithParagraph } from '../../../components';
import * as S from './style';

const coachTips = {
  'What is a Reading Coach?': [
    'Reading coaches are the people who support learners on a 1:1 basis.',
    'They use {courseName} to deliver reading sessions.',
    'Reading Coaches are not expected to become teachers or tutors - they are supporting someone to learn.',
    'Reading Coaches will encourage learners to set their own reading goals.',
    'They will monitor learners progress and celebrate their achievements.',
    'They may on occasion offer their learner some practical support.',
  ],
  'How can I recruit reading coaches?': [
    'Talk to existing staff, volunteers, mentors or even clients.',
    'Post an advertisement on your website or through social media.',
    'Liaise with local volunteering agencies.',
    'Develop partnerships e.g. with local universities.',
  ],
  'What skills/traits am I looking for?': [
    'Reasonable level of reading',
    'Personable',
    'Patient',
    'Boundaried',
    'Resilient',
    'Kind',
    'Supportive',
  ],
  'Using {courseName} Digital': [
    'The recruit coaches section on the right of this page allows you to develop your own application form and create a link to this which will enable you to include in an advertisement. This means you could develop your own recruitment process for your coaches.',
    'If you create an application form and would like to send it directly to someone you can do this by adding their email address to the ‘add coaches email section’.',
    'If you already know who your coaches are, you can simply add them via their email on the ‘add coaches manually’ tab. Once added the system will send them a link to create their account and begin their training.',
  ],
};

const Tip = ({ txt, courseName }) => (
  <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
    <T.P>{txt.replace(/\{courseName\}/, courseName)}</T.P>
  </IconWithParagraph>
);

const AddCoachesTips = ({ courseName }) => {
  return (
    <>
      <T.H2>Tips for recruiting coaches</T.H2>
      {Object.keys(coachTips).map((key, i) => (
        <S.TipsGroupWrapper>
          <T.H3 key={key} mt="5">
            {key.replace(/\{courseName\}/, courseName)}
          </T.H3>
          {coachTips[key].map((txt) => (
            <Tip key={txt} txt={txt} courseName={courseName} />
          ))}
        </S.TipsGroupWrapper>
      ))}
    </>
  );
};

export default AddCoachesTips;
