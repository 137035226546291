import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentWrapper = styled.div`
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacings[6]};
  margin-right: ${({ theme }) => theme.spacings[6]};
  margin-bottom: ${({ theme }) => theme.spacings[8]};
`;

export const PreFilledAnswersGridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: ${({ theme }) => theme.spacings[6]} 0px;
  background: ${({ theme }) => theme.colors.neutralLight};
  min-width: 310px;
`;

export const PyramidRowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
`;

export const BoxWrapper = styled.div`
  position: relative;

  ${({ showLine, index, theme }) => {
    if (showLine) {
      return `
        &::before {
          content: '';
          position: absolute;
          width: 2px;
          height: 40px;
          background-color: ${theme.colors.neutralMain};
          transform: translateY(-100%) rotate(${
            index === 0 ? '50deg' : '130deg'
          });
          top: 8px;
          left: ${index === 0 ? '70%' : '30%'};
        }
      `;
    }
    return '';
  }}
`;

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 32px 48px;
  background: ${({ rowIndex, theme }) =>
    rowIndex % 2 === 0 ? theme.colors.white : theme.colors.neutralLightNew};
`;

export const TypeInput = styled.input`
  border-style: solid;
  border-width: ${({ showValue, isEqualInput }) =>
    showValue ? '0' : isEqualInput ? '0 0 2px' : '2px'};
  border-radius: ${({ isEqualInput }) => (isEqualInput ? '0' : '12px')};
  border-color: ${({ theme, isCorrect }) =>
    isCorrect ? theme.colors.quinaryMain : theme.colors.primaryMain};
  width: ${({ isEqualInput }) => (isEqualInput ? '98px' : '64px')};
  height: 64px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 150%;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutralMain};
  background: ${({ theme, showValue }) =>
    showValue ? theme.colors.white : 'transparent'};
  transition: 0.3s;
  &:focus,
  &:focus-visible {
    border-color: ${({ theme, isCorrect }) =>
      isCorrect ? theme.colors.quinaryMain : theme.colors.neutralMain};
    outline: none;
  }
`;
