import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import SortIcon from './SortIcon';
import * as T from 'components/Typography';

import * as S from './style';

const SelectSortOptionComponent = ({ sortOptions, onChange, width }) => {
  const [value, setValue] = useState('');
  return (
    <RadioGroup
      aria-labelledby="controlled-radio-buttons-group"
      name="controlled-radio-buttons-group"
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder="Sort by"
      sx={{
        width: width || '100%',
      }}
    >
      <S.StyledField
        select
        placeholder="Sort by"
        value={value}
        SelectProps={{
          IconComponent: () => null,
          placeholder: 'Sort by',
          displayEmpty: true,
          renderValue: (value) => (
            <T.P>
              {value
                ? sortOptions?.find((option) => option.value === value)?.label
                : 'Sort By'}
            </T.P>
          ),
        }}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SortIcon />
            </InputAdornment>
          ),
        }}
      >
        {sortOptions.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              <FormControlLabel
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            </MenuItem>
          );
        })}
      </S.StyledField>
    </RadioGroup>
  );
};

export default SelectSortOptionComponent;
