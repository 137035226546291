import * as T from '../../../components/Typography';
import { BasicButton } from '../../../components/Button';
import { Textarea } from '../../../components/Inputs';
import Modal from './Modal';
import FacilitatorsInvitesTable from './FacilitatorsInvitesTable';
import useInviteFacilitators from './useInviteFacilitators';
import * as S from './style';

const AddFacilitatorCard = () => {
  const {
    addresses,
    setAddresses,
    visible,
    facilitatorsInvites,
    handleSubmit,
    validationErr,
    httpError,
    isError,
    isLoading,
    setVisible,
    reset,
  } = useInviteFacilitators();

  return (
    <>
      <S.CardWrapper>
        <T.H2 color="neutralMain" mb="4">
          Add facilitators manually
        </T.H2>
        <T.P mb="5">
          When you add the facilitator they will receive an invite link to
          create their account. Enter their email addresses below, separated by
          commas. Each email will then receive a unique link to create their
          facilitator account on your platform.
        </T.P>
        <Textarea
          label={`Facilitator email addresses`}
          placeholder="Type or paste here..."
          value={addresses}
          handleChange={setAddresses}
          m={{ mt: 5 }}
          error={validationErr}
        />
        {isError && (
          <T.P color="error" mt="2" mb="-20px">
            {httpError?.message || 'Something went wrong'}
          </T.P>
        )}
        <BasicButton mt="20px" onClick={handleSubmit} loading={isLoading}>
          <T.P color="white" weight="semi">
            Add facilitators
          </T.P>
        </BasicButton>
      </S.CardWrapper>
      <Modal visible={visible}>
        <FacilitatorsInvitesTable invites={facilitatorsInvites} />
        <S.ButtonWrapper>
          <BasicButton
            handleClick={() => {
              reset();
              setVisible(false);
            }}
            variant="secondary"
            maxWidth="280px"
          >
            <T.P color="neutralSurface" weight="bold">
              Back
            </T.P>
          </BasicButton>
        </S.ButtonWrapper>
      </Modal>
    </>
  );
};

export default AddFacilitatorCard;
