import * as T from '../Typography';
import Icon from '../Icon';
import { useCopyToClipboard } from 'Hooks';

const CopyLink = ({ text, title = 'Copy Link' }) => {
  const { copied, copyToClipboard } = useCopyToClipboard();

  return (
    <T.P
      onClick={() => copyToClipboard(text)}
      style={{ display: 'flex', color: '#405ED6', cursor: 'pointer' }}
    >
      <Icon icon={copied ? 'tick' : 'copy3'} />
      <div
        style={{
          textDecoration: 'underline',
          whiteSpace: 'nowrap',
          marginLeft: '3px',
          width: '98px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {copied ? 'Copied' : title}
      </div>
    </T.P>
  );
};

export default CopyLink;
