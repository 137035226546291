import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import * as T from 'components/Typography';
import React, { forwardRef } from 'react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const OptionalTag = styled.span`
  display: inline-block;
  font-weight: 400 !important;
  color: ${({ theme }) => theme.colors.neutral80};
`;

const TimePicker = forwardRef(function TimePicker(
  { muiTextFieldProps: { label, error, optional, labelColor, color, ...rest } },
  ref
) {
  return (
    <Wrapper>
      <label htmlFor="timeFrom">
        <T.P as="label" weight="bold" color={labelColor || color} m="0" ml="1">
          {label} {optional && <OptionalTag ml="1">(optional)</OptionalTag>}
        </T.P>
      </label>
      <TextField
        {...rest}
        type="time"
        ref={ref}
        sx={{
          border: `2px solid ${error ? '#D83A2C' : '#333'}`,
          borderRadius: '1rem',
          '& fieldset': {
            border: 'none',
          },
        }}
      />
      {error && (
        <T.P color="error" m="0" ml="3" mt="2">
          {error}
        </T.P>
      )}
    </Wrapper>
  );
});

export default TimePicker;
