import copy from 'copy-to-clipboard';
import { useState } from 'react';

const useCopyToClipboard = () => {
  const [copied, setCopied] = useState(false);

  const resetCopiedState = () => setTimeout(() => setCopied(false), 750);

  /**
   * @param {string} textToCopy
   */
  const copyToClipboard = async (textToCopy) => {
    setCopied(false);
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(textToCopy);
        setCopied(true);
        resetCopiedState();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Failed to copy text: ', err);
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn('Clipboard API not supported, using fallback method.');
      copy(textToCopy); // used as a fallback if clipboard api is not supported
      setCopied(true);
      resetCopiedState();
    }
  };

  return {
    copied,
    setCopied,
    copyToClipboard,
  };
};

export default useCopyToClipboard;
