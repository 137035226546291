import theme from '../../../theme';

const Trash2 = ({ width, height, color, ...props }) => (
  <svg
    width={width || '28'}
    height={height || '28'}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.6665 24.5C8.02484 24.5 7.47573 24.2717 7.01917 23.8152C6.56184 23.3578 6.33317 22.8083 6.33317 22.1667V7C6.00262 7 5.72534 6.88839 5.50134 6.66517C5.27812 6.44117 5.1665 6.16389 5.1665 5.83333C5.1665 5.50278 5.27812 5.2255 5.50134 5.0015C5.72534 4.77828 6.00262 4.66667 6.33317 4.66667H10.9998C10.9998 4.33611 11.1118 4.05883 11.3358 3.83483C11.5591 3.61161 11.8359 3.5 12.1665 3.5H16.8332C17.1637 3.5 17.441 3.61161 17.665 3.83483C17.8882 4.05883 17.9998 4.33611 17.9998 4.66667H22.6665C22.9971 4.66667 23.2739 4.77828 23.4972 5.0015C23.7212 5.2255 23.8332 5.50278 23.8332 5.83333C23.8332 6.16389 23.7212 6.44117 23.4972 6.66517C23.2739 6.88839 22.9971 7 22.6665 7V22.1667C22.6665 22.8083 22.4382 23.3578 21.9817 23.8152C21.5243 24.2717 20.9748 24.5 20.3332 24.5H8.6665ZM8.6665 7V22.1667H20.3332V7H8.6665ZM10.9998 18.6667C10.9998 18.9972 11.1118 19.2741 11.3358 19.4973C11.5591 19.7213 11.8359 19.8333 12.1665 19.8333C12.4971 19.8333 12.7743 19.7213 12.9983 19.4973C13.2216 19.2741 13.3332 18.9972 13.3332 18.6667V10.5C13.3332 10.1694 13.2216 9.89217 12.9983 9.66817C12.7743 9.44494 12.4971 9.33333 12.1665 9.33333C11.8359 9.33333 11.5591 9.44494 11.3358 9.66817C11.1118 9.89217 10.9998 10.1694 10.9998 10.5V18.6667ZM15.6665 18.6667C15.6665 18.9972 15.7785 19.2741 16.0025 19.4973C16.2257 19.7213 16.5026 19.8333 16.8332 19.8333C17.1637 19.8333 17.441 19.7213 17.665 19.4973C17.8882 19.2741 17.9998 18.9972 17.9998 18.6667V10.5C17.9998 10.1694 17.8882 9.89217 17.665 9.66817C17.441 9.44494 17.1637 9.33333 16.8332 9.33333C16.5026 9.33333 16.2257 9.44494 16.0025 9.66817C15.7785 9.89217 15.6665 10.1694 15.6665 10.5V18.6667Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default Trash2;
