import React from 'react';

const StepToProgress = ({ width, height, color, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '304'}
      height={height || '219'}
      fill="none"
      viewBox="0 0 304 219"
    >
      <path
        stroke={color || '#050D30'}
        stroke-dasharray="13 13"
        stroke-linecap="round"
        stroke-width="4"
        d="M302 217s-47.932-3.007-112.27-106.748C136.774 9.517 2 76.172 2 2"
      />
    </svg>
  );
};

export default StepToProgress;
