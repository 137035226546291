import React from 'react';

const LeftStepToRightStep = ({ width, height, color, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '304'}
      height={height || '214'}
      fill="none"
      viewBox="0 0 304 214"
    >
      <path
        stroke={color || '#050D30'}
        stroke-dasharray="13 13"
        stroke-linecap="round"
        stroke-width="4"
        d="M302 211.5S263.45 131 186.844 124C41.782 110.745 2 96.5 2 2"
      />
    </svg>
  );
};

export default LeftStepToRightStep;
