import theme from '../../../theme';

const Edit3 = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '25'}
    height={height || '24'}
    viewBox="0 0 25 24"
    fill="none"
    {...props}
  >
    <path
      d="M18.6202 8.28853C18.6202 7.89088 18.9425 7.56853 19.3402 7.56853C19.7378 7.56853 20.0602 7.89088 20.0602 8.28853V16.2748C20.0602 18.0252 18.6072 19.4396 16.8202 19.4396H8.42018C6.63312 19.4396 5.18018 18.0252 5.18018 16.2748V7.72435C5.18018 5.97393 6.63312 4.55957 8.42018 4.55957H16.2386C16.6363 4.55957 16.9586 4.88193 16.9586 5.27957C16.9586 5.67722 16.6363 5.99957 16.2386 5.99957H8.42018C7.42329 5.99957 6.62018 6.77481 6.62018 7.72435V16.2748C6.62018 17.2243 7.42329 17.9996 8.42018 17.9996H16.8202C17.8171 17.9996 18.6202 17.2243 18.6202 16.2748V8.28853Z"
      fill={theme.colors[color] || color || '#050D30'}
    />
    <path
      d="M19.7907 3.81049C20.0719 3.52931 20.5278 3.52931 20.809 3.81049C21.0902 4.09167 21.0902 4.54755 20.809 4.82873L14.089 11.5487C13.8078 11.8299 13.3519 11.8299 13.0707 11.5487C12.7896 11.2675 12.7896 10.8117 13.0707 10.5305L19.7907 3.81049Z"
      fill={theme.colors[color] || color || '#050D30'}
    />
  </svg>
);

export default Edit3;
