import { Progress } from 'antd';
import { useParams } from 'react-router-dom';
import colors from '../../../theme/colors';
import * as S from './style';
import { Link } from 'react-router-dom';
import Icon from '../../Icon';
import * as T from '../../Typography';
import { EXERCISES } from '../../../constants/nav-routes';
import { useGeneralState } from '../../../context/general-state';
import { useAuth } from '../../../context/auth';
import ParkingIconAndModal from '../../ParkingIconAndModal';

const ProgressHeader = ({ showNotes, showParking, progressPercent }) => {
  const { exerciseId } = useParams();

  const { state } = useGeneralState();

  const {
    headerSteps: { show, completedSteps, requiredSteps },
  } = state;
  const { user } = useAuth();
  return (
    <S.ProgressWrapper bgColor={user.overlayColor}>
      {show ? (
        <T.P
          size="xLarge"
          weight="bold"
          style={{
            minWidth: 'fit-content',
          }}
          mr={2}
        >
          {completedSteps >= requiredSteps
            ? completedSteps
            : `${completedSteps}/${requiredSteps}`}
        </T.P>
      ) : null}
      <Progress
        percent={progressPercent * 100}
        showInfo={false}
        type="line"
        strokeColor={colors.neutralMain}
        strokeWidth={17}
      />
      {showParking && <ParkingIconAndModal />}
      {showNotes && (
        <Link
          to={EXERCISES.COACH_NOTES.replace(':exerciseId', exerciseId)}
          state={{ canGoBack: true }}
          aria-label="Coach Notes"
        >
          <Icon
            icon="note"
            color="neutralMain"
            ml={2}
            style={{ cursor: 'pointer' }}
            width={44}
            height={44}
          />
        </Link>
      )}
    </S.ProgressWrapper>
  );
};

export default ProgressHeader;
