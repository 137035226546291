import { useRef, useState } from 'react';
import { Col, Row } from 'components/Grid';
import { BasicButton } from '../../../components/Button';
import * as S from './style';
import { Typography as T } from 'components';

import {
  navRoutes,
  organisationStatuses,
  coachStatusesQuery,
} from '../../../constants';

import { useUpdateOrganisationStatus } from 'api-calls/organisations.queries';

import OrganisationsTable from '../Organisations/OrganisationsTable';
import Modal from '../../../components/Modal';
import FeedbackSection from '../Organisations/FeedbackSection';
import { useNavigate } from 'react-router-dom';

import QuickAccessCard from 'components/QuickAccessCard';
import Stack from '@mui/material/Stack';
import { getGreetings } from 'helpers';
import { useAuth } from 'context/auth';

import theme from 'theme';
import { useSiteSettings } from 'context/site-settings';

const Organisations = ({ hideFeedback }) => {
  const status = useRef(null);
  const id = useRef(null);
  const { user } = useAuth();
  const [isUpdateModalOpen, setIsUpdateModalVisible] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalVisible] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { mutateAsync: updateOrganisationStatus, isLoading: updateLoading } =
    useUpdateOrganisationStatus();
  const { courseName } = useSiteSettings();

  const activateAndDeactivate = async () => {
    await updateOrganisationStatus(
      {
        id: id.current,
        status: status.current,
      },
      {
        onSuccess: () => {
          setIsUpdateModalVisible(false);
          setIsSuccessModalVisible(true);
        },
        onError: (error) => {
          setError(error.message);
        },
      }
    );
  };

  const onActivateAndDeactivate = (_id, _status) => {
    status.current = _status;
    id.current = _id;
    setIsUpdateModalVisible(true);
  };

  return (
    <>
      <Row inner jc="space-between" ai="center">
        <Col w={[4, 12, 10]} dir="column" ai="flex-start">
          <T.H1 mb="2">{`Welcome back to ${courseName}`}</T.H1>
          <T.P>
            {getGreetings()}, {user.firstName}!
          </T.P>
        </Col>
      </Row>

      {error && (
        <Col w={[4, 12, 12]}>
          <T.P color="error">{error}</T.P>
        </Col>
      )}

      <S.QuickAccessWrapper>
        <T.H2 mb="16px" weight="bold" size="med">
          Quick Access
        </T.H2>

        <Stack
          spacing="16px"
          direction={{
            xs: 'column',
            md: 'row',
          }}
          sx={{
            flexWrap: {
              xs: 'wrap',
              md: 'nowrap',
            },
          }}
        >
          <QuickAccessCard
            text="Learners"
            to={navRoutes.COMMON.LEARNERS}
            bgColor={theme.colors.neutralLight}
          />
          <QuickAccessCard
            text="Coaches"
            to={navRoutes.COMMON.COACHES.replace(
              ':statusType',
              coachStatusesQuery.ALL
            )}
            bgColor={theme.colors.neutralLight}
          />
          <QuickAccessCard
            text="Facilitators"
            to={navRoutes.COMMON.FACILITATORS}
            bgColor={theme.colors.neutralLight}
          />
        </Stack>
      </S.QuickAccessWrapper>
      {hideFeedback ? null : <FeedbackSection />}
      <Row
        mb={7}
        inner
        jc="space-between"
        ai="center"
        mt={!hideFeedback && '8'}
        mtT={!hideFeedback && '6'}
      >
        <S.TableButtonWrapper>
          <S.TableTitleDescriptionWrapper>
            <T.H1 mb="2">Organisations</T.H1>
            <T.P>
              Unlocking Learning Synergy: Explore and Connect with Diverse
              Organizations
            </T.P>
          </S.TableTitleDescriptionWrapper>

          <BasicButton
            ml="16px"
            mlM="0"
            mtM="16px"
            width="230px"
            handleClick={() => navigate(navRoutes.ADMIN.ADD_ORGANISATION)}
          >
            Add organisation
          </BasicButton>
        </S.TableButtonWrapper>
      </Row>
      <Row>
        <OrganisationsTable onActivateAndDeactivate={onActivateAndDeactivate} />
      </Row>

      <Modal
        visible={isUpdateModalOpen}
        setIsModalVisible={setIsUpdateModalVisible}
      >
        <Row>
          <Col w={[4, 12, 12]}>
            <T.P size="med" weight="bold" color="white">
              Are you sure?
            </T.P>
            <T.P size="regular" color="white" mt={4} mb={4}>
              {status.current === organisationStatuses.DEACTIVATED
                ? `By deactivating them you will remove their access to the platform as well as all their facilitators, coaches and learners.`
                : `By activating them they will be able to access the platform via their original log in details.`}
            </T.P>

            <BasicButton
              variant="secondary"
              onClick={(e) => {
                activateAndDeactivate(e, true);
              }}
              mt={4}
              loading={updateLoading}
              disabled={updateLoading}
            >
              Confirm
            </BasicButton>
            <BasicButton
              variant="tertiary"
              onClick={() => {
                setIsUpdateModalVisible(false);
              }}
              mt={2}
            >
              Cancel
            </BasicButton>
          </Col>
        </Row>
      </Modal>
      <Modal
        visible={isSuccessModalOpen}
        setIsModalVisible={setIsSuccessModalVisible}
      >
        <Row>
          <Col w={[4, 12, 12]}>
            <T.P size="med" weight="bold" color="white">
              Success
            </T.P>
            <T.P size="regular" color="white" mt={4} mb={4}>
              {status.current === organisationStatuses.DEACTIVATED
                ? `They have been deactivated, if you wish to give them permission again, please go to the deactivated tab.`
                : `They have been activated and can now log in again. If they have forgotten their log in details they can click “Forgot password” on the log in page.`}
            </T.P>

            <BasicButton
              variant="secondary"
              onClick={() => setIsSuccessModalVisible(false)}
              mt={4}
            >
              Return to organisations
            </BasicButton>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Organisations;
