import styled from '@emotion/styled';

const fontSizes = {
  default: '24px',
  large: '28px',
  wide: '28px',
};

const paddings = {
  default: '12px',
  large: '12px 16px',
  wide: '12px 16px',
};

export const commonStyle = ({
  theme,
  preFilled,
  bgColor,
  size,
  fontSize,
  color,
  isActive,
  w,
  h,
  m,
}) => ` 
  min-width: ${w || '58px'} !important;
  min-height: ${h || '58px'} !important;
  line-height: 0;
  cursor: pointer;
  border: ${preFilled ? 'none' : `2px solid ${theme.colors.primaryMain}`};
  border-radius: ${theme.borders.emptySpaceBR};
  background: ${theme.colors[bgColor] || bgColor};
  width: fit-content;
  padding: ${paddings[size]};
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: ${
    preFilled
      ? 'none'
      : `inset 0px -3px 0px -1px rgb(4 13 38 / 10%),
    inset 0px -3px 1px -1px rgb(4 13 38 / 11%)`
  };

  font-size: ${fontSize || fontSizes[size]};
  color: ${theme.colors[color] || color};
  margin: ${m ?? '5px 2px'};


  :focus {
    outline: 2px solid black;
    outline-offset: -6px;
  }

  :hover {
    color: ${theme.colors.primaryMain};
  }

  ${
    isActive
      ? `
  outline: 2px solid black;
  outline-offset: -6px;
  `
      : ''
  }
`;

export const Wrapper = styled.button`
  ${commonStyle}
`;

export const DivWrapper = styled.div`
  ${commonStyle}
`;
