import styled from '@emotion/styled';

export const CarouselWrapper = styled.div`
  .ant-carousel .slick-dots li.slick-active button {
    background: ${({ theme }) => theme.colors.neutralMain};
  }
  .ant-carousel .slick-dots button {
    background: ${({ theme }) => theme.colors.neutral40};
  }
`;

export const QuoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings[5]};
  align-items: center;

  color: #fff;
  text-align: center;
`;
