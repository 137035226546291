import React from 'react';

const StartPath = ({ width, height, color, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '210'}
      height={height || '187'}
      fill="none"
      viewBox="0 0 210 187"
    >
      <circle
        cx="197.743"
        cy="173.997"
        r="10"
        fill="#FDFCF9"
        stroke={color || '#050D30'}
        stroke-width="4"
      />
      <path
        stroke={color || '#050D30'}
        stroke-dasharray="13 13"
        stroke-linecap="round"
        stroke-width="4"
        d="M2 3.496s56.431-1.354 86.722 28c30.291 29.355 20.553 83.687 36.1 110.5 24.277 41.871 61.825 33.5 61.825 33.5"
      />
    </svg>
  );
};

export default StartPath;
