import { Typography as T, Button } from '../../../components';

import * as S from './style';
import { getMediaUrl } from '../../../helpers';
import { useGeneralState } from '../../../context/general-state';
import GoalCard from './GoalCard';
import theme from 'theme';

const UncompletedGoalsSection = ({
  goals,
  refetch,
  handleTaskCheck,
  setGoals,
  handleDeleteGoal,
  setOpenModal,
}) => {
  const { state: generalState } = useGeneralState();

  return (
    <>
      <S.PlayButtonWrapper>
        <Button.PlayButton
          width="56px"
          height="56px"
          backgroundColor={theme.colors.neutralSurface}
          audioUrl={getMediaUrl(
            null, // Todo: update "Not completed goals" audio
            true,
            generalState?.preferredVoice
          )}
          iconProps={{ width: 27.2 }}
          fallbackText="Not completed goals"
        />

        <T.H1 ml="3">Not completed goals</T.H1>
      </S.PlayButtonWrapper>
      <S.CardsWrapper>
        {goals.map((g) => (
          <GoalCard
            goalId={g.id}
            key={g.id}
            title={g.title}
            audio={g.audio}
            tasks={g.tasks}
            handleTaskCheck={handleTaskCheck}
            handleDeleteGoal={handleDeleteGoal}
            setGoals={setGoals}
            refetch={refetch}
            setOpenModal={setOpenModal}
          />
        ))}
      </S.CardsWrapper>
    </>
  );
};

export default UncompletedGoalsSection;
