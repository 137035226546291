import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  gap: 40px;
  padding: 0 40px;
  ${({ theme }) => theme.media.mobile} {
    padding: 0 12px;
    gap: 24px;
  }
`;
export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 65px;
  gap: 32px;
  ${({ theme }) => theme.media.mobile} {
    padding: 0;
    gap: 18px;
  }
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const BodyTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const UnderlineBold = styled.span`
  text-decoration: underline;
  font-weight: 700;
`;

export const IssueSection = styled.section`
  background-color: #f5f7ff;
  border-radius: 4px;
  display: flex;
  padding: 24px;
  gap: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
