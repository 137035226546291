import { useState } from 'react';

import * as T from 'components/Typography';
import * as S from './style';

import { Icon } from 'components';

import QuestionModal from './QuestionModal';

const Question = ({ questionLabel, answersCount, children, role }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <S.QuestionWrapper>
      <T.P color="neutralMain" weight="bold" mb={3}>
        {questionLabel}{' '}
        <T.P color="neutralMain" display="inline">
          ({answersCount} Answer{answersCount !== 1 && 's'})
        </T.P>
      </T.P>
      <S.ExpandAndCloseWrapper>
        <Icon
          icon="expand"
          color="primaryMain"
          width="25"
          height="25"
          pointer
          onClick={() => setIsModalOpen(true)}
        />
      </S.ExpandAndCloseWrapper>
      {children}
      <QuestionModal
        questionLabel={questionLabel}
        answersCount={answersCount}
        content={children}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        role={role}
      />
    </S.QuestionWrapper>
  );
};

export default Question;
