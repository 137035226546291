import { string, array, object, number, boolean } from 'yup';

export const validationSchema = {
  textAudio: string().notRequired(),
  textAudioKey: string().notRequired(),
  correctOptions: array()
    .of(
      object()
        .shape({
          id: number().required(),
          value: string().required('required field'),
          showValue: boolean().required('required field'),
        })
        .required()
    )
    .required('You must provide correct options')
    .min(1, 'You must provide at least one correct option')
    .max(12, "You can't use add more than 12 correct options"),
};
