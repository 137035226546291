import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Redirect({ to, state }) {
  let navigate = useNavigate();
  useEffect(() => {
    navigate(to, { state });
  });
  return null;
}

export default Redirect;
