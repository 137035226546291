import styled from '@emotion/styled';
import MuiModal from '@mui/material/Modal';
import * as T from 'components/Typography';

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 100%;
  max-width: 100%;
  .ant-table table {
    font-size: 1rem;
    font-weight: 400;
  }

  .ant-tabs-ink-bar {
    background: ${({ theme }) => theme.colors.primaryMain};
    height: 3px !important;
  }
  .ant-tabs-tab {
    margin: 0 !important;
    min-width: 133px;
    justify-content: center;
    border-bottom: 3px solid #e8e8e8;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    left: ${({ activeTab }) => {
      if (activeTab === '1') return '0px';
      if (activeTab === '2') return '133px';
      if (activeTab === '3') return '266px';
    }};
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-table {
    border: 1px solid ${({ theme }) => theme.colors.neutral50};
    overflow: auto;
  }
`;

export const RateButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: center;

  ${({ theme, modalView }) =>
    modalView ? theme.media.all : theme.media.mobile} {
    flex-wrap: wrap;
    gap: 0px;
  }
`;

export const RateButtonPair = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  ${({ theme, modalView }) =>
    modalView ? theme.media.all : theme.media.mobile} {
    ${({ index }) => index === 0 && 'border-bottom: 1px solid #E8E8E8;'}
  }
`;

export const SmallVerticalDivider = styled.div`
  width: 1px;
  height: 72px;
  background: ${({ theme }) => theme.colors.neutral50};

  ${({ theme, modalView }) =>
    modalView ? theme.media.all : theme.media.mobile} {
    min-height: 132px;
    ${({ hideOnMobile }) => hideOnMobile && 'display: none;'}
  }
`;

export const Answer = styled(T.P)`
  padding: ${({ theme }) => theme.spacings[3]};
  background: white;
`;

export const NoData = styled(T.P)`
  padding: ${({ theme }) => theme.spacings[3]};
  text-align: center;
`;

export const QuestionWrapper = styled.div`
  padding: ${({ theme }) => theme.spacings[3]};
  margin-bottom: ${({ theme }) => theme.spacings[3]};
  background-color: ${({ theme }) => theme.colors.neutralSurface};
  border-radius: 8px;
  position: relative;
`;

export const QuestionContentWrapper = styled.div`
  max-height: ${({ modalView }) => (modalView ? '500px' : '256px')};
  overflow: auto;
`;

export const ExpandAndCloseWrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacings[3]};
  right: ${({ theme }) => theme.spacings[3]};
`;

export const Modal = styled(MuiModal)`
  overflow: auto;
  z-index: 9999;
  background: #ffffff50;
`;

export const ModalContentWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  max-width: 908px;
  width: 100%;
  padding: ${({ theme }) => theme.spacings[6]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  margin: 0 auto;
  position: relative;
  ${({ theme }) => theme.media.tablet} {
    width: 95%;
    padding: ${({ theme }) => theme.spacings[4]};
  }

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
  top: 50%;
  transform: translateY(-50%);
`;

export const GrayBackgroundText = styled(T.H2)`
  background-color: ${({ theme }) => theme.colors.neutral30};
  padding: ${({ theme }) => theme.spacings[3]}
    ${({ theme }) => theme.spacings[2]};
`;

export const FeedbackHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: -28px;

  ${({ theme }) => theme.media.mobile} {
    position: relative;
    top: 0;
  }
`;
