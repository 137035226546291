import { Col, Row } from 'components/Grid';
import * as T from 'components/Typography';
import * as S from './styles';
import Icon from 'components/Icon';
import { useAuth } from 'context/auth';
import { BasicButton } from 'components/Button';
import { navRoutes } from '../../constants';
import { useNavigate } from 'react-router-dom';
import InlineCopy from 'components/Copy/InlineCopy';

const List = ({ externalLink, title, order, id }) => {
  const navigate = useNavigate();
  const openLink = () => {
    window.open(externalLink, '_blank');
  };

  const editResource = () => {
    navigate(
      navRoutes.FACILITATOR.TRAINING_EDIT_RESOURCES.replace(':resourceId', id)
    );
  };

  return (
    <S.LinkWrapper>
      <S.LinkTitleWrapper mb="1">
        <T.P weight="bold" underline>
          {title}
        </T.P>
        <S.LinkActions>
          <S.Action type="button" onClick={editResource}>
            <Icon color="neutralMain" icon="edit" width={25} height={25} />
          </S.Action>
          <InlineCopy textToCopy={externalLink} copyIcon={'copy4'} />
          <S.Action type="button" onClick={openLink}>
            <Icon color="neutralMain" icon="openLink" width={14} height={14} />
          </S.Action>
        </S.LinkActions>
      </S.LinkTitleWrapper>
      <T.Link weight="400" to={externalLink}>
        {externalLink}
      </T.Link>
    </S.LinkWrapper>
  );
};

export const FurtherResources = ({ customTrainingResources }) => {
  const { user } = useAuth();
  const canAddResources = user?.role === 'FACILITATOR';

  return (
    <Row>
      <Col display="block" w={[4, 12, 12]} mb="4">
        <T.H2 weight="bold" color="neutralMain" mb="2">
          Further resources
        </T.H2>
        <T.P>
          If you’re interested in further advice and tips check out our useful
          resources below
        </T.P>
      </Col>
      <Col w={[4, 12, 12]}>
        {customTrainingResources?.sort((a, b) => a.order - b.order).map(List)}
      </Col>
      {canAddResources ? (
        <Col w={[4, 12, 12]} mt="4">
          <BasicButton
            variant="primary"
            to={navRoutes.FACILITATOR.TRAINING_ADD_RESOURCES}
            aria-label="Add a resource"
            type="submit"
            id="add-a-resource"
            maxWidth="332px"
          >
            Add a resource
          </BasicButton>
        </Col>
      ) : null}
    </Row>
  );
};
