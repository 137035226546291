import { Col, Row } from '../../../../components/Grid';
import { Typography as T } from '../../../../components';
import {
  BasicInput,
  Dropdown,
  Textarea,
  BasicInputWrapper,
} from '../../../../components/Inputs';
import { BasicButton } from '../../../../components/Button';
import RichTextExample from '../../../../components/RichText';
import { LinkButton } from './style';

const StepForm = ({
  setFormData,
  form,
  validationErrs,
  httpError,
  loading,
  handleSubmit,
  handleDelete = () => {},
  formType = 'add',
  orderDropdownOptions = [],
}) => {
  const selectedOrder =
    orderDropdownOptions.find((option) => option.value === form.order) ||
    undefined;

  return (
    <div style={{ maxWidth: '636px' }}>
      <Row>
        <Col w={[4, 11, 12]} mt="5">
          <T.H1 weight="bold" color="neutralMain">
            {formType === 'add' ? 'Add custom step' : 'Edit custom step'}
          </T.H1>
        </Col>
        <Col w={[4, 11, 12]} mt="5">
          <BasicInput
            label="Title"
            value={form.title}
            name="title"
            handleChange={(title) => setFormData({ title })}
            error={validationErrs.title}
            placeholder="Organisation name..."
          />
        </Col>
        <Col w={[4, 11, 12]} mt="5">
          <Textarea
            placeholder="Introduction..."
            label="Introduction"
            value={form.introduction}
            name="introduction"
            handleChange={(introduction) => setFormData({ introduction })}
            error={validationErrs.introduction}
          />
        </Col>
        <Col w={[4, 11, 12]} mt="5">
          <BasicInput
            label="Video link"
            optional
            placeholder="Video link..."
            value={form.videoLink}
            name="videoLink"
            handleChange={(videoLink) => {
              if (!videoLink.includes('youtube.com/embed/')) {
                videoLink = videoLink.replace(
                  'youtube.com/',
                  'youtube.com/embed/'
                );
              }
              setFormData({ videoLink });
            }}
            error={validationErrs.videoLink}
          />
        </Col>{' '}
        <Col w={[4, 11, 12]} mt="5">
          <BasicInputWrapper
            label="Description"
            optional
            value={form.description}
            name="description"
            error={validationErrs.description}
            InputComponent={
              <RichTextExample
                htmlContent={form.description}
                handleChange={(description) => setFormData({ description })}
              />
            }
          />
        </Col>
        <Col w={[4, 11, 12]} mt="5">
          <Dropdown
            weight="regular"
            label="Order"
            placeholder="Select ..."
            options={orderDropdownOptions}
            selected={selectedOrder}
            handleChange={(order) => setFormData({ order })}
            error={validationErrs.order}
          />

          <T.P mt={4}>
            This lets you select which order your would like your custom
            resources to be completed
          </T.P>
        </Col>
        <Col w={[4, 11, 10]} mt="2">
          {httpError && (
            <T.P mb="2" color="error">
              {httpError}
            </T.P>
          )}

          {validationErrs.hasError && (
            <Row>
              <Col w={[4, 10, 12]}>
                <T.P color="error" mb={3}>
                  There is an error in your form. Please correct before
                  submitting.
                </T.P>
              </Col>
            </Row>
          )}
        </Col>
        <Col w={[4, 11, 6]}>
          <BasicButton
            mt={6}
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            handleClick={handleSubmit}
          >
            <T.P color="white" weight="semi">
              {formType === 'add' ? 'Create step' : 'Save changes'}
            </T.P>
          </BasicButton>

          {formType === 'edit' && (
            <LinkButton
              disabled={loading}
              loading={loading}
              onClick={() => handleDelete()}
              mt={6}
            >
              Delete this step
            </LinkButton>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default StepForm;
