import React, { useCallback, useState } from 'react';
import * as T from 'components/Typography';
import * as S from './style';
import { Col, Row } from 'components/Grid';
import { Modal } from 'components';
import { BasicButton } from 'components/Button';
import { useDeleteUser } from 'api-calls/users.queries';
import { useParams } from 'react-router-dom';
import { userRoles } from '../../../../../constants';
import { GENERAL } from 'constants/nav-routes';

const DeleteAccount = () => {
  const { coachId } = useParams();
  const [deleteUserModalVisible, setDeleteUserModalVisible] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const {
    mutateAsync: deleteUser,
    isLoading: deleteUserLoading,
    error: deleteUserError,
  } = useDeleteUser({
    id: coachId,
    role: userRoles.COACH,
  });

  const showDeleteUserModal = useCallback(() => {
    setDeleteUserModalVisible(true);
  }, []);

  const hideDeleteUserModal = useCallback(() => {
    setDeleteUserModalVisible(false);
  }, []);

  const handleDeleteUser = useCallback(() => {
    deleteUser(
      {
        id: coachId,
        role: userRoles.LEARNER,
      },
      {
        onSuccess: () => {
          setSuccessModalVisible(true);
        },
      }
    );
  }, [deleteUser, coachId]);

  return (
    <>
      <T.H2 size="med" mb="3">
        Delete account
      </T.H2>
      <T.P mb="3">
        This will delete all personal data related to this user. Please note
        this cannot be undone.
      </T.P>
      <S.LinkButton onClick={showDeleteUserModal} mb="6">
        Delete account anyway
      </S.LinkButton>

      {/* Confirm Modal */}
      <Modal onCancel={hideDeleteUserModal} visible={deleteUserModalVisible}>
        <Row>
          <Col w={[4, 12, 12]} mb={4}>
            {!deleteUserError ? (
              <T.H3 color="neutralSurface">
                Are you sure you want to delete all personal data related to
                this user?
              </T.H3>
            ) : (
              <T.H3 color="error">
                There was an error deleting this account. Please try again
                later.
              </T.H3>
            )}
          </Col>
        </Row>
        <BasicButton
          variant="secondary"
          loading={deleteUserLoading}
          disabled={deleteUserLoading || deleteUserError}
          handleClick={handleDeleteUser}
        >
          <T.P color="white" weight="bold">
            Yes, I'm sure
          </T.P>
        </BasicButton>
        <BasicButton
          mt={3}
          to={'#'}
          variant="tertiary"
          loading={deleteUserLoading}
          handleClick={hideDeleteUserModal}
        >
          No, go back
        </BasicButton>
      </Modal>

      {/* SUCCESS MODAL */}
      <Modal visible={successModalVisible}>
        <S.ModalContent>
          <T.H1 mb={3} color="neutralSurface">
            Done! 🎉
          </T.H1>

          <BasicButton
            to={GENERAL.HOME}
            linkState={{ refetch: userRoles.LEARNER }}
            variant="secondary"
          >
            <T.P color="white" weight="bold">
              Return to dashboard
            </T.P>
          </BasicButton>
        </S.ModalContent>
      </Modal>
    </>
  );
};

export default DeleteAccount;
