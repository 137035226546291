import * as T from '../../../components/Typography';
import { Row, Col } from '../../../components/Grid';
import InfoCard from '../../../components/InfoCard';
import { COMMON } from '../../../constants/nav-routes';
import Loading from '../../../components/Loading';
import { useGetLearnersByCoachId } from '../../../api-calls/learners.queries';
import LearnersEmptyState from './LearnersEmptyState';

const CoachLearnersSection = ({ coachId }) => {
  const {
    learners = [],
    isLoading: getLearnersLoading,
    error: getLearnersError,
  } = useGetLearnersByCoachId({ coachId });

  if (getLearnersLoading) {
    return <Loading />;
  }
  if (getLearnersError) {
    return (
      <T.P mb={2} mt={2} color="error">
        {getLearnersError.message}
      </T.P>
    );
  }

  return (
    <Row gap="16px 0" gapM="16px" inner>
      {learners.length !== 0 ? (
        learners?.map((learner) => {
          return (
            <Col w={[4, 6, 6]} key={learner.id}>
              <InfoCard
                firstColumnLabel="Learner ID"
                firstColumnValue={learner.learnerUniqueId}
                secondColumnLabel="Name"
                secondColumnValue={learner.firstName}
                to={COMMON.LEARNER.replace(':userId', learner.id)}
              />
            </Col>
          );
        })
      ) : (
        <Col w={[4, 12, 12]} mt="2" display="flex" jc="center">
          <LearnersEmptyState />
        </Col>
      )}
    </Row>
  );
};
export default CoachLearnersSection;
