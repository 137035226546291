import { useState, useEffect } from 'react';
import { ClearButton } from '../../../../../components/Button';
import * as S from './style';

export const rowButtonStyleVariants = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
};

const Rows = ({
  setEnableNext,
  rows,
  styleVariant = rowButtonStyleVariants.PRIMARY,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    setEnableNext(
      rows.every((row) => {
        const isSelected = selectedRows.includes(row.id);
        return row.isCorrect ? isSelected : !isSelected;
      })
    );
  }, [rows, selectedRows, setEnableNext]);

  const handleRowFill = (rowId) => {
    setSelectedRows((prev) => {
      if (prev.includes(rowId)) {
        return prev.filter((_rowId) => _rowId !== rowId);
      }
      return [...prev, rowId];
    });
  };

  const handleMouseDown = (number) => {
    setIsDragging(true);
    handleRowFill(number);
  };

  const handleMouseEnter = (number) => {
    if (isDragging) {
      handleRowFill(number);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <>
      {rows.map((row, rowIndex) => (
        <S.RowButton
          key={rowIndex}
          showBorderTop={rowIndex === 0}
          selected={selectedRows.includes(row.id)}
          onMouseDown={() => handleMouseDown(row.id)}
          onMouseEnter={() => handleMouseEnter(row.id)}
          onMouseUp={handleMouseUp}
          styleVariant={styleVariant}
        >
          {row.text}
        </S.RowButton>
      ))}
      <ClearButton
        mt="4"
        hideIcon
        hideBorder
        handleClick={() => setSelectedRows([])}
      />
    </>
  );
};

export default Rows;
