import * as S from './style';
import { Typography as T } from '../../../components';

const GoalProgress = ({ progress = 0, completed }) => {
  return (
    <S.GoalProgressWrapper>
      <T.P size="med" mr="1">
        {Math.round(progress * 10) / 10}%
      </T.P>

      <S.GoalProgressTube>
        <S.GoalProgressDone completed={completed} percentage={progress} />
      </S.GoalProgressTube>
    </S.GoalProgressWrapper>
  );
};

export default GoalProgress;
