import { useEffect, useRef, useState } from 'react';

import * as T from '../../../../components/Typography';
import MDEditor from '../../../../components/MDEditor';
import * as S from './style';
import { Icon } from '../../../../components';
import { BasicInput } from '../../../../components/Inputs';
import { MediaKeyInput } from '../../../components/MediaKeyInput';

import { string, number, boolean, array, object } from 'yup';
import { mediaTypes } from '../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';

const initialData = {
  options: [{ id: 0, option: '' }],
  textAudioKey: '',
  textAudio: '',
};

const CombineWordsPre = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateDataFields } = useStepForm(initialRef.current);
  const { data, validationErrs } = state;
  const [options, setOptions] = useState(
    data?.options?.map((e, i) => ({ ...e, id: i })) || [{ id: 0, option: '' }]
  );

  useEffect(() => {
    updateDataFields({
      options: options?.filter((e) => e.option),
    });
  }, [options, updateDataFields]);

  const addOption = () => {
    const maxId = Math.max(...options.map((e) => e.id), 0);
    setOptions([...options, { id: maxId + 1, option: '' }]);
  };
  const removeOption = (id) => {
    setOptions(options.filter((e) => e.id !== id));
  };

  const onOptionChange = (value, id) => {
    setOptions(
      options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      })
    );
  };

  return (
    <div>
      <BasicInput
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        label="Top Question text"
        error={validationErrs.textAudio}
        m={{ mt: 8 }}
      />

      <MediaKeyInput
        label="Top Question text Audio"
        type={mediaTypes.TEXT_AUDIO}
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs.textAudioKey}
      />

      <T.P mb={2} mt={5}>
        Examples
      </T.P>
      {validationErrs?.options &&
        typeof validationErrs.options === 'string' && (
          <T.P mb={2} mt={2} color="error">
            {validationErrs.options}
          </T.P>
        )}

      {options.map((e, i) => {
        return (
          <>
            <MDEditor
              label={`Example ${i + 1}`}
              value={e.option}
              onChange={(value) => onOptionChange(value, e.id)}
              m={{ mt: 5 }}
              error={
                validationErrs?.options && validationErrs?.options[i]?.option
              }
            />

            {options.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeOption(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}
      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addOption}
        disabled={options.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Example
      </S.Button>
    </div>
  );
};

const validationSchema = {
  options: array()
    .of(
      object()
        .shape({
          id: number().required(),
          option: string().required('required field'),
          isCorrect: boolean(),
        })
        .required()
    )
    .required('you must provide examples')
    .min(1, 'you must provide examples'),
  textAudio: string().notRequired(),
  textAudioKey: string().notRequired(),
};
export { validationSchema };

export default CombineWordsPre;
