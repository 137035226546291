import styled from '@emotion/styled';
import { BasicButton } from 'components/Button';
import * as T from 'components/Typography';
import { setMargin } from 'helpers';

export const TH1 = styled(T.H1)`
  text-align: center !important;
`;

export const SBasicButton = styled(BasicButton)`
  width: fit-content;
  padding: 1.5rem 2rem;
`;

export const StyledLink = styled(T.Link)`
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
`;

export const CancelBtnWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const HeadingWrapper = styled('div')`
  text-align: center;
  width: 100%;
`;

export const ContinueBtnWrapper = styled('div')`
  ${setMargin}
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
`;
