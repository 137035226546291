import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PlayButton } from '../../../../components/Button';
import { Markdown } from '../../../../components';
import StepFooterButtons from '../StepFooterButtons';
import * as S from './style';
import * as SharedStyles from '../shared/style';
import Rows, { rowButtonStyleVariants } from '../shared/Rows';
import { createH1, createH3 } from 'utils/markdown';
import { Row } from 'components/Grid';

const ComprehensionSelect = ({
  data: { textAudio, textAudioUrl, questionTitle, rowsTitle, rows = [] } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  isLoading,
}) => {
  const [enableNext, setEnableNext] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setEnableNext(false);
  }, [location]);

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        {!!textAudio && (
          <SharedStyles.TextAudioWrapper mt="2" mb={!questionTitle ? 6 : 4}>
            {textAudioUrl ? (
              <>
                <PlayButton
                  audioUrl={textAudioUrl}
                  width="56px"
                  height="56px"
                  iconProps={{ width: '32px', height: '32px' }}
                  fallbackText={textAudio}
                />
                <div>
                  <Markdown
                    text={textAudio}
                    customStyles={{
                      p: { size: 'regular', ml: '3' },
                    }}
                  />
                </div>
              </>
            ) : (
              <div>
                <Markdown
                  text={textAudio}
                  customStyles={{
                    p: { size: 'regular' },
                  }}
                />
              </div>
            )}
          </SharedStyles.TextAudioWrapper>
        )}

        {questionTitle ? (
          <>
            <SharedStyles.Divider w="100%" />
            <Row my={2}>
              <Markdown
                text={createH1(questionTitle)}
                customStyles={{
                  h1: {
                    fontSize: '18px',
                  },
                }}
              />
            </Row>
          </>
        ) : null}

        {rowsTitle ? (
          <Row my={3}>
            <Markdown
              text={createH3(rowsTitle)}
              customStyles={{
                h3: {
                  fontSize: 'regular',
                },
              }}
            />
          </Row>
        ) : null}
        <S.RowsWrapper>
          <Rows
            setEnableNext={setEnableNext}
            rows={rows}
            styleVariant={rowButtonStyleVariants.SECONDARY}
          />
        </S.RowsWrapper>
      </S.ContentWrapper>

      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleNext}
        preview={preview}
        disabled={disabled}
        enableNext={enableNext}
        isLoading={isLoading}
      />
    </S.Wrapper>
  );
};

export default ComprehensionSelect;
