import CircularProgress from '@mui/material/CircularProgress';
import * as S from './style';

const IconButtonWithLoader = ({ children, loading, ...props }) => {
  return (
    <S.StyledButton disabled={loading || props.disabled} {...props}>
      {loading ? <CircularProgress size={20} color="inherit" /> : children}
    </S.StyledButton>
  );
};

export default IconButtonWithLoader;
