import styled from '@emotion/styled';

export const AlignmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  min-width: 100%;
`;

export const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: ${({ isAside }) => (isAside ? 'center' : 'flex-start')};
  background-color: ${({ theme, bg }) =>
    bg ? theme.colors[bg] : theme.colors.primaryLight};
  border-radius: 16px;
  padding: 16px;
  gap: 12px;
  opacity: ${({ complete, active, disableList }) =>
    complete || active || disableList ? 1 : 0.5};
  cursor: ${({ complete, active, clickable }) =>
    clickable && (complete || active) ? 'pointer' : 'default'};
  border: ${({ theme, active, border }) =>
    active ? `3px solid ${theme.colors[border]}` : ''};
`;

export const NumberWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${({ complete, icon }) => (complete || icon ? '0px' : '5px')};
  align-items: center;
  border-radius: 9.2px;
  min-width: 42px;
  height: 42px;
  background-color: ${({ theme, bg }) =>
    bg ? theme.colors[bg] : theme.colors.primaryLight};
`;

export const TextWrapper = styled.div``;

export const Line = styled.div`
  width: 6px;
  height: 20px;
  background-color: black;
  opacity: ${({ active }) => (active ? 1 : 0.2)};
`;

export const CompletedMark = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.quinaryMain};
  top: -5px;
  right: -5px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ theme }) => theme.colors.neutralSurface};
`;

export const CompletedBoxTraining = styled.div`
  border-radius: 16px;
  border: 1px solid;
  background-color: ${({ theme }) => theme.colors.quinaryLight};
  border-color: ${({ theme }) => theme.colors.quinaryMain};
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ active }) => (active ? 1 : 0.2)};
`;
