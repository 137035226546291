import styled from '@emotion/styled';

export const Wrapper = styled.div`
  margin: 0 auto;
  padding: 0 32px;
  ${({ theme }) => theme.media.tablet} {
    padding: 0 0;
  }
`;

export const QuestionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const DnDItemWrapper = styled.div`
  display: flex;
  align-items: start;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.media.tablet} {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

export const HeadingButtons = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacings[5]};
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.neutral30};
  margin-top: ${({ theme }) => theme.spacings[8]};
  margin-bottom: ${({ theme, noMarginBottom }) =>
    !noMarginBottom && theme.spacings[8]};
`;

export const StaticQuestionsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
`;

export const AddQuestionButton = styled.button`
  display: flex;
  align-items: center;
  padding: ${({ theme }) =>
    `${theme.spacings[2]} 0 ${theme.spacings[2]} ${theme.spacings[2]}`};
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.primaryMain};
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
`;
