import styled from '@emotion/styled';
import * as T from '../../../components/Typography';
import { setMargin } from 'helpers';

export const DashboardWrapper = styled.div`
  width: 100%;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 48px;
  ${({ theme }) => theme.media.tablet} {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const LeftColumn = styled.div`
  flex: 1 1 66%;
  max-width: calc(67% - 48px);
  ${({ theme }) => theme.media.tablet} {
    max-width: 100%;
  }
`;
export const RightColumn = styled.div`
  flex-basis: 33%;
  min-width: 375px;
  max-width: 33%;
  ${({ theme }) => theme.media.tablet} {
    min-width: 100%;
  }
`;

export const StatisticsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 4px;
  border-radius: 16px;
  overflow: hidden;
`;

export const StyledFindOut = styled(T.P)`
  flex: 1;
  align-items: flex-end;
  display: flex;
  justify-content: center;
`;

export const VideoCard = styled.div`
  margin-top: 8px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
`;

export const ImageCard = styled.div`
  margin-top: 8px;
  width: 100%;
  overflow: hidden;
  background-image: ${({ bg }) => `url(${bg})`};
  min-height: 156px;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
`;

export const ResponsiveImg = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  width: 100%;
  border-radius: 10px;
`;

export const HeaderWrapper = styled.div`
  ${setMargin}
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const TableTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: start;
    gap: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const HR = styled.div`
  ${setMargin}
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral40};
`;

export const CardsWrapper = styled.div`
  width: 100%;
`;

export const TableWrapper = styled.div`
  ${setMargin}
  width: 100%;
  overflow: auto;
`;

export const TabsWrapper = styled.div`
  margin-left: 110px;
  width: calc(100% - 220px);
  ${({ theme }) => theme.media.tablet} {
    margin-left: 50px;
    width: calc(100% - 100px);
  }
  ${({ theme }) => theme.media.mobile} {
    margin-left: 0;
    width: 100%;
  }
`;

export const BackWrapper = styled.div`
  /* padding-bottom: 32px; */
`;
