import { LEARNER_COACH_SESSIONS } from '../constants';
import {
  usePost,
  formatQueryError,
  useFetch,
  usePatch,
} from '../utils/queries';

function useCreateLearnerCoachSessions(_, options = {}) {
  const context = usePost({
    url: `${LEARNER_COACH_SESSIONS}`,
    reactQueryConfig: options,
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
}

function useGetLearnerCoachSessionBySessionId({ sessionId }, options = {}) {
  const context = useFetch({
    url: `${LEARNER_COACH_SESSIONS}/${sessionId}`,
    key: [LEARNER_COACH_SESSIONS, sessionId],
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
}

function useGetLearnerCoachSessionByCoachId(options = {}) {
  const context = useFetch({
    url: `${LEARNER_COACH_SESSIONS}`,
    key: [LEARNER_COACH_SESSIONS, options],
    axiosParams: options,
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
}

function useUpdateLearnerCoachSession({ sessionId }, options = {}) {
  const context = usePatch({
    url: `${LEARNER_COACH_SESSIONS}/${sessionId}`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
}

function useCancelLearnerCoachSession({ sessionId }, options = {}) {
  const context = usePatch({
    url: `${LEARNER_COACH_SESSIONS}/${sessionId}/cancel`,
    reactQueryConfig: {
      invalidateKeys: () => [LEARNER_COACH_SESSIONS],
      ...options,
    },
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
}

function useGetLearnerSessions({ id, status }, options = {}) {
  const context = useFetch({
    url: `${LEARNER_COACH_SESSIONS}/learner/${id}/sessions`,
    key: [LEARNER_COACH_SESSIONS, id, status],
    axiosParams: { status },
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
}

export {
  useCreateLearnerCoachSessions,
  useGetLearnerCoachSessionBySessionId,
  useUpdateLearnerCoachSession,
  useGetLearnerCoachSessionByCoachId,
  useGetLearnerSessions,
  useCancelLearnerCoachSession,
};
