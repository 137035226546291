import { useMemo, useRef } from 'react';

import { BasicInput, Dropdown } from '../../../../../components/Inputs';
import { MediaKeyInput } from '../../../../components/MediaKeyInput';
import {
  mediaTypes,
  questionAndAnswerVariations,
} from '../../../../../constants';
import { convertSnakeCaseToSpaces } from 'helpers';
import MDEditor from '../../../../../components/MDEditor';
import { generateInitialCorrectOptions } from './utils';
import CorrectAnswers from './CorrectAnswers';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
export { validationSchema } from './validation';

const initialData = {
  correctOptions: [{ id: 0, option: '' }],
  variation: '',
  textAudio: '',
  textAudioKey: '',
  question: '',
  questionAudioKey: '',
};

const QuestionAndAnswer = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateFormFields, updateDataFields } = useStepForm(
    initialRef.current
  );
  const { form, data, validationErrs } = state;

  const correctOptions = useMemo(
    () => data.correctOptions || [{ id: 0, option: '' }],
    [data.correctOptions]
  );
  const variation = useMemo(() => data.variation, [data.variation]);

  const onOptionChange = ({ key, value, id }) => {
    updateDataFields({
      correctOptions: correctOptions.map((e) => {
        if (e.id !== id) return e;
        return {
          ...e,
          [key]: value,
        };
      }),
    });
  };

  const onVariationChange = (variation) => {
    updateDataFields({
      variation,
      correctOptions: generateInitialCorrectOptions(variation),
    });
  };

  const variationOptions = Object.keys(
    questionAndAnswerVariations.variations
  ).map((e) => ({
    label: convertSnakeCaseToSpaces(e),
    value: e,
  }));

  // use same logic in ExerciseStep/QuestionAndAnswer/index.js
  const showImage =
    variation !== questionAndAnswerVariations.variations.NO_IMAGE;
  const showQuestion =
    questionAndAnswerVariations.showQuestionVariations.includes(variation);

  return (
    <div>
      <Dropdown
        label="Choose a variation"
        options={variationOptions}
        selected={
          variation
            ? {
                value: variation,
                label: convertSnakeCaseToSpaces(variation),
              }
            : null
        }
        handleChange={onVariationChange}
        error={validationErrs?.variation}
      />

      {!!variation && (
        <>
          <BasicInput
            label="Explainer Text"
            value={data.textAudio}
            handleChange={(textAudio) => updateDataFields({ textAudio })}
            m={{ mt: 8 }}
            error={validationErrs?.textAudio}
          />
          <MediaKeyInput
            type={mediaTypes.TEXT_AUDIO}
            label={`Explainer Text file Key`}
            value={data.textAudioKey}
            handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
            m={{ mt: 5 }}
            error={validationErrs?.textAudioKey}
          />

          {showImage ? (
            <MediaKeyInput
              type={mediaTypes.IMAGE}
              label="Image Key"
              value={form.imageKey}
              handleChange={(imageKey) => updateFormFields({ imageKey })}
              m={{ mb: 5, mt: 5 }}
              error={validationErrs?.imageKey}
            />
          ) : (
            <MDEditor
              value={data.paragraph}
              onChange={(paragraph) => updateDataFields({ paragraph })}
              mode={'edit'}
              label="Paragraph"
              m={{ mt: 8 }}
              helper="Provide the paragraph"
              error={validationErrs.paragraph}
            />
          )}

          {showQuestion && (
            <>
              <BasicInput
                label="Question"
                value={data.question}
                handleChange={(question) => updateDataFields({ question })}
                m={{ mt: 8 }}
                error={validationErrs?.question}
              />
              <MediaKeyInput
                type={mediaTypes.QUESTION_AUDIO}
                label={`Question file Key`}
                value={data.questionAudioKey}
                handleChange={(questionAudioKey) =>
                  updateDataFields({ questionAudioKey })
                }
                m={{ mt: 5 }}
                error={validationErrs?.questionAudioKey}
              />
            </>
          )}
          <CorrectAnswers
            variation={variation}
            validationErrs={validationErrs}
            onOptionChange={onOptionChange}
            correctOptions={correctOptions}
          />
        </>
      )}
    </div>
  );
};

export default QuestionAndAnswer;
