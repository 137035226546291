import styled from '@emotion/styled';

export const ProgressTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacings[5]};
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

export const CardWrapper = styled.div`
  padding: 20px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.neutralSurface};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  gap: 8px;
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacings[4]};
  }
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`;

export const OuterWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`;

export const MilestoneWrapper = styled.div`
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.neutralLight};
  max-width: 350px;
  width: 100%;
  padding: ${({ theme }) => theme.spacings[3]};
`;
