import { useNavigate, useParams } from 'react-router-dom';
import { navRoutes } from '../../constants';
import { Icon, Loading } from 'components';
import * as T from '../Typography';
import * as S from './style';

const generateColors = (data) => {
  const backgrounds = ['primaryLight', 'quaternaryLight', 'neutral30'];
  const icons = ['primaryMain', 'quaternaryMain', 'neutralMain'];
  const colorLength = backgrounds.length;

  return data.map((_, index) => ({
    background: backgrounds[index % colorLength],
    icon: icons[index % colorLength],
  }));
};

const ProgressCard = ({
  data = [],
  completionMessage,
  disableList = false,
  activeStep = 0,
  loading,
  isEditModeActive = false,
  isAside = false,
}) => {
  const navigate = useNavigate();
  const { stepId } = useParams();
  const colors = generateColors(data);

  if (isEditModeActive) {
    activeStep = data.length;
  }

  const handleClick = ({ user, routeKey, param }, stepIndex) => {
    const arrayIndex = stepIndex + 1;

    const route = navRoutes[user][routeKey];
    let navRoute = route;

    if (param && arrayIndex) navRoute = navRoute.replace(param, arrayIndex);
    navigate(navRoute);
  };

  if (loading) return <Loading />;

  return (
    <>
      {data.map((step, index) => {
        const { background, icon } = colors[index];
        const active = isAside ? +stepId - 1 === index : index === activeStep;
        const isClickable =
          !isEditModeActive &&
          step?.clickable &&
          (active || index <= activeStep);

        return (
          <S.AlignmentWrapper key={step.title}>
            <S.Wrapper
              disableList={disableList}
              clickable={!isEditModeActive && step?.clickable}
              border={icon}
              bg={background}
              complete={index <= activeStep}
              active={!disableList && active}
              onClick={isClickable ? () => handleClick(step, index) : undefined}
              isAside={isAside}
            >
              <S.NumberWrapper
                bg={icon}
                complete={index < activeStep}
                icon={step?.icon}
              >
                <T.H3 size="medLarge" weight="regular" color="white">
                  {step?.icon ? (
                    <Icon
                      icon={step?.icon}
                      color="white"
                      width="24px"
                      height="24px"
                    />
                  ) : (
                    index + 1
                  )}
                </T.H3>
              </S.NumberWrapper>

              <S.TextWrapper>
                <T.P weight="bold">{step?.title}</T.P>
                {isAside ? null : <T.P>{step?.desc}</T.P>}
              </S.TextWrapper>

              {isClickable && !active && !isAside && (
                <S.CompletedMark>
                  <Icon icon="tick" color="white" width="12px" height="12px" />
                </S.CompletedMark>
              )}
            </S.Wrapper>

            {index <
              data.length -
                (!isEditModeActive && completionMessage ? 0 : 1) && (
              <S.Line active={isClickable || isEditModeActive} />
            )}
            {!isEditModeActive &&
              completionMessage &&
              index === data.length - 1 && (
                <S.CompletedBoxTraining active={activeStep === data.length}>
                  <T.P color="neutralMain" weight="bold">
                    {completionMessage}
                  </T.P>
                </S.CompletedBoxTraining>
              )}
          </S.AlignmentWrapper>
        );
      })}
    </>
  );
};

export default ProgressCard;
