import { useParams } from 'react-router-dom';
import { useGetLearnerStats } from '../../../api-calls/learners.queries';
import Loading from '../../../components/Loading';
import Milestones from '../LearnerProfile/Profile/ProgressReports/Milestones';
import * as S from './style';
import { BackButton } from 'components/BackButton';
import * as T from '../../../components/Typography';
import { BasicButton } from '../../../components/Button';
import { navRoutes } from '../../../constants';

const LearnerMilestonesReached = () => {
  const { learnerId } = useParams();

  const { stats, isLoading } = useGetLearnerStats({
    learnerId,
  });

  if (isLoading) return <Loading />;
  return (
    <S.PageWrapper>
      <BackButton mb="6" />
      <S.HeaderWrapper>
        <T.H2 mb="4">Milestones reached</T.H2>
        <BasicButton
          variant="primary"
          to={navRoutes.LEARNER.SELECT_MILESTONE.replace(
            ':learnerId',
            learnerId
          )}
          mb="6"
          width="auto"
        >
          Edit learner level
        </BasicButton>
      </S.HeaderWrapper>
      <Milestones stats={stats} />
    </S.PageWrapper>
  );
};

export default LearnerMilestonesReached;
