import styled from '@emotion/styled';
import { setMargin } from 'helpers';

export const LinkButton = styled.button`
  ${setMargin};
  all: unset;
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.tertiaryMain};
  text-decoration: underline;
  cursor: pointer;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
