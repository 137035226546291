import { useSearchParams } from 'react-router-dom';
import { formTypes, mediaTypes } from '../../constants';
import * as S from './style';
import { useUpdateStepMedia } from 'CMS/hooks/useUpdateStepMedia';
import { useUpdateExerciseMedia } from 'CMS/hooks/useUpdateExerciseMedia';

export const SelectMediaButton = ({ mediaKey }) => {
  const [searchParams] = useSearchParams();
  const { updateStepMediaType } = useUpdateStepMedia();
  const { updateExerciseMediaType } = useUpdateExerciseMedia();

  const formType = searchParams.get('form');
  const tabKey = searchParams.get('tabKey');

  const onClick = () =>
    formType === formTypes.EXERCISE
      ? updateExerciseMediaType({ mediaKey })
      : updateStepMediaType({ mediaKey });

  return (
    <S.SelectButton type="link" onClick={onClick} color="primaryMain" underline>
      {`Select ${tabKey === mediaTypes.IMAGE ? 'image' : 'audio'}`}
    </S.SelectButton>
  );
};
