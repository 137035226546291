import * as S from './style';
import * as T from '../Typography';

import Icon from '../Icon';
import data from './data';

const RateButton = ({
  buttonId = 1,
  variant = 'clickable',
  count,
  percentage,
  setFeeling,
  selected,
}) => {
  const button = data[buttonId];

  const handleFeelingChange = (newFeeling) => {
    if (variant !== 'clickable') return;
    const updatedFeeling = selected ? null : newFeeling;
    setFeeling(updatedFeeling);
  };

  const handleKeyPress = (event, newFeeling) => {
    if (variant !== 'clickable') return;
    if (event.key === 'Enter' || event.key === ' ') {
      handleFeelingChange(newFeeling);
    }
  };

  return (
    <S.RadioLabel
      selected={selected}
      tabIndex={0}
      onKeyDown={(e) => handleKeyPress(e, button.value)}
      role="button"
      variant={variant}
    >
      <S.RadioInput
        type="radio"
        name="radio"
        checked={selected}
        onChange={() => handleFeelingChange(button.value)}
      />
      <Icon icon={button.icon} />
      <T.P color="neutral90" weight={selected && 'bold'} ta="center">
        {button.value}
      </T.P>
      {variant === 'stats' && (
        <T.P color="quinaryDark" weight="bold" ta="center" display="inline">
          {percentage}{' '}
          <T.P display="inline" color="neutral60">
            {count} answers
          </T.P>
        </T.P>
      )}
    </S.RadioLabel>
  );
};

export default RateButton;
