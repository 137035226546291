import styled from '@emotion/styled';
import setMargin from './../../helpers/set-margin';

export const LinkWrapper = styled.div`
  ${setMargin};
  width: 100%;
  padding: ${({ theme }) => `${theme.spacings[3]} ${theme.spacings[5]}`};
  background-color: ${({ theme }) => theme.colors.neutral30};
  border-radius: ${({ theme }) => theme.borders.radiusMedium};
  &:not(:last-child) {
    margin-bottom: 8px !important;
  }
`;

export const LinkTitleWrapper = styled.div`
  ${setMargin};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LinkActions = styled.div`
  ${setMargin};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`;

export const Action = styled.button`
  ${setMargin};
  all: unset;
  cursor: pointer;
`;
