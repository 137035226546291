import styled from '@emotion/styled';
import { Row, Col } from '../../../components/Grid';
export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: ${({ theme }) => theme.spacings[8]};
  padding-right: ${({ theme }) => theme.spacings[11]};
  padding-left: ${({ theme }) => theme.spacings[11]};
  ${({ theme }) => theme.media.tablet} {
    padding-right: ${({ theme }) => theme.spacings[6]};
    padding-left: ${({ theme }) => theme.spacings[6]};
  }
  ${({ theme }) => theme.media.mobile} {
    padding-top: ${({ theme }) => theme.spacings[4]};
    padding-right: 0;
    padding-left: 0;
  }
`;

export const ButtonsWrapper = styled(Row)`
  flex: 0;
  align-items: flex-end;
  ${({ theme }) => theme.media.tablet} {
    flex: 0;
  }
`;

export const ButtonContainer = styled(Col)`
  align-items: flex-end;
`;
