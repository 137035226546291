import { useAuth } from 'context/auth';
import { useGetOrganisationTrainingProgress } from 'api-calls/organisations.queries';
import { userRoles } from 'constants/index';
import { useOrganisationDetails } from 'context/organisation-details';

const useGetOrgProgress = () => {
  const { user } = useAuth();
  const { organisationDetails } = useOrganisationDetails();

  const {
    data: orgProgress,
    isLoading,
    error: progressError,
  } = useGetOrganisationTrainingProgress(
    { organisationId: organisationDetails?.id },
    { enabled: !!organisationDetails?.id }
  );

  const { completedCustomisation, facilitatorsCount, coachesCount } =
    user.role === userRoles.ADMIN
      ? {
          completedCustomisation: true,
          facilitatorsCount: 0,
          coachesCount: 1,
          loading: false,
          progressError: '',
        }
      : orgProgress || {};

  const hasFacilitator =
    (user?.isFacilitator && user?.role === userRoles.ORGANISATION_ADMIN) ||
    !!facilitatorsCount;

  return {
    completedCustomisation,
    facilitatorsCount: Number(facilitatorsCount),
    coachesCount: Number(coachesCount),
    progressError,
    loading: isLoading,
    hasFacilitator,
  };
};

export default useGetOrgProgress;
