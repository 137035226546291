import styled from '@emotion/styled';
import setMargin from '../../../helpers/set-margin';
import Icon from '../../Icon';

export const Field = styled.div`
  ${setMargin};
  width: ${({ w }) => w || '100%'};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  .ant-select {
    border-radius: ${({ theme }) => theme.borders.radiusLarge};
    width: 100%;
    height: 60px;
    border: none;
    background-color: transparent;
    box-shadow: ${({ error, theme }) =>
      error
        ? `inset 0px 0px 0px 2px ${theme.colors.error} `
        : `inset 0px 0px 0px 2px ${theme.colors.neutralMain} `};
  }

  .ant-select:hover {
    .ant-select-arrow path {
      stroke: ${({ theme }) => theme.colors.primaryMain};
    }

    box-shadow: ${({ error, theme }) =>
      error
        ? `inset 0px 0px 0px 2px ${theme.colors.error} !important`
        : `inset 0px 0px 0px 2px ${theme.colors.primaryMain} !important`};
  }

  .ant-select:focus-within {
    box-shadow: inset 0px 0px 0px 2px rgba(5, 23, 48, 1) !important;
    outline-style: solid;
    outline-width: 3px;
    outline-color: ${({ theme }) => theme.colors.primaryMain + '!important'};
    outline-offset: 0.2em;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: ${({ theme }) => theme.spacings[3]};
    background-color: transparent;
    overflow-x: hidden;
    border: none;
    border-radius: ${({ theme }) => theme.borders.radiusLarge};
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector {
    height: 100%;
    padding: ${({ theme }) => theme.spacings[3]};
  }

  // STYLE SELECTED ITEM IN INPUT FOR MULTIPLE SELECT
  .ant-select-multiple .ant-select-selection-item {
    height: 100%;
    padding: 0.1rem 0.4rem;
    font-size: 1rem;
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    input {
    height: 100%;
  }

  // STYLE ARROW ICON
  .ant-select-arrow,
  .ant-select-clear,
  .ant-select-selection-item-remove {
    color: ${({ theme }) => theme.colors.secondaryMain};
    width: 20px;
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 15px;
      transition: all ease 0.5s;
      transform: ${({ open, multi, search }) =>
        open && !multi && !search && 'rotate(180deg)'};
    }
  }

  // STYLE PLACEHOLDER
  .ant-select-multiple .ant-select-selection-placeholder,
  .ant-select-selection-placeholder {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.neutral80};
    padding-left: ${({ theme }) => theme.spacings[3]};
    text-align: left;
  }

  // STYLE SELECTED ITEM IN SINGLE DROPDOWN
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.neutralMain};
    display: flex;
    align-items: center;

    // SEEMS WEIRD BUT YOU NEED TO DO THIS TO FORCE THE WIDTH TO STAY CONTAINED
    width: 1px;
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }

  .ant-select-arrow svg {
    width: 20px;
    height: 15px;
  }
`;

export const Answer = styled.div`
  flex-direction: column;
  width: 100%;
`;

export const OptionStyle = `
  font-size: 0.875rem;
  font-weight: normal;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  white-space: normal;
`;

export const StyledIcon = styled(Icon)`
  width: 100%;
`;
