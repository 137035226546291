import TextField from '@mui/material/TextField';
import moment from 'moment';
import * as S from './style.js';
import * as CS from './../style';
import * as T from '../../Typography';
import { forwardRef } from 'react';

const DatePicker = forwardRef((props, ref) => {
  const {
    label,
    color,
    error,
    helper,
    w,
    disabled,
    m,
    handleChange,
    optional,
    value,
    ignoreDateLimits,
    validDateLimit = moment().subtract(18, 'years').format('YYYY-MM-DD'), // used for DOB
    minDateLimit,
  } = props;

  const decideColor = () => {
    if (error) return 'error';
    return color;
  };
  // const pickerRef = useRef();

  // This is not working on all browsers
  // const handleClick = () => {
  //   const elm = pickerRef.current.querySelector('input[type="date"]');
  //   elm.showPicker();
  // };

  return (
    <S.Field
      w={w}
      disabled={disabled}
      color={decideColor()}
      error={error}
      {...m}
    >
      {label && (
        <CS.Label htmlFor={label} mb={helper ? '1' : '2'}>
          <T.P weight="bold" mb={helper ? '0' : '4'}>
            {label}
          </T.P>
          {optional && <CS.OptionalTag ml="1">(optional)</CS.OptionalTag>}
        </CS.Label>
      )}
      {helper && (
        <T.P color="neutralMain" mb="4" ml={2}>
          {helper}
        </T.P>
      )}
      <S.Answer>
        <TextField
          ref={ref}
          onChange={(e) => {
            handleChange(moment(e.target.value).format('YYYY-MM-DD'));
          }}
          type="date"
          sx={{ '& .MuiOutlinedInput-root': { height: '64px' } }}
          fullWidth
          hiddenLabel
          {...(!moment(value).isValid()
            ? {}
            : { value: moment(value).format('YYYY-MM-DD') })}
          format="YYYY-MM-DD"
          dateFormat="YYYY-MM-DD"
          // onClick={handleClick}
          // ref={pickerRef}
          inputProps={{
            max: !ignoreDateLimits && validDateLimit,
            min: minDateLimit,
          }}
          label={`hidden-label ${label}`}
          id="date"
          InputLabelProps={{
            shrink: true,
            style: {
              clip: 'rect(0 0 0 0)',
              clipPath: 'inset(50%)',
              height: 1,
              overflow: 'hidden',
              position: 'absolute',
              whiteSpace: 'nowrap',
              width: 1,
            },
          }}
        />
      </S.Answer>
      {error && (
        <T.P color="error" m="0" ml="3" mt="2">
          {error}
        </T.P>
      )}
    </S.Field>
  );
});

export default DatePicker;
