import * as S from './style';
import { PlayButton } from '../Button';
import * as T from '../Typography';
import MilestoneCard from './MilestoneCard';
import { getMediaUrl } from '../../helpers';
import { useGeneralState } from './../../context/general-state';
import Icon from 'components/Icon';
import TextWithIcon from 'components/TextWithIcon';

const handleClick = () => {};
const Exercises = ({
  image = 'exerciseCompletedBackground',
  backgroundColor = 'neutralLight',
  to,
  title,
  exerciseComplete,
  savedExercise,
  disabled,
  titleAudioKey,
  ...props
}) => {
  const { state: generalState } = useGeneralState();
  // used when button is disabled
  const preventClick = (event) => {
    event.preventDefault();
  };

  return (
    <S.StyledLink
      backgroundColor={backgroundColor}
      to={to}
      onClick={disabled ? preventClick : null}
      exerciseComplete={exerciseComplete}
      savedExercise={savedExercise}
      disabled={disabled}
      aria-disabled={disabled ? 'true' : 'false'}
      {...props}
    >
      <S.StyledImage image={image} />

      <S.Content>
        <PlayButton
          handleClick={handleClick}
          backgroundColor="white"
          iconProps={{ width: '32px', height: '32px', color: 'neutralMain' }}
          padding="12px"
          icon={disabled ? 'lock' : 'speaker'}
          disabled={disabled}
          audioUrl={getMediaUrl(
            titleAudioKey,
            true,
            generalState?.preferredVoice
          )}
          fallbackText={title}
        />
        <T.P
          weight="bold"
          ta="left"
          lineHeight="medLarge"
          size="medLarge"
          color="neutralMain"
        >
          {title}
        </T.P>
      </S.Content>
      {exerciseComplete && (
        <S.CompletedIconWrapper>
          <Icon icon="check" color="quinaryDark" width="28px" height="28px" />
        </S.CompletedIconWrapper>
      )}
      {savedExercise && (
        <S.SavedIconWrapper>
          <TextWithIcon
            icon="bookmarkSaved"
            text="Parked page"
            isText={true}
            iconProps={{
              color: 'neutralMain',
              width: '20px',
              height: '26px',
            }}
          />
        </S.SavedIconWrapper>
      )}
    </S.StyledLink>
  );
};

export { MilestoneCard };
export default Exercises;
