import { BasicButton } from './../../../components/Button';
import { useState } from 'react';

import {
  useCreateMilestone,
  useGetMilestones,
} from './../../../api-calls/milestones.queries';
import { message } from 'antd';
import { BasicInput } from '../../../components/Inputs';
import * as T from '../../../components/Typography';
import Table from './Table';

const ViewMilestones = () => {
  const [title, setTitle] = useState('');
  const { data: milestones } = useGetMilestones();
  const {
    mutateAsync: createMilestone,
    error,
    loading,
  } = useCreateMilestone(null);

  const onClick = async () => {
    await createMilestone(
      { title },
      {
        onSuccess: () => {
          message.success('Milestone created successfully');
          setTitle('');
        },
      }
    );
  };

  return (
    <div>
      <Table data={milestones} loading={loading} />
      <div>
        <BasicInput
          value={title}
          handleChange={setTitle}
          m={{ mt: 5 }}
          label="Add new"
          placeholder="Enter milestone's title"
          w="352px"
        />
        <T.P color="error" mt={error?.message ? '2' : '6'} mb="1">
          {error?.message}
        </T.P>
        <BasicButton
          onClick={onClick}
          loading={loading}
          disabled={!title}
          maxWidth="352px"
        >
          Create new one
        </BasicButton>
      </div>
    </div>
  );
};

export default ViewMilestones;
