import {
  useDelete,
  formatQueryError,
  usePost,
  useFetch,
  usePatch,
  useFetchMutation,
} from '../utils/queries';
import { USERS_BASE, userRoles } from '../constants';
import axios from 'axios';
import handleError from './format-error';

function useDeleteUser({ id, role }, options = {}) {
  const context = useDelete({
    url: `${USERS_BASE}/delete/${role}/${id}`,
    reactQueryConfig: {
      invalidateKeys: () => [
        role === userRoles.LEARNER ? 'GET_LEARNERS' : 'COACHES',
      ],
      ...options,
    },
  });
  return { ...context, error: formatQueryError(context) };
}

function useLogout(_, options = {}) {
  const context = usePost({
    url: `${USERS_BASE}/logout`,
    reactQueryConfig: {
      invalidateAll: true,
      ...options,
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
}

function useDeleteUserPersonalInfo(_, options = {}) {
  const context = useDelete({
    url: `${USERS_BASE}/personal-info`,
    reactQueryConfig: {
      invalidateAll: true,
      ...options,
    },
  });
  return { ...context, error: formatQueryError(context) };
}

function useGetUserPersonalInfo({ id }, options = {}) {
  const context = useFetch({
    key: ['PERSONAL_INFO', id],
    url: `${USERS_BASE}/personal-info`,
    reactQueryConfig: {
      ...options,
    },
  });
  return { ...context, error: formatQueryError(context) };
}

function useUpdateUserPersonalInfo(_, options = {}) {
  const context = usePatch({
    url: `${USERS_BASE}/personal-info`,
    reactQueryConfig: {
      invalidateKeys: () => [['PERSONAL_INFO']],
      ...options,
    },
  });

  return { ...context, error: formatQueryError(context) };
}

const useGetLoggedInUserInfo = (
  { organisationId, userId, role },
  options = {}
) => {
  const context = useFetch({
    key: ['LOGGED_IN_USER_INFO'],
    url: `${USERS_BASE}/my-info?organisationId=${organisationId}&userId=${userId}&role=${role}`,
    reactQueryConfig: {
      ...options,
    },
  });

  return { ...context, error: formatQueryError(context) };
};

const useGetLoggedInUserInfoMutation = (_, options = {}) => {
  const context = useFetchMutation({
    getUrl: ({ organisationId, userId, role }) =>
      `${USERS_BASE}/my-info?organisationId=${organisationId}&userId=${userId}&role=${role}`,
    reactQueryConfig: {
      ...options,
    },
  });

  return { ...context, error: formatQueryError(context) };
};

const useLogin = (_, options = {}) => {
  const context = usePost({
    url: `${USERS_BASE}/login`,
    reactQueryConfig: options,
  });
  return { ...context, error: formatQueryError(context) };
};

const useUpdateOverlayColor = (_, options = {}) => {
  const context = usePatch({
    url: `${USERS_BASE}/overlay-color`,
    reactQueryConfig: options,
  });
  return { ...context, error: formatQueryError(context) };
};

const useGetCSRFToken = (_, options = {}) => {
  const context = useFetch({
    key: ['CSRF_TOKEN'],
    url: `${USERS_BASE}/get-csrf-token`,
    reactQueryConfig: options,
  });
  return { ...context, error: formatQueryError(context) };
};

const getCSRFToken = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${USERS_BASE}/get-csrf-token`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const useSendResetPasswordLink = (_, options = {}) => {
  const context = usePost({
    url: `${USERS_BASE}/reset-password-link`,
    reactQueryConfig: options,
  });
  return { ...context, error: formatQueryError(context) };
};

const useUpdatePassword = (_, options = {}) => {
  const context = usePost({
    url: `${USERS_BASE}/update-password`,
    reactQueryConfig: options,
  });
  return { ...context, error: formatQueryError(context) };
};

const useGetSelectedLearner = (_, options = {}) => {
  const context = useFetch({
    key: ['SELECTED_LEARNER'],
    url: `${USERS_BASE}/selected-learner`,
    reactQueryConfig: options,
  });
  return { ...context, error: formatQueryError(context) };
};

const useSwitchToCourse = (_, options = {}) => {
  const context = usePost({
    url: `${USERS_BASE}/switch-course`,
    reactQueryConfig: options,
  });
  return { ...context, error: formatQueryError(context) };
};

const useAutoLogin = (_, options = {}) => {
  const context = usePost({
    url: `${USERS_BASE}/auto-login`,
    reactQueryConfig: options,
  });
  return { ...context, error: formatQueryError(context) };
};

const useResend2FACode = (_, options = {}) => {
  const context = usePost({
    url: `${USERS_BASE}/resend-2fa-code`,
    reactQueryConfig: options,
  });
  return { ...context, error: formatQueryError(context) };
};

const useVerify2FACode = (_, options = {}) => {
  const context = usePost({
    url: `${USERS_BASE}/verify-2fa-code`,
    reactQueryConfig: options,
  });
  return { ...context, error: formatQueryError(context) };
};

export {
  useDeleteUser,
  useLogout,
  useDeleteUserPersonalInfo,
  useGetUserPersonalInfo,
  useUpdateUserPersonalInfo,
  useGetLoggedInUserInfo,
  useLogin,
  useUpdateOverlayColor,
  useGetCSRFToken,
  getCSRFToken,
  useSendResetPasswordLink,
  useUpdatePassword,
  useGetSelectedLearner,
  useGetLoggedInUserInfoMutation,
  useSwitchToCourse,
  useAutoLogin,
  useResend2FACode,
  useVerify2FACode,
};
