import React, {
  createContext,
  useContext,
  useReducer,
  useCallback,
  useEffect,
} from 'react';
import { isEmpty } from '../utils';

const StepFormContext = createContext();

const initialStepFormState = {
  form: {
    type: '',
    order: 0,
    imageKey: '',
    audioKey: '',
  },
  data: {},
  httpError: '',
  validationErrs: {},
  getExerciseLoading: false,
  key: 0,
  disabled: false,
  isDirty: false,
};

export const stepFormActionTypes = {
  UPDATE_FORM_FIELDS: 'UPDATE_FORM_FIELDS',
  UPDATE_DATA_FIELDS: 'UPDATE_DATA_FIELDS',
  UPDATE_FORM_META_DATA: 'UPDATE_FORM_META',
  RESET_DATA_FIELDS: 'RESET_DATA_FIELDS',
  RESET_FORM: 'RESET_FORM',
};

// from the previous onChange function
// these were passed on every change
// key: prevState.key + 1
// disabled

function stepFormReducer(state, action) {
  switch (action.type) {
    case stepFormActionTypes.UPDATE_FORM_FIELDS: {
      return {
        ...state,
        form: { ...state.form, ...action.data },
        key: state.key + 1,
      };
    }
    case stepFormActionTypes.UPDATE_DATA_FIELDS: {
      return {
        ...state,
        data: { ...state.data, ...action.data },
        key: state.key + 1,
      };
    }
    case stepFormActionTypes.UPDATE_FORM_META_DATA: {
      return { ...state, ...action.data };
    }
    case stepFormActionTypes.RESET_DATA_FIELDS: {
      return { ...state, data: { ...initialStepFormState.data } };
    }
    case stepFormActionTypes.RESET_FORM: {
      return { ...initialStepFormState };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function StepFormProvider({ children }) {
  const [state, dispatch] = useReducer(stepFormReducer, initialStepFormState);

  const value = [state, dispatch];

  return (
    <StepFormContext.Provider value={value}>
      {children}
    </StepFormContext.Provider>
  );
}

export function useStepForm(initialData) {
  const [state, dispatch] = useContext(StepFormContext);

  if (!state) {
    throw new Error('useStepForm must be used within the StepFormContext');
  }

  // Set initial data values provided by step form components
  useEffect(() => {
    if (!!state.data && isEmpty(state.data) && initialData) {
      dispatch({
        type: stepFormActionTypes.UPDATE_DATA_FIELDS,
        data: initialData,
      });
    }
  }, [dispatch, initialData, state.data]);

  // Show browser's default warning on reload without saving
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (state.isDirty) {
        event.preventDefault();
        event.returnValue = ''; // This triggers the browser warning
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [state.isDirty]);

  const updateFormFields = useCallback(
    (data, isDirty = true) => {
      dispatch({ type: stepFormActionTypes.UPDATE_FORM_FIELDS, data });
      dispatch({
        type: stepFormActionTypes.UPDATE_FORM_META_DATA,
        data: { isDirty },
      });
    },
    [dispatch]
  );

  const updateDataFields = useCallback(
    (data, isDirty = true) => {
      dispatch({ type: stepFormActionTypes.UPDATE_DATA_FIELDS, data });
      dispatch({
        type: stepFormActionTypes.UPDATE_FORM_META_DATA,
        data: { isDirty },
      });
    },
    [dispatch]
  );

  const updateFormMetaData = useCallback(
    (data) => {
      dispatch({ type: stepFormActionTypes.UPDATE_FORM_META_DATA, data });
    },
    [dispatch]
  );

  const resetDataFields = useCallback(() => {
    dispatch({ type: stepFormActionTypes.RESET_DATA_FIELDS });
  }, [dispatch]);

  const resetForm = useCallback(() => {
    dispatch({ type: stepFormActionTypes.RESET_FORM });
  }, [dispatch]);

  return {
    state,
    updateFormFields,
    updateDataFields,
    updateFormMetaData,
    resetForm,
    resetDataFields,
  };
}
