import { useMemo, useRef } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput } from '../../../../components/Inputs';
import * as S from './style';
import { Icon } from '../../../../components';
import { string, number, array, object } from 'yup';
import { useStepForm } from 'CMS/Providers/StepFormProvider';

const initialData = {
  options: [{ id: 0, option: '' }],
  audioUrl: '',
  correctOptions: [{ id: 0, option: '' }],
};

const WordJoin1 = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateDataFields } = useStepForm(initialRef.current);
  const { data, validationErrs } = state;

  const options = useMemo(
    () =>
      data?.options?.map((e, i) => ({ ...e, id: i })) || [
        { id: 0, option: '' },
      ],
    [data?.options]
  );

  const correctOptions = useMemo(
    () =>
      data?.correctOptions?.map((e, i) => ({
        ...e,
        id: i,
      })) || [{ id: 0, option: '' }],
    [data?.correctOptions]
  );

  const addOption = () => {
    const maxId = Math.max(...options.map((e) => e.id), 0);
    updateDataFields({
      options: [...options, { id: maxId + 1, option: '' }],
    });
  };
  const removeOption = (id) => {
    updateDataFields({
      options: options.filter((e) => e.id !== id),
    });
  };

  const addCorrectOptions = () => {
    const maxId = Math.max(...correctOptions.map((e) => e.id), 0);
    updateDataFields({
      correctOptions: [...correctOptions, { id: maxId + 1, option: '' }],
    });
  };
  const removeCorrectOptions = (id) => {
    updateDataFields({
      correctOptions: correctOptions.filter((e) => e.id !== id),
    });
  };
  const onOptionChange = (value, id) => {
    updateDataFields({
      options: options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  const onCorrectOptionsTextChange = (value, id) => {
    updateDataFields({
      correctOptions: correctOptions.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  return (
    <div>
      <T.P mt={8} size="large" weight="bold">
        Options
      </T.P>
      {validationErrs?.options &&
        typeof validationErrs.options === 'string' && (
          <T.P mb={2} mt={2} color="error">
            {validationErrs.options}
          </T.P>
        )}
      {options.map((e, i) => {
        return (
          <>
            <BasicInput
              label={`Option ${i + 1}`}
              value={e.option}
              handleChange={(value) => {
                onOptionChange(value, e.id);
              }}
              m={{ mt: 5 }}
              helper='single word e.g. "stand" / "up" ...'
              error={
                validationErrs?.options && validationErrs?.options[i]?.option
              }
            />

            {options.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeOption(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}
      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addOption}
        disabled={options.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Option
      </S.Button>
      <T.P mt={8} size="large" weight="bold">
        Correct words
      </T.P>

      {validationErrs?.correctOptions &&
        typeof validationErrs.correctOptions === 'string' && (
          <T.P mb={2} mt={2} color="error">
            {validationErrs.correctOptions}
          </T.P>
        )}

      {correctOptions.map((e, i) => {
        return (
          <>
            <BasicInput
              label={`Word ${i + 1}`}
              value={e.option}
              handleChange={(value) => {
                onCorrectOptionsTextChange(value, e.id);
              }}
              m={{ mb: 2, mt: 5 }}
              helper='split words with "-" e.g. stand-up'
              error={
                validationErrs?.correctOptions &&
                validationErrs?.correctOptions[i]?.option
              }
            />

            {correctOptions.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeCorrectOptions(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}
      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addCorrectOptions}
        disabled={correctOptions.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add correct word
      </S.Button>
    </div>
  );
};

const validationSchema = {
  options: array()
    .of(
      object()
        .shape({
          id: number().notRequired(),
          option: string().required('required field'),
        })
        .required()
    )
    .required('You must provide options')
    .min(1, 'You must provide options'),
  correctOptions: array()
    .of(
      object()
        .shape({
          id: number().required(),
          option: string()
            .required('required field')
            .test('shouldIncludeDash', 'Should be split by "-"', (val) => {
              return val?.split('-')?.length === 2;
            }),
        })
        .required()
    )
    .required('You must provide correct words')
    .min(1, 'You must provide correct words'),
};
export { validationSchema };

export default WordJoin1;
