import { useParams } from 'react-router-dom';
import { navRoutes } from 'constants';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import Loading from '../../../components/Loading';

import { EXERCISES } from '../../../constants/nav-routes';
import * as S from './style';
import Exercises from '../../../components/Exercises';
import { useGetMilestonePreparationExercises } from '../../../api-calls/milestones.queries';
import { BackButton } from 'components/BackButton';

const PrepareMilestone = () => {
  const { milestoneId } = useParams();

  const { data, isLoading } = useGetMilestonePreparationExercises({
    milestoneId,
  });

  if (isLoading) return <Loading />;

  return (
    <S.Wrapper>
      <BackButton />
      <T.H1 mt="6">Milestone {milestoneId} - Coach Preparation</T.H1>
      <T.P color="neutral90" mt={5}>
        Below we have provided an example of each of the types of exercises you
        will encounter in this milestone is everything you need to know in order
        to deliver Turning Pages
      </T.P>
      <Row inner ai="stretch">
        {data?.map((exercise) => (
          <Col w={[4, 4, 4]} mt={5} ai="stretch">
            <Exercises
              title={exercise.title}
              titleAudioKey={exercise.titleAudioKey}
              backgroundColor="neutralLight"
              to={
                EXERCISES.EXERCISE_EXPLAINER.replace(
                  ':exerciseId',
                  exercise.id
                ) + '?isPreparation=true'
              }
              exerciseComplete={false}
              savedExercise={false}
              disabled={false}
            />
          </Col>
        ))}
      </Row>

      <Row inner mb="6">
        <Col w={[4, 12, 7]}>
          <S.Card>
            <T.P>
              Remember you can review these at any time! And if you’d like to
              practice the whole milestone then you can do that via your
              practice version of the tool here
            </T.P>

            <S.Button
              bgColor="quaternaryMain"
              variant="secondary"
              to={
                navRoutes.COMMON.EXERCISES_PLAYGROUND +
                `?milestoneId=${milestoneId}`
              }
              aria-label="Find out more"
              type="submit"
              id="find-out-more"
              mt={5}
              external
            >
              Play with tool{' '}
            </S.Button>
          </S.Card>
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default PrepareMilestone;
