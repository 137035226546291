import { BackButton } from 'components/BackButton';
import React from 'react';
import { Button, IconWithParagraph, Image, Typography as T } from 'components';
import { useParams } from 'react-router-dom';
import { navRoutes } from 'constants';
import { useAuth } from 'context/auth';
import { useGetOrganisationByIdOrUniqueSlug } from 'api-calls/organisations.queries';
import * as S from './style';

function SafeGuard() {
  const { receiverId } = useParams();
  const { user } = useAuth();
  const { data: org = {} } = useGetOrganisationByIdOrUniqueSlug({
    id: user?.organisationId,
  });

  return (
    <S.Wrapper>
      <BackButton />
      <S.InnerWrapper>
        <T.H1>Safeguarding</T.H1>
        <S.BodyWrapper>
          <S.BodyTextWrapper>
            <div style={{ fontSize: '16px' }}>
              We are committed to safeguarding, protecting children and
              vulnerable adults and keeping employees, volunteers and people
              involved with our service safe from harm. To ensure that we can
              work together in a the safest way, the following should be adhered
              to:
            </div>
            <IconWithParagraph icon="smallTick" ai="flex-start">
              <T.P>
                In order to maintain a professional relationship and respect
                personal boundaries, Coaches and Learners are strongly advised
                to withhold from sharing personal information with one another.
              </T.P>
            </IconWithParagraph>
            <IconWithParagraph icon="smallTick" ai="flex-start">
              <T.P>
                Should a Learner volunteer information that suggest they, or
                anyone else is experiencing harm, this should be reported to the
                Programme Manager at the earliest opportunity.
              </T.P>
            </IconWithParagraph>
            <T.P>
              You can read our full{' '}
              <T.Link to={navRoutes.COACH.DASHBOARD_SAFEGUARDING} underline>
                Safeguarding advice here
              </T.Link>
              .
            </T.P>
          </S.BodyTextWrapper>

          <S.IssueSection>
            <div>
              <T.H2>Any Issues?</T.H2>
              <T.P color="neutral90" mt="4px" mb="12px">
                Reach out to {org.name} for assistance to help If you encounter
                any issues.
              </T.P>
              <Button.BasicButton
                icon="message"
                height="40px"
                maxWidth="270px"
                variant="secondary"
                left
                external
                to={`mailto:${org.email}`}
                showEllipsis
              >
                Contact {org.name}
              </Button.BasicButton>
            </div>
            <Image
              image="waving"
              alt="waving person"
              width="120px"
              height="120px"
            />
          </S.IssueSection>
        </S.BodyWrapper>
        <Button.BasicButton
          to={navRoutes.COACH.CHAT_WITH_LEARNER.replace(
            ':receiverId',
            receiverId
          )}
        >
          Continue
        </Button.BasicButton>
      </S.InnerWrapper>
    </S.Wrapper>
  );
}

export default SafeGuard;
