import { PlayButton } from '../../../../../../components/Button';

import StepFooterButtons from '../../../StepFooterButtons';
const ListenAndRepeat = ({
  data: { questionText, questionAudioUrl } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
}) => {
  return (
    <>
      <PlayButton
        audioUrl={questionAudioUrl}
        iconProps={{ width: '32px', height: '32px' }}
        weight="bold"
        mb={5}
        fallbackText={typeof questionText === 'string' && questionText}
      >
        {questionText}
      </PlayButton>

      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleNext}
        preview={preview}
        disabled={disabled || preview}
        enableNext={!disabled || !preview}
      />
    </>
  );
};

export default ListenAndRepeat;
