import { Table } from 'antd';

import { PlayButton } from '../../components/Button';
import { convertSnakeCaseToSpaces, getMediaUrl } from './../../helpers';
import { Col, Row } from 'components/Grid';
import * as T from 'components/Typography';
import { Icon } from 'components';
import AudioVoModal from 'CMS/components/AudioVoModal';
import { useState } from 'react';
import VoiceToggle from 'CMS/components/VoiceToggle';
import theme from 'theme';
import { useGeneralState } from 'context/general-state';
import { EditButton, MicButton, VoStatus } from './style';

const getStatusColors = (status) => {
  if (status.toLowerCase() === 'awaiting review')
    return {
      color: theme.colors.neutral80,
      bgColor: theme.colors.warningLight,
    };
  if (status.toLowerCase() === 'awaiting recording')
    return {
      color: theme.colors.neutralSurface,
      bgColor: theme.colors.primaryMain,
    };
  // default colors
  return {
    color: theme.colors.neutral80,
    bgColor: theme.colors.warningLight,
  };
};

const getColumns = ({ preferredVoice, onEdit }) => [
  {
    title: 'Voice type',
    dataIndex: 'id',
    key: 'id',
    width: '25%',
    render: (text, record) => {
      if (record.status === 'AWAITING_RECORDING') {
        return (
          <Row jc="center" ai="center">
            <Col w={[12, 12, 12]} jc="center">
              <MicButton>
                <Icon icon="mic" color="white" />
              </MicButton>
            </Col>
            <Col w={[12, 12, 12]} mt={2} jc="center">
              <T.P color="primaryMain">Audio missing</T.P>
            </Col>
          </Row>
        );
      }
      const mediaUrl = getMediaUrl(record.fileKey, true, preferredVoice);
      return (
        <Row>
          <Col w={[12, 12, 12]} jc="center">
            <PlayButton audioUrl={mediaUrl} showCrossIconOnError />
          </Col>
          <Col w={[12, 12, 12]} mt={2} jc="center">
            <T.P color="neutral80" copyable>
              {record.fileKey}
            </T.P>
          </Col>
        </Row>
      );
    },
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: (text, record) => {
      return (
        <T.P color="neutral80" weight="bold" copyable>
          {text}
        </T.P>
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text, record) => {
      if (!record.status) return null;
      return (
        <VoStatus bgColor={getStatusColors(record.status).bgColor}>
          <T.P weight="bold" color={getStatusColors(record.status).color}>
            {convertSnakeCaseToSpaces(record.status)}
          </T.P>
        </VoStatus>
      );
    },
  },
  {
    key: 'actions',
    title: 'Actions',
    width: 282,
    render: (_, record) => {
      return (
        <Row jc="flex-start" ai="center">
          <EditButton onClick={() => onEdit(record)}>
            <Icon icon="edit" />
            <T.P weight="bold" color="primaryMain" ml={2}>
              Edit
            </T.P>
          </EditButton>
        </Row>
      );
    },
  },
];

const components = {
  header: {
    cell: ({ children, ...restProps }) => (
      <th
        {...restProps}
        style={{
          backgroundColor: theme.colors.neutral30,
          color: theme.colors.neutralMain,
          fontWeight: 'bold',
        }}
      >
        {children}
      </th>
    ),
  },
};

export default ({ data, loading }) => {
  const [currentVo, setCurrentVo] = useState({});
  const [isVoModalOpen, setIsVoModalOpen] = useState(false);
  const { state: generalState } = useGeneralState();

  const onEdit = (record) => {
    setCurrentVo({ ...record, tags: record.tags.map((t) => t.id) });
    setIsVoModalOpen(true);
  };

  const columns = getColumns({
    preferredVoice: generalState.preferredVoice,
    onEdit,
  });

  return (
    <>
      <Row mb={5} mt="-10px">
        <VoiceToggle />
      </Row>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data}
        bordered
        loading={loading}
        components={components}
      />
      {isVoModalOpen && (
        <AudioVoModal
          isVoTable
          voRequired={currentVo}
          open={isVoModalOpen}
          onCancel={() => setIsVoModalOpen(false)}
        />
      )}
    </>
  );
};
