const ItalicText = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5625 12.375C4.30208 12.375 4.08083 12.2838 3.89875 12.1012C3.71625 11.9192 3.625 11.6979 3.625 11.4375C3.625 11.1771 3.71625 10.9558 3.89875 10.7738C4.08083 10.5912 4.30208 10.5 4.5625 10.5H5.73438L8.23438 4.25H7.0625C6.80208 4.25 6.58083 4.15875 6.39875 3.97625C6.21625 3.79417 6.125 3.57292 6.125 3.3125C6.125 3.05208 6.21625 2.83083 6.39875 2.64875C6.58083 2.46625 6.80208 2.375 7.0625 2.375H11.4375C11.6979 2.375 11.9192 2.46625 12.1012 2.64875C12.2838 2.83083 12.375 3.05208 12.375 3.3125C12.375 3.57292 12.2838 3.79417 12.1012 3.97625C11.9192 4.15875 11.6979 4.25 11.4375 4.25H10.2656L7.76562 10.5H8.9375C9.19792 10.5 9.41917 10.5912 9.60125 10.7738C9.78375 10.9558 9.875 11.1771 9.875 11.4375C9.875 11.6979 9.78375 11.9192 9.60125 12.1012C9.41917 12.2838 9.19792 12.375 8.9375 12.375H4.5625Z"
      fill="currentColor"
    />
  </svg>
);

export default ItalicText;
