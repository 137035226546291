import * as T from '../../../../../components/Typography';
import { BasicInput } from '../../../../../components/Inputs';
import { Icon } from '../../../../../components';
import { fillInTheGridVariations } from '../../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';

import * as S from '../style';

const CorrectAnswers = () => {
  const { state, updateDataFields } = useStepForm();
  const { data, validationErrs } = state;

  const onOptionChange = ({ key, value, id }) => {
    updateDataFields({
      correctOptions: data.correctOptions.map((e) => {
        if (e.id !== id) return e;
        return {
          ...e,
          [key]: value,
        };
      }),
    });
  };

  const addOption = () => {
    const maxId = Math.max(...data.correctOptions.map((e) => e.id), 0);
    updateDataFields({
      correctOptions: [...data.correctOptions, { id: maxId + 1, option: '' }],
    });
  };

  const removeOption = (id) => {
    updateDataFields({
      correctOptions: data.correctOptions.filter((e) => e.id !== id),
    });
  };

  let AnswerComponent = null;
  switch (data.variation) {
    case fillInTheGridVariations.variations.GRID_NO_VALUES:
      AnswerComponent = (
        <BasicInput
          label="Number of squares to fill"
          value={data.correctOptions?.[0]?.option}
          handleChange={(value) => {
            onOptionChange({
              key: 'option',
              value,
              id: data.correctOptions?.[0]?.id,
            });
          }}
          type="number"
          error={validationErrs?.correctOptions?.[0]?.option}
          placeholder="0"
        />
      );
      break;
    case fillInTheGridVariations.variations.GRID_WITH_VALUES:
      AnswerComponent = (
        <>
          <S.AnswerInputWrapper isGrid>
            {data.correctOptions.map((correctOption, index) => (
              <S.InputWithDeleteWrapper>
                <BasicInput
                  value={correctOption.option}
                  handleChange={(value) => {
                    onOptionChange({
                      key: 'option',
                      value,
                      id: correctOption.id,
                    });
                  }}
                  type="number"
                  error={validationErrs?.correctOptions?.[index]?.option}
                  placeholder="0"
                />
                <S.Button
                  type="link"
                  danger
                  ghost
                  onClick={() => removeOption(correctOption.id)}
                >
                  <Icon icon="cross" />
                </S.Button>
              </S.InputWithDeleteWrapper>
            ))}
          </S.AnswerInputWrapper>
          <S.Button
            type="link"
            ghost
            mt={5}
            mb={5}
            onClick={addOption}
            disabled={data.correctOptions.find((e) => !e.option)}
            ml="auto"
            mr="auto"
          >
            <Icon icon="plus" /> Add Option
          </S.Button>
        </>
      );
      break;

    default:
      break;
  }

  return (
    <>
      <T.P weight="bold" mt="8" ml="2" mb="2">
        Answers:
      </T.P>
      {AnswerComponent}
      {typeof validationErrs?.correctOptions === 'string' && (
        <T.P mb="2" color="error">
          {validationErrs.correctOptions}
        </T.P>
      )}
    </>
  );
};

export default CorrectAnswers;
