import styled from '@emotion/styled';

export const NotesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
