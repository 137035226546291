import { useEffect } from 'react';
import {
  Grid,
  Typography as T,
  ProgressCard,
  Loading,
} from '../../../components';
import { useNavigate } from 'react-router-dom';

import { navRoutes } from '../../../constants';
import useCustomTrainingStepHook from './Hooks/useCustomTrainingStepHook';
import { Divider } from 'components/Divider';
import { FurtherResources } from 'components/FurtherResources';
import { JoinOurCommunity } from 'components/JoinOurCommunity';

import PlayWithTheTool from 'components/PlayWithTheTool';

const { Row, Col } = Grid;

const TrainingReview = () => {
  const navigate = useNavigate();
  const {
    isAPILoading,
    customTrainingSteps,
    activeStep,
    customTrainingResources,
    isAllStepCompleted,
    trainingContent,
    userId,
  } = useCustomTrainingStepHook();

  useEffect(() => {
    if (!isAPILoading && customTrainingSteps && !isAllStepCompleted) {
      navigate(navRoutes.COACH.TRAINING_STEP.replace(':stepId', '1'));
    }
  }, [isAPILoading, customTrainingSteps, isAllStepCompleted, navigate]);

  if (isAPILoading) {
    return <Loading />;
  }

  return (
    <>
      <Row dir="column" jc="center">
        <Col w={[4, 10, 10]}>
          <T.H1 weight="bold" color="neutralMain" mb="2">
            Coach training
          </T.H1>
          <T.P mb="5">
            Here is everything you need to know in order to deliver Turning
            Pages in your organisation
          </T.P>
          <ProgressCard
            loading={isAPILoading}
            id={userId}
            activeStep={activeStep}
            data={trainingContent}
            completionMessage="🎉 Training complete"
          />
        </Col>
        <Col w={[4, 10, 10]}>
          <Divider />
        </Col>
        <Col w={[4, 10, 10]}>
          <PlayWithTheTool />
        </Col>
        <Col w={[4, 10, 10]}>
          <Divider />
        </Col>
        <Col w={[4, 10, 10]}>
          <FurtherResources customTrainingResources={customTrainingResources} />
        </Col>
        <Col w={[4, 10, 10]}>
          <Divider />
        </Col>
        <Col w={[4, 10, 10]}>
          <JoinOurCommunity />
        </Col>
      </Row>
    </>
  );
};

export default TrainingReview;
