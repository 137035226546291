import styled from '@emotion/styled';
import margins from 'helpers/set-margin';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentWrapper = styled.div`
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacings[6]};
  margin-right: ${({ theme }) => theme.spacings[6]};
`;

export const TextAudioWrapper = styled.div`
  width: 100%;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
  margin-bottom: ${({ theme, mb }) => theme.spacings[mb || 0]};
  display: flex;
  align-items: center;
  justify-content: ${({ jc }) => jc || 'center'};
`;

export const RowsWrapper = styled.div`
  background: ${({ theme }) => theme.colors.neutralLightNew};
  padding: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[5]}`};
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: ${({ theme }) => theme.spacings[4]};
  margin-bottom: ${({ theme }) => theme.spacings[4]};
`;

export const RowWrapper = styled.div`
  ${margins}
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  & input,
  & #formulaBox {
    width: ${({ inputsPerRow }) =>
      inputsPerRow > 4 ? '54px' : inputsPerRow > 3 ? '64px' : '80px'};
    height: ${({ inputsPerRow }) =>
      inputsPerRow > 4 ? '54px' : inputsPerRow > 3 ? '64px' : '80px'};
  }
`;

export const RowInputsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  flex-wrap: wrap;
  & > * {
    max-width: ${({ inputsPerRow }) =>
      inputsPerRow > 4 ? '54px' : inputsPerRow > 3 ? '64px' : '80px'};
  }
`;

export const FormulaeBox = styled.div`
  width: 64px;
  min-height: 64px;
  background: #fdfcf9;
  padding: ${({ theme }) => theme.spacing[4]};
  border-radius: ${({ theme }) => theme.spacings[3]};

  display: flex;
  justify-content: center;
  align-items: center;
`;
