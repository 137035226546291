import styled from '@emotion/styled';
import { Tag as AntdTag, Button as AntdButton } from 'antd';
import { Checkbox } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TableTitleWrapper = styled.div`
  min-height: 72px;
  display: flex;
  align-items: center;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  .ant-table table {
    font-size: 1rem;
    font-weight: 400;
  }

  .ant-tabs-ink-bar {
    background: ${({ theme }) => theme.colors.primaryMain};
    height: 3px !important;
  }
  .ant-tabs-tab {
    margin: 0 !important;
    min-width: 133px;
    justify-content: center;
    border-bottom: 3px solid #e8e8e8;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    left: ${({ activeTab }) => {
      if (activeTab === '1') return '0px !important';
      if (activeTab === '2') return '133px !important';
      if (activeTab === '3') return '266px !important';
    }};
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-table {
    border: 1px solid ${({ theme }) => theme.colors.neutral50};
    overflow: auto;
  }
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  background: ${({ theme }) => theme.colors.neutralLightNew};
  box-shadow: ${({ theme }) => theme.shadows.elevation1};
  border-radius: 8px;
  display: flex;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: background-color 300ms linear, color 300ms linear,
    opacity 300ms linear;
  :active {
    opacity: 0.9;
    background-color: ${({ theme }) => theme.colors.neutralMain};
    transform: translateY(1px) scale(0.99);
  }
`;

export const ActivationButton = styled.button`
  display: flex;
  background: none;
  border: none;
  color: #051730;
  justify-content: center;
  align-items: center;
`;

export const LearnerNameColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  span {
    color: ${({ theme }) => theme.colors['neutralMain']};
    font-weight: bold;
    font-size: 1rem;
  }
`;

export const Button = styled(AntdButton)`
  padding: 0;
`;

export const Tag = styled(AntdTag)`
  background-color: ${({ bgColor, theme }) => theme.colors[bgColor] ?? bgColor};
  color: ${({ fontColor, theme }) =>
    theme.colors[fontColor] || theme.colors['white']};
  border-radius: 24px;
  font-size: 1rem;
  font-weight: 700;
  padding: 2px 8px;
`;

export const DBSCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.neutralMain};
    border-color: ${({ theme }) => theme.colors.neutralMain};
  }
`;

export const NameColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100px;

  span {
    color: ${({ theme }) => theme.colors['neutralMain']};
    font-weight: bold;
    font-size: 1rem;
  }
`;

export const TabsWrapper = styled.div`
  .ant-tabs-ink-bar {
    background: ${({ theme }) => theme.colors.primaryMain};
    height: 3px !important;
  }
  .ant-tabs-tab {
    margin: 0 !important;
    min-width: 133px;
    justify-content: center;
    border-bottom: 3px solid #e8e8e8;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    left: ${({ activeTab }) => (activeTab - 1) * 133}px !important;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-table {
    border: 1px solid ${({ theme }) => theme.colors.neutral50};
    overflow: auto;
  }
`;

export const TableOptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
`;

export const TableTitleOptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  ${({ theme }) => theme.media.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SearchInputWrapper = styled.div``;

export const StatsWrapper = styled.div`
  margin-bottom: 32px;
`;

export const TableTitleDescriptionWrapper = styled.div``;

export const TableButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;
  gap: 16px;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: stretch;
  }
`;
