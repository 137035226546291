import { fields, createSchema, validate as _validate } from '..';

const {
  organisationName,
  email,
  password,
  firstName,
  lastName,
  agreedOnTerms,
  requiredText,
} = fields;

const organisationCreateAccount = createSchema({
  organisationName,
  email,
  firstName,
  lastName,
  password,
  agreedOnTerms,
});
const organisationCreateAccountOld = createSchema({
  organisationName,
  email,
  firstName,
  lastName,
  password: requiredText,
  agreedOnTerms,
});

const validate = (data) => {
  if (data.alreadyRegistered) {
    return _validate(organisationCreateAccountOld, data);
  }
  return _validate(organisationCreateAccount, data);
};

export default validate;
