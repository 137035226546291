import React, { useCallback } from 'react';
import * as S from './Profile/style';
import * as T from 'components/Typography';
import { BasicButton } from 'components/Button';
import Image from 'components/Image';
import { Divider } from 'components/Divider';
import { Icon, Loading } from 'components';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCoachDetails } from 'api-calls/coaches.queries';
import { useOrganisationDetails } from 'context/organisation-details';
import ViewAllNotes from './ViewAllNotes';

const AllNotesPage = () => {
  const { coachId } = useParams();
  const navigate = useNavigate();
  const { coach, isLoading } = useGetCoachDetails({
    coachId,
  });
  const { organisationDetails } = useOrganisationDetails();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <S.PageWrapper>
      <S.CoverWrapper>
        <S.CoverBackButtonWrapper>
          <BasicButton
            handleClick={goBack}
            variant="tertiary"
            icon={'leftChevron'}
          >
            Back
          </BasicButton>
        </S.CoverBackButtonWrapper>
        <S.CoverBackgroundWrapper>
          <Image image="profileCover" width="100%" height="100%" />
        </S.CoverBackgroundWrapper>
      </S.CoverWrapper>
      <S.Content>
        <S.DetailsWrapper>
          <S.AvatarNameWrapper>
            <S.AvatarWrapper>
              <Icon
                icon="openedBook"
                width="32px"
                height="32px"
                color="white"
              />
              <S.FadedIcon>
                <Icon
                  icon="openedBook"
                  width="84px"
                  height="84px"
                  color="white"
                />
              </S.FadedIcon>
            </S.AvatarWrapper>
            <S.NameWrapper>
              <T.H1>{coach?.fullName}</T.H1>
              <T.P size="med">{organisationDetails?.name}</T.P>
            </S.NameWrapper>
          </S.AvatarNameWrapper>
          {/* <BasicButton
            // handleClick={reassignCoach}
            maxWidth="220px"
            variant="tertiary"
            icon={'profile'}
            iconProps={{
              width: '20px',
              height: '25px',
            }}
          >
            Assign a learner
          </BasicButton> */}
        </S.DetailsWrapper>
        <Divider my="6" />
        <ViewAllNotes />
      </S.Content>
    </S.PageWrapper>
  );
};

export default AllNotesPage;
