import { Button } from '@mui/material';
import Icon from 'components/Icon';
import theme from 'theme';

const ReportBtn = ({ ...props }) => (
  <Button
    variant="contained"
    startIcon={<Icon icon="warningFilled" width="24px" height="24px" />}
    sx={{
      width: '90px',
      height: { md: '40px', sm: '60px', xs: '60px' },
      padding: '8px',
      backgroundColor: theme.colors.primaryLight,
      color: theme.colors.neutral80,
      fontSize: '14px',
      lineHeight: '150%',
      borderRadius: '16px',
      textTransform: 'capitalize',
      '& .MuiButton-startIcon': {
        marginRight: '4px',
      },
      '&:hover': {
        backgroundColor: theme.colors.primary30,
      },
      display: {
        xs: 'flex',
      },
      alignItems: {
        xs: 'center',
      },
      flexDirection: {
        xs: 'column',
        md: 'row',
      },
    }}
    {...props}
  >
    Report
  </Button>
);

export default ReportBtn;
