const getMediaUrl = (key, isAudio, voice = 'MALE') => {
  const awsBaseUrl = process.env.REACT_APP_AWS_BASE_URL?.replace(/\/+$/, '');

  if (!key) return undefined;
  const mediaUrl = isAudio
    ? `${awsBaseUrl}/AUDIO/${voice}${key}`
    : `${awsBaseUrl}/IMAGE${key}`;

  return mediaUrl;
};

export default getMediaUrl;
