import React from 'react';

const RightProgressToLeftStep = ({ width, height, color, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '307'}
      height={height || '332'}
      fill="none"
      viewBox="0 0 307 332"
    >
      <path
        stroke={color || '#050D30'}
        stroke-dasharray="13 13"
        stroke-linecap="round"
        stroke-width="4"
        d="M304.5 2s0 55.5-36.456 70.5c-66.544 23-127.66 20.969-172.893 42C-5.763 157.5 8.14 324.091 2 329.5"
      />
    </svg>
  );
};

export default RightProgressToLeftStep;
