const Link = ({ width, height, color, ...props }) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3334 10.0016V15.3348H4.66682V4.66828H10.0001V3.60156H4.66682C4.08026 3.60156 3.6001 4.08156 3.6001 4.66828V15.335C3.6001 15.9214 4.08026 16.4016 4.66682 16.4016H15.3334C15.9199 16.4016 16.4001 15.9214 16.4001 15.3348V10.0016H15.3334Z"
      fill={color}
      stroke={color}
      stroke-width="0.5"
    />
    <path
      d="M16.4 7.86828L16.3995 4.13468C16.4 3.84044 16.161 3.60156 15.8667 3.60156H12.1333V4.66796H14.5786L10 9.24716L10.7542 10.0016L15.3333 5.42316V7.86812L16.4 7.86828Z"
      fill={color}
      stroke={color}
      stroke-width="0.5"
    />
  </svg>
);
export default Link;
