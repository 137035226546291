import styled from '@emotion/styled';
import { BasicButton } from '../../../components/Button';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 920px;
`;

export const Card = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.quaternaryLight};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacings[6]}`};
  margin-top: ${({ theme }) => `${theme.spacings[6]}`};
  border-width: ${({ theme, border }) => border && '1px'};
  border-color: ${({ theme, border }) => border && `${theme.colors.neutral50}`};
  border-style: ${({ border }) => (border ? 'solid' : 'none')};
  border-radius: ${({ theme, clear }) => !clear && theme.spacings[2]};
`;

export const Button = styled(BasicButton)`
  max-width: 300px;
`;
