import { useEffect, useRef, useState } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput } from '../../../../components/Inputs';
import { number, array, object, string } from 'yup';
import mealsData from '../../../../pages/Exercises/ExerciseStep/Comprehensions/Recipes/meals-data';
import { TreeSelect } from 'antd';
import { MediaKeyInput } from '../../../components/MediaKeyInput';
import { mediaTypes } from '../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';

const { TreeNode } = TreeSelect;

const initialData = {
  correctOptions: [{ id: 0, option: '' }],
  textAudio: '',
  textAudioKey: '',
};

const SelectRecipes = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateDataFields } = useStepForm(initialRef.current);
  const { data, validationErrs } = state;
  const [correctOptions, setCorrectOptions] = useState(
    data?.correctOptions?.map((e, i) => ({
      ...e,
      id: i,
    })) || [{ id: 0, option: '' }]
  );
  const [treeSelectValue, setTreeSelectValue] = useState(
    data ? data.correctOptions.map((e, i) => e.option) : []
  );

  useEffect(() => {
    updateDataFields({
      correctOptions: correctOptions?.filter((e) => e.option),
    });
  }, [correctOptions, updateDataFields]);

  const onChange2 = (newValue) => {
    setCorrectOptions(
      newValue.map((e) => {
        return { id: e, option: e };
      })
    );

    setTreeSelectValue(newValue);
  };

  return (
    <div>
      <BasicInput
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        label="Top Question text"
        error={validationErrs.textAudio}
        m={{ mt: 8 }}
      />

      <MediaKeyInput
        label="Top Question text Audio"
        type={mediaTypes.TEXT_AUDIO}
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs.textAudioKey}
      />

      <T.P mb={2} mt={8} weight="bold">
        Correct Options
      </T.P>
      <TreeSelect
        showSearch
        style={{
          width: '100%',
        }}
        value={treeSelectValue}
        dropdownStyle={{
          maxHeight: 400,
          overflow: 'auto',
        }}
        placeholder="Please select"
        allowClear
        multiple
        treeDefaultExpandAll
        onChange={onChange2}
      >
        {mealsData.map((day) => (
          <TreeNode
            value={day.title}
            title={day.title}
            key={day.title}
            selectable={false}
          >
            {day.meals.map((meal) => (
              <TreeNode
                value={meal.title}
                title={meal.title}
                key={meal.title + day.title}
                selectable={false}
              >
                {meal.recipes.map((recipe) => (
                  <TreeNode
                    value={recipe.id}
                    title={recipe.title}
                    key={recipe.id + day.title}
                  >
                    {recipe.title}
                  </TreeNode>
                ))}
              </TreeNode>
            ))}
          </TreeNode>
        ))}
      </TreeSelect>

      {validationErrs?.correctOptions &&
        typeof validationErrs.correctOptions === 'string' && (
          <T.P mb={2} mt={2} color="error">
            {validationErrs.correctOptions}
          </T.P>
        )}
    </div>
  );
};

const validationSchema = {
  correctOptions: array()
    .of(
      object()
        .shape({
          id: number().required('required field'),
        })
        .required('required field')
    )
    .required('You must provide options')
    .min(1, 'You must provide options'),

  textAudio: string().notRequired(),
  textAudioKey: string().notRequired(),
};
export { validationSchema };

export default SelectRecipes;
