import { audio } from '../../../constants';

export const defaultGoals = {
  'Read a book to my child': {
    title: 'Read a book to my child',
    audio: audio.readBookToMyChild,
  },
  'Read a newspaper': {
    title: 'Read a newspaper',
    audio: audio.readANewspaper,
  },
  'Fill in a form': {
    title: 'Fill in a form',
    audio: audio.fillInAForm,
  },
  'Read a letter from my family': {
    title: 'Read a letter from my family',
    audio: audio.readALetterFromMyFamily,
  },
  'Read paperwork': {
    title: 'Read paperwork',
    audio: audio.readPaperwork,
  },
  'Build my confidence': {
    title: 'Build my confidence',
    audio: audio.buildMyConfidence,
  },
  'Gain a job': { title: 'Gain a job', audio: audio.gainAJob },
  'Continue learning': {
    title: 'Continue learning',
    audio: audio.continueLearning,
  },
  'Write a text message': {
    title: 'Write a text message',
    audio: audio.writeATextMessage,
  },
};
