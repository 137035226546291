import * as T from '../../../components/Typography';
import * as S from './style';
import { Textarea } from '../../../components/Inputs';
import { BasicButton } from '../../../components/Button';
import Modal from './Modal';
import CoachesInvitesTable from './CoachesInvitesTable';
import useInviteCoaches from './useInviteCoaches';

const AddCoachesCard = () => {
  const {
    addresses,
    setAddresses,
    visible,
    coachesInvites,
    handleSubmit,
    validationErr,
    httpError,
    isError,
    isLoading,
    setVisible,
    reset,
  } = useInviteCoaches({
    inviteType: 'signup',
  });

  return (
    <>
      <S.CardWrapper>
        <T.H2 color="neutralMain" mb="4">
          Add coaches manually
        </T.H2>
        <T.P mb="5">
          To manually add coaches you have already pre-approved, enter their
          email addresses below, separated by commas. Each email will then
          receive a unique link to create their coach account on your platform.
        </T.P>
        <Textarea
          label={`Coach email addresses`}
          placeholder="Type or paste here..."
          value={addresses}
          handleChange={setAddresses}
          m={{ mt: 5 }}
          error={validationErr}
        />
        {isError && (
          <T.P color="error" mt="2" mb="-20px">
            {httpError?.message || 'Something went wrong'}
          </T.P>
        )}
        <BasicButton mt="20px" onClick={handleSubmit} loading={isLoading}>
          <T.P color="white" weight="semi">
            Submit
          </T.P>
        </BasicButton>
      </S.CardWrapper>
      <Modal visible={visible}>
        <CoachesInvitesTable invites={coachesInvites} />
        <S.ButtonWrapper>
          <BasicButton
            handleClick={() => {
              reset();
              setVisible(false);
            }}
            variant="secondary"
            maxWidth="280px"
          >
            <T.P color="neutralSurface" weight="bold">
              Back
            </T.P>
          </BasicButton>
        </S.ButtonWrapper>
      </Modal>
    </>
  );
};

export default AddCoachesCard;
