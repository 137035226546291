import React from 'react';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from './utils';
import * as S from './style';
import Icon from 'components/Icon';

const DragHandle = sortableHandle(() => (
  <Icon
    icon="sixDots"
    width={26}
    height={26}
    color="neutral80"
    mt={4}
    mr={4}
    pointer
  />
));

const SortableItem = sortableElement(({ value, renderItem, questionIndex }) => (
  <S.DnDItemWrapper>
    <DragHandle />
    {renderItem({
      question: value,
      questionIndex,
    })}
  </S.DnDItemWrapper>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <ul>{children}</ul>;
});

const DnD = ({ items, setItems, renderItem }) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMoveImmutable(items, oldIndex, newIndex));
  };

  return (
    <SortableContainer onSortEnd={onSortEnd} useDragHandle>
      {items.map((item, index) => (
        <SortableItem
          key={`item-${item.id}`}
          index={index}
          value={item}
          questionIndex={index}
          renderItem={renderItem}
        />
      ))}
    </SortableContainer>
  );
};

export default DnD;
