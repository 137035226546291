import styled from '@emotion/styled';
import MuiModal from '@mui/material/Modal';

export const Modal = styled(MuiModal)`
  overflow: auto;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  max-width: 908px;
  width: 100%;
  padding: ${({ theme }) => theme.spacings[6]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  margin: 0 auto;
  position: relative;
  ${({ theme }) => theme.media.tablet} {
    width: 95%;
    padding: ${({ theme }) => theme.spacings[4]};
  }

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;

export const CoverWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

export const Content = styled.div`
  margin: ${({ theme }) => `${theme.spacings[6]} 0 0 ${theme.spacings[5]}`};
`;
