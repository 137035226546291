import { useMemo, useRef } from 'react';

import * as T from '../../../../../components/Typography';
import { BasicInput, Dropdown } from '../../../../../components/Inputs';
import { Checkbox } from 'antd';
import * as S from '../style';
import MDEditor from '../../../../../components/MDEditor';
import { Icon } from '../../../../../components';
import { string, number, boolean, array, object } from 'yup';
import { MediaKeyInput } from '../../../../components/MediaKeyInput';
import { convertSnakeCaseToSpaces } from 'helpers';
import {
  multipleChoiceVariations,
  mediaTypes,
  mediaInputLabels,
} from '../../../../../constants';
import { generateInitialOptions } from './utils';
import { useStepForm } from 'CMS/Providers/StepFormProvider';

const initialData = {
  options: [{ id: 0, option: '', isCorrect: false, imageKey: '' }],
  title: '',
  imageKey: '',
  audioKey: '',
  variation: multipleChoiceVariations.variations.DEFAULT,
};

const MultipleChoice = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateFormFields, updateDataFields } = useStepForm(
    initialRef.current
  );
  const { form, data, validationErrs } = state;

  const variation = data.variation ?? initialData.variation;

  const options = useMemo(
    () =>
      data?.options?.map((e, i) => ({
        ...e,
        id: i,
      })) || [{ id: 0, option: '', isCorrect: false, imageKey: '' }],
    [data?.options]
  );

  const addOption = () => {
    const maxId = Math.max(...options.map((e) => e.id), 0);
    updateDataFields({
      options: [
        ...options,
        { id: maxId + 1, option: '', hide: false, imageKey: '' },
      ],
    });
  };
  const removeOption = (id) => {
    updateDataFields({
      options: options.filter((e) => e.id !== id),
    });
  };

  const onOptionChange = (value, id) => {
    updateDataFields({
      options: options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  const onImageKeyChange = (value, id) => {
    updateDataFields({
      options: options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, imageKey: value };
      }),
    });
  };
  const onAudioKeyChange = (value, id) => {
    updateDataFields({
      options: options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, audioKey: value };
      }),
    });
  };

  const onIsCorrectChange = (value, id) => {
    updateDataFields({
      options: options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, isCorrect: value };
      }),
    });
  };

  const variationOptions = Object.keys(multipleChoiceVariations.variations).map(
    (e) => ({
      label: convertSnakeCaseToSpaces(e),
      value: e,
    })
  );

  const onVariationChange = (variation) => {
    updateDataFields({
      variation,
      options: generateInitialOptions(variation, data.options),
    });
  };

  // use same logic in ExerciseStep/MultipleChoice/index.js
  const showPlaySound =
    !variation || variation === multipleChoiceVariations.variations.DEFAULT;
  const txtImageAtTop =
    variation === multipleChoiceVariations.variations.TEXT_AND_IMAGE_AT_THE_TOP;
  const txtParagraphAtTop =
    variation ===
    multipleChoiceVariations.variations.TEXT_AND_PARAGRAPH_AT_THE_TOP;

  return (
    <div>
      <Dropdown
        label="Choose a variation"
        options={variationOptions}
        selected={
          variation
            ? {
                value: variation,
                label: convertSnakeCaseToSpaces(variation),
              }
            : null
        }
        handleChange={onVariationChange}
        error={validationErrs?.variation}
      />

      {!txtParagraphAtTop && (
        <MDEditor
          label={'Title'}
          value={data.title}
          onChange={(title) => updateDataFields({ title })}
          mode={'edit'}
          height={80}
          m={{ mt: 8 }}
          error={validationErrs?.title}
        />
      )}
      <BasicInput
        label={`Audio Text`}
        value={data.textAudio}
        handleChange={(textAudio) => {
          updateDataFields({ textAudio });
        }}
        m={{ mt: 8 }}
        error={validationErrs?.textAudio}
      />

      <MediaKeyInput
        label={`Audio Text file Key`}
        type={mediaTypes.TEXT_AUDIO}
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs?.textAudioKey}
      />

      {showPlaySound && (
        <MediaKeyInput
          label={mediaInputLabels.AUDIO_LABEL}
          type={mediaTypes.AUDIO}
          value={form.audioKey}
          handleChange={(audioKey) => {
            updateFormFields({ audioKey });
          }}
          m={{ mt: 8 }}
          error={validationErrs?.audioKey}
          helper='for "Play sound" button'
        />
      )}
      {!txtParagraphAtTop && (
        <MediaKeyInput
          label={mediaInputLabels.IMAGE_LABEL}
          type={mediaTypes.IMAGE}
          value={form.imageKey}
          handleChange={(imageKey) => {
            updateFormFields({ imageKey });
          }}
          m={{ mt: 8 }}
          error={validationErrs?.imageKey}
        />
      )}

      {txtParagraphAtTop && (
        <>
          <MDEditor
            value={data.paragraph}
            onChange={(paragraph) => updateDataFields({ paragraph })}
            mode={'edit'}
            label="Paragraph"
            m={{ mt: 8 }}
            helper="Provide the paragraph"
            error={validationErrs.paragraph}
          />

          <BasicInput
            label="Question"
            value={data.question}
            handleChange={(question) => updateDataFields({ question })}
            m={{ mt: 8 }}
            error={validationErrs?.question}
          />
          <MediaKeyInput
            type={mediaTypes.QUESTION_AUDIO}
            label={`Question file Key`}
            value={data.questionAudioKey}
            handleChange={(questionAudioKey) =>
              updateDataFields({ questionAudioKey })
            }
            m={{ mt: 5 }}
            error={validationErrs?.questionAudioKey}
          />
        </>
      )}

      <T.P mt={8} size="large" weight="bold">
        Options
      </T.P>
      {validationErrs?.options &&
        typeof validationErrs.options === 'string' && (
          <T.P mb={2} color="error">
            {validationErrs.options}
          </T.P>
        )}
      {options.map((e, i) => {
        return (
          <>
            <MDEditor
              label={`Option ${i + 1} text`}
              value={e.option}
              onChange={(value) => {
                onOptionChange(value, e.id);
              }}
              mode={'edit'}
              height={80}
              m={{ mt: 8 }}
              error={
                validationErrs?.options && validationErrs?.options[i]?.option
              }
            />

            <Checkbox
              checked={e.isCorrect}
              onChange={(value) =>
                onIsCorrectChange(value.target.checked, e.id)
              }
              disabled={!e.option && !e.imageKey}
            >
              correct option?
            </Checkbox>

            {!txtImageAtTop && (
              <MediaKeyInput
                index={i}
                type={mediaTypes.OPTION_IMAGE}
                label={`Option ${i + 1} ${mediaInputLabels.IMAGE_LABEL}`}
                value={e.imageKey}
                handleChange={(value) => {
                  onImageKeyChange(value, e.id);
                }}
                m={{ mt: 4 }}
                error={validationErrs?.options?.[i]?.imageKey}
              />
            )}
            <MediaKeyInput
              index={i}
              type={mediaTypes.OPTION_AUDIO}
              label={`Option ${i + 1} ${mediaInputLabels.AUDIO_LABEL}`}
              value={e.audioKey}
              handleChange={(value) => {
                onAudioKeyChange(value, e.id);
              }}
              m={{ mt: 4 }}
              error={validationErrs?.options?.[i]?.audioKey}
            />
            {options.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeOption(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}
      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addOption}
        disabled={options.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Option
      </S.Button>
    </div>
  );
};

const validationSchema = {
  options: array()
    .of(
      object()
        .shape({
          id: number().notRequired(),
          option: string().test(
            'textOrImage',
            'Text or Image should be provided',
            (val, { parent }) => {
              return val || parent.imageKey;
            }
          ),
          imageKey: string().test(
            'textOrImage',
            'Text or Image should be provided',
            (val, { parent }) => {
              return val || parent.option;
            }
          ),
          audioKey: string().notRequired(),
          isCorrect: boolean(),
        })
        .required()
    )
    .required('you must provide options')
    .min(1, 'you must provide options')
    .test('oneCorrect', 'should select one correct answer', (val) => {
      return val?.filter((e) => e.isCorrect)?.length === 1;
    }),

  title: string().optional().max(15, 'title must be less than 15 characters'),
  textAudio: string().notRequired(),
  paragraph: string().when('variation', {
    is: (variation) =>
      variation ===
      multipleChoiceVariations.variations.TEXT_AND_PARAGRAPH_AT_THE_TOP,
    then: string().required('Paragraph is a required field'),
    otherwise: string().notRequired(),
  }),
  // textAudioKey: string().when('textAudio', {
  //   is: (textAudio) => !!textAudio,
  //   then: string().required('required field'),
  //   otherwise: string().notRequired(),
  // }),
};
export { validationSchema };

export default MultipleChoice;
