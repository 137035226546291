import styled from '@emotion/styled';
import { setMargin } from '../../helpers';

const decideBgColor = (manualNumber) => {
  switch (manualNumber) {
    case 1:
      return 'secondaryMain';

    case 2:
      return 'primaryMain';

    case 3:
      return 'tertiaryMain';

    case 4:
      return 'quinaryMain';

    case 5:
      return 'quaternaryMain';

    default:
      break;
  }
};

export const Wrapper = styled.div`
  ${setMargin}
  display: flex;
`;

export const ManualWrapper = styled.div`
  ${setMargin}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  height: 80px;
  background: ${({ theme, manualNumber }) =>
    theme.colors[decideBgColor(manualNumber)]};
  border-radius: 16px;
`;
