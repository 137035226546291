import { string, array, object, number, boolean } from 'yup';

export const validationSchema = {
  textAudio: string().required('required field'),
  textAudioKey: string().notRequired(),
  questionTitle: string().optional(),
  rowsTitle: string().optional(),
  rows: array()
    .of(
      object().shape({
        id: number().required(),
        text: string().required('required field'),
        isCorrect: boolean().required('required field'),
      })
    )
    .min(1, 'at add least 1 row')
    .max(10, `maximum of 10 rows allowed`),
};
