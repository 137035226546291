import { useState, useEffect } from 'react';

import * as T from '../../../components/Typography';
import * as S from './style';
import { GENERAL, COMMON } from '../../../constants/nav-routes';
import Icon from '../../../components/Icon';
import { BasicButton } from '../../../components/Button';
import { BasicInput } from '../../../components/Inputs';

import useInviteCoaches from './useInviteCoaches';
import SuccessModal from './SuccessModal';

const NeedHelpCard = ({ uniqueSlug }) => {
  const [copied, setCopied] = useState(false);
  const {
    addresses,
    setAddresses,
    visible,
    setVisible,
    handleSubmit,
    validationErr,
    httpError,
    isError,
    isLoading,
  } = useInviteCoaches({ inviteType: 'recruitment' });
  const page = `${GENERAL.INTEREST.replace(':uniqueSlug', uniqueSlug)}`;
  const previewPage = `${page}/?preview=true`;
  const textToCopy = `${window.location.origin}${page}`;

  const copyCodeToClipboard = async () => {
    setCopied(false);
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to copy text: ', err);
    }
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 750);
    }
  }, [copied]);

  return (
    <>
      <S.CardWrapper>
        <T.H2 color="neutralMain" mb="4">
          Recruit coaches section
        </T.H2>
        <T.P mb="5">
          Here you can edit the recruitment form you can add with the tool as if
          you were a learner. Preview every exercise and more!
        </T.P>
        <S.RecruitmentFormWrapper>
          <S.LinkWrapper>
            <T.P underline color="neutralMain">
              Recruitment form
            </T.P>
            <S.IconsWrapper>
              <Icon
                icon={copied ? 'circleTick' : 'copy'}
                width="20px"
                height="20px"
                color={copied ? 'green' : 'neutralMain'}
                onClick={copyCodeToClipboard}
              />
              <a href={previewPage} target="_blank" rel="noopener noreferrer">
                <Icon
                  icon="link"
                  width="20px"
                  height="20px"
                  color="neutralMain"
                />
              </a>
            </S.IconsWrapper>
          </S.LinkWrapper>
          <S.LinkTextWrapper>
            <T.P
              color="primaryMain"
              ellipsis={{
                rows: 1,
              }}
            >
              {textToCopy}
            </T.P>
          </S.LinkTextWrapper>
          <BasicButton
            variant="secondary"
            borderRadius="8px"
            padding="12px"
            height="48px"
            to={COMMON.EDIT_ORGANISATION_RECRUITMENT_FORM}
          >
            Edit Recruitment form
          </BasicButton>
        </S.RecruitmentFormWrapper>
        <BasicInput
          label="Coach email addresses"
          placeholder="Type or paste here..."
          value={addresses}
          handleChange={setAddresses}
          m={{ mt: 5 }}
          error={validationErr}
        />
        <T.P color="neutral80" mt={1}>
          *This email is to invite the coach to fill out the recruitment form.
        </T.P>
        {isError && (
          <T.P color="error" mt="2" mb="-20px">
            {httpError?.message || 'Something went wrong'}
          </T.P>
        )}
        <BasicButton mt="20px" onClick={handleSubmit} loading={isLoading}>
          <T.P color="white" weight="semi">
            Send invitation
          </T.P>
        </BasicButton>
      </S.CardWrapper>
      <SuccessModal
        visible={visible}
        setVisible={setVisible}
        buttonLabel={'Done!'}
        onClickButton={() => setVisible(false)}
      />
    </>
  );
};

export default NeedHelpCard;
