import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as T from '../../../components/Typography';
import * as S from './style';
import LoaderIcon from './LoaderIcon';
import { COACH, FACILITATOR } from '../../../constants/nav-routes';
import Quote from '../../../components/Quote';
import data from './data';
import { userRoles } from 'constants/data-types';

const SplashScreen = () => {
  const [progress, setProgress] = useState(0);
  const [seenSplash] = useState(
    JSON.parse(sessionStorage.getItem('SEEN_SPLASH'))
  );
  const location = useLocation();
  const { role } = location.state || {};

  const navigate = useNavigate();

  let dashboardLink = COACH.DASHBOARD;

  if (role === userRoles.COACH) {
    dashboardLink = COACH.DASHBOARD;
  } else if (role === userRoles.FACILITATOR) {
    dashboardLink = FACILITATOR.DASHBOARD;
  }

  useEffect(() => {
    if (seenSplash) {
      navigate(dashboardLink);
    }
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          navigate(dashboardLink, {
            replace: true,
          });
          return 100;
        }
        const diff = Math.random() * 20;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, [dashboardLink, navigate, seenSplash]);

  if (seenSplash) {
    return null;
  } else {
    sessionStorage.setItem('SEEN_SPLASH', JSON.stringify(true));
  }

  return (
    <S.PageWrapper>
      <LoaderIcon />
      <T.H2 color="neutralMain" mt={5} mb={11}>
        Loading your dashboard...
      </T.H2>
      <div
        style={{
          width: '100%',
        }}
      >
        <S.BorderLinearProgress variant="determinate" value={progress} />
      </div>

      <S.CarouselSection>
        <Quote data={data} />
      </S.CarouselSection>
    </S.PageWrapper>
  );
};
export default SplashScreen;
