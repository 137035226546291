import theme from '../../../theme';

const Edit = ({ width, height, color, ...props }) => (
  <svg
    width={width || '25'}
    height={height || '25'}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.9905 9.14214V16.875C19.9905 18.6009 18.5914 20 16.8655 20H8.11548C6.38959 20 4.99048 18.6009 4.99048 16.875V8.125C4.99048 6.39911 6.38959 5 8.11548 5H15.6473L11.5612 9.08614C11.2917 9.35561 11.1349 9.71755 11.1226 10.0984L11.0519 12.2905C11.024 13.1565 11.7335 13.866 12.5995 13.8381L14.7915 13.7673C15.1724 13.7551 15.5344 13.5983 15.8038 13.3288L19.9905 9.14214V9.14214Z"
      fill={color || theme.colors.neutralSurface}
    />
    <path
      d="M20.6828 4.20712C19.9017 3.42608 18.6354 3.42608 17.8543 4.20712L12.2682 9.79327C12.1784 9.88309 12.1261 10.0037 12.122 10.1307L12.0513 12.3227C12.042 12.6114 12.2785 12.8479 12.5672 12.8386L14.7592 12.7679C14.8861 12.7638 15.0068 12.7115 15.0966 12.6217L20.6828 7.03555C21.4638 6.2545 21.4638 4.98817 20.6828 4.20712Z"
      fill={color || theme.colors.neutralSurface}
    />
  </svg>
);

export default Edit;
