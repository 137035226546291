import Icon from '../Icon';
import Markdown from '../../components/Markdown';
import * as S from './style';
import { UseAudio } from 'Hooks';

const AudioIn = ({
  icon = 'speaker',
  iconProps,
  handleClick = () => {},
  disabled,
  audioUrl,
  innerText,
  padding,
  hidden,
  showStrongUnderlined,
  fallbackText,
  ...props
}) => {
  const { isLoading, fallbackDisabled, handleAudioClick } = UseAudio({
    audioUrl,
    handleClick,
    fallbackText: typeof fallbackText === 'string' && fallbackText,
  });

  const audioDisabled = disabled || isLoading || fallbackDisabled;

  return (
    <S.Container {...props}>
      <S.PlayButton
        audioUrl={audioUrl}
        disabled={audioDisabled}
        onClick={handleAudioClick}
        aria-label="Play sound"
        innerText={innerText}
      >
        {icon && !hidden && (
          <Icon
            icon={icon}
            disabled={audioDisabled}
            width="32px"
            height="32px"
          />
        )}
        {innerText && (
          <S.InnerPlayText ml={3}>
            <Markdown
              text={innerText}
              customStyles={{
                p: { size: 'med', color: 'neutralMain' },
              }}
              showStrongUnderlined={showStrongUnderlined}
            />
          </S.InnerPlayText>
        )}
      </S.PlayButton>
    </S.Container>
  );
};

export default AudioIn;
