import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TryAgainModal from '../../../../components/ModalNew/TryAgainModal';
import { PlayButton } from '../../../../components/Button';
import { Markdown, Typography as T } from '../../../../components';
import StepFooterButtons from '../StepFooterButtons';

import * as S from './style';
import * as CS from '../shared/style';

const getInitialAnswers = (options) => {
  const _answers = {};
  options?.forEach((option) => {
    _answers[option.id] = option.showValue ? option.option : '';
  });

  return _answers;
};

const ColumnsTyping = ({
  data: { textAudio, textAudioUrl, rowSize, columnSize, headers, options } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
}) => {
  const [showTryAgain, setShowTryAgain] = useState(false);
  const [answers, setAnswers] = useState(() => getInitialAnswers(options));

  const location = useLocation();
  useEffect(() => {
    if (options?.length) {
      setAnswers(getInitialAnswers(options));
    }

    // To avoid unnecessary re-renders we only want this to work when the pathname changes,
    // if react-query still refetching step is handled in the parent (isStepLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleClick = () => {
    const allCorrect = options?.every(
      (option) => answers[option.id] === option.option
    );

    if (allCorrect) {
      handleNext();
    } else {
      setShowTryAgain(true);
    }
  };

  const showHeaders = headers?.some((header) => !!header);
  const showSquares = rowSize >= 1 && columnSize >= 1 && columnSize <= 3;
  const rows = useMemo(() => {
    if (!showSquares) {
      return [];
    }
    const _options = options || [];
    const result = [];
    let index = 0;
    let rowLength = Number(columnSize);

    while (index < _options.length) {
      result.push(_options.slice(index, index + rowLength));
      index += rowLength;
    }

    return result;
  }, [options, showSquares, columnSize]);

  const isAllInputsFilled = Object.values(answers).every((value) => !!value);

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        {!!textAudio && (
          <CS.TextAudioWrapper mt="2" mb="4">
            {textAudioUrl ? (
              <>
                <PlayButton
                  audioUrl={textAudioUrl}
                  width="56px"
                  height="56px"
                  iconProps={{ width: '32px', height: '32px' }}
                  fallbackText={textAudio}
                />
                <div>
                  <Markdown
                    text={textAudio}
                    customStyles={{
                      p: { size: 'med', ml: '3' },
                    }}
                  />
                </div>
              </>
            ) : (
              <div>
                <Markdown
                  text={textAudio}
                  customStyles={{
                    p: { size: 'med' },
                  }}
                />
              </div>
            )}
          </CS.TextAudioWrapper>
        )}
        {showSquares && (
          <S.PreFilledAnswersGridWrapper>
            {showHeaders && (
              <S.RowWrapper>
                {headers.map((header, index) => (
                  <T.P key={index} size="med" weight="bold" ta="center">
                    {header}
                  </T.P>
                ))}
              </S.RowWrapper>
            )}
            {rows.map((rowOptions, rowIndex) => (
              <S.RowWrapper key={rowIndex}>
                {rowOptions.map((option) => {
                  const id = option.id;
                  const value = answers[id];
                  const isCorrect = value === option.option;
                  return (
                    <S.TypeInput
                      key={id}
                      id={`answer-${id}`}
                      name={`answer-${id}`}
                      type="text"
                      aria-labelledby={`answer-${id}`}
                      rowSize={rowSize}
                      showValue={option.showValue}
                      value={value}
                      disabled={option.showValue}
                      onChange={(e) => {
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          [option.id]: e.target.value,
                        }));
                      }}
                      isCorrect={isCorrect}
                    />
                  );
                })}
              </S.RowWrapper>
            ))}
          </S.PreFilledAnswersGridWrapper>
        )}
      </S.ContentWrapper>

      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleClick}
        preview={preview}
        disabled={disabled}
        enableNext={isAllInputsFilled}
      />
      <TryAgainModal
        aria-modal="true"
        isModalVisible={showTryAgain}
        setIsModalVisible={setShowTryAgain}
      />
    </S.Wrapper>
  );
};

export default ColumnsTyping;
