const buttonsData = {
  1: {
    id: 1,
    icon: 'face1',
    value: 'Very much',
  },
  2: {
    id: 2,
    icon: 'face2',
    value: 'Yes, I am',
  },
  3: {
    id: 3,
    icon: 'face3',
    value: 'Not really',
  },
  4: {
    id: 4,
    icon: 'face4',
    value: 'No, I’m not',
  },
};
export default buttonsData;
