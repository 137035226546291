import { useState, useEffect, useRef, Fragment } from 'react';
import {
  Grid,
  Typography as T,
  Inputs as I,
  Icon,
  Video,
  Loading,
  ProgressCard,
} from '../../../components';
import * as S from './style';
import { useAuth } from '../../../context/auth';
import { BasicButton } from 'components/Button';
import { useUpdateTrainingStep } from '../../../api-calls/facilitator.queries';
import { useParams, useNavigate } from 'react-router-dom';
import { navRoutes } from '../../../constants';
import { BackButton } from 'components/BackButton';
import { MultiDropDown } from 'components/Inputs';
import { Divider } from 'components/Divider';
import useFacilitatorCompletedTrainingStep from 'pages/Facilitator/Training/Hooks/useFacilitatorCompletedTrainingStep';
import useFacilitatorTrainingData from './Hooks/useFacilitatorTrainingData';
const { Row, Col } = Grid;

const TickList = ({ text }) => {
  return (
    <Row mt="4">
      <Col w={[4, 1, 0.5]}>
        <Icon icon="tick" color="neutralMain" mt="1" />
      </Col>
      <Col w={[4, 11, 11]}>
        <Row inner>
          <T.P>{text}</T.P>
        </Row>
      </Col>
    </Row>
  );
};

const NumberedList = ({ text, index }) => {
  return (
    <Row mt="1">
      <Col w={[4, 1, 0.5]}>
        <T.P>{index}</T.P>
      </Col>
      <Col w={[4, 11, 11]}>
        <Row inner>
          <T.P>{text}</T.P>
        </Row>
      </Col>
    </Row>
  );
};

const DosDonts = ({ text, index }) => {
  return (
    <Row mt="1">
      <Col w={[4, 1, 0.5]}>
        <T.P>{index}</T.P>
      </Col>
      <Col w={[4, 11, 11]}>
        <Row inner>
          <T.P>{text}</T.P>
        </Row>
      </Col>
    </Row>
  );
};

const BASE_URL = `/facilitator/training/step`;

const TrainingDetailPage = () => {
  const {
    user: { id },
  } = useAuth();
  const { stepId } = useParams();
  const navigate = useNavigate();
  const [complete, setComplete] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const { trainingData, trainingContent } = useFacilitatorTrainingData();

  const buttonLabel = useRef('Mark this step as complete');
  const isFirstItem = Number(stepId) === 1;
  const isLastItem = Number(stepId) === trainingData.length;

  const { mutateAsync: updateStep } = useUpdateTrainingStep({ id });

  const {
    completedTrainingStep,
    refetchCompletedTrainingStep,
    getCurrentStepLoading,
    isSuccess,
  } = useFacilitatorCompletedTrainingStep();

  useEffect(() => {
    if (isSuccess && completedTrainingStep + 1 < stepId) {
      navigate(navRoutes.FACILITATOR.TRAINING);
    }
    setCurrentStep(completedTrainingStep);
  }, [completedTrainingStep, isSuccess, navigate, stepId]);

  useEffect(() => {
    if (currentStep >= stepId) {
      setComplete(true);
      buttonLabel.current = 'Back to training page';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const goToNextItem = () => {
    if (isLastItem && currentStep === trainingData.length) {
      navigate(navRoutes.FACILITATOR.TRAINING_REVIEW);
      return;
    }

    if (!isLastItem) {
      navigate(`${BASE_URL}/${Number(stepId) + 1}`);
      setComplete(false);
      setCurrentStep((state) => state + 1);
    } else {
      navigate(navRoutes.FACILITATOR.TRAINING_COMPLETE, {
        state: {
          showCongrats: true,
        },
      });
    }
  };

  const updateTrainingStep = async () => {
    await updateStep({
      id,
      step: stepId,
    });
    await refetchCompletedTrainingStep();
    goToNextItem();
  };

  const handleCheckboxClick = () => {
    setComplete(!complete);
  };

  const handleUpdateStep = async () => {
    if (currentStep < stepId) {
      return await updateTrainingStep();
    }
    return goToNextItem();
  };

  const goToPrevItem = (isCompleted) => {
    const item = trainingData[stepId - 1];
    const user = item?.user;
    const routeKey = item?.routeKey;
    const param = item?.param;

    const route = navRoutes[user][routeKey];

    if (!isFirstItem) {
      const nextStepId = Number(stepId) - 1;

      let navRoute = route;
      navRoute = navRoute.replace(param, nextStepId);
      navigate(navRoute);
    }
  };

  const Text = () => {
    const lines = introduction.split('\n').map((line, index) => (
      <Fragment key={index}>
        {line}
        <br />
      </Fragment>
    ));

    return (
      <Col w={[4, 12, 12]} mt="2" mb="4">
        <T.P>{lines}</T.P>
      </Col>
    );
  };

  const [{ title, introduction, videoURLs, items }] = trainingContent?.filter(
    (i) => i.index === Number(stepId)
  );

  const isStepHaveVideo = videoURLs?.length > 0;

  if (getCurrentStepLoading) {
    return <Loading />;
  }

  const renderItems = (items) => {
    return items.map((item) => {
      return (
        <S.ListWrapper type={item.type}>
          <T.H2 weight="bold" color="neutralMain">
            {item.title}
          </T.H2>
          {item.introduction && <T.P>{item.introduction}</T.P>}
          {item.type !== 'Paragraph' &&
            item.list.map((b, i) => {
              switch (item.type) {
                case 'TickList':
                  return <TickList text={b} />;
                case 'NumberedList':
                  return <NumberedList text={b} index={String(i + 1) + '.'} />;
                case 'Dos':
                  return <DosDonts text={b} index={String(i + 1) + '.'} />;
                case 'Donts':
                  return <DosDonts text={b} index={String(i + 1) + '.'} />;
                default:
                  return <TickList text={b} />;
              }
            })}
        </S.ListWrapper>
      );
    });
  };

  const onSelectChange = (step) => {
    const item = trainingData[step];
    const stepId = step + 1;
    const user = item?.user;
    const routeKey = item?.routeKey;
    const param = item?.param;

    const route = navRoutes[user][routeKey];

    let navRoute = route;
    navRoute = navRoute.replace(param, stepId);
    navigate(navRoute);
  };

  return (
    <S.PageWrapper>
      <Row gap="60px 48px" gapT="32px 24px" gapM="0">
        <Col w={[4, 12, 12]} mbM="5">
          <BackButton link={navRoutes.FACILITATOR.DASHBOARD} />
        </Col>
        <Col w={[0, 0, 4]} maxWidth="385px">
          <S.AsideWrapper>
            <T.H1 mb="5">Facilitator Training</T.H1>
            <ProgressCard
              loading={getCurrentStepLoading}
              id={id}
              activeStep={currentStep}
              data={trainingData}
              completionMessage="🎉 Training complete"
              baseURL={BASE_URL}
              isAside
            />
          </S.AsideWrapper>
        </Col>
        <Col w={[4, 12, 0]} maxWidth="385px">
          <T.H1 mb="4">Facilitator Training</T.H1>
          <MultiDropDown
            multiple={false}
            disableCloseOnSelect={false}
            filterSelectedOptions={false}
            disableClearable
            options={trainingData?.map((step, index) => ({
              label: `${index + 1}. ${step.title}}`,
              value: index,
            }))}
            defaultValue={{
              label: trainingData[Number(stepId) - 1]?.title,
              value: Number(stepId) - 1,
            }}
            onChange={(_, { value }) => {
              onSelectChange(value);
            }}
            inputProps={{
              readOnly: true,
            }}
            wrapperProps={{
              answerProps: {
                mt: '0',
              },
            }}
          />
          <Divider />
        </Col>
        <Col w={[4, 12, 8]} maxWidth="767px" flex="1 !important">
          <Row mb="3" inner>
            <Col w={[4, 12, 12]}>
              <T.H1 weight="bold" color="neutralMain">
                {title}
              </T.H1>
            </Col>
            {introduction && Text()}
            {videoURLs?.map((video) => (
              <Col w={[4, 12, 12]} mt="2" mb="5">
                <T.H2>{video?.title}</T.H2>
                <Video src={video?.url} />
              </Col>
            ))}
            {isStepHaveVideo && (
              <Col w={[4, 12, 12]} mt="2" mb="5">
                <I.Checkbox
                  checked={complete || currentStep >= stepId}
                  disabled={currentStep >= stepId}
                  handleChange={handleCheckboxClick}
                  plain
                  label="I confirm I have watched the entire video"
                />
              </Col>
            )}
            <Col w={[4, 12, 12]}>{items && renderItems(items)}</Col>
            <Col
              display="flex"
              dir="row"
              w={[4, 12, 12]}
              mt="4"
              maxWidth="390px"
              noWrap
              noWrapM={false}
            >
              <BasicButton
                disabled={videoURLs && !complete && currentStep + 1 === +stepId}
                variant="primary"
                loading={getCurrentStepLoading}
                handleClick={() => handleUpdateStep()}
                aria-label="Mark this step as complete"
                type="submit"
                id="create-account-button"
                mr="5"
                mrM="0"
                mbM="4"
                maxWidth="fit-content"
                maxWidthM="none"
                icon={isLastItem ? null : 'next'}
                iconProps={{
                  width: '20',
                  height: '12',
                }}
              >
                {isLastItem ? 'Finish training' : 'Next lesson'}
              </BasicButton>
              {!isFirstItem ? (
                <BasicButton
                  variant="tertiary"
                  handleClick={() => goToPrevItem()}
                  aria-label="Back to training page"
                  id="back-to-training-page-button"
                  maxWidth="fit-content"
                  maxWidthM="none"
                  icon={'back'}
                  iconProps={{
                    width: '20',
                    height: '12',
                  }}
                >
                  Previous lesson
                </BasicButton>
              ) : (
                <BasicButton
                  variant="tertiary"
                  handleClick={() =>
                    navigate(navRoutes.FACILITATOR.TRAINING_REVIEW)
                  }
                  aria-label="Back to training page"
                  id="back-to-training-page-button"
                  maxWidth="fit-content"
                  maxWidthM="none"
                >
                  Back to training page
                </BasicButton>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </S.PageWrapper>
  );
};

export default TrainingDetailPage;
