import styled from '@emotion/styled';

export const TableTitleWrapper = styled.div`
  min-height: 72px;
  display: flex;
  align-items: center;
`;

export const CopyLinkWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  div {
    width: auto;
  }
`;

export const SignupDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  button {
    font-weight: bold;
    font-size: 1rem;
    margin-left: -0.5rem;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  .ant-table table {
    font-size: 1rem;
    font-weight: 400;
  }

  .ant-tabs-ink-bar {
    background: ${({ theme }) => theme.colors.primaryMain};
    height: 3px !important;
  }
  .ant-tabs-tab {
    margin: 0 !important;
    min-width: 133px;
    justify-content: center;
    border-bottom: 3px solid #e8e8e8;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    left: ${({ activeTab }) => {
      if (activeTab === '1') return '0px !important';
      if (activeTab === '2') return '133px !important';
      if (activeTab === '3') return '266px !important';
    }};
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-table {
    border: 1px solid ${({ theme }) => theme.colors.neutral50};
    overflow: auto;
  }
`;

export const ActivationButton = styled.button`
  display: flex;
  background: none;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: #051730;
  font-weight: bold;
  align-items: center;
`;

export const EmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings[3]};
`;

export const TableTitleOptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  ${({ theme }) => theme.media.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TableTitleDescriptionWrapper = styled.div``;

export const TableButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const TableOptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
`;

export const SearchInputWrapper = styled.div``;

export const IconWrapper = styled.div`
  cursor: pointer;
  background: ${({ theme }) => theme.colors.neutralLightNew};
  box-shadow: ${({ theme }) => theme.shadows.elevation1};
  border-radius: 8px;
  display: flex;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: background-color 300ms linear, color 300ms linear,
    opacity 300ms linear;
  :active {
    opacity: 0.9;
    background-color: ${({ theme }) => theme.colors.neutralMain};
    transform: translateY(1px) scale(0.99);
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100px;
`;
