import { useParams } from 'react-router-dom';
import { userRoles } from '../../../constants';
import * as S from './style';
import { BackButton } from 'components/BackButton';
import { Chat, Typography as T } from 'components';
import { useGetLearnerUniqueId } from 'api-calls/learners.queries';

export { default as ChatSafeGuard } from './SafeGuard';

const ChatWithLearner = () => {
  const { receiverId } = useParams();
  const { data: learner, isLoading } = useGetLearnerUniqueId({
    learnerUserId: receiverId,
  });

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <BackButton />
        <S.IdWrapper>
          <T.P width="92px">
            ID:{isLoading ? '...' : learner?.learnerUniqueId}
          </T.P>
        </S.IdWrapper>
      </S.HeaderWrapper>
      <S.ChatWrapper>
        <Chat receiverId={receiverId} userType={userRoles.COACH} />
      </S.ChatWrapper>
    </S.Wrapper>
  );
};

export default ChatWithLearner;
