import styled from '@emotion/styled';
import { Editable } from 'slate-react';

export const Placeholder = styled.span`
  color: ${({ theme }) => theme.colors.placeholder};
  opacity: 1 !important;
`;

export const StyledEditable = styled(Editable)`
  height: 78px;
  max-height: 78px;
  overflow-y: auto;
`;

export const Button = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  color: ${({ theme, active, reversed }) =>
    reversed
      ? active
        ? theme.colors['neutral90']
        : theme.colors['neutral60']
      : active
      ? theme.colors['neutral90']
      : theme.colors['neutral60']};
`;

export const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 216px;
`;
