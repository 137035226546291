import { useEffect, createContext, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { useGetStepsByMilestoneId } from '../api-calls/progressChecks.queries';

const initialStepsState = [{}];
const ProgressCheckContext = createContext({
  steps: initialStepsState,
  setSteps: () => {},
});

const ProgressCheckProvider = (props) => {
  const [subProgress, setSubProgress] = useState(0);
  const [progressPercent, setProgressPercent] = useState(0);
  const { milestoneId, stepId } = useParams();
  const { steps = [] } = useGetStepsByMilestoneId({ milestoneId });
  const _progressPercent = (stepId - 1) / steps?.length;

  useEffect(() => {
    setProgressPercent(_progressPercent);
    setSubProgress(0);
  }, [_progressPercent]);

  const value = {
    steps,
    progressPercent: progressPercent + subProgress / steps?.length,
    setSubProgress,
  };
  return <ProgressCheckContext.Provider value={value} {...props} />;
};

const useProgressCheck = () => {
  const value = useContext(ProgressCheckContext);
  return value;
};

export { ProgressCheckProvider, useProgressCheck };
export default ProgressCheckContext;
