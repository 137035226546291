import Icon from 'components/Icon';
import * as T from 'components/Typography';
import * as S from './style';

const QuickAccessCard = ({ text, to, bgColor, icon = 'standingPerson' }) => {
  return (
    <S.Box bgColor={bgColor} to={to}>
      <Icon icon={icon} width="40" height="40" mb="1" color="neutralMain" />
      <S.TextIconWrapper>
        <T.P weight="bold" size="medLarge">
          {text}
        </T.P>
        <Icon pointer={true} icon="chevronRight" width="35" height="35" />
      </S.TextIconWrapper>
    </S.Box>
  );
};

export default QuickAccessCard;
