import styled from '@emotion/styled';

export const EmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings[3]};
`;
