import { Col, Row } from 'components/Grid';
import Image from 'components/Image';
import Modal from 'components/Modal';
import * as T from 'components/Typography';
import * as S from './style';
import React from 'react';
import Icon from 'components/Icon';
import { userRoles } from '../../constants';

export const CompleteTrainingCongratsDialog = ({ visible, onCancel, role }) => {
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      bgColor="white"
      maxWidth="900px"
    >
      <Row jc={'center'} inner>
        <Col w={[4, 12, 12]} jc="flex-end" mb="1">
          <S.CancelButton type="button" onClick={onCancel}>
            <Icon icon="cross" width="12" height="12" />
            Cancel
          </S.CancelButton>
        </Col>
        <Col w={[4, 12, 12]} jc="center">
          <Image image="Congrats" width="300px" height={200} />
        </Col>
        <Col w={[4, 12, 12]} dir="column" jc="center" mt="5">
          <T.H1 weight="bold" color="neutralMain">
            Congratulations!
          </T.H1>
          <T.P color="neutralMain" mt="1" maxWidth="500px" ta="center">
            You have completed the training! Now you are ready to{' '}
            {role === userRoles.COACH
              ? 'take your first learner'
              : 'get started'}
            . Keep up the good work - you've got this!
          </T.P>
        </Col>
        <Col w={[4, 12, 12]} mt="5">
          <S.TestimonialWrapper>
            <T.P color="neutralMain" ta="center" mb="4">
              The first book was basic and good, Shannon Trust gives good
              foundations in reading and English. I’ve spent years in education
              but this has been far more beneficial in a short period of time.
            </T.P>
            <T.P color="neutral60" mt="1" ta="center">
              Shannon Trust learner
            </T.P>
          </S.TestimonialWrapper>
        </Col>
      </Row>
    </Modal>
  );
};
