import { BackButton } from 'components/BackButton';
import * as S from './style';
import SignupExistingForm from './SignupExistingForm';

const SignupExistingAccount = () => {
  return (
    <S.Wrapper>
      <BackButton />

      <SignupExistingForm />
    </S.Wrapper>
  );
};
export default SignupExistingAccount;
