import styled from '@emotion/styled';
import * as T from '../Typography';

const bgColors = {
  dark: 'neutralMain',
  light: 'neutralLight',
  primary: 'primaryMain',
  quaternary: 'quaternaryMainHex',
  progress: 'neutralSurface',
};

export const Card = styled.div`
  min-width: 200px;
  min-height: 205px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: ${({ theme, backgroundMode }) =>
    theme.colors[bgColors[backgroundMode]]};
  ${({ theme }) => theme.media.mobile} {
    padding: ${({ theme }) => theme.spacings[4]};
  }
`;

export const ProgressText = styled(T.P)`
  display: flex;
  text-align: center !important;
  justify-content: center;
  padding-left: ${({ theme }) => theme.spacings[5]};
  padding-right: ${({ theme }) => theme.spacings[5]};
`;

export const StyledLink = styled(T.Link)`
  display: flex;
`;

export const Progress = styled.div`
  .ant-progress-inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: ${({ theme }) => theme.colors.neutralMain};
  }
`;
