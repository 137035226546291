import { useState } from 'react';

import * as T from '../../../../components/Typography';
import { PlayBig } from '../../../../components/Button';

import EmptySpace from '../../../../components/EmptySpace';
import Option from '../../../../components/Option';
import * as S from './style';
import StepFooterButtons from '../StepFooterButtons';

import { showMovingBlock } from '../../../../helpers';

const DragAndDropV1 = ({
  data: { options: _options = [], correctAnswer } = {},
  audioUrl,
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  cmsPreview,
}) => {
  const [answers, setAnswers] = useState(
    correctAnswer.split('').map(() => ({ text: null }))
  );

  const [options, setOptions] = useState(
    _options.map((e, i) => ({
      text: e.option,
      id: i,
    }))
  );

  const [activePosition, setActivePosition] = useState(0);

  const onClickAnswer = (answer, i) => {
    setActivePosition(i);

    if (answer?.text) {
      showMovingBlock({
        OptionElId: `option-${answer.id}`,
        answerElId: `answer-${i}`,
        movingElId: 'moving-div',
        isBeingSelected: true,
        done: () => {
          setOptions((_ops) =>
            _ops.map((e) => {
              if (e.id === answer.id) {
                return { ...e, hidden: false };
              }
              return e;
            })
          );
        },
      });
      setAnswers((_answers) =>
        _answers.map((e, _i) => {
          if (_i === i) {
            return { id: null, text: null };
          }
          return e;
        })
      );
    }
  };

  const onClickOption = (option) => {
    setOptions((_ops) =>
      _ops.map((e) => {
        if (e.id === option.id) {
          return { ...e, hidden: true };
        }
        if (e.id === answers[activePosition].id) {
          return { ...e, hidden: false };
        }
        return e;
      })
    );
    showMovingBlock({
      OptionElId: `option-${option.id}`,
      answerElId: `answer-${activePosition}`,
      movingElId: 'moving-div',
      isBeingSelected: false,
      done: () => {
        setAnswers((_answers) =>
          _answers.map((e, i) => {
            if (i === activePosition) {
              return { ...option };
            }
            return e;
          })
        );
      },
    });

    setActivePosition((_activePosition) => {
      const nextPosition = (_activePosition + 1) % answers.length;
      return nextPosition;
    });
  };

  const onFocusAnswer = (i) => {
    setActivePosition(i);
  };

  const answer = answers.map((e) => e.text).join('');
  if (!_options?.length) {
    return (
      <S.Wrapper>
        {<T.P color="error">Exercise missing required fields </T.P>}
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper>
      {/* keep this to animation */}
      <div style={{ display: 'none' }}>
        <Option id="moving-div"></Option>
      </div>
      <S.ButtonWrapper>
        <PlayBig audioUrl={audioUrl} mt="3" mb="4" />
      </S.ButtonWrapper>
      <S.OptionsGrid>
        {options.map((op) => (
          <Option
            onClick={() => onClickOption(op)}
            id={`option-${op.id}`}
            key={op.id}
            size="large"
            hide={op.hidden}
          >
            {op.text}
          </Option>
        ))}
      </S.OptionsGrid>
      <S.AnswersGridWrapper>
        {correctAnswer.split('').map((answer, i) => (
          <EmptySpace
            isActive={i === activePosition && !cmsPreview}
            onClick={() => onClickAnswer(answers[i], i)}
            onFocus={() => onFocusAnswer(i)}
            id={`answer-${i}`}
            bgColor={
              answers[i]?.text ? 'neutralLight' : 'rgba(255, 255, 255, 0.5)'
            }
            key={i}
            w="65px"
            h="65px"
            size="large"
          >
            {answers[i]?.text}
          </EmptySpace>
        ))}
      </S.AnswersGridWrapper>
      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleNext}
        preview={preview}
        disabled={disabled}
        enableNext={answer === correctAnswer}
      />
    </S.Wrapper>
  );
};

export default DragAndDropV1;
