import { useEffect, useState } from 'react';

import * as T from '../../../../components/Typography';
import { PlayButton } from '../../../../components/Button';
import { Markdown } from '../../../../components';
import { useLocation } from 'react-router-dom';
import StepFooterButtons from '../StepFooterButtons';
import { Row } from '../../../../components/Grid';

import * as S from './style';
import * as SharedStyles from '../shared/style';
import { questionAndAnswerVariations } from 'constants';
import InputsWrapper from './InputsWrapper';

const QuestionAndAnswer = ({
  data: {
    variation,
    textAudio,
    textAudioUrl,
    question,
    questionAudioUrl,
    correctOptions,
    paragraph,
  } = {},
  imageUrl,
  handleNext,
  stuckAtReview,
  disabled,
  preview,
}) => {
  const [enableNext, setEnableNext] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setEnableNext(false);
  }, [location]);

  if (!variation) {
    return (
      <S.Wrapper>
        <Row jc="center" mb={6}>
          <T.P color="error" m="0" mt="1">
            Please choose a variation
          </T.P>
        </Row>
      </S.Wrapper>
    );
  }
  // use same logic from CreateStep/StepForms/QuestionAndAnswer.js
  const showImage =
    variation !== questionAndAnswerVariations.variations.NO_IMAGE;
  const showQuestion =
    questionAndAnswerVariations.showQuestionVariations.includes(variation);

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        {!!textAudio && (
          <S.TextAudioWrapper mt="2" mb="4">
            {textAudioUrl ? (
              <>
                <PlayButton
                  audioUrl={textAudioUrl}
                  width="56px"
                  height="56px"
                  iconProps={{ width: '32px', height: '32px' }}
                  fallbackText={textAudio}
                />
                <T.P ml={4} weight="regular" size="regular">
                  {textAudio}
                </T.P>
              </>
            ) : (
              <T.P weight="regular" size="regular">
                {textAudio}
              </T.P>
            )}
          </S.TextAudioWrapper>
        )}
        {showImage ? (
          <S.ImageWrapper>
            <S.Img src={imageUrl} alt={question} />
          </S.ImageWrapper>
        ) : (
          <SharedStyles.OuterWrapper bg="neutralLight">
            <Markdown
              text={paragraph}
              customStyles={{
                p: {
                  position: 'relative',
                  zIndex: 4,
                  lh: '30px',
                  padding: '0.15rem',
                  size: 'regular',
                },
                li: {
                  size: 'regular',
                },
              }}
            />
          </SharedStyles.OuterWrapper>
        )}
        {showQuestion && (
          <S.TextAudioWrapper mt="7" mb={2}>
            <PlayButton
              audioUrl={questionAudioUrl}
              width="56px"
              height="56px"
              iconProps={{ width: '32px', height: '32px' }}
              fallbackText={question}
            />
            <T.P ml={4} weight="regular" size="med">
              {question}
            </T.P>
          </S.TextAudioWrapper>
        )}
        {correctOptions && (
          <InputsWrapper
            variation={variation}
            correctOptions={correctOptions}
            enableNext={enableNext}
            setEnableNext={setEnableNext}
            showQuestion={showQuestion}
          />
        )}
      </S.ContentWrapper>

      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleNext}
        preview={preview}
        disabled={disabled}
        enableNext={enableNext}
      />
    </S.Wrapper>
  );
};

export default QuestionAndAnswer;
