import moment from 'moment';

import { Row } from '../Grid';
import * as T from '../Typography';
import * as S from './style';

const NoteCard = ({ createdAt, note, noteId, editPath, editState }) => {
  return (
    <S.NoteWrapper key={`note-${noteId}`}>
      <Row>
        <S.DateWrapper>
          <T.P color="neutral90">
            Date <br />
            <S.Value>{moment(createdAt).format('DD.MM.YY')}</S.Value>
          </T.P>
          <T.P color="neutral90" ml="5">
            Time <br />
            <S.Value>{moment(createdAt).format('hh:mm')}</S.Value>
          </T.P>
        </S.DateWrapper>
      </Row>
      <div>
        <T.P color="neutral90" mb="1">
          Notes
        </T.P>
        <T.P color="neutralMain">
          <S.Value>{note}</S.Value>
        </T.P>
      </div>
      <Row>
        <T.Link color="neutralMain" underline to={editPath} state={editState}>
          Edit Note
        </T.Link>
      </Row>
    </S.NoteWrapper>
  );
};
export default NoteCard;
