import styled from '@emotion/styled';
import * as T from 'components/Typography';

export const CancelBtnWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const StyledLink = styled(T.Link)`
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
`;

export const HeadingWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 16px;
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

export const VidWrapper = styled.div`
  max-width: 668px;
  margin: 0 auto;
  border-radius: 16px;
  overflow: hidden;
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 48px;
`;
