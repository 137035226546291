import { recruitmentStaticQuestions as data } from '../../constants';
import Question from './Question';

const StaticQuestions = ({ setFormData, form, validationErrs }) => {
  return data.map((question) => (
    <Question
      question={{ ...question, answer: form[question.key] }}
      key={question.key}
      setAnswer={(answer) => setFormData({ [question.key]: answer })}
      error={{ answer: validationErrs?.[question.key] }}
    />
  ));
};

export default StaticQuestions;
