import { multipleChoiceVariations } from '../../../../../constants';

export const generateInitialOptions = (variation, existingOptions) => {
  let options = existingOptions || [];
  let defaultOption;
  switch (variation) {
    case multipleChoiceVariations.variations.TEXT_AND_IMAGE_AT_THE_TOP:
      defaultOption = { id: 0, option: '', isCorrect: false, audioKey: '' };
      if (existingOptions.length === 0) {
        options = [
          { ...defaultOption, id: 0 },
          { ...defaultOption, id: 1 },
        ];
      } else {
        options = [
          existingOptions[0],
          existingOptions[1] || { ...defaultOption, id: 1 },
          ...existingOptions.slice(2),
        ];
      }
      break;
    case multipleChoiceVariations.variations.OPTIONS_HAVE_IMAGES:
      defaultOption = {
        id: 0,
        option: '',
        isCorrect: false,
        audioKey: '',
        imageKey: '',
      };

      if (existingOptions.length === 0) {
        options = [
          { ...defaultOption, id: 0 },
          { ...defaultOption, id: 1 },
        ];
      } else {
        options = [
          existingOptions[0],
          existingOptions[1] || { ...defaultOption, id: 1 },
          ...existingOptions.slice(2),
        ];
      }
      break;

    case multipleChoiceVariations.variations.DEFAULT:
    default:
      if (existingOptions.length === 0) {
        options = [{ id: 0, option: '', isCorrect: false, imageKey: '' }];
      } else {
        options = existingOptions;
      }
      break;
  }

  return options;
};
