import { printSingleOrPluralStr } from 'helpers';
import moment from 'moment';
import * as T from '../Typography';
import Icon from '../Icon';
import theme from 'theme';
import { Progress } from 'antd';
import * as S from './style';

const STATE_CHART_TYPE = {
  milestone: {
    icon: 'milestone',
    getLabel: (value) =>
      `${printSingleOrPluralStr(value, 'Milestone')}  Completed`,
    actionTextValue: 'View / Edit',
  },
  coach: {
    icon: 'withCoach',
    getLabel: (value) => (value === 1 ? 'Coach' : 'Coaches'),
    actionTextValue: 'View all coaches',
  },
  usersAwaitingCount: {
    icon: 'awaiting',
    getLabel: () => 'Awaiting',
    actionTextValue: 'View / Edit',
  },
  totalCount: {
    icon: 'byYourSelf',
    getLabel: (text) => `Total ${text}`,
    actionTextValue: `View all learners`,
  },
  usersCompletedTrainingCount: {
    icon: 'lock',
    getLabel: () => 'Completed training',
    actionTextValue: 'View / Edit',
  },
  totalActiveUsers: {
    icon: 'profile',
    getLabel: () => 'Total active',
    actionTextValue: 'View / Edit',
  },
  hours: {
    icon: 'alarmClock',
    getLabel: (value) => `${printSingleOrPluralStr(value, 'Hour')} on the tool`,
    actionTextValue: 'View / Edit',
  },
  learningTime: {
    icon: 'sandGlass',
    getLabel: (_, selfLearningPercentage) => (
      <T.P ta="center">
        Learning Time
        <br />
        <T.P display="block" mt={2}>
          {selfLearningPercentage}% Self Learning
        </T.P>
      </T.P>
    ),
    actionTextValue: 'View / Edit',
  },
  selfLearningTime: {
    icon: 'sandGlass',
    getLabel: () => 'Self Learning Time',
  },
  sessionDate: {
    icon: 'calendar',
    getLabel: () => 'Date of last session with coach',
    actionTextValue: 'View / Edit',
  },
  completedTrainingDate: {
    icon: 'calendar',
    getLabel: () => 'Date they have completed training',
    actionTextValue: 'View / Edit',
  },
  completionTaskLastDate: {
    icon: 'check',
    getLabel: () => 'Date of last task completion',
    actionTextValue: 'View / Edit',
  },
  progress: {
    getLabel: () => 'Exercises completed',
    actionTextValue: 'View / Edit',
  },
  dateOfSignUp: {
    icon: 'calendar',
    getLabel: () => 'Date of sign up',
    actionText: 'View / Edit',
  },
  coachLearningTime: {
    icon: 'sandGlass',
    getLabel: () => 'Coaching Time',
    actionTextValue: 'View / Edit',
  },
  totalLearnersCount: {
    icon: 'byYourSelf',
    getLabel: () => 'Learners',
    actionTextValue: 'View / Edit',
  },
};

const BACKGROUND_MODE = {
  dark: {
    iconColor: 'neutralSurface',
    textColor: 'neutralSurface',
  },
  light: {
    iconColor: 'neutralMain',
    textColor: 'neutral90',
  },
  primary: {
    iconColor: 'neutralSurface',
    textColor: 'neutralSurface',
  },
  quaternary: {
    iconColor: 'neutralSurface',
    textColor: 'neutralSurface',
  },
  progress: {
    iconColor: 'neutralSurface',
    textColor: 'neutral90',
  },
};

const StatsChart = ({
  backgroundMode = 'light',
  statChartType = 'milestone',
  value,
  label,
  actionText,
  actionLink,
  customIcon,
  selfLearningPercentage,
  ...props
}) => {
  const { icon, getLabel, actionTextValue } = STATE_CHART_TYPE[statChartType];
  const { iconColor, textColor } = BACKGROUND_MODE[backgroundMode];

  const newValue = [
    'completionTaskLastDate',
    'sessionDate',
    'completedTrainingDate',
    'dateOfSignUp',
  ].includes(statChartType)
    ? value && moment(value).isValid()
      ? moment(value).format('DD.MM.YY')
      : statChartType === 'sessionDate'
      ? 'Not Started Yet'
      : 'N/A'
    : value;

  const isHour = ['hours'].includes(statChartType);

  return (
    <S.Card backgroundMode={backgroundMode} {...props}>
      {(customIcon || icon) && (
        <Icon
          icon={customIcon || icon}
          width="40"
          height="40"
          color={iconColor}
        />
      )}

      {statChartType === 'progress' ? (
        <S.Progress>
          <Progress
            type="circle"
            strokeColor={theme.colors.primaryMain}
            strokeWidth={10}
            percent={value}
            format={(perc) => (perc === 100 ? '100%' : `${perc}%`)}
            width={94}
          />
        </S.Progress>
      ) : (
        <S.ProgressText size="med" mt="3" color={iconColor} weight="bold">
          {newValue} {isHour ? printSingleOrPluralStr(value, 'Hour') : ''}
        </S.ProgressText>
      )}

      <S.ProgressText size="regular" mt="3" color={textColor}>
        {label || getLabel(value, selfLearningPercentage)}
      </S.ProgressText>

      {actionLink && (
        <S.StyledLink mt="3" to={actionLink} display="flex">
          <Icon icon="eye" color={iconColor} mr="1" />
          <T.H3 color={iconColor} weight="600">
            {actionTextValue || actionText}
          </T.H3>
        </S.StyledLink>
      )}
    </S.Card>
  );
};

export default StatsChart;
