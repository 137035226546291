const INSTRUCTIONS = '/instructions';

// ALWAYS SYNC WITH server/constants/assets-urls.js -> instructions
export const audio = {
  welcome: `${INSTRUCTIONS}/welcome.mp3`,
  profile: `${INSTRUCTIONS}/profile.mp3`,
  listenToSoundBoard: `${INSTRUCTIONS}/listen_to_sound_board.mp3`,
  seeListenToAppIcons: `${INSTRUCTIONS}/see_listen_to_app_icons.mp3`,
  addAColouredOverlay: `${INSTRUCTIONS}/add_coloured_overlay.mp3`,
  sendAThankYouToYourCoach: `${INSTRUCTIONS}/send_a_thank_you_to_your_coach.mp3`,
  tryAgain: `${INSTRUCTIONS}/try_again.mp3`,
  badges: `${INSTRUCTIONS}/badges.mp3`,
  books: `${INSTRUCTIONS}/books.mp3`,
  viewAllBooks: `${INSTRUCTIONS}/view_all_books.mp3`,
  statistics: `${INSTRUCTIONS}/statistics.mp3`,
  areYouLearningAloneOrWithYourCoach: `${INSTRUCTIONS}/are_you_learning_alone_or_with_your_coach.mp3`,
  clickOnThePicturesBelowToPlayTheSound: `${INSTRUCTIONS}/click_on_the_pictures_below_to_play_the_sound.mp3`,
  parkingPageSaved: `${INSTRUCTIONS}/parking_page_saved.mp3`,
  rememberYouCanUseAPenAndPaper: `${INSTRUCTIONS}/use_pen_paper.mp3`,
  nice: `${INSTRUCTIONS}/nice.mp3`,
  great: `${INSTRUCTIONS}/great.mp3`,
  goodWork: `${INSTRUCTIONS}/good_work.mp3`,
  coolKeepItGoing: `${INSTRUCTIONS}/cool_keep_it_going.mp3`,
  logout: `${INSTRUCTIONS}/logout.mp3`,
  congratsExerciseCompleted: `${INSTRUCTIONS}/congrats_exercise_completed.mp3`,
  pleaseRotateYourScreen: `${INSTRUCTIONS}/please_rotate_your_screen.mp3`,
  playTheWordAndSelectFirstSound: `${INSTRUCTIONS}/play_the_word_and_select_first_sound.mp3`,
  playTheWordAndSelectSecondSound: `${INSTRUCTIONS}/play_the_word_and_select_second_sound.mp3`,
  playTheWordAndSelectThirdSound: `${INSTRUCTIONS}/play_the_word_and_select_third_sound.mp3`,
  playTheWordAndSelectFourthSound: `${INSTRUCTIONS}/play_the_word_and_select_fourth_sound.mp3`,
  playTheCompletedWord: `${INSTRUCTIONS}/play_the_completed_word.mp3`,
  doYouWantToAddMoreWords: `${INSTRUCTIONS}/do_you_want_to_add_more_words.mp3`,
  greatJobOtherWordsYouCouldHaveMade: `${INSTRUCTIONS}/great_job_other_words_you_could_have_made.mp3`,
  welcomeToShannonTrust:
    '/static-content/6f47c0f5-045e-46e5-831d-8a678f5c7e1d.mp3',
  welcomeToTurningPages:
    '/static-content/1c2e9b87-110f-4b38-97a6-d1c8874bf0ef.mp3',
  getStarted: '/static-content/b9ea7a73-c5eb-4c60-8757-81f754a6a6c9.mp3',
  howToUse: '/static-content/4c1e247f-05b9-4a5b-b167-511c6dd93dbb.mp3',
  learnerId: '/static-content/9c89c372-991d-49c4-9a89-9461b3d1988d.mp3',
  requestToDeleteTheAccount:
    '/static-content/8d1b2ee3-674d-4c6e-a6f7-c2a173a9caad.mp3',
  byPhoneCall: '/static-content/e89a24ea-e74d-4cb2-9da5-d8bbaa82c23a.mp3',
  clickTheOptionsBelowToTellUsIfYouAreLearningOnYourOwn:
    '/static-content/2ea38ab5-9b04-4f41-917c-6c394b48730f.mp3',
  youAreMakingProgressKeepGoing:
    '/static-content/79e10679-f075-4f45-8f4d-2f726a4e1a53.mp3',
  enterYourLearnerId:
    '/static-content/56ff9d8a-5063-4dab-a4d1-7e2702c3ed50.mp3',
  logIn: '/static-content/85b3d5f2-5f3a-4b26-9b5d-88870e5d6747.mp3',
  others: '/static-content/2f0845e6-777a-4b2e-89fc-1a3a101d2a24.mp3',
  myProfile: '/static-content/my_profile.mp3',
  myGoals: '/static-content/my_goals.mp3',
  myExercises: '/static-content/my_exercises.mp3',
  progressCheck: '/static-content/progress_check.mp3',
  deleteMyAccount: '/static-content/delete_my_account.mp3',
  phone: '/aeeef0d9-1681-4084-99fb-0933c8092bb3nut.mp3',
  whichColouredOverlayWouldYouLike:
    '/static-content/which_coloured_overlay_would_you_like.mp3',
  clickOnTheIconsBelowToListenToWhatTheyMean:
    '/static-content/click_on_the_icons_below_to_listen_to_what_they_mean.mp3',
  next: '/static-content/next.mp3',
  back: '/words/back.mp3',
  complete: '/6d778165-3859-43f9-8804-96779a840b22nut.mp3',
  saveParkingPageBookmark: '/static-content/save_parking_page_(bookmark).mp3',
  speaker: '/static-content/speaker.mp3',
  exerciseLocked: '/static-content/exercise_locked.mp3',
  withYourCoach: '/static-content/with_your_coach.mp3',
  byYourself: '/static-content/by_yourself.mp3',
  success: '/static-content/success.mp3',
  milestone: '/static-content/milestone.mp3',
  book: '/6ed6b4be-f9ff-46e9-9bc8-27f02c37caaaNut.mp3',
  coachNotes: '/static-content/coach_notes.mp3',
  letsSeeHowYoureCurrentlyFeelingAndHowMuchProgressYouveMade:
    '/static-content/let’s_see_how_you’re_currently_feeling_and_how_much_progress_you’ve_made_you_will_need_to_be_with_your_coach_to_do_this_please_start_by_getting_your_coach_to_input_their_code_below.mp3',
  letSDoAQuickRefresherToSeeHowMuchProgressWeveMade:
    '/static-content/let’s_do_a_quick_refresher_to_see_how_much_progress_we’ve_made_you_will_need_to_be_with_your_coach_to_do_this_please_start_by_getting_your_coach_to_input_their_code_below.mp3',
  thankYouLooksLikeYoureMakingSomeGreatProgress:
    '/static-content/thank_you_looks_like_you’re_making_some_great_progress_now_let’s_keep_going.mp3',
  youveCompletedAManualYouShouldBeChuffed:
    '/static-content/you’ve_completed_a_manual_you_should_be_chuffed.mp3',
  woohooYouVeReachedAMilestone:
    '/static-content/woohoo_you’ve_reached_a_milestone.mp3',
  letSRepeatAnAffirmationBeforeWeContinue:
    '/static-content/let’s_repeat_an_affirmation_before_we_continue.mp3',
  iLoveGainingKnowledgeWhichHelpsMeInGrowingToMyFullPotential:
    '/static-content/i_love_gaining_knowledge_which_helps_me_in_growing_to_my_full_potential.mp3',
  iAmAdvancingToNewLevelsByLearningMoreEachDay:
    '/static-content/i_am_advancing_to_new_levels_by_learning_more_each_day.mp3',
  myMindsAbilityToLearnAndRememberIsIncreasingEveryDay:
    '/static-content/my_mind’s_ability_to_learn_and_remember_is_increasing_every_day.mp3',
  iHaveAWinnersMindsetAndILoveAccomplishingMyGoals:
    '/static-content/i_have_a_winner’s_mindset_and_i_love_accomplishing_my_goals.mp3',
  iWillContinueToExpandMyMind:
    '/static-content/i_will_continue_to_expand_my_mind.mp3',
  congratsAnotherExerciseCompleted:
    '/static-content/congrats_another_exercise_completed.mp3',
  greatLetSContinue: '/static-content/great_lets_continue.mp3',
  correct: '/static-content/correct.mp3',
  contactUs: '/static-content/contact_us.mp3',
  privacyPolicy: '/static-content/privacy_policy.mp3',
  readBookToMyChild: '/static-content/read_a_book_to_my_child.mp3',
  readANewspaper: '/static-content/read_a_newspaper.mp3',
  fillInAForm: '/static-content/fill_in_a_form.mp3',
  readALetterFromMyFamily: '/static-content/read_a_letter_from_my_family.mp3',
  readPaperwork: '/static-content/read_paperwork.mp3',
  buildMyConfidence: '/static-content/build_my_confidence.mp3',
  gainAJob: '/static-content/gain_a_job.mp3',
  continueLearning: '/static-content/continue_learning.mp3',
  writeATextMessage: '/static-content/write_a_text_message.mp3',
  goalCompleted:
    '/static-content/goal_completed_this_has_now_moved_to_your_completed_goals.mp3',
  completedGoals: '/static-content/completed_goals.mp3',
  addANewGoal: '/static-content/add_a_new_goal.mp3',
  goalCompletedThisHasNowMovedToYourCompletedGoals:
    '/static-content/goal_completed_this_has_now_moved_to_your_completed_goals.mp3',

  // not currently linked to any files
  review: '/static-content/review.mp3',
  HereAreTheOptionalBooksYouCanRead:
    '/static-content/tip_here_are_the_optional_books_you_can_read_this_is_encouraged_but_not_necessary_you_can_read_the_books_in_any_order.mp3',
  booksYouReReadingAtTheMoment:
    '/static-content/books_you’re_reading_at_the_moment.mp3',
  allUnlockedBooks: '/static-content/all_unlocked_books.mp3',
  startReading: '/static-content/start_reading.mp3',
  requestPhysicalCopy: '/static-content/request_physical_copy.mp3',
  thanksForYourRequest:
    '/static-content/thanks_for_your_request_we_will_arrange_for_you_to_get_the_book_remember_it’s_good_to_read_the_physical_books_with_your_coach.mp3',
  outOfLevelWords: '/static-content/out_of_level_words.mp3',
  continueReading: '/static-content/continue_reading.mp3',
  requestCopy: '/static-content/request_copy.mp3',
  markBookAsComplete: '/static-content/mark_book_as_complete.mp3',
  bookCompletedWellDone: '/static-content/book_completed_well_done.mp3',
  nextBookWeSuggestYouRead: '/static-content/next_book_we_suggest_you_read.mp3',

  // ended up not putting a play button as we think the icon is self explanatory
  seeMoreActivities: '/static-content/see_more_activities.mp3',
  seeMoreExercises: '/static-content/see_more_exercises.mp3',

  accessibility: '/static-content/accessibility.mp3',
  increaseFontSize: '/static-content/increase_font_size.mp3',
  decreaseFontSize: '/static-content/decrease_font_size.mp3',

  // not needed on the screen
  blue: '/94acafe6-6707-4347-8f3b-f0319f583fb2nut.mp3',
  green: '/static-content/green.mp3',
  yellow: '/static-content/yellow.mp3',
  red: '/61a6ff0e-2516-41ec-8dbf-56b37809e18anut.mp3',

  niceYouVeCompletedTheProgressCheck:
    '/static-content/nice_you’ve_completed_the_progress_check.mp3',

  // emoji feedback
  greatWorkAnotherExerciseCompletedPleaseSelectAnEmojiToShowHowTheExerciseMadeYouFeel:
    '/static-content/great_work_another_exercise_completed_please_select_an_emoji_to_show_how_the_exercise_made_you_feel.mp3',

  hereAreAFewActivitiesYouCanRecap:
    '/static-content/here_are_a_few_activities_you_can_recap_you’ll_see_them_in_your_exercises_next.mp3',

  // badges
  youVeJustWonABadgeForCompletingManualOne:
    '/static-content/you’ve_just_won_a_badge_for_completing_manual_one_amazing_work.mp3',
  youveJustWonABadgeForCompletingManualTwo:
    '/static-content/you’ve_just_won_a_badge_for_completing_manual_two_great_work.mp3',
  youveJustWonABadgeForCompletingManualThree:
    '/static-content/you’ve_just_won_a_badge_for_completing_manual_three_nice_one.mp3',
  youveJustWonABadgeForCompletingManualFour:
    '/static-content/you’ve_just_won_a_badge_for_completing_manual_four_you_should_be_chuffed.mp3',
  youveJustWonABadgeForCompletingManualFive:
    '/static-content/you’ve_just_won_a_badge_for_completing_manual_five_and_the_whole_programme_amazing_work.mp3',
  youveJustWonABadgeForCompletingYourFirstBook:
    '/static-content/you’ve_just_won_a_badge_for_completing_your_first_book_keep_going.mp3',
  youveJustWonABadgeForAchievingAPersonalGoal:
    '/static-content/you’ve_just_won_a_badge_for_achieving_a_personal_goal_great_work.mp3',
  youveJustWonABadgeForAttending5SessionsInARow:
    '/static-content/you’ve_just_won_a_badge_for_attending_5_sessions_in_a_row_way_to_keep_committed.mp3',
  youveJustWonABadgeForMakingProgress:
    '/static-content/you’ve_just_won_a_badge_for_making_progress_keep_going.mp3',
  youveJustWonABadgeForCompletingYourFirstExercise:
    '/static-content/you’ve_just_won_a_badge_for_completing_your_first_exercise_great_start.mp3',
  youveJustWonABadgeForCompletingYourFirstListenAndRepeatExercise:
    '/static-content/you’ve_just_won_a_badge_for_completing_your_first_listen_and_repeat_exercise_great_work.mp3',
  youveJustWonABadgeForCompletingAllMultipleChoiceQuestionsInManual1:
    '/static-content/you’ve_just_won_a_badge_for_completing_all_multiple_choice_questions_in_manual_1_keep_going.mp3',
  youveJustWonABadgeForCompletingYourFirstDragAndDropExercise:
    '/static-content/you’ve_just_won_a_badge_for_completing_your_first_drag_and_drop_exercise_great_work.mp3',
  youveJustWonABadgeForCompletingYourAllTextFinderExercises:
    '/static-content/you’ve_just_won_a_badge_for_completing_your_all_text_finder_exercises_great_work.mp3',
  youveJustWonABadgeForCompletingAllWordGames:
    '/static-content/you’ve_just_won_a_badge_for_completing_all_word_games_great_work.mp3',
  youreAStreakMasterYouveJustWonABadgeForUsingTheAppFor7Days:
    '/static-content/youre_a_streak_master_youve_just_won_a_badge_for_using_the_app_for_7_days.mp3',
  youreAStreakMasterYouveJustWonABadgeForUsingTheAppFor14Days:
    '/static-content/youre_a_streak_master_youve_just_won_a_badge_for_using_the_app_for_14_days.mp3',
  youreAStreakMasterYouveJustWonABadgeForUsingTheAppFor30Days:
    '/static-content/youre_a_streak_master_youve_just_won_a_badge_for_using_the_app_for_30_days.mp3',
  youreAStreakProdigyYouveJustWonABadgeForUsingTheAppFor100Days:
    '/static-content/youre_a_streak_prodigy_youve_just_won_a_badge_for_using_the_app_for_100_days_amazing_work.mp3',
  bullseyeYouveJustWonABadgeForGettingAllAnswersCorrectInAnExercise:
    '/static-content/bullseye_youve_just_won_a_badge_for_getting_all_answers_correct_in_an_exercise.mp3',
  youreAWordsmithYouveJustWonABadgeForLearning10Words:
    '/static-content/youre_a_wordsmith_youve_just_won_a_badge_for_learning_10_words.mp3',
  youreAWordsmithYouveJustWonABadgeForLearning20Words:
    '/static-content/youre_a_wordsmith_youve_just_won_a_badge_for_learning_20_words.mp3',
  youreAWordsmithYouveJustWonABadgeForLearning30Words:
    '/static-content/youre_a_wordsmith_youve_just_won_a_badge_for_learning_30_words.mp3',
  youreAWordsmithYouveJustWonABadgeForLearning50Words:
    '/static-content/youre_a_wordsmith_youve_just_won_a_badge_for_learning_50_words.mp3',
  youreAWordsmithYouveJustWonABadgeForLearning100Words:
    '/static-content/youre_a_wordsmith_youve_just_won_a_badge_for_learning_100_words.mp3',
  youreAWordsmithYouveJustWonABadgeForLearning200Words:
    '/static-content/youre_a_wordsmith_youve_just_won_a_badge_for_learning_200_words.mp3',
  youreAWordsmithYouveJustWonABadgeForLearning500Words:
    '/static-content/youre_a_wordsmith_youve_just_won_a_badge_for_learning_500_words.mp3',
  youreAMasterInTheMakingYouveJustWonABadgeForCompleting10ExercisesInOneDay:
    '/static-content/youre_a_master_in_the_making_youve_just_won_a_badge_for_completing_10_exercises_in_one_day.mp3',

  woohooYouveAlmostReachedAMilestone:
    '/static-content/woohoo_you’ve_almost_reached_a_milestone_here_are_the_exercises_you_need_to_complete_to_unlock_the_next_milestone_you’ll_see_these_review_activities_in_your_dashboard.mp3',

  // goals missing
  maximumOf3Goals: 'to be added',
  createYourLearnerAccountPleaseGiveUsYourBasicInformation: 'to be added',
  createYourLearnerAccountPleaseTellUsAboutYourCoachPreferences: 'to be added',
  whatIsYourFirstName: 'to be added',
  whatIsYourLastName: 'to be added',
  whatIsYourEmail: 'to be added',
  phoneNumberOptional: 'to be added',
  whatTypeOfSessionsDoYouPrefer: 'to be added',
  inPersonOnly: 'to be added',
  either: 'to be added',
  onComputerOrPhone: 'to be added',
  pleaseEnterYourPostcode: 'to be added',
  finishSignup: 'to be added',
  sendRequest: 'to be added',
  WeBelieveNobodyShouldBeLeftOutOfLearningToReadThisAppIsAFreshEasyWayForAdultsToReadAtTheirOwnPaceYouCanLearnByYourselfWithATrainedReadingCoachOrWithAFriendOrFamilyMember:
    'to be addedd',
  tryTurningPagesForFree: 'to be added',
  inALittleOver7WeeksIHadGoneFromBeingANonReaderToBeingAbleToReadShortStoryBooksTurningPagesLearner:
    'to be added',
  continue: 'to be added',
  watchTheVideoToBegin: 'to be added',
  myUpcomingCoachingSessions: 'to be added',
  myCoachingSessions: 'to be added',
  sessionDate: 'to be added',
  sessionTime: 'to be added',
  sessionLocation: 'to be added',
  contactTheCoach: 'to be added',
  newMessage: 'to be added',
  viewMessage: 'to be added',
  cancel: 'to be added',
  messageYourCoach: 'to be added',
  noSessionsAvailable: 'to be added',
  yourRequestHasBeenSentSuccessfullyYouWillBeContactedByYour: 'to be added',
  done: 'to be added',
  aNewMessageFromYourCoach: 'to be added',
  whatDoesReportingAMessageMean: 'to be added',
  confirm: 'to be added',
  reportTheMessage: 'to be added',
  close: 'to be added',
  iDontWantThisCoach: 'to be added',
  areYouSureYouDontWantThisCoach: 'to be added',
  yesImSure: 'to be added',
  noGoBack: 'to be added',
  theFacilitatorHasBeenNotifiedAndWillBeAssignedANewCoachSoon: 'to be added',
  addToCalendar: 'to be added',
  welcomeToTurningPagesSelfSignup: 'to be added',
  welcomeToTurningPagesSelfSignupDescription: 'to be added',
  thisIsYourLearnerIdDescription: 'to be added',
  startExercises: 'to be added',
  messages: 'to be added',
  doHaveATurningPagesAccountAlready: 'to be added',
  doHaveACountMeInAccountAlready: 'to be added',
  yes: 'to be added',
  no: 'to be added',
  submit: 'to be added',
  welcomeToTurningPagesSelfSignupSwitchCourseInstructions: 'to be added',
  switchCourse: 'to be added',
  whichCourseDoYouWantToView: 'to be added',
  turningPages: 'to be added',
  countMeIn: 'to be added',
  thisFieldIsRequired: 'to be added',
  mustSelectOneOfTheseOptions: 'to be added',
  learnerAlreadyRegistered: 'to be added',
  aUserAccountWithThatEmailAlreadyExists: 'to be added',
  yourOrganisationHasBeenDeactivated: 'to be added',
  yourOrganisationDoesNotAllowSelfSignup: 'to be added',
  weCantFindThatLearnerId: 'to be added',
  learnerAlreadyRegisteredToThisCourse: 'to be added',
  learnerLinkedToOtherCourse: 'to be added',
  sorryYouCannotLogInUntilYouHaveBeenAssignedACoach: 'to be added',
  invalidPostcode: 'to be added',
  internalServerError: 'to be added',
  alertEmailIsLinkedToALearner: 'to be added',
  alertTryDifferentEmail: 'to be added',
  niceWellDone: 'to be added',
  oopsTryAgain: 'to be added',
};
