import { useContext } from 'react';
import * as S from './style';
import Context from './context';
import RateButton from '../../../../components/RateButton';

const RateQuestionContent = ({ feedbacks, totalCount }) => {
  const { mode } = useContext(Context);
  const modalView = mode === 'onModal';
  const count1 =
    feedbacks?.filter((feedback) => feedback?.feeling === 'Very much')
      ?.length || 0;
  const perc1 = Math.round((count1 / totalCount) * 100) || 0;

  const count2 =
    feedbacks?.filter((feedback) => feedback?.feeling === 'Yes, I am')
      ?.length || 0;
  const perc2 = Math.round((count2 / totalCount) * 100) || 0;

  const count3 =
    feedbacks?.filter((feedback) => feedback?.feeling === 'Not really')
      ?.length || 0;
  const perc3 = Math.round((count3 / totalCount) * 100) || 0;

  const count4 =
    feedbacks?.filter((feedback) => feedback?.feeling === 'No, I’m not')
      ?.length || 0;
  const perc4 = Math.round((count4 / totalCount) * 100) || 0;

  return (
    <S.RateButtonsWrapper modalView={modalView}>
      <S.RateButtonPair modalView={modalView} index={0}>
        <RateButton
          buttonId={1}
          variant="stats"
          count={count1}
          percentage={`${perc1}%`}
        />
        <S.SmallVerticalDivider modalView={modalView} />
        <RateButton
          buttonId={2}
          variant="stats"
          count={count2}
          percentage={`${perc2}%`}
        />
      </S.RateButtonPair>
      <S.SmallVerticalDivider modalView={modalView} hideOnMobile />
      <S.RateButtonPair modalView={modalView} index={1}>
        <RateButton
          buttonId={3}
          variant="stats"
          count={count3}
          percentage={`${perc3}%`}
        />
        <S.SmallVerticalDivider modalView={modalView} />
        <RateButton
          buttonId={4}
          variant="stats"
          count={count4}
          percentage={`${perc4}%`}
        />
      </S.RateButtonPair>
    </S.RateButtonsWrapper>
  );
};

export default RateQuestionContent;
