import { useReducer, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import {
  Grid,
  Typography as T,
  Inputs as I,
  Button,
  Modal,
  TipWithBullets,
} from '../../components';
import * as S from './style';
import validate from '../../validation/schemas/forget-password';
import { Users } from '../../api-calls';
import theme from '../../theme';
import { cleanEmail } from '../../helpers';

import { navRoutes as R } from '../../constants';

const { Row, Col } = Grid;

const initialState = {
  email: '',
  httpError: '',
  validationErrs: {},
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const ForgetPassword = () => {
  const submitAttempt = useRef(false);
  const [state, setState] = useReducer(reducer, initialState);
  const [isSuccess, setIsSuccess] = useState(false);
  const { email, validationErrs, httpError } = state;
  const navigate = useNavigate();
  const { mutateAsync: sendResetPasswordLink, isLoading: loading } =
    Users.useSendResetPasswordLink(null);
  const isTablet = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.tablet})`,
  });

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const validateForm = () => {
    try {
      validate({
        email: cleanEmail(email),
      });
      setState({ validationErrs: {} });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: error.inner });
      }
      return false;
    }
  };

  const handleSendLink = async () => {
    setState({ loading: true });

    sendResetPasswordLink(
      {
        email: cleanEmail(email),
      },
      {
        onSuccess: () => {
          setIsSuccess(true);
        },
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      handleSendLink();
    }
  };

  return (
    <>
      {isSuccess && !isTablet ? (
        <>
          <Row mt="8">
            <Col w={[0, 12, 12]}>
              <T.P color="neutralMain" weight="bold">
                A password reset link has been emailed to you
              </T.P>
            </Col>
          </Row>
          <Row>
            <Col w={[0, 12, 6]} mt="6">
              <Button.BasicButton
                variant="primary"
                onClick={() => {
                  navigate(R.GENERAL.LOGIN);
                }}
              >
                Okay, back to log in
              </Button.BasicButton>
            </Col>
          </Row>
        </>
      ) : (
        <S.Form onSubmit={handleSubmit}>
          <Row>
            <Col w={[4, 12, 12]}>
              <T.H2 weight="bold" color="neutralMain" mb="2">
                Forgot your password? No problem!
              </T.H2>
            </Col>
          </Row>
          <Row>
            <Col w={[4, 12, 0]} mt="4">
              <TipWithBullets
                listItems={[
                  'Enter the email address associated with your account and we will send you a link to reset your password. Please remember to check your email junk folder.',
                ]}
                ShowBoldTipPrefix
              />
            </Col>
          </Row>
          <Row mt="6">
            <Col w={[4, 12, 6]}>
              <I.BasicInput
                id="email"
                label="Enter your email"
                placeholder="Email..."
                margins={{ mt: '2', mb: '1' }}
                type="email"
                value={email}
                autoFocus
                handleChange={(input) => setState({ email: input })}
                error={validationErrs.email}
              />
            </Col>
            <Col ml="5" w={[0, 0, 5]}>
              <TipWithBullets
                listItems={[
                  'Enter the email address associated with your account and we will send you a link to reset your password. Please remember to check your email junk folder.',
                ]}
                ShowBoldTipPrefix
              />
            </Col>
          </Row>
          <S.ButtonsWrapper mt="7" mtT="6">
            <S.ButtonContainer w={[4, 12, 6]}>
              {loading ? <T.P mb="2">...loading</T.P> : null}
              {httpError && (
                <T.P mb="2" color="error">
                  {httpError}
                </T.P>
              )}
              <Button.BasicButton
                variant="primary"
                disabled={false}
                loading={loading}
                type="submit"
                id="send-magic-link-button"
              >
                Send magic link
              </Button.BasicButton>
            </S.ButtonContainer>
          </S.ButtonsWrapper>
        </S.Form>
      )}

      {isTablet ? (
        <Modal visible={isSuccess} setIsModalVisible={setIsSuccess}>
          <Row>
            <Col w={[4, 12, 12]}>
              <T.P size="med" color="white">
                A password reset link has been emailed to you
              </T.P>
            </Col>
          </Row>
          <Row>
            <Col w={[4, 12, 12]} mt="4">
              <Button.BasicButton
                variant="secondary"
                onClick={() => {
                  navigate(R.GENERAL.LOGIN);
                  setIsSuccess(false);
                }}
              >
                Okay, back to log in
              </Button.BasicButton>
            </Col>
          </Row>
        </Modal>
      ) : null}
    </>
  );
};

export default ForgetPassword;
