const data = [
  {
    id: 1,
    description:
      'The first book was basic and good, Shannon Trust gives good foundations in reading and English. I’ve spent years in education but this has been far more beneficial in a short period of time.',
    author: 'Shannon Trust learner',
  },
  {
    id: 2,
    description:
      'I get a real sense of pride knowing the positive impact I am having on someone’s life, and I hope when I leave prison I am able to help more adults who struggle with reading and writing.',
    author: 'Shannon Trust mentor',
  },
  {
    id: 3,
    description:
      'If my grandmother was still alive she would be so proud that I’ve done this. I know she would say ‘I told you, you’re never too old to learn’ and learning to read at 33 years of age proves just that.',
    author: 'Shannon Trust mentor',
  },
];

export default data;
