import { createSchema, validate as _validate } from '../../../validation';
import { validationSchema as listenAndRepeat } from './StepForms/ListenAndRepeat';
import { validationSchema as ListenAndRepeatMultiple } from './StepForms/ListenAndRepeatMultiple';
import { validationSchema as dragAndDrop1 } from './StepForms/DragAndDrop1';
import { validationSchema as dragAndDrop2 } from './StepForms/DragAndDrop2';
import { validationSchema as dragAndDropOrderSounds } from './StepForms/DragAndDropOrderSounds';
import { validationSchema as dragAndDropCreateOrder } from './StepForms/DragAndDropCreateOrder';
import { validationSchema as listenAndRepeatGrid } from './StepForms/ListenAndRepeatGrid';
import { validationSchema as listenAndRepeatGridHidden } from './StepForms/ListenAndRepeatGridHidden';
import { validationSchema as multipleChoice } from './StepForms/MultipleChoice';
import { validationSchema as multipleChoiceBubble } from './StepForms/MultipleChoiceBubble';
import { validationSchema as sentenceFill } from './StepForms/SentenceFill';
import { validationSchema as spotTheWord } from './StepForms/SpotTheWord';
import { validationSchema as vowelBreak } from './StepForms/VowelBreak';
import { validationSchema as wordBuild } from './StepForms/WordBuild';
import { validationSchema as wordJoin1 } from './StepForms/WordJoin1';
import { validationSchema as wordJoin2 } from './StepForms/WordJoin2';
import { validationSchema as combineWordsPre } from './StepForms/CombineWordsPre';
import { validationSchema as combineWords } from './StepForms/CombineWords';
import { validationSchema as selectRecipes } from './StepForms/SelectRecipes';
import { validationSchema as typeNewEndings } from './StepForms/TypeNewEndings';
import { validationSchema as multipleChoiceGrid } from './StepForms/MultipleChoiceGrid';
import { validationSchema as textFinder } from './StepForms/TextFinder';
import { validationSchema as textFinderComprehension } from './StepForms/TextFinderComprehension';
import { validationSchema as reorder } from './StepForms/Reorder';
import { validationSchema as listenAndRepeatComp } from './StepForms/ListenAndRepeatComp';
import { validationSchema as multipleChoiceComp } from './StepForms/MultipleChoiceComp';
import { validationSchema as typing } from './StepForms/Typing';
import { validationSchema as trace } from './StepForms/Trace';
import { validationSchema as draw } from './StepForms/Draw';
import { validationSchema as questionAndAnswer } from './StepForms/QuestionAndAnswer';
import { validationSchema as typingHorizontal } from './StepForms/TypingHorizontal';
import { validationSchema as fillInTheGrid } from './StepForms/FillInTheGrid';
import { validationSchema as pyramid } from './StepForms/Pyramid';
import { validationSchema as gridWithTypingAnswer } from './StepForms/GridWithTypingAnswer';
import { validationSchema as factFamily } from './StepForms/FactFamily';
import { validationSchema as formulae } from './StepForms/Formulae';
import { validationSchema as wordJoin3 } from './StepForms/WordJoin3';
import { validationSchema as columnsTyping } from './StepForms/ColumnsTyping';
import { validationSchema as comprehensionSelect } from './StepForms/ComprehensionSelect';
import { types as Ty } from '../../../constants';

const obj = {
  [Ty.exerciseTypes.LISTEN_AND_REPEAT]: listenAndRepeat,
  [Ty.exerciseTypes.LISTEN_AND_REPEAT_MULTIPLE]: ListenAndRepeatMultiple,
  [Ty.exerciseTypes.LISTEN_AND_REPEAT_GRID]: listenAndRepeatGrid,
  [Ty.exerciseTypes.LISTEN_AND_REPEAT_GRID_HIDDEN]: listenAndRepeatGridHidden,
  [Ty.exerciseTypes.MULTIPLE_CHOICE]: {
    default: multipleChoice,
    comprehension: multipleChoiceComp,
  },
  [Ty.exerciseTypes.MULTIPLE_CHOICE_BUBBLE]: multipleChoiceBubble,
  [Ty.exerciseTypes.WORD_BUILD]: wordBuild,
  [Ty.exerciseTypes.SPOT_THE_WORD]: spotTheWord,
  [Ty.exerciseTypes.DRAG_AND_DROP_1]: dragAndDrop1,
  [Ty.exerciseTypes.DRAG_AND_DROP_2]: dragAndDrop2,
  [Ty.exerciseTypes.DRAG_AND_DROP_ORDER_SOUNDS]: dragAndDropOrderSounds,
  [Ty.exerciseTypes.DRAG_AND_DROP_CREATE_ORDER]: dragAndDropCreateOrder,
  [Ty.exerciseTypes.VOWEL_BREAK]: vowelBreak,
  [Ty.exerciseTypes.WORD_JOIN_1]: wordJoin1,
  [Ty.exerciseTypes.WORD_JOIN_2]: wordJoin2,
  [Ty.exerciseTypes.WORD_JOIN_3]: wordJoin3,
  [Ty.exerciseTypes.SENTENCE_FILL]: sentenceFill,
  [Ty.exerciseTypes.COMBINE_WORDS_PRE]: combineWordsPre,
  [Ty.exerciseTypes.COMBINE_WORDS]: combineWords,
  [Ty.exerciseTypes.SELECT_RECIPES]: selectRecipes,
  [Ty.exerciseTypes.TYPE_NEW_ENDINGS]: typeNewEndings,
  [Ty.exerciseTypes.MULTIPLE_CHOICE_GRID]: multipleChoiceGrid,
  [Ty.exerciseTypes.TEXT_FINDER]: textFinder,
  [Ty.exerciseTypes.COMPREHENSION_TEXT_FINDER]: textFinderComprehension,
  [Ty.exerciseTypes.REORDER]: reorder,
  [Ty.exerciseTypes.COMPREHENSION_LISTEN_AND_RESPOND]: listenAndRepeatComp,
  [Ty.exerciseTypes.TYPING]: typing,
  [Ty.exerciseTypes.TRACE]: trace,
  [Ty.exerciseTypes.DRAW]: draw,
  [Ty.exerciseTypes.QUESTION_AND_ANSWER]: questionAndAnswer,
  [Ty.exerciseTypes.TYPING_HORIZONTAL]: typingHorizontal,
  [Ty.exerciseTypes.FILL_IN_THE_GRID]: fillInTheGrid,
  [Ty.exerciseTypes.PYRAMID]: pyramid,
  [Ty.exerciseTypes.GRID_WITH_TYPING_ANSWER]: gridWithTypingAnswer,
  [Ty.exerciseTypes.FACT_FAMILY]: factFamily,
  [Ty.exerciseTypes.FORMULAE]: formulae,
  [Ty.exerciseTypes.COLUMNS_TYPING]: columnsTyping,
  [Ty.exerciseTypes.COMPREHENSION_SELECT]: comprehensionSelect,
};

const validate = ({ isComprehension, ...data }) => {
  const stepSchema = createSchema({
    ...(obj[data.type][isComprehension ? 'comprehension' : 'default'] ||
      obj[data.type]),
  });

  return _validate(stepSchema, data);
};

export default validate;
