import Modal from '../../../components/Modal';
import * as T from '../../../components/Typography';
import { BasicButton } from '../../../components/Button';

const SuccessModal = ({
  visible,
  buttonLabel,
  onClickButton,
  to,
  setVisible,
}) => {
  return (
    <Modal visible={visible} setIsModalVisible={setVisible}>
      <T.H1 mb={4} color="quaternaryLight">
        Success
      </T.H1>
      <T.P mb={3} color="quaternaryLight">
        Invitations to join as coaches have now been received via email.
      </T.P>
      <BasicButton to={to} handleClick={onClickButton} variant="secondary">
        <T.P color="neutralSurface" weight="bold">
          {buttonLabel}
        </T.P>
      </BasicButton>
    </Modal>
  );
};

export default SuccessModal;
