import { useReducer, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import {
  Grid,
  Typography as T,
  Inputs as I,
  Button,
  Loading,
  ValidationErrorWithPlayButton,
} from '../../../components';
import * as S from './style';
import {
  validate,
  validate2,
} from '../../../validation/schemas/signup-learner';
import {
  useLearnerSignup,
  useLearnerValidateEmailForSignup,
} from '../../../api-calls/learners.queries';
import { useAuth } from '../../../context/auth';
import { navRoutes as R, userRoles } from '../../../constants';
import { useGeneralState } from '../../../context/general-state';
import { getMediaUrl } from '../../../helpers';
import { audio } from '../../../constants';
import Redirect from 'components/Route/Redirect';
import theme from 'theme';
import { Radio } from 'components/Inputs';
import ReactGA from 'react-ga4';
import { useGetOrganisationByIdOrUniqueSlug } from 'api-calls/organisations.queries';
import { useSiteSettings } from 'context/site-settings';
import LinkedToOtherCourse from './LinkedToOtherCourse';

const { Row, Col } = Grid;
const { PlayButton } = Button;

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  preferredSessionsType: '',
  postcode: '',
  validationErrs: {},
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const Signup = () => {
  const submitAttempt = useRef(false);
  const [state, setState] = useReducer(reducer, initialState);
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.mobile})`,
  });
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    preferredSessionsType,
    postcode,
    validationErrs,
  } = state;
  const navigate = useNavigate();
  const { setUser, user } = useAuth();
  const { courseCode } = useSiteSettings();
  const {
    mutateAsync: signup,
    isLoading,
    error: httpErrorSignup,
  } = useLearnerSignup(null);

  const {
    mutateAsync: validateEmailForSignup,
    isLoadingValidateEmail,
    error: httpErrorValidateEmail,
  } = useLearnerValidateEmailForSignup();

  const { uniqueSlug } = useParams();

  const { data: organisation, isLoading: isOrgLoading } =
    useGetOrganisationByIdOrUniqueSlug({ uniqueSlug });
  const { state: generalState } = useGeneralState();
  const [step, setStep] = useState('1');
  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, lastName, email, phoneNumber]);

  const validateForm = () => {
    try {
      if (step === '1') {
        validate({
          firstName,
          lastName,
          email,
          phoneNumber,
        });
        setState({ validationErrs: {} });
        return true;
      }
      if (step === '2') {
        validate2({
          preferredSessionsType,
          postcode,
        });
        setState({ validationErrs: {} });
        return true;
      }
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: error.inner });
      }
      return false;
    }
  };

  const handleValidateEmail = async () => {
    validateEmailForSignup(
      { email, courseCode },
      {
        onSuccess: (data) => {
          if (data?.isLinkedToOtherCourse) {
            setStep('linkedToOtherCourse');
          }

          if (data?.isEmailValid) {
            setStep('2');
          }
        },
      }
    );
  };

  const handleSignup = async () => {
    if (step === '1') {
      handleValidateEmail();
      return;
    }

    signup(
      {
        firstName,
        lastName,
        email,
        phoneNumber,
        preferredSessionsType,
        postcode,
        uniqueSlug,
      },
      {
        onSuccess: (data) => {
          setUser(data);
          if (data.role === userRoles.LEARNER) {
            ReactGA.event({ category: 'signup', action: 'signup' });
            ReactGA.set({ ...data });
          }
          navigate(
            R.LEARNER.WELCOME_SELF_SIGNUP.replace(':uniqueSlug', uniqueSlug)
          );
        },
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      handleSignup();
    }
  };
  const switchStep = (_step) => {
    if (step === '1') {
      const isValid = validateForm();
      if (!isValid) return;
      handleValidateEmail();
      return;
      // validate email in backend
    }

    setStep(_step);
  };

  if (isLoading || isOrgLoading) return <Loading />;

  if (user.id && user.role === '') {
    return <Redirect to={R.COMMON.SELECT_ORGANISATION} />;
  }
  if (!organisation?.allowLearnerSelfSignup) {
    return <Redirect to={R.GENERAL.NOT_FOUND} />;
  }

  const httpError = httpErrorValidateEmail || httpErrorSignup;
  return (
    <S.Form onSubmit={handleSubmit}>
      <Row mb={6}>
        <Col w={[4, 12, 12]} ai="center">
          <S.StyledBasicButton
            id="step-1-button"
            variant={step === '1' ? 'primary' : undefined}
            disabled={false}
            loading={isLoading}
            type="button"
            {...(step === '1' && { variant: 'primary' })}
            {...(step === '2' && {
              bgColor: '#F0F1F5',
            })}
            step={step}
            handleClick={() => switchStep('1')}
          >
            1
          </S.StyledBasicButton>
          <S.VerticalLine />

          <S.StyledBasicButton
            id="step-2-button"
            variant={step === '2' ? 'primary' : undefined}
            disabled={false}
            loading={isLoading}
            type="button"
            {...(step === '2' && { variant: 'primary' })}
            {...((step === '1' || step === 'linkedToOtherCourse') && {
              bgColor: '#F0F1F5',
            })}
            step={step}
            handleClick={() => switchStep('2')}
          >
            2
          </S.StyledBasicButton>
        </Col>
      </Row>
      {step === 'linkedToOtherCourse' && (
        <LinkedToOtherCourse
          setFormState={setState}
          setStep={setStep}
          submitAttempt={submitAttempt}
        />
      )}
      {step !== 'linkedToOtherCourse' && (
        <Row mb={6}>
          <Col w={[4, 12, 12]} ai="center">
            <S.VoiceTextWrapper>
              <PlayButton
                width={'56px'}
                height={'56px'}
                radius={12}
                iconProps={{
                  height: '32px',
                  width: '32px',
                }}
                variant="onlyIcon"
                color="neutralMain"
                backgroundColor="white"
                audioUrl={getMediaUrl(
                  step === '1'
                    ? audio.createYourLearnerAccountPleaseGiveUsYourBasicInformation
                    : audio.createYourLearnerAccountPleaseTellUsAboutYourCoachPreferences,
                  true,
                  generalState?.preferredVoice
                )}
                fallbackText={`Create your learner account ${
                  step === '1'
                    ? 'Please give us your basic information.'
                    : 'Please tell us about your coach preferences'
                }`}
              />
              <T.H1
                weight="bold"
                color="neutralMain"
                size={isMobile ? 'med' : 'large'}
                ml="5"
                mt="1"
              >
                Create your learner account
                <T.P mt="2" size={isMobile ? 'small' : 'regular'}>
                  {step === '1'
                    ? 'Please give us your basic information.'
                    : 'Please tell us about your coach preferences'}
                </T.P>
              </T.H1>
            </S.VoiceTextWrapper>
          </Col>
        </Row>
      )}
      {step === '1' && (
        <Row>
          <Col w={[12, 12, 6]} mt="5" ai="center">
            <S.PlayButton error={!!validationErrs.firstName}>
              <PlayButton
                radius={12}
                iconProps={{
                  height: '32px',
                  width: '32px',
                }}
                color="neutralMain"
                backgroundColor="neutralSurface"
                audioUrl={getMediaUrl(
                  audio.whatIsYourFirstName,
                  true,
                  generalState?.preferredVoice
                )}
                fallbackText="What is your first name"
              />
            </S.PlayButton>
            <I.BasicInput
              id="first-name-id"
              label="What is your first name"
              placeholder="e.g. Adam"
              margins={{ mt: '2', mb: '1' }}
              type="text"
              value={firstName}
              autoFocus
              w="70%"
              handleChange={(input) => setState({ firstName: input })}
              error={
                validationErrs.firstName && (
                  <ValidationErrorWithPlayButton
                    message={validationErrs.firstName}
                  />
                )
              }
              fallbackText="What is your first name?"
            />
          </Col>
          <Col w={[12, 12, 6]} mt="5" ai="center">
            <S.PlayButton error={!!validationErrs.lastName}>
              <PlayButton
                radius={12}
                iconProps={{
                  height: '32px',
                  width: '32px',
                }}
                color="neutralMain"
                backgroundColor="neutralSurface"
                audioUrl={getMediaUrl(
                  audio.whatIsYourLastName,
                  true,
                  generalState?.preferredVoice
                )}
                fallbackText="What is your last name?"
              />
            </S.PlayButton>
            <I.BasicInput
              id="last-name-id"
              label="What is your last name?"
              placeholder="e.g. Woods"
              margins={{ mt: '2', mb: '1' }}
              type="text"
              value={lastName}
              autoFocus
              w="70%"
              handleChange={(input) => setState({ lastName: input })}
              error={
                validationErrs.lastName && (
                  <ValidationErrorWithPlayButton
                    message={validationErrs.lastName}
                  />
                )
              }
              fallbackText="What is your last name?"
            />
          </Col>
          <Col w={[12, 12, 6]} mt="5" ai="center">
            <S.PlayButton error={!!validationErrs.email}>
              <PlayButton
                radius={12}
                iconProps={{
                  height: '32px',
                  width: '32px',
                }}
                audioUrl={getMediaUrl(
                  audio.whatIsYourEmail,
                  true,
                  generalState?.preferredVoice
                )}
                color="neutralMain"
                backgroundColor="neutralSurface"
                fallbackText="What is your email?"
              />
            </S.PlayButton>
            <I.BasicInput
              id="email-id"
              label="What is your email?"
              placeholder="e.g. example@email.com"
              margins={{ mt: '2', mb: '1' }}
              type="text"
              value={email}
              autoFocus
              w="70%"
              handleChange={(input) => setState({ email: input })}
              error={
                validationErrs.email && (
                  <ValidationErrorWithPlayButton
                    message={validationErrs.email}
                  />
                )
              }
            />
          </Col>
          <Col w={[12, 12, 6]} mt="5" ai="center">
            <S.PlayButton error={!!validationErrs.phoneNumber}>
              <PlayButton
                radius={12}
                iconProps={{
                  height: '32px',
                  width: '32px',
                }}
                audioUrl={getMediaUrl(
                  audio.phoneNumberOptional,
                  true,
                  generalState?.preferredVoice
                )}
                color="neutralMain"
                backgroundColor="neutralSurface"
                fallbackText="Phone Number (optional)?"
              />
            </S.PlayButton>
            <I.BasicInput
              id="phone-number-id"
              label="Phone Number (optional)?"
              placeholder="e.g. +44 7822 031221"
              margins={{ mt: '2', mb: '1' }}
              type="text"
              value={phoneNumber}
              autoFocus
              w="70%"
              handleChange={(input) => setState({ phoneNumber: input })}
              error={
                validationErrs.phoneNumber && (
                  <ValidationErrorWithPlayButton
                    message={validationErrs.phoneNumber}
                  />
                )
              }
              fallbackText="Phone Number (optional)?"
            />
          </Col>
        </Row>
      )}

      {step === '2' && (
        <Row>
          <Col w={[12, 12, 12]} mt="5">
            <S.RadioWrapper>
              <S.PlayButton mt="0">
                <PlayButton
                  radius={12}
                  iconProps={{
                    height: '32px',
                    width: '32px',
                  }}
                  audioUrl={getMediaUrl(
                    audio.whatTypeOfSessionsDoYouPrefer,
                    true,
                    generalState?.preferredVoice
                  )}
                  color="neutralMain"
                  backgroundColor="neutralSurface"
                  fallbackText="What type of sessions do you prefer?"
                />
              </S.PlayButton>
              <Radio
                mt="3"
                id="type-of-sessions-id"
                // row
                label="What type of sessions do you prefer?"
                options={[
                  {
                    label: 'In-person only',
                    value: 'IN_PERSON',
                    fallbackText: 'In-person only',
                    audioUrl: getMediaUrl(
                      audio.inPersonOnly,
                      true,
                      generalState?.preferredVoice
                    ),
                  },
                  {
                    label: 'On a computer or phone',
                    value: 'ONLINE',
                    fallbackText: 'On a computer or phone',
                    audioUrl: getMediaUrl(
                      audio.onComputerOrPhone,
                      true,
                      generalState?.preferredVoice
                    ),
                  },
                  {
                    label: 'Either',
                    value: 'EITHER',
                    fallbackText: 'Either',
                    audioUrl: getMediaUrl(
                      audio.either,
                      true,
                      generalState?.preferredVoice
                    ),
                  },
                ]}
                value={preferredSessionsType}
                onChange={(input) => setState({ preferredSessionsType: input })}
                error={
                  validationErrs.preferredSessionsType && (
                    <ValidationErrorWithPlayButton
                      message={validationErrs.preferredSessionsType}
                    />
                  )
                }
              />
            </S.RadioWrapper>
          </Col>
          {/* POSTCODE FIELD */}
          {(preferredSessionsType === 'IN_PERSON' ||
            preferredSessionsType === 'EITHER') && (
            <>
              <Col w={[12, 12, 6]} mt="5" ai="center">
                <S.PlayButton error={validationErrs.postcode}>
                  <PlayButton
                    radius={12}
                    iconProps={{
                      height: '32px',
                      width: '32px',
                    }}
                    audioUrl={getMediaUrl(
                      audio.pleaseEnterYourPostcode,
                      true,
                      generalState?.preferredVoice
                    )}
                    color="neutralMain"
                    backgroundColor="neutralSurface"
                    fallbackText="Please enter your postcode"
                  />
                </S.PlayButton>
                <I.BasicInput
                  id="postalCode"
                  autocomplete="postal-code"
                  label="Please enter your postcode"
                  placeholder="Postcode..."
                  margins={{ mt: '2', mb: '1' }}
                  type="text"
                  value={postcode}
                  autoFocus
                  w="70%"
                  handleChange={(input) => setState({ postcode: input })}
                  error={
                    validationErrs.postcode && (
                      <ValidationErrorWithPlayButton
                        message={validationErrs.postcode}
                      />
                    )
                  }
                  fallbackText="Please enter your postcode"
                />
              </Col>
            </>
          )}
        </Row>
      )}

      <Row mt="7">
        <Col w={[4, 12, 12]} dir="column" jc="center" ai="flex-start">
          {httpError?.message && (
            <ValidationErrorWithPlayButton message={httpError?.message} />
          )}
          {step === '1' && (
            <S.NextButton
              id="signup-button"
              variant="primary"
              disabled={false}
              loading={isLoading || isLoadingValidateEmail}
              type="submit"
              showSound
              audioUrl={getMediaUrl(
                audio.next,
                true,
                generalState?.preferredVoice
              )}
              fallbackText="Next"
            >
              Next
            </S.NextButton>
          )}
          {step === '2' && (
            <S.NextButton
              id="signup-button"
              variant="primary"
              disabled={false}
              loading={isLoading}
              type="submit"
              showSound
              audioUrl={getMediaUrl(
                audio.finishSignup,
                true,
                generalState?.preferredVoice
              )}
              fallbackText="Finish Signup"
            >
              Finish Signup
            </S.NextButton>
          )}
        </Col>
      </Row>
    </S.Form>
  );
};

export default Signup;
