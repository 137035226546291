const Assign = ({ width, height, color, ...props }) => (
  <svg
    width={width || '32'}
    height={height || '32'}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color || 'currentColor'}
      fill-rule="evenodd"
      d="M6.9 9.6a5.76 5.76 0 1 0 11.52 0 5.76 5.76 0 0 0-11.52 0Zm15.36 16.64a9.6 9.6 0 0 0-19.2 0v2.56c0 .353.287.64.64.64h17.92a.64.64 0 0 0 .64-.64v-2.56Z"
      clip-rule="evenodd"
    />
    <path
      fill={color || 'currentColor'}
      d="M24.784 13.05a.89.89 0 0 1 0-1.268.909.909 0 0 1 1.277 0l3.614 3.584a.89.89 0 0 1 0 1.267l-3.614 3.584a.909.909 0 0 1-1.277 0 .89.89 0 0 1 0-1.267l2.071-2.055h-6.252A.9.9 0 0 1 19.7 16a.9.9 0 0 1 .904-.896h6.251l-2.072-2.054Z"
    />
  </svg>
);
export default Assign;
