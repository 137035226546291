const Underline = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 14.875C2.65625 14.875 2.36208 14.7527 2.1175 14.5081C1.8725 14.2631 1.75 13.9688 1.75 13.625C1.75 13.2812 1.8725 12.9869 2.1175 12.7419C2.36208 12.4973 2.65625 12.375 3 12.375H13C13.3438 12.375 13.6381 12.4973 13.8831 12.7419C14.1277 12.9869 14.25 13.2812 14.25 13.625C14.25 13.9688 14.1277 14.2631 13.8831 14.5081C13.6381 14.7527 13.3438 14.875 13 14.875H3ZM4.96875 10.5C4.6875 10.5 4.47917 10.4063 4.34375 10.2188C4.20833 10.0313 4.19271 9.80208 4.29687 9.53125L6.95312 2.46875C7.03646 2.27083 7.17958 2.10146 7.3825 1.96063C7.58583 1.82021 7.79687 1.75 8.01562 1.75C8.22396 1.75 8.42708 1.82021 8.625 1.96063C8.82292 2.10146 8.96354 2.27083 9.04688 2.46875L11.7031 9.53125C11.8073 9.80208 11.7917 10.0313 11.6563 10.2188C11.5208 10.4063 11.3073 10.5 11.0156 10.5C10.8906 10.5 10.7683 10.4583 10.6488 10.375C10.5288 10.2917 10.4479 10.1927 10.4063 10.0781L9.78125 8.25H6.25L5.59375 10.0781C5.55208 10.1927 5.47146 10.2917 5.35188 10.375C5.23188 10.4583 5.10417 10.5 4.96875 10.5V10.5ZM6.6875 7H9.3125L8.03125 3.375H7.96875L6.6875 7Z"
      fill="currentColor"
    />
  </svg>
);
export default Underline;
