import { useEffect } from 'react';
import axios from 'axios';
import { useVoiceVisualizer, VoiceVisualizer } from 'react-voice-visualizer';
import { Button, Icon, LetterAvatar } from 'components';
import * as S from './style';

const AudioMsg = ({
  id,
  sender,
  userType,
  audioUrl,
  setMsgIdToReport,
  reported,
}) => {
  const isUserMsg = userType === sender.role;

  return (
    <S.MsgWrapper key={id} isUserMsg={isUserMsg}>
      <LetterAvatar name={sender.name} />
      <PlayAudioMsg audioUrl={audioUrl} />
      {!isUserMsg && !reported && (
        <Button.ReportBtn id="reportBtn" onClick={() => setMsgIdToReport(id)} />
      )}
    </S.MsgWrapper>
  );
};

export const PlayAudioMsg = ({ audioUrl }) => {
  const recorderControls = useVoiceVisualizer();
  const {
    isPausedRecordedAudio,
    isPreloadedBlob,
    togglePauseResume,
    setPreloadedAudioBlob,
  } = recorderControls;

  useEffect(() => {
    const fetchAudioBlob = async () => {
      try {
        const response = await axios.get(audioUrl, { responseType: 'blob' });
        const audioBlob = new Blob([response.data], { type: 'audio/webm' });

        setPreloadedAudioBlob(audioBlob);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching audio blob:', error);
      }
    };

    fetchAudioBlob();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioUrl]);

  return (
    <S.AudioMsg>
      <Button.IconButton
        isWithLoading
        style={{
          width: '40px',
          height: '40px',
          padding: '0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={togglePauseResume}
        disabled={!isPreloadedBlob}
      >
        <Icon
          icon={isPausedRecordedAudio ? 'play' : 'pause'}
          color="primaryMain"
        />
      </Button.IconButton>
      {isPreloadedBlob && (
        <VoiceVisualizer
          controls={recorderControls}
          fullscreen
          mainBarColor="#050D30"
          width="calc(100% - 52px)"
          height="32"
          mainContainerClassName="voice-visualizer-main-container"
        />
      )}
    </S.AudioMsg>
  );
};
export default AudioMsg;
