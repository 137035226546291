import * as S from './styles';
import * as T from '../Typography';
import Icon from 'components/Icon';
import theme from 'theme';
import { getMediaUrl } from 'helpers';
import { useGeneralState } from 'context/general-state';
import { UseAudio } from 'Hooks';
import { PlayButton } from '../Button';

const Alert = ({ variant = 'primary', audio, message, children, ...props }) => {
  const { state: generalState } = useGeneralState();
  const { isLoading, handleAudioClick } = UseAudio({
    audioUrl: getMediaUrl(audio, true, generalState?.preferredVoice),
    handleClick: () => {},
    fallbackText: typeof message === 'string' && message,
  });

  if (variant === 'success' || variant === 'fail') {
    return (
      <S.AlertWrapper {...props}>
        <S.AlertContent variant={variant} p="2" width="100%" br="8px">
          <PlayButton
            variant="onlyIcon"
            width="28px"
            height="28px"
            iconProps={{
              height: '28px',
              width: '28px',
            }}
            audioUrl={getMediaUrl(audio, true, generalState?.preferredVoice)}
            fallbackText={message}
            withoutButtonStyle
            backgroundColor="none"
          />

          <T.P ml={2}>{message}</T.P>
        </S.AlertContent>
      </S.AlertWrapper>
    );
  }

  return (
    <S.AlertWrapper>
      <S.PlaySoundButton onClick={handleAudioClick} disabled={isLoading}>
        <Icon
          icon="speaker"
          width="32px"
          height="32px"
          color={theme.colors.white}
        />
      </S.PlaySoundButton>
      <S.AlertContent variant={variant}>
        <Icon
          icon="info"
          color={variant === 'primary' ? '#E05447' : theme.colors.primaryMain}
        />
        <T.P ml={2}>
          {message} {children}
        </T.P>
      </S.AlertContent>
    </S.AlertWrapper>
  );
};

export default Alert;
