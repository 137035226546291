import theme from '../../../theme';

const ArrowBack = ({ width, height, color, ...props }) => (
  <svg
    width={width || '22'}
    height={height || '20'}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.8125 4.375L8.1875 10L13.8125 15.625"
      strokeWidth="1.875"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={color || theme.colors.neutralMain}
    />
  </svg>
);
export default ArrowBack;
