import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import Icon from '../../Icon';
import * as S from './style';
import { useAuth } from '../../../context/auth';
import PlayButton from '../Play';

export const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const BasicButton = ({
  variant = 'primary',
  icon,
  loading,
  handleClick,
  disabled,
  to,
  customColor,
  bgColor,
  external,
  iconProps,
  children,
  iconMR,
  id,
  showSound,
  audioUrl,
  linkState = {},
  borderColor,
  soundBgColor,
  soundColor,
  innerTextPadding,
  fallbackText,
  PlayButtonProps,
  showEllipsis,
  ...props
}) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  // get text from children if it's component

  const onClick = (e) => {
    if (external) return;
    if (to) navigate(to, { state: { ...linkState } });
    if (handleClick instanceof Function) handleClick(e);
  };

  if (external) {
    props.href = to;
    props.target = '_blank';
  }

  if (showSound) {
    return (
      <S.BasicButtonWithPlaySound
        type="button"
        id={id}
        variant={variant}
        disabled={disabled || loading}
        onClick={onClick}
        as={external ? 'a' : 'button'}
        icon={icon}
        customColor={customColor}
        bgColor={bgColor}
        overlayColor={user.overlayColor}
        isLoading={loading}
        aria-disabled={disabled || loading ? 'true' : 'false'}
        aria-label={icon && icon}
        borderColor={borderColor}
        {...props}
      >
        <PlayButton
          disabled={disabled || loading}
          variant={variant}
          combined
          audioUrl={audioUrl}
          backgroundColor={soundBgColor}
          btnColor={soundColor}
          fallbackText={fallbackText}
          {...PlayButtonProps}
        />
        <S.Content>
          {icon && (
            <Icon
              icon={icon}
              mr={iconMR || '9.5px'}
              {...iconProps}
              style={{ cursor: 'pointer' }}
            />
          )}
          <S.InnerTextAlign>{children}</S.InnerTextAlign>
          {loading && <S.Loading variant={variant} indicator={antIcon} />}
        </S.Content>
      </S.BasicButtonWithPlaySound>
    );
  }

  return (
    <S.BasicButton
      type="button"
      id={id}
      variant={variant}
      disabled={disabled || loading}
      onClick={onClick}
      as={external ? 'a' : 'button'}
      icon={icon}
      customColor={customColor}
      bgColor={bgColor}
      overlayColor={user.overlayColor}
      borderColor={borderColor}
      isLoading={loading}
      aria-disabled={disabled || loading ? 'true' : 'false'}
      aria-label={icon && icon}
      {...props}
    >
      {icon && (
        <Icon
          icon={icon}
          mr={iconMR || '8px'}
          {...iconProps}
          style={{ cursor: 'pointer' }}
        />
      )}
      <S.InnerTextAlign
        innerPadding={innerTextPadding}
        showEllipsis={showEllipsis}
      >
        {children}
      </S.InnerTextAlign>
      {loading && (
        <S.Loading color={customColor} variant={variant} indicator={antIcon} />
      )}
    </S.BasicButton>
  );
};

export default BasicButton;
