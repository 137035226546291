import { fields, createSchema, validate as _validate } from '..';

const { email, firstName, lastName, organisationName } = fields;

const adminUser = createSchema({
  email,
  organisationName,
  firstName,
  lastName,
});

const user = createSchema({
  email,
  firstName,
  lastName,
});

const validate = (data, isAdmin) =>
  isAdmin ? _validate(adminUser, data) : _validate(user, data);

export default validate;
