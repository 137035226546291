import { useState, useRef, useEffect } from 'react';
import Icon from '../../../components/Icon';
import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';

import { LEARNER } from '../../../constants/nav-routes';
import { ImageWithText, BasicInput } from '../../../components/Inputs';
import Modal from '../../../components/Modal';
import { goals as validate } from '../../../validation/schemas';
import { options } from '../ProgressReport/Data';
import { useAddLearnerPersonalGoals } from '../../../api-calls/learners.queries';
import { useAuth } from '../../../context/auth';
import { useCreateLearnerGoal } from '../../../api-calls/learnersGoals.queries';

const Onboarding2 = () => {
  const { user } = useAuth();
  const submitAttempt = useRef(false);
  const [personalGoals, setPersonalGoals] = useState([]);
  const [otherGoals, setOtherGoals] = useState([]);
  const [validationErrs, setValidationErrs] = useState({ hasError: false });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { mutateAsync: createGoal } = useCreateLearnerGoal();
  const { mutateAsync: addLearnerPersonalGoals } = useAddLearnerPersonalGoals();

  const validateForm = () => {
    try {
      validate({
        personalGoals: personalGoals.length ? personalGoals : otherGoals,
      });

      setValidationErrs({ hasError: false });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setValidationErrs({ ...error.inner, hasError: true });
      }

      return false;
    }
  };

  const handleSubmit = () => {
    submitAttempt.current = true;
    const isValid = validateForm();
    if (isValid) {
      addGoals();
      setIsModalVisible(true);
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalGoals, otherGoals]);

  const addGoals = async () => {
    const goalArr = [...personalGoals, ...otherGoals];
    await addLearnerPersonalGoals({
      learnerId: user.selectedLearnerId,
      personalGoals: goalArr,
    });
    goalArr.forEach((g) => {
      createGoal({
        title: g,
        learnerId: user.selectedLearnerId,
      });
    });
  };

  return (
    <>
      <Row mb={3}>
        <Col w={[4, 7, 7]} mt="3">
          <T.H2 color="neutralMain">
            What personal goals would you like to set?
          </T.H2>
        </Col>
      </Row>

      <Row mb={4}>
        <Col w={[4, 10, 10]} mt="4">
          <ImageWithText
            options={options}
            value={personalGoals}
            setValue={setPersonalGoals}
            error={validationErrs.personalGoals}
          />
        </Col>
      </Row>

      <Row>
        <Col w={[4, 10, 10]} mt="5">
          <T.P color="neutralMain" weight="bold">
            Something else? Please write it down{' '}
          </T.P>
        </Col>
        <Col w={[4, 10, 10]} mt="2">
          <BasicInput
            placeholder="A personal goal..."
            type="text"
            optional="true"
            margins={{ mt: '2', mb: '1' }}
            value={otherGoals[0]}
            autoFocus
            handleChange={(input) => setOtherGoals([input])}
            error={validationErrs.otherGoals}
          />
        </Col>
      </Row>
      {validationErrs.hasError && (
        <Row>
          <Col w={[4, 10, 10]}>
            <T.P color="error" mb={3}>
              Please add at least one goal
            </T.P>
          </Col>
        </Row>
      )}
      <Row mt="6" ai="end" style={{ flex: 1 }}>
        <Col w={[4, 7, 7]}>
          <BasicButton
            variant="primary"
            icon="next"
            iconProps={{ width: '24px', height: '24px' }}
            handleClick={handleSubmit}
          />
        </Col>
      </Row>
      <Modal visible={isModalVisible} setIsModalVisible={setIsModalVisible}>
        <Row>
          <Col w={[4, 12, 12]} jc="center">
            <Icon icon="like" color="white" width="80" height="80" />
          </Col>
          <Col w={[4, 12, 12]} jc="center" mt={3}>
            <T.P size="med" color="white" weight="regular">
              Goal(s) added
            </T.P>
          </Col>
          <Col w={[4, 12, 12]} mt={4}>
            <BasicButton
              handleClick={() => setIsModalVisible(false)}
              icon="tick"
              variant="secondary"
              iconProps={{ width: '13', height: '11.5' }}
              to={LEARNER.PROGRESS_FIRST_PAGE}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Onboarding2;
