import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import { Loading } from '../../../components';
import * as T from '../../../components/Typography';
import * as S from './style';
import NoteCard from '../../../components/NoteCard';
import { navRoutes } from '../../../constants';

import { useGetCoachNotes } from '../../../api-calls/coaches.queries';
import AddNote from './Notes/AddNote';

const ViewAllNotes = () => {
  const { coachId } = useParams();
  const [loadMore, setLoadMore] = useState(6);
  const handleLoadMore = async () => {
    setLoadMore((old) => old + 6);
  };
  const {
    notes = [],
    error: getCoachNotesError,
    isLoading: getCoachNotesLoading,
  } = useGetCoachNotes({ coachId });

  if (getCoachNotesLoading) {
    return <Loading />;
  }
  return (
    <S.Wrapper>
      <Row inner>
        <Col w={[4, 12, 6]} mb={5}>
          <T.H1>Notes</T.H1>
        </Col>
      </Row>
      <Row inner>
        {notes?.length ? (
          notes
            ?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            ?.slice(0, loadMore)
            .map(({ updatedAt, note, id }) => (
              <Col w={[4, 12, 12]} mb={4}>
                <NoteCard
                  createdAt={updatedAt}
                  editPath={navRoutes.COMMON.EDIT_COACH_NOTE}
                  editState={{
                    note,
                    noteId: id,
                    coachId,
                  }}
                  note={note}
                />
              </Col>
            ))
        ) : (
          <Row inner>
            <Col w={[4, 12, 6]}>
              <T.P>There is no notes yet</T.P>
            </Col>
          </Row>
        )}
      </Row>
      {loadMore < notes?.length ? (
        <Row>
          <Col w={[4, 12, 4]} mb={3} mt={2}>
            <BasicButton
              variant="primary"
              type="submit"
              onClick={handleLoadMore}
              loading={getCoachNotesLoading}
            >
              Load more
            </BasicButton>
          </Col>
        </Row>
      ) : null}
      <AddNote />
      {getCoachNotesError ? (
        <T.P color="error">{getCoachNotesError?.message}</T.P>
      ) : null}
    </S.Wrapper>
  );
};

export default ViewAllNotes;
