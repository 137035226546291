import theme from '../../../theme';

const CmidIcon = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '33'}
    height={height || '32'}
    viewBox="0 0 33 32"
    fill="none"
    {...props}
  >
    <path
      d="M12.7667 0H3.16667C1.69467 0 0.5 1.19467 0.5 2.66667V12.2667C0.5 13.7387 1.69467 14.9333 3.16667 14.9333H12.7667C14.2387 14.9333 15.4333 13.7387 15.4333 12.2667V2.66667C15.4333 1.19467 14.2387 0 12.7667 0ZM11.1667 8.53333H9.03333V10.6667C9.03333 11.2533 8.55333 11.7333 7.96667 11.7333C7.38 11.7333 6.9 11.2533 6.9 10.6667V8.53333H4.76667C4.18 8.53333 3.7 8.05333 3.7 7.46667C3.7 6.88 4.18 6.4 4.76667 6.4H6.9V4.26667C6.9 3.68 7.38 3.2 7.96667 3.2C8.55333 3.2 9.03333 3.68 9.03333 4.26667V6.4H11.1667C11.7533 6.4 12.2333 6.88 12.2333 7.46667C12.2333 8.05333 11.7533 8.53333 11.1667 8.53333Z"
      fill={color || theme.colors.neutralMain}
    />
    <path
      d="M29.8333 0H20.2333C18.7613 0 17.5667 1.19467 17.5667 2.66667V12.2667C17.5667 13.7387 18.7613 14.9333 20.2333 14.9333H29.8333C31.3053 14.9333 32.5 13.7387 32.5 12.2667V2.66667C32.5 1.19467 31.3053 0 29.8333 0ZM28.2333 8.53333H21.8333C21.2467 8.53333 20.7667 8.05333 20.7667 7.46667C20.7667 6.88 21.2467 6.4 21.8333 6.4H28.2333C28.82 6.4 29.3 6.88 29.3 7.46667C29.3 8.05333 28.82 8.53333 28.2333 8.53333Z"
      fill={color || theme.colors.neutralMain}
    />
    <path
      d="M29.8333 17.0664H20.2333C18.7613 17.0664 17.5667 18.2611 17.5667 19.7331V29.3331C17.5667 30.8051 18.7613 31.9997 20.2333 31.9997H29.8333C31.3053 31.9997 32.5 30.8051 32.5 29.3331V19.7331C32.5 18.2611 31.3053 17.0664 29.8333 17.0664ZM20.7667 26.6664C20.7667 26.0797 21.2467 25.5997 21.8333 25.5997H28.2333C28.82 25.5997 29.3 26.0797 29.3 26.6664C29.3 27.2531 28.82 27.7331 28.2333 27.7331H21.8333C21.2467 27.7331 20.7667 27.2531 20.7667 26.6664ZM28.2333 23.4664H21.8333C21.2467 23.4664 20.7667 22.9864 20.7667 22.3997C20.7667 21.8131 21.2467 21.3331 21.8333 21.3331H28.2333C28.82 21.3331 29.3 21.8131 29.3 22.3997C29.3 22.9864 28.82 23.4664 28.2333 23.4664Z"
      fill={color || theme.colors.neutralMain}
    />
    <path
      d="M12.7667 17.0664H3.16667C1.69467 17.0664 0.5 18.2611 0.5 19.7331V29.3331C0.5 30.8051 1.69467 31.9997 3.16667 31.9997H12.7667C14.2387 31.9997 15.4333 30.8051 15.4333 29.3331V19.7331C15.4333 18.2611 14.2387 17.0664 12.7667 17.0664ZM10.9853 26.0424C11.4013 26.4584 11.4013 27.1357 10.9853 27.5517C10.5693 27.9677 9.892 27.9677 9.476 27.5517L7.96667 26.0424L6.45733 27.5517C6.04133 27.9677 5.364 27.9677 4.948 27.5517C4.532 27.1357 4.532 26.4584 4.948 26.0424L6.45733 24.5331L4.948 23.0237C4.532 22.6077 4.532 21.9304 4.948 21.5144C5.364 21.0984 6.04133 21.0984 6.45733 21.5144L7.96667 23.0237L9.476 21.5144C9.892 21.0984 10.5693 21.0984 10.9853 21.5144C11.4013 21.9304 11.4013 22.6077 10.9853 23.0237L9.476 24.5331L10.9853 26.0424Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default CmidIcon;
