import { useState, useMemo } from 'react';
import * as T from 'components/Typography';
import * as S from './style';
import { useAuth } from 'context/auth';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Row, Col } from 'components/Grid';

import {
  useGetOrganisations,
  useGetOrganisationsCount,
} from 'api-calls/organisations.queries';

import getCountText from 'utils/getCountText';

import { navRoutes, userRoles } from 'constants/index';
import { Button, Inputs } from 'components';
import SelectSortOptionComponent from 'components/SelectSortOptionComponent';
import TabsCommonTable from 'components/CommonTables/TabsCommonTable';
import getOrganisationTableColumn from './columns';
import useCsvDownload from 'Hooks/useCsvDownload';

const facilitatorTableEmptyState = {
  title: 'You currently do NOT have any organisations.',
  subTitle: 'Currently, there are no organisations registered.',
  buttonText: 'Setup a new organisation',
  buttonLink: navRoutes.ADMIN.ADD_ORGANISATION,
  disabled: false,
};
const pageSize = 20;

const OrganisationsTable = ({ onActivateAndDeactivate }) => {
  const [page, setPage] = useState(1);
  const [statusType, setStatusType] = useState('ACTIVATED');
  const navigate = useNavigate();

  const [sortInfo, setSortInfo] = useState({
    key: 'created_at',
    order: 'ASC',
  });

  const [searchValue, setSearchValue] = useState('');

  const [debounceSearchValue, setDebounceSearchValue] = useState('');
  const [data, handleClick] = useCsvDownload('/csv/organisations');
  const {
    user: { role },
  } = useAuth();

  const { organisationsCount } = useGetOrganisationsCount({
    searchValue: debounceSearchValue,
  });

  const {
    basicCount: {
      activatedCount = 0,
      deactivatedCount = 0,
      deletedCount = 0,
      totalCount = 0,
    },
    searchCount: {
      activatedCount: searchActivatedCount = 0,
      deactivatedCount: searchDeactivatedCount = 0,
      deletedCount: searchDeletedCount = 0,
    },
  } = organisationsCount || {
    basicCount: {
      activatedCount: 0,
      deactivatedCount: 0,
      deletedCount: 0,
      totalCount: 0,
    },
    searchCount: {
      activatedCount: 0,
      deactivatedCount: 0,
      deletedCount: 0,
      totalCount: 0,
    },
  };

  const { organisations, isLoading, isError } = useGetOrganisations({
    page,
    limit: pageSize,
    statusType,
    searchValue: debounceSearchValue,
    sortField: sortInfo.key,
    sortOrder: sortInfo.order,
  });

  const searchValueFunctionDebounced = useMemo(
    () => debounce((value) => setDebounceSearchValue(value), 500),
    [setDebounceSearchValue]
  );
  if (isError?.message) {
    return (
      <T.P mb={2} mt={2} color="error">
        {isError?.message}
      </T.P>
    );
  }

  const isAdmin = role === userRoles.ADMIN;

  const commonTableProps = {
    loading: isLoading,
    emptyState: !totalCount && facilitatorTableEmptyState,
    setPage,
    data: organisations,
    totalRecords: totalCount || 0,
  };

  return (
    <S.TableWrapper>
      <Row ai="center" mb={5}>
        <Col w={[4, 12, 4.5]} mbT={4}>
          <T.H2 color="neutralMain" size="medLarge">
            My Organisations
          </T.H2>
        </Col>
        <Col w={[4, 4, 2.5]} mbM={2}>
          <SelectSortOptionComponent
            sortOptions={[
              {
                value: 'oldest-to-newest',
                label: 'Oldest to Newest',
              },
              {
                value: 'newest-to-oldest',
                label: 'Newest to Oldest',
              },
            ]}
            onChange={(value) => {
              if (value === 'oldest-to-newest') {
                setSortInfo({
                  key: 'created_at',
                  order: 'ASC',
                });
              } else if (value === 'newest-to-oldest') {
                setSortInfo({
                  key: 'created_at',
                  order: 'DESC',
                });
              }
            }}
          />
        </Col>

        <Col w={[4, 4, 2.5]} mbM={2}>
          <Inputs.BasicInput
            w="100%"
            value={searchValue}
            searchIcon
            handleChange={(value) => {
              setSearchValue(value);
              setPage(1);
              searchValueFunctionDebounced(value);
            }}
            placeholder="Search..."
            m={{ ml: '0' }}
          />
        </Col>

        <Col w={[4, 4, 2.5]}>
          <Button.BasicButton
            width="100%"
            height="64px"
            disabled={data.loading}
            handleClick={handleClick}
            loading={data.loading}
            variant="tertiary"
            icon={'export'}
          >
            Export data
          </Button.BasicButton>
        </Col>
      </Row>

      <TabsCommonTable
        page={page}
        tabs={[
          {
            key: '1',
            title: 'Active',
            count: activatedCount
              ? getCountText(
                  Number(activatedCount),
                  Number(searchActivatedCount),
                  !!debounceSearchValue,
                  isLoading
                )
              : 0,
            columns: getOrganisationTableColumn({
              showActionsField: true,
              showAdminFields: isAdmin === true,
              onActivateAndDeactivate,
              UpdateFacilitatorsOrganisationsOnClick: (id) => {
                navigate(
                  navRoutes.ADMIN.UPDATE_FACILITATORS_ORGANISATIONS.replace(
                    ':id',
                    id
                  )
                );
              },
            }),
            ...commonTableProps,
            totalRecords:
              Number(searchActivatedCount) || Number(activatedCount),
          },
          {
            key: '2',
            title: 'Deactivated',
            count: deactivatedCount
              ? getCountText(
                  Number(deactivatedCount),
                  Number(searchDeactivatedCount),
                  !!debounceSearchValue,
                  isLoading
                )
              : 0,
            columns: getOrganisationTableColumn({
              showActionsField: false,
              showAdminFields: isAdmin === true,
              onActivateAndDeactivate,
            }),
            ...commonTableProps,
            totalRecords:
              Number(searchDeactivatedCount) || Number(deactivatedCount),
          },
          {
            key: '3',
            title: 'Deleted',
            count: deletedCount
              ? getCountText(
                  Number(deletedCount),
                  Number(searchDeletedCount),
                  !!debounceSearchValue,
                  isLoading
                )
              : 0,
            columns: getOrganisationTableColumn({
              showActionsField: false,
              showAdminFields: isAdmin === true,
              onActivateAndDeactivate,
            }),
            ...commonTableProps,
            totalRecords: Number(searchDeletedCount) || Number(deletedCount),
          },
        ]}
        tabsProps={{
          onChange: (key) => {
            setPage(1);
            if (key === '1') setStatusType('ACTIVATED');
            if (key === '2') setStatusType('DEACTIVATED');
            if (key === '3') setStatusType('DELETED');
          },
        }}
      />
    </S.TableWrapper>
  );
};

export default OrganisationsTable;
