import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';
import MuiModal from '@mui/material/Modal';

export const Modal = styled(MuiModal)`
  overflow: auto;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  max-width: 908px;
  width: 100%;
  padding: ${({ theme }) => theme.spacings[6]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  margin: 0 auto;
  position: relative;

  ${({ theme }) => theme.media.tablet} {
    width: 95%;
    padding: ${({ theme }) => theme.spacings[4]};
  }

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: ${({ isFirstTab }) => (isFirstTab ? 'flex-end' : '')};
`;

export const LineProgress = styled.div`
  ${setMargin};
  background-color: ${({ theme, activeColor }) =>
    activeColor ? theme.colors.primaryMain : theme.colors.neutral30};
  width: 100%;
  height: ${({ theme }) => theme.spacings[1]};
  border-radius: 2px;
`;

export const CoverWrapper = styled.div`
  width: 100%;
  min-height: 300px;
  max-height: ${({ isFirstTab }) => (isFirstTab ? 'auto' : '300px')};
  height: ${({ isFirstTab }) => (isFirstTab ? '300px' : 'auto')};
  overflow: hidden;
  margin-top: ${({ theme }) => theme.spacings[3]};

  ${({ theme }) => theme.media.mobile} {
    margin-top: ${({ theme }) => theme.spacings[2]};
    min-height: 250px;
    max-height: ${({ isFirstTab }) => (isFirstTab ? 'auto' : '250px')};
    height: ${({ isFirstTab }) => (isFirstTab ? '250px' : 'auto')};
  }
`;

export const TabImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Content = styled.div`
  width: ${({ isFirstTab }) => (isFirstTab ? '70%' : '100%')};
  margin: ${({ isFirstTab }) => (isFirstTab ? '0 auto' : '0')};

  ${({ theme }) => theme.media.mobile} {
    width: ${({ isFirstTab }) => (isFirstTab ? '90%' : '100%')};
  }
`;

export const Footer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacings[7]};
  justify-content: ${({ isFirstTab }) =>
    isFirstTab ? 'flex-end' : 'space-between'};

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    margin-top: ${({ theme }) => theme.spacings[5]};

    .back {
      max-width: 100% !important;
      margin-bottom: ${({ theme }) => theme.spacings[4]} !important;
    }

    .next {
      max-width: 100% !important;
    }
  }
`;
