import theme from '../../../theme';

const Paragraph = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '25'}
    height={height || '25'}
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.3434 15H4.65659C4.19606 15 3.82275 14.6266 3.82275 14.1661V13.8328C3.82275 13.3723 4.19606 12.999 4.65659 12.999H20.3434C20.804 12.999 21.1773 13.3723 21.1773 13.8328V14.1661C21.1773 14.6269 20.804 15 20.3434 15ZM20.3434 7.00071H4.65659C4.19606 7.00071 3.82275 6.6274 3.82275 6.16688V5.83356C3.82275 5.37303 4.19606 5 4.65659 5H20.3434C20.804 5 21.1773 5.37331 21.1773 5.83356V6.16688C21.1773 6.6274 20.804 7.00071 20.3434 7.00071ZM4.65659 8.99948H17.0103C17.4708 8.99948 17.8439 9.3728 17.8439 9.83305V10.1664C17.8439 10.6269 17.4705 10.9999 17.0103 10.9999H4.65659C4.19606 10.9999 3.82275 10.6266 3.82275 10.1664V9.83305C3.82275 9.3728 4.19606 8.99948 4.65659 8.99948ZM4.65659 16.999H13.6772C14.1377 16.999 14.511 17.3723 14.511 17.8328V18.1662C14.511 18.6267 14.1377 19 13.6772 19H4.65659C4.19606 19 3.82275 18.6267 3.82275 18.1662V17.8328C3.82275 17.3723 4.19606 16.999 4.65659 16.999Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default Paragraph;
