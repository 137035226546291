import React from 'react';
import { Col, Row } from 'components/Grid';
import * as T from 'components/Typography';
import * as S from './style';
import { BasicButton } from 'components/Button';
import Image from 'components/Image';

const TraningNotStartedNote = ({ link }) => {
  return (
    <S.Card>
      <Row inner>
        <Col w={[4, 12, 12]}>
          <T.H2 weight="bold" color="neutralMain" mb="1">
            You have NOT started your Training yet!
          </T.H2>
        </Col>
        <Col w={[4, 12, 12]}>
          <T.P>
            Embark on Your Learning Journey: Training Awaits Your Command!
          </T.P>
        </Col>
        <Col w={[4, 12, 12]} mt="3">
          <BasicButton
            variant="secondary"
            bgColor={'warningMain'}
            to={link}
            aria-label="Training page"
            type="button"
            id="training-page"
            width="auto"
            weight="700"
            customColor="neutralMain"
          >
            Training page
          </BasicButton>
        </Col>
      </Row>
      <Image image="megaphone" alt="Megaphone" width="120px" height="120" />
    </S.Card>
  );
};

export default TraningNotStartedNote;
