import styled from '@emotion/styled';
import MuiModal from '@mui/material/Modal';
import { BasicButton } from 'components/Button';

export const Modal = styled(MuiModal)`
  overflow: auto;
  z-index: 9999;
  ${({ flex }) =>
    flex &&
    `
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  max-width: 908px;
  width: 100%;
  padding: ${({ theme }) => theme.spacings[6]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  margin: 0 auto;
  position: relative;
  ${({ theme }) => theme.media.tablet} {
    width: 95%;
    padding: ${({ theme }) => theme.spacings[4]};
  }

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ theme }) => theme.media.mobile} {
    display: none;
  }
`;

export const CoverWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

export const TabImage = styled.img`
  width: 144px;
  height: 144px;
  object-fit: contain;
`;

export const Content = styled.div`
  margin: ${({ theme }) => `${theme.spacings[6]} 0 0 ${theme.spacings[5]}`};
`;

export const DividingLine = styled.div`
  width: 100%;
  max-width: 812px;
  height: 2px;
  margin: ${({ theme }) => theme.spacings[5]} auto;
  background: linear-gradient(
    270deg,
    rgba(229, 231, 235, 0) 0%,
    #e5e7eb 51.56%,
    rgba(229, 231, 235, 0) 100%
  );
`;

export const Footer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacings[7]};
  justify-content: flex-end;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;

    .next {
      max-width: 100% !important;
    }
  }
`;

export const QuestionsSection = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral40};
`;

export const RateButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;

  ${({ theme }) => theme.media.mobile} {
    flex-wrap: wrap;
  }
`;

export const CancelButton = styled(BasicButton)`
  display: none;
  ${({ theme }) => theme.media.mobile} {
    display: flex;
  }
`;
