import * as React from 'react';
import * as S from './style';

const Switch = ({
  checked,
  onChange,
  disabled,
  defaultChecked,
  label,
  size,
  ...props
}) => {
  const labelProps = {
    inputProps: { 'aria-label': 'Switch demo' },
    size,
  };
  return (
    <S.Wrapper>
      <S.Label
        value="start"
        control={
          <S.StyledSwitch
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
            disabled={disabled}
            defaultChecked={defaultChecked}
            {...labelProps}
          />
        }
        label={label}
        labelPlacement="end"
        {...props}
      />
    </S.Wrapper>
  );
};

export default Switch;
