const SortIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.72581 7.56522C2.32495 7.56522 2 7.21483 2 6.78261C2 6.35039 2.32495 6 2.72581 6H16.2742C16.675 6 17 6.35039 17 6.78261C17 7.21483 16.675 7.56522 16.2742 7.56522H2.72581ZM4.66129 12.7826C4.26044 12.7826 3.93548 12.4322 3.93548 12C3.93548 11.5678 4.26044 11.2174 4.66129 11.2174H14.3387C14.7396 11.2174 15.0645 11.5678 15.0645 12C15.0645 12.4322 14.7396 12.7826 14.3387 12.7826H4.66129ZM5.87097 17.2174C5.87097 17.6496 6.19592 18 6.59677 18H12.4032C12.8041 18 13.129 17.6496 13.129 17.2174C13.129 16.7852 12.8041 16.4348 12.4032 16.4348H6.59677C6.19592 16.4348 5.87097 16.7852 5.87097 17.2174Z"
      fill="#050D30"
    />
    <path
      d="M21.1818 15.5342L21.8361 14.8412C22.1023 14.5591 22.534 14.5591 22.8003 14.8412C23.0666 15.1232 23.0666 15.5805 22.8003 15.8625L20.9821 17.7885C20.7159 18.0705 20.2841 18.0705 20.0179 17.7885L18.1997 15.8625C17.9334 15.5805 17.9334 15.1232 18.1997 14.8412C18.466 14.5591 18.8977 14.5591 19.1639 14.8412L19.8182 15.5342V5.72222C19.8182 5.32335 20.1234 5 20.5 5C20.8766 5 21.1818 5.32335 21.1818 5.72222V15.5342Z"
      fill="#050D30"
    />
  </svg>
);

export default SortIcon;
