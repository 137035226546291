import styled from '@emotion/styled';

import { Button } from 'components';
export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: ${({ theme }) => theme.spacings[6]};
  padding-right: ${({ theme }) => theme.spacings[9]};
  padding-left: ${({ theme }) => theme.spacings[9]};
  ${({ theme }) => theme.media.tablet} {
    padding-right: ${({ theme }) => theme.spacings[6]};
    padding-left: ${({ theme }) => theme.spacings[6]};
  }
  ${({ theme }) => theme.media.mobile} {
    padding-top: ${({ theme }) => theme.spacings[4]};
    padding-right: 0;
    padding-left: 0;
  }
`;

export const PlayButton = styled.div`
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 6]};
  margin-right: ${({ theme }) => theme.spacings[5]};
  margin-bottom: ${({ error }) => (error ? '32px' : '0px')};
  ${({ theme }) => theme.media.mobile} {
    margin-top: ${({ theme, mt }) => theme.spacings[mt || 4]};
    margin-right: ${({ theme }) => theme.spacings[4]};
  }
`;

export const VerticalLine = styled.span`
  border: ${({ theme }) => theme.borders.verticalLine};
  width: 80px;
`;

export const StyledBasicButton = styled(Button.BasicButton)`
  width: 48px;
  height: 46px;
  padding: 11px 12px;
  font-size: 24px;
  border-radius: 10px;
  ${({ theme }) => theme.media.mobile} {
    width: 40px;
    height: 40px;
    padding: 10px 10px;
    font-size: 20px;
  }
`;

export const VoiceTextWrapper = styled.div`
  display: flex;
`;

export const NextButton = styled(Button.BasicButton)`
  margin-top: ${({ theme }) => theme.spacings[6]};
  min-width: 240px;
  height: 64px;
  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    max-width: 100%;
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const BR = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral40};
  width: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0;
  margin-top: 32px;
`;
