const Face1 = ({ width, height, color, ...props }) => (
  <svg
    width={width || '32'}
    height={height || '32'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.05994 16.0025C3.05994 23.4252 9.07724 29.4425 16.4999 29.4425C23.9227 29.4425 29.9399 23.4252 29.9399 16.0025C29.9399 8.57979 23.9227 2.5625 16.4999 2.5625C9.07724 2.5625 3.05994 8.57979 3.05994 16.0025ZM14.5799 12.8025C14.5799 13.5094 14.0069 14.0825 13.2999 14.0825C12.593 14.0825 12.0199 13.5094 12.0199 12.8025C12.0199 12.0956 12.593 11.5225 13.2999 11.5225C14.0069 11.5225 14.5799 12.0956 14.5799 12.8025ZM19.6999 14.0825C20.4069 14.0825 20.9799 13.5094 20.9799 12.8025C20.9799 12.0956 20.4069 11.5225 19.6999 11.5225C18.993 11.5225 18.4199 12.0956 18.4199 12.8025C18.4199 13.5094 18.993 14.0825 19.6999 14.0825ZM16.4999 24.3225C12.6119 24.3225 9.45994 21.1706 9.45994 17.2825C9.45994 16.929 9.74648 16.6425 10.0999 16.6425H22.8999C23.2534 16.6425 23.5399 16.929 23.5399 17.2825C23.5399 21.1706 20.388 24.3225 16.4999 24.3225Z"
      fill="#3B3C42"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.4998 23.0419C19.4647 23.0419 21.9063 20.8018 22.2247 17.9219L10.775 17.9219C11.0934 20.8018 13.535 23.0419 16.4998 23.0419Z"
      fill="#3B3C42"
    />
  </svg>
);

export default Face1;
