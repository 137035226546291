import { useEffect, useReducer, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import ReactGA from 'react-ga4';
import {
  Button,
  Typography as T,
  Inputs as I,
  ValidationErrorWithPlayButton,
} from 'components';
import {
  courseCodeTypes,
  audio,
  navRoutes as R,
  userRoles,
} from '../../../constants';
import { Learners } from 'api-calls';
import { useSiteSettings } from 'context/site-settings';
import { useGeneralState } from 'context/general-state';
import { getMediaUrl } from 'helpers';
import validate from '../../../validation/schemas/login';
import * as S from './style';
import { useAuth } from 'context/auth';

const initialState = {
  learnerUniqueId: '',
  validationErrs: {},
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const SignupExistingForm = ({ insideSignup }) => {
  const { courseCode } = useSiteSettings();
  const { state: generalState } = useGeneralState();
  const submitAttempt = useRef(false);
  const [state, setState] = useReducer(reducer, initialState);
  const { learnerUniqueId, validationErrs } = state;
  const { setUser } = useAuth();
  const navigate = useNavigate();

  const {
    mutate: signup,
    isLoading,
    isError,
    error,
  } = Learners.useLearnerSignup();
  const { uniqueSlug } = useParams();

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learnerUniqueId]);

  const validateForm = () => {
    try {
      validate({
        learnerUniqueId,
        role: userRoles.LEARNER,
      });
      setState({ validationErrs: {} });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: error.inner });
      }
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      signup(
        { uniqueSlug, learnerUniqueId, signupWithExistingAccount: true },
        {
          onSuccess: (data) => {
            setUser(data);
            // [TODO] ask about it
            // if (data.role === userRoles.LEARNER) {
            //   ReactGA.event({ category: 'signup', action: 'signup' });
            //   ReactGA.set({ ...data });
            // }
            navigate(
              `${R.LEARNER.WELCOME_SELF_SIGNUP.replace(
                ':uniqueSlug',
                uniqueSlug
              )}?signupWithExistingAccount=true`
            );
          },
        }
      );
    }
  };

  const existingCourseName =
    courseCode !== courseCodeTypes.TPD ? 'Turning Pages' : 'Count Me In';
  return (
    <S.FormWrapper onSubmit={handleSubmit} insideSignup={insideSignup}>
      <S.TitleWrapper>
        <Button.PlayButton
          width={'56px'}
          height={'56px'}
          radius={12}
          color="neutralMain"
          iconProps={{
            height: '32px',
            width: '32px',
          }}
          audioUrl={getMediaUrl(null, true, generalState?.preferredVoice)}
          fallbackText={
            insideSignup
              ? 'Use your learner ID to Log in'
              : `Enter your ${existingCourseName} Learner ID to get started`
          }
        />
        <T.H1>
          {insideSignup
            ? 'Use your learner ID to Log in'
            : `Enter your ${existingCourseName} Learner ID to get started`}
        </T.H1>
      </S.TitleWrapper>
      <I.BasicInput
        id="learner-id"
        label="Enter your Learner ID..."
        placeholder="Learner ID..."
        margins={{ mt: '2', mb: '1' }}
        type="text"
        value={learnerUniqueId}
        autoFocus
        handleChange={(input) => setState({ learnerUniqueId: input })}
        error={
          validationErrs.learnerUniqueId && (
            <ValidationErrorWithPlayButton
              message={validationErrs.learnerUniqueId}
            />
          )
        }
        audioUrl={getMediaUrl(
          audio.enterYourLearnerId,
          true,
          generalState?.preferredVoice
        )}
        fallbackText="Enter your Learner ID"
      />
      <S.InfoWrapper>
        <Button.PlayButton
          width={'32px'}
          height={'32px'}
          radius={12}
          iconProps={{
            height: '25px',
            width: '25px',
          }}
          color="neutralMain"
          withoutButtonStyle={true}
          backgroundColor="none"
          audioUrl={getMediaUrl(null, true, generalState?.preferredVoice)}
          fallbackText={`Forget you learner I.D? Contact Shannon Trust`}
        />
        <T.Link
          size="regular"
          display="flex"
          external
          color="neutral90"
          weight="400"
          underline
          to={`mailto:${R.EXTERNAL.CONTACT_US}`}
        >
          Forget you learner ID?&nbsp;
          <T.P color="primaryMain">Contact Shannon Trust</T.P>
        </T.Link>
      </S.InfoWrapper>
      <S.ButtonContainer>
        {isError && <ValidationErrorWithPlayButton message={error?.message} />}
        <Button.BasicButton
          id="login-button"
          variant="primary"
          disabled={validationErrs.learnerUniqueId}
          loading={isLoading}
          type={insideSignup ? 'button' : 'submit'}
          showSound
          width="100%"
          height="64px"
          audioUrl={getMediaUrl(
            audio.submit,
            true,
            generalState?.preferredVoice
          )}
          fallbackText="Submit"
          {...(insideSignup && { onClick: handleSubmit })}
        >
          Submit
        </Button.BasicButton>
      </S.ButtonContainer>
    </S.FormWrapper>
  );
};

export default SignupExistingForm;
