import styled from '@emotion/styled';
import { setMargin } from 'helpers';

export const TableTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: start;
    gap: 16px;
  }
`;

export const HR = styled.div`
  ${setMargin}
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral40};
`;

export const TableWrapper = styled.div`
  ${setMargin}
  width: 100%;
  overflow: auto;
`;

export const TabsWrapper = styled.div`
  margin-left: 110px;
  width: calc(100% - 220px);
  ${({ theme }) => theme.media.tablet} {
    margin-left: 50px;
    width: calc(100% - 100px);
  }
  ${({ theme }) => theme.media.mobile} {
    margin-left: 0;
    width: 100%;
  }
`;
