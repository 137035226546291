const Bell = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '30'}
    height={height || '30'}
    fill="none"
    viewBox="0 0 30 30"
  >
    <path
      fill={color || '#fff'}
      d="M7.728 16.355a6.205 6.205 0 0 1 10.748-6.206l2.17 3.76 3.494 1.728c.404.2.422.77.032.994L10.493 24.53a.564.564 0 0 1-.845-.525l.251-3.889-2.17-3.76Z"
    />
    <path
      fill={color || '#fff'}
      fill-rule="evenodd"
      d="M9.271 5.855c-1.05.606-1.636 1.687-1.603 2.747a8.633 8.633 0 0 1 2.168-1.77 8.633 8.633 0 0 1 2.616-.993c-.901-.558-2.13-.59-3.18.016Zm9.872 17.098c1.093-.63 1.684-1.775 1.597-2.875l-4.885 2.82c.909.625 2.196.686 3.288.055Z"
      clip-rule="evenodd"
    />
  </svg>
);

export default Bell;
