import { useReducer, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import {
  Grid,
  Typography as T,
  Inputs as I,
  Button,
  Modal,
  TipWithBullets,
} from '../../components';
import * as S from './style';
import validate from '../../validation/schemas/reset-password';
import { Users } from '../../api-calls';
import theme from '../../theme';

import { navRoutes as R } from '../../constants';

const { Row, Col } = Grid;

const initialState = {
  password: '',
  httpError: '',
  validationErrs: {},
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const ResetPassword = () => {
  const submitAttempt = useRef(false);
  const [state, setState] = useReducer(reducer, initialState);
  const [isSuccess, setIsSuccess] = useState(false);
  const { password, validationErrs, httpError } = state;
  const navigate = useNavigate();
  const { token } = useParams();
  const { mutateAsync: updatePassword, isLoading } = Users.useUpdatePassword();

  const isTablet = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.tablet})`,
  });

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  const validateForm = () => {
    try {
      validate({
        password,
      });
      setState({ validationErrs: {} });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: error.inner });
      }
      return false;
    }
  };

  const handleCreateNewPassword = async () => {
    await updatePassword(
      {
        password,
        token,
      },
      {
        onSuccess: () => {
          setIsSuccess(true);
        },
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitAttempt.current = true;
    const isValid = validateForm();

    if (isValid) {
      handleCreateNewPassword();
    }
  };

  return (
    <>
      {isSuccess && !isTablet ? (
        <>
          <Row mt="8">
            <Col w={[0, 12, 12]}>
              <T.P color="neutralMain" weight="bold">
                Great, you’ve set up a new password. Keep it safe!
              </T.P>
            </Col>
          </Row>
          <Row>
            <Col w={[0, 12, 6]} mt="6">
              <Button.BasicButton
                variant="primary"
                onClick={() => {
                  navigate(R.GENERAL.LOGIN);
                }}
              >
                Okay, back to log in
              </Button.BasicButton>
            </Col>
          </Row>
        </>
      ) : (
        <S.Form onSubmit={handleSubmit}>
          <Row>
            <Col w={[4, 12, 12]}>
              <T.H2 weight="bold" color="neutralMain">
                Reset password
              </T.H2>
            </Col>
          </Row>
          <Row>
            <Col w={[4, 12, 0]} mt="4">
              <TipWithBullets
                p="12px 16px"
                title="Tip! Password must contain: "
                listItems={[
                  'Minimum of 8 characters',
                  'One capital letter',
                  'One lowercase letter',
                  'One number',
                  'One special character',
                ]}
                ShowBoldTipPrefix
              />
            </Col>
          </Row>
          <Row mt="6">
            <Col w={[4, 12, 6]}>
              <I.BasicInput
                id="password"
                label="Create new password"
                placeholder="Password..."
                margins={{ mt: '2', mb: '1' }}
                type="password"
                value={password}
                autoFocus
                handleChange={(input) => setState({ password: input })}
                error={validationErrs.password}
              />
            </Col>
            <Col w={[0, 0, 6]}>
              <TipWithBullets
                p="12px 16px"
                title="Tip! Password must contain: "
                listItems={[
                  'Minimum of 8 characters',
                  'One capital letter',
                  'One lowercase letter',
                  'One number',
                  'One special character',
                ]}
                ShowBoldTipPrefix
              />
            </Col>
          </Row>
          <S.ButtonsWrapper mt="7" mtT="6">
            <S.ButtonContainer w={[4, 12, 6]}>
              {isLoading ? <T.P mb="2">...loading</T.P> : null}
              {httpError && (
                <T.P mb="2" color="error">
                  {httpError}
                </T.P>
              )}
              <Button.BasicButton
                variant="primary"
                disabled={false}
                loading={isLoading}
                type="submit"
                id="create-new-password"
              >
                Reset password
              </Button.BasicButton>
            </S.ButtonContainer>
          </S.ButtonsWrapper>
        </S.Form>
      )}

      {isTablet ? (
        <Modal visible={isSuccess} setIsModalVisible={setIsSuccess}>
          <Row>
            <Col w={[4, 12, 12]}>
              <T.P size="med" color="white">
                Great, you’ve set up a new password. Make sure to keep it safe.
              </T.P>
            </Col>
          </Row>
          <Row>
            <Col w={[4, 12, 12]} mt="4">
              <Button.BasicButton
                variant="secondary"
                onClick={() => {
                  navigate(R.GENERAL.LOGIN);
                  setIsSuccess(false);
                }}
              >
                Back to log in
              </Button.BasicButton>
            </Col>
          </Row>
        </Modal>
      ) : null}
    </>
  );
};

export default ResetPassword;
