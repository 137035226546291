import React from 'react';

const OpenLink = ({ width, height, color, ...props }) => (
  <svg
    width={width || '16'}
    height={height || '16'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
  >
    <path
      fill="#050D30"
      stroke="#050D30"
      stroke-width=".5"
      d="M12.333 7v5.333H1.667V1.666H7V.6H1.667A1.07 1.07 0 0 0 .6 1.666v10.667c0 .586.48 1.067 1.067 1.067h10.666a1.07 1.07 0 0 0 1.067-1.067V7h-1.067Z"
    />
    <path
      fill="#050D30"
      stroke="#050D30"
      stroke-width=".5"
      d="M13.4 4.866V1.133A.533.533 0 0 0 12.867.6H9.133v1.066h2.446L7 6.246 7.754 7l4.58-4.579v2.445H13.4Z"
    />
  </svg>
);

export default OpenLink;
