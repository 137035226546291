import * as T from '../../../../components/Typography';
import { typingHorizontalVariations } from '../../../../constants';
import * as S from './style';

const AnswerInput = ({ index, setUserAnswers, userAnswers, correctAnswer }) => {
  const text = userAnswers[index];
  const hasPrefix =
    correctAnswer.type ===
    typingHorizontalVariations.correctOptionTypes.HAS_PREFIX;
  const hasSuffix =
    correctAnswer.type ===
    typingHorizontalVariations.correctOptionTypes.HAS_SUFFIX;

  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    setUserAnswers((old) => {
      const newAnswers = [...old];
      newAnswers[index] = value.toLowerCase();
      return newAnswers;
    });
  };

  return (
    <S.TypeInputWrapper>
      {hasPrefix && (
        <T.P size={'medLarge'} weight="bold">
          {correctAnswer.prefix}
        </T.P>
      )}
      <S.TypeInput
        id={`answer-${index}`}
        name={`answer-${index}`}
        type="text"
        aria-labelledby={`answer-${index}`}
        value={text}
        onChange={handleChange}
        isCorrect={text === correctAnswer.answer}
      />
      {hasSuffix && (
        <T.P size={'medLarge'} weight="bold">
          {correctAnswer.suffix}
        </T.P>
      )}
    </S.TypeInputWrapper>
  );
};

export default AnswerInput;
