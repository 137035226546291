import theme from '../../../theme';

const Mic = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.3201 6.24135C16.3201 3.85469 14.3859 1.91992 12.0001 1.91992C9.61419 1.91992 7.68006 3.85469 7.68006 6.24135V11.0429C7.68006 13.4296 9.61419 15.3644 12.0001 15.3644C14.3859 15.3644 16.3201 13.4296 16.3201 11.0429V6.24135ZM12.0458 22.3199C12.4434 22.3199 12.7658 21.9975 12.7658 21.5998V18.4464C16.5335 18.0613 19.4401 14.8758 19.4401 11.0429C19.4401 10.6452 19.1177 10.3228 18.7201 10.3228C18.3224 10.3228 18.0001 10.6452 18.0001 11.0429C18.0001 14.3151 15.3798 16.9828 12.1146 17.0439C12.1193 17.0411 12.0827 17.0383 12.0458 17.0383C12.0056 17.0383 11.9657 17.0416 11.9265 17.0482C8.66743 17.0273 6.00006 14.3456 6.00006 11.0429C6.00006 10.6452 5.67772 10.3228 5.28006 10.3228C4.8824 10.3228 4.56006 10.6452 4.56006 11.0429C4.56006 14.91 7.51722 18.1131 11.3258 18.4552V21.5998C11.3258 21.9975 11.6481 22.3199 12.0458 22.3199Z"
      fill={theme.colors[color] || color || '#050D30'}
    />
  </svg>
);

export default Mic;
