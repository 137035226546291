const Eye = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '15'}
    height={height || '12'}
    fill="none"
    viewBox="0 0 15 12"
  >
    <path
      fill={color || 'currentColor'}
      fill-rule="evenodd"
      d="M7.5 4.876c-.62 0-1.125.505-1.125 1.125S6.88 7.126 7.5 7.126s1.125-.505 1.125-1.125S8.12 4.876 7.5 4.876Zm0 3.75a2.628 2.628 0 0 1-2.625-2.625A2.628 2.628 0 0 1 7.5 3.376a2.628 2.628 0 0 1 2.625 2.625A2.628 2.628 0 0 1 7.5 8.626Zm7.401-2.998C14.421 4.794 11.78.613 7.297.753 3.15.86.74 4.512.099 5.628a.754.754 0 0 0 0 .747c.473.822 3.022 4.876 7.42 4.876.06 0 .122 0 .184-.002 4.145-.106 6.558-3.758 7.198-4.874a.754.754 0 0 0 0-.747Z"
      clip-rule="evenodd"
    />
  </svg>
);

export default Eye;
