import { mediaTypes } from '../../constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useExerciseForm } from 'CMS/Providers/ExerciseFormProvider';

export const useUpdateExerciseMedia = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { updateFormFields } = useExerciseForm();
  const prevPath = searchParams.get('prevPath');
  const mediaTypeFromParams = searchParams.get('type');

  const updateExerciseMediaType = ({ mediaType: _mediaType, mediaKey }) => {
    const mediaType = mediaTypeFromParams || _mediaType;
    switch (mediaType) {
      case mediaTypes.TITLE_AUDIO:
        updateFormFields({ titleAudioKey: mediaKey });
        break;
      case mediaTypes.BEFORE_EXERCISE_TEXT_AUDIO:
        updateFormFields({ beforeExerciseTextAudioKey: mediaKey });
        break;
      case mediaTypes.BEFORE_EXERCISE_TIP_AUDIO:
        updateFormFields({ beforeExerciseTipAudioKey: mediaKey });
        break;
      default:
        throw Error(`Unsupported media type: ${mediaType}`);
    }

    navigate(prevPath, {
      state: { isRedirected: true },
    });
  };

  return { updateExerciseMediaType };
};
