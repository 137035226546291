import styled from '@emotion/styled';
import { BasicButton } from 'components/Button';

export const PageWrapper = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  width: 100%;
`;

export const CustomHTMLContentWrapper = styled.div`
  width: 100%;
`;

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ListWrapper = styled.div`
  width: 100%;
  border-radius: ${({ theme, type }) =>
    ['Dos', 'Donts'].includes(type) ? theme.spacings[2] : '0'};
  border-color: ${({ theme, type }) =>
    ['Dos', 'Donts'].includes(type) ? theme.colors.neutral50 : 'transparent'};
  border-style: ${({ type }) =>
    ['Dos', 'Donts'].includes(type) ? 'solid' : 'none'};
  border-width: ${({ type }) =>
    ['Dos', 'Donts'].includes(type) ? '2px' : '0'};
  background: ${({ theme, type }) =>
    type === 'Dos'
      ? theme.colors.secondaryLight
      : type === 'Donts'
      ? theme.colors.primaryLight
      : 'none'};
  display: flex;
  flex-direction: column;
  padding: ${({ theme, type }) =>
    ['Dos', 'Donts'].includes(type) ? theme.spacings[6] : 0};
  margin-bottom: ${({ theme }) => theme.spacings[6]};
`;

export const AsideWrapper = styled.div`
  padding-right: ${({ theme }) => theme.spacings[4]};
  border-right: 1px solid ${({ theme }) => theme.colors.neutral30};
  ${({ theme }) => theme.media.mobile} {
    border-right: 0;
  }
`;

export const Button = styled(BasicButton)`
  max-width: 300px;
`;
