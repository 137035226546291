import { Icon } from 'components';
import * as S from './style';

import FormControlLabel from '@mui/material/FormControlLabel';

const SelectSortOption = ({ value, setValue, staticQuestion, ...props }) => {
  const SelectProps = {};
  if (staticQuestion) {
    SelectProps.IconComponent = () => null;
  }

  return (
    <S.QuestionTypeDropdown
      select
      value={value}
      onChange={(e) => setValue(e.target.value)}
      SelectProps={SelectProps}
      staticQuestion={staticQuestion}
      disabled={staticQuestion || props.disabled}
      {...props}
    >
      <S.QuestionTypeDropdownItem value="SHORT_ANSWER">
        <FormControlLabel
          value="SHORT_ANSWER"
          control={<Icon icon="shortAnswer" width="24" height="24" mr={2} />}
          label="Short answer"
        />
      </S.QuestionTypeDropdownItem>
      <S.QuestionTypeDropdownItem value="PARAGRAPH">
        <FormControlLabel
          value="PARAGRAPH"
          control={<Icon icon="paragraph" width="24" height="24" mr={2} />}
          label="Paragraph"
        />
      </S.QuestionTypeDropdownItem>
      <S.QuestionTypeDropdownItem value="SINGLE_CHOICE">
        <FormControlLabel
          value="SINGLE_CHOICE"
          control={<Icon icon="circle" width="24" height="24" mr={2} />}
          label="Single Choice"
        />
      </S.QuestionTypeDropdownItem>
      <S.QuestionTypeDropdownItem value="MULTIPLE_CHOICE">
        <FormControlLabel
          value="MULTIPLE_CHOICE"
          control={<Icon icon="square" width="24" height="24" mr={2} />}
          label="Multiple Choice"
        />
      </S.QuestionTypeDropdownItem>
      <S.QuestionTypeDropdownItem value="CHECKBOXES">
        <FormControlLabel
          value="CHECKBOXES"
          control={<Icon icon="checklist" width="24" height="24" mr={2} />}
          label="Checkboxes"
        />
      </S.QuestionTypeDropdownItem>
    </S.QuestionTypeDropdown>
  );
};

export default SelectSortOption;
