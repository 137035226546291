import { Typography as T, Modal, Button, YoutubeVid } from '../../components';
import { useGeneralState } from 'context/general-state';
import ExerciseTimeline from '../../components/ExerciseTimeline';
import { useSearchParams } from 'react-router-dom';
import { useGetExercises } from '../../api-calls/exercises.queries';
import { Learners } from '../../api-calls';
import Loading from '../../components/Loading';
import { useEffect, useState } from 'react';
import * as S from './style';
import { useAuth } from 'context/auth';
import { getMediaUrl } from 'helpers';
import { audio, courseCodeTypes } from '../../constants';
import NewMsgsModal from './NewMsgsModal';
import { useSiteSettings } from 'context/site-settings';

const LearnerDashboard = ({ forPublic }) => {
  const { isLoading, isError, error, isSuccess, milestones } = useGetExercises({
    forPublic,
  });
  const { setState: setGeneralState, state: generalState } = useGeneralState();
  const { courseCode } = useSiteSettings();
  const { user, setUser } = useAuth();
  const [videoEnded, setVideoEnded] = useState(false);

  const {
    mutateAsync: updateLearnerWatchedOnboardingVid,
    error: httpError,
    isLoading: isUpdating,
  } = Learners.useUpdateLearnerWatchedOnboardingVid({
    learnerUserId: user?.id,
  });

  const [searchParams] = useSearchParams();
  const milestoneIdToMoveTo = searchParams.get('milestoneId');

  useEffect(() => {
    if (isSuccess) {
      setGeneralState({
        exerciseFlow: forPublic ? 'PLAYGROUND' : 'LEARNING',
      });
    }
  }, [isSuccess, forPublic, setGeneralState]);

  const handleWatchedVideo = () => {
    updateLearnerWatchedOnboardingVid(
      {},
      { onSuccess: () => setUser({ ...user, watchedOnboardingVid: true }) }
    );
  };

  if (isLoading) return <Loading />;
  return (
    <S.Wrapper>
      <NewMsgsModal />
      {isSuccess || forPublic ? (
        <ExerciseTimeline
          milestones={milestones}
          forPublic={forPublic}
          milestoneIdToMoveTo={milestoneIdToMoveTo}
        />
      ) : null}
      {isError ? (
        <T.P mb="2" color="error">
          {error?.message}
        </T.P>
      ) : null}
      <Modal
        visible={user?.isSelfSignedUp && !user?.watchedOnboardingVid}
        bgColor="white"
        maxWidth="900px"
        p="32px"
      >
        <S.PlayButtonWrapper>
          <Button.PlayButton
            width="56px"
            height="56px"
            audioUrl={getMediaUrl(
              audio.watchTheVideoToBegin,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            backgroundColor="neutralSurface"
            fallbackText={`Watch the video to begin`}
          />
          <T.H2 ml="4">Watch the video to begin</T.H2>
        </S.PlayButtonWrapper>
        <S.VideoWrapper>
          <YoutubeVid
            videoId={
              courseCode === courseCodeTypes.TPD ? '8g0Tk3XFTVA' : 'oz2wg8e4DBA'
            }
            onPlay={() => setVideoEnded(true)}
            onError={(err) => {
              setVideoEnded(true);
            }}
          />
        </S.VideoWrapper>
        <S.ContinueWrapper>
          <Button.BasicButton
            variant="primary"
            iconProps={{ width: '23px', height: '23px' }}
            handleClick={handleWatchedVideo}
            showSound
            audioUrl={getMediaUrl(
              audio.continue,
              true,
              generalState?.preferredVoice
            )}
            fallbackText={'Continue'}
            maxWidth="200px"
            disabled={!videoEnded}
            loading={isUpdating}
          >
            <T.P weight="bold" color="white">
              Continue
            </T.P>
          </Button.BasicButton>
        </S.ContinueWrapper>
        {httpError && (
          <T.P color="error" m="0" mt="2" mx={2}>
            There was an error, Try again later
          </T.P>
        )}
      </Modal>
    </S.Wrapper>
  );
};

export default LearnerDashboard;
