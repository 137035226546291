import React, { useCallback } from 'react';
import * as S from './style';
import * as T from 'components/Typography';
import { BasicButton } from 'components/Button';
import Image from 'components/Image';
import { Divider } from 'components/Divider';
import { Icon, Loading, StatsChart } from 'components';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteAccount from './DeleteAccount';
import { useGetCoachDetails } from 'api-calls/coaches.queries';
import { useOrganisationDetails } from 'context/organisation-details';
import Notes from '../Notes';
import { COMMON } from 'constants/nav-routes';
import CoachLearnersSection from '../CoachLearnersSection';
import { useAuth } from 'context/auth';
import { navRoutes } from 'constants';

import ViewAnotherCourseProfile from 'components/ViewAnotherCourseProfile';

const NewCoachProfile = () => {
  const { user } = useAuth();
  const { coachId, statusType } = useParams();
  const navigate = useNavigate();
  const { coach, isLoading } = useGetCoachDetails({
    coachId,
  });

  const { organisationDetails } = useOrganisationDetails();
  const dashboardLink = navRoutes[user?.role].DASHBOARD;

  const detailsLink = COMMON.COACH_DETAILS.replace(
    ':statusType',
    statusType
  ).replace(':coachId', coachId);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <S.PageWrapper>
      <S.CoverWrapper>
        <S.CoverBackButtonWrapper>
          <BasicButton
            handleClick={goBack}
            variant="tertiary"
            icon={'leftChevron'}
          >
            Back
          </BasicButton>
        </S.CoverBackButtonWrapper>
        <S.CoverBackgroundWrapper>
          <Image image="profileCover" width="100%" height="100%" />
        </S.CoverBackgroundWrapper>
      </S.CoverWrapper>
      <S.Content>
        <S.DetailsWrapper>
          <S.AvatarNameWrapper>
            <S.AvatarWrapper>
              <Icon
                icon="openedBook"
                width="32px"
                height="32px"
                color="white"
              />
              <S.FadedIcon>
                <Icon
                  icon="openedBook"
                  width="84px"
                  height="84px"
                  color="white"
                />
              </S.FadedIcon>
            </S.AvatarWrapper>
            <S.NameWrapper>
              <T.H1>{coach?.fullName}</T.H1>
              <T.P color="neutral70" size="med">
                {organisationDetails?.name}
              </T.P>
              <ViewAnotherCourseProfile
                isCmidCourse={coach?.isCmidCourse}
                isTpdCourse={coach.isTpdCourse}
              />
            </S.NameWrapper>
          </S.AvatarNameWrapper>

          {/* <BasicButton
            maxWidth="220px"
            variant="tertiary"
            icon={'profile'}
            iconProps={{
              width: '20px',
              height: '25px',
            }}
          >
            Assign a learner
          </BasicButton> */}
        </S.DetailsWrapper>
        <Divider my="6" />
        <T.H1 mb="5">{coach?.firstName}’s stats</T.H1>
        <S.StatsWrapper>
          <StatsChart
            backgroundMode="light"
            statChartType="totalLearnersCount"
            value={coach?.totalLearners}
          />
          <StatsChart
            backgroundMode="light"
            statChartType="coachLearningTime"
            value={coach?.totalCoachingTime}
          />
          <StatsChart
            backgroundMode="quaternary"
            statChartType="dateOfSignUp"
            value={coach?.createdAt}
          />
          <StatsChart
            backgroundMode="primary"
            statChartType="completedTrainingDate"
            value={coach?.completedTrainingAt}
          />
        </S.StatsWrapper>
        <Divider my="6" />
        <T.H2 size="med" mb="5">
          {coach?.firstName}’s learners
        </T.H2>
        <CoachLearnersSection coachId={coachId} />
        <Divider my="6" />
        <T.H2 size="med" mb="2">
          Details
        </T.H2>
        <T.P mb="4">Here you can view the coach’s submission form.</T.P>
        <T.Link color="neutralMain" to={detailsLink} display="flex">
          Show coach’s form{' '}
          <Icon ml="4" color="neutralMain" icon="rightChevron" />
        </T.Link>
        <Divider my="6" />
        <Notes />
        <Divider my="6" />
        <DeleteAccount />
        <BasicButton
          to={dashboardLink}
          icon={'back'}
          variant="secondary"
          maxWidth="225px"
        >
          Back to dashboard
        </BasicButton>
      </S.Content>
    </S.PageWrapper>
  );
};

export default NewCoachProfile;
