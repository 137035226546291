const Phone = ({ width, height, color, ...props }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8164 18.5892L22.1655 24.2195C22.7454 24.8299 23.7957 24.9171 24.9658 24.3419C27.9436 23.1104 28.9344 22.8102 29.5 23.4055L35.6487 29.8777C36.3755 30.6427 36.1816 31.917 35.3688 32.7726C31.1485 37.2149 24.2888 37.2149 20.0685 32.7726L8.58238 20.6823C4.38318 16.2623 4.38318 9.11141 8.58238 4.69135C9.4123 3.81778 10.6868 3.60284 11.441 4.39672L17.5897 10.8689C18.1227 11.4298 17.8369 12.4747 16.6734 15.5878C16.1324 16.8031 16.2191 17.9604 16.8164 18.5892Z"
      fill={color || '#2E2E2E'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3003 4.98791C22.3003 5.58961 22.7881 6.07738 23.3898 6.07738C29.2062 6.07738 33.9213 10.7925 33.9213 16.609C33.9213 17.2107 34.4091 17.6984 35.0108 17.6984C35.6125 17.6984 36.1003 17.2107 36.1003 16.609C36.1003 9.58913 30.4096 3.89844 23.3898 3.89844C22.7881 3.89844 22.3003 4.38621 22.3003 4.98791ZM23.3898 10.4423C22.7881 10.4423 22.3003 9.95452 22.3003 9.35282C22.3003 8.75112 22.7881 8.26335 23.3898 8.26335C28.0028 8.26335 31.7424 12.0029 31.7424 16.616C31.7424 17.2177 31.2546 17.7055 30.6529 17.7055C30.0512 17.7055 29.5635 17.2177 29.5635 16.616C29.5635 13.2063 26.7994 10.4423 23.3898 10.4423ZM23.3898 14.7922C22.7881 14.7922 22.3003 14.3045 22.3003 13.7028C22.3003 13.1011 22.7881 12.6133 23.3898 12.6133C25.596 12.6133 27.3845 14.4018 27.3845 16.608C27.3845 17.2097 26.8967 17.6975 26.295 17.6975C25.6933 17.6975 25.2056 17.2097 25.2056 16.608C25.2056 15.6052 24.3926 14.7922 23.3898 14.7922Z"
      fill={color || '#2E2E2E'}
    />
  </svg>
);

export default Phone;
