import styled from '@emotion/styled';
import { Checkbox, Button as AntdButton, Tag as AntdTag } from 'antd';
import theme from '../../../theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .ant-table {
    overflow: auto;
  }

  .ant-table table {
    font-size: 1rem;
    font-weight: 400;
  }

  .ant-picker-input > input::placeholder {
    font-size: 1rem;
  }
`;

export const NameColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  span {
    color: ${({ theme }) => theme.colors['neutralMain']};
    font-weight: bold;
    font-size: 1rem;
  }
`;

export const DBSCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${theme.colors.neutralMain};
    border-color: ${theme.colors.neutralMain};
  }
`;

export const Button = styled(AntdButton)`
  padding: 0;
`;

export const ApprovedStatusColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  button {
    font-weight: bold;
    font-size: 1rem;
    margin-left: -0.5rem;
  }
`;

export const TableTitleWrapper = styled.div`
  min-height: 72px;
  display: flex;
  align-items: center;
`;

export const ActionButton = styled(AntdButton)``;
export const ActionsButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 16px;
`;

export const CopyLinkWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  div {
    width: auto;
  }
`;

export const Tag = styled(AntdTag)`
  background-color: ${({ bgColor, theme }) => theme.colors[bgColor] ?? bgColor};
  color: ${({ fontColor, theme }) => {
    return `${theme.colors[fontColor] || theme.colors['white']} !important`;
  }};
  border-radius: 24px;
  font-size: 1rem;
  font-weight: 700;
  width: fit-content;
  padding: 2px 8px;
`;

export const TableOptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
`;

export const TableTitleOptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  ${({ theme }) => theme.media.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SearchInputWrapper = styled.div``;

export const StatsWrapper = styled.div`
  margin-bottom: 32px;
`;

export const TableTitleDescriptionWrapper = styled.div``;

export const TableButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;
  gap: 16px;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: stretch;
  }
`;
