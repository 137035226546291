import { useReducer, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { organisationStatuses, userRoles } from '../../../constants';
import { Grid, Typography as T, Inputs as I } from '../../../components';
import { BasicButton } from 'components/Button';
import validate from '../../../validation/schemas/organisation-create-account';
import { navRoutes } from '../../../constants';
import { useAuth } from '../../../context/auth';
import { Checkbox } from 'components/Inputs';
import {
  useGetOrganisationByInviteToken,
  useSignupOrganisation,
} from '../../../api-calls/organisations.queries';
import Tip from '../../../components/Tip';
import * as S from './style';
import Modal from '../../../components/Modal';
import { GENERAL } from 'constants/nav-routes';
import { useSiteSettings } from 'context/site-settings';

const { Row, Col } = Grid;

const tipString = `
Password must contain:
  * Minimum of 8 characters
  * One capital letter
  * One lowercase letter
  * One number
  * One special character
`;

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }
  return { ...state, ...value };
}

const initialState = {
  form: {
    id: '',
    organisationName: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    status: '',
    agreedOnTerms: false,
  },
  httpError: '',
  validationErrs: {},
  loading: false,
};

const CreateAccount = () => {
  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const { courseName } = useSiteSettings();
  const { inviteToken: token } = useParams();
  const [state, setState] = useReducer(reducer, {
    ...initialState,
    form: { ...initialState.form, token },
  });

  const {
    isLoading: getInvitationLoading,
    isSuccess,
    data: organisationData,
  } = useGetOrganisationByInviteToken({ token }, { enabled: !user?.id });

  const { mutateAsync: signupOrganisation } = useSignupOrganisation();

  const [showModal, setShowModal] = useState(false);

  const {
    form: {
      id,
      organisationName,
      firstName,
      lastName,
      email,
      password,
      agreedOnTerms,
      alreadyRegistered,
    },
    loading,
    validationErrs,
    httpError,
  } = state;

  const validateForm = () => {
    try {
      validate(state.form);
      setState({ validationErrs: { hasError: false } });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({
          validationErrs: { ...error.inner, hasError: true },
        });
      }
      return false;
    }
  };

  const setFormData = (data, inputName) => {
    setState((prevState) => ({
      validationErrs: { ...prevState.validationErrs, [inputName]: '' },
      httpError: '',
      form: { ...prevState.form, ...data },
    }));
  };

  useEffect(() => {
    if (user?.id) {
      return setShowModal(true);
    }
  }, [user?.id]);

  useEffect(() => {
    if (isSuccess) {
      if (organisationData.status === 'ACTIVATED') {
        return navigate(navRoutes.COACH.DASHBOARD);
      }
      setState((prevState) => ({
        form: {
          ...prevState.form,
          ...organisationData,
          organisationName: organisationData.name,
        },
      }));
    }
  }, [isSuccess, navigate, organisationData]);

  const handleOrganisationSignup = async () => {
    setState({ loading: true });

    await signupOrganisation(
      {
        id,
        status: organisationStatuses.ACTIVATED,
        name: organisationName,
        firstName,
        lastName,
        email,
        password,
        alreadyRegistered,
      },
      {
        onSuccess: (data) => {
          setUser({
            ...data,
            role: userRoles.ORGANISATION_ADMIN,
          });

          if (organisationData.isTpdCourse && organisationData.isCmidCourse) {
            navigate(navRoutes.ORGANISATION_ADMIN.WELCOME);
            return;
          }
          navigate(navRoutes.ORGANISATION_ADMIN.DASHBOARD);
        },
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (isValid) {
      await handleOrganisationSignup();
    }
  };

  return (
    <>
      <Row mb="3">
        <Col w={[4, 12, 12]}>
          <T.H1 weight="bold" color="neutralMain">
            Create your organisation account
          </T.H1>
        </Col>
        <Col w={[4, 12, 12]} mt="2">
          {alreadyRegistered ? (
            <>
              <T.P>
                You have been invited to create an account for $
                {organisationName}.
              </T.P>
              <T.P>
                You can update your personal details below by entering your
                existing password.
              </T.P>
            </>
          ) : (
            <T.P>
              {`Welcome to ${courseName}! To start using ${courseName} in your
              platform you first need to create your organisation account by
              filling in the details below.`}
            </T.P>
          )}
        </Col>
      </Row>
      <S.Form onSubmit={handleSubmit}>
        <Row>
          <Col w={[4, 6, 6]} mt="4">
            <I.BasicInput
              id="account-name"
              label="Organisation name"
              aria-label="Organisation name"
              placeholder="Organisation name..."
              type="text"
              value={organisationName}
              handleChange={(organisationName) =>
                setFormData({ organisationName }, 'organisationName')
              }
              error={validationErrs.organisationName}
              disabled={loading || getInvitationLoading}
            />
          </Col>
        </Row>
        <Row mt="6">
          <Col w={[4, 12, 12]}>
            <T.H2 weight="bold" color="neutralMain">
              Main account holder details
            </T.H2>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 6, 6]} mt="5">
            <I.BasicInput
              id="first-name"
              label="First name"
              aria-label="First name"
              placeholder="First name..."
              type="text"
              value={firstName}
              handleChange={(firstName) =>
                setFormData({ firstName }, 'firstName')
              }
              error={validationErrs.firstName}
              disabled={loading || getInvitationLoading}
            />
          </Col>
          <Col w={[4, 6, 6]} mt="5">
            <I.BasicInput
              id="last-name"
              label="Last name"
              aria-label="Last name"
              placeholder="Last name..."
              type="text"
              value={lastName}
              handleChange={(lastName) => setFormData({ lastName }, 'lastName')}
              error={validationErrs.lastName}
              disabled={loading || getInvitationLoading}
            />
          </Col>
        </Row>
        <Row>
          <Col w={[4, 6, 6]} mt="5">
            <I.BasicInput
              id="email"
              label="Email address"
              aria-label="Email address"
              placeholder="Email..."
              type="email"
              value={email}
              handleChange={(email) => setFormData({ email }, 'email')}
              error={validationErrs.email}
              disabled={loading || getInvitationLoading}
            />
          </Col>
          <Col w={[4, 6, 6]} mt="5">
            <I.BasicInput
              id="password"
              label={
                alreadyRegistered
                  ? 'Enter your current password'
                  : 'Create a Password'
              }
              aria-label={
                alreadyRegistered
                  ? 'Enter your current password'
                  : 'Create a Password'
              }
              placeholder="Password..."
              type="password"
              value={password}
              handleChange={(password) => setFormData({ password }, 'password')}
              error={validationErrs.password}
              disabled={loading || getInvitationLoading}
            />
          </Col>
        </Row>
        <Row mt="6" mtT="6">
          <Col w={[4, 6, 12]}>
            {!alreadyRegistered && (
              <Tip
                coachNotesStyle={true}
                ml={0}
                text={tipString}
                p="12px 16px"
              />
            )}
            <Checkbox
              mt="4"
              id="terms-of-use"
              aria-label="Terms of use checkbox"
              label={
                <T.P>
                  I agree to the Turning Pages{' '}
                  <T.Link
                    color={'neutralMain'}
                    weight="bold"
                    underline
                    external
                    to={navRoutes.EXTERNAL.TERMS_OF_USE}
                  >
                    Terms of Use
                  </T.Link>
                  <br />
                  By clicking Sign up I acknowledge the Shannon Trust{' '}
                  <T.Link
                    to={navRoutes.EXTERNAL.PRIVACY_POLICY}
                    color="neutralMain"
                    external
                    underline
                  >
                    Privacy Policy
                  </T.Link>
                </T.P>
              }
              checked={agreedOnTerms}
              handleChange={(agreedOnTerms) =>
                setFormData({ agreedOnTerms }, 'agreedOnTerms')
              }
              error={validationErrs.agreedOnTerms}
            />
          </Col>
        </Row>
        <S.ButtonsWrapper mt="6" mtT="6">
          <S.ButtonContainer w={[4, 6, 6]}>
            {loading ? <T.P mb="2">...loading</T.P> : null}
            {httpError && (
              <T.P mb="2" color="error">
                {httpError}
              </T.P>
            )}
            <BasicButton
              variant="primary"
              disabled={loading}
              loading={loading}
              aria-label="Create account button"
              type="submit"
              id="create-account-button"
            >
              Create organisation account
            </BasicButton>
          </S.ButtonContainer>
        </S.ButtonsWrapper>
      </S.Form>
      <Modal visible={showModal}>
        <Row>
          <Col w={[4, 12, 12]}>
            <T.P size="med" weight="bold" color="white">
              Cannot Open Invitation Links While Logged In
            </T.P>
            <T.P size="regular" color="white" mt={4} mb={4}>
              You can't open this link while you are logged in. Please log out
              first
            </T.P>

            <BasicButton variant="secondary" to={GENERAL.HOME} mt={4}>
              Go to dashboard
            </BasicButton>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default CreateAccount;
