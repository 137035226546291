import styled from '@emotion/styled';
import { Button as AntdButton, Tag as AntdTag } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 370px;
  .ant-table table {
    font-size: 1rem;
    font-weight: 400;
  }

  .ant-picker-input > input::placeholder {
    font-size: 1rem;
  }
`;

export const NameColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  span {
    color: ${({ theme }) => theme.colors['neutralMain']};
    font-weight: bold;
    font-size: 1rem;
  }
`;

export const Button = styled(AntdButton)`
  padding: 0;
`;

export const ApprovedStatusColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  button {
    font-weight: bold;
    font-size: 1rem;
    margin-left: -0.5rem;
  }
`;

export const Tag = styled(AntdTag)`
  background-color: ${({ bgColor, theme }) => theme.colors[bgColor] ?? bgColor};
  color: ${({ fontColor, theme }) => theme.colors[fontColor] ?? fontColor};
  border-radius: 24px;
  font-size: 1rem;
`;

export const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`;

export const TableTitleWrapper = styled.div`
  min-height: 72px;
  display: flex;
  align-items: center;
`;
