import { BasicInput, Dropdown } from '../../../../../components/Inputs';
import { typingHorizontalVariations } from '../../../../../constants';

import { convertSnakeCaseToSpaces } from 'helpers';
import * as S from '../style';

const CorrectAnswer = ({ errors, onOptionChange, correctOption, index }) => {
  const variationOptions = Object.keys(
    typingHorizontalVariations.correctOptionTypes
  ).map((e) => ({
    label: convertSnakeCaseToSpaces(e),
    value: e,
  }));

  const isPrefix =
    correctOption.type ===
    typingHorizontalVariations.correctOptionTypes.HAS_PREFIX;
  const isSuffix =
    correctOption.type ===
    typingHorizontalVariations.correctOptionTypes.HAS_SUFFIX;
  return (
    <>
      <Dropdown
        m={{ mt: index > 0 ? '7' : '5', mb: '3' }}
        placeholder="Select the option type"
        options={variationOptions}
        selected={
          correctOption.type
            ? {
                value: correctOption.type,
                label: convertSnakeCaseToSpaces(correctOption.type),
              }
            : null
        }
        handleChange={(value) => {
          onOptionChange({
            key: 'type',
            value,
            id: correctOption.id,
          });
        }}
        error={errors?.correctOptions?.[index]?.type}
      />

      {!!correctOption.type && (
        <S.AnswerInputWrapper isPrefix={isPrefix} isSuffix={isSuffix}>
          {isPrefix && (
            <BasicInput
              value={correctOption.prefix}
              handleChange={(value) => {
                onOptionChange({
                  key: 'prefix',
                  value,
                  id: correctOption.id,
                });
              }}
              error={errors?.correctOptions?.[index]?.prefix}
              helper="Provide Prefix"
              placeholder="ex. 7="
            />
          )}
          <BasicInput
            value={correctOption.option}
            handleChange={(value) => {
              onOptionChange({
                key: 'option',
                value,
                id: correctOption.id,
              });
            }}
            error={
              errors?.correctOptions && errors?.correctOptions?.[index]?.option
            }
            helper="Provide the correct answer"
            placeholder=""
          />
          {isSuffix && (
            <BasicInput
              value={correctOption.suffix}
              handleChange={(value) => {
                onOptionChange({
                  key: 'suffix',
                  value,
                  id: correctOption.id,
                });
              }}
              error={errors?.correctOptions?.[index]?.suffix}
              helper="Provide Suffix"
              placeholder="ex. cm"
            />
          )}
        </S.AnswerInputWrapper>
      )}
    </>
  );
};

export default CorrectAnswer;
