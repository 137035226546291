import styled from '@emotion/styled';
import { setMargin } from 'helpers';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentWrapper = styled.div`
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacings[6]};
  margin-right: ${({ theme }) => theme.spacings[6]};
`;

export const TextAudioWrapper = styled.div`
  width: 100%;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
  margin-bottom: ${({ theme, mb }) => theme.spacings[mb || 0]};
  display: flex;
  align-items: center;
  justify-content: ${({ jc }) => jc || 'center'};
`;

export const InputsWrapper = styled.div`
  ${setMargin}
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ isThermometer }) =>
    isThermometer ? 'flex-start' : 'center'};
  width: 100%;
  max-width: 420px;
`;

export const Row = styled.div`
  display: flex;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Square = styled.button`
  width: 42px;
  height: 42px;
  border: 1px solid ${({ theme }) => theme.colors.neutralMain};
  font-size: ${({ content, theme }) =>
    content?.length > 8
      ? `${theme.fontSizes[0]}px`
      : content?.length > 5
      ? `${theme.fontSizes[1]}px`
      : content?.length > 3
      ? `${theme.fontSizes[2]}px`
      : `${theme.fontSizes[4]}px`};
  background: ${({ selected, theme }) =>
    selected ? theme.colors.primaryMain : 'transparent'};
  padding: 0;
  margin: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  overflow: hidden;
`;
