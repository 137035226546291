import React from 'react';
import * as S from './style';
import Graphics from '../../../components/Graphics';
import Header from '../Header';
import Footer from '../Footer';
import { useMediaQuery } from 'react-responsive';
import theme from '../../../theme';
import { useAuth } from './../../../context/auth';
import { useNavigate } from 'react-router-dom';
import { Typography as T, Icon } from '../../../components';

const NewBack = ({
  children,
  image,
  sideColor = 'neutralLight',
  headerProps = {},
  fullWidth,
  isWithBackButton,
  ...props
}) => {
  const {
    user: { overlayColor },
  } = useAuth();
  let navigate = useNavigate();

  const isTablet = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.tablet})`,
  });

  const goBack = () => {
    navigate(-1);
  };

  return (
    <S.Wrapper {...props} bgColor={overlayColor}>
      {!isTablet && <Graphics image={image} bgColor={sideColor} general />}
      <S.Content>
        <Header exercise {...headerProps} />
        <S.ContentBody
          removePaddingTop
          fullWidth={fullWidth}
          headerType={headerProps.type}
        >
          {isWithBackButton && (
            <S.BackWrapper>
              <S.BackButton onClick={goBack}>
                <Icon
                  icon="arrowBack"
                  width="20px"
                  height="22px"
                  color="neutralMain"
                  style={{ marginRight: '10px' }}
                />
                <T.P size="regular" color="neutralMain">
                  Back
                </T.P>
              </S.BackButton>
            </S.BackWrapper>
          )}
          <S.CurrentPage>{children}</S.CurrentPage>
        </S.ContentBody>
        <Footer />
      </S.Content>
      {!isTablet && <Graphics image={image} bgColor={sideColor} general />}
    </S.Wrapper>
  );
};

export default NewBack;
