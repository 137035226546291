import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  padding: 0 48px;
  ${({ theme }) => theme.media.tablet} {
    padding: 0;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 625px;
  margin-inline: auto;
`;
