import { useFetch, usePost, formatQueryError } from '../utils/queries';
import { MILESTONES_BASE } from '../constants';

function useGetMilestoneNewWords({ milestoneId }, options = {}) {
  const context = useFetch({
    key: [`GET_MILESTONE_NEW_WORDS`, { milestoneId }],
    url: `${MILESTONES_BASE}/milestone-new-word/${milestoneId}`,
    reactQueryConfig: options,
  });
  return {
    ...context,
    newWords: context.data,
    error: formatQueryError(context),
  };
}

function useGetMilestonePreparationExercises({ milestoneId }, options = {}) {
  const context = useFetch({
    key: [`GET_MILESTONE_PREPARATION_EXERCISES`, { milestoneId }],
    url: `${MILESTONES_BASE}/${milestoneId}/preparation-exercises`,
    reactQueryConfig: options,
  });
  return {
    ...context,
    preparationExercises: context.data,
    error: formatQueryError(context),
  };
}

const useGetMilestones = (_, options = {}) => {
  const context = useFetch({
    key: [`GET_MILESTONES`],
    url: `${MILESTONES_BASE}`,
    reactQueryConfig: options,
  });

  return { ...context, error: formatQueryError(context) };
};

const useCreateMilestone = (_, options = {}) => {
  const context = usePost({
    url: `${MILESTONES_BASE}`,
    reactQueryConfig: { ...options, invalidateKeys: () => [`GET_MILESTONES`] },
  });

  return { ...context, error: formatQueryError(context) };
};

export {
  useGetMilestoneNewWords,
  useGetMilestonePreparationExercises,
  useGetMilestones,
  useCreateMilestone,
};
