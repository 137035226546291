import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';
import * as T from '../Typography';

const bgColors = {
  exercisesCompleted: 'neutralSurface',
  learningTime: 'neutralLight',
  coachingOnTheApp: 'neutralLight',
  noOfLearners: 'neutralSurface',
  newWords: 'primaryMain',
  milestoneReached: 'neutralMain',
  dateOfLastTaskCompletion: 'quaternaryMain',
  dateOfLastSessionWithCoach: 'secondaryMain',
};

export const Wrapper = styled.div`
  ${setMargin};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, type, backgroundColor }) =>
    backgroundColor || theme.colors[bgColors[type]]};
  max-width: 230px;
  width: 50%;
  height: 221px;
  .ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: ${({ theme }) => theme.colors.primaryMain};
    font-weight: bold;
  }
`;

export const ProgressText = styled(T.P)`
  display: flex;
  text-align: center !important;
  justify-content: center;
  padding-left: ${({ theme }) => theme.spacings[5]};
  padding-right: ${({ theme }) => theme.spacings[5]};
`;
export const StyledLink = styled(T.Link)`
  display: flex;
`;
