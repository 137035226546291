import { useState, useRef, Fragment } from 'react';
import {
  Grid,
  Typography as T,
  Inputs as I,
  Icon,
  Video,
  Loading,
  ProgressCard,
} from '../../../components';
import * as S from './style';
import { BasicButton } from 'components/Button';
import { useParams, useNavigate } from 'react-router-dom';
import { navRoutes } from '../../../constants';
import useCustomTrainingStepHook from './Hooks/useCustomTrainingStepHook';
import { MultiDropDown } from 'components/Inputs';
import { Divider } from 'antd';
import { BackButton } from 'components/BackButton';

const { Row, Col } = Grid;

const TickList = ({ text }) => {
  return (
    <Row mt="4">
      <Col w={[0.5, 1, 0.5]}>
        <Icon icon="tick" color="neutralMain" mt="1" />
      </Col>
      <Col w={[3.5, 11, 11]}>
        <Row inner>
          <T.P>{text}</T.P>
        </Row>
      </Col>
    </Row>
  );
};

const NumberedList = ({ text, index }) => {
  return (
    <Row mt="1">
      <Col w={[4, 1, 0.5]}>
        <T.P>{index}</T.P>
      </Col>
      <Col w={[4, 11, 11]}>
        <Row inner>
          <T.P>{text}</T.P>
        </Row>
      </Col>
    </Row>
  );
};

const DosDonts = ({ text, index }) => {
  return (
    <Row mt="1">
      <Col w={[4, 1, 0.5]}>
        <T.P>{index}</T.P>
      </Col>
      <Col w={[4, 11, 11]}>
        <Row inner>
          <T.P>{text}</T.P>
        </Row>
      </Col>
    </Row>
  );
};

const TrainingDetailPage = () => {
  const {
    activeStep,
    trainingContent,
    userId,
    isAPILoading,
    updateTrainingStep,
    refetchDefaultCompletedStep,
  } = useCustomTrainingStepHook();

  const [isFinished, setIsFinished] = useState(false);

  const { stepId } = useParams();
  const navigate = useNavigate();

  const buttonLabel = useRef('Mark this step as complete');

  if (activeStep > trainingContent.length) {
    navigate(navRoutes.COACH.TRAINING);
  }

  if (Number(stepId) > activeStep + 1) {
    navigate(navRoutes.COACH.TRAINING_STEP.replace(':stepId', activeStep + 1));
  }

  const handleCheckboxClick = () => {
    setIsFinished((state) => !state);
  };

  const isFirstItem = Number(stepId) === 1;
  const isLastItem = Number(stepId) === trainingContent.length;

  const goToNextItem = (isCompleted) => {
    const item = trainingContent[stepId - 1];
    const user = item?.user;
    const routeKey = item?.routeKey;
    const param = item?.param;

    const route = navRoutes[user][routeKey];

    if (!isLastItem) {
      const nextStepId = Number(stepId) + 1;

      let navRoute = route;
      navRoute = navRoute.replace(param, nextStepId);
      navigate(navRoute, { replace: true });
      return;
    } else {
      if (isCompleted) {
        navigate(navRoutes.COACH.TRAINING_REVIEW);
        return;
      }
      navigate(navRoutes.COACH.TRAINING_COMPLETE, {
        state: {
          showCongrats: true,
        },
      });
    }
  };

  const goToPrevItem = (isCompleted) => {
    const item = trainingContent[stepId - 1];
    const user = item?.user;
    const routeKey = item?.routeKey;
    const param = item?.param;

    const route = navRoutes[user][routeKey];

    if (!isFirstItem) {
      const nextStepId = Number(stepId) - 1;

      let navRoute = route;
      navRoute = navRoute.replace(param, nextStepId);
      navigate(navRoute);
    }
  };

  const onSelectChange = (step) => {
    const item = trainingContent[step];
    const stepId = step + 1;
    const user = item?.user;
    const routeKey = item?.routeKey;
    const param = item?.param;

    const route = navRoutes[user][routeKey];

    let navRoute = route;
    navRoute = navRoute.replace(param, stepId);
    navigate(navRoute);
  };

  const handleUpdateStep = async (customStepId, isCompleted) => {
    if (!isCompleted) {
      await updateTrainingStep(
        {
          customStepId,
          userId,
          step: Number(stepId),
        },

        {
          onSettled: async () => {
            await refetchDefaultCompletedStep();
            goToNextItem(isCompleted);
          },
        }
      );
      setIsFinished(false);
      return;
    }
    goToNextItem(isCompleted);
  };

  const Text = (text) => {
    const lines = text.split('\n').map((line, index) => (
      <Fragment key={index}>
        {line}
        <br />
      </Fragment>
    ));

    return (
      <Col w={[4, 12, 12]} mt="2" mb="4">
        <T.P>{lines}</T.P>
      </Col>
    );
  };

  const trainingContentPerRow = trainingContent.filter(
    (_, index) => index === Number(stepId) - 1
  );

  const title = trainingContentPerRow?.[0]?.title;
  const introduction = trainingContentPerRow?.[0]?.introduction;
  const videoURLs = trainingContentPerRow?.[0]?.videoURLs;
  const items = trainingContentPerRow?.[0]?.items;
  const descriptionAsHtml = trainingContentPerRow?.[0]?.descriptionAsHtml;
  const customStepId = trainingContentPerRow?.[0]?.customStepId;
  const isCompleted = trainingContentPerRow?.[0]?.isCompleted;

  if (isCompleted) {
    buttonLabel.current = 'Back to training page';
  }

  const isStepHaveVideo = videoURLs?.length > 0;

  const isCheckBoxTrue = isFinished || isCompleted;

  const isNextButtonDisabledForVideoStep = isStepHaveVideo && !isCheckBoxTrue;

  const isNextButtonDisabled = isAPILoading || isNextButtonDisabledForVideoStep;

  if (isAPILoading) {
    return <Loading />;
  }

  const renderItems = (items) => {
    return items.map((item, index) => {
      return (
        <S.ListWrapper type={item.type} key={index}>
          <T.H2 weight="bold" color="neutralMain">
            {item?.title}
          </T.H2>
          {item?.introduction && <T.P>{item?.introduction}</T.P>}
          {item?.list &&
            item.list.map((b, i) => {
              switch (item.type) {
                case 'TickList':
                  return <TickList text={b} />;
                case 'NumberedList':
                  return <NumberedList text={b} index={String(i + 1) + '.'} />;
                case 'Dos':
                  return <DosDonts text={b} index={String(i + 1) + '.'} />;
                case 'Donts':
                  return <DosDonts text={b} index={String(i + 1) + '.'} />;
                case 'Paragraph':
                  return Text(b);
                default:
                  return <TickList text={b} />;
              }
            })}
        </S.ListWrapper>
      );
    });
  };

  return (
    <S.PageWrapper>
      <Row gap="60px 48px" gapT="32px 24px" gapM="0">
        <Col w={[4, 12, 12]} mbM="5">
          <BackButton link={navRoutes.COACH.DASHBOARD} />
        </Col>
        <Col w={[0, 0, 4]} maxWidth="385px">
          <S.AsideWrapper>
            <T.H1 mb="5">Coach Training</T.H1>
            <ProgressCard
              loading={isAPILoading}
              id={userId}
              activeStep={activeStep}
              data={trainingContent}
              completionMessage="🎉 Training complete"
              isAside
            />
          </S.AsideWrapper>
        </Col>
        <Col w={[4, 12, 0]} maxWidth="385px">
          <T.H1 mb="4">Coach Training</T.H1>
          <MultiDropDown
            multiple={false}
            disableCloseOnSelect={false}
            filterSelectedOptions={false}
            disableClearable
            options={trainingContent?.map((step, index) => ({
              label: `${index + 1}. ${step.title}}`,
              value: index,
            }))}
            defaultValue={{
              label: trainingContent[Number(stepId) - 1]?.title,
              value: Number(stepId) - 1,
            }}
            onChange={(_, { value }) => {
              onSelectChange(value);
            }}
            inputProps={{
              readOnly: true,
            }}
            wrapperProps={{
              answerProps: {
                mt: '0',
              },
            }}
          />
          <Divider />
        </Col>
        <Col w={[4, 12, 8]} maxWidth="767px" flex="1 !important">
          <Row mb="3" inner>
            <Col w={[4, 12, 12]}>
              <T.H1 weight="bold" color="neutralMain">
                {title}
              </T.H1>
            </Col>
            {introduction && Text(introduction)}
            {videoURLs?.map((video) => (
              <Col w={[4, 12, 12]} mt="2" mb="5" key={video.title}>
                <T.H2>{video?.title}</T.H2>
                <Video src={video?.url} />
              </Col>
            ))}
            {isStepHaveVideo && (
              <Col w={[4, 12, 12]} mt="2" mb="5">
                <I.Checkbox
                  checked={isFinished || isCompleted}
                  handleChange={handleCheckboxClick}
                  plain
                  label="I confirm I have watched the entire video"
                  disabled={isCompleted}
                />
              </Col>
            )}
            <Col w={[4, 12, 12]}>{items && renderItems(items)}</Col>
            <Col w={[4, 12, 12]}>
              <T.P
                style={{
                  width: '100%',
                }}
              >
                <S.CustomHTMLContentWrapper
                  dangerouslySetInnerHTML={{ __html: descriptionAsHtml }}
                />
              </T.P>
            </Col>
            <Col
              display="flex"
              dir="row"
              w={[4, 12, 12]}
              mt="4"
              maxWidth="390px"
              noWrap
              noWrapM={false}
            >
              <BasicButton
                variant="primary"
                disabled={isNextButtonDisabled}
                loading={isAPILoading}
                handleClick={() => handleUpdateStep(customStepId, isCompleted)}
                aria-label="Mark this step as complete"
                type="submit"
                id="create-account-button"
                mr="5"
                mrM="0"
                mbM="4"
                maxWidth="fit-content"
                maxWidthM="none"
                icon={isLastItem ? null : 'next'}
                iconProps={{
                  width: '20',
                  height: '12',
                }}
              >
                {isLastItem ? 'Finish training' : 'Next lesson'}
              </BasicButton>
              {!isFirstItem ? (
                <BasicButton
                  variant="tertiary"
                  handleClick={() => goToPrevItem()}
                  aria-label="Back to training page"
                  id="back-to-training-page-button"
                  maxWidth="fit-content"
                  maxWidthM="none"
                  icon={'back'}
                  iconProps={{
                    width: '20',
                    height: '12',
                  }}
                >
                  Previous lesson
                </BasicButton>
              ) : (
                <BasicButton
                  variant="tertiary"
                  handleClick={() => navigate(navRoutes.COACH.TRAINING_REVIEW)}
                  aria-label="Back to training page"
                  id="back-to-training-page-button"
                  maxWidth="fit-content"
                  maxWidthM="none"
                >
                  Back to training page
                </BasicButton>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </S.PageWrapper>
  );
};

export default TrainingDetailPage;
