const Copy2 = ({ width, height, color, ...props }) => (
  <svg
    width={width || '25'}
    height={height || '25'}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5451 4.03522C11.4801 4.24522 11.4451 4.46822 11.4451 4.69922C11.4451 5.11322 11.7811 5.44922 12.1951 5.44922H16.6951C16.894 5.44922 17.0847 5.3702 17.2254 5.22955C17.3661 5.0889 17.4451 4.89813 17.4451 4.69922C17.4451 4.47413 17.4114 4.2503 17.3451 4.03522M11.5451 4.03522C11.6871 3.57592 11.9724 3.17415 12.3593 2.88876C12.7462 2.60336 13.2143 2.44933 13.6951 2.44922H15.1951C16.2071 2.44922 17.0621 3.11722 17.3451 4.03522M11.5451 4.03522C11.1691 4.05822 10.7951 4.08522 10.4211 4.11522C9.29007 4.20922 8.44507 5.17222 8.44507 6.30722V8.44922M17.3451 4.03522C17.7211 4.05822 18.0951 4.08522 18.4691 4.11522C19.6001 4.20922 20.4451 5.17222 20.4451 6.30722V16.6992C20.4451 17.296 20.208 17.8683 19.7861 18.2902C19.3641 18.7122 18.7918 18.9492 18.1951 18.9492H15.9451M8.44507 8.44922H5.07007C4.44907 8.44922 3.94507 8.95322 3.94507 9.57422V20.8242C3.94507 21.4452 4.44907 21.9492 5.07007 21.9492H14.8201C15.4411 21.9492 15.9451 21.4452 15.9451 20.8242V18.9492M8.44507 8.44922H14.8201C15.4411 8.44922 15.9451 8.95322 15.9451 9.57422V18.9492M7.69507 15.9492L9.19507 17.4492L12.1951 13.6992"
      stroke={color || 'black'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Copy2;
