import { formulaTypes } from '../constants';

const getOperatorSymbol = (formulaType) => {
  if (formulaType === formulaTypes.SUM) {
    return '+';
  } else if (formulaType === formulaTypes.SUBTRACTION) {
    return '-';
  } else if (formulaType === formulaTypes.MULTIPLICATION) {
    return 'x';
  } else if (formulaType === formulaTypes.DIVISION) {
    return '÷';
  }

  throw Error(`formulaType: ${formulaType} is not supported`);
};

export default getOperatorSymbol;
