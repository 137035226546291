import styled from '@emotion/styled';
import { Col } from 'components/Grid';

export const LeftWrapper = styled(Col)`
  padding-right: ${({ theme }) => theme.spacings[6]};
  .ant-upload-list {
    display: flex;
    flex-direction: column-reverse;
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    background-color: ${({ theme }) => theme.colors.neutralMain};
  }

  .upload-image-style .ant-upload-list-item {
    position: relative;
    margin: 30px 0;

    &:before {
      position: absolute;
      top: -30px;
      content: 'File: ';
      width: 100%;
      height: 100%;
      color: red;
      display: block;
      font-size: 16px;
      color: ${({ theme }) => theme.colors.neutral90};
    }
  }
`;
export const RightWrapper = styled(Col)`
  padding-left: ${({ theme }) => theme.spacings[6]};
`;
export const UniqueLinkWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.secondaryLight};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral50};
  padding: ${({ theme }) => theme.spacings[6]};
  width: 100%;
`;

export const InputWrapper = styled.div`
  display: flex;
`;

export const LogoDivWrapper = styled.div`
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
  margin-bottom: 64px;
`;

export const DragAndDropContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings[4]};
`;

export const ColorsDivWrapper = styled.div`
  padding: ${({ theme }) => theme.spacings[6]};
  background-color: ${({ theme }) => theme.colors.primaryLight};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral50};
  width: 100%;
`;
