import * as T from 'components/Typography';
import * as S from './style';
import { navRoutes } from 'constants';

const PlayWithTheTool = () => {
  return (
    <S.Card bg="quaternaryLight">
      <T.H2 weight="bold" color="neutralMain" mb="2">
        Play with the tool
      </T.H2>
      <T.P>
        Here you can play with the tool as if you were a learner. Try out any of
        the exercises whenever you wish!
      </T.P>
      <S.Button
        bgColor="quaternaryMain"
        variant="secondary"
        to={navRoutes.COMMON.EXERCISES_PLAYGROUND + `?milestoneId=1`}
        aria-label="Find out more"
        type="submit"
        id="find-out-more"
        mt={5}
        external
      >
        Play with tool{' '}
      </S.Button>
    </S.Card>
  );
};

export default PlayWithTheTool;
