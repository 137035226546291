import { Icon, Modal, PlayAudioMsg, Typography as T } from 'components';
import { chatMessagesTypes } from '../../../../constants';
import * as S from './style';

const ViewMsgModal = ({ viewedMsg, setViewedMsg }) => {
  const isAudioMsg = viewedMsg.msgType === chatMessagesTypes.AUDIO;
  return (
    <Modal
      maxWidth={isAudioMsg ? '530px' : '800px'}
      bgColor="white"
      visible={!!viewedMsg.id}
      setIsModalVisible={() => setViewedMsg({})}
    >
      <S.HeadWrapper>
        <T.H2>Reported message</T.H2>
        <S.StyledLink onClick={() => setViewedMsg({})}>
          <Icon icon="close" />
          <T.P>Cancel</T.P>
        </S.StyledLink>
      </S.HeadWrapper>
      {isAudioMsg ? (
        <PlayAudioMsg audioUrl={viewedMsg.audioUrl} />
      ) : (
        <TextMsg content={viewedMsg.content} />
      )}
    </Modal>
  );
};

const TextMsg = ({ content = '' }) => {
  return (
    <T.Pre mt="6" padding="12px">
      {content}
    </T.Pre>
  );
};

export default ViewMsgModal;
