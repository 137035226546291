import styled from '@emotion/styled';
import { setMargin } from 'helpers';

export const BackButtonContainer = styled.div`
  ${setMargin}
`;

export const BackButtonStyle = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[2]};
  justify-content: center;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.neutralMain};

  div {
    height: 20px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const BackTextWrapper = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 20px;
  height: 85%;
`;
