import { css } from '@emotion/react';

const style = css`
  @font-face {
    font-family: 'BasicCommercial';
    src: url('/BasicCommercialRoman.woff2') format('woff2'),
      url('/BasicCommercialRoman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'BasicCommercial';
    src: url('/BasicCommercialBold.woff2') format('woff2'),
      url('/BasicCommercialBold.woff') format('woff');
    font-weight: bold;
    font-style: bold;
  }

  pre {
    font-family: 'BasicCommercial', sans-serif;
    font-weight: normal;
    font-size: 1em;
  }

  code,
  kbd,
  samp {
    font-family: 'BasicCommercial', sans-serif;
    font-weight: normal;
    font-size: 1em;
  }

  small {
    font-size: 80%;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'BasicCommercial', sans-serif !important;
    font-weight: normal;
    word-wrap: break-word;
  }

  /* to override body margin from antd */
  .sb-show-main.sb-main-padded {
    margin: 0;
    padding: 0;
  }

  /* to override elipsis on dropdown options from antd */
  .ant-select-item-option-content {
    white-space: normal;
    height: auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-family: 'BasicCommercial', sans-serif;
    font-weight: bold;
  }
  p,
  a {
    margin: 0;
    font-family: 'BasicCommercial', sans-serif;
    font-weight: normal;
  }

  code {
    font-family: 'BasicCommercial', sans-serif;
    font-weight: normal;
  }

  html {
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%;
  }

  *,
  *:after,
  *:before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  *:focus {
    outline: none;
  }

  *:focus-visible {
    outline: #0061ce solid 2px;
  }

  *:active {
    outline: none;
  }

  /* Audio: Remove big play button (leave only the button in controls). */
  .video-js.vjs-audio .vjs-big-play-button {
    display: none;
  }
  /* Audio: Make the controlbar visible by default */
  .video-js.vjs-audio .vjs-control-bar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  /* Make player height minimum to the controls height so when we hide video/poster area the controls are displayed correctly. */
  .video-js.vjs-audio {
    min-height: 2rem;
  }

  .vjs-text-track-display {
    display: none;
  }

  // Remove recaptcha badge
  .grecaptcha-badge {
    visibility: hidden;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #405ed6;
    color: white;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #051730;
    color: white;
  }
  .ant-select-item-option {
    min-height: 40px;
  }
  .ant-select-item-option-content {
    font-size: 1rem;
    display: flex;
    align-items: center;
  }

  .ant-radio-button-wrapper {
    :hover {
      .ant-typography {
        color: #405ed6 !important;
      }
    }
  }
  // .ant-input:focus,
  // .ant-input-password:focus,
  // .ant-input-search:focus,
  // .ant-input-affix-wrapper:focus,
  // .ant-select-selector:focus {
  //   border-color: inherit !important;
  //   outline: 0;
  //   -webkit-box-shadow: 0 0 0 0px rgba(87, 168, 233, 0.2);
  //   box-shadow: 0 0 0 0px rgba(87, 168, 233, 0.2);
  // }

  // .ant-input:hover,
  // .ant-input-password:hover,
  // .ant-input-search:hover,
  // .ant-input-affix-wrapper:hover,
  // .ant-select-selector:hover {
  //   border-color: inherit !important;
  //   outline: 0;
  //   -webkit-box-shadow: 0 0 0 0px rgba(87, 168, 233, 0.2);
  //   box-shadow: 0 0 0 0px rgba(87, 168, 233, 0.2);
  // }

  .ant-dropdown-menu {
    padding: 0;
  }

  .ant-dropdown-menu-item:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .ant-dropdown-menu-item:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .ant-dropdown-menu-item:not(:first-child):not(:last-child) {
    border-radius: 8px;
  }

  .ant-dropdown-menu-item:not(:last-child) {
    border-bottom: 1px solid #e5e7eb;
  }

  .ant-dropdown-menu-item:hover {
    background-color: #f0f1f5;
  }

  .ant-dropdown-menu-item {
    color: #002666;
    height: 45px;
  }
`;

export default style;
