import axios from 'axios';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { CHAT_MESSAGES } from '../constants';
import {
  formatQueryError,
  queryClient,
  useFetch,
  usePatch,
  usePost,
} from '../utils/queries';

const fetchMessages = async ({ pageParam, receiverId }) => {
  const { dir, cursor } = pageParam || {};
  const response = await axios.get(`${CHAT_MESSAGES}/my-msgs/${receiverId}`, {
    params: {
      dir,
      cursor,
      limit: 20, // Fetch 20 messages at a time
    },
  });
  return response.data;
};

function useGetMyChatMsgs({ receiverId }, options = {}) {
  const context = useInfiniteQuery({
    queryKey: [`CHAT_MSGS`, { receiverId }],
    queryFn: ({ pageParam = null }) => fetchMessages({ pageParam, receiverId }),
    getNextPageParam: (lastPage) => {
      return lastPage?.myMsgs.length
        ? {
            cursor: lastPage.myMsgs[lastPage.myMsgs.length - 1].id,
            dir: 'next',
          }
        : lastPage.meta?.oldCursor
        ? { cursor: lastPage.meta?.oldCursor, dir: 'next' }
        : {};
    },
    getPreviousPageParam: (firstPage) => {
      return firstPage?.myMsgs.length
        ? { cursor: firstPage.myMsgs[0].id, dir: 'prev' }
        : undefined;
    },
    initialPageParam: 0,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
}

const useSendChatMsg = ({ receiverId }, options = {}) => {
  const context = usePost({
    url: `${CHAT_MESSAGES}/my-msgs/${receiverId}`,
    reactQueryConfig: {
      ...options,
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useSetMsgSeen = (options = {}) => {
  const context = usePatch({
    getUrl: ({ msgId }) => `${CHAT_MESSAGES}/${msgId}/set-as-seen`,
    reactQueryConfig: {
      ...options,
      // @Ramy this is not invalidating pls check
      invalidateKeys: () => [
        [`CHAT_MSGS`],
        // ['GET_USER_HAS_NEW_MSGS'],
        ['GET_LEARNERS'],
      ],
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useGetUserHasNewMsgs = (options = {}) => {
  const context = useFetch({
    key: [`GET_USER_HAS_NEW_MSGS`],
    url: `${CHAT_MESSAGES}/user-has-new-msgs`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useReportChatMsg = ({ receiverId }, options = {}) => {
  const context = usePost({
    getUrl: ({ msgId }) => `${CHAT_MESSAGES}/${msgId}/report`,
    reactQueryConfig: {
      ...options,
      onSuccess: (data, variables) => {
        // Update the cache to mark the message as reported
        queryClient.setQueryData([`CHAT_MSGS`, { receiverId }], (oldData) => {
          if (!oldData) return oldData;

          return {
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              myMsgs: page.myMsgs.map((msg) =>
                msg.id === variables.msgId ? { ...msg, reported: true } : msg
              ),
            })),
          };
        });
      },
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useGetReportedMsgsForAdmin = ({ pageParam = {} }, options = {}) => {
  const context = useFetch({
    key: [`ADMIN_REPORTED_MSGS`, pageParam.page],
    axiosParams: pageParam,
    url: `${CHAT_MESSAGES}/reported-chat-messages`,
    reactQueryConfig: { ...options, placeholderData: keepPreviousData },
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useToggleReportedMsgStatus = ({ page } = {}, options = {}) => {
  const context = usePatch({
    getUrl: ({ reportedChatMsgId }) =>
      `${CHAT_MESSAGES}/reported-chat-messages/${reportedChatMsgId}/toggle-status`,
    reactQueryConfig: {
      ...options,
      onSuccess: (data, variables) => {
        queryClient.setQueryData([`ADMIN_REPORTED_MSGS`, page], (oldData) => {
          if (!oldData) return oldData;

          return {
            ...oldData,
            reportedMsgs: oldData.reportedMsgs.map((msg) => {
              let status = msg.status;
              if (msg.id === variables.reportedChatMsgId) {
                status = status === 'RESOLVED' ? 'UNRESOLVED' : 'RESOLVED';
              }

              return {
                ...msg,
                status,
              };
            }),
          };
        });
      },
    },
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

export {
  useGetMyChatMsgs,
  useSendChatMsg,
  useGetUserHasNewMsgs,
  useSetMsgSeen,
  useReportChatMsg,
  useGetReportedMsgsForAdmin,
  useToggleReportedMsgStatus,
};
