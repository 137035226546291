import { ORGANISATIONS_BASE } from '../constants';
import {
  useFetch,
  usePost,
  formatQueryError,
  usePatch,
} from '../utils/queries';

const useGetOrganisationByIdOrUniqueSlug = (
  { id, uniqueSlug },
  options = {}
) => {
  const url = uniqueSlug
    ? `${ORGANISATIONS_BASE}/unique-slug/${uniqueSlug}`
    : `${ORGANISATIONS_BASE}/${id}`;

  const context = useFetch({
    key: [`GET_ORGANISATION_BY_ID`, { id, uniqueSlug }],
    url,
    reactQueryConfig: options,
  });

  return {
    ...context,
    organisation: context.data,
    error: formatQueryError(context),
  };
};

const useAddOrganisation = (_, options = {}) => {
  const context = usePost({
    url: `${ORGANISATIONS_BASE}`,
    reactQueryConfig: {
      invalidateKeys: () => [[`GET_ORGANISATIONS`]],
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useUpdateOrganisation = ({ id }, options = {}) => {
  const context = usePatch({
    url: `${ORGANISATIONS_BASE}/${id}`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [
        [`GET_ORGANISATION_BY_ID`, { id }],
        [`GET_ORGANISATIONS`],
      ],
    },
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useUpdateOrganisationCustomisation = ({ id }, options = {}) => {
  return usePatch({
    url: `${ORGANISATIONS_BASE}/${id}/customisation`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [
        [`GET_ORGANISATION_BY_ID`, { id }],
        [`GET_ORGANISATIONS`],
      ],
    },
  });
};

const useGetOrganisationsCount = ({ searchValue }, options = {}) => {
  const context = useFetch({
    key: `GET_ORGANISATIONS_COUNT`,
    url: `${ORGANISATIONS_BASE}/count`,
    axiosParams: {
      search: searchValue,
    },
    reactQueryConfig: options,
  });

  return {
    ...context,
    organisationsCount: context.data,
    error: formatQueryError(context),
  };
};

const useGetOrganisations = ({
  page,
  limit,
  statusType,
  searchValue,
  sortField,
  sortOrder,
  reactQueryConfig = {},
}) => {
  const context = useFetch({
    key: [
      `GET_ORGANISATIONS`,
      { page, limit, statusType, searchValue, sortField, sortOrder },
    ],
    url: `${ORGANISATIONS_BASE}`,
    axiosParams: { page, limit, statusType, searchValue, sortField, sortOrder },
    reactQueryConfig,
  });

  return {
    ...context,
    organisations: context.data,
    error: formatQueryError(context),
  };
};

const useUpdateOrgAdminToFacilitator = ({ id }, options = {}) => {
  const context = usePatch({
    url: `${ORGANISATIONS_BASE}/${id}/is-facilitator`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [
        [`GET_ORGANISATION_BY_ID`, { id }],
        [`GET_ORGANISATIONS`],
        ['LOGGED_IN_USER_INFO'],
      ],
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useGetOrganisationRecruitmentForm = (
  { id, uniqueSlug },
  options = {}
) => {
  const url = uniqueSlug
    ? `${ORGANISATIONS_BASE}/unique-slug/${uniqueSlug}/recruitment-form`
    : `${ORGANISATIONS_BASE}/${id}/recruitment-form`;

  const context = useFetch({
    key: `GET_ORGANISATION_RECRUITMENT_FORM`,
    url,
    reactQueryConfig: options,
  });

  return {
    ...context,
    recruitmentForm: context.data,
    error: formatQueryError(context),
  };
};

const useUpdateRecruitmentForm = ({ id }, options = {}) => {
  return usePatch({
    url: `${ORGANISATIONS_BASE}/${id}/recruitment-form`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [[`GET_ORGANISATION_RECRUITMENT_FORM`]],
    },
  });
};

const useUpdateOrganisationStatus = (_, options = {}) => {
  const context = usePatch({
    getUrl: ({ id }) => `${ORGANISATIONS_BASE}/${id}/status`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [
        ['GET_ORGANISATIONS'],
        ['GET_ORGANISATIONS_COUNT'],
      ],
    },
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useSignupOrganisation = (_, options = {}) => {
  const context = usePatch({
    getUrl: ({ id }) => `${ORGANISATIONS_BASE}/signup/${id}`,
    reactQueryConfig: options,
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useGetOrganisationByInviteToken = ({ token }, options = {}) => {
  const context = useFetch({
    key: [`GET_ORGANISATION_BY_INVITE_TOKEN`, { token }],
    url: `${ORGANISATIONS_BASE}/signup-token/${token}`,
    reactQueryConfig: options,
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useGetOrganisationTrainingProgress = (
  { organisationId },
  options = {}
) => {
  const context = useFetch({
    key: [`GET_ORGANISATION_TRAINING_PROGRESS`, { organisationId }],
    url: `${ORGANISATIONS_BASE}/${organisationId}/progress`,
    reactQueryConfig: options,
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

export {
  useGetOrganisations,
  useAddOrganisation,
  useGetOrganisationByIdOrUniqueSlug,
  useUpdateOrganisation,
  useUpdateOrganisationCustomisation,
  useUpdateOrgAdminToFacilitator,
  useGetOrganisationsCount,
  useGetOrganisationRecruitmentForm,
  useUpdateRecruitmentForm,
  useUpdateOrganisationStatus,
  useSignupOrganisation,
  useGetOrganisationByInviteToken,
  useGetOrganisationTrainingProgress,
};
