import { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { BasicButton } from './../../../components/Button';
import validate from './validation';
import { useGetExerciseById } from './../../../api-calls/exercises.queries';
import {
  useGetStep,
  useCreateStep,
  useDeleteStep,
  useUpdateStep,
} from '../../../api-calls/steps.queries';

import { BasicInput, Dropdown } from './../../../components/Inputs';
import { comprehensionsKeys, exerciseTypes } from './../../../constants';
import { useGeneralState } from './../../../context/general-state';
import Preview from './Preview';
import * as S from './style';
import {
  replaceMediaKeysWithUrls,
  convertSnakeCaseToSpaces,
} from './../../../helpers';
import StepForms from './StepForms';
import SuccessModal from './SuccessModal';
import Loading from '../../../components/Loading';
import { CMS } from '../../../constants/nav-routes';
import NavigateExercisesSteps from './NavigateExercisesSteps';
import { useStepForm } from 'CMS/Providers/StepFormProvider';

const exerciseTypesComprehension = [
  exerciseTypes.TYPING,
  exerciseTypes.MULTIPLE_CHOICE,
  exerciseTypes.REORDER,
  exerciseTypes.COMPREHENSION_LISTEN_AND_RESPOND,
  // exerciseTypes.SELECT_RECIPES,
];

const exerciseTypesDefault = [
  exerciseTypes.LISTEN_AND_REPEAT,
  exerciseTypes.LISTEN_AND_REPEAT_MULTIPLE,
  exerciseTypes.DRAG_AND_DROP_1,
  exerciseTypes.DRAG_AND_DROP_2,
  exerciseTypes.DRAG_AND_DROP_ORDER_SOUNDS,
  exerciseTypes.DRAG_AND_DROP_CREATE_ORDER,
  exerciseTypes.SPOT_THE_WORD,
  exerciseTypes.WORD_BUILD,
  exerciseTypes.VOWEL_BREAK,
  exerciseTypes.LISTEN_AND_REPEAT_GRID,
  exerciseTypes.LISTEN_AND_REPEAT_GRID_HIDDEN,
  exerciseTypes.WORD_JOIN_1,
  exerciseTypes.WORD_JOIN_2,
  exerciseTypes.WORD_JOIN_3,
  exerciseTypes.SENTENCE_FILL,
  exerciseTypes.TYPE_NEW_ENDINGS,
  exerciseTypes.COMBINE_WORDS,
  exerciseTypes.COMBINE_WORDS_PRE,
  exerciseTypes.TEXT_FINDER,
  exerciseTypes.COMPREHENSION_TEXT_FINDER,
  exerciseTypes.MULTIPLE_CHOICE,
  exerciseTypes.MULTIPLE_CHOICE_BUBBLE,
  exerciseTypes.MULTIPLE_CHOICE_GRID,
  exerciseTypes.TRACE,
  exerciseTypes.DRAW,
  exerciseTypes.QUESTION_AND_ANSWER,
  exerciseTypes.TYPING_HORIZONTAL,
  exerciseTypes.FILL_IN_THE_GRID,
  exerciseTypes.PYRAMID,
  exerciseTypes.GRID_WITH_TYPING_ANSWER,
  exerciseTypes.FACT_FAMILY,
  exerciseTypes.FORMULAE,
  exerciseTypes.COLUMNS_TYPING,
  exerciseTypes.COMPREHENSION_SELECT,
];

const CreateStep = () => {
  const {
    state,
    updateFormFields,
    updateDataFields,
    updateFormMetaData,
    resetDataFields,
  } = useStepForm();
  const { form, data, getExerciseLoading, disabled, key } = state;
  const submitAttempt = useRef(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { id, stepId } = useParams();
  const location = useLocation();
  const isRedirected = location.state?.isRedirected;
  const activeStep = useRef({
    id: null,
    mode: stepId === 'new' ? 'create' : 'update',
  });
  const { state: generalState } = useGeneralState();
  const { data: exercise = {}, isSuccess: isGetExerciseSuccess } =
    useGetExerciseById({ id });
  const isComprehension = comprehensionsKeys.includes(exercise?.key);
  const navigate = useNavigate();
  const {
    data: step = {},
    isLoading: getStepLoading,
    isSuccess,
  } = useGetStep(
    { id: stepId },
    {
      enabled: stepId !== 'new' && !isRedirected,
    }
  );

  const { mutateAsync: createStepMutation, isLoading: creating } =
    useCreateStep();

  const { mutateAsync: updateStepMutation, isLoading: updating } =
    useUpdateStep({ id: stepId });

  const { mutateAsync: deleteStepMutation, isLoading: deleting } =
    useDeleteStep({ id: stepId });

  const _exerciseTypesComprehension =
    isComprehension && exercise?.key === 'M5_P164&166'
      ? [...exerciseTypesComprehension, exerciseTypes.SELECT_RECIPES]
      : exerciseTypesComprehension;

  useEffect(() => {
    if (isSuccess && !isRedirected) {
      updateDataFields(
        {
          ...step.data,
        },
        false
      );
      updateFormFields(
        {
          id: step.id,
          type: step.type,
          order: step.order,
          imageKey: step.imageKey,
          audioKey: step.audioKey,
        },
        false
      );
    }
  }, [
    isRedirected,
    isSuccess,
    step.id,
    step.audioKey,
    step.data,
    step.imageKey,
    step.order,
    step.type,
    updateDataFields,
    updateFormFields,
  ]);

  useEffect(() => {
    if (
      isGetExerciseSuccess &&
      stepId === 'new' &&
      form.order === 0 &&
      !isRedirected
    ) {
      updateFormFields({
        order:
          Math.max(...(exercise?.steps?.map((e) => e.order) || [0]), 0) + 1,
      });
    }
  }, [
    exercise?.steps,
    isGetExerciseSuccess,
    stepId,
    updateFormFields,
    isRedirected,
    form.order,
  ]);

  // This is used for resetting the route state
  // to re-refetch the step data on refresh
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      window.history.replaceState(null, '');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createStep = async () => {
    createStepMutation(
      { exerciseId: id, ...form, data },
      {
        onSuccess: (_data) => {
          activeStep.current = { id: _data.id, mode: 'create' };
          setIsModalVisible(true);
          updateFormMetaData({ isDirty: false });
        },
      }
    );
  };

  const deleteStep = async () => {
    deleteStepMutation(
      { id: stepId },
      {
        onSuccess: () => {
          navigate(CMS.VIEW_EXERCISE_STEPS.replace(':id', id));
        },
      }
    );
  };

  const updateStep = async () => {
    updateStepMutation(
      {
        id: stepId,
        exerciseId: id,
        ...form,
        data,
      },
      {
        onSuccess: () => {
          activeStep.current = { id: stepId, mode: 'update' };
          setIsModalVisible(true);
          updateFormMetaData({ isDirty: false });
        },
      }
    );
  };

  const validateForm = useCallback(() => {
    try {
      validate({
        ...form,
        ...data,
        isComprehension,
      });

      updateFormMetaData({
        validationErrs: {
          hasError: false,
        },
      });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        updateFormMetaData({
          validationErrs: {
            hasError: true,
            ...error.inner,
          },
        });
      }

      return false;
    }
  }, [data, form, isComprehension, updateFormMetaData]);

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
  }, [data, validateForm]);

  const handleSubmit = () => {
    submitAttempt.current = true;

    const isValid = validateForm();
    if (!isValid) return;
    if (stepId === 'new') createStep();
    else updateStep();
  };

  const types = Object.keys(exerciseTypes)
    .filter((e) =>
      isComprehension
        ? _exerciseTypesComprehension.includes(e)
        : exerciseTypesDefault.includes(e)
    )
    .map((e) => ({
      label: convertSnakeCaseToSpaces(e),
      value: e,
    }));

  if ((stepId !== 'new' && getStepLoading) || getExerciseLoading) {
    return <Loading />;
  }

  const increaseWidth = [
    exerciseTypes.VOWEL_BREAK,
    exerciseTypes.TYPING_HORIZONTAL,
  ].includes(state.form.type);
  const loading = creating || updating || deleting;

  return (
    <S.Wrapper>
      <S.Form>
        <BasicInput
          label="Step order in exercise"
          value={form.order ?? ''}
          handleChange={(order) => updateFormFields({ order })}
          m={{ mb: 5 }}
          type="number"
          disabled={stepId === 'new'}
        />

        <Dropdown
          label="Type"
          options={types}
          selected={
            form.type
              ? {
                  value: form.type,
                  label: convertSnakeCaseToSpaces(form.type),
                }
              : ''
          }
          // search
          handleChange={(type) => {
            updateFormFields({ type });
            resetDataFields();
          }}
          m={{ mb: 5 }}
          disabled={stepId !== 'new'}
        />

        {form.type && <StepForms type={form.type} exercise={exercise} />}

        <BasicButton
          onClick={handleSubmit}
          mt={5}
          loading={loading}
          disabled={disabled}
        >
          {stepId === 'new' ? 'Submit' : 'Update'}
        </BasicButton>
        {stepId !== 'new' && (
          <BasicButton
            onClick={() => {
              activeStep.current = { mode: 'confirmDelete' };
              setIsModalVisible(true);
            }}
            mt={5}
            loading={loading}
            variant="transparent"
            customColor="error"
          >
            Delete Step
          </BasicButton>
        )}
        <NavigateExercisesSteps
          exerciseId={exercise?.id}
          stepId={stepId}
          steps={exercise?.steps}
        />
      </S.Form>
      {form.type && (
        <S.Preview key={key} increaseWidth={increaseWidth}>
          <Preview
            type={form.type}
            exercise={exercise}
            step={{
              ...replaceMediaKeysWithUrls(
                { ...form, data },
                generalState.preferredVoice
              ),
            }}
          />
        </S.Preview>
      )}
      <SuccessModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        stepId={activeStep.current?.id}
        mode={activeStep.current?.mode}
        onConfirmDelete={deleteStep}
        exerciseId={id}
      />
    </S.Wrapper>
  );
};

export default CreateStep;
