import styled from '@emotion/styled';
import { setMargin } from 'helpers';

export const TextAudioWrapper = styled.div`
  ${setMargin};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ jc }) => jc || 'center'};
`;

export const OuterWrapper = styled.div`
  ${setMargin}
  width: ${({ w }) => w ?? '100%'};
  padding: 24px;
  background-color: ${({ bg, theme }) => bg && theme.colors[bg]};
  ${({ theme }) => theme.media.mobile} {
    padding: 12px;
  }
`;

export const Divider = styled.div`
  width: ${({ w }) => w ?? '80%'};
  padding-top: ${({ theme }) => theme.spacings[4]};
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin: ${({ theme }) => theme.spacings[4]} auto;
`;
