import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tabs } from 'antd';
import UseDebounce from '../../Hooks/UseDebounce';
import * as T from 'components/Typography';

import { mediaTypes } from './../../constants';
import { useGetMediaCms, useGetMediaTags } from '../../api-calls/media.queries';
import Table from './Table';
import Search from './Search';
import { Row } from 'components/Grid';
import { getMediaType } from '../utils';
import VoTable from './VoTable';
import MediaUpdate from 'CMS/MediaUpdate';
const { TabPane } = Tabs;

const MediaPage = () => {
  const [exactMatch, setExactMatch] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const mediaType = searchParams.get('type');
  const tabKey = searchParams.get('tabKey');

  const [query, setQuery] = useState('');
  const debouncedSearch = UseDebounce(query, 500);
  const { media, isLoading, isError, error } = useGetMediaCms(
    {
      type: tabKey,
      query: debouncedSearch || '',
      exactMatch,
    },
    { enabled: tabKey !== 'new' }
  );

  const { tags } = useGetMediaTags();

  useEffect(() => {
    if (!tabKey) {
      if (mediaType) {
        const _mediaType = getMediaType(mediaType);
        searchParams.set('tabKey', _mediaType);
      } else {
        searchParams.set('tabKey', mediaTypes.IMAGE);
      }
      setSearchParams(searchParams, { replace: true });
    }
  }, [mediaType, searchParams, setSearchParams, tabKey]);

  const handleTabChange = (key) => {
    searchParams.set('tabKey', key);
    if (key === 'new') {
      searchParams.set('mediaId', 'new');
    } else {
      searchParams.delete('mediaId');
    }
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <>
      <Row jc="space-between" ai="center">
        <T.H1>Manage Media</T.H1>
        <Search
          query={query}
          setQuery={setQuery}
          exactMatch={exactMatch}
          setExactMatch={setExactMatch}
        />
      </Row>
      <Tabs
        defaultActiveKey={mediaTypes.IMAGE}
        onChange={handleTabChange}
        key={tabKey}
        activeKey={tabKey}
        style={{
          marginTop: 10,
          paddingLeft: 14,
          paddingRight: 14,
        }}
      >
        <TabPane tab="Images" key={mediaTypes.IMAGE} />
        <TabPane tab="Audio" key={mediaTypes.AUDIO} />
        <TabPane tab="Recording list" key={mediaTypes.VO_AUDIO} />
        {/* {process.env.REACT_APP_IS_MEDIA_UPLOAD_ENABLED && ( */}
        <TabPane tab="New File" key="new" />
      </Tabs>
      {isError ? (
        <>
          <T.P color="error" mt={4} ml={6}>
            Something went wrong
          </T.P>
          <T.P color="error" ml={6}>
            {error.message}
          </T.P>
        </>
      ) : tabKey === mediaTypes.VO_AUDIO ? (
        <VoTable data={media} tags={tags} loading={isLoading} />
      ) : tabKey === 'new' ? (
        <MediaUpdate />
      ) : (
        <Table data={media} tags={tags} loading={isLoading} />
      )}
    </>
  );
};

export default MediaPage;
