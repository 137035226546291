import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as T from '../../../../components/Typography';
import { PlayButton } from '../../../../components/Button';
import { Markdown } from '../../../../components';
import StepFooterButtons from '../StepFooterButtons';
import { Row } from '../../../../components/Grid';
import * as S from './style';
import InputsWrapper from './InputsWrapper';

const FillInTheGrid = ({
  data: {
    variation,
    textAudio,
    textAudioUrl,
    rowSize,
    columnSize,
    gridStart,
    gridEnd,
    gridReplacedWithText,
    correctOptions,
    rows,
  } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  isLoading,
}) => {
  const [enableNext, setEnableNext] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setEnableNext(false);
  }, [location]);

  if (!variation) {
    return (
      <S.Wrapper>
        <Row jc="center" mb={6}>
          <T.P color="error" m="0" mt="1">
            Please choose a variation
          </T.P>
        </Row>
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        {!!textAudio && (
          <S.TextAudioWrapper mt="2" mb="4">
            {textAudioUrl ? (
              <>
                <PlayButton
                  audioUrl={textAudioUrl}
                  width="56px"
                  height="56px"
                  iconProps={{ width: '32px', height: '32px' }}
                  fallbackText={textAudio}
                />
                <div>
                  <Markdown
                    text={textAudio}
                    customStyles={{
                      p: { size: 'regular', ml: '3' },
                    }}
                  />
                </div>
              </>
            ) : (
              <div>
                <Markdown
                  text={textAudio}
                  customStyles={{
                    p: { size: 'regular' },
                  }}
                />
              </div>
            )}
          </S.TextAudioWrapper>
        )}

        <InputsWrapper
          variation={variation}
          correctOptions={correctOptions}
          setEnableNext={setEnableNext}
          rowSize={rowSize}
          columnSize={columnSize}
          gridStart={gridStart}
          gridEnd={gridEnd}
          gridReplacedWithText={gridReplacedWithText}
          rows={rows}
        />
      </S.ContentWrapper>

      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleNext}
        preview={preview}
        disabled={disabled}
        enableNext={enableNext}
        isLoading={isLoading}
      />
    </S.Wrapper>
  );
};

export default FillInTheGrid;
