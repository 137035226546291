import React from 'react';
import Layout, { layoutTypes } from './../../components/Layout';
import { authorization } from '../../helpers';
import { useAuth } from '../../context/auth';
import PropTypes from 'prop-types';

import { navRoutes, userRoles } from '../../constants';
import Redirect from './Redirect';

const Route = (props) => {
  const {
    isPrivate,
    layout,
    Component,
    allowedRoles,
    publicOnly,
    loggedWithoutOrganisation,
  } = props;

  const { user } = useAuth();

  // This is temporary to redirect users to TurningPages
  const currentRoute = window.location.pathname;
  if (
    process.env.REACT_APP_REDIRECT_TO_TURNINGPAGES &&
    currentRoute !== '/redirection'
  ) {
    return <Redirect to="/redirection" />;
  }

  if (publicOnly && user.id && user.role !== '') {
    if (user.role === userRoles.COACH || user.role === userRoles.FACILITATOR) {
      return (
        <Redirect
          to={navRoutes.COMMON.SPLASH_SCREEN}
          state={{
            role: user.role,
          }}
        />
      );
    } else if (user.role === userRoles.CONTENT_EDITOR) {
      return <Redirect to={navRoutes.CMS.BASE} />;
    } else if (user.role === userRoles.ADMIN) {
      return <Redirect to={navRoutes.ADMIN.ORGANISATIONS} />;
    } else if (user.role === userRoles.ORGANISATION_ADMIN) {
      return <Redirect to={navRoutes.ORGANISATION_ADMIN.DASHBOARD} />;
    }
    return <Redirect to={navRoutes.LEARNER.DASHBOARD} />;
  }

  if (isPrivate) {
    const authorized = authorization(user.role, allowedRoles);

    if (user.id) {
      if (layout) {
        return (
          <Layout layout={layout} {...props}>
            {authorized ? (
              <Component {...props} />
            ) : (
              <Redirect to={navRoutes.GENERAL.UNAUTHORIZED} {...props} />
            )}
          </Layout>
        );
      } else {
        return authorized ? (
          <Component {...props} />
        ) : (
          <Redirect to={navRoutes.GENERAL.UNAUTHORIZED} {...props} />
        );
      }
      // check if user is logging out and redirect to correct login page
    } else if (user.logout) {
      return user.role === userRoles.LEARNER ? (
        <Redirect to={navRoutes.LEARNER.LOGIN} />
      ) : (
        <Redirect to={navRoutes.GENERAL.LOGIN} />
      );
    }
    // return general login as default fallback
    return <Redirect to={navRoutes.GENERAL.LOGIN} />;
  }

  if (loggedWithoutOrganisation) {
    if (user.id) {
      if (layout) {
        return (
          <Layout layout={layout} {...props}>
            <Component {...props} />
          </Layout>
        );
      } else {
        return <Component {...props} />;
      }
      // check if user is logging out and redirect to correct login page
    } else if (user.logout) {
      return user.role === userRoles.LEARNER ? (
        <Redirect to={navRoutes.LEARNER.LOGIN} />
      ) : (
        <Redirect to={navRoutes.GENERAL.LOGIN} />
      );
    }
    // return general login as default fallback
    return <Redirect to={navRoutes.GENERAL.LOGIN} />;
  }

  if (layout) {
    return (
      <Layout layout={layout} overlayColor={user.overlayColor} {...props}>
        <Component layout={layout} {...props} />
      </Layout>
    );
  }

  return <Component layout={layout} {...props} />;
};

Route.propTypes = {
  layout: PropTypes.oneOf(...layoutTypes),
};

Route.Redirect = Redirect;
export default Route;
