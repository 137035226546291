import {
  useGetAllCustomTrainingSteps,
  useGetAllCustomTrainingResources,
  useGetDefaultCompletedStep,
  useUpdateTrainingStepUsesMutation,
} from 'api-calls/custom-training.queries';
import { useAuth } from '../../../../context/auth';
import { useMemo } from 'react';
import useCoachTrainingData from './useCoachTraining';

const useCustomTrainingStepHook = () => {
  const {
    user: { id: userId, organisationId },
  } = useAuth();
  const { trainingData } = useCoachTrainingData();

  // apply the delete staff to resources
  // move all of the training endpoint to custom training endpoint
  // test the training page

  const {
    mutateAsync: updateTrainingStep,
    isLoading: updateTrainingStepLoading,
  } = useUpdateTrainingStepUsesMutation();

  const { customTrainingSteps, isLoading: customTrainingStepsLoading } =
    useGetAllCustomTrainingSteps({
      organisationId,
    });

  const {
    defaultCompletedStep,
    isLoading: defaultCompletedStepLoading,
    refetch: refetchDefaultCompletedStep,
    isRefetching: isRefetchingDefaultCompletedStep,
  } = useGetDefaultCompletedStep({
    userId,
  });

  const defaultCompletedStepId =
    defaultCompletedStep?.tpdCompletedTrainingStep ??
    defaultCompletedStep?.cmidCompletedTrainingStep ??
    0;

  const { customTrainingResources, isLoading: customTrainingResourcesLoading } =
    useGetAllCustomTrainingResources({
      organisationId,
    });

  const isAPILoading =
    customTrainingStepsLoading ||
    defaultCompletedStepLoading ||
    customTrainingResourcesLoading ||
    updateTrainingStepLoading ||
    isRefetchingDefaultCompletedStep;

  const trainingContent = useMemo(
    () =>
      trainingData
        .map((s, index) => ({
          ...s,
          isCompleted: defaultCompletedStepId > index ? true : false,
        }))
        .concat(
          customTrainingSteps
            ?.map((step) => ({
              ...step,
              clickable: true,
              routeKey: 'TRAINING_STEP',
              param: ':stepId',
              user: 'COACH',
              desc: step.introduction,
              videoURLs: step.videoLinks,
              descriptionAsHtml: step.description,
              customStepId: step.id,
              isCompleted: step.isCompleted,
            }))
            ?.sort((a, b) => a.order - b.order) || []
        ),
    [customTrainingSteps, defaultCompletedStepId, trainingData]
  );

  const isAllStepCompleted = trainingContent.every((s) => !!s?.isCompleted);

  const activeStep = isAllStepCompleted
    ? trainingContent.length
    : trainingContent.findIndex((s) => s?.isCompleted === false) || 0;

  return {
    trainingContent,
    isAPILoading,
    isAllStepCompleted,
    activeStep,
    customTrainingResources,
    customTrainingSteps,
    userId,
    updateTrainingStep,
    refetchDefaultCompletedStep,
  };
};

export default useCustomTrainingStepHook;
