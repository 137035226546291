import { useState, useEffect, useCallback } from 'react';
import { Button, Loading, OnboardingModal } from '../../../components';
import { Typography as T } from 'components';
import {
  navRoutes as NR,
  coachStatusesQuery,
  navRoutes,
} from '../../../constants';
import StatsChart from 'components/StatsChart';
import { useGetStats } from 'api-calls/stats.queries';

import { useAuth } from '../../../context/auth';
import * as S from './style';
import Stack from '@mui/material/Stack';
import QuickAccessCard from 'components/QuickAccessCard';
import TrainingBanner from 'components/TrainingBanner';
import theme from 'theme';
import { getGreetings } from 'helpers';
import FeedbackModal from '../../../components/FeedbackModal';
import { useLocation } from 'react-router-dom';
import { getOnboardingFacilitatorStaticData } from 'components/OnboardingModal/onboardingModalStaticData';
import { useSiteSettings } from 'context/site-settings';
import useFacilitatorCompletedTrainingStep from 'pages/Facilitator/Training/Hooks/useFacilitatorCompletedTrainingStep';
import useFacilitatorTrainingData from '../Training/Hooks/useFacilitatorTrainingData';

const Dashboard = () => {
  const { user } = useAuth();
  const { state } = useLocation();
  const [showOnboarding, setShowOnboarding] = useState(false);

  const {
    stats,
    isLoading: getStatsLoading,
    isError: getStatsError,
  } = useGetStats();
  const { courseName, courseCode } = useSiteSettings();

  const { completedTrainingStep, getCurrentStepLoading, currentStepError } =
    useFacilitatorCompletedTrainingStep();
  const { trainingData } = useFacilitatorTrainingData();

  const {
    totalCoachesCount: { total: totalCoachesCount },
    totalLearnersCount: { total: totalLearnersCount, activeCount },
    learningTime: { totalSessionTimeHours },
    totalCompletedMilestones,
  } = stats || {
    totalCoachesCount: { total: 0 },
    totalLearnersCount: { total: 0 },
    learningTime: { totalSessionTimeHours: 0 },
    totalCompletedMilestones: 0,
  };
  const loading = getStatsLoading || getCurrentStepLoading;

  useEffect(() => {
    if (!!state?.showOnboarding) {
      setShowOnboarding(true);
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  useEffect(() => {
    if (loading) return;
    const overlayDiv = document.querySelector('#overlay');
    const focusableElements = overlayDiv?.querySelectorAll('*');
    focusableElements?.forEach((element) => {
      element.setAttribute('tabIndex', '-1');
    });

    // Cleanup function to restore the tab indexes when the component is unmounted
    return () => {
      focusableElements?.forEach((element) => {
        element.removeAttribute('tabIndex');
      });
    };
  }, [loading]);

  const closeOnboarding = useCallback(() => {
    setShowOnboarding(false);
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (getStatsError || currentStepError) {
    return (
      <T.P mb={2} mt={2} color="error">
        {getStatsError?.error ||
          currentStepError?.error ||
          'There was an error with your request.'}
      </T.P>
    );
  }

  return (
    <S.Wrapper>
      <S.HeaderWrapper mb="6">
        <div>
          <T.H1 id="welcome-message" color="neutralMain" mb="2">
            {`Welcome back to ${courseName}`}
          </T.H1>
          <T.P color="neutral70" size="med">
            {getGreetings()}, {user.firstName}!
          </T.P>
        </div>
        <Button.BasicButton
          icon={'userWithPlusFill'}
          iconProps={{
            width: '32px',
            height: '32px',
          }}
          to={navRoutes.COMMON.ADD_LEARNER}
          width="auto"
          size="medium"
        >
          New learner
        </Button.BasicButton>
      </S.HeaderWrapper>
      <TrainingBanner
        stepIndex={completedTrainingStep}
        stepData={trainingData[completedTrainingStep]}
        isAllStepCompleted={completedTrainingStep >= trainingData.length}
      />
      <S.QuickAccessWrapper>
        <T.H2 weight="bold" size="med" mb="4">
          Quick Access
        </T.H2>

        <Stack
          spacing="16px"
          direction={{
            xs: 'column',
            md: 'row',
          }}
          sx={{
            flexWrap: {
              xs: 'wrap',
              md: 'nowrap',
            },
          }}
        >
          <QuickAccessCard
            text="My learners"
            to={NR.COMMON.LEARNERS}
            bgColor={theme.colors.neutralLight}
          />
          <QuickAccessCard
            icon="withCoach"
            text="My Coaches"
            to={NR.COMMON.COACHES.replace(
              ':statusType',
              coachStatusesQuery.ALL
            )}
            bgColor={theme.colors.neutralLight}
          />
          <QuickAccessCard
            icon="threePersons"
            text="Training / Resources"
            to={NR.FACILITATOR.TRAINING}
            bgColor={theme.colors.neutralLight}
          />
        </Stack>
      </S.QuickAccessWrapper>

      <Stack spacing="16px" width="100%">
        <T.H2 weight="bold" size="med">
          Statistics
        </T.H2>

        <Stack
          gap="16px"
          display={'grid'}
          gridTemplateColumns={{
            xs: '1fr',
            md: '1fr 1fr 1fr',
          }}
        >
          <StatsChart
            statChartType="hours"
            backgroundMode="dark"
            value={totalSessionTimeHours}
          />
          <Stack spacing="16px" direction="column">
            <StatsChart
              label="Learners"
              statChartType="totalCount"
              backgroundMode="light"
              value={totalLearnersCount}
            />
            <StatsChart
              label="Coaches"
              statChartType="coach"
              backgroundMode="light"
              value={totalCoachesCount}
            />
          </Stack>

          <Stack spacing={'16px'} direction="column">
            <StatsChart
              label="Active Learners"
              statChartType="totalCount"
              backgroundMode="light"
              value={activeCount}
              customIcon="activeUser"
            />
            <StatsChart
              statChartType="milestone"
              backgroundMode="light"
              value={Number(totalCompletedMilestones)}
            />
          </Stack>
        </Stack>
      </Stack>

      <FeedbackModal />

      <OnboardingModal
        isModalOpen={showOnboarding}
        onClose={closeOnboarding}
        handleClick={closeOnboarding}
        data={getOnboardingFacilitatorStaticData({ courseCode, courseName })}
      />
    </S.Wrapper>
  );
};

export default Dashboard;
