import styled from '@emotion/styled';
import Image from '../Image';
import { Link } from 'react-router-dom';

export const StyledImage = styled(Image)`
  position: absolute;
  pointer-events: none;
  width: 100%;
  top: 0;
  left: 0;
`;
export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  /* padding: ${({ theme }) => `20px 24px 0`}; */
  width: 100%;
  white-space: normal;
  z-index: 1;
  gap: 16px;
  position: relative;
`;

export const SavedIconWrapper = styled.div`
  padding: 8px 12px;
  background-color: ${({ theme }) => theme.colors.warningMain};
  border-radius: 8px;
  position: relative;
  width: fit-content;
  margin-top: 8px;
  margin-inline: auto;
`;

const getStyledLinkBg = (exerciseComplete, disabled, theme) => {
  if (exerciseComplete) return theme.colors.neutralLight;
  if (disabled) return theme.colors.neutralLight90;
  return 'white';
};

export const StyledLink = styled(Link)`
  background-color: ${({ theme, exerciseComplete, disabled }) =>
    getStyledLinkBg(exerciseComplete, disabled, theme)};
  border-radius: ${({ theme }) => theme.borders.radiusLarge};
  /* for disabled style */
  opacity: ${({ disabled }) => (disabled ? 0.65 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  white-space: nowrap;
  width: 300px;
  right: ${({ right }) => right || 'auto'};
  left: ${({ left }) => left || 'auto'};
  padding: 20px 24px;
  ${({ theme }) => theme.media.mobile} {
    left: 0;
    right: 0;
  }
`;

export const MilestoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.colors.primaryMain};
  border-radius: ${({ theme }) => theme.borders.radiusLarge};
  white-space: nowrap;
  height: 150px;
`;

export const CompletedIconWrapper = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
`;
