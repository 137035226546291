import { useState } from 'react';
import * as S from './style';
import { getMediaUrl } from '../../../helpers';
import { useGeneralState } from '../../../context/general-state';

import { Typography as T, Button, Grid, Icon } from '../../../components';

import GoalProgress from './GoalProgress';

import { defaultGoals } from './helpers';

import Task from './Task';
import theme from 'theme';
const { Row } = Grid;

const CompletedCard = ({ goalId, title, tasks }) => {
  const { state: generalState } = useGeneralState();

  const [expanded, setExpanded] = useState(false);

  const goalHasSound = defaultGoals[title];

  return (
    <S.CardWrapper completed={true}>
      {/* <S.DeleteButton
        type="button"
        onClick={() =>
          handleDeleteGoal({
            goalId,
            completed,
            cb: () => deleteGoal({ personalGoalId: goalId }),
          })
        }
      >
        <T.P color="neutral70" weight="bold">
          Delete
        </T.P>
      </S.DeleteButton> */}
      <Button.PlayButton
        backgroundColor={theme.colors.white}
        audioUrl={getMediaUrl(
          goalHasSound?.audio || null, // TODO: update the sound when it's ready
          true,
          generalState?.preferredVoice
        )}
        fallbackText={title}
      />

      <T.H2 mt="4" mb="2">
        {title}
      </T.H2>
      <GoalProgress progress={100} completed={true} />
      {tasks?.length > 0 && (
        <S.AccordionWrapper>
          {!expanded && (
            <S.AccordionTitle>
              <S.AccordionBtn onClick={() => setExpanded(!expanded)}>
                <T.P color="primaryMain" weight="bold">
                  Expand
                </T.P>
                <Icon
                  icon="chevronDown"
                  color="primaryMain"
                  width="18px"
                  height="18px"
                />
              </S.AccordionBtn>
            </S.AccordionTitle>
          )}
          <S.AccordionItem collapsed={!expanded}>
            <S.AccordionContent>
              <S.Divider />

              <Row>
                {tasks.map((item) => {
                  return (
                    <Task
                      key={item.id}
                      item={item}
                      goalId={goalId}
                      refetch={() => {}}
                      completedGoal={true}
                      handleTaskCheck={() => {}}
                    />
                  );
                })}
              </Row>
            </S.AccordionContent>
            <S.AccordionTitle>
              <S.AccordionBtn onClick={() => setExpanded(!expanded)}>
                <T.P color="primaryMain" weight="bold">
                  Collapse
                </T.P>
                <Icon
                  icon="chevronDown"
                  color="primaryMain"
                  width="18px"
                  height="18px"
                  style={{ transform: 'rotate(90deg)' }}
                />
              </S.AccordionBtn>
            </S.AccordionTitle>
          </S.AccordionItem>
        </S.AccordionWrapper>
      )}
    </S.CardWrapper>
  );
};

export default CompletedCard;
