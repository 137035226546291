import { Spin } from 'antd';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import * as T from '../../Typography';
import setMargin from './../../../helpers/set-margin';

export const Container = styled.div`
  ${setMargin};
  background-color: ${({ bubble, theme }) =>
    bubble ? theme.colors.neutralLight : 'transparent'};
  padding: ${({ bubble }) => (bubble ? '18px' : '0px')};
  border-radius: ${({ bubble }) => (bubble ? '16px' : '0px')};
  position: relative;
  border-right: ${({ bubble, theme }) =>
    bubble ? `16px solid ${theme.colors.primaryMain}` : 'none'};
  box-sizing: border-box;
  display: ${({ text }) => {
    if (text) return 'flex';
    else return 'inline-block';
  }};
  align-items: center;

  ${({ bubble, theme }) =>
    bubble &&
    `:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 25px;
      border-top: 20px solid black;
      border-top-color: ${theme.colors.neutralLight};  
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;`}
`;

export const PlayText = styled(T.H2)`
  ${setMargin}
  font-weight: ${({ weight }) => (weight ? weight : 'regular')} !important;
  color: ${({ theme, loading, color }) =>
    loading ? theme.colors.neutral70 : theme.colors[color] || color};
`;

export const PlayTextLink = styled(T.Link)`
  ${setMargin}
  font-weight: ${({ weight }) => (weight ? weight : 'regular')} !important;
  color: ${({ theme, loading, color }) =>
    loading ? theme.colors.neutral70 : theme.colors[color] || color};
`;

const playButtonCommonStyle = (props) =>
  css`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    min-width: ${props?.innerText ? '' : props?.width ? props?.width : '56px'};
    min-height: ${props?.height ? props?.height + 'px' : '56px'};
    position: relative;
    border: none;
    border-radius: ${props?.radius ? props?.radius + 'px' : '16px'};
    font-size: 1rem;
    font-weight: bold;
    padding: ${props?.withoutButtonStyle
      ? 0
      : props?.padding ||
        `${props?.theme.spacings[3]} ${props?.theme.spacings[2]}`};

    box-shadow: ${props?.withoutButtonStyle
      ? 'none'
      : props?.theme.shadows.elevation1};

    transition: background-color 300ms linear, color 300ms linear;
    cursor: ${props?.disabled || props?.loading ? 'not-allowed' : 'pointer'};

    :active {
      color: ${props?.theme.colors.white};
      opacity: 0.9;
      background-color: ${props?.theme.colors.neutralMain};
    }

    :focus {
      box-shadow: inset 0px 0px 0px 2px rgba(5, 23, 48, 1);
      outline-style: solid;
      outline-width: 2px;
      outline-color: ${props?.theme.colors.primaryMain};
      outline-offset: 2px;
    }
  `;

export const PlayButton = styled.button`
  ${playButtonCommonStyle};
  color: ${({ theme, btnColor }) => btnColor || theme.colors.neutralMain};

  background: ${({ theme, backgroundColor }) =>
    theme.colors[backgroundColor] ||
    backgroundColor ||
    theme.colors.neutralLight};
  :hover {
    color: ${({ theme }) => theme.colors.primaryMain};
  }

  :disabled {
    color: ${({ theme }) => theme.colors.neutralMain};
    background: ${({ theme, backgroundColor }) =>
      backgroundColor || theme.colors.neutralLight};
  }
`;

export const PlayIconButton = styled.button`
  ${setMargin}
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
`;

export const decideBackgroundHoverBasic = (variant, theme) => {
  switch (variant) {
    case 'primary':
      return `${theme.colors.neutralMain50}`;
    case 'secondary':
      return `${theme.colors.neutralMain80}`;
    case 'tertiary':
      return `${theme.colors.neutralMain50}`;
    default:
      return 'transparent';
  }
};

export const PlayButtonSoundCombinedButton = styled.button`
  ${playButtonCommonStyle};
  color: ${({ theme, btnColor }) => btnColor || theme.colors.white};

  background: ${({ variant, theme, backgroundColor }) =>
    backgroundColor || decideBackgroundHoverBasic(variant, theme)};

  height: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;

  ${({ theme }) => theme.media.mobile} {
    min-width: 48px;
    min-height: 56px;
  }
`;

export const CenterLoading = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  span {
    line-height: 24px;
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.quaternaryMain};
  }
`;

export const InnerText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  margin-left: 12px;
`;
