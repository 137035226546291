import styled from '@emotion/styled';

export const Wrapper = styled.div`
  border-radius: 0.25rem;
  padding: 24px;
  background: ${({ theme }) => theme.colors.primaryLight};
  border: ${({ theme }) => `1px solid ${theme.colors.neutral40}`};
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: space-between;
`;

export const ImgWrapper = styled.div`
  @media (max-width: 580px) {
    display: none;
  }
`;
