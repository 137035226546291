import * as T from 'components/Typography';
import { useAuth } from 'context/auth';
import { authorization } from 'helpers';
import { userRoles } from 'constants';
import ModifyLearnerStatus from './ModifyLearnerStatus';

const LearnerStatus = ({ learner }) => {
  const { user } = useAuth();
  const authorized = authorization(user.role, [
    userRoles.ORGANISATION_ADMIN,
    userRoles.FACILITATOR,
    userRoles.ADMIN,
  ]);

  return (
    <>
      <T.H2 size="med" mb="3">
        {authorized ? 'Manage active status' : 'Status'}
      </T.H2>
      <T.P mb="1">Current status: {learner.active ? 'active' : 'inactive'}</T.P>
      <T.P mb="3">Latest update: 2023-06-2</T.P>
      {authorized && <ModifyLearnerStatus learner={learner} />}
    </>
  );
};

export default LearnerStatus;
