import {
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from 'react';

import {
  storeUserInfoIntoStorage,
  clearDataFromStorage,
} from '../utils/localStorage';
import {
  useGetCSRFToken,
  useGetLoggedInUserInfoMutation,
} from './../api-calls/users.queries';
import { useLogout } from './../api-calls/users.queries';
import { queryClient } from './../api-calls/queryClient';
import ReactGA from 'react-ga4';
import { userRoles } from '../constants';

const initialUserState = {
  id: null,
  email: '',
  firstName: '',
  lastName: '',
  role: '',
  selectedLearnerId: null, // for coach when selected learner
  facilitatorId: null, // for coaches
  isFacilitator: false, // for organisation admins
  organisationId: null,
};

const AuthContext = createContext({
  user: initialUserState,
  setUser: () => {},
  logout: async () => {},
  setOverlayColor: () => {},
  resetStateAfterSwitchAccount: async () => {},
  refetchLoggedInUserInfo: () => {},
});

const getUserInfoFromStorage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.id) {
    if (
      (user.role === userRoles.LEARNER || user.role === userRoles.COACH) &&
      ReactGA.isInitialized
    ) {
      ReactGA.set({
        ...user,
      });
    }
    return user;
  } else {
    return initialUserState;
  }
};

const storeInfoIntoStorage = (data) => {
  if (
    data?.activeOrganisationsWithActivatedStatus?.find(
      (u) =>
        u.role === userRoles.FACILITATOR &&
        u.organisationId === data?.organisationId
    )
  ) {
    data.isFacilitator = true;
  }

  storeUserInfoIntoStorage(data);
};

const AuthProvider = (props) => {
  const [user, setUser] = useState(getUserInfoFromStorage);
  const { mutateAsync: logoutApi } = useLogout();

  const { mutateAsync: getLoggedInUserInfo } = useGetLoggedInUserInfoMutation();

  useGetCSRFToken(null, { refetchInterval: 60000 });

  useEffect(() => {
    getLoggedInUserInfo(
      {
        organisationId: user.organisationId,
        role: user.role,
        userId: user.id,
      },
      {
        onSuccess: (data) => {
          storeInfoIntoStorage(data);
          setUser(data);
        },
        onError: () => {
          clearDataFromStorage();
          setUser(initialUserState);
        },
      }
    );
  }, [getLoggedInUserInfo, user.id, user.organisationId, user.role]);

  const resetStateAfterSwitchAccount = useCallback(
    async ({ organisationId, role, id }) => {
      await queryClient.invalidateQueries();
      return getLoggedInUserInfo(
        {
          organisationId,
          role,
          userId: id,
        },
        {
          onSuccess: (data) => {
            storeInfoIntoStorage(data);
            setUser(data);
          },
        }
      );
    },
    [getLoggedInUserInfo]
  );

  const logout = useCallback(
    async ({ onLogoutSuccess } = {}) => {
      await logoutApi(
        {},
        {
          onSuccess: () => {
            if (typeof onLogoutSuccess === 'function') {
              onLogoutSuccess();
            }
            clearDataFromStorage();
            setUser(initialUserState);
          },
        }
      );
      setUser({ logout: true });
    },
    [logoutApi]
  );

  const setOverlayColor = useCallback(
    (color) => {
      setUser({ ...user, overlayColor: color });
    },
    [user, setUser]
  );

  const value = useMemo(
    () => ({
      user,
      setUser: setUser,
      logout,
      setOverlayColor,
      selectedLearnerId:
        user?.role === userRoles.LEARNER ? user.id : user?.selectedLearnerId,
      resetStateAfterSwitchAccount,
    }),
    [user, logout, setOverlayColor, resetStateAfterSwitchAccount]
  );

  return <AuthContext.Provider value={value} {...props} />;
};

const useAuth = () => {
  const value = useContext(AuthContext);
  return value;
};

export { AuthProvider, useAuth };
export default AuthContext;
