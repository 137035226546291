import { EyeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import * as T from 'components/Typography';
import * as S from './style';
import {
  navRoutes,
  coachApprovalStatuses,
  coachStatusesQuery,
} from '../../../constants';
import { Link as RouterLink } from 'react-router-dom';

const NameColumn = ({ name, id, approvalStatus }) => (
  <S.NameColumnWrapper>
    <T.P ml={3}>{name}</T.P>
    {(approvalStatus === coachApprovalStatuses.INVITED_TO_INTERVIEW ||
      approvalStatus === coachApprovalStatuses.REQUESTED) && (
      <RouterLink
        to={navRoutes.COMMON.COACH_DETAILS.replace(
          ':statusType',
          coachStatusesQuery.ALL
        ).replace(':coachId', id)}
        className="view-profile"
      >
        <Button type="text" shape="round" icon={<EyeOutlined />} tabIndex={-1}>
          View Application
        </Button>
      </RouterLink>
    )}
    <RouterLink
      to={navRoutes.COMMON.COACH_PROFILE.replace(
        ':statusType',
        coachStatusesQuery.ALL
      ).replace(':coachId', id)}
      className="view-profile"
    >
      <Button type="text" shape="round" icon={<EyeOutlined />} tabIndex={-1}>
        View Profile
      </Button>
    </RouterLink>
  </S.NameColumnWrapper>
);

export default NameColumn;
