import styled from '@emotion/styled';

import { Inputs } from '../../components';
import { Checkbox } from 'antd';

const { BasicInput } = Inputs;
const Wrapper = styled.form`
  display: flex;
  align-items: center;
  button {
    cursor: pointer;
    width: 50px;
    background: none;
    border: none;
  }
`;

const Search = ({ query, setQuery, exactMatch, setExactMatch }) => {
  const handleChange = (v) => {
    setQuery(v);
  };

  return (
    <Wrapper onSubmit={(e) => e.preventDefault()}>
      <Checkbox
        checked={exactMatch}
        onChange={(value) => setExactMatch(value.target.checked)}
      >
        Exact match
      </Checkbox>
      <BasicInput
        handleChange={handleChange}
        placeholder="Search..."
        w="175px"
      />
    </Wrapper>
  );
};

export default Search;
