import React from 'react';
import Icon from '../Icon';
import * as S from './style';
import * as T from '../Typography';
import { useCopyToClipboard } from 'Hooks';

const InlineCopy = ({ text, textToCopy, color, copyIcon }) => {
  const { copied, copyToClipboard } = useCopyToClipboard();

  return (
    <>
      <S.InlineWrapper>
        <S.InlineCopyBtn onClick={() => copyToClipboard(textToCopy)}>
          <Icon
            icon={copied ? 'tick' : copyIcon || 'copy'}
            color={copied ? 'green' : color || 'neutral90'}
            width="16"
            height="16"
            mr="1"
          />
          <T.P color={color || 'gray8'} weight="bold" ellipsis={true}>
            {text}
          </T.P>
        </S.InlineCopyBtn>
      </S.InlineWrapper>
    </>
  );
};

export default InlineCopy;
