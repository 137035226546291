import styled from '@emotion/styled';

export const Wrapper = styled.article`
  display: flex;
  background: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] || bgColor : 'transparent'};
`;

export const Content = styled.main`
  width: 100%;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  display: flex;
`;

export const BackWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacings[4]};
  padding: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[5]}`};
`;

export const BackButton = styled.button`
  display: flex;
  outline: none;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  background-color: transparent;
  align-items: center;
`;

export const CurrentPage = styled.div`
  margin-top: ${({ theme }) => theme.spacings[7]};
  margin-bottom: ${({ theme }) => theme.spacings[7]};
  padding-left: ${({ theme }) => theme.spacings[11]};
  padding-right: ${({ theme }) => theme.spacings[11]};
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${({ theme }) => theme.media.tablet} {
    padding-left: ${({ theme }) => theme.spacings[7]};
    padding-right: ${({ theme }) => theme.spacings[7]};
  }

  ${({ theme }) => theme.media.mobile} {
    padding-left: ${({ theme }) => theme.spacings[5]};
    padding-right: ${({ theme }) => theme.spacings[5]};
  }
`;

export const ContentBody = styled.div`
  ${({ theme }) => theme.media.tablet} {
    min-height: ${({ theme, headerType }) =>
      `calc(100vh - ${
        headerType === 'progress'
          ? theme.constants.layout.calculateHeight('tabletWithProgressBar')
          : theme.constants.layout.calculateHeight('tabletWithoutProgressBar')
      } )`};
  }
`;
