import { fillInTheGridVariations } from '../../../../../constants';
import { string, array, object, number, boolean } from 'yup';

export const validationSchema = {
  variation: string()
    .oneOf(Object.values(fillInTheGridVariations.variations), 'required field')
    .required('required field'),
  textAudio: string().when('variation', {
    is: (variation) =>
      variation !== fillInTheGridVariations.variations.IMAGE_ONLY,
    then: string().required('required field'),
    otherwise: string().notRequired(),
  }),
  textAudioKey: string().notRequired(),
  columnSize: number().when('variation', {
    is: (variation) =>
      fillInTheGridVariations.variations.GRID_NO_VALUES === variation,
    then: number().min(1).max(8).required('required field'),
    otherwise: number().notRequired(),
  }),
  rowSize: number().when('variation', {
    is: (variation) =>
      fillInTheGridVariations.variations.GRID_NO_VALUES === variation,
    then: number().min(1).max(8).required('required field'),
    otherwise: number().notRequired(),
  }),
  gridStart: number().when('variation', {
    is: (variation) =>
      fillInTheGridVariations.variations.GRID_WITH_VALUES === variation,
    then: number().required('required field'),
    otherwise: number().notRequired(),
  }),
  gridEnd: number().when('variation', {
    is: (variation) =>
      fillInTheGridVariations.variations.GRID_WITH_VALUES === variation,
    then: number().when('gridStart', (gridStart, schema) => {
      const gridEndSchema = schema;
      return gridEndSchema.min(gridStart + 1).required('required field');
    }),
    otherwise: number().notRequired(),
  }),
  correctOptions: array()
    .of(
      object()
        .shape({
          id: number().notRequired(),
          option: string().required('required field'),
        })
        .required()
    )
    .required('you must provide answers')
    .when(
      ['variation', 'gridStart', 'gridEnd'],
      (variation, gridStart, gridEnd, schema) => {
        // Handle array length validation
        let validatedSchema = schema;
        if (fillInTheGridVariations.variations.GRID_NO_VALUES === variation) {
          validatedSchema = schema.length(1, 'only one answer allowed');
        } else if (
          fillInTheGridVariations.variations.GRID_WITH_VALUES === variation
        ) {
          const maximumLength = gridEnd - gridStart;
          validatedSchema = schema
            .min(1, `minimum of one answer required`)
            .max(maximumLength, `maximum of ${maximumLength} answers allowed`);
        } else {
          validatedSchema = schema.notRequired();
        }

        return validatedSchema;
      }
    ),
  gridReplacedWithText: array()
    .of(
      object().shape({
        id: number().required(),
        number: number().required('required field'),
        replacedWith: string().required('required field'),
      })
    )
    .when(
      ['variation', 'gridStart', 'gridEnd'],
      (variation, gridStart, gridEnd, schema) => {
        let validatedSchema = schema;
        const maximumLength = gridEnd - gridStart;
        if (fillInTheGridVariations.variations.GRID_WITH_VALUES === variation) {
          validatedSchema = schema.max(
            maximumLength,
            `maximum of ${maximumLength} answers allowed`
          );
        }
        return validatedSchema;
      }
    ),
  rows: array()
    .of(
      object().shape({
        id: number().required(),
        text: string().required('required field'),
        isCorrect: boolean().required('required field'),
      })
    )
    .when('variation', (variation, schema) => {
      let validatedSchema = schema;
      if (fillInTheGridVariations.variations.ROWS === variation) {
        validatedSchema = schema
          .min(1, 'at add least 1 row')
          .max(10, `maximum of 10 rows allowed`);
      } else if (fillInTheGridVariations.variations.THERMOMETER === variation) {
        validatedSchema = schema
          .min(1, 'at add least 1 value')
          .max(10, `maximum of 10 values allowed`);
      } else {
        validatedSchema = schema.notRequired();
      }
      return validatedSchema;
    }),
};
