import { useEffect, useState } from 'react';

import * as T from '../../../../../components/Typography';
import EmptySpace from '../../../../../components/EmptySpace';
import Option from '../../../../../components/Option';
import * as S from './style';
import * as SharedStyles from '../../shared/style';
import { showMovingBlock } from '../../../../../helpers';
import TryAgainModal from '../../../../../components/Modal/TryAgainModal';
import StepFooterButtons from '../../StepFooterButtons';
import { PlayButton } from 'components/Button';

const WordJoin2 = ({
  data: { textAudio, textAudioUrl, correctOptions = [] } = {},
  handleNext,
  stuckAtReview,
  preview,
  cmsPreview,
}) => {
  const [disableCheck, setDisableCheck] = useState(false);
  const [activePosition, setActivePosition] = useState(0);
  const [showTryAgain, setShowTryAgain] = useState(false);
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [answers, setAnswers] = useState(() => {
    return correctOptions.map((co) => (co?.showValue ? co : null));
  });

  useEffect(() => {
    if (answers?.length) {
      const currentEmptyCorrectOption =
        answers.findIndex((answer) => answer === null) ?? 0;
      setActivePosition(currentEmptyCorrectOption);
    }
  }, [answers]);

  useEffect(() => {
    // Shuffle the options once when the component mounts or when correctOptions changes
    setShuffledOptions(
      [...correctOptions]
        .filter((option) => !option.showValue)
        .sort(() => Math.random() - 0.5)
    );
  }, [correctOptions]);

  const onAnswerClick = (correctOptionIndex, answer) => {
    if ((answer?.id || answer?.id === 0) && !answer?.showValue) {
      setActivePosition(correctOptionIndex);
      showMovingBlock({
        OptionElId: `option-${answer.id}`,
        answerElId: `value-${correctOptionIndex}`,
        movingElId: 'moving-div',
        isBeingSelected: true,
      });
      setAnswers(
        answers.map((answer, currentIndex) => {
          if (currentIndex === correctOptionIndex) {
            return null;
          }
          return answer;
        })
      );
    } else if (!answer?.showValue) {
      setActivePosition(correctOptionIndex);
    }
  };

  const validateAnswer = (index) => {
    const currentAnswer = answers[index]?.value;
    return correctOptions[index]?.value === currentAnswer;
  };

  const onOptionClick = (option) => {
    setDisableCheck(true);
    showMovingBlock({
      OptionElId: `option-${option.id}`,
      answerElId: `value-${activePosition}`,
      movingElId: 'moving-div',
      isBeingSelected: false,
      done: () => {
        setAnswers(() => {
          return answers.map((answer, currentIndex) => {
            if (currentIndex === activePosition) {
              return option;
            }
            return answer;
          });
        });
        setDisableCheck(false);
      },
    });
  };

  const onNext = () => {
    const allCorrect = correctOptions.every((e, i) => validateAnswer(i));

    if (allCorrect || stuckAtReview) {
      handleNext();
    } else {
      setShowTryAgain(true);
    }
  };

  return (
    <S.Wrapper>
      {textAudio ? (
        <SharedStyles.TextAudioWrapper ml={3} mlM={1} mlT={2} mt={1} mb={7}>
          {textAudioUrl ? (
            <>
              <PlayButton
                audioUrl={textAudioUrl}
                width="56px"
                height="56px"
                iconProps={{ width: '32px', height: '32px' }}
                fallbackText={textAudio}
              />
              <T.P ml={3} weight="regular" size="med">
                {textAudio}
              </T.P>
            </>
          ) : (
            <T.P weight="regular" size="med">
              {textAudio}
            </T.P>
          )}
        </SharedStyles.TextAudioWrapper>
      ) : null}
      {/* keep this to animation */}
      <div style={{ display: 'none' }}>
        <Option id="moving-div"></Option>
      </div>
      <S.ItemsWrapper correctOptionsLength={correctOptions.length}>
        {answers.map((correctOption, currentIndex) => (
          <S.Item
            w={correctOptions.length > 7 ? '45%' : '100%'}
            showBorder={!correctOption?.showValue}
          >
            <EmptySpace
              isActive={currentIndex === activePosition && !cmsPreview}
              onClick={() => onAnswerClick(currentIndex, correctOption)}
              id={`value-${currentIndex}`}
              color="#051730"
              bgColor="#FDFCF9"
              key={`${currentIndex}`}
              disabled={currentIndex !== activePosition}
              w="100%"
              size="large"
              fontSize="20px"
              preFilled
              m={0}
            >
              {correctOption?.value}
            </EmptySpace>
          </S.Item>
        ))}
      </S.ItemsWrapper>

      <S.OptionsGridWrapper
        ai={correctOptions.length === 1 ? 'flex-start' : 'flex-end'}
      >
        <S.OptionsGrid jc="center">
          {shuffledOptions.map((correctOption) => (
            <Option
              m={1}
              onClick={() => onOptionClick(correctOption)}
              id={`option-${correctOption.id}`}
              key={correctOption.id}
              style={{ minWidth: '74px' }}
              fontSize="20px"
            >
              {correctOption.value}
            </Option>
          ))}
        </S.OptionsGrid>
      </S.OptionsGridWrapper>

      {!correctOptions && (
        <T.P color="error">Exercise missing required fields </T.P>
      )}
      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={onNext}
        preview={preview}
        disabled={disableCheck}
        enableNext={
          answers.filter((answer) => answer?.value).length >=
          correctOptions.length
        }
      />
      <TryAgainModal
        isModalVisible={showTryAgain}
        setIsModalVisible={setShowTryAgain}
      />
    </S.Wrapper>
  );
};

export default WordJoin2;
