import Icon from '../Icon';
import * as S from './style';
import * as T from '../Typography';
import { PlayButton } from '../Button';

const TextWithIcon = ({
  color = 'neutralMain',
  to,
  icon = 'tick',
  text = 'Success',
  external,
  underline,
  isButton,
  direction,
  handleClick,
  weight,
  disabled,
  m,
  mr,
  mt,
  isText,
  iconProps,
  size,
  jc,
  ai,
  audioUrl,
  id,
  ...props
}) => {
  if (isButton)
    return (
      <S.Button
        {...m}
        {...props}
        id={id}
        onClick={handleClick}
        disabled={disabled}
        type="button"
        aria-disabled={disabled ? 'true' : 'false'}
      >
        {icon && <Icon icon={icon} {...iconProps} />}
        <T.P
          size={size}
          weight={weight}
          color={color}
          td={underline && 'underline'}
        >
          {text}
        </T.P>
      </S.Button>
    );

  if (audioUrl)
    return (
      <S.Wrapper jc={jc} mt={mt} ai={ai} {...props}>
        <PlayButton
          handleClick={() => {}}
          audioUrl={audioUrl}
          iconProps={iconProps}
          mr="2"
          withoutButtonStyle
          backgroundColor="none"
          fallbackText={typeof text === 'string' && text}
        />
        <T.P
          to={to}
          color={color}
          weight={weight}
          size={size}
          td={underline && 'underline'}
        >
          {text}
        </T.P>
      </S.Wrapper>
    );

  if (isText)
    return (
      <S.Wrapper jc={jc} mt={mt} ai={ai} {...props}>
        {icon && <Icon icon={icon} {...iconProps} mr="2" />}
        <T.P
          to={to}
          color={color}
          weight={weight}
          size={size}
          td={underline && 'underline'}
        >
          {text}
        </T.P>
      </S.Wrapper>
    );

  return (
    <S.Wrapper {...props} {...m}>
      {icon && <Icon icon={icon} {...iconProps} />}
      <T.Link
        to={to}
        color={color}
        external={external}
        size={size}
        underline={underline}
      >
        <T.P weight={weight} color={color}>
          {text}
        </T.P>
      </T.Link>
    </S.Wrapper>
  );
};

export default TextWithIcon;
