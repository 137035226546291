import { useState } from 'react';
import * as S from './style';
import { useSendChatMsg } from 'api-calls/chat-messages.queries';
import ViewMsgs from './ViewMsgs';
import { types as Ty } from '../../constants';
import { queryClient } from 'api-calls/queryClient';
import ChatTextArea from './ChatTextArea';

export { PlayAudioMsg } from './AudioMsg';

const Chat = ({ receiverId, userType, PreMsg }) => {
  const [textMsg, setTextMsg] = useState('');
  const {
    mutateAsync: sendMsg,
    // error: httpError,
    isLoading,
  } = useSendChatMsg({ receiverId });
  const handleSendMsg = async (
    msgType = Ty.chatMessagesTypes.TEXT,
    audioMediaId
  ) => {
    sendMsg(
      {
        msgType,
        textMsg,
        audioMediaId,
      },
      {
        onSuccess: (data) => {
          queryClient.setQueryData([`CHAT_MSGS`, { receiverId }], (oldData) => {
            return {
              ...oldData,
              pages: [
                ...oldData.pages,
                {
                  meta: {},
                  myMsgs: [data],
                },
              ],
            };
          });
          setTextMsg('');
        },
      }
    );
  };

  return (
    <S.Wrapper>
      <ViewMsgs userType={userType} receiverId={receiverId} PreMsg={PreMsg} />
      <ChatTextArea
        handleSendMsg={handleSendMsg}
        textMsg={textMsg}
        setTextMsg={setTextMsg}
        isLoading={isLoading}
      />
    </S.Wrapper>
  );
};

export default Chat;
