import { Col, Row } from 'components/Grid';
import { BasicButton } from '../../../components/Button';
import { Typography as T } from 'components';

import Modal from '../../../components/Modal';

const ConfirmationModal = ({
  isUpdateModalOpen,
  setIsUpdateModalVisible,
  updateLoading,
  confirmationMessage,
  onClick,
}) => {
  return (
    <Modal
      visible={isUpdateModalOpen}
      setIsModalVisible={setIsUpdateModalVisible}
    >
      <Row>
        <Col w={[4, 12, 12]}>
          <T.P size="med" weight="bold" color="white">
            Are you sure?
          </T.P>
          <T.P size="regular" color="white" mt={4} mb={4}>
            {confirmationMessage}
          </T.P>

          <BasicButton
            variant="secondary"
            onClick={onClick}
            mt={4}
            loading={updateLoading}
            disabled={updateLoading}
          >
            Confirm
          </BasicButton>
          <BasicButton
            variant="tertiary"
            onClick={() => {
              setIsUpdateModalVisible({
                modal: '',
                visible: false,
              });
            }}
            mt={2}
          >
            Cancel
          </BasicButton>
        </Col>
      </Row>
    </Modal>
  );
};

export default ConfirmationModal;
