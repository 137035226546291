const UsersGroup = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '61'}
    height={height || '61'}
    viewBox="0 0 61 61"
    fill="none"
    {...props}
  >
    <path
      d="M10.9096 49.6116C11.773 50.4752 12.7979 51.1602 13.926 51.6276C15.0542 52.0949 16.2633 52.3355 17.4844 52.3355M10.9096 49.6116C12.6531 51.3551 15.0187 52.3355 17.4844 52.3355M10.9096 49.6116C9.16612 47.8681 8.1875 45.5025 8.1875 43.0369V10.4978C8.1875 8.95824 9.437 7.70874 10.9766 7.70874H23.9922C25.5317 7.70874 26.7812 8.95824 26.7812 10.4978V20.593M17.4844 52.3355C18.7055 52.3355 19.9146 52.0949 21.0427 51.6276C22.1708 51.1602 23.1958 50.4752 24.0591 49.6116M17.4844 52.3355C19.9501 52.3355 22.3156 51.3551 24.0591 49.6116M17.4844 52.3355L50.0234 52.3337C51.563 52.3337 52.8125 51.0842 52.8125 49.5447V36.5291C52.8125 34.9895 51.563 33.74 50.0234 33.74H39.9283M24.0591 49.6116L39.9283 33.74M24.0591 49.6116C25.8026 47.8681 26.7812 45.5025 26.7812 43.0369V20.593M39.9283 33.74L47.0658 26.6C48.1566 25.5141 48.1566 23.7489 47.0658 22.6581L37.8631 13.453C36.7723 12.3646 35.0071 12.3646 33.9212 13.453L26.7812 20.593M17.4844 43.0369H17.5042V43.0567H17.4844V43.0369Z"
      stroke={color}
      strokeWidth="3.71875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UsersGroup;
