import styled from '@emotion/styled';

export const Wrapper = styled.div`
  box-shadow: 0px 2px 4px -2px #1018281a, 0px 4px 6px -1px #1018281a;
  width: 100%;
`;

export const PlayButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`;

export const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 48px;
  max-width: 668px;
  border-radius: 16px;
  overflow: hidden;
`;

export const ContinueWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const ModalConfirmWrapper = styled.div`
  width: 375px;
  height: 310px;
  padding: 32px 0px 0px 0px;
  background-color: #405ed6;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
`;
