import { Routes, Route } from 'react-router-dom';
import { Route as CustomRoute } from '../../components';
import EditNote from './CoachProfile/EditNote';
import { navRoutes, userRoles } from '../../constants';
import LearnerReferral from './LearnerReferral';
import LearnerProfile from './LearnerProfile/Profile';
import Coaches from './Coaches';
import Learners from './Learners';
import AssignCoach from './AssignCoach';
import AddCoaches from './AddCoaches';
import PageNotFound from '../PageNotFound';
import LearnerMilestonesReached from './LearnerMilestonesReached';
import EditRecruitmentForm from './EditRecruitmentForm';
import SplashScreen from './SplashScreen';
import OrganisationsSelectWrapper from './OrganisationsSelectWrapper';
import EditLearnerNote from './LearnerProfile/EditNote';
import ViewAllNotes from './CoachProfile/ViewAllNotes';
import ViewAllLearnerNotes from './LearnerProfile/ViewAllLearnerNotes';
import Facilitators from './Facilitators';
import LearnerSessions from './LearnerSessions';

import {
  ManageInterviewOrReject,
  ApproveCoach,
  CoachInviteLink,
} from './ManageCoachStatus';
import InterestForm from '../InterestForm';
import Signup from './Signup';
import NewCoachProfile from './CoachProfile/Profile';
import AllNotesPage from './CoachProfile/AllNotesPage';
import SwitchCourse from './SwitchCourse';
import AutoLogin from './AutoLogin';
import TowFactorAuth from './TowFactorAuth';

function CommonRouter() {
  return (
    <Routes>
      <Route
        path={navRoutes.GENERAL.TWO_FACTOR_AUTH}
        element={
          <CustomRoute
            Component={TowFactorAuth}
            layout="side"
            contentBodyProps={{
              alignItems: 'center',
            }}
          />
        }
      />
      <Route
        path={navRoutes.COMMON.SELECT_ORGANISATION}
        element={
          <CustomRoute
            Component={OrganisationsSelectWrapper}
            layout="full"
            loggedWithoutOrganisation
            contentBodyProps={{
              alignItems: 'center',
            }}
          />
        }
      />

      <Route
        path={navRoutes.COMMON.SWITCH_COURSE}
        element={
          <CustomRoute
            Component={SwitchCourse}
            layout="center"
            isPrivate
            allowedRoles="all"
          />
        }
      />

      <Route
        path={navRoutes.COMMON.AUTO_LOGIN}
        element={<CustomRoute Component={AutoLogin} layout="center" />}
      />

      <Route
        path={navRoutes.COACH.SIGNUP}
        element={
          <CustomRoute
            Component={Signup}
            layout="side"
            sideColor="neutralLight"
            image="secondaryColor"
            publicOnly
          />
        }
      />
      <Route
        path={navRoutes.COMMON.COACH_PROFILE}
        element={
          <CustomRoute
            Component={NewCoachProfile}
            layout="center"
            contentProps={{ maxWidth: '1040px' }}
            contentBodyProps={{
              paddingTop: '0',
              ptT: '0',
              plT: '0',
              prT: '0',
              ptM: '0',
              plM: '0',
              prM: '0',
            }}
            isPrivate
            allowedRoles={[
              userRoles.FACILITATOR,
              userRoles.ORGANISATION_ADMIN,
              userRoles.ADMIN,
            ]}
          />
        }
      />
      <Route
        path={navRoutes.COMMON.COACH_NOTES}
        element={
          <CustomRoute
            Component={AllNotesPage}
            layout="center"
            contentProps={{ maxWidth: '1040px' }}
            contentBodyProps={{
              paddingTop: '0',
              ptT: '0',
              plT: '0',
              prT: '0',
              ptM: '0',
              plM: '0',
              prM: '0',
            }}
            isPrivate
            allowedRoles={[
              userRoles.FACILITATOR,
              userRoles.ORGANISATION_ADMIN,
              userRoles.ADMIN,
            ]}
          />
        }
      />
      <Route
        path={navRoutes.COMMON.EDIT_NOTE}
        element={
          <CustomRoute
            Component={EditNote}
            layout="full"
            isPrivate
            allowedRoles={[userRoles.FACILITATOR, userRoles.ORGANISATION_ADMIN]}
          />
        }
      />

      <Route
        path={navRoutes.COMMON.ADD_LEARNER}
        element={
          <CustomRoute
            Component={LearnerReferral}
            layout="center"
            isPrivate
            allowedRoles={[userRoles.FACILITATOR, userRoles.ORGANISATION_ADMIN]}
          />
        }
      />
      <Route
        path={navRoutes.COMMON.LEARNER_REFERRAL}
        element={
          <CustomRoute
            Component={LearnerReferral}
            layout="full"
            isPrivate
            allowedRoles={[
              userRoles.FACILITATOR,
              userRoles.ORGANISATION_ADMIN,
              userRoles.ADMIN,
            ]}
          />
        }
      />

      <Route
        path={navRoutes.COMMON.LEARNER}
        element={
          <CustomRoute
            Component={LearnerProfile}
            layout="center"
            contentProps={{ maxWidth: '1040px' }}
            contentBodyProps={{
              paddingTop: '0',
              ptT: '0',
              plT: '0',
              prT: '0',
              ptM: '0',
              plM: '0',
              prM: '0',
            }}
            isPrivate
            allowedRoles={[
              userRoles.COACH,
              userRoles.FACILITATOR,
              userRoles.ORGANISATION_ADMIN,
              userRoles.ADMIN,
            ]}
          />
        }
      />
      <Route
        path={navRoutes.COMMON.APPROVE_COACH}
        element={
          <CustomRoute
            Component={ApproveCoach}
            layout="side"
            sideColor="neutralLight"
            isPrivate
            allowedRoles={[userRoles.FACILITATOR, userRoles.ORGANISATION_ADMIN]}
          />
        }
      />
      <Route
        path={navRoutes.COMMON.COACH_INVITE_LINK}
        element={
          <CustomRoute
            Component={CoachInviteLink}
            layout="side"
            sideColor="neutralLight"
            isPrivate
            allowedRoles={[userRoles.FACILITATOR, userRoles.ORGANISATION_ADMIN]}
          />
        }
      />
      <Route
        path={navRoutes.COMMON.MANAGE_COACH_APPLICATION}
        element={
          <CustomRoute
            Component={ManageInterviewOrReject}
            layout="side"
            sideColor="neutralLight"
            isPrivate
            allowedRoles={[userRoles.FACILITATOR, userRoles.ORGANISATION_ADMIN]}
          />
        }
      />
      <Route
        path={navRoutes.COMMON.COACHES}
        element={
          <CustomRoute
            Component={Coaches}
            isPrivate
            layout="full"
            overlayColor
            allowedRoles={[
              userRoles.FACILITATOR,
              userRoles.ORGANISATION_ADMIN,
              userRoles.ADMIN,
            ]}
          />
        }
      />
      <Route
        path={navRoutes.COMMON.LEARNERS}
        element={
          <CustomRoute
            Component={Learners}
            isPrivate
            layout="full"
            overlayColor
            allowedRoles={[
              userRoles.FACILITATOR,
              userRoles.ORGANISATION_ADMIN,
              userRoles.ADMIN,
            ]}
          />
        }
      />
      <Route
        path={navRoutes.COMMON.ASSIGN_COACH}
        element={
          <CustomRoute
            Component={AssignCoach}
            isPrivate
            sideColor="neutralMain"
            layout="full"
            overlayColor
            allowedRoles={[userRoles.FACILITATOR, userRoles.ORGANISATION_ADMIN]}
          />
        }
      />
      <Route
        path={navRoutes.COMMON.COACH_DETAILS}
        element={
          <CustomRoute
            Component={InterestForm}
            isPrivate
            layout="side"
            overlayColor
            allowedRoles={[
              userRoles.ADMIN,
              userRoles.FACILITATOR,
              userRoles.ORGANISATION_ADMIN,
            ]}
            adminView
          />
        }
      />
      <Route
        path={navRoutes.COMMON.ADD_COACHES}
        element={
          <CustomRoute
            Component={AddCoaches}
            layout="full"
            isPrivate
            allowedRoles={[userRoles.FACILITATOR, userRoles.ORGANISATION_ADMIN]}
          />
        }
      />

      <Route
        path={navRoutes.COMMON.LEARNER_REACHED_MILESTONES}
        element={
          <CustomRoute
            Component={LearnerMilestonesReached}
            layout="side"
            sideColor="neutralLight"
            isPrivate
            allowedRoles={[
              userRoles.ADMIN,
              userRoles.FACILITATOR,
              userRoles.COACH,
              userRoles.ORGANISATION_ADMIN,
            ]}
          />
        }
      />
      <Route
        path={navRoutes.COMMON.EDIT_ORGANISATION_RECRUITMENT_FORM}
        element={
          <CustomRoute
            Component={EditRecruitmentForm}
            layout="center"
            contentProps={{ maxWidth: '1120px' }}
            isPrivate
            allowedRoles={[userRoles.FACILITATOR, userRoles.ORGANISATION_ADMIN]}
          />
        }
      />
      <Route
        path={navRoutes.COMMON.SPLASH_SCREEN}
        element={
          <CustomRoute
            Component={SplashScreen}
            layout="full"
            isPrivate
            allowedRoles={[
              userRoles.COACH,
              userRoles.FACILITATOR,
              userRoles.ORGANISATION_ADMIN,
            ]}
          />
        }
      />

      <Route
        path={navRoutes.COMMON.EDIT_LEARNER_NOTE}
        element={
          <CustomRoute
            Component={EditLearnerNote}
            layout="full"
            isPrivate
            allowedRoles={[
              userRoles.FACILITATOR,
              userRoles.ORGANISATION_ADMIN,
              userRoles.ADMIN,
              userRoles.COACH,
            ]}
          />
        }
      />
      <Route
        path={navRoutes.COMMON.COACH_NOTES}
        element={
          <CustomRoute
            Component={ViewAllNotes}
            layout="full"
            isPrivate
            allowedRoles={[
              userRoles.FACILITATOR,
              userRoles.ADMIN,
              userRoles.ORGANISATION_ADMIN,
            ]}
          />
        }
      />
      <Route
        path={navRoutes.COMMON.LEARNER_NOTES}
        element={
          <CustomRoute
            Component={ViewAllLearnerNotes}
            layout="full"
            isPrivate
            allowedRoles={[
              userRoles.FACILITATOR,
              userRoles.COACH,
              userRoles.ADMIN,
              userRoles.ORGANISATION_ADMIN,
            ]}
          />
        }
      />
      <Route
        path={navRoutes.COMMON.FACILITATORS}
        element={
          <CustomRoute
            Component={Facilitators}
            layout="full"
            isPrivate
            allowedRoles={[userRoles.ORGANISATION_ADMIN, userRoles.ADMIN]}
          />
        }
      />
      <Route
        path={navRoutes.COMMON.LEARNER_SESSIONS}
        element={
          <CustomRoute
            Component={LearnerSessions}
            layout="center"
            sideColor="neutralLight"
            isPrivate
            allowedRoles={[
              userRoles.COACH,
              userRoles.FACILITATOR,
              userRoles.ADMIN,
              userRoles.ORGANISATION_ADMIN,
            ]}
            contentBodyProps={{ maxWidth: '1038px' }}
          />
        }
      />

      <Route
        path={'*'}
        element={
          <CustomRoute
            Component={PageNotFound}
            layout="general"
            sideColor="neutralLight"
          />
        }
      />
    </Routes>
  );
}

export default CommonRouter;
