import { fields, createSchema, validate as _validate } from '..';

const { urlSlug, optionalText } = fields;

const schema = createSchema({
  uniqueSlug: urlSlug,
  mainBrandColour: optionalText,
  secondaryBrandColour: optionalText,
});

const validate = (data) => _validate(schema, data);

export default validate;
