import * as T from '../../../components/Typography';
import { BasicButton } from '../../../components/Button';
import { navRoutes } from '../../../constants';
import { useAuth } from 'context/auth';
import * as S from './style';

const ReviewCompleted = () => {
  const { user } = useAuth();

  return (
    <S.Wrapper>
      <S.ButtonWrapper flex="1">
        <T.H2 ta="center">Review complete!</T.H2>
      </S.ButtonWrapper>
      <S.ButtonWrapper flex="1">
        <BasicButton
          to={navRoutes.COACH.REVIEW_LEARNER_SELF_LEARNING.replace(
            ':id',
            user.selectedLearnerId
          )}
          icon="next"
          variant="primary"
        >
          <T.P color="white" weight="semi">
            Next
          </T.P>
        </BasicButton>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default ReviewCompleted;
