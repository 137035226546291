import { useState } from 'react';

import {
  Grid,
  Typography as T,
  Inputs as I,
  Button,
  Alert,
  ValidationErrorWithPlayButton,
} from '../../../components';
import * as S from './style';
import { validateEmail } from '../../../validation/schemas/signup-learner';
import { useLearnerValidateEmailForSignup } from '../../../api-calls/learners.queries';
import { facilitatorDetailsPublic, navRoutes as R } from '../../../constants';
import { useGeneralState } from '../../../context/general-state';
import { cleanEmail, getMediaUrl } from '../../../helpers';
import { audio } from '../../../constants';
import { useSiteSettings } from 'context/site-settings';

const { Row, Col } = Grid;

const TryNewEmail = ({ setFormState, setStep }) => {
  const { courseCode } = useSiteSettings();
  const { state: generalState } = useGeneralState();
  const [email, setEmail] = useState('');
  const [validationErrs, setValidationErrs] = useState({});
  const {
    mutateAsync: validateEmailForSignup,
    isLoadingValidateEmail,
    error: httpErrorValidateEmail,
  } = useLearnerValidateEmailForSignup();

  const handleContinue = () => {
    const cleanedEmail = cleanEmail(email);
    try {
      validateEmail({
        email: cleanedEmail,
      });
      setValidationErrs({});
    } catch (error) {
      setValidationErrs(error.inner);
      return;
    }

    validateEmailForSignup(
      { email, courseCode },
      {
        onSuccess: (data) => {
          if (data?.isLinkedToOtherCourse) {
            setValidationErrs({
              email: 'This email is already linked to a learner',
            });
          }

          if (data?.isEmailValid) {
            setFormState({ email: cleanedEmail, validationErrs: {} });
            setStep('2');
          }
        },
      }
    );
  };

  const emailExistError =
    httpErrorValidateEmail?.message &&
    httpErrorValidateEmail?.message.includes('already exists')
      ? httpErrorValidateEmail?.message
      : '';

  return (
    <>
      <Row mt="48px">
        <Col w={[4, 12, 12]} ai="center">
          <S.TitleWrapper>
            <Button.PlayButton
              width={'56px'}
              height={'56px'}
              radius={12}
              color="neutralMain"
              iconProps={{
                height: '32px',
                width: '32px',
              }}
              audioUrl={getMediaUrl(null, true, generalState?.preferredVoice)}
              fallbackText={`Enter a different email`}
            />
            <T.H1>Enter a different email</T.H1>
          </S.TitleWrapper>
        </Col>
      </Row>
      <Row mt="48px">
        <Col w={[4, 12, 12]} ai="center">
          <Alert
            variant="secondary"
            audio={audio.alertTryDifferentEmail}
            message="Try a different email address or contact Shannon Trust at"
          >
            <T.Link
              to={`tel:${facilitatorDetailsPublic.phoneNumber}`}
              color="neutralMain"
              external
              weight="bold"
            >
              {facilitatorDetailsPublic.phoneNumber}
            </T.Link>
          </Alert>
        </Col>
      </Row>
      <Row mt="48px">
        <Col w={[4, 12, 12]} ai="center">
          <I.BasicInput
            id="email-id"
            label="Email"
            placeholder="email..."
            margins={{ mt: '2', mb: '1' }}
            type="text"
            value={email}
            autoFocus
            handleChange={(input) => {
              setEmail(input);
              setValidationErrs((prev) => ({ ...prev, email: null }));
            }}
            error={
              (validationErrs.email || emailExistError) && (
                <ValidationErrorWithPlayButton
                  message={validationErrs.email || emailExistError}
                />
              )
            }
            audioUrl={getMediaUrl(
              audio.enterYourLearnerId,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="Enter your Learner ID"
          />
          <S.InfoWrapper>
            <Button.PlayButton
              width={'32px'}
              height={'32px'}
              radius={12}
              iconProps={{
                height: '25px',
                width: '25px',
              }}
              color="neutralMain"
              withoutButtonStyle={true}
              backgroundColor="none"
              audioUrl={getMediaUrl(null, true, generalState?.preferredVoice)}
              fallbackText={`Forget you learner I.D? Contact Shannon Trust`}
            />
            <T.Link
              size="regular"
              display="flex"
              external
              color="neutral90"
              weight="400"
              underline
              to={`mailto:${R.EXTERNAL.CONTACT_US}`}
            >
              Forget you learner ID?&nbsp;
              <T.P color="primaryMain">Contact Shannon Trust</T.P>
            </T.Link>
          </S.InfoWrapper>
        </Col>
      </Row>
      <Row mt="32px">
        <Col w={[4, 12, 12]} ai="center">
          {httpErrorValidateEmail?.message && (
            <ValidationErrorWithPlayButton
              message={httpErrorValidateEmail?.message}
            />
          )}
          <Button.BasicButton
            id="continue-button"
            variant="primary"
            disabled={validationErrs.email}
            loading={isLoadingValidateEmail}
            onClick={handleContinue}
            showSound
            width="100%"
            height="64px"
            audioUrl={getMediaUrl(
              audio.continue,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="Continue"
          >
            Continue
          </Button.BasicButton>
        </Col>
      </Row>
    </>
  );
};

export default TryNewEmail;
