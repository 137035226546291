import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import * as CS from '../style';
import * as T from '../../Typography';
import { PlayButton } from 'components/Button';

export default function RowRadioButtonsGroup({
  label,
  labelAudioUrl,
  helper,
  optional,
  labelColor,
  color,
  options = [],
  value,
  onChange,
  m,
  disabled,
  w,
  error,
  row = false,
  name,
  showSound = false,
}) {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <CS.Field w={w} disabled={disabled} {...m}>
      {labelAudioUrl && (
        <PlayButton
          radius={12}
          iconProps={{
            height: '32px',
            width: '32px',
          }}
          audioUrl={labelAudioUrl}
          color="neutralMain"
          backgroundColor="white"
          fallbackText={label}
          mr="5"
        />
      )}

      <FormControl>
        <FormLabel id={`group-label-${label}`}>
          {label && (
            <CS.Label htmlFor={label}>
              <T.P weight="bold" color={labelColor || color} m="0">
                {label}
              </T.P>
              {optional && <CS.OptionalTag ml="1">(optional)</CS.OptionalTag>}
            </CS.Label>
          )}
          {helper && (
            <T.P isSmall color={'neutral80'} mb="2" ml="2">
              {helper}
            </T.P>
          )}
        </FormLabel>
        <CS.RadioGroupWrapper>
          <RadioGroup
            aria-labelledby={`group-label-${label}`}
            name={name || `${label}-group-radio-buttons-group`}
            value={value}
            onChange={handleChange}
            {...(showSound && {
              style: { display: 'flex', flexFlow: 'column' },
            })}
            row={row}
          >
            {options.map((option) => (
              <div key={`${label}-${option.value}`}>
                {(option.audioUrl || option.fallbackText) && (
                  <PlayButton
                    width={'32px'}
                    height={'32px'}
                    radius={12}
                    iconProps={{
                      height: '25px',
                      width: '25px',
                    }}
                    color="neutralMain"
                    withoutButtonStyle={true}
                    backgroundColor="white"
                    {...(option.audioUrl && {
                      audioUrl: option.audioUrl,
                    })}
                    {...(option.fallbackText && {
                      fallbackText: option.fallbackText,
                    })}
                    style={{ top: '5px', right: '5px' }}
                  />
                )}
                <FormControlLabel
                  value={option.value}
                  control={<Radio />}
                  label={<T.P color="neutral90">{option.label}</T.P>}
                />
              </div>
            ))}
          </RadioGroup>
        </CS.RadioGroupWrapper>
      </FormControl>
      {error && (
        <T.P color="error" m="0" mt="1" mx={2}>
          {error}
        </T.P>
      )}
    </CS.Field>
  );
}
