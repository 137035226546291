import { useEffect, useState } from 'react';
import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import { LEARNER } from '../../../constants/nav-routes';
import { useGeneralState } from '../../../context/general-state';
import { useProgressCheck } from '../../../context/progress-check';
import { types as Ty } from '../../../constants';

import ListenAndRepeat from '../../Exercises/ExerciseStep/ListenAndRepeat';
import ListenAndRepeatGrid from '../../Exercises/ExerciseStep/ListenAndRepeatGrid';
import { replaceMediaKeysWithUrls } from '../../../helpers';
import Comprehensions from '../../Exercises/ExerciseStep/Comprehensions';
import { useCreateCompletedProgressCheck } from '../../../api-calls/progressChecks.queries';

const initStep = {
  audioKey: '',
  correctOption: null,
  imageKey: '',
  options: null,
  subtitle: '',
  title: '',
};

const ProgressCheckStep = () => {
  const navigate = useNavigate();
  const { steps } = useProgressCheck();
  const { state: generalState } = useGeneralState();
  const [searchParams] = useSearchParams();
  const sandboxMode = searchParams.get('sandboxMode');
  const { stepId, milestoneId } = useParams();
  const [step, setStep] = useState(initStep);
  const { search } = useLocation();

  const index = stepId - 1;
  const { mutate: createCompletedProgressCheck } =
    useCreateCompletedProgressCheck({
      milestoneId,
    });
  const handleNext = () => {
    if (index === steps.length - 1) {
      if (sandboxMode === 'true' || generalState?.isPlayground) {
        return navigate(LEARNER.PROGRESS_CHECK_THANK_YOU + search);
      }
      createCompletedProgressCheck(
        { milestoneId },
        {
          onSuccess: () => {
            navigate(LEARNER.PROGRESS_CHECK_THANK_YOU + search);
          },
        }
      );
    } else {
      navigate(
        LEARNER.PROGRESS_CHECK_QUESTIONS.replace(
          ':stepId',
          Number(stepId) + 1
        ).replace(':milestoneId', milestoneId) + search
      );
    }
  };
  useEffect(() => {
    if (steps?.length)
      setStep({
        ...replaceMediaKeysWithUrls(steps[index], generalState?.preferredVoice),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps, stepId, milestoneId, index]);

  switch (step.type) {
    case Ty.exerciseTypes.LISTEN_AND_REPEAT:
      return <ListenAndRepeat {...step} handleNext={handleNext} />;

    case Ty.exerciseTypes.LISTEN_AND_REPEAT_GRID:
      return <ListenAndRepeatGrid {...step} handleNext={handleNext} />;
    case Ty.exerciseTypes.TYPING:
      return (
        <Comprehensions isCheck="true" handleNext={handleNext} {...step} />
      );
    case Ty.exerciseTypes.MULTIPLE_CHOICE:
      return (
        <Comprehensions isCheck="true" handleNext={handleNext} {...step} />
      );
    default:
      return null;
  }
};

export default ProgressCheckStep;
