import { fields, createSchema, validate as _validate } from '..';

const { requiredText, requiredRadioNumber, optionalText, urlOptional } = fields;

const schema = createSchema({
  title: requiredText,
  description: optionalText,
  introduction: requiredText,
  videoLink: urlOptional.matches('/embed/', {
    message: 'Please enter a valid YouTube embed link',
    excludeEmptyString: true,
  }),
  order: requiredRadioNumber,
});

const validate = (data) => _validate(schema, data);

export default validate;
