import theme from '../../../theme';

const Play = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '40'}
    height={height || '40'}
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8 37.6C11.5216 37.6 4 30.0784 4 20.8C4 11.5216 11.5216 4 20.8 4C30.0784 4 37.6 11.5216 37.6 20.8C37.6 30.0784 30.0784 37.6 20.8 37.6ZM17.9148 29.3702C17.0982 29.8975 16.0088 29.663 15.4815 28.8465C15.2977 28.5619 15.2 28.2304 15.2 27.8917V13.7081C15.2 12.7361 15.988 11.9481 16.96 11.9481C17.2987 11.9481 17.6302 12.0459 17.9148 12.2296L28.8969 19.3214C29.7135 19.8487 29.948 20.9381 29.4207 21.7547C29.2852 21.9645 29.1067 22.143 28.8969 22.2785L17.9148 29.3702ZM28.029 20.6655C28.1032 20.7135 28.1245 20.8125 28.0766 20.8867C28.0643 20.9058 28.048 20.922 28.029 20.9343L17.0468 28.0261C16.9726 28.0741 16.8735 28.0527 16.8256 27.9785C16.8089 27.9526 16.8 27.9225 16.8 27.8917V13.7081C16.8 13.6198 16.8716 13.5481 16.96 13.5481C16.9908 13.5481 17.0209 13.557 17.0468 13.5737L28.029 20.6655Z"
      fill={theme.colors[color] || color || '#405ED6'}
    />
  </svg>
);

export default Play;
