import { useState } from 'react';
import * as S from './style';
import * as T from '../../../components/Typography';
import { BasicButton } from 'components/Button';
import Modal from 'components/ModalNew';

import axios from 'axios';

const SyncWithSalesforce = () => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async () => {
    try {
      setError(null);
      setLoading(true);
      await axios.post('/salesforce');
    } catch (error) {
      setError(error?.response?.data?.message || 'Something went wrong');
    } finally {
      setVisible(true);
      setLoading(false);
    }
  };

  return (
    <>
      <S.Wrapper>
        <T.H3 mb={7}>
          This will sync the data from Salesforce with the data in the
          application manually.
        </T.H3>
        <BasicButton
          variant="secondary"
          onClick={handleSubmit}
          style={{ maxWidth: '300px' }}
          loading={loading}
        >
          Sync now
        </BasicButton>
      </S.Wrapper>
      <Modal visible={visible} setIsModalVisible={setVisible}>
        <T.H2 color="white">
          {error
            ? error
            : 'The data has been synced successfully with Salesforce'}
        </T.H2>

        <BasicButton
          variant="secondary"
          onClick={() => setVisible(false)}
          style={{ maxWidth: '300px' }}
          mt={4}
        >
          Close
        </BasicButton>
      </Modal>
    </>
  );
};

export default SyncWithSalesforce;
