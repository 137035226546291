import { useState, useEffect, useReducer, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Question from './Question';
import {
  useGetOrganisationRecruitmentForm,
  useUpdateRecruitmentForm,
} from '../../../api-calls/organisations.queries';
import { useAuth } from '../../../context/auth';
import * as S from './style';
import DnD from './DnD';
import * as T from '../../../components/Typography';
import { BasicButton } from 'components/Button';
import { BasicInput, BasicInputWrapper } from 'components/Inputs';
import RichTextInput from 'components/RichText';
import StaticQuestions from './StaticQuestions';
import { Checkbox } from '../../../components/Inputs';
import { EXTERNAL, GENERAL } from '../../../constants/nav-routes';
import { editRecruitmentForm as validate } from '../../../validation/schemas';
import Icon from '../../../components/Icon';
import { useOrganisationDetails } from '../../../context/organisation-details';
import { Loading } from '../../../components';
import { BackButton } from 'components/BackButton';

const reducer = (state, newState) => {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
};

const InterestForm = () => {
  const { organisationDetails } = useOrganisationDetails();
  const { user } = useAuth();
  const [updatedData, _setUpdatedData] = useReducer(reducer, null);
  const contentUnsaved = useRef(false);

  const setUpdatedData = (newState) => {
    _setUpdatedData(newState);
    contentUnsaved.current = true;
  };

  const [validationErrs, setValidationErrs] = useState({
    hasError: false,
  });
  const navigate = useNavigate();
  const submitAttempt = useRef(false);

  const { data, error, isLoading, isSuccess } =
    useGetOrganisationRecruitmentForm({ id: user.organisationId });

  const { mutateAsync: updateRecruitmentForm, isLoading: isUpdating } =
    useUpdateRecruitmentForm({ id: user.organisationId });

  useEffect(() => {
    if (isSuccess) {
      _setUpdatedData({
        ...data,
        questions: data?.questions
          ?.sort((a, b) => a.order - b.order)
          .map((q, i) => ({ ...q, order: i + 1 })),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isLoading]);

  const setQuestion = (questionId, value) => {
    setUpdatedData((prev) => {
      if (value.deleted) {
        const updatedQuestions = [
          ...prev.questions.filter((q) => q.id !== questionId),
        ];
        return { ...prev, questions: updatedQuestions };
      }
      const updatedQuestions = [...prev.questions];
      const questionIndex = updatedQuestions.findIndex(
        (q) => q.id === questionId
      );
      const updatedQuestion = { ...updatedQuestions[questionIndex], ...value };
      updatedQuestions[questionIndex] = updatedQuestion;

      // check if question type has changed from paragraph or short answer to multiple choice or single choice
      if (updatedQuestion.type !== prev.questions[questionIndex].type) {
        updatedQuestion.options =
          updatedQuestion.type === 'PARAGRAPH' ||
          updatedQuestion.type === 'SHORT_ANSWER'
            ? null
            : [
                {
                  label: '',
                  required: false,
                },
              ];
      }

      return { ...prev, questions: updatedQuestions };
    });
  };

  const onAddQuestionClick = () => {
    const largestId = updatedData?.questions?.length
      ? Math.max(...updatedData?.questions?.map((q) => q.id))
      : 0;
    const newQuestion = {
      id: largestId + 1,
      label: '',
      order: (updatedData?.questions?.length || 0) + 1,
      type: 'SHORT_ANSWER',
      required: false,
      options: null,
      isNew: true,
    };
    setUpdatedData((prev) => ({
      ...prev,
      questions: prev?.questions?.length
        ? [...prev?.questions, newQuestion]
        : [newQuestion],
    }));
  };

  const validateForm = () => {
    try {
      validate(updatedData);
      setValidationErrs({ hasError: false });

      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setValidationErrs({ hasError: true, ...error.inner });
      }
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (!isValid) return;

    const { recruitmentFormTitle, recruitmentFormDescription, questions } =
      updatedData;

    const payload = {
      recruitmentFormTitle,
      recruitmentFormDescription,
      questions: questions.map((q, i) => ({
        ...q,
        order: i + 1,
        options: q.options?.filter((o) => o?.label !== ''),
      })),
    };

    await updateRecruitmentForm(payload);
    contentUnsaved.current = false;

    return;
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedData]);

  if (error?.message) {
    return <div>{error.message}</div>;
  }

  if (!updatedData) {
    return <Loading />;
  }

  if (contentUnsaved.current) {
    window.onbeforeunload = () => true;
  } else {
    window.onbeforeunload = undefined;
  }

  return (
    <S.Wrapper>
      <BackButton mb="5" />
      <S.HeadingWrapper>
        <T.H1>Edit coach recruitment form</T.H1>
        <S.HeadingButtons>
          <BasicButton
            variant="tertiary"
            ta="center"
            width="auto"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel editing
          </BasicButton>
          <BasicButton
            variant="primary"
            ta="center"
            width="auto"
            onClick={handleSubmit}
            disabled={isUpdating || !contentUnsaved.current}
            loading={isUpdating}
          >
            Save edits
          </BasicButton>
          <BasicButton
            variant="secondary"
            ta="center"
            width="auto"
            to={`${GENERAL.INTEREST.replace(
              ':uniqueSlug',
              organisationDetails.uniqueSlug
            )}/?preview=true`}
            disabled={isUpdating || contentUnsaved.current}
          >
            Preview form
          </BasicButton>
        </S.HeadingButtons>
      </S.HeadingWrapper>
      <S.Divider />
      <BasicInput
        label="Form title"
        value={updatedData?.recruitmentFormTitle}
        handleChange={(value) =>
          setUpdatedData({ recruitmentFormTitle: value })
        }
        placeholder="Add title..."
        error={validationErrs.recruitmentFormTitle}
      />

      <BasicInputWrapper
        label="Recruitment form description"
        value={updatedData?.recruitmentFormDescription}
        name="description"
        error={validationErrs.recruitmentFormDescription}
        m={{
          mt: 4,
        }}
        InputComponent={
          <RichTextInput
            htmlContent={updatedData?.recruitmentFormDescription}
            handleChange={(value) =>
              setUpdatedData({ recruitmentFormDescription: value })
            }
          />
        }
      />
      <S.Divider noMarginBottom />
      <T.P mt={5} mb={5} weight="bold">
        Form questions
      </T.P>
      <StaticQuestions />

      <S.QuestionsWrapper>
        {updatedData?.questions?.length > 0 && (
          <DnD
            items={updatedData?.questions}
            setItems={(questions) => setUpdatedData({ questions })}
            renderItem={({ question, questionIndex }) => {
              return (
                <Question
                  question={question}
                  setQuestion={setQuestion}
                  error={validationErrs.questions?.[questionIndex]}
                />
              );
            }}
          />
        )}
      </S.QuestionsWrapper>

      <S.AddQuestionButton onClick={onAddQuestionClick}>
        <Icon icon="plus" width={24} height={24} mr={2} />
        <T.P color="primaryMain">Add new question</T.P>
      </S.AddQuestionButton>

      <S.Divider />
      <Checkbox
        ai="start"
        label={
          <T.P>
            I declare that the information on this form is true and complete
          </T.P>
        }
        handleChange={() => {}}
        plain
      />

      <Checkbox
        ai="start"
        label={
          <T.P color="neutralMain">
            By applying I give consent to Turning Pages using and keeping the
            information I provide as described in our&nbsp;
            <T.Link
              to={EXTERNAL.PRIVACY_POLICY}
              color="neutralMain"
              external
              underline
            >
              Privacy Policy
            </T.Link>
          </T.P>
        }
        handleChange={() => {}}
        plain
      />
    </S.Wrapper>
  );
};

export default InterestForm;
