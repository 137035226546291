const FileUpload = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M3 16.5417V18.7917C3 19.3885 3.23705 19.9608 3.65901 20.3827C4.08097 20.8047 4.65326 21.0417 5.25 21.0417H18.75C19.3467 21.0417 19.919 20.8047 20.341 20.3827C20.7629 19.9608 21 19.3885 21 18.7917V16.5417M7.5 7.54175L12 3.04175M12 3.04175L16.5 7.54175M12 3.04175V16.5417"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FileUpload;
