import { createContext, useContext, useRef } from 'react';
import { useSpeechSynthesis as useSpeechSynthesisKit } from 'react-speech-kit';
import { useGeneralState } from './general-state';
const SpeechSynthesisContext = createContext({
  speak: (text) => {},
});

const findVoice = (voices, preferredVoice) => {
  if (!voices) return null;

  let selectedVoiceValue;
  let ukVoices;
  let ukVoice;

  ukVoices = voices.filter(
    (v, index) => v.lang.includes('en-GB') || v.lang.includes('en_GB')
  );

  if (preferredVoice === 'MALE') {
    selectedVoiceValue = voices.find(
      (v, index) =>
        v.voiceURI.includes('Daniel') ||
        v.name.includes('Daniel') ||
        v.voiceURI.includes('daniel')
    );
    if (!selectedVoiceValue) {
      ukVoice = ukVoices?.find((v) => v?.voiceURI?.includes('Male'));
    }
  } else {
    // FEMALE
    selectedVoiceValue = voices.find(
      (v, index) =>
        v.voiceURI.includes('Martha') ||
        v.name.includes('Martha') ||
        v.voiceURI.includes('martha')
    );
    if (!selectedVoiceValue) {
      ukVoice = ukVoices?.find((v) => v?.voiceURI?.includes('Female'));
    }
  }

  selectedVoiceValue =
    selectedVoiceValue || ukVoice || ukVoices?.[0] || voices[0];

  return selectedVoiceValue;
};
const SpeechSynthesisProvider = (props) => {
  const onEndFn = useRef(() => {});
  const { state } = useGeneralState();
  const {
    cancel,
    speak,
    voices,
    speaking,
    //  speaking, supported, pause, resume
  } = useSpeechSynthesisKit({
    onEnd: () => {
      if (typeof onEndFn.current === 'function') {
        onEndFn.current();
      }
    },
  });
  let selectedVoiceValue = findVoice(voices, state.preferredVoice);

  const _speak = (text, onEnd = () => {}) => {
    onEndFn.current = onEnd;
    if (speaking) {
      cancel();
    }

    setTimeout(() => {
      // put in timeout to make sure the "cancel" is called before the "speak"
      speak({
        text,
        voice: selectedVoiceValue,
      });
    }, 0);
  };

  const value = {
    speak: _speak,
  };
  return <SpeechSynthesisContext.Provider value={value} {...props} />;
};

const useSpeechSynthesis = () => {
  const value = useContext(SpeechSynthesisContext);
  return value;
};

export { SpeechSynthesisProvider, useSpeechSynthesis };
export default SpeechSynthesisContext;
