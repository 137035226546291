import theme from '../../../theme';

const Speaker = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.221 5.737 7.549 8.693H5.184c-.65 0-1.183.532-1.183 1.183v4.73c0 .651.533 1.183 1.183 1.183h2.365l4.672 2.957c.532.355 1.3.177 1.656-.355a1.14 1.14 0 0 0 .177-.591V6.683c0-.65-.532-1.183-1.183-1.183-.236.06-.473.118-.65.237ZM15.828 15.494c-.355 0-.591-.237-.591-.592 0-.177.059-.355.177-.414 1.183-1.123 1.301-2.956.237-4.198l-.237-.237c-.236-.236-.296-.591-.059-.828.237-.236.591-.295.828-.059 1.715 1.538 1.833 4.14.296 5.854-.119.119-.178.237-.296.296-.06.118-.237.178-.355.178Z"
      fill={color || theme.colors.neutralMain}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.827 17.857a.593.593 0 0 1-.591-.591c0-.236.177-.473.414-.532a4.693 4.693 0 0 0 2.838-6.032c-.473-1.36-1.537-2.365-2.838-2.838-.296-.119-.473-.473-.355-.769.118-.296.473-.473.769-.355 3.075 1.065 4.671 4.494 3.607 7.57-.592 1.655-1.892 3.015-3.607 3.606-.119-.059-.178-.059-.237-.059Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default Speaker;
