const Face4 = ({ width, height, color, ...props }) => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.05994 16.0025C3.05994 23.4252 9.07723 29.4425 16.4999 29.4425C23.9226 29.4425 29.9399 23.4252 29.9399 16.0025C29.9399 8.57979 23.9226 2.5625 16.4999 2.5625C9.07723 2.5625 3.05994 8.57979 3.05994 16.0025ZM14.5799 12.8025C14.5799 13.5094 14.0069 14.0825 13.2999 14.0825C12.593 14.0825 12.0199 13.5094 12.0199 12.8025C12.0199 12.0956 12.593 11.5225 13.2999 11.5225C14.0069 11.5225 14.5799 12.0956 14.5799 12.8025ZM19.6999 14.0825C20.4069 14.0825 20.9799 13.5094 20.9799 12.8025C20.9799 12.0956 20.4069 11.5225 19.6999 11.5225C18.993 11.5225 18.4199 12.0956 18.4199 12.8025C18.4199 13.5094 18.993 14.0825 19.6999 14.0825ZM21.2749 21.664C18.7654 20.083 14.2973 20.0928 11.7133 21.6689C11.4115 21.853 11.0177 21.7575 10.8336 21.4558C10.6496 21.154 10.745 20.7602 11.0468 20.5761C14.0419 18.7493 19.0325 18.7383 21.9572 20.581C22.2563 20.7694 22.3459 21.1646 22.1575 21.4637C21.9691 21.7627 21.5739 21.8524 21.2749 21.664Z"
      fill="#3B3C42"
    />
  </svg>
);

export default Face4;
