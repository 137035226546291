import { useReducer, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Typography as T,
  Inputs as I,
  Button,
  ValidationErrorWithPlayButton,
} from '../../../components';
import * as S from './style';
import validate from '../../../validation/schemas/login';
import { useLogin } from '../../../api-calls/learners.queries';
import { useAuth } from '../../../context/auth';
import { navRoutes as R, userRoles } from '../../../constants';
import ReactGA from 'react-ga4';
import { useGeneralState } from '../../../context/general-state';
import { getMediaUrl } from '../../../helpers';
import { audio, navRoutes } from '../../../constants';
import Redirect from 'components/Route/Redirect';
import Divider from '../../../components/Divider/Divider';

const { Row, Col } = Grid;
const { PlayButton } = Button;

const initialState = {
  learnerUniqueId: '',
  validationErrs: {},
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const Login = () => {
  const submitAttempt = useRef(false);
  const [state, setState] = useReducer(reducer, initialState);
  const { learnerUniqueId, validationErrs } = state;
  const navigate = useNavigate();
  const { setUser, user } = useAuth();
  const { mutateAsync: login, isLoading, error: httpError } = useLogin(null);
  const { state: generalState } = useGeneralState();

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learnerUniqueId]);

  const validateForm = () => {
    try {
      validate({
        learnerUniqueId,
        role: userRoles.LEARNER,
      });
      setState({ validationErrs: {} });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: error.inner });
      }
      return false;
    }
  };

  const handleLogin = async () => {
    login(
      { learnerUniqueId },
      {
        onSuccess: (data) => {
          setUser(data);
          if (
            (data.role === userRoles.LEARNER ||
              data.role === userRoles.COACH) &&
            ReactGA.isInitialized
          ) {
            ReactGA.event({ category: 'login', action: 'login' });
            ReactGA.set({ ...data });
          }
          let redirectPath = R.LEARNER.DASHBOARD;
          if (data?.isSelfSignedUp || data?.onboarded) {
            redirectPath = R.LEARNER.DASHBOARD;
          } else {
            redirectPath = R.LEARNER.WELCOME;
          }
          navigate(redirectPath);
        },
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      handleLogin();
    }
  };

  if (user.id && user.role === '') {
    return <Redirect to={R.COMMON.SELECT_ORGANISATION} />;
  }

  return (
    <S.Form onSubmit={handleSubmit}>
      <Row mb={6}>
        <Col w={[4, 12, 12]} ai="center">
          <PlayButton
            width={'56px'}
            height={'56px'}
            radius={12}
            iconProps={{
              height: '32px',
              width: '32px',
            }}
            color="neutralMain"
            backgroundColor="white"
            audioUrl={getMediaUrl(
              audio.welcome,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="Welcome"
          />
          <T.H1 weight="bold" color="neutralMain" ml="3" mt="1">
            Welcome
          </T.H1>
        </Col>
      </Row>
      <Row mb={4}>
        <Col w={[4, 12, 12]}>
          <PlayButton
            width={'56px'}
            height={'56px'}
            radius={12}
            iconProps={{
              height: '32px',
              width: '32px',
            }}
            size="medLarge"
            weight="bold"
            color="neutralMain"
            backgroundColor="none"
            withoutButtonStyle={true}
            mlText={0}
            audioUrl={getMediaUrl(
              audio.logInAsLearner,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="Log in as learner"
          >
            Log in as learner
          </PlayButton>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 12]} mt="5">
          <I.BasicInput
            id="learner-id"
            label="Enter your Learner ID..."
            placeholder="Learner ID..."
            margins={{ mt: '2', mb: '1' }}
            type="text"
            value={learnerUniqueId}
            autoFocus
            handleChange={(input) => setState({ learnerUniqueId: input })}
            error={
              validationErrs.learnerUniqueId && (
                <ValidationErrorWithPlayButton
                  message={validationErrs.learnerUniqueId}
                />
              )
            }
            audioUrl={getMediaUrl(
              audio.enterYourLearnerId,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="Enter your Learner ID"
          />
        </Col>
      </Row>

      <S.ButtonsWrapper mt="6" mb="4">
        <S.ButtonContainer w={[4, 12, 12]}>
          {httpError?.message && (
            <ValidationErrorWithPlayButton message={httpError?.message} />
          )}
          <Button.BasicButton
            id="login-button"
            variant="primary"
            disabled={false}
            loading={isLoading}
            type="submit"
            showSound
            width="100%"
            audioUrl={getMediaUrl(
              audio.logIn,
              true,
              generalState?.preferredVoice
            )}
            fallbackText="Log in"
          >
            Log in
          </Button.BasicButton>
        </S.ButtonContainer>
      </S.ButtonsWrapper>
      <Divider showText />
      <S.ButtonsWrapper mt="4">
        <S.ButtonContainer w={[4, 12, 12]}>
          <Button.BasicButton
            id="login-as-admin-button"
            variant="transparent"
            customColor="neutralMain"
            bgColor="neutralSurface"
            borderColor="neutral50"
            disabled={false}
            loading={isLoading}
            type="submit"
            to={navRoutes.GENERAL.LOGIN}
            width="100%"
            size="large"
          >
            Log in to your Admin / Coaching account here
          </Button.BasicButton>
        </S.ButtonContainer>
      </S.ButtonsWrapper>
    </S.Form>
  );
};

export default Login;
