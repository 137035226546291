import { Icon } from 'components';
import React from 'react';
import * as T from 'components/Typography';
import * as S from './style';
// import { BasicButton } from 'components/Button';

const LearnersEmptyState = () => {
  return (
    <S.LearnersEmptyState>
      <Icon icon="profile" color="neutral60" />
      <T.H2 ta="center" size="med">
        No assigned learners!
      </T.H2>
      {/* <T.P ta="center">
        You can assign a new learner by clicking at assign a learner on the top
        right on this page.
      </T.P>
      <BasicButton
        // handleClick={reassignCoach}
        maxWidth="220px"
        variant="tertiary"
        icon={'profile'}
        iconProps={{
          width: '20px',
          height: '25px',
        }}
      >
        Assign a learner
      </BasicButton> */}
    </S.LearnersEmptyState>
  );
};

export default LearnersEmptyState;
