import styled from '@emotion/styled';
import { Tag as AntdTag, Button as AntdButton } from 'antd';
import { Checkbox } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  overflow: auto;

  .ant-table {
    overflow: auto;
  }

  .ant-table table {
    font-size: 1rem;
    font-weight: 400;
  }
`;
export const TableScroll = styled.div`
  ${(props) =>
    props.isResponsiveScroll === true
      ? ` @media (max-width: 880px) {
    overflow: scroll;
   }
`
      : ``}
`;
export const IconWrapper = styled.div`
  cursor: pointer;
  background: ${({ theme }) => theme.colors.neutralLightNew};
  box-shadow: ${({ theme }) => theme.shadows.elevation1};
  border-radius: 8px;
  display: flex;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: background-color 300ms linear, color 300ms linear,
    opacity 300ms linear;
  :active {
    opacity: 0.9;
    background-color: ${({ theme }) => theme.colors.neutralMain};
    transform: translateY(1px) scale(0.99);
  }
`;

export const ActivationButton = styled.button`
  display: flex;
  background: none;
  border: none;
  color: #051730;
  justify-content: center;
  align-items: center;
`;

export const LearnerNameColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  span {
    color: ${({ theme }) => theme.colors['neutralMain']};
    font-weight: bold;
    font-size: 1rem;
  }
`;

export const Button = styled(AntdButton)`
  padding: 0;
`;

export const TableWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  min-width: 100%;
  .ant-table {
    overflow: auto;
  }
  .ant-table table {
    font-size: 1rem;
    font-weight: 400;
  }
`;

export const Tag = styled(AntdTag)`
  background-color: ${({ bgColor, theme }) => theme.colors[bgColor] ?? bgColor};
  color: ${({ fontColor, theme }) =>
    theme.colors[fontColor] || theme.colors['white']};
  border-radius: 24px;
  font-size: 1rem;
  font-weight: 700;
  padding: 4px 12px;
  width: fit-content;
`;

export const DBSCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.neutralMain};
    border-color: ${({ theme }) => theme.colors.neutralMain};
  }
`;

export const TableTitleWrapper = styled.div`
  min-height: 72px;
  display: flex;
  align-items: center;
`;

export const NameColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: ${({ previouslyAssigned }) =>
    previouslyAssigned ? '166px' : '100px'};

  span {
    color: ${({ theme }) => theme.colors['neutralMain']};
    font-weight: bold;
    font-size: 1rem;
  }
`;

export const StatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TabsWrapper = styled.div`
  .ant-tabs-ink-bar {
    background: ${({ theme }) => theme.colors.primaryMain};
    height: 3px !important;
  }
  .ant-tabs-tab {
    margin: 0 !important;
    min-width: 133px;
    justify-content: center;
    border-bottom: 3px solid #e8e8e8;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    left: ${({ activeTab }) => (activeTab - 1) * 133}px !important;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-table {
    border: 1px solid ${({ theme }) => theme.colors.neutral50};
    overflow: auto;
  }
`;

export const EmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings[3]};
  min-height: 300px;
`;

export const EmptyText = styled.div`
  max-width: 504px;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  flex-direction: column;
`;

export const TabsTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  .ant-table table {
    font-size: 1rem;
    font-weight: 400;
  }

  .ant-tabs-ink-bar {
    background: ${({ theme }) => theme.colors.primaryMain};
    height: 3px !important;
  }
  .ant-tabs-tab {
    margin: 0 !important;
    min-width: 150px;
    justify-content: center;
    border-bottom: 3px solid #e8e8e8;
    padding: 16px 16px !important;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-table {
    border: 1px solid ${({ theme }) => theme.colors.neutral50};
    overflow: auto;
  }
`;

export const CardWrapper = styled('div')`
  width: 100%;
  padding: ${({ theme }) => theme.spacings[6]};
  border: 1px solid ${({ theme }) => theme.colors.neutral50};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.secondaryLight};
  margin-bottom: ${({ theme }) => theme.spacings[4]};
  textarea {
    background-color: transparent;
  }
`;

export const TableNotes = styled.p`
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 315px;
`;
