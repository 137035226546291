import { useEffect, useReducer, useRef, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Typography, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';

import * as T from '../../components/Typography';
import { Button, Loading, Grid, Inputs } from './../../components';
import { OldDropdown as Dropdown } from './../../components/Inputs/Dropdown';
import * as S from './style';
import { formTypes, mediaTypes, navRoutes } from './../../constants';
import { getMediaUrl } from './../../helpers';
import {
  useGetMediaCmsById,
  useGetMediaTags,
} from '../../api-calls/media.queries';
import { Media } from '../../api-calls';
import { useUpdateStepMedia } from 'CMS/hooks/useUpdateStepMedia';
import { getMediaType } from 'CMS/utils';
import { useUpdateExerciseMedia } from 'CMS/hooks/useUpdateExerciseMedia';

const { BasicInput } = Inputs;
const { Col, Row } = Grid;

const initState = {
  id: null,
  type: mediaTypes.IMAGE,
  description: '',
  tags: [],
  imageFileList: [],
  femaleVoiceFileList: [],
  maleVoiceFileList: [],
  imageKey: '',
  femaleVoiceKey: '',
  maleVoiceKey: '',
  validationErrs: {},
  loading: false,
  httpError: '',
  keyToCopy: '',
  uploadError: '',
  cleanKey: '',
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const getFiles = (key, type, femaleVoice, maleVoice) => {
  if (type === mediaTypes.IMAGE) {
    return {
      imageFileList: [
        {
          name: key,
          url: `${getMediaUrl(key, false)}?random=${Date().toString()}`,
        },
      ],
    };
  } else {
    return {
      femaleVoiceFileList: femaleVoice
        ? [
            {
              name: 'Female Voice',
              url: getMediaUrl(key, true, 'FEMALE'),
            },
          ]
        : [],
      maleVoiceFileList: maleVoice
        ? [
            {
              name: 'Male Voice',
              url: getMediaUrl(key, true, 'MALE'),
            },
          ]
        : [],
    };
  }
};
const getOldKeys = (key, type, femaleVoice, maleVoice) => {
  if (type === mediaTypes.IMAGE) {
    return {
      imageKey: `/IMAGE${key}`,
    };
  } else {
    return {
      femaleVoiceKey: `/AUDIO/FEMALE${key}`,
      maleVoiceKey: `/AUDIO/MALE${key}`,
    };
  }
};

const MediaUpdate = () => {
  const [state, setState] = useReducer(reducer, initState);
  const { tags, refetch } = useGetMediaTags();
  const [aspectRatio, setAspectRatio] = useState(1);
  const prevIdRef = useRef();
  const { updateStepMediaType } = useUpdateStepMedia();
  const { updateExerciseMediaType } = useUpdateExerciseMedia();

  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const mediaIdParam = searchParams.get('mediaId');
  const prevPath = searchParams.get('prevPath');
  const mediaType = searchParams.get('type');
  const formType = searchParams.get('form');
  const query = searchParams.get('search');

  const mediaId = id || mediaIdParam;
  const isNewSession = mediaId === 'new';
  const mediaKey = id === 'edit' && query.get('key');

  const {
    media,
    isLoading,
    isError,
    isSuccess,
    error,
    refetch: refetchMedia,
    isFetching,
  } = useGetMediaCmsById(
    {
      mediaKey,
      id: mediaId,
      isNewSession,
    },
    { enabled: !isNewSession }
  );

  useEffect(() => {
    if (!isNewSession && isSuccess) {
      setState({
        id: media.id,
        type: media.type,
        description: media.description,
        tags: media.tags.map((t) => t.id),
        keyToCopy: media.key,
        cleanKey: media.key,
        ...getFiles(media.key, media.type, media.femaleVoice, media.maleVoice),
        ...getOldKeys(
          media.key,
          media.type,
          media.femaleVoice,
          media.maleVoice
        ),
      });
    }
  }, [mediaId, isNewSession, isSuccess, media, state.type]);

  useEffect(() => {
    if (prevIdRef.current !== id) {
      refetch();
      if (isNewSession) {
        setState({ ...initState });
      }

      prevIdRef.current = id;
    }
  }, [id, isNewSession, refetch]);

  useEffect(() => {
    if (mediaType) {
      const _mediaType = getMediaType(mediaType);
      return setState({
        type: _mediaType,
      });
    }
  }, [mediaType]);

  const handleFileChange = ({ fileList: newFileList, target }) => {
    setState({ [target]: newFileList });
  };

  const handleUpload = async (f, voice) => {
    setState({
      uploadError: null,
      loading: true,
    });

    const { data: signedUrl, error } = await Media.getSignedUrl({
      fileName: f.file.name,
      fileType: f.file.type,
      fileSize: f.file.size,
      fileCategory: state.type,
      voice,
      key: state.cleanKey || undefined,
    });

    if (error) {
      setState({
        uploadError: error.message,
        loading: false,
      });
      f.onError(error.message);
      return;
    }

    const { error: s3Error } = await Media.uploadFileToS3({
      signedUrl: signedUrl.url,
      file: f.file,
    });

    if (s3Error) {
      setState({
        uploadError: s3Error.message,
        loading: false,
      });
      f.onError(s3Error.message);
      return;
    }

    let target;
    if (!voice) target = 'imageKey';
    if (voice === 'MALE') target = 'maleVoiceKey';
    if (voice === 'FEMALE') target = 'femaleVoiceKey';

    setState({
      [target]: `/${signedUrl.key}`,
      cleanKey: signedUrl.cleanKey,
      loading: false,
    });
    f.onSuccess();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setState({ loading: true, httpError: '' });
    const keysToDelete = [];
    if (media?.key) {
      const oldKeys = getOldKeys(
        media.key,
        media.type,
        media.femaleVoice,
        media.maleVoice
      );
      if (
        oldKeys.imageKey &&
        (!state.imageFileList?.length || state?.imageKey?.includes('temp'))
      )
        keysToDelete.push(oldKeys.imageKey);
      if (
        oldKeys.femaleVoiceKey &&
        (!state.femaleVoiceFileList?.length ||
          state?.femaleVoiceKey?.includes('temp'))
      )
        keysToDelete.push(oldKeys.femaleVoiceKey);
      if (
        oldKeys.maleVoiceKey &&
        (!state.maleVoiceFileList?.length ||
          state?.maleVoiceKey?.includes('temp'))
      )
        keysToDelete.push(oldKeys.maleVoiceKey);
    }
    const { error, data } = await Media.createOrUpdateMedia({
      data: {
        isUpdate: !isNewSession,
        id: state.id,
        type: state.type,
        description: state.description,
        tags: state.tags,
        keys: [
          state.imageFileList?.length ? state.imageKey : null,
          state.femaleVoiceFileList?.length ? state.femaleVoiceKey : null,
          state.maleVoiceFileList?.length ? state.maleVoiceKey : null,
        ].filter((e) => !!e),
        keysToDelete,
      },
    });

    if (error) {
      setState({
        httpError: error.message,
        loading: false,
      });
      return;
    } else {
      setState({ loading: false, keyToCopy: data.key });
    }

    if (isNewSession) {
      if (prevPath) {
        return formType === formTypes.EXERCISE
          ? updateExerciseMediaType({ mediaKey: data.key })
          : updateStepMediaType({ mediaKey: data.key });
      }

      if (id) {
        navigate(navRoutes.CMS.MEDIA_UPDATE.replace(':id', data.id));
      } else {
        searchParams.set('mediaId', data.id);
        setSearchParams(searchParams, { replace: true });
      }
    } else {
      refetchMedia();
    }
  };

  const onPreview = async (file) => {
    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);

        reader.onload = () => resolve(reader.result);
      });
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const calculateImageAspect = (file) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const ratio = img.width / img.height;
        setAspectRatio(ratio);
        resolve(true);
      };
      img.src = URL.createObjectURL(file);
    });
  };

  if (isLoading || isFetching) {
    return <Loading />;
  }

  if (isError) {
    return (
      <>
        <div>something went wrong</div>
        <div>{error.message}</div>
      </>
    );
  }

  const buttonsDisabled =
    state.type === mediaTypes.IMAGE
      ? !state.imageFileList[0] || !state.description
      : (!state.maleVoiceFileList[0] && !state.femaleVoiceFileList[0]) ||
        !state.description;

  return (
    <S.Wrapper>
      <form
        onSubmit={handleSubmit}
        name="update media"
        style={{ width: '100%', maxWidth: 1200, margin: '0px auto' }}
      >
        <Row mt="6">
          <Col w={[4, 6, 6]}>
            <T.H1 mb="6">{isNewSession ? 'Create file' : 'Upload file'}</T.H1>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 4, 4]}>
            <Dropdown
              label="Select media type"
              options={[
                { label: mediaTypes.AUDIO, value: mediaTypes.AUDIO },
                { label: mediaTypes.IMAGE, value: mediaTypes.IMAGE },
              ]}
              selected={state.type}
              handleChange={(v) =>
                setState({
                  type: v,
                  imageFileList: [],
                  femaleVoiceFileList: [],
                  maleVoiceFileList: [],
                })
              }
              error={state.validationErrs.type}
              allowClear={false}
              disabled={!isNewSession}
            />
          </Col>
          <Col w={[4, 8, 8]}>
            <Dropdown
              label="Select media tags"
              search
              addNew
              optionFilterProp="label"
              multi
              options={tags?.map((t) => ({ label: t.tag, value: t.id }))}
              selected={state.tags}
              handleChange={(v) =>
                setState({
                  tags: v.map((newTag) => {
                    const index = tags.findIndex((t) => t.tag === newTag);

                    if (index !== -1) {
                      return tags[index].id;
                    }
                    return newTag;
                  }),
                })
              }
              error={state.validationErrs.tags}
            />
          </Col>
        </Row>
        <Row mt="5" style={{ position: 'relative' }}>
          <Col w={[4, 12, 12]}>
            <BasicInput
              label="Media description"
              placeholder="add a description"
              value={state.description}
              name="description"
              handleChange={(v) => setState({ description: v })}
              suffix={
                !!state.description && (
                  <Typography.Paragraph
                    copyable={{ text: state.description }}
                  />
                )
              }
              error={state.validationErrs.description}
            />
          </Col>
        </Row>
        {state.keyToCopy && (
          <Row mt="5" style={{ position: 'relative' }}>
            <Col w={[4, 12, 12]}>
              <BasicInput
                label="File Key"
                value={state.keyToCopy}
                name="key"
                readOnly
                suffix={
                  !!state.description && (
                    <Typography.Paragraph
                      copyable={{ text: state.keyToCopy }}
                    />
                  )
                }
                error={state.validationErrs.description}
              />
            </Col>
          </Row>
        )}
        {state.type === mediaTypes.IMAGE && (
          <ImgCrop
            rotationSlider
            showGrid
            aspectSlider
            aspect={aspectRatio}
            minZoom={0}
            cropperProps={{ restrictPosition: false }}
            beforeCrop={(file) => calculateImageAspect(file)}
          >
            <Upload
              customRequest={handleUpload}
              listType="picture-card"
              fileList={state.imageFileList}
              onChange={(e) =>
                handleFileChange({ ...e, target: 'imageFileList' })
              }
              onPreview={onPreview}
              className="upload-image-style"
              accept="image/*"
            >
              {state.imageFileList.length < 1 && '+ Upload'}
            </Upload>
          </ImgCrop>
        )}
        {state.type === mediaTypes.AUDIO && (
          <Row mt="6" jc={'center'} ai="end">
            <Col w={[4, 6, 6]}>
              Female Voice
              <Upload
                customRequest={(f) => handleUpload(f, 'FEMALE')}
                listType="picture"
                fileList={state.femaleVoiceFileList}
                onChange={(e) =>
                  handleFileChange({ ...e, target: 'femaleVoiceFileList' })
                }
                className="upload-audio-style"
                accept=".mp3"
                disabled={state.loading}
              >
                {state.femaleVoiceFileList.length < 1 &&
                  '+ Upload female voice'}
              </Upload>
            </Col>
            <Col w={[4, 6, 6]}>
              Male Voice
              <Upload
                customRequest={(f) => handleUpload(f, 'MALE')}
                listType="picture"
                fileList={state.maleVoiceFileList}
                onChange={(e) =>
                  handleFileChange({ ...e, target: 'maleVoiceFileList' })
                }
                className="upload-audio-style"
                accept=".mp3"
                disabled={state.loading}
              >
                {state.maleVoiceFileList.length < 1 && '+ Upload male voice'}
              </Upload>
            </Col>
          </Row>
        )}

        <Row mt="6" jc={'center'}>
          <Col w={[4, 6, 6]}>
            {(state.httpError || state.uploadError) && (
              <T.P color="error">{state.httpError || state.uploadError}</T.P>
            )}
            {state.keyToCopy ? (
              <>
                <Button.BasicButton
                  variant="primary"
                  disabled={buttonsDisabled}
                  loading={state.loading}
                  type="submit"
                  id="login-button"
                  mb={4}
                >
                  Update
                </Button.BasicButton>

                <Button.BasicButton
                  variant="secondary"
                  disabled={buttonsDisabled}
                  loading={state.loading}
                  type="button"
                  handleClick={() => {
                    setState(initState);
                    navigate(navRoutes.CMS.MEDIA_UPDATE.replace(':id', 'new'));
                  }}
                  id="login-button"
                >
                  Upload new
                </Button.BasicButton>
              </>
            ) : (
              <Button.BasicButton
                variant="primary"
                disabled={buttonsDisabled}
                loading={state.loading}
                type="submit"
                id="login-button"
              >
                Submit
              </Button.BasicButton>
            )}
          </Col>
        </Row>
      </form>
    </S.Wrapper>
  );
};

export default MediaUpdate;
