import { useFetch, formatQueryError } from '../utils/queries';

const useGetStats = (_, options = {}) => {
  const context = useFetch({
    key: [`GET_STATS`],
    url: `/stats`,
    reactQueryConfig: options,
  });
  return { ...context, stats: context.data, error: formatQueryError(context) };
};

export { useGetStats };
