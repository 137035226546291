import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 0 48px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  max-width: 600px;
  width: 100%;
  gap: 16px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  text-align: center;
  max-width: 600px;
  width: 100%;
  margin-top: 32px;
`;
export const ErrorWrapper = styled.div`
  display: flex;
  align-self: center;
  max-width: 600px;
  width: 100%;
`;
