import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';

import * as S from './style';
import assessmentNewToReadingPank from '../../../components/assets/assessmentNewToReadingPank.png';
import assessmentKnowSomeWords from '../../../components/assets/assessmentKnowSomeWords.png';
import Tip from '../../../components/Tip';
import { navRoutes } from '../../../constants';
import { useParams } from 'react-router-dom';
const SelectStartingPoint = () => {
  const { learnerId } = useParams();

  return (
    <>
      <S.Wrapper>
        <Row mb={1}>
          <Col w={[4, 10, 9]}>
            <T.H2 color="neutralMain" weight="bold">
              You’re now ready to start 🎉
            </T.H2>
            <T.P color="neutralMain" mt="3">
              At what point would you like to begin learning?
            </T.P>
          </Col>
        </Row>

        <Row>
          <Col w={[4, 10, 9]} mt="4" jc="center">
            <S.ImageButtonWrapper
              image={assessmentNewToReadingPank}
              to={navRoutes.LEARNER.DASHBOARD}
            >
              <T.P color="white" weight="bold" ta="center">
                Start at the beginning
              </T.P>
            </S.ImageButtonWrapper>
            <S.ImageButtonWrapper
              image={assessmentKnowSomeWords}
              to={
                navRoutes.LEARNER.SELECT_MILESTONE.replace(
                  ':learnerId',
                  learnerId
                ) + '?afterOnboarding=true'
              }
            >
              <T.P color="white" weight="bold" ta="center">
                Check learning level to see which milestone to start on
              </T.P>
            </S.ImageButtonWrapper>
            <Tip
              ShowBoldTipPrefix
              text="95% of learners start at the beginning and we recommend this generally"
              mt={5}
            />
          </Col>
        </Row>
      </S.Wrapper>
    </>
  );
};

export default SelectStartingPoint;
