import { useAuth } from 'context/auth';
import { userRoles } from 'constants';
import { Button, Grid, Typography as T } from 'components';
import { getMediaUrl } from 'helpers';
import { useGeneralState } from 'context/general-state';
import { audio } from 'constants';
import * as S from './style';

const { Col } = Grid;

const StatsChartLayout = ({ variant, children, ...props }) => {
  const { user } = useAuth();
  const isLearner = user.role === userRoles.LEARNER;
  const { state: generalState } = useGeneralState();

  if (variant === 'left') {
    return (
      <S.WrapperGrid {...props}>
        <S.BoxLeft mr="4">{children?.[0]}</S.BoxLeft>

        <S.BoxLeft mr="4">
          <S.Card>{children?.[1]}</S.Card>
          <S.Card className="second">{children?.[2]}</S.Card>
        </S.BoxLeft>

        <S.BoxLeft>
          <S.Card>{children?.[3]}</S.Card>
          <S.Card className="second">{children?.[4]}</S.Card>
        </S.BoxLeft>
      </S.WrapperGrid>
    );
  }

  if (variant === 'center') {
    return (
      <S.WrapperGrid {...props}>
        <S.BoxLeft mr="1">
          <S.Card>{children?.[0]}</S.Card>
          <S.Card className="second">{children?.[1]}</S.Card>
        </S.BoxLeft>

        <S.BoxLeft className="middle" mr="1">
          {children?.[2]}
        </S.BoxLeft>

        <S.BoxLeft>
          <S.Card>{children?.[3]}</S.Card>
          <S.Card className="second">{children?.[4]}</S.Card>
        </S.BoxLeft>
      </S.WrapperGrid>
    );
  }

  return (
    <>
      {isLearner && (
        <S.PlayButtonWrapper mt="7" mb="5">
          <Button.PlayButton
            width="56px"
            height="56px"
            audioUrl={getMediaUrl(
              audio.statistics,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            fallbackText="Statistics"
          />

          <Col w={[3, 7, 7]} ml="2">
            <T.H2>Statistics</T.H2>
          </Col>
        </S.PlayButtonWrapper>
      )}
      <S.Wrapper variant={variant} {...props}>
        {children?.map((child, index) => (
          <S.DefaultCard key={index}>{child}</S.DefaultCard>
        ))}
      </S.Wrapper>
    </>
  );
};

export default StatsChartLayout;
