import { BasicButton } from 'components/Button';
import { Col, Row } from 'components/Grid';
import Modal from 'components/Modal';
import * as T from 'components/Typography';
import WithAudio from './WithAudio';
import Success from './Success';
import * as S from './style';
import Icon from 'components/Icon';

export default function ConfirmationSuccessModal({
  visible,
  setIsModalVisible,
  title,
  titleAudioKey,
  description,
  descriptionAudioKey,
  onConfirm,
  confirmText = 'Confirm',
  confirmTextAudioKey,
  cancelText,
  cancelTextAudioKey,
  onConfirmLoading,
  onConfirmDisabled,
  onCancel,
  httpError,
  variant = 'default',
  maxWidth,
  ConfirmBtn,
  addCloseButton,
}) {
  if (variant === 'withAudio') {
    return (
      <WithAudio
        visible={visible}
        setIsModalVisible={setIsModalVisible}
        title={title}
        titleAudioKey={titleAudioKey}
        description={description}
        descriptionAudioKey={descriptionAudioKey}
        onConfirm={onConfirm}
        onConfirmLoading={onConfirmLoading}
        onConfirmDisabled={onConfirmDisabled}
        confirmText={confirmText}
        confirmTextAudioKey={confirmTextAudioKey}
        cancelText={cancelText}
        cancelTextAudioKey={cancelTextAudioKey}
        onCancel={onCancel}
        maxWidth={maxWidth}
        httpError={httpError}
      />
    );
  }

  if (variant === 'success') {
    return (
      <Success
        visible={visible}
        setIsModalVisible={setIsModalVisible}
        maxWidth={maxWidth}
        title={title}
        titleAudioKey={titleAudioKey}
        cancelTextAudioKey={cancelTextAudioKey}
        cancelText={cancelText}
        onCancel={onCancel}
      />
    );
  }

  return (
    <Modal visible={visible} setIsModalVisible={setIsModalVisible} padding="0">
      <S.DefaultModalWrapper>
        {addCloseButton && (
          <S.CloseButton
            handleClick={() => setIsModalVisible(false)}
            aria-label="Close video"
            noPadding
          >
            <Icon icon="close" color="white" />
          </S.CloseButton>
        )}
        <Row>
          <Col w={[4, 12, 12]}>
            <T.P size="med" weight="bold" color="white">
              {title}
            </T.P>
            {description && (
              <T.P size="regular" color="white" mt={4} mb={4}>
                {description}
              </T.P>
            )}
            {ConfirmBtn && <ConfirmBtn />}
            {onConfirm && (
              <BasicButton
                variant="secondary"
                onClick={onConfirm}
                mt={4}
                loading={onConfirmLoading}
                disabled={onConfirmDisabled}
              >
                {confirmText}
              </BasicButton>
            )}
            {cancelText && (
              <BasicButton
                variant="tertiary"
                onClick={onCancel}
                mt={2}
                loading={onConfirmLoading}
                disabled={onConfirmDisabled}
              >
                {cancelText}
              </BasicButton>
            )}
            {httpError && (
              <T.P size="small" color="#ff8181" mt={4}>
                {httpError}
              </T.P>
            )}
          </Col>
        </Row>
      </S.DefaultModalWrapper>
    </Modal>
  );
}
