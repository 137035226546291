import styled from '@emotion/styled';
import { setMargin } from 'helpers';

export const Wrapper = styled.div`
  width: 100%;
`;

export const QuickAccessWrapper = styled.div`
  width: 100%;
  margin-block: 32px;
  padding: ${({ theme }) => theme.spacings[5]};
  background-color: ${({ theme }) => theme.colors.neutralSurface};
  border-radius: 12px;
`;

export const HeaderWrapper = styled.div`
  ${setMargin}
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: stretch;
  }
`;
