import * as Yup from 'yup';
import moment from 'moment';
import { fields, createSchema, validate as _validate } from '..';
import { URLregex } from '../regex';
import * as errMsgs from '../err-msgs';

const { postcode } = fields;

const coachSessionSchema = createSchema({
  learner: Yup.string().required('Learner is required'),
  date: Yup.date()
    .min(moment().startOf('d'), 'Date must be in the future')
    .typeError('Date is required')
    .required('Date is required'),
  timeFrom: Yup.string()
    .required('Start time is required')
    .test(
      'is-before',
      'Start time must be earlier than End time',
      function (value) {
        if (!value) return true;
        const { timeTo } = this.parent;
        return moment(value, 'HH:mm').isBefore(moment(timeTo, 'HH:mm'));
      }
    )
    .test(
      'is-before-now',
      'Start time must be in the future',
      function (value) {
        if (!value) return true;
        const { date } = this.parent;

        const combinedDateTime = moment(date).set({
          hour: moment(value, 'HH:mm').hour(),
          minute: moment(value, 'HH:mm').minute(),
        });

        return combinedDateTime.isAfter(moment());
      }
    ),
  timeTo: Yup.string().required('End time is required'),
  type: Yup.string().required('Session type is required'),
  link: Yup.string().when('type', {
    is: 'in-person',
    then: Yup.string().notRequired(),
    otherwise: Yup.string()
      .matches(URLregex, {
        message: errMsgs.INVALID_LINK,
      })
      .required('Meeting link is required'),
  }),
  addressLine1: Yup.string().when('type', {
    is: 'in-person',
    then: Yup.string().required('Address is required'),
    otherwise: Yup.string().notRequired(),
  }),
  addressLine2: Yup.string(),
  town: Yup.string().when('type', {
    is: 'in-person',
    then: Yup.string().required('Town is required'),
    otherwise: Yup.string().notRequired(),
  }),
  postcode: Yup.string().when('type', {
    is: 'in-person',
    then: postcode,
    otherwise: Yup.string().notRequired(),
  }),
  additionalInfo: Yup.string(),
  confirmed: Yup.boolean().oneOf([true], 'You must confirm understanding'),
});

export const validate = (data) => {
  return _validate(coachSessionSchema, data);
};

export default validate;
