import { Progress } from 'antd';
import { format, parseISO } from 'date-fns';
import * as S from './style';
import Icon from '../Icon';
import theme from '../../theme';
import * as T from '../Typography';
import { navRoutes as R, userRoles } from '../../constants';
import { printSingleOrPluralStr } from '../../helpers';

export const ExercisesCompleted = ({ percent = 0, size = 0 }) => (
  <>
    <Progress
      type="circle"
      strokeColor={theme.colors.primaryMain}
      strokeWidth={10}
      percent={percent}
      width={94}
      format={(perc) => (perc === 100 ? '100%' : `${perc}%`)}
    />
    <S.ProgressText color="neutralMain" mt="4" weight="bold">
      {size}&nbsp;
    </S.ProgressText>
    <S.ProgressText color="neutralMain" mt="2">
      {printSingleOrPluralStr(size, 'exercise')} completed
    </S.ProgressText>
  </>
);

export const LearningTime = ({ days = 0, hours = 0, months = 0 }) => {
  return (
    <>
      <Icon icon="learningAndCoachingTime" width="35" height="35" mb="1" />
      <S.ProgressText color="neutralMain" mt="4" weight="bold">
        {hours >= 0 ? (
          <>
            {hours} {printSingleOrPluralStr(hours, 'hour')}
            <br />
          </>
        ) : null}
        {days >= 1 ? (
          <>
            {days} {printSingleOrPluralStr(days, 'day')}
            <br />
          </>
        ) : null}
        {months >= 1 ? (
          <>
            {months} {printSingleOrPluralStr(months, 'month')}
          </>
        ) : null}
      </S.ProgressText>
      <S.ProgressText color="neutralMain" mt="2">
        learning time
      </S.ProgressText>
    </>
  );
};

export const CoachingOnTheApp = ({ value }) => (
  <>
    <Icon icon="learningAndCoachingTime" width="35" height="35" mb="1" />
    <S.ProgressText color="neutralMain" mt="4" weight="bold">
      {value}
    </S.ProgressText>
    <S.ProgressText color="neutralMain" mt="2">
      coaching / on the app
    </S.ProgressText>
  </>
);

export const NoOfLearners = ({ learners }) => (
  <>
    <Icon icon="byYourSelf" width="35" height="35" mb="1" />
    <S.ProgressText color="neutralMain" mt="4" weight="bold">
      {learners}
    </S.ProgressText>
    <S.ProgressText color="neutralMain" mt="2">
      {printSingleOrPluralStr(learners, 'learner')}
    </S.ProgressText>
  </>
);

export const NewWords = ({ words = 0 }) => (
  <>
    <Icon icon="book" width="28" height="34" mb="1" color="neutralSurface" />
    <S.ProgressText color="neutralSurface" mt="4" weight="bold">
      {words}
    </S.ProgressText>
    <S.ProgressText color="neutralSurface" mt="2">
      new {printSingleOrPluralStr(words, 'word')} learned
    </S.ProgressText>
  </>
);

export const MilestoneReached = ({
  milestonesNum = 0,
  learnerId,
  userRole,
  canEdit,
}) => (
  <>
    <Icon
      icon="milestone"
      width="28"
      height="34"
      mb="1"
      color="neutralSurface"
    />
    <S.ProgressText color="neutralSurface" mt="4" weight="bold">
      {milestonesNum}
    </S.ProgressText>
    <S.ProgressText color="neutralSurface" mt="1">
      {printSingleOrPluralStr(milestonesNum, 'milestone')} reached
    </S.ProgressText>
    {userRole !== userRoles.LEARNER && (
      <S.StyledLink
        to={R.COMMON.LEARNER_REACHED_MILESTONES.replace(
          ':learnerId',
          learnerId
        )}
        mt="3"
      >
        <Icon icon="eye" color="neutralSurface" mr="1" />
        <T.H3 color="neutralSurface" weight="600" mt="1">
          View / Edit
        </T.H3>
      </S.StyledLink>
    )}
  </>
);

export const DateOfLastTaskCompletion = ({ date = '' }) => (
  <>
    <Icon icon="check" width="28" height="34" mb="1" color="neutralSurface" />
    <S.ProgressText color="neutralSurface" mt="4" weight="bold">
      {date ? format(parseISO(date), 'dd.MM.yy') : '00.00.00'}
    </S.ProgressText>
    <S.ProgressText color="neutralSurface" mt="2">
      date of last task completion
    </S.ProgressText>
  </>
);

export const DateOfLastSessionWithCoach = ({ date = '', text }) => (
  <>
    <Icon
      icon="calendar"
      width="28"
      height="34"
      mb="1"
      color="neutralSurface"
    />
    <S.ProgressText color="neutralSurface" mt="4" weight="bold">
      {date ? format(parseISO(date), 'dd.MM.yy') : '00.00.00'}
    </S.ProgressText>
    <S.ProgressText color="neutralSurface" mt="2">
      {text || 'date of last session with coach'}
    </S.ProgressText>
  </>
);
