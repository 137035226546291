import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';

export const Wrapper = styled.div`
  ${setMargin};
  display: flex;
  width: 100%;

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const WrapperGrid = styled.div`
  ${setMargin};
  display: flex;
  width: 100%;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const BoxLeft = styled.div`
  ${setMargin};
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.middle {
    flex: 1.5;
  }

  @media (max-width: 700px) {
    margin-right: 0 !important;
    &.middle {
      margin-top: ${({ theme }) => theme.spacings[1]} !important;
    }
  }
`;

export const Card = styled.div`
  width: 100%;
  &.second {
    margin-top: ${({ theme }) => theme.spacings[4]};
  }

  @media (max-width: 700px) {
    margin-top: ${({ theme }) => theme.spacings[1]};
  }
`;

export const DefaultCard = styled.div`
  width: 100%;
  height: 100%;
  margin-right: ${({ theme }) => theme.spacings[1]};
`;

export const PlayButtonWrapper = styled.div`
  ${setMargin};
  display: flex;
  align-items: center;
  width: 100%;
`;
