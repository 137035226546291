import { Col, Row } from 'components/Grid';
import Image from 'components/Image';
import * as T from 'components/Typography';
import React from 'react';
import { BasicButton } from 'components/Button';
import * as S from './style';

const Success = ({ isModalOpen, onClose }) => {
  return (
    <S.Modal open={isModalOpen} onClose={onClose} role="dialog" flex>
      <S.Wrapper>
        <Row jc={'center'} inner>
          <Col w={[4, 12, 12]} jc="center">
            <Image image="Congrats" width="300px" height={200} />
          </Col>
          <Col w={[4, 12, 12]} dir="column" jc="center" mt="5">
            <T.H1 weight="bold" color="neutralMain">
              Feedback submitted!
            </T.H1>
            <T.P color="neutralMain" mt="3" maxWidth="500px" ta="center">
              Your feedback is important to us. Thank you for taking the time to
              share your thoughts.
            </T.P>
          </Col>

          <Col w={[4, 12, 12]} mt="5" display="flex" jc="center">
            <BasicButton
              variant="primary"
              handleClick={onClose}
              maxWidth="300px"
            >
              Continue
            </BasicButton>
          </Col>
        </Row>
      </S.Wrapper>
    </S.Modal>
  );
};

export default Success;
