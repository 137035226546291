import { PlayButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import * as S from './style';
import { useGeneralState } from '../../../context/general-state';
import { audio } from '../../../constants';
import { getMediaUrl } from '../../../helpers';

import IconWithBackgroundWithText from '../../../components/IconWithBackgroundWithText';
const IconsPage = () => {
  const { state: generalState } = useGeneralState();
  return (
    <>
      <S.Wrapper>
        <Row ai="center" mb="4">
          <PlayButton
            width="56px"
            height="56px"
            mr="3"
            audioUrl={getMediaUrl(
              audio.clickOnTheIconsBelowToListenToWhatTheyMean,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            fallbackText="Click on the icons below to listen to what they mean"
          />
          <Col w={[3, 9, 9]}>
            <T.H2 color="neutralMain">
              Click on the icons below to listen to what they mean
            </T.H2>
          </Col>
        </Row>
        <Row mt="4" ai="center">
          <PlayButton
            width="56px"
            height="56px"
            mr="3"
            audioUrl={getMediaUrl(
              audio.next,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            fallbackText="Next"
          />
          <Col w={[3, 4, 4]}>
            <IconWithBackgroundWithText icon="next" text="Next" />
          </Col>
        </Row>

        <Row mt="2" ai="center">
          <PlayButton
            width="56px"
            height="56px"
            mr="3"
            audioUrl={getMediaUrl(
              audio.back,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            fallbackText="Back"
          />
          <Col w={[3, 9, 9]} mt="2">
            <IconWithBackgroundWithText icon="back" text="Back" />
          </Col>
        </Row>
        <Row mt="2" ai="center">
          <PlayButton
            width="56px"
            height="56px"
            mr="3"
            audioUrl={getMediaUrl(
              audio.complete,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            fallbackText="Complete"
          />
          <Col w={[3, 9, 9]} mt="2">
            <IconWithBackgroundWithText
              icon="tick"
              text="Complete"
              iconProps={{ width: '16px', height: '16px' }}
            />
          </Col>
        </Row>
        <Row mt="2" ai="center">
          <PlayButton
            width="56px"
            height="56px"
            mr="3"
            audioUrl={getMediaUrl(
              audio.saveParkingPageBookmark,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            fallbackText="Save parking page (bookmark)"
          />
          <Col w={[3, 9, 9]} mt="2">
            <IconWithBackgroundWithText
              icon="questionMark"
              text="Save parking page (bookmark)"
              iconProps={{ width: '36px', height: '36px' }}
            />
          </Col>
        </Row>
        <Row mt="2" ai="center">
          <PlayButton
            width="56px"
            height="56px"
            mr="3"
            audioUrl={getMediaUrl(
              audio.parkingPageSaved,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            fallbackText="Parking page (bookmark) saved"
          />
          <Col w={[3, 9, 9]} mt="2">
            <IconWithBackgroundWithText
              icon="bookmarkSaved"
              text="Parking page (bookmark) saved"
              iconProps={{ width: '36px', height: '36px' }}
            />
          </Col>
        </Row>
        <Row mt="2" ai="center">
          <PlayButton
            width="56px"
            height="56px"
            mr="3"
            audioUrl={getMediaUrl(
              audio.addAColouredOverlay,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            fallbackText="Add coloured overlay"
          />
          <Col w={[3, 9, 9]} mt="2">
            <IconWithBackgroundWithText
              icon="profileEye"
              text="Add coloured overlay"
              iconProps={{ width: '32px', height: '32px' }}
            />
          </Col>
        </Row>
        <Row mt="2" ai="center">
          <PlayButton
            width="56px"
            height="56px"
            mr="3"
            audioUrl={getMediaUrl(
              audio.profile,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            fallbackText="Profile"
          />
          <Col w={[3, 9, 9]} mt="2">
            <IconWithBackgroundWithText icon="profile" text="Profile" />
          </Col>
        </Row>
        <Row mt="2" ai="center">
          <PlayButton
            width="56px"
            height="56px"
            mr="3"
            audioUrl={getMediaUrl(
              audio.speaker,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            fallbackText="Speaker"
          />
          <Col w={[3, 9, 9]} mt="2">
            <IconWithBackgroundWithText icon="speaker" text="Speaker" />
          </Col>
        </Row>
        <Row mt="2" ai="center">
          <PlayButton
            width="56px"
            height="56px"
            mr="3"
            audioUrl={getMediaUrl(
              audio.exerciseLocked,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            fallbackText="Exercise locked"
          />
          <Col w={[3, 9, 9]} mt="2">
            <IconWithBackgroundWithText icon="lock" text="Exercise locked" />
          </Col>
        </Row>
        <Row mt="2" ai="center">
          <PlayButton
            width="56px"
            height="56px"
            mr="3"
            audioUrl={getMediaUrl(
              audio.byYourself,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            fallbackText="By yourself"
          />
          <Col w={[3, 9, 9]} mt="2">
            <IconWithBackgroundWithText
              icon="byYourSelf"
              text="By yourself"
              iconProps={{ width: '48px', height: '48px' }}
            />
          </Col>
        </Row>

        <Row mt="2" ai="center">
          <PlayButton
            width="56px"
            height="56px"
            mr="3"
            audioUrl={getMediaUrl(
              audio.withYourCoach,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            fallbackText="With your coach"
          />
          <Col w={[3, 9, 9]} mt="2">
            <IconWithBackgroundWithText
              icon="withCoach"
              text="With your coach"
              iconProps={{ width: '48px', height: '48px' }}
            />
          </Col>
        </Row>

        <Row mt="2" ai="center">
          <PlayButton
            width="56px"
            height="56px"
            mr="3"
            audioUrl={getMediaUrl(
              audio.coachNotes,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            fallbackText="Coach notes"
          />
          <Col w={[3, 9, 9]} mt="2">
            <IconWithBackgroundWithText icon="note" text="Coach notes" />
          </Col>
        </Row>

        <Row mt="2" ai="center">
          <PlayButton
            width="56px"
            height="56px"
            mr="3"
            audioUrl={getMediaUrl(
              audio.success,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            fallbackText="Success"
          />
          <Col w={[3, 9, 9]} mt="2">
            <IconWithBackgroundWithText icon="like" text="Success" />
          </Col>
        </Row>

        <Row mt="2" ai="center">
          <PlayButton
            width="56px"
            height="56px"
            mr="3"
            audioUrl={getMediaUrl(
              audio.congratsExerciseCompleted,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            fallbackText="Exercise completed"
          />
          <Col w={[3, 9, 9]} mt="2">
            <IconWithBackgroundWithText icon="cup" text="Exercise complete" />
          </Col>
        </Row>

        <Row mt="2" ai="center">
          <PlayButton
            width="56px"
            height="56px"
            mr="3"
            audioUrl={getMediaUrl(
              audio.tryAgain,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            fallbackText="Try again"
          />
          <Col w={[3, 9, 9]} mt="2">
            <IconWithBackgroundWithText icon="tryAgain" text="Try again" />
          </Col>
        </Row>

        <Row mt="2" ai="center">
          <PlayButton
            width="56px"
            height="56px"
            mr="3"
            audioUrl={getMediaUrl(
              audio.milestone,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            fallbackText="Milestone"
          />
          <Col w={[3, 9, 9]} mt="2">
            <IconWithBackgroundWithText
              icon="milestone"
              text="Milestone"
              iconProps={{ color: 'neutralMain' }}
            />
          </Col>
        </Row>

        <Row mt="2" ai="center">
          <PlayButton
            width="56px"
            height="56px"
            mr="3"
            audioUrl={getMediaUrl(
              audio.book,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
            fallbackText="Book"
          />
          <Col w={[3, 9, 9]} mt="2">
            <IconWithBackgroundWithText
              icon="book"
              text="Book"
              iconProps={{ color: 'neutralMain' }}
            />
          </Col>
        </Row>
      </S.Wrapper>
    </>
  );
};

export default IconsPage;
