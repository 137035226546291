import { fillInTheGridVariations } from '../../../../../constants';

export const generateInitialCorrectOptions = (
  variation,
  existingCorrectOptions = []
) => {
  let correctOptions = existingCorrectOptions;

  switch (variation) {
    case fillInTheGridVariations.variations.GRID_NO_VALUES:
      if (existingCorrectOptions.length === 0) {
        correctOptions = [{ id: 0, option: '' }];
      } else {
        correctOptions = [existingCorrectOptions[0]];
      }
      break;
    case fillInTheGridVariations.variations.GRID_WITH_VALUES:
      if (existingCorrectOptions.length === 0) {
        correctOptions = [{ id: 0, option: '' }];
      } else {
        correctOptions = existingCorrectOptions;
      }
      break;

    default:
      correctOptions = undefined;
      break;
  }

  return correctOptions;
};

export const generateInitialRows = (variation, existingRows = []) => {
  let rows = existingRows;

  switch (variation) {
    case fillInTheGridVariations.variations.ROWS:
    case fillInTheGridVariations.variations.THERMOMETER:
      if (existingRows.length === 0) {
        rows = [{ id: 0, text: '', isCorrect: false }];
      } else {
        rows = existingRows;
      }
      break;

    default:
      rows = undefined;
      break;
  }

  return rows;
};
