import moment from 'moment';
import * as T from 'components/Typography';
import * as S from './style';
import { navRoutes, userRoles } from '../../../constants';
import InlineCopy from '../../../components/Copy/InlineCopy';
import { Icon } from 'components';
import { facilitatorsStatuses } from '../../../constants';

const getFacilitatorsTableColumns = ({
  organisationId = undefined,
  makeFacilitatorAsCoach,
  onActivateAndDeactivate,
  hideNameColumn = false,
  showAdminFields,
  showActionsField,
  UpdateFacilitatorsOrganisationsOnClick,
}) => {
  const commonColumns = [
    {
      title: <T.P weight="bold">Name</T.P>,
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text, record) => {
        if (record.status === facilitatorsStatuses.DELETED)
          return (
            <T.P color="neutralMain" style={{ minWidth: '120px' }}>
              Deleted
            </T.P>
          );
        return (
          <T.P color="neutralMain" style={{ minWidth: '120px' }}>
            {text || 'N/A'}
          </T.P>
        );
      },
    },
    {
      title: <T.P weight="bold">Account Email</T.P>,
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => {
        if (record.status === facilitatorsStatuses.DELETED) {
          return 'Deleted';
        }
        if (
          record.inviteToken &&
          record.status === facilitatorsStatuses.DEACTIVATED
        ) {
          return "Hasn't signed up yet";
        }

        return (
          <S.CopyLinkWrapper>
            <T.P style={{ minWidth: '200px' }}>{text}</T.P>

            {record.inviteToken && record.active && (
              <InlineCopy
                textToCopy={navRoutes.FACILITATOR.SIGNUP.replace(
                  ':inviteToken',
                  record.inviteToken
                )}
                text="Invitation link"
                color="primaryMain"
              />
            )}
          </S.CopyLinkWrapper>
        );
      },
    },
    {
      title: <T.P weight="bold">Date added</T.P>,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, record) => {
        return moment(text).format('DD/MM/YYYY');
      },
    },
  ].filter((column) =>
    hideNameColumn && column.key === 'fullName' ? false : true
  );

  if (!showAdminFields) {
    commonColumns.push({
      title: <T.P weight="bold">Actions</T.P>,
      dataIndex: 'activations',
      key: 'activations',
      render: (text, record) =>
        record.status === facilitatorsStatuses.DELETED || !record.id ? (
          ''
        ) : (
          <S.ActionWrapper>
            <S.ActivationButton
              onClick={() => {
                onActivateAndDeactivate(
                  record,
                  record.status === facilitatorsStatuses.DEACTIVATED
                    ? facilitatorsStatuses.ACTIVATED
                    : facilitatorsStatuses.DEACTIVATED
                );
              }}
            >
              <Icon
                icon={
                  record?.status === facilitatorsStatuses.DEACTIVATED
                    ? 'toggleDeActive'
                    : 'toggleActive'
                }
                color={
                  record?.status === facilitatorsStatuses.DEACTIVATED
                    ? 'neutral50'
                    : 'primaryMain'
                }
                width="39"
                height="24"
                mr={2}
              />
              Active
            </S.ActivationButton>
            <S.ActivationButton
              onClick={async () => {
                const isCoachAndActive =
                  record?.organisationsRoles?.filter(
                    (o) =>
                      o.organisationId === organisationId &&
                      o.status === 'ACTIVATED' &&
                      o.role === userRoles.COACH
                  ).length >= 1;

                await makeFacilitatorAsCoach({
                  id: record.id,
                  setAsCoach: !isCoachAndActive,
                });
              }}
              disabled={record?.status === facilitatorsStatuses.DEACTIVATED}
            >
              <Icon
                icon={
                  record?.organisationsRoles?.filter(
                    (o) =>
                      o.organisationId === organisationId &&
                      o.status === 'ACTIVATED' &&
                      o.role === userRoles.COACH
                  ).length < 1
                    ? 'toggleDeActive'
                    : 'toggleActive'
                }
                color={
                  record?.organisationsRoles?.filter(
                    (o) =>
                      o.organisationId === organisationId &&
                      o.status === 'ACTIVATED' &&
                      o.role === userRoles.COACH
                  ).length < 1
                    ? 'neutral50'
                    : 'primaryMain'
                }
                width="39"
                height="24"
                mr={2}
              />
              Coach
            </S.ActivationButton>
          </S.ActionWrapper>
        ),
    });
    return commonColumns;
  }

  return [
    ...commonColumns.splice(0, 2),
    {
      title: <T.P weight="bold">Organisation(s)</T.P>,
      dataIndex: 'organisationsRoles',
      key: 'organisationsRoles',
      render: (organisationsRoles) => (
        <T.P>
          {organisationsRoles
            ?.map((o) => o.organisationName)
            ?.filter((v, i, a) => a.indexOf(v) === i)
            ?.join(',')}
        </T.P>
      ),
    },
    ...[
      ...commonColumns,
      showActionsField
        ? {
            title: <T.P weight="bold">Actions</T.P>,
            dataIndex: 'facilitatorsOrganisationsActions',
            key: 'facilitatorsOrganisationsActions',
            render: (text, record) =>
              record?.id ? (
                <S.ActivationButton
                  onClick={() =>
                    UpdateFacilitatorsOrganisationsOnClick(record.id)
                  }
                >
                  <S.IconWrapper>
                    <Icon icon="edit" color="primaryMain" />
                  </S.IconWrapper>
                  Manage organisations
                </S.ActivationButton>
              ) : (
                <></>
              ),
          }
        : {},
    ],
  ];
};

export default getFacilitatorsTableColumns;
