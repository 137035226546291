import { useParams, useNavigate } from 'react-router-dom';
import { Col, Row } from '../../../components/Grid';
import { BigButton, PlayButton } from '../../../components/Button';
import { LearningSession } from './../../../api-calls';
import { navRoutes, audio } from './../../../constants';
import * as T from './../../../components/Typography';
import { getMediaUrl } from '../../../helpers';
import { useGeneralState } from '../../../context/general-state';
import theme from 'theme';

const SelectWithCoachOrNot = () => {
  const navigate = useNavigate();
  const { exerciseId } = useParams();
  const { state: generalState, setState: setGeneralState } = useGeneralState();

  const {
    mutateAsync,
    error: httpError,
    isLoading,
    isError,
  } = LearningSession.useCreateLearningSession();

  const handleClick = async (withCoach) => {
    if (!generalState?.isPlayground) {
      await mutateAsync({ selfLearning: !withCoach });
    }
    setGeneralState({ withCoach });
    navigate(
      navRoutes.EXERCISES.EXERCISE_EXPLAINER.replace(':exerciseId', exerciseId)
    );
  };

  return (
    <Row jc="center">
      <Col w={[4, 9, 9]}>
        <PlayButton
          audioUrl={getMediaUrl(
            audio.areYouLearningAloneOrWithYourCoach,
            true,
            generalState?.preferredVoice
          )}
          iconProps={{ width: '32px', height: '32px' }}
          padding="12px"
          size="large"
          backgroundColor={theme.colors.neutralSurface}
          fallbackText="Are you learning alone or with your coach?"
        >
          Are you learning alone or with your coach?
        </PlayButton>
        <PlayButton
          audioUrl={getMediaUrl(
            audio.clickTheOptionsBelowToTellUsIfYouAreLearningOnYourOwn,
            true,
            generalState?.preferredVoice
          )}
          iconProps={{ width: '32px', height: '32px' }}
          padding="12px"
          backgroundColor={theme.colors.neutralSurface}
          mt="4"
          weight={'regular'}
          customColor={'neutral80'}
          size="small"
          fallbackText="click the option below to tell us if you are learning on your own today or with your coach"
        >
          click the option below to tell us if you are learning on your own
          today or with your coach
        </PlayButton>
      </Col>
      <Col w={[4, 9, 9]} mt="2">
        <BigButton
          variant={'byYourSelf'}
          onClick={() => handleClick(false)}
          mb={5}
          mt={5}
          loading={isLoading}
        />
        <BigButton
          variant={'withCoach'}
          onClick={() => handleClick(true)}
          loading={isLoading}
        />
        {isError && (
          <T.P color="error" mt={4}>
            {httpError.message}
          </T.P>
        )}
      </Col>
    </Row>
  );
};

export default SelectWithCoachOrNot;
