import { questionAndAnswerVariations } from 'constants';

export const generateInitialCorrectOptions = (variation) => {
  let correctOptions = [{ id: 0, option: '' }];
  if (
    variation === questionAndAnswerVariations.variations.FRACTION ||
    variation === questionAndAnswerVariations.variations.TWO_INPUTS_WITH_ENDINGS
  ) {
    correctOptions = [
      { id: 0, option: '' },
      { id: 1, option: '' },
    ];
  } else if (
    variation === questionAndAnswerVariations.variations.MULTIPLICATION
  ) {
    correctOptions = [
      { id: 0, option: '' },
      { id: 1, option: '' },
      { id: 2, option: '' },
    ];
  }
  return correctOptions;
};
