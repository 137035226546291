import { useEffect, useMemo, useRef, useState } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput, Dropdown } from '../../../../components/Inputs';
import * as S from './style';
import { Icon } from '../../../../components';
import { string, number, array, object, boolean } from 'yup';
import { MediaKeyInput } from '../../../components/MediaKeyInput';
import { mediaTypes } from '../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';

const initialData = {
  options: [{ id: 0, option: '' }],
  audioUrl: '',
  correctOptions: [{ id: 0, option: '' }],
};

const WordBuild = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateFormFields, updateDataFields } = useStepForm(
    initialRef.current
  );
  const { data, validationErrs } = state;
  const options = useMemo(
    () =>
      data?.options?.map((e, i) => ({ ...e, id: i })) || [
        { id: 0, option: '' },
      ],
    [data?.options]
  );
  const correctOptions = useMemo(
    () =>
      data?.correctOptions?.map((e, i) => ({ ...e, id: i })) || [
        { id: 0, option: '' },
      ],
    [data?.correctOptions]
  );
  const [wordsLength, setWordsLength] = useState(
    data?.correctOptions?.[0]?.option?.length || 3
  );

  useEffect(() => {
    updateFormFields({
      innerError: options.every((e) => e.option.length === wordsLength),
      disabled: correctOptions.find((e) => e.option.length !== wordsLength),
    });
  }, [correctOptions, options, updateFormFields, wordsLength]);

  const addOption = () => {
    const maxId = Math.max(...options.map((e) => e.id), 0);
    updateDataFields({
      options: [...options, { id: maxId + 1, option: '' }],
    });
  };
  const removeOption = (id) => {
    updateDataFields({
      options: options.filter((e) => e.id !== id),
    });
  };

  const addCorrectOptions = () => {
    const maxId = Math.max(...correctOptions.map((e) => e.id), 0);
    updateDataFields({
      correctOptions: [...correctOptions, { id: maxId + 1, option: '' }],
    });
  };
  const removeCorrectOptions = (id) => {
    updateDataFields({
      correctOptions: correctOptions.filter((e) => e.id !== id),
    });
  };
  const onOptionChange = (value, id) => {
    updateDataFields({
      options: options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  const onCorrectOptionsTextChange = (value, id) => {
    updateDataFields({
      correctOptions: correctOptions.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  const onCorrectOptionsAudioKeyChange = (value, id) => {
    updateDataFields({
      correctOptions: correctOptions.map((e) => {
        if (e.id !== id) return e;
        return { ...e, audioKey: value };
      }),
    });
  };

  return (
    <div>
      <T.P mt={8} size="large" weight="bold">
        Options
      </T.P>
      {validationErrs?.options &&
        typeof validationErrs.options === 'string' && (
          <T.P mb={2} mt={2} color="error">
            {validationErrs.options}
          </T.P>
        )}

      {options.map((e, i) => {
        return (
          <>
            <BasicInput
              label="Text"
              value={e.option}
              handleChange={(value) => {
                onOptionChange(value, e.id);
              }}
              m={{ mt: 5 }}
              error={
                validationErrs?.options && validationErrs?.options[i]?.option
              }
            />

            {options.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeOption(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}

      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addOption}
        disabled={options.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Option
      </S.Button>

      <T.P mt={8} size="large" weight="bold">
        Correct Answers
      </T.P>
      {validationErrs?.correctOptions &&
        typeof validationErrs.correctOptions === 'string' && (
          <T.P mb={2} mt={2} color="error">
            {validationErrs.correctOptions}
          </T.P>
        )}

      <Dropdown
        label="Select words length"
        options={[
          {
            value: 3,
            label: '3',
          },
          {
            value: 4,
            label: '4',
          },
          {
            value: 5,
            label: '5',
          },
          {
            value: 6,
            label: '6',
          },
        ]}
        selected={{ label: String(wordsLength), value: wordsLength }}
        m={{ mb: 2, mt: 5 }}
        helper={'All correct answers / words should have the same length'}
        handleChange={(wordsLength) => setWordsLength(wordsLength)}
      />

      {correctOptions.map((e, i) => {
        return (
          <>
            <BasicInput
              label={`Correct answer ${i + 1}`}
              value={e.option}
              handleChange={(value) => {
                onCorrectOptionsTextChange(value, e.id);
              }}
              m={{ mb: 2, mt: 8 }}
              error={
                validationErrs?.correctOptions?.[i]?.option ||
                (e.option.length !== wordsLength &&
                  `should be ${wordsLength} characters`)
              }
            />

            <MediaKeyInput
              index={i}
              type={mediaTypes.ANSWER_AUDIO}
              label={`Answer ${i + 1} audio file Key`}
              value={e.audioKey}
              handleChange={(value) => {
                onCorrectOptionsAudioKeyChange(value, e.id);
              }}
              m={{ mt: 5 }}
              error={validationErrs?.correctOptions?.[i]?.audioKey}
            />
            {correctOptions.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeCorrectOptions(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}

      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addCorrectOptions}
        disabled={correctOptions.find((e) => !e.option || !e.audioKey)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add answer
      </S.Button>
    </div>
  );
};

const validationSchema = {
  options: array()
    .of(
      object()
        .shape({
          id: number().notRequired(),
          option: string().required('required field'),
        })
        .required()
    )
    .required()
    .min(1),
  correctOptions: array()
    .of(
      object()
        .shape({
          id: number().required(),
          option: string()
            .min(3, 'should be 3 letters length')
            .required('required field'),

          audioKey: string().required('required field'),
        })
        .required()
    )
    .required()
    .min(1),
  innerError: boolean().oneOf([false]),
};
export { validationSchema };

export default WordBuild;
