import styled from '@emotion/styled';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { setMargin } from 'helpers';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ScrolledWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

export const Wrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.spacings[6]};
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 878px;
  margin-inline: auto;
  width: 668px;
  @media (max-width: 712px) {
    width: auto;
  }
  @media (max-width: 345px) {
    padding-right: 32px;
  }
`;
export const PlayButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 24px;
`;

export const RowWrapper = styled.div`
  ${setMargin}
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const SessionTextWrapper = styled.div`
  font-size: 16px;
  text-align: center;
  margin-top: 3px;
  margin-left: 6px;
`;
export const PlayCardWrapper = styled.div`
  align-items: center;
  margin-bottom: 16px;
  padding: 16px;
  background-color: #f0f1f5;
  border-radius: 16px;
  margin-top: 32px;
`;

export const PlayCardText = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 16px;
`;
export const NoDataWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

export const AddToCalendarWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const CustomAddToCalendarButton = styled(AddToCalendarButton)``;
