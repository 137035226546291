import styled from '@emotion/styled';

export const AlignmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  min-width: 100%;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  background: ${({ theme }) => theme.colors.neutralLightNew};
  box-shadow: ${({ theme }) => theme.shadows.elevation1};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: background-color 300ms linear, color 300ms linear,
    opacity 300ms linear;
  :active {
    opacity: 0.9;
    background-color: ${({ theme }) => theme.colors.neutralMain};
    transform: translateY(1px) scale(0.99);
  }
`;

export const IconStepContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  min-width: 100%;
  padding: 16px;
  background-color: ${({ theme, bg }) =>
    bg ? theme.colors[bg] : theme.colors.primaryLight};
  cursor: ${({ complete, active, clickable }) =>
    clickable && (complete || active) ? 'pointer' : 'default'};
  border: ${({ theme, active, border }) =>
    active ? `3px solid ${theme.colors[border]}` : ''};
`;

export const Wrapper = styled.div`
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row;

  gap: 12px;
  cursor: ${({ complete, active, clickable }) =>
    clickable && (complete || active) ? 'pointer' : 'default'};
  border: ${({ theme, active, border }) =>
    active ? `3px solid ${theme.colors[border]}` : ''};
`;

export const NumberWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${({ complete, icon }) => (complete || icon ? '0px' : '5px')};
  align-items: center;
  border-radius: 9.2px;
  min-width: 42px;
  height: 42px;
  background-color: ${({ theme, bg }) =>
    bg ? theme.colors[bg] : theme.colors.primaryLight};
`;

export const TextWrapper = styled.div``;

export const Line = styled.div`
  width: 6px;
  height: 20px;
  background-color: black;
  opacity: ${({ active }) => (active ? 1 : 0.2)};
`;
