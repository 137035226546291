import theme from '../../../theme';

const Clock = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '16'}
    height={height || '16'}
    viewBox="0 0 16 16"
  >
    <path
      fill={color || theme.colors.neutralMain}
      d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8Zm0 14.77A6.777 6.777 0 0 1 1.23 8 6.777 6.777 0 0 1 8 1.23 6.777 6.777 0 0 1 14.77 8 6.777 6.777 0 0 1 8 14.77Zm.615-7.032V2.52a.615.615 0 0 0-1.23 0V8c0 .167.068.328.188.444l3.019 2.9a.614.614 0 1 0 .853-.886l-2.83-2.72Z"
    />
  </svg>
);

export default Clock;
