import { useDontWantThisCoach } from 'api-calls/learners.queries';
import {
  Button,
  ConfirmationSuccessModal,
  Divider,
  Grid,
  Typography as T,
} from 'components';
import { getMediaUrl } from 'helpers';
import { useGeneralState } from 'context/general-state';
import * as S from './style';
import { useAuth } from 'context/auth';
import theme from 'theme';
import { audio, navRoutes } from '../../../constants';
import { useState } from 'react';
import { queryClient } from 'api-calls/queryClient';
import { useNavigate } from 'react-router-dom';

const { Col } = Grid;
const DontWantThisCoach = () => {
  const { state: generalState } = useGeneralState();
  const { user, logout } = useAuth();
  const [showConsentModal, setShowConsentModal] = useState(false);
  const [showConfirmedModal, setShowConfirmedModal] = useState(false);
  const navigate = useNavigate();
  const {
    mutateAsync: dontWantThisCoach,
    isLoading: isLoadingDontWantThisCoach,
    error: dontWantThisCoachError,
  } = useDontWantThisCoach();

  const handleDontWantThisCoach = () => {
    dontWantThisCoach(null, {
      onSuccess: () => {
        setShowConsentModal(false);
        setShowConfirmedModal(true);
        queryClient.invalidateQueries();
      },
    });
  };

  return (
    <>
      <ConfirmationSuccessModal
        visible={showConsentModal}
        variant="withAudio"
        setIsModalVisible={setShowConsentModal}
        title="Are you sure you don’t want this coach anymore?"
        titleAudioKey="areYouSureYouDontWantThisCoach"
        confirmText="Yes, I’m sure"
        confirmTextAudioKey="yesImSure"
        onConfirm={handleDontWantThisCoach}
        onConfirmLoading={isLoadingDontWantThisCoach}
        cancelText="No, go back"
        cancelTextAudioKey="noGoBack"
        onCancel={() => setShowConsentModal(false)}
        httpError={dontWantThisCoachError?.message}
        maxWidth="447px"
      />
      <ConfirmationSuccessModal
        visible={showConfirmedModal}
        variant="success"
        setIsModalVisible={() => {
          setShowConfirmedModal(false);
          // log learner out
          logout({
            onLogoutSuccess: () => {
              navigate(navRoutes.LEARNER.LOGIN);
            },
          });
        }}
        title="The facilitator has been notified and will be assigned a new coach soon."
        titleAudioKey="theFacilitatorHasBeenNotifiedAndWillBeAssignedANewCoachSoon"
        cancelText="Close"
        cancelTextAudioKey="close"
        onCancel={() => {
          setShowConfirmedModal(false);
          // log learner out
          logout({
            onLogoutSuccess: () => {
              navigate(navRoutes.LEARNER.LOGIN);
            },
          });
        }}
        maxWidth="447px"
      />
      <Divider />
      <S.PlayButtonWrapper mb="5">
        <Button.PlayButton
          width="56px"
          height="56px"
          audioUrl={getMediaUrl(null, true, generalState?.preferredVoice)}
          iconProps={{ width: 27.2 }}
          mr={3}
          fallbackText={`My Coach ${user.coach?.firstName}`}
        />

        <Col w={[3, 7, 7]} mlM="3">
          <T.H2>My Coach </T.H2>
          <T.P>&nbsp;{user.coach?.firstName}</T.P>
        </Col>
      </S.PlayButtonWrapper>
      <Button.BasicButton
        variant="tertiary"
        soundBgColor={theme.colors.tertiaryMain}
        bgColor="tertiaryLight"
        height="56px"
        ta="center"
        showSound
        audioUrl={getMediaUrl(
          audio.iDontWantThisCoach,
          true,
          generalState?.preferredVoice
        )}
        maxWidth="325px"
        fallbackText="I don't want this coach"
        handleClick={() => setShowConsentModal(true)}
        loading={isLoadingDontWantThisCoach}
      >
        I don't want this coach
      </Button.BasicButton>
    </>
  );
};

export default DontWantThisCoach;
