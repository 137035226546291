import MuiDropDownWrapper from './MuiDropDownWrapper';
import Autocomplete from '@mui/material/Autocomplete';
import * as S from './style';
const MultiDropDown = ({
  wrapperProps,
  inputProps: _inputProps = {},
  ...props
}) => {
  return (
    <MuiDropDownWrapper {...wrapperProps}>
      <Autocomplete
        multiple
        filterSelectedOptions
        disableCloseOnSelect
        getOptionLabel={(option) => {
          return option.label;
        }}
        renderInput={({ inputProps, ...params }) => (
          <S.StyledField
            {...params}
            multiline={true}
            rows={1.22}
            fullWidth
            hiddenLabel
            focused
            inputProps={{ ...inputProps, ..._inputProps }}
            InputLabelProps={{
              style: {
                clip: 'rect(0 0 0 0)',
                clipPath: 'inset(50%)',
                height: 1,
                overflow: 'hidden',
                position: 'absolute',
                whiteSpace: 'nowrap',
                width: 1,
              },
            }}
          />
        )}
        {...props}
      />
    </MuiDropDownWrapper>
  );
};

export default MultiDropDown;
