const ChevronRight = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.87167 6.5L8.46167 7.91L13.0417 12.5L8.46167 17.09L9.87167 18.5L15.8717 12.5L9.87167 6.5Z"
      fill="#050D30"
      fillOpacity="0.5"
    />
  </svg>
);

export default ChevronRight;
