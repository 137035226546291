import { Route, Routes } from 'react-router-dom';
import { Route as CustomRoute } from 'components';

import { navRoutes, userRoles } from '../../constants';
import Organisations from './Organisations';
import AddOrganisation from './AddOrganisation';
import Dashboard from './Dashboard';
import SyncSF from './SyncSF';

import UpdateFacilitatorsOrganisations from './UpdateFacilitatorsOrganisations';
import { OrganizationAdded } from 'components/OrganizationAdded';

const { ADMIN } = navRoutes;
const { BASE } = ADMIN;

function AdminRouter() {
  return (
    <Routes>
      <Route
        path={'/sync-sf'}
        element={
          <CustomRoute
            Component={SyncSF}
            layout="center"
            isPrivate
            allowedRoles={[userRoles.ADMIN]}
          />
        }
      />
      <Route
        path={ADMIN.UPDATE_FACILITATORS_ORGANISATIONS.replace(BASE, '')}
        element={
          <CustomRoute
            Component={UpdateFacilitatorsOrganisations}
            layout="center"
            isPrivate
            allowedRoles={[userRoles.ADMIN]}
          />
        }
      />
      <Route
        path={ADMIN.DASHBOARD.replace(BASE, '')}
        element={
          <CustomRoute
            Component={Dashboard}
            layout="full"
            isPrivate
            allowedRoles={[userRoles.ADMIN]}
          />
        }
      />
      <Route
        path={ADMIN.ORGANISATIONS.replace(BASE, '')}
        element={
          <CustomRoute
            Component={Organisations}
            layout="full"
            isPrivate
            allowedRoles={[userRoles.ADMIN]}
            hideFeedback
          />
        }
      />
      <Route
        path={ADMIN.ADD_ORGANISATION.replace(BASE, '')}
        element={
          <CustomRoute
            Component={AddOrganisation}
            layout="center"
            isPrivate
            allowedRoles={[userRoles.ADMIN]}
          />
        }
      />
      <Route
        path={ADMIN.EDIT_ORGANISATION.replace(BASE, '')}
        element={
          <CustomRoute
            Component={AddOrganisation}
            layout="center"
            isPrivate
            allowedRoles={[userRoles.ADMIN]}
          />
        }
      />
      <Route
        path={ADMIN.ORGANISATION_ADDED.replace(BASE, '')}
        element={
          <CustomRoute
            Component={OrganizationAdded}
            layout="center"
            isPrivate
            allowedRoles={[userRoles.ADMIN]}
          />
        }
      />
    </Routes>
  );
}

export default AdminRouter;
