const Close = ({ width, height, color, ...props }) => (
  <svg
    width={width || '32'}
    height={height || '32'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.6751 15.7398L10.2194 11.2801C9.92667 10.9871 9.92688 10.5122 10.2199 10.2194C10.5129 9.92667 10.9878 9.92688 11.2806 10.2199L15.7365 14.6799L20.2038 10.2193C20.4969 9.9266 20.9718 9.92695 21.2645 10.2201C21.5572 10.5132 21.5568 10.988 21.2637 11.2807L16.7967 15.7411L21.2623 20.2107C21.5551 20.5037 21.5549 20.9786 21.2618 21.2714C20.9688 21.5641 20.4939 21.5639 20.2012 21.2709L15.7353 16.8009L11.2799 21.2497C10.9868 21.5423 10.512 21.542 10.2193 21.2489C9.9266 20.9558 9.92695 20.4809 10.2201 20.1882L14.6751 15.7398Z"
      fill={color || '#55225D'}
    />
  </svg>
);

export default Close;
