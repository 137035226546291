import { Col, Row } from '../../components/Grid';
import { Typography as T } from '../../components';
import { Checkbox } from '../../components/Inputs';
import { BasicButton } from '../../components/Button';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import theme from '../../theme';
import Question from './Question';
import StaticQuestions from './StaticQuestions';

const StyledP = styled(T.P)`
  line-height: 28px !important;
  font-size: 1 !important;
  color: ${theme.colors.neutral90} !important;
  padding: 4.5px 0 !important;
  padding-top: 0 !important;
  padding-left: ${({ theme }) => theme.spacings[2]} !important;
`;

const InterestForm = ({
  setFormData,
  form,
  validationErrs,
  httpError,
  loading,
  handleSubmit,
  title,
  description,
  adminView,
}) => {
  const navigate = useNavigate();
  const setAnswer = (questionId, answer) => {
    setFormData({
      questions: form.questions.map((q) => {
        if (q.id === questionId) {
          return { ...q, answer };
        }
        return q;
      }),
    });
  };

  return (
    <>
      <Row>
        <Col w={[4, 12, 12]}>
          <T.H2 weight="bold" color="neutralMain" display="block">
            {title}
          </T.H2>
          <T.P weight="regular" size="regular" mt={3} display="block">
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </T.P>
        </Col>
        <StaticQuestions
          setFormData={setFormData}
          form={form}
          validationErrs={validationErrs}
        />

        {form?.questions?.map((question, i) => {
          return (
            <Question
              question={question}
              key={question.id}
              setAnswer={(answer) => setAnswer(question.id, answer)}
              error={validationErrs?.questions?.[i]}
            />
          );
        })}
        <Col w={[4, 12, 12]}>
          <Checkbox
            ai="start"
            label={
              <StyledP>
                I declare that the information on this form is true and complete
              </StyledP>
            }
            checked={form.trueAndCompleteInfo}
            handleChange={(trueAndCompleteInfo) =>
              setFormData({ trueAndCompleteInfo })
            }
            plain
            error={validationErrs.trueAndCompleteInfo}
            m={{
              mt: 4,
            }}
          />

          <Checkbox
            ai="start"
            label={
              <StyledP>
                By applying I give consent for the organisation to which I am
                applying to volunteer to use and keep the information I
                provide.casdcsadc
              </StyledP>
            }
            checked={form.giveConsent}
            handleChange={(giveConsent) => setFormData({ giveConsent })}
            plain
            error={validationErrs.giveConsent}
          />
        </Col>
        <Col w={[4, 12, 10]} mt="2">
          {httpError && (
            <T.P mb="2" color="error">
              {httpError}
            </T.P>
          )}

          {validationErrs.hasError && (
            <Row>
              <Col w={[4, 10, 12]}>
                <T.P color="error" mb={3}>
                  There is an error in your form. Please correct before
                  submitting.
                </T.P>
              </Col>
            </Row>
          )}
        </Col>

        {adminView ? (
          <Col w={[4, 11, 9]}>
            <BasicButton
              variant="primary"
              disabled={validationErrs.hasError}
              handleClick={() => navigate(-1)}
            >
              <T.P color="white" weight="bold">
                Go back
              </T.P>
            </BasicButton>
          </Col>
        ) : (
          <Col w={[4, 11, 9]}>
            <BasicButton
              variant="primary"
              disabled={loading}
              loading={loading}
              type="submit"
              handleClick={handleSubmit}
            >
              <T.P color="white" weight="semi">
                Submit
              </T.P>
            </BasicButton>
          </Col>
        )}
      </Row>
    </>
  );
};

export default InterestForm;
