import { useState } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';

import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import Dropdown from '../../../components/Inputs/Dropdown';
import * as S from './style';
import {
  useUpdateLearnerMilestoneUpdate,
  useGetLearnerCurrentMilestone,
} from '../../../api-calls/learners.queries';
import { useAuth } from '../../../context/auth';
import { useNavigate } from 'react-router-dom';
import ProgressCheckCard from './ProgressCheckCard';
import { navRoutes } from '../../../constants';
import { Loading } from '../../../components';
import { BackButton } from 'components/BackButton';
import { useGetMilestones } from 'api-calls/milestones.queries';

const SelectMilestone = () => {
  const [milestoneId, setMilestoneId] = useState(null);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const afterOnboarding = searchParams.get('afterOnboarding');
  const { learnerId: _learnerId } = useParams();
  const learnerId = _learnerId || user?.selectedLearnerId;

  const { mutateAsync: createLearnerMilestoneUpdate, isLoading } =
    useUpdateLearnerMilestoneUpdate({ learnerId });

  const {
    data: currentMilestone,
    isLoading: currentMilestoneLoading,
    isError: currentMilestoneError,
    error: currentMilestoneErrorData,
    refetch: refetchCurrentMilestone,
  } = useGetLearnerCurrentMilestone(
    { learnerId },
    { enabled: afterOnboarding === 'true' ? false : true }
  );
  const {
    data: milestones,
    isLoading: milestonesLoading,
    error: milestonesErr,
  } = useGetMilestones();

  const handleUpdateMilestone = async () => {
    await createLearnerMilestoneUpdate({ milestoneId: milestoneId - 1 });
    await refetchCurrentMilestone();
    if (afterOnboarding === 'true') {
      navigate(navRoutes.LEARNER.DASHBOARD);
    } else {
      navigate(
        navRoutes.COMMON.LEARNER_REACHED_MILESTONES.replace(
          ':learnerId',
          learnerId
        )
      );
    }
  };

  if (currentMilestoneLoading || milestonesLoading) return <Loading />;
  if (currentMilestoneError || milestonesErr)
    return (
      <T.P>{currentMilestoneErrorData?.message || milestonesErr.message}</T.P>
    );

  const mainMilestonesOrder = [3, 6, 9, 12, 15];
  let mainMilestones = [];
  if (currentMilestone?.currentMilestoneId) {
    mainMilestones = milestones.filter(
      (m, i) =>
        mainMilestonesOrder.includes(i + 1) &&
        m.id > currentMilestone?.currentMilestoneId
    );
  }

  const remainingMilestones = currentMilestone?.currentMilestoneId
    ? milestones.filter(
        (milestone) => milestone.id > currentMilestone?.currentMilestoneId
      )
    : milestones;

  const milestonesForSelect = remainingMilestones.map((m) => ({
    label: m.title,
    value: m.id,
  }));

  if (
    currentMilestone?.currentMilestoneId >=
    milestones[milestones.length - 1]?.id
  ) {
    return (
      <S.Wrapper>
        <Row mb={1}>
          <Col w={[4, 10, 9]}>
            <T.H2 color="neutralMain" weight="bold">
              {currentMilestone?.currentMilestone === 15
                ? 'Your learner is already at milestone 15'
                : 'Your learner has completed all milestones'}
            </T.H2>
          </Col>
        </Row>
        <S.BackWrapper mt="6" ai="end">
          <Col w={[4, 10, 9]}>
            <BasicButton variant="primary" onClick={() => navigate(-1)} mt={8}>
              Go Back
            </BasicButton>
          </Col>
        </S.BackWrapper>
      </S.Wrapper>
    );
  }

  return (
    <>
      <S.Wrapper>
        <BackButton mb="6" />
        <Row mb={1}>
          {afterOnboarding === 'true' ? (
            <Col w={[4, 10, 9]}>
              <T.H2 color="neutralMain" weight="bold">
                We have picked a number of progress checks at key milestones in
                the course.
              </T.H2>
              <T.P color="neutralMain" mt={3}>
                Try as many as you like to see where your learner might like to
                start.
              </T.P>
              <T.P color="neutralMain" mt={4} mb={6}>
                Once ready, select a starting milestone at the bottom of this
                page.
              </T.P>
            </Col>
          ) : (
            <Col w={[4, 10, 9]}>
              <T.H2 color="neutralMain" weight="bold">
                We have selected a number of progress checks at milestones in
                the course that are still to complete.
              </T.H2>
              <T.P color="neutralMain" mt={3} mb={3}>
                Please be careful not to move the learner too far ahead
              </T.P>
            </Col>
          )}
        </Row>
        <Row mb={1}>
          <Col w={[4, 10, 9]}>
            {mainMilestones.map(({ id, title }) => (
              <ProgressCheckCard
                key={milestoneId}
                milestoneId={id}
                title={title}
              />
            ))}

            <S.SelectWrapper>
              <Dropdown
                label={
                  afterOnboarding === 'true'
                    ? 'Which milestone do you want to get started on?'
                    : 'Which milestone do you want to move them ahead to?'
                }
                options={milestonesForSelect}
                handleChange={(v) => setMilestoneId(v)}
                selected={
                  milestoneId &&
                  milestonesForSelect.find((m) => m.value === milestoneId)
                }
              />
              <BasicButton
                variant="primary"
                onClick={handleUpdateMilestone}
                disabled={!milestoneId}
                loading={isLoading}
                mt={4}
              >
                {afterOnboarding === 'true' ? 'Let’s start' : 'Confirm'}
              </BasicButton>
            </S.SelectWrapper>
          </Col>
        </Row>
      </S.Wrapper>
    </>
  );
};

export default SelectMilestone;
