import { Route, Routes } from 'react-router-dom';
import { Route as CustomRoute } from 'components';
import { navRoutes, userRoles } from '../../constants';
import Dashboard from './Dashboard';
import CustomiseTool from './CustomiseTool';
import { CreateAccount } from 'pages';
import AddFacilitators from './AddFacilitators';
import OrganisationWelcome from './OrganisationWelcome';

const { ORGANISATION_ADMIN } = navRoutes;
const { BASE } = ORGANISATION_ADMIN;

function OrganisationAdminRouter() {
  return (
    <Routes>
      <Route
        path={ORGANISATION_ADMIN.WELCOME.replace(BASE, '')}
        element={
          <CustomRoute
            Component={OrganisationWelcome}
            layout="full"
            isPrivate
            allowedRoles={[userRoles.ORGANISATION_ADMIN]}
          />
        }
      />
      <Route
        path={ORGANISATION_ADMIN.DASHBOARD.replace(BASE, '')}
        element={
          <CustomRoute
            Component={Dashboard}
            layout="full"
            isPrivate
            allowedRoles={[userRoles.ORGANISATION_ADMIN]}
          />
        }
      />
      <Route
        path={ORGANISATION_ADMIN.ADD_FACILITATORS.replace(BASE, '')}
        element={
          <CustomRoute
            Component={AddFacilitators}
            layout="full"
            isPrivate
            allowedRoles={[userRoles.ORGANISATION_ADMIN]}
          />
        }
      />
      <Route
        path={ORGANISATION_ADMIN.CUSTOMISE.replace(BASE, '')}
        element={
          <CustomRoute
            Component={CustomiseTool}
            contentBodyProps={{ pt: '32px' }}
            layout="full"
            isPrivate
            allowedRoles={[userRoles.ORGANISATION_ADMIN]}
          />
        }
      />
      <Route
        path={ORGANISATION_ADMIN.SIGNUP.replace(BASE, '')}
        element={
          <CustomRoute
            Component={CreateAccount}
            layout="side"
            image="secondaryColor"
          />
        }
      />
    </Routes>
  );
}

export default OrganisationAdminRouter;
