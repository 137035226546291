import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Box = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 200px;
  border-radius: 12px;
  padding-top: ${({ theme }) => theme.spacings[4]};
  padding-bottom: ${({ theme }) => theme.spacings[4]};
  padding-left: ${({ theme }) => theme.spacings[4]};
  padding-right: ${({ theme }) => theme.spacings[4]};
  background-color: ${({ theme, bgColor }) =>
    bgColor || theme.colors.neutralSurface};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    background-color: ${({ theme }) => theme.colors.neutralLight};
  }
`;

export const TextIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
