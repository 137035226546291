import Modal from './../../../components/Modal';
import * as T from './../../../components/Typography';
import { Row, Col } from './../../../components/Grid';
import { BasicButton } from './../../../components/Button';

const SuccessModal = ({
  isSuccessModalVisible,
  setIsSuccessModalVisible,
  onSuccessClick,
  successMessage,
}) => {
  return (
    <Modal
      visible={isSuccessModalVisible}
      setIsModalVisible={setIsSuccessModalVisible}
    >
      <Row>
        <Col w={[4, 12, 12]} jc="center">
          <T.P size="med" weight="bold" color="white">
            {successMessage}
          </T.P>
        </Col>
        <>
          <Col w={[4, 12, 12]} mt={4}>
            <BasicButton onClick={onSuccessClick} variant="secondary">
              Return to home
            </BasicButton>
          </Col>
        </>
      </Row>
    </Modal>
  );
};

export default SuccessModal;
