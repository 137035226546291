import React, { useEffect, useState } from 'react';
import * as S from './style';
import Icon from 'components/Icon';

const CurrentExerciseButton = ({ activeExerciseRef, executeScroll }) => {
  const [isActiveAtBottom, setIsActiveAtBottom] = useState(false);

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting && entry.boundingClientRect.top > 0) {
          setIsActiveAtBottom(true);
        } else {
          setIsActiveAtBottom(false);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback);
    if (activeExerciseRef.current) {
      observer.observe(activeExerciseRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [activeExerciseRef]);

  return (
    <S.CurrentBtnWrapper>
      <S.CurrentButton
        onClick={() => {
          executeScroll(activeExerciseRef.current);
        }}
      >
        {isActiveAtBottom ? <Icon icon="arrowDown" /> : <Icon icon="arrowUp" />}
        Current
      </S.CurrentButton>
    </S.CurrentBtnWrapper>
  );
};

export default CurrentExerciseButton;
