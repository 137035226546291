import styled from '@emotion/styled';
import theme from 'theme';

export const Wrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const MsgsWrapper = styled.div`
  overflow-y: auto;
  flex: 1 1 auto;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    height: 70px;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.94) 6.4%,
      rgba(255, 255, 255, 0) 100%
    );
    pointer-events: none;
  }
`;

// TextMsg
export const MsgWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: ${(props) => (props.isUserMsg ? 'row-reverse' : 'row')};
  padding-bottom: 16px;

  &:hover #reportBtn,
  &:active #reportBtn,
  &:focus-within #reportBtn {
    display: flex;
  }

  #reportBtn {
    display: none;
  }
`;

export const TextMsg = styled.div`
  padding: 12px;
  max-width: 480px;
  background: ${(props) =>
    props.isUserMsg ? theme.colors.neutral30 : theme.colors.primary30};
  border-radius: 16px;
`;

export const AudioMsg = styled.div`
  display: flex;
  align-items: center;
  width: 234px;
  height: 48px;
  padding: 8px;
  background: ${(props) =>
    props.isUserMsg ? theme.colors.neutral30 : theme.colors.primary30};
  border-radius: 24px;

  .voice-visualizer-main-container {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-grow: 1;
    height: 32px;
  }
  .voice-visualizer__buttons-container {
    display: none;
  }
`;

export const ReadMoreButton = styled.span`
  color: ${theme.colors.primaryMain};
  cursor: pointer;
`;

export const TextAreaWrapper = styled.div`
  display: flex;
  height: 56px;
  padding: 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  border-radius: 16px;
  border: 1px solid #405ed6;
  background: #f0f1f5;
`;

export const TextArea = styled.textarea`
  flex-grow: 1;
  border: none;
  border-radius: 16px;
  background: transparent;
  color: ${theme.colors.neutralMain};
  font-family: 'Basic Commercial LT';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  resize: none;
  height: 100%;
  padding: 0;
  outline: none;

  &::placeholder {
    color: ${theme.colors.neutral60};
  }
`;

export const ChatTextAreaWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: #fff;
  p: 4;
  width: 904px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
`;
export const ModalText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 28px;
  font-weight: 700;
  color: #002666;
  margin-bottom: 24px;
`;
export const CloseModal = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;
export const ButtonWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 32px;
`;
export const ModalConfirmWrapper = styled.div`
  width: 375px;
  background-color: #405ed6;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  padding: 32px;
`;

export const RecordWrapper = styled.div`
  position: absolute;
  display: flex;
  right: 50px;
  width: calc(100% - 60px);
  border-radius: 12px;
  overflow: hidden;

  .voice-visualizer-main-container {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-grow: 1;
    height: 40px;
    padding-right: 10px;
    background: ${({ theme }) => theme.colors.primaryLight};
  }
  .voice-visualizer__buttons-container {
    display: none;
  }
`;

export const YouWillBeNotifiedWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0px;
  position: absolute;
  bottom: 4px;
`;
