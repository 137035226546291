import {
  DEFAULT_REQUIRED,
  INVALID_POSTCODE,
  MUST_SELECT_OPTION,
} from 'validation/err-msgs';

export const ValidationErrorMessages = {
  DEFAULT_REQUIRED,
  INVALID_POSTCODE,
  MUST_SELECT_OPTION,
  EMAIL_ALREADY_EXISTS: 'A user account with that email already exists',
  YOUR_ORGANISATION_HAS_BEEN_DEACTIVATED_FOR_OTHERS:
    'Your organisation has been deactivated.',
  YOUR_ORGANISATION_DOES_NOT_ALLOW_SELF_SIGNUP:
    'Your organisation does not allow self sign up',
  LEARNER_UNIQUE_ID_DOES_NOT_EXIST: "We can't find that learner ID",
  LEARNER_ALREADY_REGISTERED_TO_THIS_COURSE:
    'Learner already registered to this course',
  LEARNER_LINKED_TO_OTHER_COURSE: 'Learner linked to other course',
  LEARNER_LOGIN_NO_COACH_ASSIGNED:
    'Sorry, you cannot log in until you have been assigned a coach',
};
