export const EXERCISES_BASE = '/exercises';
export const LEARNERS_BASE = '/learners';
export const LEARNERS_GOALS_BASE = '/learner-personal-goals';
export const BADGES_BASE = '/badges';
export const COACHES_BASE = '/coaches';
export const MEDIA_BASE = '/media';
export const FACILITATORS_BASE = '/facilitators';
export const LEARNING_SESSIONS_BASE = '/learning-sessions';
export const PROGRESS_CHECKS_BASE = '/progress-checks';
export const MILESTONES_BASE = '/milestones';
export const USERS_BASE = '/users';
export const ORGANISATIONS_BASE = '/organisations';
export const CUSTOM_TRAINING_BASE = '/custom-training';
export const FEEDBACK_BASE = '/feedbacks';
export const CHAT_MESSAGES = '/chat-messages';
export const LEARNER_COACH_SESSIONS = '/coaches/sessions';
export const SITE_SETTINGS = '/site-settings';
export const VO_REQUIRED = '/vo-required';
