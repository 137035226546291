import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/auth';
import { navRoutes } from '../../../constants';
import { Col, Row } from '../../../components/Grid';
import { useUpdateCoach } from '../../../api-calls/coaches.queries';
import { COACH } from '../../../constants/nav-routes';
import { BasicButton } from '../../../components/Button';
import * as T from '../../../components/Typography';
import {
  useGetDefaultFacilitator,
  // useGetFacilitatorById,
} from '../../../api-calls/facilitator.queries';

const Onboarding6 = () => {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const coachId = user.id;
  const completedTrainingAt = new Date();

  // const { facilitator = {} } = useGetFacilitatorById({
  //   facilitatorId: user.facilitatorId,
  // });
  const { facilitator = {} } = useGetDefaultFacilitator();

  const {
    mutateAsync: updateCoach,
    isLoading,
    error,
  } = useUpdateCoach(user.id);

  if (user.id && (!user.bestDayTime || !user.availabilityHoursPerWeek)) {
    navigate(navRoutes.COACH.ONBOARDING_5, { replace: true });
  }

  const handleClick = async (e) => {
    e.preventDefault();

    await updateCoach({
      coachId,
      completedTrainingAt,
    });

    setUser({ ...user, completedTrainingAt });
    navigate(COACH.DASHBOARD);
  };

  return (
    <>
      <Row>
        <Col w={[4, 7, 7]}>
          <T.H2 color="neutralMain">Getting support</T.H2>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 7, 7]} mt="3">
          <T.P color="neutralMain">
            If you need help at any time, you can reach out to your Shannon
            Trust manager
          </T.P>
        </Col>
      </Row>

      <Row>
        <Col w={[4, 7, 7]} mt="5">
          <T.H3 color="neutralMain">Manager details</T.H3>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 7, 7]} mt="3">
          <T.P color="neutralMain">
            {facilitator.firstName} {facilitator.lastName}
          </T.P>
        </Col>
        <Col w={[4, 7, 7]}>
          <T.Link
            color="neutral90"
            mt={1}
            to={`mailto:${facilitator.email}`}
            external
          >
            {facilitator.email}
          </T.Link>
        </Col>
      </Row>
      <Row mt="6" ai="end" style={{ flex: 1 }}>
        <Col w={[4, 7, 7]}>
          {error?.message && <T.P color="error">{error?.message}</T.P>}
          <BasicButton
            variant="primary"
            maxWidth="426px"
            handleClick={handleClick}
            loading={isLoading}
          >
            Continue
          </BasicButton>
        </Col>
      </Row>
    </>
  );
};

export default Onboarding6;
