import { useMemo, useRef } from 'react';
import { Typography as T } from '../../../../../components';
import { BasicInput, Checkbox } from '../../../../../components/Inputs';
import { MediaKeyInput } from '../../../../components/MediaKeyInput';
import { mediaTypes } from '../../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import * as S from '../style';

export { validationSchema } from './validation';

const initialData = {
  options: [],
  rowSize: null,
  squaresPerRow: null,
  question: '',
  questionAudioKey: '',
  correctAnswer: '',
};

const GridWithTypingAnswer = () => {
  const initialRef = useRef(initialData);
  const { state, updateDataFields, updateFormMetaData } = useStepForm(
    initialRef.current
  );
  const { data = initialData, validationErrs } = state;

  const handleRowSizeChange = ({ rowSize, squaresPerRow }) => {
    const isRowSize = rowSize !== undefined;
    let value = isRowSize ? rowSize : squaresPerRow;
    let updatedKey = '';
    let validationErrsKey = '';
    if (isRowSize) {
      updatedKey = 'rowSize';
      validationErrsKey = 'rowSize';
    } else {
      updatedKey = 'squaresPerRow';
      validationErrsKey = 'squaresPerRow';
    }

    if (value < 2 || value > 4) {
      updateFormMetaData({
        validationErrs: {
          ...validationErrs,
          [validationErrsKey]: `${
            isRowSize ? 'Row size' : 'Squares per row'
          } must be between 2 and 4`,
        },
      });

      updateDataFields({ [updatedKey]: value });

      return;
    }
    const newOptions = [];
    let currentIndex = 0;

    Array.from({ length: isRowSize ? value : data.rowSize }).forEach(() => {
      Array.from({ length: !isRowSize ? value : data.squaresPerRow }).forEach(
        () => {
          newOptions.push({
            id: currentIndex,
            option: data.options[currentIndex]?.option || '',
            showValue: data.options[currentIndex]?.showValue || true,
          });
          currentIndex += 1;
        }
      );
    });

    updateDataFields({ options: newOptions, [updatedKey]: value });
    updateFormMetaData({
      validationErrs: {
        ...validationErrs,
        [validationErrsKey]: '',
      },
    });
  };

  const onOptionChange = ({ key, value, id }) => {
    updateDataFields({
      options: data.options.map((e) => {
        if (e.id !== id) return e;
        return {
          ...e,
          [key]: value,
        };
      }),
    });
  };

  const showSquares =
    data.rowSize >= 2 &&
    data.rowSize <= 4 &&
    data.squaresPerRow >= 2 &&
    data.squaresPerRow <= 4;

  const rows = useMemo(() => {
    if (!showSquares) {
      return [];
    }
    const options = data.options || [];
    const result = [];
    let index = 0;
    let rowLength = Number(data.squaresPerRow);

    while (index < options.length) {
      result.push(options.slice(index, index + rowLength));
      index += rowLength;
    }

    return result;
  }, [data.options, data.squaresPerRow, showSquares]);

  return (
    <div>
      <S.AnswerInputWrapper>
        <BasicInput
          label="Row Size"
          value={data.rowSize}
          handleChange={(rowSize) => handleRowSizeChange({ rowSize })}
          m={{ mt: 8 }}
          type="number"
          error={validationErrs.rowSize}
        />
        <BasicInput
          label="Squares per row"
          value={data.squaresPerRow}
          handleChange={(squaresPerRow) =>
            handleRowSizeChange({ squaresPerRow })
          }
          m={{ mt: 8 }}
          type="number"
          error={validationErrs.squaresPerRow}
        />
      </S.AnswerInputWrapper>

      <T.P mt="6">
        Fill in all the squares with the correct values. uncheck the box if you
        want to hide the value and allow the user to fill it.
      </T.P>
      {showSquares && (
        <S.PyramidInputsWrapper>
          {rows.map((rowOptions, rowIndex) => (
            <S.PyramidRowWrapper key={rowIndex} rowSize={data.rowSize}>
              {rowOptions.map((option) => {
                const id = option.id;
                const error = validationErrs?.options?.[id]?.option;

                return (
                  <S.BoxWrapper>
                    <BasicInput
                      key={id}
                      placeholder=""
                      value={option.option}
                      handleChange={(value) => {
                        onOptionChange({
                          key: 'option',
                          value,
                          id,
                        });
                      }}
                      bgColor={option.showValue ? 'neutralSurface' : ''}
                      borderColor={
                        error ? 'error' : option.showValue ? '' : 'primaryMain'
                      }
                      error={error}
                      hideErrorMsg
                      textAlign="center"
                    />
                    <Checkbox
                      w="33px"
                      plain
                      noPadding
                      checked={option.showValue}
                      handleChange={(checked) => {
                        onOptionChange({
                          key: 'showValue',
                          value: checked,
                          id,
                        });
                      }}
                      error={validationErrs.confirmedPermissionType}
                      hideErrorMsg
                    />
                  </S.BoxWrapper>
                );
              })}
            </S.PyramidRowWrapper>
          ))}
        </S.PyramidInputsWrapper>
      )}

      <BasicInput
        label="Question"
        value={data.question}
        handleChange={(question) => updateDataFields({ question })}
        m={{ mt: 8 }}
        error={validationErrs?.question}
      />
      <MediaKeyInput
        m={{ mt: 5 }}
        type={mediaTypes.QUESTION_AUDIO}
        label={`Question file Key`}
        value={data.questionAudioKey}
        handleChange={(questionAudioKey) =>
          updateDataFields({ questionAudioKey })
        }
        error={validationErrs?.questionAudioKey}
      />

      <BasicInput
        m={{ mt: 5 }}
        label="Correct Answer"
        value={data.correctAnswer}
        handleChange={(correctAnswer) => {
          updateDataFields({ correctAnswer });
        }}
        error={validationErrs?.correctAnswer}
      />
    </div>
  );
};

export default GridWithTypingAnswer;
