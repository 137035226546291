import styled from '@emotion/styled';
import { Checkbox, Tag as AntdTag } from 'antd';
import theme from '../../../theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .ant-table {
    overflow: auto;
  }

  .ant-table table {
    font-size: 1rem;
    font-weight: 400;
  }
`;

export const DBSCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${theme.colors.neutralMain};
    border-color: ${theme.colors.neutralMain};
  }
`;

export const ApprovedStatusColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  button {
    font-weight: bold;
    font-size: 1rem;
    margin-left: -0.5rem;
  }
`;

export const Tag = styled(AntdTag)`
  background-color: ${({ bgColor, theme }) => theme.colors[bgColor] ?? bgColor};
  color: ${({ fontColor, theme }) => theme.colors[fontColor]};
  border-radius: 24px;
  font-size: 1rem;
`;
