import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 700px;
  margin: 0 auto;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-top: 32px;
`;

export const TipWrapper = styled.div`
  margin-left: 0;
`;

export const CardWrapper = styled.div`
  margin-top: 32px;
  ${({ theme }) => theme.media.mobile} {
    margin-top: 24px;
  }
`;
