import styled from '@emotion/styled';
import { Col } from '../../../components/Grid';

import MuiModal from '@mui/material/Modal';

export const Wrapper = styled('div')`
  padding: 0 24px;
  max-width: 1000px;
  margin-inline: auto;
  ${({ theme }) => theme.media.tablet} {
    padding: 0;
  }
`;

export const CardWrapper = styled('div')`
  width: 100%;
  padding: ${({ theme, clear }) => !clear && theme.spacings[6]};
  padding-bottom: ${({ theme, clear }) => clear && theme.spacings[3]};
  border: ${({ theme, clear }) =>
    !clear && `1px solid ${theme.colors.neutral50}`};
  border-radius: 4px;
  background-color: ${({ theme, clear }) =>
    !clear && theme.colors.secondaryLight};
  textarea {
    background-color: transparent;
  }
`;

export const MessageWrapper = styled('div')`
  width: 100%;
  max-width: 548px;
  padding: 24px;
  border: 1px solid ${({ theme, clear }) => !clear && theme.colors.neutral40};
  background-color: ${({ theme }) => theme.colors.quaternaryLight};
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 13px;
`;

export const Section = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacings[6]};
`;

export const RightWrapper = styled(Col)`
  padding-left: ${({ theme }) => theme.spacings[6]};
`;

export const LeftWrapper = styled(Col)`
  padding-right: ${({ theme }) => theme.spacings[6]};
`;

export const FacilitatorsInvitesTableWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  .ant-table {
    overflow: auto;
  }
  .ant-table table {
    font-size: 1rem;
    font-weight: 400;
  }

  .ant-picker-input > input::placeholder {
    font-size: 1rem;
  }
`;

export const TableTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 400px;
  width: 100%;

  .ant-table {
    overflow: auto;
  }
  .ant-table table {
    font-size: 1rem;
    font-weight: 400;
  }

  .ant-picker-input > input::placeholder {
    font-size: 1rem;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 30%;
  }

  @media (max-width: 580px) {
    button {
      width: 100%;
    }
  }
`;

export const Modal = styled(MuiModal)`
  max-width: 100%;
  height: auto;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  margin-left: ${({ theme }) => theme.spacings[6]};
  margin-right: ${({ theme }) => theme.spacings[6]};
  @media (max-width: 580px) {
    max-width: 100%;
  }
`;

export const Container = styled.div`
  max-width: 100%;
  background-color: white;
  border: none;
  box-shadow: none;
  padding: 32px;
  @media (max-width: 580px) {
    max-width: 100%;
  }
`;
