import React, { useState } from 'react';
import { useExercises } from 'CMS/Providers/ExercisesProvider';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from '../../../components/Grid';
import { BasicButton } from '../../../components/Button';
import { createExerciseUrl } from 'CMS/utils';
import UnsavedModal from 'components/UnsavedModal';
import { useExerciseForm } from 'CMS/Providers/ExerciseFormProvider';

const NavigateExercise = ({ exerciseId }) => {
  const { exercises, isLoading } = useExercises();
  const { state, updateFormFields } = useExerciseForm();
  const { isDirty } = state;
  const navigate = useNavigate();
  const [nextExerciseId, setNextExerciseId] = useState('');
  const [isUnsavedModalVisible, setIsUnsavedModalVisible] = useState(false);

  const currentExerciseIndex = exercises?.findIndex(
    (exercise) => exercise.id === Number(exerciseId)
  );

  // Calculate previous and next exercise
  const prevExercise =
    currentExerciseIndex > 0 ? exercises[currentExerciseIndex - 1] : null;
  const nextExercise =
    currentExerciseIndex < exercises?.length - 1
      ? exercises[currentExerciseIndex + 1]
      : null;

  const handleNextExercise = (exerciseId) => {
    if (isDirty) {
      setIsUnsavedModalVisible(true);
      setNextExerciseId(exerciseId); // "prev" or "next"
    } else {
      // No unsaved changes, navigate directly
      navigate(createExerciseUrl(exerciseId));
    }
  };

  if (isLoading) return null;

  return (
    <>
      <Row jc="end" mt={6}>
        {prevExercise && (
          <Col w={[4, 6, 6]}>
            <BasicButton
              onClick={() => handleNextExercise(prevExercise.id)}
              variant="secondary"
            >
              Pervious exercise
            </BasicButton>
          </Col>
        )}
        {nextExercise && (
          <Col w={[4, 6, 6]}>
            <BasicButton
              onClick={() => handleNextExercise(nextExercise.id)}
              variant="secondary"
            >
              Next exercise
            </BasicButton>
          </Col>
        )}
      </Row>
      <UnsavedModal
        isModalVisible={isUnsavedModalVisible}
        setIsModalVisible={setIsUnsavedModalVisible}
        handleLeaveAnyway={() => {
          setIsUnsavedModalVisible(false);
          updateFormFields({ isDirty: false });
          navigate(createExerciseUrl(nextExerciseId));
        }}
      />
    </>
  );
};

export default NavigateExercise;
