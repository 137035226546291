import { useState } from 'react';

import * as T from '../../../../components/Typography';
import { Markdown } from '../../../../components';

import * as S from './style';
import StepFooterButtons from '../StepFooterButtons';

import CombineWordsPre from './CombineWordsPre';
import { Row } from 'components/Grid';
import { PlayButton } from 'components/Button';
import { combineWordsVariations } from 'constants';

const MDElement = ({
  item,
  index,
  sectionIndex,
  setAnswersArr,
  answersArr,
  correctAnswers,
}) => {
  const userAnswer = answersArr[sectionIndex][index];
  const handleChange = (e) => {
    const text = e.target.value;
    setAnswersArr((old) => {
      const newArr = [...old];
      newArr[sectionIndex][index] = text.toLowerCase();
      return newArr;
    });
  };

  return (
    <S.MDElementWrapper>
      <S.MDElement>
        <label htmlFor={'question' + index}>
          <Markdown
            text={item.pre?.replaceAll('+ ', '+&nbsp;')}
            customStyles={{
              p: {
                size: 'large',
                weight: 'regular',
                mb: 0,
                lh: '150%',
                ta: 'start',
              },
            }}
          />
        </label>
        <S.TypeInput
          id={'question' + index}
          name={'question' + index}
          isCorrect={userAnswer === item.answer}
          type="text"
          aria-labelledby={'question' + index}
          value={userAnswer}
          onChange={handleChange}
          size={userAnswer ? userAnswer.length + 1 : 100}
        />
      </S.MDElement>
    </S.MDElementWrapper>
  );
};

const Section = ({
  section = [],
  index: sectionIndex,
  setAnswersArr,
  answersArr,
  correctAnswers,
  showWordPost,
}) => {
  return (
    <S.Row
      bgColor={sectionIndex % 2 ? 'white' : 'neutralLight'}
      key={sectionIndex}
      mt={sectionIndex === 0 ? 5 : 0}
      direction="column"
    >
      {showWordPost ? (
        <S.MDElementWrapper>
          <T.P size="xxLarge" weight="bold" ta="left">
            {section[section.length - 1].answer}
          </T.P>
        </S.MDElementWrapper>
      ) : (
        section.map((md, index) => (
          <MDElement
            item={md}
            key={`md-${index}`}
            index={index}
            sectionIndex={sectionIndex}
            setAnswersArr={setAnswersArr}
            correctAnswers={correctAnswers}
            answersArr={answersArr}
          />
        ))
      )}
    </S.Row>
  );
};

const CombineWords = ({
  data: {
    variation = combineWordsVariations.variations.DEFAULT,
    textAudio,
    textAudioUrl,
    options = [],
  } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  enableNext,
}) => {
  const [answersArr, setAnswersArr] = useState(
    options.map((section) => section.map((option) => ''))
  );
  const correctAnswers = options.map((section) =>
    section.map((option) => option.answer)
  );

  const [showWordPost, setShowWordPost] = useState(false);

  const handleClick = () => {
    if (showWordPost || stuckAtReview) {
      handleNext();
    } else {
      setShowWordPost(true);
    }
  };

  const allAnswersAreCorrect = answersArr.every((section, i) =>
    section.every((answer, i2) => {
      return answersArr[i][i2] === correctAnswers[i][i2];
    })
  );

  if (!variation) {
    return (
      <S.Wrapper>
        <Row jc="center" mb={6}>
          <T.P color="error" m="0" mt="1">
            Please choose a variation
          </T.P>
        </Row>
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper display={'flex'}>
      {textAudio ? (
        <S.TextAudioWrapper ml={3} mlM={1} mlT={2} mt={1} mb={7}>
          {textAudioUrl ? (
            <>
              <PlayButton
                audioUrl={textAudioUrl}
                width="56px"
                height="56px"
                iconProps={{ width: '32px', height: '32px' }}
                fallbackText={textAudio}
              />
              <T.P ml={3} weight="regular" size="med">
                {textAudio}
              </T.P>
            </>
          ) : (
            <T.P weight="regular" size="med">
              {textAudio}
            </T.P>
          )}
        </S.TextAudioWrapper>
      ) : null}

      {options?.length > 0 &&
        options.map((section, index) => {
          return (
            <Section
              section={section}
              index={index}
              key={index}
              setAnswersArr={setAnswersArr}
              answersArr={answersArr}
              correctAnswers={correctAnswers}
              showWordPost={showWordPost}
            />
          );
        })}

      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleClick}
        preview={preview}
        disabled={disabled || preview || !allAnswersAreCorrect}
        enableNext={enableNext ?? allAnswersAreCorrect}
      />
    </S.Wrapper>
  );
};

export { CombineWordsPre };
export default CombineWords;
