import * as T from 'components/Typography';
import * as S from './style';
import { Icon } from 'components';
import Context from './context';

const QuestionModal = ({
  isModalOpen,
  setIsModalOpen,
  questionLabel,
  content,
  answersCount,
  role,
}) => {
  let roleLabel = role;
  if (answersCount !== 1) {
    roleLabel = role === 'COACH' ? 'coaches' : 'facilitators';
  }

  return (
    <S.Modal open={isModalOpen} onClose={() => setIsModalOpen()} role="dialog">
      <S.ModalContentWrapper>
        <T.P color="neutralMain" weight="bold">
          {questionLabel}
        </T.P>
        <S.ExpandAndCloseWrapper>
          <Icon
            icon="close"
            text="Cancel"
            onClick={() => setIsModalOpen(false)}
          />
        </S.ExpandAndCloseWrapper>

        <S.GrayBackgroundText ta="center" color="primaryMain" mt={6} mb={4}>
          {answersCount} {roleLabel} answered this question
        </S.GrayBackgroundText>
        <Context.Provider value={{ mode: 'onModal' }}>
          {content}
        </Context.Provider>
      </S.ModalContentWrapper>
    </S.Modal>
  );
};

export default QuestionModal;
