import React from 'react';
import { Col, Row } from 'components/Grid';
import * as T from 'components/Typography';
import * as S from './style';
import { BasicButton } from 'components/Button';
import { navRoutes } from '../../constants';
import Image from 'components/Image';
import { useSiteSettings } from 'context/site-settings';

export const JoinOurCommunity = (props) => {
  const { courseName } = useSiteSettings();

  return (
    <S.Card bg="primaryLight" {...props}>
      <Row inner>
        <Col w={[4, 12, 12]}>
          <T.H2 weight="bold" color="neutralMain" mb="4">
            Join our Community of Practice webinars
          </T.H2>
          <T.P>
            {`If you would like any further training, get direct advice from the
            ${courseName}, ask questions, or speak to fellow facilitators.`}
          </T.P>
        </Col>
        <Col w={[4, 6, 8]} mt="5">
          <BasicButton
            variant="primary"
            to={navRoutes.EXTERNAL.JOIN_OUR_COMMUNITY}
            aria-label="Find out more"
            type="button"
            id="find-out-more"
            width="300px"
            weight="500"
            external
          >
            Find out more
          </BasicButton>
        </Col>
      </Row>
      <Image
        image="joinCommunityAndWebinars"
        alt="Join our Community of Practice webinars"
        width="115px"
        height="120"
      />
    </S.Card>
  );
};
