import { useEffect } from 'react';

import {
  useDeleteUserPersonalInfo,
  useGetUserPersonalInfo,
  useUpdateUserPersonalInfo,
} from '../../../api-calls/users.queries';

export const useApiCallQueries = ({ setState, state }) => {
  const {
    mutateAsync: updateUserPersonalInfo,
    isLoading: isUpdatingUserPersonalInfoLoading,
    error: isUpdatingUserPersonalInfoError,
  } = useUpdateUserPersonalInfo();

  const {
    mutateAsync: deleteUserPersonalInfo,
    isLoading: isDeletingUserPersonalInfoLoading,
  } = useDeleteUserPersonalInfo();

  const {
    data: userPersonalInfo = {},
    isLoading: isLoadingUserPersonalInfo,
    isSuccess,
  } = useGetUserPersonalInfo({});

  useEffect(() => {
    if (isSuccess) {
      setState((prevState) => ({
        form: { ...prevState.form, ...userPersonalInfo },
      }));
    }
  }, [isSuccess, setState, userPersonalInfo]);

  useEffect(() => {
    // we use here useEffect to set the form data after the userPersonalInfo is loaded
    setState((prevState) => ({
      form: {
        ...prevState.form,
        ...userPersonalInfo,
      },
    }));
  }, [setState, userPersonalInfo]);

  return {
    userPersonalInfo,
    isLoadingUserPersonalInfo,
    updateUserPersonalInfo,
    isUpdatingUserPersonalInfoLoading,
    deleteUserPersonalInfo,
    isDeletingUserPersonalInfoLoading,
    isUpdatingUserPersonalInfoError,
  };
};
