import { useState, useEffect } from 'react';
import * as T from '../../../../components/Typography';
import { questionAndAnswerVariations } from '../../../../constants';
import * as S from './style';

const NormalAnswerInput = ({
  index,
  isWithEnding,
  setUserAnswers,
  userAnswers,
  correctAnswers,
}) => {
  const text = userAnswers[index];
  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    setUserAnswers((old) => {
      const newAnswers = [...old];
      newAnswers[index] = value.toLowerCase();
      return newAnswers;
    });
  };

  return (
    <S.TypeInputWrapper>
      <S.TypeInput
        id={`answer-${index}`}
        name={`answer-${index}`}
        type="text"
        aria-labelledby={`answer-${index}`}
        value={text}
        onChange={handleChange}
        isCorrect={text === correctAnswers[index].answer}
        isFullWidth
      />
      {isWithEnding && (
        <T.P size={'medLarge'} weight="bold" mb="2">
          {correctAnswers[index].ending}
        </T.P>
      )}
    </S.TypeInputWrapper>
  );
};

const FractionInputs = ({ setUserAnswers, userAnswers, correctAnswers }) => {
  const handleChange = (value, index) => {
    setUserAnswers((old) => {
      const newAnswers = [...old];
      newAnswers[index] = value.toLowerCase();
      return newAnswers;
    });
  };

  return (
    <S.TypeInputWrapper isFraction>
      <S.TypeInput
        id={`answer-${0}`}
        name={`answer-${0}`}
        type="text"
        aria-labelledby={`answer-${0}`}
        value={userAnswers[0]}
        onChange={(e) => handleChange(e.target.value, 0)}
        isCorrect={userAnswers[0] === correctAnswers[0].answer}
        isFullWidth
        isSquare
      />
      <S.Divide />
      <S.TypeInput
        id={`answer-${1}`}
        name={`answer-${1}`}
        type="text"
        aria-labelledby={`answer-${1}`}
        value={userAnswers[1]}
        onChange={(e) => handleChange(e.target.value, 1)}
        isCorrect={userAnswers[1] === correctAnswers[1].answer}
        isFullWidth
        isSquare
      />
    </S.TypeInputWrapper>
  );
};

const MultiplicationInputs = ({
  setUserAnswers,
  userAnswers,
  correctAnswers,
}) => {
  const handleChange = (value, index) => {
    setUserAnswers((old) => {
      const newAnswers = [...old];
      newAnswers[index] = value.toLowerCase();
      return newAnswers;
    });
  };

  return (
    <S.TypeInputWrapper jc="center">
      <S.TypeInput
        id={`answer-${0}`}
        name={`answer-${0}`}
        type="text"
        aria-labelledby={`answer-${0}`}
        value={userAnswers[0]}
        onChange={(e) => handleChange(e.target.value, 0)}
        isCorrect={userAnswers[0] === correctAnswers[0].answer}
        isSquare
      />
      <T.P weight="bold" m="0" size="large" lh="64px !important">
        X
      </T.P>
      <S.TypeInput
        id={`answer-${1}`}
        name={`answer-${1}`}
        type="text"
        aria-labelledby={`answer-${1}`}
        value={userAnswers[1]}
        onChange={(e) => handleChange(e.target.value, 1)}
        isCorrect={userAnswers[1] === correctAnswers[1].answer}
        isSquare
      />
      <T.P weight="bold" m="0" size="large" lh="64px !important">
        =
      </T.P>
      <S.TypeInput
        id={`answer-${2}`}
        name={`answer-${2}`}
        type="text"
        aria-labelledby={`answer-${2}`}
        value={userAnswers[2]}
        onChange={(e) => handleChange(e.target.value, 2)}
        isCorrect={userAnswers[2] === correctAnswers[2].answer}
      />
    </S.TypeInputWrapper>
  );
};

const InputsWrapper = ({
  variation,
  correctOptions,
  enableNext,
  setEnableNext,
  showQuestion,
}) => {
  const [userAnswers, setUserAnswers] = useState(
    new Array(correctOptions?.length).fill('')
  );
  const correctAnswers = correctOptions?.map((co) => {
    return { ...co, answer: co.option };
  });

  const isCorrectAnswersProvided =
    correctAnswers.length > 0 && correctAnswers.every((ans) => !!ans?.answer);

  useEffect(() => {
    if (isCorrectAnswersProvided) {
      const allAnswersCorrect = userAnswers.every(
        (text, index) => text?.toLowerCase() === correctAnswers[index]?.answer
      );
      setEnableNext(allAnswersCorrect);
    }
  }, [userAnswers, isCorrectAnswersProvided, correctAnswers, setEnableNext]);

  if (!isCorrectAnswersProvided) {
    return (
      <S.InputsWrapper>
        <T.P color="error" m="0" mt="1">
          Please provide correct answers
        </T.P>
      </S.InputsWrapper>
    );
  }

  let RenderInputs = null;
  switch (variation) {
    case questionAndAnswerVariations.variations.IMAGE_ONLY:
    case questionAndAnswerVariations.variations.NO_IMAGE:
      RenderInputs = (
        <NormalAnswerInput
          index={0}
          setUserAnswers={setUserAnswers}
          userAnswers={userAnswers}
          correctAnswers={correctAnswers}
        />
      );
      break;

    case questionAndAnswerVariations.variations.WITH_ENDING:
      RenderInputs = (
        <NormalAnswerInput
          isWithEnding
          index={0}
          setUserAnswers={setUserAnswers}
          userAnswers={userAnswers}
          correctAnswers={correctAnswers}
        />
      );
      break;
    case questionAndAnswerVariations.variations.FRACTION:
      RenderInputs = (
        <FractionInputs
          setUserAnswers={setUserAnswers}
          userAnswers={userAnswers}
          correctAnswers={correctAnswers}
        />
      );
      break;
    case questionAndAnswerVariations.variations.MULTIPLICATION:
      RenderInputs = (
        <MultiplicationInputs
          setUserAnswers={setUserAnswers}
          userAnswers={userAnswers}
          correctAnswers={correctAnswers}
        />
      );
      break;

    case questionAndAnswerVariations.variations.TWO_INPUTS_WITH_ENDINGS:
      RenderInputs = (
        <>
          <NormalAnswerInput
            isWithEnding
            index={0}
            setUserAnswers={setUserAnswers}
            userAnswers={userAnswers}
            correctAnswers={correctAnswers}
          />
          <NormalAnswerInput
            isWithEnding
            index={1}
            setUserAnswers={setUserAnswers}
            userAnswers={userAnswers}
            correctAnswers={correctAnswers}
          />
        </>
      );
      break;

    default:
      break;
  }

  return (
    <S.InputsWrapper mt={showQuestion ? '3' : '7'} mb="7">
      {RenderInputs}
    </S.InputsWrapper>
  );
};

export default InputsWrapper;
