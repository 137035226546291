import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: ${({ theme }) => `0 ${theme.spacings[11]}`};

  ${({ theme }) => theme.media.tablet} {
    padding: ${({ theme }) => `0 ${theme.spacings[7]}`};
    flex-direction: column;
    align-items: center;
  }

  ${({ theme }) => theme.media.mobile} {
    padding: ${({ theme }) => `0 ${theme.spacings[3]}`};
  }

  padding-bottom: ${({ theme }) => theme.spacings[8]};
`;
