import { useLocation } from 'react-router-dom';
import { EyeFilled } from '@ant-design/icons';
import * as T from '../Typography';
import { Users } from 'api-calls';
import { useSiteSettings } from 'context/site-settings';
import { useAuth } from 'context/auth';
import { courseCodeTypes } from 'constants';
import * as S from './style';

const ViewAnotherCourseProfile = ({ isCmidCourse, isTpdCourse }) => {
  const {
    mutate: switchCourse,
    isLoading: isSwitchLoading,
    error: switchError,
  } = Users.useSwitchToCourse();
  const location = useLocation();
  const { anotherCourseName, courseCode, anotherCourseCode } =
    useSiteSettings();
  const { user } = useAuth();

  const clickViewProfile = () => {
    switchCourse(
      { courseCode: anotherCourseCode },
      {
        onSuccess: (data) => {
          if (data?.redirectUrl) {
            window.open(
              `${data.redirectUrl}&targetUrl=${location.pathname}&targetRole=${user.role}`,
              '_blank'
            );
          }
        },
      }
    );
  };

  const showButton =
    (courseCode === courseCodeTypes.TPD && isCmidCourse) ||
    (courseCode === courseCodeTypes.CMID && isTpdCourse);

  if (!showButton) return null;
  return (
    <>
      <S.Button
        type="text"
        icon={<EyeFilled />}
        onClick={clickViewProfile}
        loading={isSwitchLoading}
      >
        View {anotherCourseName} profile
      </S.Button>
      {switchError?.message && (
        <S.ErrorWrapper>
          <T.P color="error">{switchError.message}</T.P>
        </S.ErrorWrapper>
      )}
    </>
  );
};

export default ViewAnotherCourseProfile;
