import Modal from '../../../components/Modal';
import * as T from '../../../components/Typography';
import { Col } from '../../../components/Grid';
import { BasicButton } from '../../../components/Button';

const DeleteConfirmModal = ({
  isModalVisible,
  setIsModalVisible,
  onConfirm,
  onCancel,
  message = 'Once you have deleted this resource this cannot be undone.',
}) => {
  return (
    <Modal visible={isModalVisible} setIsModalVisible={setIsModalVisible}>
      <Col w={[4, 12, 12]} jc="left">
        <>
          <T.P size="med" weight="bold" color="white">
            Are you sure?
          </T.P>
          <T.P color="white" mt="5">
            {message}
          </T.P>
        </>
      </Col>
      <>
        <Col w={[4, 12, 12]} mt={5}>
          <BasicButton onClick={onConfirm} variant="secondary">
            Confirm
          </BasicButton>
          <BasicButton mt="8px" onClick={onCancel} variant="tertiary">
            Cancel
          </BasicButton>
        </Col>
      </>
    </Modal>
  );
};

export default DeleteConfirmModal;
