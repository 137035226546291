import React from 'react';
import * as S from './milestoneBarStyle';
import * as T from 'components/Typography';
import Icon from 'components/Icon';
import CircularProgressWithLabel from 'components/CircularProgressWithLabel/CircularProgressWithLabel';

const MilestoneBar = ({ title, isCompleted, exercises, isFirstItem }) => {
  const completedExercises = exercises.filter(
    (item) => item.exerciseComplete && !item.savedExercise
  ).length;
  const totalExercises = exercises.length;
  const progress = (completedExercises / exercises.length) * 100;
  return (
    <S.Wrapper isCompleted={isCompleted} isFirstItem={isFirstItem}>
      <S.NameWrapper>
        <T.H2 size="med">{title}</T.H2>
      </S.NameWrapper>
      {isCompleted ? (
        <Icon icon="check" color="quinaryDark" width="28px" height="28px" />
      ) : (
        <CircularProgressWithLabel
          color="success"
          value={progress}
          size={`${completedExercises}/${totalExercises}`.length > 4 ? 52 : 40}
        >
          <T.P color="quinaryDark">
            {completedExercises}/{totalExercises}
          </T.P>
        </CircularProgressWithLabel>
      )}
    </S.Wrapper>
  );
};

export default MilestoneBar;
