import { useState } from 'react';
import {
  Button,
  Icon,
  Modal,
  YoutubeVid,
  Typography as T,
  ConfirmationSuccessModal,
} from 'components';
import * as S from './style';
import { useAuth } from 'context/auth';
import { audio, userRoles } from '../../../constants';
import { useGeneralState } from 'context/general-state';
import { getMediaUrl } from 'helpers';
import { useGetOrganisationByIdOrUniqueSlug } from 'api-calls/organisations.queries';
import { useReportChatMsg } from 'api-calls/chat-messages.queries';
import makeError from 'api-calls/format-error';
const { PlayButton } = Button;

const ReportModal = ({ msgIdToReport, setMsgIdToReport, receiverId }) => {
  const { state: generalState } = useGeneralState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [error, setError] = useState('');
  const { user } = useAuth();
  const { data: org } = useGetOrganisationByIdOrUniqueSlug({
    id: user?.organisationId,
  });
  const { mutate: reportMessage, isLoading: isReporting } = useReportChatMsg({
    receiverId,
  });
  const showAudio = user.role === userRoles.LEARNER;

  const handleConfirm = () => {
    setError('');
    reportMessage(
      { msgId: msgIdToReport },
      {
        onSuccess: () => {
          setShowConfirmModal(false);
          setShowSuccessModal(true);
          setMsgIdToReport('');
        },
        onError: (_error) => {
          const err = makeError(_error);
          let errMsg = 'Something went wrong. Please try again.';
          if (err?.statusCode === 403) {
            errMsg = err.message;
            if (err.message.includes('already reported')) {
              setShowConfirmModal(false);
              setShowSuccessModal(true);
              setMsgIdToReport('');
            }
          }
          setError(errMsg);
        },
      }
    );
  };

  const handleReset = () => {
    setShowConfirmModal(false);
    setShowSuccessModal(false);
    setMsgIdToReport('');
    setError('');
  };
  return (
    <>
      <Modal
        maxWidth={'908px'}
        bgColor="white"
        visible={!!msgIdToReport && !showConfirmModal}
        setIsModalVisible={() => setMsgIdToReport('')}
      >
        <S.CancelBtnWrapper>
          <S.StyledLink onClick={() => setMsgIdToReport('')}>
            <Icon icon="close" />
            <T.P>Cancel</T.P>
          </S.StyledLink>
        </S.CancelBtnWrapper>
        <S.HeadingWrapper>
          {showAudio && (
            <PlayButton
              radius={12}
              iconProps={{
                height: '32px',
                width: '32px',
              }}
              color="neutralMain"
              backgroundColor="neutralSurface"
              audioUrl={getMediaUrl(
                audio.whatDoesReportingAMessageMean,
                true,
                generalState?.preferredVoice
              )}
              fallbackText="What does reporting a message mean?"
            />
          )}
          <T.H1 ta="center">What does reporting a message mean?</T.H1>
        </S.HeadingWrapper>
        <S.VidWrapper>
          <YoutubeVid videoId={'8g0Tk3XFTVA'} />
        </S.VidWrapper>
        <S.BtnWrapper>
          <Button.BasicButton
            onClick={() => {
              setShowConfirmModal(true);
            }}
            style={{ width: '193px', height: '64px' }}
            {...(showAudio
              ? {
                  showSound: true,
                  audioUrl: getMediaUrl(
                    audio.continue,
                    true,
                    generalState?.preferredVoice
                  ),
                  fallbackText: 'Continue',
                }
              : {})}
          >
            Continue
          </Button.BasicButton>
        </S.BtnWrapper>
      </Modal>
      <ConfirmationSuccessModal
        variant={showAudio ? 'withAudio' : ''}
        visible={showConfirmModal}
        title="Report the message?"
        titleAudioKey="reportTheMessage"
        description={`This will notify ${org?.name} who will contact you to help resolve the issue.`}
        descriptionAudioKey={undefined} // bc of org?.name is dynamic
        confirmText="Confirm"
        confirmTextAudioKey="confirm"
        onConfirm={handleConfirm}
        onConfirmLoading={isReporting}
        cancelText="Cancel"
        cancelTextAudioKey="cancel"
        onCancel={handleReset}
        httpError={error}
      />
      <ConfirmationSuccessModal
        variant={showAudio ? 'withAudio' : ''}
        visible={showSuccessModal}
        title="Message reported"
        titleAudioKey={undefined}
        description={`A facilitator at ${org?.name} will review the message and contact you to help resolve the issue. `}
        descriptionAudioKey={undefined} // bc of org?.name is dynamic
        confirmText="Close"
        confirmTextAudioKey="close"
        onConfirm={handleReset}
      />
    </>
  );
};

export default ReportModal;
