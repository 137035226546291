import { useMemo, useRef } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput } from '../../../../components/Inputs';
import * as S from './style';
import { Icon } from '../../../../components';
import { string, array, object, number } from 'yup';
import { MediaKeyInput } from '../../../components/MediaKeyInput';
import { mediaInputLabels } from 'constants';
import { mediaTypes } from 'constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';

const initialData = {
  options: [{ id: 0, option: '' }],
  correctAnswer: '',
  audioUrl: '',
};

const DragAndDropOrderSounds = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateFormFields, updateDataFields } = useStepForm(
    initialRef.current
  );
  const { form, data, validationErrs } = state;

  const options = useMemo(
    () =>
      data?.options?.map((e, i) => ({ ...e, id: i })) || [
        { id: 0, option: '' },
      ],
    [data?.options]
  );

  const addOption = () => {
    const maxId = Math.max(...options.map((e) => e.id), 0);
    updateDataFields({
      options: [...options, { id: maxId + 1, option: '', hide: false }],
    });
  };
  const removeOption = (id) => {
    updateDataFields({
      options: options.filter((e) => e.id !== id),
    });
  };

  const onOptionChange = (value, id) => {
    updateDataFields({
      options: options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  return (
    <div>
      <MediaKeyInput
        label={mediaInputLabels.AUDIO_LABEL}
        type={mediaTypes.AUDIO}
        value={form.audioKey}
        handleChange={(audioKey) => updateFormFields({ audioKey })}
        m={{ mt: 8 }}
        error={validationErrs.audioKey}
      />

      <BasicInput
        label="Correct Answer"
        value={data.correctAnswer}
        handleChange={(correctAnswer) => updateDataFields({ correctAnswer })}
        m={{ mt: 8 }}
        helper="split the sounds by -"
        placeholder="eg. fan-tas-tic"
        error={validationErrs.correctAnswer}
      />

      <T.P mb={2} mt={8} size="large" weight="bold">
        Options
      </T.P>

      {validationErrs?.options &&
        typeof validationErrs.options === 'string' && (
          <T.P mb={2} mt={2} color="error">
            {validationErrs.options}
          </T.P>
        )}

      {options.map((e, i) => {
        return (
          <>
            <BasicInput
              label="Text"
              value={e.option}
              handleChange={(value) => {
                onOptionChange(value, e.id);
              }}
              m={{ mt: 5 }}
              error={
                validationErrs?.options && validationErrs?.options[i]?.option
              }
            />

            {options.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeOption(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}
      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addOption}
        disabled={options.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Option
      </S.Button>
    </div>
  );
};

const validationSchema = {
  options: array()
    .of(
      object()
        .shape({
          id: number().notRequired(),
          option: string().required('required field'),
        })
        .required()
    )
    .required()
    .min(1),
  correctAnswer: string().required('required field'),
  audioKey: string().required('required field'),
};
export { validationSchema };

export default DragAndDropOrderSounds;
