import { useRef, useState } from 'react';
import { Row } from 'components/Grid';
import { BasicButton } from '../../../components/Button';
import * as S from './style';
import { Typography as T } from 'components';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from './ConfirmationModal';
import SuccessModal from './SuccessModal';
import { navRoutes, facilitatorsStatuses, userRoles } from '../../../constants';

import { useUpdateFacilitatorStatus } from 'api-calls/facilitator.queries';
import { useMakeFacilitatorAsCoach } from 'api-calls/facilitator.queries';

import FacilitatorsTable from '../../CommonPages/Facilitators/FacilitatorsTable';
import { useAuth } from 'context/auth';

const Facilitators = () => {
  const status = useRef(null);
  const record = useRef(null);
  const { mutateAsync: updateFacilitatorStatus, isLoading: updateLoading } =
    useUpdateFacilitatorStatus();

  const {
    user: { role, organisationId },
  } = useAuth();

  const {
    mutateAsync: makeFacilitatorAsCoach,
    isLoading: makeFacilitatorAsCoachLoading,
    // isError: makeFacilitatorAsCoachError,
  } = useMakeFacilitatorAsCoach(null, {
    onSuccess: async () => {
      setIsUpdateModalVisible({
        modal: '',
        visible: false,
      });
      setIsSuccessModalVisible({
        modal: 'makeFacilitatorAsCoach',
        visible: true,
      });
    },
  });

  const isOrganisationAdmin = role === userRoles.ORGANISATION_ADMIN;

  const [isUpdateModalOpen, setIsUpdateModalVisible] = useState({
    modal: '',
    visible: false,
  });
  const [isSuccessModalOpen, setIsSuccessModalVisible] = useState({
    modal: '',
    visible: false,
  });

  const navigate = useNavigate();

  const activateAndDeactivate = async () => {
    updateFacilitatorStatus(
      {
        id: record.current.id,
        status: status.current,
        isInvite: !!record.current.inviteToken,
      },
      {
        onSuccess: async () => {
          setIsUpdateModalVisible({
            modal: 'activateAndDeactivate',
            visible: false,
          });
          setIsSuccessModalVisible({
            modal: 'activateAndDeactivate',
            visible: true,
          });
        },
      }
    );
  };

  const onActivateAndDeactivate = (_record, _status) => {
    status.current = _status;
    record.current = _record;
    setIsUpdateModalVisible({
      modal: 'activateAndDeactivate',
      visible: true,
    });
  };

  const onMakeFacilitatorAsCoach = (props) => {
    record.current = props;
    setIsUpdateModalVisible({
      modal: 'makeFacilitatorAsCoach',
      visible: true,
    });
  };

  const getConfirmationMessage = () => {
    if (isUpdateModalOpen.modal === 'activateAndDeactivate') {
      return status.current === facilitatorsStatuses.DEACTIVATED
        ? `By deactivating them you will remove their access to the platform.`
        : `By activating them they will be able to access the platform via their original log in details.`;
    }

    return record.current?.setAsCoach === true
      ? `By making them a coach they will be able to access the platform via their original log in details.`
      : `By removing them as a coach they will no longer be able to access the platform - Coach Dashboard and they will un-assigned form their learners`;
  };

  const getSuccessMessage = () => {
    if (isUpdateModalOpen.modal === 'activateAndDeactivate') {
      return status.current === facilitatorsStatuses.DEACTIVATED
        ? `They have been deactivated, if you wish to give them permission again, please go to the deactivated tab.`
        : `They have been activated and can now log in again. If they have forgotten their log in details they can click “Forgot password” on the log in page.`;
    }

    return record.current?.setAsCoach === true
      ? `They have been made a coach and can now log in again. If they have forgotten their log in details they can click “Forgot password” on the log in page.`
      : `They have been removed as a coach and can no longer log in. If they need to access the platform again, please make them a coach again.`;
  };

  return (
    <>
      <Row mb={9} mbT={7} inner jc="space-between" ai="center">
        <S.TableButtonWrapper>
          <S.TableTitleDescriptionWrapper>
            <T.H1 mb="8px">Facilitators</T.H1>
            <T.P>
              Facilitate Learning Excellence: Discover, Engage, and Empower
              Facilitators
            </T.P>
          </S.TableTitleDescriptionWrapper>

          {isOrganisationAdmin && (
            <BasicButton
              width="230px"
              handleClick={() =>
                navigate(navRoutes.ORGANISATION_ADMIN.ADD_FACILITATORS)
              }
            >
              Add Facilitator
            </BasicButton>
          )}
        </S.TableButtonWrapper>
      </Row>

      <FacilitatorsTable
        onActivateAndDeactivate={onActivateAndDeactivate}
        organisationId={organisationId}
        makeFacilitatorAsCoach={onMakeFacilitatorAsCoach}
      />

      <ConfirmationModal
        confirmationMessage={getConfirmationMessage()}
        isUpdateModalOpen={isUpdateModalOpen.visible === true}
        updateLoading={updateLoading || makeFacilitatorAsCoachLoading}
        setIsUpdateModalVisible={() => {
          setIsUpdateModalVisible({
            modal: '',
            visible: false,
          });
        }}
        onClick={(e) => {
          if (isUpdateModalOpen.modal === 'activateAndDeactivate') {
            activateAndDeactivate(e, true);
          } else if (isUpdateModalOpen.modal === 'makeFacilitatorAsCoach') {
            makeFacilitatorAsCoach(record.current);
          }
        }}
      />

      <SuccessModal
        isSuccessModalOpen={isSuccessModalOpen.visible === true}
        setIsSuccessModalVisible={() => {
          setIsSuccessModalVisible({
            modal: '',
            visible: false,
          });
        }}
        successMessage={getSuccessMessage()}
      />
    </>
  );
};

export default Facilitators;
