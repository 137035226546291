import TraningNotStartedNote from 'components/TraningNotStartedNote';
import React, { useCallback, useMemo } from 'react';
import * as S from './style';
import * as T from '../Typography';
import { navRoutes } from 'constants';
import { useNavigate } from 'react-router-dom';

const TrainingBanner = ({ stepIndex, stepData, isAllStepCompleted }) => {
  const navigate = useNavigate();

  const getTutorialLink = useMemo(() => {
    const arrayIndex = stepIndex + 1;
    const { user, routeKey, param } = stepData || {};

    const route = navRoutes?.[user]?.[routeKey];
    let navRoute = route;

    if (param && arrayIndex) navRoute = navRoute.replace(param, arrayIndex);
    return navRoute;
  }, [stepData, stepIndex]);

  const handleClick = useCallback(() => {
    navigate(getTutorialLink);
  }, [navigate, getTutorialLink]);

  return (
    <>
      {isAllStepCompleted ? null : stepIndex === 0 ? (
        <TraningNotStartedNote link={getTutorialLink} />
      ) : (
        <>
          <T.H2 mb="3">Complete training</T.H2>
          <S.TraningCard tabIndex={1} onClick={handleClick}>
            <S.StepNo>
              <T.H2 size="medLarge" color="white">
                {stepIndex + 1}
              </T.H2>
            </S.StepNo>
            <div>
              <T.P weight="bold">{stepData?.title}</T.P>
              <T.P>{stepData?.desc}</T.P>
            </div>
          </S.TraningCard>
        </>
      )}
    </>
  );
};

export default TrainingBanner;
