import { useState } from 'react';
import * as T from 'components/Typography';
import GenericCommonTable from './GenericCommonTable';
import { Tabs } from 'antd';
import * as S from './style';
const { TabPane } = Tabs;

// tabProps : {
//   onChange: (key) => {}
//}

// tabs: [
//   {
//     key: '1',
//     title: 'Tab 1',
//     count: 0,
//     columns: [],
//     data: [],
//     loading: false,
//     setPage: (page) => {},
//     emptyState: <></>,
//     tabProps: {},
//     tableProps: {}
//   }
// ]

const TabsCommonTable = ({
  tabsProps,
  activeTab: _activeTab,
  setActiveTab: _setActiveTab,
  tabs = [],
  page,
}) => {
  const [localActiveTab, setLocalActiveTab] = useState('1');

  let activeTab = _activeTab || localActiveTab;
  const setActiveTab = (key) => {
    setLocalActiveTab(key);
    if (typeof _setActiveTab === 'function') {
      _setActiveTab(key);
    }
  };
  return (
    <S.TabsTableWrapper activeTab={activeTab}>
      <Tabs
        {...tabsProps}
        activeKey={activeTab}
        defaultActiveKey="1"
        onChange={(key) => {
          setActiveTab(key);
          tabsProps.onChange(key);
        }}
        size="large"
      >
        {tabs.map((tab) => (
          <TabPane
            tab={
              <span>
                <T.P weight={activeTab === tab.key ? 'bold' : 'regular'}>
                  {tab.title} {`(${tab.count})`}
                </T.P>
              </span>
            }
            key={tab.key}
            {...tab.tabProps}
          >
            <GenericCommonTable
              page={page}
              totalRecords={tab.totalRecords}
              columns={tab.columns}
              dataSource={tab.data}
              loading={tab.loading}
              setPage={tab.setPage}
              emptyState={tab.emptyState}
              {...tab.tableProps}
            />
          </TabPane>
        ))}
      </Tabs>
    </S.TabsTableWrapper>
  );
};

export default TabsCommonTable;
