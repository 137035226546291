import styled from '@emotion/styled';
import { setMargin } from '../../../helpers';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 32px;
  height: 100%;
`;

export const PlayButtonWrapper = styled.div`
  ${setMargin};
  display: flex;
  align-items: center;
  width: 100%;
`;

export const MultiLineTitleWrapper = styled.div`
  ${setMargin};
`;

export const CircleDiv = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #200309;
  border: 8px solid #ff5b59;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white; /* Text color */
  font-size: 40px; /* Adjust font size as needed */
  font-weight: 700;
`;

export const CoverWrapper = styled.div`
  width: 100%;
  max-width: 708px;
  overflow: hidden;
  margin-top: 16px;
`;

export const TabImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
