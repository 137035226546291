import * as T from '../../../../../components/Typography';
import { fillInTheGridVariations } from '../../../../../constants';
import * as S from '../style';
import GridInput from './GridInput';
import GridInputWithValues from './GridInputWithValues';
import Rows from '../../shared/Rows';
import Thermometer from './Thermometer';

const InputsWrapper = ({
  variation,
  correctOptions = [],
  setEnableNext,
  rowSize,
  columnSize,
  gridStart,
  gridEnd,
  gridReplacedWithText,
  rows,
}) => {
  const correctAnswers = correctOptions?.map((co) => {
    return { ...co, answer: co.option };
  });

  let isCorrectAnswersProvided =
    correctAnswers.length > 0 && correctAnswers.every((ans) => !!ans?.answer);

  const isRows = variation === fillInTheGridVariations.variations.ROWS;
  const isThermometer =
    variation === fillInTheGridVariations.variations.THERMOMETER;
  if (isRows || isThermometer) {
    isCorrectAnswersProvided = !!rows.find((row) => row.isCorrect);
  }

  if (!isCorrectAnswersProvided) {
    return (
      <S.InputsWrapper>
        <T.P color="error" m="0" mt="1">
          Please provide
          {isRows || isThermometer
            ? ' at least one correct answer'
            : ' correct answers'}
        </T.P>
      </S.InputsWrapper>
    );
  }

  let RenderInputs = null;
  switch (variation) {
    case fillInTheGridVariations.variations.GRID_NO_VALUES:
      RenderInputs = (
        <GridInput
          setEnableNext={setEnableNext}
          correctAnswers={correctAnswers}
          rowSize={rowSize}
          columnSize={columnSize}
        />
      );
      break;
    case fillInTheGridVariations.variations.GRID_WITH_VALUES:
      RenderInputs = (
        <GridInputWithValues
          setEnableNext={setEnableNext}
          correctAnswers={correctAnswers}
          gridStart={gridStart}
          gridEnd={gridEnd}
          gridReplacedWithText={gridReplacedWithText}
        />
      );
      break;
    case fillInTheGridVariations.variations.ROWS:
      RenderInputs = <Rows setEnableNext={setEnableNext} rows={rows} />;
      break;
    case fillInTheGridVariations.variations.THERMOMETER:
      RenderInputs = <Thermometer setEnableNext={setEnableNext} rows={rows} />;
      break;

    default:
      break;
  }

  return (
    <S.InputsWrapper
      isThermometer={isThermometer}
      ml={isThermometer ? '2' : ''}
    >
      {RenderInputs}
    </S.InputsWrapper>
  );
};

export default InputsWrapper;
