import styled from '@emotion/styled';
import { setMargin } from '../../../helpers';

export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacings[6]};
  max-width: 878px;
  margin-inline: auto;
  @media (max-width: 345px) {
    padding-right: 32px;
  }
`;

export const Content = styled.div`
  padding-left: ${({ theme }) => theme.spacings[5]};
  ${({ theme }) => theme.media.mobile} {
    padding-left: 0;
  }
`;

export const LearnerId = styled.span`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.neutral70};
`;

export const StatsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 4px;
`;

export const PlayButtonWrapper = styled.div`
  ${setMargin}
  display: flex;
  align-items: center;
`;
